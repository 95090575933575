import { Alert, Button, Table, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { FormikProvider, useFormik } from "formik";
import { TableButton } from "shared/ui";
import { useConfirmDeleteModal, usePagination } from "shared/hooks";
import {
  handleRequestError,
  renderConsumedStatuses,
  addThousandsSeparator,
  noop,
} from "shared/helpers";
import { changeMaterialConsumedDateFormat } from "shared/adapters";
import {
  useDeleteMaterialConsumedMutation,
  useGetMaterialConsumedQuery,
  MaterialConsumedItem,
  MaterialConsumedItemNotExpanded,
  MaterialConsumedItemWithDetails,
  MaterialsItem,
} from "shared/api";
import {
  columnsMaterialConsumedData,
  expandMaterialConsumedWithUnit,
  materialConsumedInitialValues,
} from "shared/constants";
import { EditConsumedStartModel } from "shared/types";
import { AddConsumedModal } from "./AddConsumedModal";
import { EditConsumedModal } from "./EditConsumedModal";
import { AddOrUpdateMaterialConsumedSchema } from "./schemas";

interface MaterialConsumedProps {
  selectedMaterial: MaterialsItem | null;
}

const MaterialConsumed: FC<MaterialConsumedProps> = ({ selectedMaterial }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const {
    consumedDatetime,
    quantity,
    order_production_set,
    material,
    materialStockBatch,
    materialItem,
    reason,
    status,
    actions,
  } = columnsMaterialConsumedData;

  const addOrUpdateMaterialConsumedItemFormik = useFormik({
    initialValues: materialConsumedInitialValues,
    validationSchema: AddOrUpdateMaterialConsumedSchema,
    onSubmit: noop,
  });

  const { setValues, values, setFieldValue } =
    addOrUpdateMaterialConsumedItemFormik;

  const [isOpenAddConsumedModal, setOpenAddConsumedModal] = useState(false);
  const [isOpenEditConsumedModal, setOpenEditConsumedModal] = useState(false);

  const {
    data: materialConsumed,
    isLoading: isLoadingMaterialConsumed,
    isFetching: isFetchingMaterialConsumed,
  } = useGetMaterialConsumedQuery(
    {
      limit,
      offset,
      material: selectedMaterial?.id,
      expand: expandMaterialConsumedWithUnit,
    },
    { skip: selectedMaterial?.id === null }
  );

  const [materialConsumedWithChangedDate, setMaterialConsumedWithChangedDate] =
    useState<MaterialConsumedItem[]>([]);

  useEffect(() => {
    if (materialConsumed) {
      const ordersWithChangedDate =
        changeMaterialConsumedDateFormat(materialConsumed);
      setMaterialConsumedWithChangedDate(ordersWithChangedDate);
    }
  }, [materialConsumed?.results]);

  const [deleteMaterialConsumed] = useDeleteMaterialConsumedMutation();

  const deleteMaterialConsumedHandler = async (
    record: MaterialConsumedItem
  ) => {
    try {
      await deleteMaterialConsumed(record).unwrap();
      api.success({
        message: t("material-consumed.success"),
        description: t("material-consumed.delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-consumed.error")
      );

      api.error({
        message: t("material-consumed.error"),
        description,
      });
    }
  };

  const showDeleteConsumedModal = useConfirmDeleteModal(
    t("material-consumed.delete-alert"),
    deleteMaterialConsumedHandler
  );

  const editConsumedHandler = (record: EditConsumedStartModel) => {
    setOpenEditConsumedModal(true);
    setValues({
      id: record.id,
      consumed_datetime: record.consumed_datetime,
      // @ts-ignore
      order_process_step_tracker: record.order_process_step_tracker,
      status: record.status,
      reason: record.reason,
      quantity: record.quantity,
    });
  };

  const setFieldVariant = (record: MaterialConsumedItem) => {
    if (record.material_item === null && record.material_stock_batch === null) {
      setFieldValue("fieldVariant", "material");
    }
    if (record.material === null && record.material_stock_batch === null) {
      setFieldValue("fieldVariant", "material_item");
    }
    if (record.material === null && record.material_item === null) {
      setFieldValue("fieldVariant", "material_stock_batch");
    }
  };

  useEffect(() => {
    if (values.fieldVariant !== "material") {
      setFieldValue("material", null);
      setFieldValue("material_item", null);
    }
  }, [values.fieldVariant]);

  const renderQuantity = (item: MaterialConsumedItemWithDetails) => {
    let quantityValue;

    if (
      item.material_stock_batch === null &&
      item.material_stock_batch === null
    ) {
      quantityValue = `${addThousandsSeparator(item.quantity)} -  ${
        item.material?.material_category.unit.abbreviation
      }`;
    }

    if (item.material === null && item.material_stock_batch === null) {
      quantityValue = `${addThousandsSeparator(item.quantity)} - ${
        item.material_item?.material_stock_batch.material.material_category.unit
          .abbreviation
      }`;
    }

    if (item.material === null && item.material_item === null) {
      quantityValue = `${addThousandsSeparator(item.quantity)} - ${
        item.material_stock_batch?.material.material_category.unit.abbreviation
      }`;
    }

    return quantityValue;
  };

  const [dynamicItemFromTable, setDynamicItemFromTable] =
    useState<MaterialConsumedItem | null>(null);

  const columns: ColumnsType<
    MaterialConsumedItem | MaterialConsumedItemWithDetails
  > = [
    {
      title: t(consumedDatetime.title),
      render: (
        item:
          | MaterialConsumedItemWithDetails
          | MaterialConsumedItem
          | EditConsumedStartModel
      ) => (
        <TableButton
          type="title"
          title={item?.consumed_datetime ?? t("material-consumed.no-info")!}
          onClick={() => {
            editConsumedHandler(item as EditConsumedStartModel);
            setFieldVariant(item as MaterialConsumedItem);
            setDynamicItemFromTable(item as MaterialConsumedItem);
          }}
        />
      ),
    },
    {
      title: t(quantity.title),
      render: (item: MaterialConsumedItem) =>
        renderQuantity(item as MaterialConsumedItemWithDetails),
    },
    {
      title: t(order_production_set.title),
      render: (item: MaterialConsumedItem) => {
        if (item.order_production_set === null) {
          return (
            <span className="opacity-50">{t("material-consumed.null")}</span>
          );
        }
        return item.order_production_set;
      },
    },
    {
      title: t(material.title),
      render: (item: MaterialConsumedItem) => {
        if (item.material === null) {
          return (
            <span className="opacity-50">{t("material-consumed.null")}</span>
          );
        }
        return item.material?.name;
      },
    },
    {
      title: t(materialStockBatch.title),
      render: (item: MaterialConsumedItem) => {
        if (item.material_stock_batch === null) {
          return (
            <span className="opacity-50">{t("material-consumed.null")}</span>
          );
        }
        return item.material_stock_batch.batch_code;
      },
    },
    {
      title: t(materialItem.title),
      render: (item: MaterialConsumedItem) => {
        if (item.material_item === null) {
          return (
            <span className="opacity-50">{t("material-consumed.null")}</span>
          );
        }
        return item.material_item.serial_number;
      },
    },
    {
      title: t(reason.title),
      render: (item: MaterialConsumedItem) => {
        if (item?.reason === null) {
          return (
            <span className="opacity-50">{t("material-consumed.null")}</span>
          );
        }
        return item?.reason;
      },
    },
    {
      title: t(status.title),
      render: (item: MaterialConsumedItem) => renderConsumedStatuses(item),
    },

    {
      title: t(actions.title),
      key: actions.key,

      render: (
        record: MaterialConsumedItem | MaterialConsumedItemNotExpanded
      ) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => {
                showDeleteConsumedModal(record as MaterialConsumedItem);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <FormikProvider value={addOrUpdateMaterialConsumedItemFormik}>
      {contextHolder}

      <div>
        <Button
          type="primary"
          className="btn-primary--dark"
          onClick={() => setOpenAddConsumedModal(true)}
        >
          {t("material-consumed.add-consumed")}
          <PlusOutlined />
        </Button>

        {!materialConsumed?.results.length && (
          <Alert
            type="warning"
            showIcon
            message={t("material-consumed.no-items")}
            className="mb-5"
          />
        )}

        <Table
          loading={isLoadingMaterialConsumed || isFetchingMaterialConsumed}
          columns={columns}
          dataSource={materialConsumedWithChangedDate}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: materialConsumed?.count,
          }}
          className={!materialConsumed?.results.length ? "hidden" : "block"}
        />

        <AddConsumedModal
          isOpenModal={isOpenAddConsumedModal}
          setOpenAddConsumedModal={setOpenAddConsumedModal}
          selectedMaterial={selectedMaterial}
        />

        <EditConsumedModal
          isOpenModal={isOpenEditConsumedModal}
          selectedMaterial={selectedMaterial}
          setOpenEditConsumedModal={setOpenEditConsumedModal}
          dynamicItemFromTable={dynamicItemFromTable}
        />
      </div>
    </FormikProvider>
  );
};

export { MaterialConsumed };
