import * as Yup from "yup";

export const widgetsValidationSchema = Yup.object().shape({
  column_names: Yup.array()
    .of(Yup.string())
    .min(1, "At least one column name is required"),
  limit_rows: Yup.number()
    .min(1, "At least one item is required")
    .required("Limit rows is required")
    .nullable(),
  filter: Yup.array()
    .of(
      Yup.object().shape({
        filter_parameter: Yup.string(),
        filter_value: Yup.array().of(Yup.string()),
      })
    )
    .test(
      "fields",
      "Filter parameter and value are required when fields array is not empty",
      // eslint-disable-next-line func-names
      function (fields = []) {
        if (fields?.length === 0) {
          return true;
        }
        return fields?.every(
          (field) =>
            !!field.filter_parameter && (field.filter_value?.length || 0) > 0
        );
      }
    ),
});
