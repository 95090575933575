import controllerGreen from "./logo/controller-green.png";
import controllerRed from "./logo/controller-red.png";
import controllerYellow from "./logo/controller-yellow.png";

import customerGreen from "./logo/customer-green.png";
import customerRed from "./logo/customer-red.png";
import customerYellow from "./logo/customer-yellow.png";

import logisticGreen from "./logo/logistic-green.png";
import logisticRed from "./logo/logistic-red.png";
import logisticYellow from "./logo/logistic-yellow.png";

import plannerGreen from "./logo/planner-green.png";
import plannerRed from "./logo/planner-red.png";
import plannerYellow from "./logo/planner-yellow.png";

import productionGreen from "./logo/production-green.png";
import productionRed from "./logo/production-red.png";
import productionYellow from "./logo/production-yellow.png";

import qualityGreen from "./logo/quality-green.png";
import qualityRed from "./logo/quality-red.png";
import qualityYellow from "./logo/quality-yellow.png";

import poweredByChainlinx from "./logo/poweredByChainlinx.png";
import logoMain from "./logo/logoMain.png";
import rIcon from "./logo/rIcon.png";

export const widgetsIcons = {
  controllerGreen,
  controllerRed,
  controllerYellow,
  customerGreen,
  customerRed,
  customerYellow,
  logisticGreen,
  logisticRed,
  logisticYellow,
  plannerGreen,
  plannerRed,
  plannerYellow,
  productionGreen,
  productionRed,
  productionYellow,
  qualityGreen,
  qualityRed,
  qualityYellow,
};

export const logosImages = {
  poweredByChainlinx,
  logoMain,
};

export const icons = {
  rIcon,
};
