import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  GenerateMultiplePackagingReport,
  OrderItem,
  useGenerateMultiplePackagingReportMutation,
  useUpdateOrderMutation,
} from "shared/api";
import { dayjs, handleRequestError } from "shared/helpers";

export const useOrdersRequests = (clearAllValuesAndCloseModal?: () => void) => {
  const { t } = useTranslation();

  const [api] = notification.useNotification();

  const [updateOrder] = useUpdateOrderMutation();
  const [
    generateMultiplePackagingReport,
    { isLoading: isLoadingGenerateMultiplePackagingReport },
  ] = useGenerateMultiplePackagingReportMutation();

  const updateOrderWantedDeliveryTimeRequest = async (
    item: OrderItem,
    wantedDeliveryDatetime: string
  ) => {
    try {
      await updateOrder({
        id: item?.id!,
        buyer: item?.buyer.id,
        po_number: item?.po_number,
        product_card: item?.product_card.id,
        production_datetime: dayjs(item?.production_datetime!).utc().format(),
        quantity_expected: item?.quantity_expected!,
        status: item?.status!,
        wanted_delivery_datetime: dayjs(wantedDeliveryDatetime).utc().format(),
        dynamic_order_fields: item?.dynamic_order_fields,
        customer_order_number: item?.customer_order_number,
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  const updateOrderWantedProductionTimeRequest = async (
    item: OrderItem,
    productionTime: string
  ) => {
    try {
      await updateOrder({
        id: item?.id!,
        buyer: item?.buyer.id,
        po_number: item?.po_number,
        product_card: item?.product_card.id,
        production_datetime: dayjs(productionTime).utc().format(),
        quantity_expected: item?.quantity_expected!,
        status: item?.status!,
        wanted_delivery_datetime: dayjs(item?.wanted_delivery_datetime!)
          .utc()
          .format(),
        dynamic_order_fields: item?.dynamic_order_fields,
        customer_order_number: item?.customer_order_number,
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  const updateOrderStatusRequest = async (item: OrderItem, status: string) => {
    try {
      await updateOrder({
        id: item?.id!,
        buyer: item?.buyer.id,
        po_number: item?.po_number,
        product_card: item?.product_card.id,
        production_datetime: dayjs(item?.production_datetime!).utc().format(),
        quantity_expected: item?.quantity_expected!,
        status: status!,
        wanted_delivery_datetime: dayjs(item?.wanted_delivery_datetime!)
          .utc()
          .format(),
        dynamic_order_fields: item?.dynamic_order_fields,
        customer_order_number: item?.customer_order_number,
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  const generateMultiplePackagingReportHandler = async (
    { id, material_id, packaging_ids }: GenerateMultiplePackagingReport,
    refetchPackages: () => void
  ) => {
    try {
      const response = await generateMultiplePackagingReport({
        id,
        material_id,
        packaging_ids,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }

      refetchPackages();

      clearAllValuesAndCloseModal?.();
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  return {
    updateOrderWantedDeliveryTimeRequest,
    updateOrderWantedProductionTimeRequest,
    updateOrderStatusRequest,
    generateMultiplePackagingReportHandler,
    isLoadingGenerateMultiplePackagingReport,
  };
};
