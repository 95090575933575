import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { Loader, PopConfirmButton } from "shared/ui";

interface OrderCodeInfoProps {
  navigateOrder: (direction: "next" | "previous") => void;
  orderCode?: string;
  isLoadingOrderCodes: boolean;
}

const OrderCodeInfo: FC<OrderCodeInfoProps> = ({
  navigateOrder,
  orderCode,
  isLoadingOrderCodes,
}) => {
  const { t } = useTranslation();
  const orderTitle = `${t("orders.order-code")} - ${orderCode}`;

  return (
    <>
      {isLoadingOrderCodes ? (
        <Loader className="!w-5 !h-5" />
      ) : (
        <LeftOutlined
          className="w-6 h-6 flex items-center justify-center !text-white self-center"
          onClick={() => navigateOrder("next")}
        />
      )}

      <PopConfirmButton
        buttonContent={orderTitle}
        onClick={() =>
          openHyperlinkInTab(
            `${Routes.orders.hyperlinkUrl}/${orderCode}`.replace(
              ":orderID/",
              ""
            )
          )
        }
        popupClassName="left-5 top-100"
        className="!text-white"
      />

      {isLoadingOrderCodes ? (
        <Loader className="!w-5 !h-5" />
      ) : (
        <RightOutlined
          className="w-6 h-6 flex items-center justify-center !text-white self-center"
          onClick={() => navigateOrder("previous")}
        />
      )}
    </>
  );
};

export { OrderCodeInfo };
