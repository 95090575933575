import { SupplierCompanyItem } from "shared/api";

export const adaptCompanies = (companiesAdapted: SupplierCompanyItem[]) => {
  const adaptCompanies = companiesAdapted?.map((item) => ({
    label: `${item.name}`,
    value: item.id!,
  }));
  return adaptCompanies || [];
};

export const adaptByersCompanies = (
  companiesAdapted: SupplierCompanyItem[]
) => {
  const adaptCompanies = companiesAdapted
    ?.filter((item) => item?.is_buyer)
    .map((item) => ({
      label: `${item.name}`,
      value: item.id!,
    }));
  return adaptCompanies || [];
};
