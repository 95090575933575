import { Table, notification } from "antd";
import React, { FC } from "react";
import {
  ReportItem,
  useDeleteReportMutation,
  useGetReportsQuery,
} from "shared/api";
import {
  useConfirmDeleteModal,
  usePagination,
  useTableConfigColumns,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

interface TemplatesConfigTableProps {
  showEditModal: () => void;
}

const TemplatesConfigTable: FC<TemplatesConfigTableProps> = ({
  showEditModal,
}) => {
  const { setValues } = useFormikContext<ReportItem>();

  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const pagination = usePagination();

  const { pageSize, currentPage, setCurrentPage, setPageSize, offset, limit } =
    pagination;

  const {
    data: reports,
    isFetching,
    isLoading,
  } = useGetReportsQuery({
    offset,
    limit,
  });

  const [deleteReport] = useDeleteReportMutation();

  const handleDeleteReport = async (report: ReportItem) => {
    try {
      await deleteReport(report).unwrap();
      api.success({
        message: t("reports.success-title"),
        description: t("reports.success-delete-msg"),
      });
    } catch (error) {
      api.error({
        message: t("reports.error-title"),
        description: handleRequestError(error, t("reports.error")),
      });
    }
  };

  const showDeleteReportModal = useConfirmDeleteModal(
    t("reports.delete-alert-msg"),
    handleDeleteReport
  );

  const handleEditReport = (record: ReportItem) => {
    showEditModal();
    setValues(record);
  };

  const columns = useTableConfigColumns({
    t,
    handleEditReport,
    showDeleteReportModal,
  });

  return (
    <>
      {contextHolder}

      <Table
        loading={isFetching || isLoading}
        columns={columns}
        dataSource={reports?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: reports?.count,
        }}
      />
    </>
  );
};

export { TemplatesConfigTable };
