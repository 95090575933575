import { Pagination, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo } from "react";
import { useWindowDimensions } from "shared/hooks";

interface OutputMaterialsEditModePaginationProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  handleCurrentPage: (page: number) => void;
}

const OutputMaterialsEditModePagination: FC<
  OutputMaterialsEditModePaginationProps
> = ({ currentPage, totalPages, handlePageChange, handleCurrentPage }) => {
  const { isDesktop } = useWindowDimensions();

  const goToOptions = useMemo(
    () =>
      Array.from({ length: totalPages }, (_, index) => ({
        value: index + 1,
        label: (index + 1).toString(),
      })),
    [totalPages]
  );

  return (
    <div className="flex items-center justify-between w-full">
      <Pagination
        current={currentPage + 1}
        total={totalPages}
        pageSize={1}
        onChange={handlePageChange}
        showSizeChanger={false}
      />

      <div className="flex gap-x-2 items-center">
        {isDesktop ? (
          <Title level={5} className="!m-0">
            Go to{" "}
          </Title>
        ) : null}

        <Select
          options={goToOptions}
          onChange={handleCurrentPage}
          value={currentPage + 1}
        />
      </div>
    </div>
  );
};

export { OutputMaterialsEditModePagination };
