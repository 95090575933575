import { UploadFile, UploadProps, message } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { AddCompanyValues } from "shared/types";
import { CompaniesResponse, CompanyItem } from "shared/api";

export const generateUploadImageParams = (
  handleUploadFile: (file: File | null) => void,
  handleUploadImage: (image: string | null) => void,
  handleFileList: (files: UploadFile<unknown>[]) => void,
  fileList: UploadFile<unknown>[]
) => {
  const props: UploadProps = {
    name: "file",
    beforeUpload: (file) => {
      handleUploadFile(file);
      const url = URL.createObjectURL(file);
      handleUploadImage(url);
      return false;
    },
    fileList,
    onChange: ({ fileList: newFileList }: UploadChangeParam) => {
      handleFileList?.(newFileList);

      if (newFileList[0]?.status === "done") {
        handleUploadFile(newFileList[0].originFileObj || null);

        if (newFileList[0].originFileObj) {
          const url = URL.createObjectURL(newFileList[0].originFileObj);

          handleUploadImage(url);
        }
      } else if (newFileList[0]?.status === "error") {
        message.error(`${newFileList[0]?.name} file upload failed.`);
      }
    },
    onRemove: (file: UploadFile<unknown>) => {
      const newFileList = fileList?.filter(
        (fileFromList) => fileFromList.uid !== file.uid
      );
      handleFileList?.(newFileList ?? []);
      handleUploadFile(null);
      handleUploadImage(null);
    },
  };

  return props;
};

export const setCompanyCustomerValue = (values: AddCompanyValues) => {
  return values?.is_supplier === "customer" || values?.is_supplier === "both";
};

export const setCompanySupplierValue = (values: AddCompanyValues) => {
  return values?.is_supplier === "supplier" || values?.is_supplier === "both";
};

export const renderCompanyRole = (company: CompanyItem) => {
  if (company.is_supplier && company.is_buyer) return "Both";
  if (company.is_supplier && !company.is_buyer) return "Supplier";
  if (company.is_buyer && !company.is_supplier) return "Customer";

  return "Unknown";
};

export const enum CompanySupplier {
  CUSTOMER = "customer",
  SUPPLIER = "supplier",
  BOTH = "both",
}

export const getSupplierStatus = (supplierValue: string) => {
  return {
    isCustomer: supplierValue === CompanySupplier.CUSTOMER,
    isSupplier: supplierValue === CompanySupplier.SUPPLIER,
  };
};

export const updateChildCompaniesToSetNewInformationField = (
  companies: CompaniesResponse,
  parentToShowChildren: CompanyItem | null,
  handleSetParentToShowChildren: (record: CompanyItem | null) => void
) => {
  const companyToUpdateInformationInModal = companies.results.find(
    (company) => company.id === parentToShowChildren?.id
  );

  handleSetParentToShowChildren(companyToUpdateInformationInModal ?? null);
};
