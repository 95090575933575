import { AdaptedStatuses, Card } from "shared/types";
import { ConfigDynamicTrackerFields } from "shared/api";

export const adaptStatusesForFiltering = (
  StatusDropdownValues: AdaptedStatuses[]
) => {
  const adaptedStatusesForFiltering = Object.values(StatusDropdownValues).map(
    (item) => ({
      text: item.label,
      value: item.value,
    })
  );
  return adaptedStatusesForFiltering;
};

export const adaptDurationString = (durationInSeconds: number) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const adaptedDuration = `${hours.toString().padStart(2, "")}h:${minutes
    .toString()
    .padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;

  return adaptedDuration;
};

export const adaptDynamicSelectValues = (
  card: ConfigDynamicTrackerFields | Card,
  fieldIndex: number
) => {
  const selectValues = card?.fields?.[fieldIndex]?.choices as string[];

  const adaptedValues = selectValues?.map((item) => ({
    value: item,
    label: item,
  }));

  return adaptedValues;
};
