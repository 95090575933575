import { useMemo } from "react";
import { ModalWidget } from "shared/api";

interface UseWidgetsDataProps {
  widgetsData: ModalWidget[];
  searchValue: string;
}

export const useWidgetsData = ({
  widgetsData,
  searchValue,
}: UseWidgetsDataProps) => {
  const filteredWidgets = useMemo(() => {
    return widgetsData
      .map((category) => ({
        category: category.category,
        widgets: category.widgets.filter((widget) =>
          widget.name?.toLowerCase().includes(searchValue.toLowerCase())
        ),
      }))
      .filter((category) => category.widgets.length > 0);
  }, [searchValue]);

  return { filteredWidgets };
};
