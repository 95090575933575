import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import {
  FormSelect,
  GlobalDatePicker,
  FormInput,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  useCreateMaterialReservationItemMutation,
  MaterialsItem,
} from "shared/api";

import { AdaptedValues, AddMaterialReservationItemValues } from "shared/types";
import { ModalSizeValue } from "shared/constants";

interface AddReservationModalProps {
  isOpenModal: boolean;
  setIsOpenAddReservationItemModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  adaptedMaterials: AdaptedValues[];
  selectedMaterial: MaterialsItem | null;
}

const AddReservationModal: FC<AddReservationModalProps> = ({
  isOpenModal,
  setIsOpenAddReservationItemModal,
  adaptedMaterials,
  selectedMaterial,
}) => {
  const { t } = useTranslation();
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddMaterialReservationItemValues>();

  const handlePickerChange = (_: DatePickerValue, dateString: string) => {
    setFieldValue("reservation_date", dateString);
  };

  const [api, contextHolder] = notification.useNotification();

  const [createMaterialReservationItem] =
    useCreateMaterialReservationItemMutation();

  const createMaterialReservationItemRequest = async () => {
    try {
      await createMaterialReservationItem({
        material: values.material!,
        quantity: values.quantity!,
        reservation_date: values.reservation_date!,
      }).unwrap();
      api.success({
        message: t("material-reservation.success-title"),
        description: t("material-reservation.success-add-desc"),
      });
      setIsOpenAddReservationItemModal(false);
      resetForm();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-reservation.error-title")
      );

      api.error({
        message: t("material-reservation.error-title"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("material-reservation.add-item-title")}
        open={isOpenModal}
        width={ModalSizeValue.PRIMARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={() => {
          resetForm();
          setIsOpenAddReservationItemModal(false);
        }}
        onOk={() => {
          createMaterialReservationItemRequest();
        }}
      >
        <Title level={5}>{t("material-reservation.material")}</Title>

        <FormSelect
          value={selectedMaterial?.id}
          placeholder={t("material-reservation.material-placeholder")}
          fieldName="material"
          options={adaptedMaterials}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.material?.toString()}
          isError={!!errors.material}
          multiple
          disabled
        />

        <Title level={5} className="mb-1.25">
          {t("material-reservation.reservation-date")}
        </Title>

        <GlobalDatePicker
          handlePickerChange={handlePickerChange}
          error={errors.reservation_date}
          isError={!!errors.reservation_date}
        />

        <Title level={5} className="mb-1.25">
          {t("material-reservation.quantity")}
        </Title>

        <FormInput
          value={values.quantity}
          fieldName="quantity"
          placeholder={t("material-reservation.quantity-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.quantity}
          isError={!!errors.quantity}
          numberType
        />
      </Modal>
    </>
  );
};

export { AddReservationModal };
