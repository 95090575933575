import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormSelect } from "shared/ui";
import { dynamicSpecificationTypes } from "shared/constants";
import { DynamicFormsValuesType } from "./constants";

interface TypeSelectProps {
  index: number;
  fieldIndex: number;
}

const TypeSelect: FC<TypeSelectProps> = ({ index, fieldIndex }) => {
  const { t } = useTranslation();

  const { handleBlur, setFieldValue, values } =
    useFormikContext<DynamicFormsValuesType>();

  return (
    <div className="w-[16%]">
      <Title level={5}>{t("material-category-management.type")}</Title>
      <FormSelect
        key={`sections[${index}].fields[${fieldIndex}].type`}
        value={values.sections[index].fields?.[fieldIndex]?.type}
        placeholder={t("material-category-management.type-placeholder")}
        fieldName={`sections[${index}].fields[${fieldIndex}].type`}
        options={dynamicSpecificationTypes}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
      />
    </div>
  );
};

export { TypeSelect };
