import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { useModal } from "shared/hooks";
import { FormikProvider, useFormik } from "formik";
import { noop } from "shared/helpers";
import { StandAloneFormik } from "shared/types";
import { addStandAloneSchema, updateStandAloneSchema } from "shared/schemas";
import {
  AddStandAloneItemDialog,
  EditStandAloneItemDialog,
  StandAloneTable,
} from "./ui";

const StandAlone = () => {
  const { t } = useTranslation("", { keyPrefix: "standAlone" });

  const [
    isVisibleAddStandAloneModal,
    showAddStandAloneModal,
    hideAddStandAloneItemModal,
  ] = useModal();

  const [
    isVisibleEditStandAloneModal,
    showEditStandAloneModal,
    hideEditStandAloneItemModal,
  ] = useModal();

  const validationSchema = useMemo(() => {
    if (isVisibleAddStandAloneModal) {
      return addStandAloneSchema;
    }

    return updateStandAloneSchema;
  }, [isVisibleAddStandAloneModal]);

  const standAloneFormik = useFormik<StandAloneFormik>({
    initialValues: {
      id: null,
      name: null,
      description: null,
      url: null,
      token: null,
      bucket: null,
      org: null,
    },
    validationSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={standAloneFormik}>
      <Page
        title={t("title")}
        buttonTitle={t("addItem")!}
        onClick={showAddStandAloneModal}
      >
        <StandAloneTable showEditStandAloneModal={showEditStandAloneModal} />

        <AddStandAloneItemDialog
          isOpen={isVisibleAddStandAloneModal}
          onCancel={hideAddStandAloneItemModal}
        />

        <EditStandAloneItemDialog
          isOpen={isVisibleEditStandAloneModal}
          onCancel={hideEditStandAloneItemModal}
        />
      </Page>
    </FormikProvider>
  );
};

export { StandAlone };
