import React, { FC } from "react";
import { notification, Table } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  useConfirmDeleteModal,
  useGetUsersColumns,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import { UserItem, useDeleteUserMutation, useGetUsersQuery } from "shared/api";

interface UsersTableProps {
  showEditUserModal: () => void;
}

const UsersTable: FC<UsersTableProps> = ({ showEditUserModal }) => {
  const { setValues } = useFormikContext<UserItem>();
  const { t } = useTranslation();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    data: allUsers,
    isFetching: isLoadingUsersFetching,
    isLoading: isLoadingAllUsers,
  } = useGetUsersQuery({
    ordering,
    offset,
    limit,
  });

  const editUserHandler = (record: UserItem) => {
    showEditUserModal();
    setValues({
      first_name: record.first_name,
      last_name: record.last_name,
      username: record.username,
      email: record.email,
      id: record.id,
    });
  };

  const [api, contextHolder] = notification.useNotification();

  const [deleteUser] = useDeleteUserMutation();

  const deleteUserHandler = async (record: UserItem) => {
    try {
      await deleteUser(record).unwrap();
      api.success({
        message: t("users-page.success-title"),
        description: t("users-page.success-delete-desc"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );
      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const showDeleteUserModal = useConfirmDeleteModal(
    t("modal-placeholders.delete-warning-message"),
    deleteUserHandler
  );

  const columns = useGetUsersColumns({
    t,
    editUserHandler,
    showDeleteUserModal,
    changeSort,
  });

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={allUsers?.results}
        rowKey={(obj) => obj.id!}
        loading={isLoadingUsersFetching || isLoadingAllUsers}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: allUsers?.count,
        }}
      />
    </>
  );
};

export { UsersTable };
