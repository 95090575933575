import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateOffset } from "shared/helpers";

const usePagination = (pageSizeDefault = 10) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);

  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const [limit, setLimit] = useState(pageSizeDefault);
  const [offset, setOffset] = useState(0);
  const isFirstPage = currentPage === 1;

  useEffect(() => {
    if (isFirstPage) {
      setOffset(0);
    } else {
      setOffset(calculateOffset(pageSize, currentPage));
    }
    setLimit(pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("page", currentPage.toString());
    navigate({ pathname: location.pathname, search: params.toString() });
  }, [currentPage, navigate, location.pathname]);

  return {
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    limit,
    offset,
    isFirstPage,
  };
};

export { usePagination };
