import { useMemo } from "react";
import GaugeComponent from "react-gauge-component";

interface SimpleGaugeProps {
  value: number;
  redLimit?: number;
  yellowLimit?: number;
}

export const SimpleGauge: React.FC<SimpleGaugeProps> = ({
  value,
  redLimit = 80,
  yellowLimit = 99,
}) => {
  const getGaugeColor = (value: number): string => {
    if (value <= redLimit) return "red";
    if (value <= yellowLimit) return "yellow";
    return "green";
  };

  const gaugeColor = useMemo(() => {
    return getGaugeColor(value);
  }, [value]);

  const simpleGaugeArc = {
    subArcs: [
      {
        color: gaugeColor,
        showTick: false,
      },
    ],
  };

  return (
    <GaugeComponent
      arc={simpleGaugeArc}
      value={value}
      labels={{
        // Hide value % symbol
        // valueLabel: { formatTextValue: (value) => `${value}` },
        tickLabels: {
          type: "outer",
          defaultTickValueConfig: {
            // Hide ticks % symbol
            // formatTextValue: (value: any) => `${value}`,
          },
          ticks: [{ value: 0 }, { value: 100 }],
        },
      }}
    />
  );
};
