export const mockLogisticsData = [
  { id: 1, order: "Completed", name: "Test" },
  { id: 2, order: "Active", name: "Test 2" },
  { id: 3, order: "Completed", name: "Test 3" },
  { id: 4, order: "Active", name: "Test 4" },
];

export const mockPackageData = [
  {
    itemName: "Package 1",
    items: ["testtesttesttesttest", "test2", "test3", "test4", "test5"],
  },
  {
    itemName: "Package 2",
    items: [],
  },
  {
    itemName: "Package 3",
    items: ["test11", "test12", "test13", "test14", "test15"],
  },
  {
    itemName: "Package 4",
    items: ["test16", "test17", "test18", "test19", "test20"],
  },
];

export const expandPackages = {
  query: "material,material_items",
};

export const logisticsTableData = {
  name: {
    title: "packagingTableColumns.name",
    dataIndex: "name",
    key: "name",
    width: "30%",
  },
  material: {
    title: "packagingTableColumns.material",
    dataIndex: "material",
    key: "material",
    width: "20%",
  },
  calcCountMaterialItems: {
    title: "packagingTableColumns.countMaterialItems",
    dataIndex: "calc_count_material_items",
    key: "calc_count_material_items",
    width: "10%",
  },
  materialItems: {
    title: "packagingTableColumns.materialItems",
    width: "30%",
  },
  situation: {
    title: "packagingTableColumns.situation",
    width: "30%",
  },
  actions: {
    title: "packagingTableColumns.actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
  },
};

export const trackingListExpand =
  "material_stock_batch.material,packaging,order_production_set";

export const trackingListOrdering = "-serial_number";

export const packageSituations = [
  { value: "NO", label: "NORMAL" },
  { value: "SE", label: "SENT" },
];
