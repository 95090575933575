import { UploadFile } from "antd";
import { useState } from "react";
import { generateUploadImageParams } from "shared/helpers";

interface UseCompanyImageUploadProps {
  documentList: UploadFile<any>[];
  handleDocumentList: (files: UploadFile[]) => void;
  closeModal: () => void;
}

const useCompanyImageUpload = ({
  documentList,
  handleDocumentList,
  closeModal,
}: UseCompanyImageUploadProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const handleUploadFile = (file: File | null) => setUploadedFile(file);

  const [previewLogoImage, setPreviewLogoImage] = useState<string | null>(null);
  const handleUploadImage = (image: string | null) =>
    setPreviewLogoImage(image);

  const uploadCompanyImageProps = generateUploadImageParams(
    handleUploadFile,
    handleUploadImage,
    handleDocumentList,
    documentList
  );

  const closeModalAndClearUploadedImage = () => {
    closeModal();
    setUploadedFile(null);
    setPreviewLogoImage(null);
    handleDocumentList([]);
  };

  return {
    uploadedFile,
    previewLogoImage,
    uploadCompanyImageProps,
    closeModalAndClearUploadedImage,
  };
};

export { useCompanyImageUpload };
