import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { useModal } from "shared/hooks";
import { FormikProvider, useFormik } from "formik";
import { noop } from "shared/helpers";
import { initialTemplateConfigFormikValues } from "shared/constants";
import {
  addTemplateConfigSchema,
  editTemplateConfigSchema,
} from "shared/schemas";
import { EditTemplateConfigModal, TemplatesConfigTable } from "./ui";
import { AddTemplateConfigModal } from "./ui/AddTemplateConfigModal";

interface TemplatesConfigProps {}

const TemplatesConfig: FC<TemplatesConfigProps> = () => {
  const { t } = useTranslation();

  const [isOpenAddModal, showAddModal, hideAddModal] = useModal();
  const [isOpenEditModal, showEditModal, hideEditModal] = useModal();

  const templateConfigValidation = useMemo(() => {
    if (isOpenAddModal) {
      return addTemplateConfigSchema;
    }
    return editTemplateConfigSchema;
  }, [isOpenAddModal]);

  const templateConfigFormik = useFormik({
    initialValues: initialTemplateConfigFormikValues,
    validationSchema: templateConfigValidation,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={templateConfigFormik}>
      <Page
        title={t("reports.title")}
        buttonTitle={t("reports.add-config")!}
        onClick={showAddModal}
      >
        <TemplatesConfigTable showEditModal={showEditModal} />

        <AddTemplateConfigModal
          isOpen={isOpenAddModal}
          onClose={hideAddModal}
        />

        <EditTemplateConfigModal
          isOpen={isOpenEditModal}
          onClose={hideEditModal}
        />
      </Page>
    </FormikProvider>
  );
};

export { TemplatesConfig };
