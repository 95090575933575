import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  qualityResultsApi,
  configurationsApi,
  reportsApi,
  dashboardsApi,
  authApi,
  usersApi,
  materialsApi,
  suppliersApi,
  productCardApi,
  ordersApi,
  packagingApi,
  standAloneApi,
} from "shared/api";
import { userReducer } from "./user/slice";

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["token"],
};

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [materialsApi.reducerPath]: materialsApi.reducer,
  [suppliersApi.reducerPath]: suppliersApi.reducer,
  [productCardApi.reducerPath]: productCardApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [packagingApi.reducerPath]: packagingApi.reducer,
  [qualityResultsApi.reducerPath]: qualityResultsApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [dashboardsApi.reducerPath]: dashboardsApi.reducer,
  [configurationsApi.reducerPath]: configurationsApi.reducer,
  [standAloneApi.reducerPath]: standAloneApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
