import { MaterialsItem, Widget, WidgetTypeEnum } from "shared/api";

export const enum MaterialTargetAlertFields {
  WIDGET_TYPE = "MATERIAL",
  ADAPT_MATERIAL_TARGET_NAME = "adaptMaterialTargetName",
  ADAPT_MATERIAL_TARGET_ID = "adaptMaterialTargeId",
  ADAPT_MATERIAL_TARGET_MATERIAL_CATEGORY = "adaptMaterialTargetMaterialCategory",
  ADAPT_MATERIAL_TARGET_MATERIAL_CATEGORY_ID = "adaptMaterialTargetMaterialCategoryId",
  ADAPT_MATERIAL_TARGET_CALC_TOTAL_REMAINING_QUANTITY = "adaptMaterialTargetCalcTotalRemainingQuantity",
  ADAPT_MATERIAL_TARGET_CALC_TOTAL_RESERVED_QUANTITY = "adaptMaterialTargetCalcTotalReservedQuantity",
  ADAPT_MATERIAL_TARGET_CALC_TOTAL_AVAILABLE_QUANTITY = "adaptMaterialTargetCalcTotalAvailableQuantity",
  ADAPT_MATERIAL_TARGET_CALC_TOTAL_MISSING_QUANTITY = "adaptMaterialTargetCalcTotalMissingQuantity",
  ADAPT_MATERIAL_TARGET_CALC_PERCENTAGE_REMAINING = "adaptMaterialTargetCalcPercentageRemaining",
  ADAPT_MATERIAL_TARGET_CALC_PERCENTAGE_AVAILABLE = "adaptMaterialTargetCalcPercentageAvailable",
  ADAPT_MATERIAL_TARGET_CALC_PERCENTAGE_MISSING = "adaptMaterialTargetCalcPercentageMissing",
}

export const adaptMaterialTargetColumnsValues = [
  { value: "adaptMaterialTargetName", label: "Name" },
  { value: "adaptMaterialTargetMaterialCategory", label: "Material Category" },
  {
    value: "adaptMaterialTargetCalcTotalRemainingQuantity",
    label: "Total Remaining Quantity",
  },
  {
    value: "adaptMaterialTargetCalcTotalReservedQuantity",
    label: "Total Reserved Quantity",
  },
  {
    value: "adaptMaterialTargetCalcTotalAvailableQuantity",
    label: "Total Available Quantity",
  },
  {
    value: "adaptMaterialTargetCalcTotalMissingQuantity",
    label: "Total Missing Quantity",
  },
  {
    value: "adaptMaterialTargetCalcPercentageRemaining",
    label: "Percentage Remaining",
  },
  {
    value: "adaptMaterialTargetCalcPercentageAvailable",
    label: "Percentage Available",
  },
  {
    value: "adaptMaterialTargetCalcPercentageMissing",
    label: "Percentage Missing",
  },
];

export const adaptMaterialTargetAlert = (materials: MaterialsItem[]) => {
  return materials.map((material) => {
    return {
      type: WidgetTypeEnum.MATERIAL_TARGET_ALERT,

      adaptMaterialTargetName: material?.name,
      adaptMaterialTargeId: material?.id,

      adaptMaterialTargetMaterialCategory: material?.material_category?.name,
      adaptMaterialTargetMaterialCategoryId: material?.material_category?.id,

      adaptMaterialTargetCalcTotalRemainingQuantity:
        material?.calc_total_remaining_quantity,

      adaptMaterialTargetCalcTotalReservedQuantity:
        material?.calc_total_reserved_quantity,

      adaptMaterialTargetCalcTotalAvailableQuantity:
        material?.calc_total_available_quantity,

      adaptMaterialTargetCalcTotalMissingQuantity:
        material?.calc_total_missing_quantity,

      adaptMaterialTargetCalcPercentageRemaining:
        material?.calc_percentage_remaining,

      adaptMaterialTargetCalcPercentageAvailable:
        material?.calc_percentage_available,

      adaptMaterialTargetCalcPercentageMissing:
        material?.calc_percentage_missing,
    };
  });
};

export const adaptMaterialTargetArticleFilterData = (widget: Widget) => {
  const processTemplatesFilter = widget?.filter?.find(
    (widget) => widget?.filter_parameter === "material_category__in"
  );

  const adapterProcessTemplates = (
    processTemplatesFilter?.filter_value as string
  )
    ?.split(",")
    .map((processTemplate) => Number(processTemplate));

  return adapterProcessTemplates;
};
