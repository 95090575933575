import { useFormik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetAppProcessTemplatesWithNoParamsQuery,
  useGetMachineByIdQuery,
  useUpdateMachineMutation,
} from "shared/api";
import { handleRequestError, noop } from "shared/helpers";
import { adaptProcessTemplates } from "shared/adapters";
import Title from "antd/es/typography/Title";
import { FormInput, FormSelect, HyperlinkPageHeader, Loader } from "shared/ui";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { AddOrEditMachineSchema } from "shared/schemas";

const MachinesHyperlink = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const { data: machine, isLoading: isLoadingMachine } = useGetMachineByIdQuery(
    { id: Number(id) },
    {
      skip: !id,
      refetchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: processTemplates, isLoading: isLoadingProcessTemplates } =
    useGetAppProcessTemplatesWithNoParamsQuery();

  const adaptedProcessTemplates = adaptProcessTemplates(processTemplates || []);

  const hyperlinkMachine = useFormik({
    initialValues: { ...machine },
    validationSchema: AddOrEditMachineSchema,
    enableReinitialize: true,
    onSubmit: noop,
  });

  const { values, errors, handleBlur, handleChange, setFieldValue, isValid } =
    hyperlinkMachine;

  const [updateMachine] = useUpdateMachineMutation();

  const updateMachineRequest = async () => {
    try {
      await updateMachine({
        id: values.id!,
        name: values.name!,
        process_template: values.process_template as unknown as number,
      }).unwrap();
      api.success({
        message: t("machines.success-title"),
        description: t("machines.success-update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("machines.error"),
        description: handleRequestError(error, t("machines.error")),
      });
    }
  };

  if (isLoadingMachine || isLoadingProcessTemplates) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}

      <div className="h-auto w-screen flex items-center justify-center flex-col ">
        <HyperlinkPageHeader
          title={`Machine Hyperlink - ${machine?.name}`}
          isValid={isValid}
          onOk={updateMachineRequest}
        />

        <Title level={5} className="!text-left mt-2">
          {t("machines.input-title")}
        </Title>

        <FormInput
          value={values.name!}
          fieldName="name"
          placeholder={t("machines.machine-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
          className="mt-3"
        />

        <Title level={5} className="!text-left">
          {t("machines.select-title")}
        </Title>

        <FormSelect
          value={values.process_template}
          placeholder={t("machines.process-type-placeholder")}
          fieldName="process_template"
          options={adaptedProcessTemplates}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.process_template?.toString()}
          isError={!!errors.process_template}
        />
      </div>
    </>
  );
};

export { MachinesHyperlink };
