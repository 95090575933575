import { Button, notification, UploadFile } from "antd";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterialCategoryForUpdate } from "shared/adapters";
import {
  useAddCompanyImageMutation,
  useGetFilteredCompanyQuery,
  useUpdateCompanyMutation,
} from "shared/api";
import { isSupplierOrBuyerSelectValues } from "shared/constants";
import {
  getSupplierStatus,
  handleRequestError,
  noop,
  setCompanyCustomerValue,
  setCompanySupplierValue,
} from "shared/helpers";
import {
  useCompaniesOptions,
  useCompanyImageUpload,
  useCompanyRole,
  useDebouncedTableFilter,
  useModal,
} from "shared/hooks";
import { EditCompanySchema } from "shared/schemas";
import { AddCompanyValues } from "shared/types";
import Title from "antd/es/typography/Title";
import {
  DynamicForm,
  FormInput,
  FormSelect,
  HyperlinkPageHeader,
  Loader,
} from "shared/ui";
import { useParams } from "react-router-dom";
import { CompanyLogo } from "./ui/CompanyLogo";
import { CategoriesSuppliedTable } from "./ui/CategoriesSuppliedTable";
import { AddProductCardModal, ProductCardTable } from "./ui";

const CompaniesHyperlink = () => {
  const { t } = useTranslation();
  const { companyID } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const [updateCompany] = useUpdateCompanyMutation();
  const [addCompanyImage] = useAddCompanyImageMutation();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);
  const [reorderingIds, setReorderingIds] = useState<number[]>([]);

  const {
    uploadedFile,
    previewLogoImage,
    uploadCompanyImageProps,
    closeModalAndClearUploadedImage,
  } = useCompanyImageUpload({
    documentList,
    handleDocumentList,
    closeModal: () => {},
  });

  const [
    visibleProductCardModal,
    showProductCardsModal,
    hideProductCardsModal,
  ] = useModal();

  const {
    debouncedValue: debouncedProductCardValue,
    handleFilterChange: handleProductCardValue,
    value: productCardValue,
    clearFilterValue: clearProductCardValue,
  } = useDebouncedTableFilter("", 400);

  const {
    adaptedProductCard,
    adaptedMaterialCategories,
    parentCompanies,
    productCards,
    materialCategories,
    unitsWithLabel,
    isLoadingProductCards,
  } = useCompaniesOptions({ productCardNameValue: debouncedProductCardValue });

  const { data: companies, isLoading: isLoadingCompany } =
    useGetFilteredCompanyQuery(
      {
        id: Number(companyID),
        expand: "categories_supplied.material_category.unit,child_companies",
      },
      {
        skip: !companyID,
      }
    );

  const companyItemToEdit = companies?.[0];
  const isLoading = isLoadingCompany || isLoadingProductCards;

  const addOrUpdateCompanyFormik = useFormik({
    initialValues: {
      ...companyItemToEdit,
      sections: companyItemToEdit?.config_dynamic_order_fields,
    },
    enableReinitialize: true,
    validationSchema: EditCompanySchema,
    onSubmit: noop,
  });

  const {
    values: companyValues,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    isValid,
  } = addOrUpdateCompanyFormik;

  const values = companyValues as unknown as AddCompanyValues;

  const { isCustomer, isSupplier } = getSupplierStatus(
    values?.is_supplier as string
  );

  useCompanyRole({ values, setFieldValue });

  const updateCompanyRequest = async (ids: number[]) => {
    try {
      await updateCompany({
        id: values.id!,
        name: values.name,
        address: values.address,
        payment_term_net_days: values.payment_term_net_days!,
        inco_terms: values.inco_terms,
        is_supplier: setCompanySupplierValue(values),
        is_buyer: setCompanyCustomerValue(values),
        config_dynamic_order_fields: values.sections!,
        product_cards: isSupplier ? [] : ids,
        evaluation_as_supplier: isCustomer
          ? null
          : values.evaluation_as_supplier!,
        categories_supplied: adaptMaterialCategoryForUpdate(values),
        parent_company: values.parent_company,
        child_companies: values.child_companies?.map((company) => company.id),
        vat_number: values.vat_number?.length ? values.vat_number : null,
        email: values.email?.length ? values?.email : null,
        phone: values.phone?.length ? values.phone : null,
        contact_name: values.contact_name?.length ? values.contact_name : null,
        information: values.information?.length ? values.information : null,
      }).unwrap();
      api.success({
        message: t("companies.success-title"),
        description: t("companies.success-update-msg"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const uploadCompanyImage = async () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      try {
        await addCompanyImage({
          id: values.id!,
          image: formData,
        }).unwrap();
      } catch (error) {
        api.error({
          message: t("users-page.error-title"),
          description: handleRequestError(error, t("users-page.error-title")),
        });
      } finally {
        closeModalAndClearUploadedImage();
      }
    }
  };

  const updateCompanyHandler = async (): Promise<void> => {
    try {
      if (uploadedFile) {
        await uploadCompanyImage();
      }

      await updateCompanyRequest(reorderingIds);
    } catch (error) {
      api.error({
        message: t("users-page.error-title"),
        description: handleRequestError(error, t("users-page.error-title")),
      });
    }
  };

  if (isLoading) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateCompanyFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`${companyItemToEdit?.name}`}
            isValid={isValid}
            onOk={updateCompanyHandler}
          />
          <div className="w-full p-2">
            <CompanyLogo
              documentList={documentList}
              uploadCompanyImageProps={uploadCompanyImageProps}
              previewLogoImage={previewLogoImage}
            />

            <Title level={5}>{t("companies.parentCompany")}</Title>
            <FormSelect
              value={values?.parent_company}
              placeholder={t("companies.parent-company-placeholder")}
              fieldName="parent_company"
              options={parentCompanies}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              showSearch
            />

            <Title level={5} className="mb-1.25">
              {t("companies.name")}
            </Title>

            <FormInput
              value={values?.name}
              fieldName="name"
              placeholder={t("companies.name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.email")}
            </Title>

            <FormInput
              value={values?.email}
              fieldName="email"
              placeholder={t("companies.email-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.email}
              isError={!!errors.email}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.phone")}
            </Title>

            <FormInput
              value={values?.phone}
              fieldName="phone"
              placeholder={t("companies.phone-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.phone}
              isError={!!errors.phone}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.vat-number")}
            </Title>

            <FormInput
              value={values?.vat_number}
              fieldName="vat_number"
              placeholder={t("companies.vat-number-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.vat_number}
              isError={!!errors.vat_number}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.address")}
            </Title>

            <FormInput
              value={values?.address}
              fieldName="address"
              placeholder={t("companies.address-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.address}
              isError={!!errors.address}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.payment-term")}
            </Title>

            <FormInput
              value={values?.payment_term_net_days!}
              fieldName="payment_term_net_days"
              placeholder={t("companies.payment-term-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.payment_term_net_days}
              isError={!!errors.payment_term_net_days}
              numberType
            />

            <Title level={5} className="mb-1.25">
              {t("companies.inco-terms")}
            </Title>

            <FormInput
              value={values?.inco_terms?.toUpperCase()}
              fieldName="inco_terms"
              placeholder={t("companies.inco-terms-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.inco_terms}
              isError={!!errors.inco_terms}
            />

            <Title level={5}>
              {`${t("companies.is-supplier")} / ${t("companies.is-customer")}`}
            </Title>

            <FormSelect
              value={values?.is_supplier || null}
              placeholder={t("companies.is-supplier-placeholder")}
              fieldName="is_supplier"
              options={isSupplierOrBuyerSelectValues}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors.is_supplier?.toString()}
              isError={!!errors.is_supplier}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.evaluation_as_supplier")}
            </Title>

            <FormInput
              value={isCustomer ? null : values?.evaluation_as_supplier!}
              fieldName="evaluation_as_supplier"
              placeholder={t("companies.evaluation_as_supplier")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.evaluation_as_supplier}
              isError={!!errors.evaluation_as_supplier}
              numberType
              disabled={isCustomer}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.contact-name")}
            </Title>

            <FormInput
              value={values.contact_name}
              fieldName="contact_name"
              placeholder={t("companies.contact-name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <Title level={5} className="mb-1.25">
              {t("companies.information")}
            </Title>

            <FormInput
              value={values.information}
              fieldName="information"
              placeholder={t("companies.information-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <CategoriesSuppliedTable
              adaptedMaterialCategories={adaptedMaterialCategories}
              materialCategories={materialCategories}
            />

            {!isSupplier && (
              <>
                <Button
                  type="primary"
                  className="btn-primary--dark mt-2.5"
                  onClick={showProductCardsModal}
                >
                  {t("companies.add-product-card")}
                </Button>

                <AddProductCardModal
                  isOpenModal={visibleProductCardModal}
                  hideProductCardsModal={hideProductCardsModal}
                  adaptedProductCard={adaptedProductCard}
                  clearProductCardValue={clearProductCardValue}
                  handleProductCardValue={handleProductCardValue}
                  productCardValue={productCardValue}
                />
              </>
            )}

            {!isSupplier && values?.product_cards?.length ? (
              <ProductCardTable
                productCards={productCards}
                setReorderingIds={setReorderingIds}
              />
            ) : null}

            <DynamicForm adaptedDimensionUnits={unitsWithLabel} />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { CompaniesHyperlink };
