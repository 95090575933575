import Table from "antd/es/table";
import { FormikProvider, useFormik } from "formik";
import { AddStockMaterialModal } from "pages/SupplyChain/MaterialManagement/ui";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MaterialsItem,
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  StockMaterialItem,
  useDeleteMaterialStockBatchMutation,
  useGetMaterialStockBatchesQuery,
} from "shared/api";
import {
  expandStockMaterial,
  materialManagementInitialValues,
} from "shared/constants";
import { handleRequestError, noop } from "shared/helpers";
import {
  useConfirmDeleteModal,
  useDateFilters,
  useDebouncedTableFilter,
  useModal,
  usePagination,
  useStockManagementData,
  useStockManagementsColumns,
  useTableSorting,
} from "shared/hooks";
import { AddOrEditStockMaterialSchema } from "shared/schemas";
import { SubmitCreateMaterialValues } from "shared/types";
import { Alert, notification } from "antd";
import { RenderClearFiltersButton } from "shared/ui";
import { StockManagementHeader } from "./StockManagementHeader";
import { UpdatedEditStockMaterialModal } from "./UpdatedEditStockManagementItemModa";

interface UpdatedMaterialStockManagementProps {
  selectedMaterial: MaterialsItem | null;
  handleMaterialItemsDetails: (
    materialStockBatchMaterialId: MaterialStockItem
  ) => void;
}

const UpdatedMaterialStockManagement: FC<
  UpdatedMaterialStockManagementProps
> = ({ selectedMaterial, handleMaterialItemsDetails }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [deleteMaterialStockBatch] = useDeleteMaterialStockBatchMutation();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    usePagination();

  const { ordering, changeSort } = useTableSorting();

  const [isOpenAddStockMaterial, showAddStockModal, hideAddStockModal] =
    useModal();

  const [isOpenEditStockMaterial, showEditStockModal, hideEditStockModal] =
    useModal();

  const [itemToEdit, setItemToEdit] =
    useState<MaterialStockBatchItemResponse | null>(null);

  const showEditModalAndSetItemToEdit = (
    item: MaterialStockBatchItemResponse
  ) => {
    setItemToEdit(item);
    showEditStockModal();
  };

  const { companiesData } = useStockManagementData();

  const addOrEditStockManagementFormik = useFormik<SubmitCreateMaterialValues>({
    initialValues: materialManagementInitialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: AddOrEditStockMaterialSchema,
    onSubmit: noop,
  });

  const {
    debouncedValue: debouncedBatchCode,
    handleFilterChange: handleBatchCode,
    value: batchCodeValue,
    clearFilterValue: clearBatchCode,
  } = useDebouncedTableFilter("", 400);

  const {
    dateGreater: deliveryDateGreater,
    dateLesser: deliveryDateLesser,
    handleDateGreater: handlePickerChangeGreater,
    handleDateLesser: handlePickerChangeLesser,
    clearDates,
    isDataFilterActive: isActiveDeliveryDateFilter,
  } = useDateFilters();

  const {
    debouncedValue: debouncedSupplierValue,
    handleFilterChange: handleSupplierValue,
    value: supplierValue,
    clearFilterValue: clearSupplierValue,
  } = useDebouncedTableFilter("", 400);

  const {
    data: stockMaterials,
    isLoading: isLoadingStockMaterials,
    isFetching: isFetchingStockMaterials,
  } = useGetMaterialStockBatchesQuery(
    {
      limit,
      offset,
      expand: expandStockMaterial,
      ordering,
      batch_code__icontains: debouncedBatchCode,
      delivery_date__gte: deliveryDateGreater || "",
      delivery_date__lte: deliveryDateLesser || "",
      supplier__name__icontains: debouncedSupplierValue,
      material: selectedMaterial?.id,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: !selectedMaterial,
    }
  );

  const deleteMaterialStockBatchHandler = async (record: StockMaterialItem) => {
    try {
      await deleteMaterialStockBatch(record).unwrap();
      api.success({
        message: t("stock-material.success-title"),
        description: t("stock-material.success-delete-desc"),
      });
    } catch (error) {
      api.error({
        message: t("stock-material.error-title"),
        description: handleRequestError(error, t("stock-material.error-title")),
      });
    }
  };

  const showDeleteMaterialStockModal = useConfirmDeleteModal(
    t("stock-material.delete-modal-msg"),
    deleteMaterialStockBatchHandler
  );

  const columns = useStockManagementsColumns({
    t,
    changeSort,
    batchCodeValue,
    clearBatchCode,
    handleBatchCode,
    supplierValue,
    handleSupplierValue,
    clearSupplierValue,
    isActiveDeliveryDateFilter,
    handlePickerChangeGreater,
    handlePickerChangeLesser,
    showEditModalAndSetItemToEdit,
    handleMaterialItemsDetails,
    showDeleteMaterialStockModal,
  });

  const clearAllFilters = () => {
    clearBatchCode();
    clearSupplierValue();
    clearDates();
  };

  const renderButtonAndClearAllFilters = () => {
    if (batchCodeValue && batchCodeValue.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    if (supplierValue && supplierValue.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    if (deliveryDateGreater && deliveryDateGreater.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    if (deliveryDateLesser && deliveryDateLesser.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    return null;
  };

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrEditStockManagementFormik}>
        <StockManagementHeader
          selectedMaterial={selectedMaterial}
          showAddStockModal={showAddStockModal}
        />

        {!stockMaterials?.results.length && (
          <Alert
            type="warning"
            showIcon
            message={t("stock-material.no-stock-items")}
            className="mb-5"
          />
        )}

        {renderButtonAndClearAllFilters()}

        <Table
          loading={isLoadingStockMaterials || isFetchingStockMaterials}
          columns={columns}
          dataSource={stockMaterials?.results}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: stockMaterials?.count,
          }}
        />

        <AddStockMaterialModal
          isOpenAddStockMaterial={isOpenAddStockMaterial}
          hideAddStockModal={hideAddStockModal}
          selectedStockMaterial={selectedMaterial}
          companiesData={companiesData}
        />

        <UpdatedEditStockMaterialModal
          isOpenEditStockMaterial={isOpenEditStockMaterial}
          hideEditStockModal={hideEditStockModal}
          itemToEdit={itemToEdit}
          companiesData={companiesData}
        />
      </FormikProvider>
    </>
  );
};

export { UpdatedMaterialStockManagement };
