import { Button, Space } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import { FieldArray, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormInput, FormSelect, Loader, TableButton } from "shared/ui";
import { useAddSuppliers } from "shared/hooks";
import { SubmitCreateMaterialValues } from "shared/types";
import { currencies } from "shared/constants";

const CompaniesSelect = () => {
  const { values, handleBlur, setFieldValue, handleChange } =
    useFormikContext<SubmitCreateMaterialValues>();

  const { t } = useTranslation();

  const {
    unselectedCompanies,
    supplierIds,
    isLoading,
    companiesAdapted,
    initialSupplierDynamicRow,
  } = useAddSuppliers({
    values,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Space direction="vertical" className="w-full">
      <Title level={5} className="w-full mt-1.25 !mb-0">
        {t("material-management.suppliers")}
      </Title>

      <FieldArray
        name="suppliers"
        render={(arrayHelpers) => (
          <div>
            {supplierIds.map((supplier, index) => {
              const supplierIdValue =
                typeof values?.suppliers?.[index]?.supplier === "object"
                  ? values?.suppliers?.[index]?.supplier?.id || null
                  : values?.suppliers?.[index]?.supplier || null;

              const supplierObject = companiesAdapted.find(
                (company) => company.value === supplierIdValue
              );

              const supplierFieldName = `suppliers.${index}.supplier`;

              const costPerUnitCurrency =
                values?.suppliers?.[index]?.cost_per_unit_currency || null;
              const costPerUnitCurrencyFieldName = `suppliers.${index}.cost_per_unit_currency`;

              const costPerUnit = values?.suppliers?.[index]?.cost_per_unit;

              const costPerUnitFieldName = `suppliers.${index}.cost_per_unit`;

              const showCurrencyError =
                costPerUnit !== null &&
                costPerUnit !== undefined &&
                !costPerUnitCurrency;

              const showCostError =
                !!costPerUnitCurrency && costPerUnit === null;

              return (
                <div
                  key={index}
                  className="w-full flex item-center justify-between gap-x-3"
                >
                  <div className="w-[30%]">
                    <Title
                      level={5}
                      className="w-full mt-1.25 !mb-0 !text-[14px]"
                    >
                      {t("material-management.suppliers")}
                    </Title>

                    <FormSelect
                      value={supplierObject}
                      placeholder={t(
                        "material-management.suppliers-placeholder"
                      )}
                      fieldName={supplierFieldName}
                      options={unselectedCompanies}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      showSearch
                      isLoading={isLoading}
                      isError={!supplierIdValue}
                      error={t("material-management.select-supplier")!}
                    />
                  </div>

                  <div className="w-[30%]">
                    <Title
                      level={5}
                      className="w-full mt-1.25 !mb-0  !text-[14px]"
                    >
                      {t("material-management.cost-per-unit-currency")}
                    </Title>

                    <FormSelect
                      value={costPerUnitCurrency}
                      placeholder={t(
                        "material-management.cost-per-unit-currency"
                      )}
                      fieldName={costPerUnitCurrencyFieldName}
                      options={currencies}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isError={showCurrencyError}
                      error={t("material-management.select-cur-per-unit")!}
                    />
                  </div>

                  <div className="w-[30%]">
                    <Title
                      level={5}
                      className="w-full mt-1.25 !mb-0  !text-[14px]"
                    >
                      {t("material-management.cost-per-unit")}
                    </Title>

                    <FormInput
                      value={costPerUnit}
                      placeholder={t("material-management.cost-per-unit")}
                      fieldName={costPerUnitFieldName}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      numberType
                      isError={showCostError}
                      error={t("material-management.select-currency")!}
                    />
                  </div>

                  <div className="w-[5%] flex items-center justify-center">
                    <TableButton
                      type="delete"
                      onClick={() => arrayHelpers.remove(index)}
                      className="mt-4"
                    />
                  </div>
                </div>
              );
            })}
            <Button
              type="primary"
              className="btn-primary--dark mt-3"
              onClick={() => arrayHelpers.push(initialSupplierDynamicRow)}
            >
              {t("material-management.add-supplier")}
            </Button>
          </div>
        )}
      />
    </Space>
  );
};

export { CompaniesSelect };
