import { Alert, Modal, notification } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { handleRequestError } from "shared/helpers";
import {
  MaterialItem,
  PackagingItem,
  useGenerateMaterialItemReportMutation,
} from "shared/api";
import { ModalSizeValue } from "shared/constants";
import { PackageItem } from "./PackageItem";

interface ExportLabelModalProps {
  isOpenModal: boolean;
  hideOpenExportLabelModal: () => void;
  packageMaterialItems: PackagingItem | null;
}

const ExportLabelModal: FC<ExportLabelModalProps> = ({
  isOpenModal,
  hideOpenExportLabelModal,
  packageMaterialItems,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const { t } = useTranslation();

  const [generateMaterialItemReport] = useGenerateMaterialItemReportMutation();

  const createMaterialItemReport = async (record: MaterialItem) => {
    try {
      const response = await generateMaterialItemReport({
        id: record.id,
        dynamic_tracker_fields: record.dynamic_tracker_fields,
        material_stock_batch: record.material_stock_batch,
        order_production_set_position: record.order_production_set_position!,
        packaging: record.packaging,
        production_number: record.production_number!,
        quantity: record.quantity,
        serial_number: record.serial_number,
        status: record.status,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      const description = handleRequestError(error, t("packaging.error"));
      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("packaging.generate-export-label")}
        open={isOpenModal}
        width={ModalSizeValue.MEDIUM}
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        onCancel={hideOpenExportLabelModal}
        onOk={() => {}}
      >
        {!packageMaterialItems?.material_items?.length && (
          <Alert message={t("packaging.no-items")} type="warning" showIcon />
        )}

        {packageMaterialItems?.material_items?.map((item) => (
          <PackageItem
            key={item.serial_number}
            title={item.serial_number}
            onClick={() => createMaterialItemReport(item)}
          />
        ))}
      </Modal>
    </>
  );
};

export { ExportLabelModal };
