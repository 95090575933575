import { useMemo } from "react";
import { DynamicOutputMaterialData } from "shared/api";

const useDynamicDataComparison = (dynamicData: DynamicOutputMaterialData[]) => {
  const areAllFieldsSame = (data: DynamicOutputMaterialData[]) => {
    if (data?.length === 0) return true;

    const firstItem = JSON.stringify(data?.[0]);
    return data?.every((item) => JSON.stringify(item) === firstItem);
  };

  const getChangedItemsMessage = (data: DynamicOutputMaterialData[]) => {
    const firstItem = JSON.stringify(data?.[0]);
    const changedItems = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < data?.length; i++) {
      if (JSON.stringify(data[i]) !== firstItem) {
        changedItems.push(i + 1);
      }
    }

    if (changedItems.length === 0) {
      return `All card are the same as the master card. Count of items: ${dynamicData?.length}`;
    }

    return `You added changes to items: ${changedItems.join(
      ", "
    )}. All other cards are the same as the master card`;
  };

  const message = useMemo(() => {
    return areAllFieldsSame(dynamicData)
      ? `All card are the same as the master card. Count of items: ${dynamicData?.length}`
      : getChangedItemsMessage(dynamicData);
  }, [dynamicData]);

  const isAllCardsAreTheSame = useMemo(() => {
    return (
      message ===
      `All card are the same as the master card. Count of items: ${dynamicData?.length}`
    );
  }, [message]);

  return { message, isAllCardsAreTheSame };
};

export { useDynamicDataComparison };
