import { OrderingDirection } from "shared/constants";
import { useState } from "react";

interface SortingOptions {
  initialOrdering?: string;
  initialDirection?: OrderingDirection;
}

const useTableSorting = ({
  initialOrdering = "",
  initialDirection = OrderingDirection.ASC,
}: SortingOptions = {}) => {
  const [ordering, setOrdering] = useState<string>(initialOrdering);
  const [sortType, setSortType] = useState<OrderingDirection>(initialDirection);

  const changeSort = (ascendOrdering: string, descendOrdering: string) => {
    const isAscDirection = sortType === OrderingDirection.ASC;

    setSortType(
      isAscDirection ? OrderingDirection.DESC : OrderingDirection.ASC
    );
    setOrdering(isAscDirection ? ascendOrdering : descendOrdering);
  };

  return { ordering, sortType, changeSort };
};

export { useTableSorting };
