import React, { FC } from "react";
import { Button, Popover } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { CustomerLogo } from "./CustomerLogo";

interface SidebarHeaderProps {
  openMinimizedSidebar: () => void;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({ openMinimizedSidebar }) => {
  return (
    <div
      className="
      w-60 h-17.5 border-b 
      border-gray-200 pt-1.25 pr-6 
      pb-0 pl-6 text-black-100 bg-white 
      text-[20px] flex items-center 
      relative justify-between sidebar
    "
    >
      <CustomerLogo />

      <Popover content="Minimize Sidebar" placement="bottom">
        <Button
          onClick={openMinimizedSidebar}
          className="
            bg-white rounded-full !m-0 
            p-0 w-8 h-8 flex items-center 
            justify-center mb-2 absolute 
            -right-3 top-4
          "
        >
          <LeftOutlined className="p-0" />
        </Button>
      </Popover>
    </div>
  );
};

export { SidebarHeader };
