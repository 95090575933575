// import { useMemo, useState } from "react";

// interface ProductCardCheckBox {
//   label: string;
//   value: number;
//   key?: number;
// }

// interface UseProductCardsKeysProps {
//   productCards: number[];
//   setFieldValue: (
//     field: string,
//     value: any,
//     shouldValidate?: boolean | undefined
//   ) => void;
//   hideProductCardsModal: () => void;
//   adaptedProductCard: ProductCardCheckBox[];
// }

// export const useProductCardsKeys = ({
//   productCards,
//   setFieldValue,
//   hideProductCardsModal,
//   adaptedProductCard,
// }: UseProductCardsKeysProps) => {
//   const fieldName = "product_cards";

//   const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
//     productCards || []
//   );

//   const addProductCards = () => {
//     setFieldValue(fieldName, selectedKeys);

//     hideProductCardsModal();
//   };

//   const rowSelection = {
//     selectedRowKeys: selectedKeys,
//     onChange: (selectedRowKeys: React.Key[]) => {
//       setSelectedKeys(selectedRowKeys);
//     },
//     getCheckboxProps: (record: ProductCardCheckBox) => ({
//       name: record.label,
//     }),
//   };

//   const selectedProductCards = useMemo(() => {
//     return adaptedProductCard.filter((card) =>
//       selectedKeys.includes(card.value)
//     );
//   }, [selectedKeys, adaptedProductCard]);

//   return { selectedKeys, rowSelection, selectedProductCards, addProductCards };
// };

import { useMemo, useState } from "react";

interface ProductCardCheckBox {
  label: string;
  value: number;
  key?: number;
}

interface UseProductCardsKeysProps {
  productCards: number[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  hideProductCardsModal: () => void;
  adaptedProductCard: ProductCardCheckBox[];
}

export const useProductCardsKeys = ({
  productCards,
  setFieldValue,
  hideProductCardsModal,
  adaptedProductCard,
}: UseProductCardsKeysProps) => {
  const fieldName = "product_cards";

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
    productCards || []
  );

  const addProductCards = () => {
    setFieldValue(fieldName, selectedKeys);
    hideProductCardsModal();
  };

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      const allKeys = adaptedProductCard.map((card) => card.value);
      setSelectedKeys(allKeys);
    } else {
      setSelectedKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys);
    },
    onSelectAll: (selected: boolean) => {
      onSelectAll(selected);
    },
    getCheckboxProps: (record: ProductCardCheckBox) => ({
      name: record.label,
    }),
  };

  const selectedProductCards = useMemo(() => {
    return adaptedProductCard.filter((card) =>
      selectedKeys.includes(card.value)
    );
  }, [selectedKeys, adaptedProductCard]);

  return { selectedKeys, rowSelection, selectedProductCards, addProductCards };
};
