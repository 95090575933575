import { NotificationInstance } from "antd/es/notification/interface";
import {
  OrderCardStatuses,
  OrderPlanStatuses,
  ConsumptionTypes,
  DynamicFieldsEnum,
} from "shared/constants";
import { DynamicValues, AddOrUpdateOrderValues } from "shared/types";
import {
  OrderItem,
  OrderPlan,
  OrderProcessStepItem,
  OrderProductionSetResponse,
  OrderStatuses,
  OrderStepTrackerResponseItem,
  ProductionInputMaterial,
  ProductionOutputMaterial,
} from "shared/api";
import { Routes } from "shared/routers";
import { TFunction } from "i18next";

let maxQuantity = Number.NEGATIVE_INFINITY;

export const findMaxQuantities = (orders: OrderItem[]) => {
  orders?.forEach((item) => {
    const quantity = item.quantity_expected;
    maxQuantity = Math.max(maxQuantity, quantity);
  });

  return maxQuantity;
};

export const renderStatuses = (item: OrderItem) => {
  if (item.status === OrderStatuses.PO) return "PO order";
  if (item.status === OrderStatuses.AP) return "Approved";
  if (item.status === OrderStatuses.PE) return "Pending";
  if (item.status === OrderStatuses.AC) return "Active";
  if (item.status === OrderStatuses.CO) return "Completed";
  if (item.status === OrderStatuses.DE) return "Delivered";

  return "Unknown status";
};

export const renderOrderStatuses = (item?: OrderStepTrackerResponseItem) => {
  if (item?.status === OrderCardStatuses.PENDING) return "Pending";
  if (item?.status === OrderCardStatuses.IN_PROGRESS) return "In Progress";
  if (item?.status === OrderCardStatuses.FINISHED) return "Finished";

  return "Unknown status";
};

export const orderPlanStatuses: Record<OrderPlanStatuses, string> = {
  [OrderPlanStatuses.ACTIVE]: "Active",
  [OrderPlanStatuses.COMPLETED]: "Completed",
  [OrderPlanStatuses.DRAFT]: "Draft",
};

export const renderTimelineOrderStatuses = (item: OrderProcessStepItem) => {
  if (item.order.status === OrderStatuses.PO) return "PO order";
  if (item.order.status === OrderStatuses.AP) return "Approved";
  if (item.order.status === OrderStatuses.PE) return "Pending";
  if (item.order.status === OrderStatuses.AC) return "Active";
  if (item.order.status === OrderStatuses.CO) return "Completed";
  if (item.order.status === OrderStatuses.DE) return "Delivered";

  return "Unknown status";
};

export const renderTimelineProcessStatuses = (item: OrderProcessStepItem) => {
  if (item?.status === OrderCardStatuses.PENDING) return "Pending";
  if (item?.status === OrderCardStatuses.IN_PROGRESS) return "In Progress";
  if (item?.status === OrderCardStatuses.FINISHED) return "Finished";

  return "Unknown status";
};

export const renderOrderPlanStatus = (item: OrderPlan) => {
  const status = orderPlanStatuses[item?.status as OrderPlanStatuses];
  return status || "Unknown status";
};

export const calculateUnitIds = (
  itemTrackerFields: DynamicValues[],
  dynamicFieldsEnum: typeof DynamicFieldsEnum,
  setUnitIds: React.Dispatch<React.SetStateAction<number[] | []>>
) => {
  if (itemTrackerFields) {
    const dynamicUnitIds = itemTrackerFields?.map((card) =>
      card.fields.filter(
        (field: any) => field.type === dynamicFieldsEnum.CHOICES
      )
    );
    setUnitIds(
      dynamicUnitIds.flat().map((item) => {
        return item.unit;
      })
    );
  } else {
    setUnitIds([]);
  }
};

export const checkValidOrderDate = (values: AddOrUpdateOrderValues) => {
  const deliveryDate = new Date(values?.wanted_delivery_datetime as string);
  const productionDate = new Date(values?.production_datetime as string);

  const isDeliveryDateBeforeProductionDate = productionDate <= deliveryDate;

  return isDeliveryDateBeforeProductionDate;
};

export const handleValidOrderDate = (
  isOpenModal: boolean,
  values: AddOrUpdateOrderValues,
  handleValidDate: (isValid: boolean) => void,
  api: NotificationInstance
) => {
  const { wanted_delivery_datetime, production_datetime } = values;

  const isEnteredDateValues =
    wanted_delivery_datetime && production_datetime && isOpenModal;

  if (isEnteredDateValues) {
    const isDeliveryDateBeforeProductionDate = checkValidOrderDate(values);

    handleValidDate(isDeliveryDateBeforeProductionDate);

    if (!isDeliveryDateBeforeProductionDate) {
      api.error({
        message: "Error!",
        description: "Delivery date should be greater than production date!",
      });
    }
  }
};

export const getConsumptionType = (consumptionType?: ConsumptionTypes) => {
  const isManual = consumptionType === ConsumptionTypes.MA;
  const isFixed = consumptionType === ConsumptionTypes.FI;
  const isProportional = consumptionType === ConsumptionTypes.PR;

  return { isFixed, isManual, isProportional };
};

export const renderDynamicQuantity = (
  inputMaterial: ProductionInputMaterial,
  consumptionType?: ConsumptionTypes,
  quantityValue?: number
) => {
  const { isFixed, isProportional } = getConsumptionType(consumptionType);

  const {
    consumptionProportionalMaterial,
    consumptionFixedValue,
    consumptionProportionalValue,
  } = inputMaterial;

  if (isFixed) {
    return consumptionFixedValue;
  }

  if (isProportional) {
    return `${consumptionProportionalValue} * ${consumptionProportionalMaterial?.name}`;
  }

  return quantityValue;
};

export const returnOrderProductionSetData = (
  orderProductionSet?: OrderProductionSetResponse
) => ({
  setNumber: orderProductionSet?.set_number,
  subSetNumber: orderProductionSet?.sub_set_number,
  orderProcessStepTracker: orderProductionSet?.order_process_step_tracker,
  materialsConsumed: orderProductionSet?.materials_consumed ?? [],
});

export const getTrackingListOptions = (
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  return {
    orderId: orderStepTrackerResponseItem?.order?.id as number,

    orderCode: orderStepTrackerResponseItem?.order?.order_code as string,

    outputMaterials:
      orderStepTrackerResponseItem?.process_step?.output_materials,

    firstMaterialFromList:
      orderStepTrackerResponseItem?.process_step?.output_materials?.[0]
        ?.material?.id,
  };
};

export const getProductionOutputMaterialInfo = (
  t: TFunction,
  index: number,
  outputMaterials: ProductionOutputMaterial[],
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const materialName =
    orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
      ?.material?.name;

  const nPackagingItems =
    orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
      ?.material.n_packaging_items;

  const nPackagingString =
    nPackagingItems && nPackagingItems === 1
      ? t("packagingItem")
      : t("packagingItems", { nPackagingItems });

  const materialTitle = `${materialName} ${nPackagingString}`;

  const materialLink =
    `${Routes.materialManagement.hyperlink}/${outputMaterials?.[index]?.id}`?.replace(
      ":id/",
      ""
    );

  const isVisibleEditModeButton =
    outputMaterials?.[index]?.dynamicData?.length > 1;

  return {
    materialLink,
    materialTitle,
    isVisibleEditModeButton,
  };
};
