export const articleFlowSortByOptions = [
  { value: "timestamp", label: "Timestamp Asc" },
  { value: "-timestamp", label: "Timestamp Desc" },
];

export const materialConsumptionSortByOptions = [
  { value: "consumed_datetime", label: "Consumed Datetime Asc" },
  { value: "-consumed_datetime", label: "Consumed Datetime Desc" },
];

export const orderStatusSortByOptions = [
  { value: "production_datetime", label: "Production Datetime Asc" },
  { value: "-production_datetime", label: "Production Datetime Desc" },
  { value: "wanted_delivery_datetime", label: "Wanted Delivery Datetime Asc" },
  {
    value: "-wanted_delivery_datetime",
    label: "Wanted Delivery Datetime Desc",
  },
];

export const machinesXSortByOptions = [
  { value: "name", label: "Name Asc" },
  { value: "-name", label: "Name Desc" },
];

export const adaptMaterialTargetSortByOptions = [
  { value: "calc_total_remaining_quantity", label: "Remaining Quantity Asc" },
  { value: "-calc_total_remaining_quantity", label: "Remaining Quantity Desc" },
  { value: "calc_total_reserved_quantity", label: "Reserved Quantity Asc" },
  { value: "-calc_total_reserved_quantity", label: "Reserved Quantity Desc" },
  { value: "calc_total_available_quantity", label: "Available Quantity Asc" },
  { value: "-calc_total_available_quantity", label: "Available Quantity Desc" },
  { value: "calc_total_missing_quantity", label: "Missing Quantity Asc" },
  { value: "-calc_total_missing_quantity", label: "Missing Quantity Desc" },
  { value: "calc_percentage_remaining", label: "Percentage Remaining Asc" },
  { value: "-calc_percentage_remaining", label: "Percentage Remaining Desc" },
  { value: "calc_percentage_available", label: "Percentage Available Asc" },
  { value: "-calc_percentage_available", label: "Percentage Available Desc" },
  { value: "calc_percentage_missing", label: "Percentage Missing Asc" },
  { value: "-calc_percentage_missing", label: "Percentage Missing Desc" },
];

export const widgetSorting = {
  articleFlowSortByOptions,
  materialConsumptionSortByOptions,
  orderStatusSortByOptions,
  machinesXSortByOptions,
  adaptMaterialTargetSortByOptions,
};
