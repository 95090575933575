import { Modal, notification } from "antd";
import React, { FC, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  GlobalDatePicker,
  FormSelect,
  RadioButtons,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { adaptStepTrackers } from "shared/adapters";
import {
  useCreateOrderPlanForMachineMutation,
  useGetAllOrderProcessStepTrackersQuery,
  OrderPlan,
  OrderStatuses,
} from "shared/api";

import { ModalSizeValue, orderProcessStepParams } from "shared/constants";
import { GroupItem, TimelineItemsValues } from "shared/types";

interface DynamicItemsModalProps {
  isOpenModal: boolean;
  setDynamicItemsModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGroup: GroupItem | null;
  selectedOrderPlan: OrderPlan | null;
}

const DynamicItemsModal: FC<DynamicItemsModalProps> = ({
  isOpenModal,
  setDynamicItemsModal,
  selectedGroup,

  selectedOrderPlan,
}) => {
  const { t } = useTranslation();

  const { values, resetForm, handleBlur, setFieldValue, errors } =
    useFormikContext<TimelineItemsValues>();

  const [isDefaultHourInterval, setDefaultHourInterval] = useState(true);
  const [isActiveOrders, setActiveOrders] = useState(true);
  const [orderStatus, setOrderStatus] = useState<OrderStatuses | string>(
    OrderStatuses.AC
  );

  const handleActiveOrders = () => {
    setActiveOrders(true);
    setOrderStatus(OrderStatuses.AC);
  };

  const handleAllOrders = () => {
    setActiveOrders(false);
    setOrderStatus("");
  };

  const handleStartDate = (date: DatePickerValue) => {
    setFieldValue("startDate", date);
  };

  const handleEndDate = (date: DatePickerValue) => {
    setFieldValue("endDate", date);
  };

  const processStepProcessTemplateID = selectedGroup?.group.process_template;

  const { data: orderProcessStepTrackers } =
    useGetAllOrderProcessStepTrackersQuery(
      {
        expand: `${orderProcessStepParams.expand},`,
        order__status: orderStatus,
        process_step__process_template: processStepProcessTemplateID,
      },
      { skip: !processStepProcessTemplateID }
    );

  const adaptedStepTrackers = useMemo(() => {
    return adaptStepTrackers(orderProcessStepTrackers || []);
  }, [orderProcessStepTrackers]);

  const [api, contextHolder] = notification.useNotification();

  const [createOrderPlanForMachine] = useCreateOrderPlanForMachineMutation();

  const createOrderPlanForMachineRequest = async () => {
    try {
      await createOrderPlanForMachine({
        order_plan: selectedOrderPlan?.id,
        machine: selectedGroup?.group.group,
        order_process_step_tracker: values.orderProcessStep!,
        start_datetime: dayjs(values.startDate),
        end_datetime: isDefaultHourInterval
          ? dayjs(values.startDate).add(1, "hour")
          : dayjs(values.endDate),
      }).unwrap();
      api.success({
        message: t("plans.success-title"),
        description: t("plans.success-item-msg-add"),
      });
      setDynamicItemsModal(false);
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );
      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const disableOkButton = () => {
    const isHourIntervalTrue =
      isDefaultHourInterval && (!values.orderProcessStep || !values.startDate);

    const isHourIntervalFalse =
      !isDefaultHourInterval &&
      (!values.orderProcessStep || !values.startDate || !values.endDate);

    return isHourIntervalTrue || isHourIntervalFalse;
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("planner.add-timeline-item")}
        open={isOpenModal}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: disableOkButton(),
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={() => {
          setDynamicItemsModal(false);
          resetForm();
        }}
        onOk={() => {
          setDynamicItemsModal(false);
          createOrderPlanForMachineRequest();
          resetForm();
        }}
      >
        <RadioButtons
          isActive={isActiveOrders}
          selectLeftOption={() => handleActiveOrders()}
          selectRightOption={() => handleAllOrders()}
          title={t("planner.order-status")}
          leftVariantTitle={t("planner.active")}
          rightVariantTitle={t("planner.all")}
        />

        <Title level={5}>{t("planner.orders-process-step")}</Title>

        <FormSelect
          value={values.orderProcessStep}
          placeholder={t("planner.orders-process-step-placeholder")}
          fieldName="orderProcessStep"
          options={adaptedStepTrackers}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.orderProcessStep?.toString()}
          isError={!!errors.orderProcessStep}
          showSearch
        />

        <Title level={5} className="mb-1.25">
          {t("planner.start-date")}
        </Title>

        <GlobalDatePicker
          value={values.startDate ? dayjs(values.startDate) : null}
          handlePickerChange={handleStartDate}
          error={errors.startDate}
          isError={!!errors.startDate}
          showTime
        />

        <RadioButtons
          isActive={isDefaultHourInterval}
          selectLeftOption={() => setDefaultHourInterval(true)}
          selectRightOption={() => setDefaultHourInterval(false)}
          title={t("planner.timeline-item-length")}
          leftVariantTitle={t("planner.default-variant")}
          rightVariantTitle={t("planner.custom-variant")}
        />

        {!isDefaultHourInterval ? (
          <>
            <Title level={5} className="mb-1.25 mt-2">
              {t("planner.end-date")}
            </Title>

            <GlobalDatePicker
              value={values.endDate ? dayjs(values.endDate) : null}
              handlePickerChange={handleEndDate}
              error={errors.startDate}
              isError={!!errors.startDate}
              showTime
            />
          </>
        ) : null}
      </Modal>
    </>
  );
};

export { DynamicItemsModal };
