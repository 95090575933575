import { Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { DatePickerValue, GlobalDatePicker } from "shared/ui";

interface DateRangeProps {
  title: string;
  leftPickerTitle: string;
  rightPickerTitle: string;
  handlePickerChangeLesser: (date: DatePickerValue, dateString: string) => void;
  handlePickerChangeGreater: (
    date: DatePickerValue,
    dateString: string
  ) => void;
}

const DateRange: FC<DateRangeProps> = ({
  title,
  handlePickerChangeGreater,
  handlePickerChangeLesser,
  leftPickerTitle,
  rightPickerTitle,
}) => {
  return (
    <Space
      className="
        flex flex-col items-center 
        p-2.5 w-full shadow-dynamicFormCard
      "
    >
      <Title level={4} className="!mb-0">
        {title}
      </Title>

      <div className="flex items-center justify-between gap-x-3">
        <div
          className="
            h-full p-3 border border-gray-400 
            rounded-xl shadow-dynamicFormCard 
            bg-blue-primary
          "
        >
          <Title level={5} className="!text-[14px] !text-gray-100">
            {rightPickerTitle}
          </Title>
          <GlobalDatePicker handlePickerChange={handlePickerChangeGreater} />
        </div>

        <div
          className="
            h-full p-3 border border-gray-400 
            rounded-xl shadow-dynamicFormCard 
            bg-blue-primary
          "
        >
          <Title level={5} className="!text-[14px] !text-gray-100">
            {leftPickerTitle}
          </Title>

          <GlobalDatePicker handlePickerChange={handlePickerChangeLesser} />
        </div>
      </div>
    </Space>
  );
};

export { DateRange };
