import * as Yup from "yup";
import {
  createDynamicChoicesValidation,
  createDynamicUnitValidation,
} from "shared/helpers";

export const AddOrEditProcessTemplateSchema = Yup.object().shape({
  name: Yup.string().nullable().required("process-templates.name-validation"),
  description: Yup.string()
    .nullable()
    .required("process-templates.description-validation"),
  abbreviation: Yup.string()
    .nullable()
    .max(3, "process-templates.enter-three-characters")
    .min(3, "process-templates.enter-three-characters")
    .required(),
  sections: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("process-templates.title-validation"),
      fields: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(
            "material-category-management.name-validation"
          ),
          type: Yup.string()
            .nullable()
            .required("material-category-management.type-validation"),

          choices: createDynamicChoicesValidation(),

          unit: createDynamicUnitValidation(),
        })
      ),
    })
  ),
});
