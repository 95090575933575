import { Modal, notification } from "antd";
import React, { FC } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { AlertOutlined } from "@ant-design/icons";
import { FormInput, FormSelect, Loader } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { ProcessTemplateItem, useUpdateProcessStepMutation } from "shared/api";
import {
  AdaptedValues,
  AddProcessStepValues,
  ProcessStepInputMaterial,
} from "shared/types";
import { ModalSizeValue } from "shared/constants";
import { adaptProcessStepInputMaterials } from "shared/adapters";
import { ProcessStepDynamicFields } from "./ProcessStepDynamicFields";
import { InputMaterialsDynamicForm } from "./InputMaterialsDynamicForm";
import { OutputMaterialsDynamicForm } from "./OutputMaterialsDynamicForm";

interface EditProcessStepModalProps {
  isOpenModal: boolean;
  hideEditProcessStepModal: () => void;
  adaptedProcessTemplates: AdaptedValues[];
  adaptedMaterials: AdaptedValues[];
  dynamicProcessTemplateFields?: ProcessTemplateItem;
  isLoadingMaterials: boolean;
}

const EditProcessStepModal: FC<EditProcessStepModalProps> = ({
  isOpenModal,
  hideEditProcessStepModal,
  adaptedMaterials,
  adaptedProcessTemplates,
  dynamicProcessTemplateFields,
  isLoadingMaterials,
}) => {
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddProcessStepValues>();

  const closeModal = () => {
    hideEditProcessStepModal();
    resetForm();
  };

  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [updateProcessStep] = useUpdateProcessStepMutation();

  const updateProcessStepRequest = async () => {
    try {
      await updateProcessStep({
        id: values?.id!,
        name: values.name!,
        dynamic_process_step_fields: values.dynamic_process_step_fields,
        input_materials: adaptProcessStepInputMaterials(
          values?.input_materials as ProcessStepInputMaterial[]
        ),
        output_materials: values?.output_materials,
        process_template: values.process_template!,
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-update-msg"),
      });
      closeModal();
    } catch (error: any) {
      const description = handleRequestError(error, t("process-step.error"));

      api.error({
        message: t("process-step.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("process-step.edit-process-step")}
        open={isOpenModal}
        width={ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          updateProcessStepRequest();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("process-step.process-step-name")}
        </Title>

        <div className="relative">
          <FormInput
            value={values.name}
            fieldName="name"
            placeholder={t("process-step.name-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.name}
            isError={!!errors.name}
          />
          {!values?.name?.length && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}
        </div>

        <Title level={5}> {t("process-step.process-template")}</Title>

        <FormSelect
          value={values.process_template}
          placeholder={t("process-step.process-template")}
          fieldName="process_template"
          options={adaptedProcessTemplates}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.process_template?.toString()}
          isError={!!errors.process_template}
        />

        <ProcessStepDynamicFields
          dynamicProcessTemplateFields={dynamicProcessTemplateFields}
        />

        {adaptedMaterials.length ? (
          <>
            <InputMaterialsDynamicForm
              adaptedMaterials={adaptedMaterials}
              isLoadingMaterials={isLoadingMaterials}
            />

            <OutputMaterialsDynamicForm
              adaptedMaterials={adaptedMaterials}
              isLoadingMaterials={isLoadingMaterials}
            />
          </>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export { EditProcessStepModal };
