import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Page } from "shared/ui";
import { noop } from "shared/helpers";
import { orderPlansInitialValues } from "shared/constants";
import { ScheduleItem } from "shared/types";
import { orderPlanSchema } from "shared/schemas";
import { OrderPlansTable } from "./UI/OrderPlansTable";
import { AddPlanModal } from "./UI/AddPlanModal";
import { EditPlanModal } from "./UI/EditPlanModal";
import { PlannerViewModal } from "./UI/PlannerViewModal";
import { OrderPlan } from "../../../shared/api/orders/models";

interface OrderPlansProps {}

const OrderPlans: FC<OrderPlansProps> = () => {
  const { t } = useTranslation();

  const [isOpenAddPlanModal, setOpenAddPlanModal] = useState(false);
  const [isOpenEditPlanModal, setOpenEditPlanModal] = useState(false);
  const [isOpenPlannerView, setPlannerView] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [items, setItems] = useState<ScheduleItem[]>([]);

  const [selectedOrderPlan, setSelectedOrderPlan] = useState<OrderPlan | null>(
    null
  );

  const addOrUpdateOrderPlanFormik = useFormik({
    initialValues: orderPlansInitialValues,
    validationSchema: orderPlanSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateOrderPlanFormik}>
      <Page
        title={t("plans.title")}
        buttonTitle={t("plans.add-plan")!}
        onClick={() => setOpenAddPlanModal(true)}
      >
        <OrderPlansTable
          setOpenEditPlanModal={setOpenEditPlanModal}
          setSelectedOrderPlan={setSelectedOrderPlan}
          setItems={setItems}
          selectedOrderPlan={selectedOrderPlan}
        />

        <AddPlanModal
          isOpenModal={isOpenAddPlanModal}
          setOpenAddPlanModal={setOpenAddPlanModal}
        />

        <EditPlanModal
          isOpenModal={isOpenEditPlanModal}
          setOpenEditPlanModal={setOpenEditPlanModal}
        />

        <PlannerViewModal
          isOpenModal={isOpenPlannerView}
          setPlannerView={setPlannerView}
          selectedOrderPlan={selectedOrderPlan}
        />
      </Page>
    </FormikProvider>
  );
};

export { OrderPlans };
