import { Button } from "antd";
import classNames from "classnames";
import { PackagesModal } from "pages/Orders/Orders/UI/Packages/PackagesModal";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { WidgetTableData } from "shared/api";
import {
  OrderStatusWidgetEnum,
  colorOrderStatusWidgetButton,
  openHyperlinkInTab,
  renderOrderStatusIcon,
} from "shared/helpers";
import { useModal } from "shared/hooks";
import { Routes } from "shared/routers";
import {
  DateWithTimeRow,
  PopConfirmButton,
  TableButton,
  TableImage,
} from "shared/ui";
import { OrderStatusProgress } from "./ui";

interface OrderStatusWidgetProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

interface OrderStatusMachine {
  machineName: string;
  machineID: string;
}

const OrderStatusWidget: FC<OrderStatusWidgetProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const { t } = useTranslation();

  const orderId = record?.orderStatusId;
  const orderCode = record?.orderStatusPackagesOrderCode;

  const [visiblePackagesModal, showPackagesModal, hidePackagesModal] =
    useModal();

  const orderStatusMachines =
    record?.orderStatusMachines as unknown as OrderStatusMachine[];

  const isEmptyRowData = text === "No Data";

  if (fieldKey === OrderStatusWidgetEnum.ICON) {
    return (
      <TableImage
        image={renderOrderStatusIcon(record?.orderStatus)}
        preview={false}
        className="w-9 h-9"
      />
    );
  }

  if (text === OrderStatusWidgetEnum.WIDGET_TYPE) {
    return <span>{t(`widgets.${text}`)} </span>;
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS_DATE_TIME) {
    return <DateWithTimeRow text={text} />;
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS_PROGRESS) {
    return (
      <OrderStatusProgress
        calcProgress={record?.orderStatusProgress as number | null}
      />
    );
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS) {
    return (
      <Button
        className={classNames("w-full", colorOrderStatusWidgetButton(record))}
      >
        {t(`widgets.${text}`)}
      </Button>
    );
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS_ORDER) {
    const redirectLink = `${Routes.orders.hyperlinkUrl}/${text}`?.replace(
      ":orderID/",
      ""
    );

    if (isEmptyRowData) {
      return <span>{t("widgets.noOrderData")}</span>;
    }

    return (
      <PopConfirmButton
        buttonContent={text}
        onClick={() => openHyperlinkInTab(redirectLink)}
      />
    );
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS_MACHINES) {
    return (
      <div>
        {orderStatusMachines?.map((machine, index) => {
          const redirectLink =
            `/process-step-production/${record?.orderStatusId}/index-${index}`?.replace(
              ":id/",
              ""
            );

          if (machine?.machineName === "No Data") {
            return <p key={index}>{t("widgets.noMachineData")}</p>;
          }

          return (
            <PopConfirmButton
              key={machine?.machineID}
              buttonContent={machine?.machineName}
              onClick={() => openHyperlinkInTab(redirectLink)}
            />
          );
        })}
      </div>
    );
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_STATUS_PACKAGES_ORDER_CODE) {
    return (
      <>
        <TableButton
          type="box"
          onClick={showPackagesModal}
          className="text-green-300"
        />
        <PackagesModal
          isOpenModal={visiblePackagesModal}
          hidePackagesModal={hidePackagesModal}
          orderID={Number(orderId)}
          orderCode={orderCode.toString()}
        />
      </>
    );
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_PRODUCTION_TIME) {
    return <DateWithTimeRow text={text} />;
  }

  if (fieldKey === OrderStatusWidgetEnum.ORDER_DELIVERY_TIME) {
    return <DateWithTimeRow text={text} />;
  }

  return <span>{text}</span>;
};

const OrderStatusWidgetMemo = React.memo(OrderStatusWidget);
export { OrderStatusWidgetMemo as OrderStatusWidget };
