import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationService } from "shared/services";
import Flag from "react-flagkit";

const LanguageSelection = () => {
  const { t } = useTranslation("", { keyPrefix: "sidebar" });

  const selectedLanguage = localStorage.getItem("language");

  useEffect(() => {
    if (selectedLanguage) {
      LocalizationService.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const [language, setLanguage] = useState(selectedLanguage);

  const handleChangeLanguage = (value: string) => {
    setLanguage(value);
    localStorage.setItem("language", value);
    LocalizationService.changeLanguage(value);
  };

  const languageOptions = [
    { value: "ar", label: "arabic", countryCode: "SA" }, // Saudi Arabia
    { value: "dn", label: "danish", countryCode: "DK" }, // Denmark
    { value: "du", label: "dutch", countryCode: "NL" }, // Netherlands
    { value: "en", label: "english", countryCode: "GB" }, // United Kingdom
    { value: "fi", label: "finnish", countryCode: "FI" }, // Finland
    { value: "gm", label: "german", countryCode: "DE" }, // Germany
    { value: "it", label: "italian", countryCode: "IT" }, // Italy
    { value: "kl", label: "klingon", countryCode: "" }, // No country for Klingon
    { value: "nw", label: "norwegian", countryCode: "NO" }, // Norway
    { value: "sw", label: "swedish", countryCode: "SE" }, // Sweden
    { value: "sp", label: "spanish", countryCode: "ES" }, // Spain
    { value: "tu", label: "turkish", countryCode: "TR" }, // Turkey
    { value: "cz", label: "czech", countryCode: "CZ" }, // Czech Republic
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="w-[80%] flex flex-col gap-y-1 items-center justify-center">
      <Select
        placement="topRight"
        onChange={handleChangeLanguage}
        className="w-full"
        value={language}
      >
        {languageOptions.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            style={{ padding: "5px" }}
          >
            <div className="flex items-center justify-between h-full">
              {t(option.label)}
              {option.countryCode && (
                <Flag country={option.countryCode} className="mr-2" />
              )}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export { LanguageSelection };
