import * as Yup from "yup";

export const CreateMaterialSchema = Yup.object().shape({
  name: Yup.string().required("material-management.name-validation"),
  expanded_label_name: Yup.string()
    .nullable()
    .required("material-management.expanded-name-validation"),
  target_quantity_in_stock: Yup.number()
    .nullable()
    .required("material-management.name-validation"),
  material_category: Yup.object().shape({
    id: Yup.number()
      .nullable()
      .required("material-management.material-category-validation"),
  }),
  n_packaging_items: Yup.number()
    .nullable()
    .required("material-management.n-packaging-validation"),
  suppliers: Yup.array()
    .of(
      Yup.object().shape({
        supplier: Yup.number().required("material-management.required"),
        cost_per_unit: Yup.number().nullable(),
        cost_per_unit_currency: Yup.string()
          .nullable()
          .test(
            "cost_per_unit_currency",
            "material-management.required",
            // eslint-disable-next-line func-names
            function (value) {
              const { cost_per_unit } = this.parent;
              if (
                cost_per_unit !== null &&
                cost_per_unit !== undefined &&
                !value
              ) {
                return false;
              }
              if (
                (cost_per_unit === null || cost_per_unit === undefined) &&
                value
              ) {
                return false;
              }
              return true;
            }
          ),
      })
    )
    .required("material-management.suppliers-validation"),
});

export const EditMaterialSchema = Yup.object().shape({
  name: Yup.string().required("material-management.name-validation"),
  expanded_label_name: Yup.string()
    .nullable()
    .required("material-management.expanded-name-validation"),
  target_quantity_in_stock: Yup.number()
    .nullable()
    .required("material-management.name-validation"),
  material_category: Yup.object().shape({
    id: Yup.number()
      .nullable()
      .required("material-management.material-category-validation"),
  }),
  n_packaging_items: Yup.number()
    .nullable()
    .required("material-management.n-packaging-validation"),
});

export const AddOrEditStockMaterialSchema = Yup.object().shape({
  // TODO: Check validation. Add 1 default value in modals
  // material: Yup.number()
  //   .nullable()
  //   .required("material-category-validation.error-msg"),
  batch_code: Yup.string().required("material-category-validation.error-msg"),
  delivery_date: Yup.string().required(
    "material-category-validation.error-msg"
  ),
  status: Yup.string().required("material-category-validation.error-msg"),
});

export const UploadStockManagementExcelSchema = Yup.object().shape({
  supplier: Yup.number().required("material-category-validation.error-msg"),
  delivery_date: Yup.string().required(
    "material-category-validation.error-msg"
  ),
});
