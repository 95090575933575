import * as Yup from "yup";

export const AddOrEditOrderSchema = Yup.object().shape({
  buyer: Yup.number().nullable().required("order-validation.buyer"),
  po_number: Yup.string().nullable().required("order-validation.number"),
  wanted_delivery_datetime: Yup.string()
    .nullable()
    .required("order-validation.wanted-delivery-datetime"),
  production_datetime: Yup.string()
    .nullable()
    .required("order-validation.production-datetime"),
  product_card: Yup.number()
    .nullable()
    .required("order-validation.product-card"),
  quantity_expected: Yup.number()
    .nullable()
    .required("order-validation.quantity-expected"),
  status: Yup.string().nullable().required("order-validation.status"),
});

export const createAddOrOrderSchema = (dynamicFieldsValidations: any[]) => {
  const dynamicFields = dynamicFieldsValidations?.reduce((acc, field) => {
    return {
      ...acc,
      ...field,
    };
  }, {});

  return Yup.object().shape({
    buyer: Yup.number().nullable().required("order-validation.buyer"),
    po_number: Yup.string().nullable().required("order-validation.po-number"),
    customer_order_number: Yup.string()
      .nullable()
      .required("order-validation.customer-order-number"),
    wanted_delivery_datetime: Yup.string()
      .nullable()
      .required("order-validation.wanted-delivery-datetime"),
    production_datetime: Yup.string()
      .nullable()
      .required("order-validation.production-datetime"),
    product_card: Yup.number()
      .nullable()
      .required("order-validation.product-card"),
    // INFO: Temporary removed validation for quantity_expected
    // quantity_expected: Yup.number()
    //   .nullable()
    //   .required("order-validation.quantity-expected"),
    status: Yup.string().nullable().required("order-validation.status"),
    dynamic_order_fields: Yup.object({
      ...dynamicFields,
    }).nullable(),
  });
};

export const EditProcessStepForTheOrderSchema = Yup.object().shape({
  machine: Yup.number().nullable().optional(),
  start_datetime: Yup.string().nullable().optional(),
  end_datetime: Yup.string().nullable().optional(),
  status: Yup.string().nullable().optional(),
});

export const orderPlanSchema = Yup.object().shape({
  name: Yup.string().nullable().required("plans.name-validation"),
  status: Yup.string().nullable().required("plans.status-validation"),
});
