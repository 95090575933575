import { Alert, Modal, Table, notification } from "antd";
import React, { FC, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { FilterFilled } from "@ant-design/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  DateRange,
  RenderClearFiltersButton,
  TableButton,
  TableSearchFilter,
} from "shared/ui";
import {
  useConfirmDeleteModal,
  useDateFilters,
  useDebouncedTableFilter,
  useModal,
  usePagination,
  useTableSorting,
  useWindowDimensions,
} from "shared/hooks";
import {
  addThousandsSeparator,
  handleRequestError,
  renderStockBatchStatuses,
} from "shared/helpers";
import {
  useDeleteMaterialStockBatchMutation,
  useGetMaterialStockBatchesProducedInOrderQuery,
  ProducedOrderItem,
} from "shared/api";
import {
  ModalSizeValue,
  sortDirections,
  expandStockMaterialForOrder,
  StockMaterialTableSorting,
  stockMaterialTableColumnsData,
  MaterialItemsSorting,
} from "shared/constants";
import { AddDynamicOrderSteps } from "shared/types";
import { MaterialItemsModal } from "../../../../SupplyChain/MaterialManagement/ui/MaterialItems/MaterialItemsModal";

import { EditStockMaterialModal } from "../../../../SupplyChain/MaterialManagement/ui";

interface ProducedItemsInOrdersModalProps {
  isOpenModal: boolean;
  hideProducedItemInOrderModal: () => void;
  processStepId?: number;
}

const ProducedItemsInOrdersModal: FC<ProducedItemsInOrdersModalProps> = ({
  isOpenModal,
  hideProducedItemInOrderModal,
  processStepId,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { setValues, values } = useFormikContext<AddDynamicOrderSteps>();
  const { isDesktop } = useWindowDimensions();
  const [deleteMaterialStockBatch] = useDeleteMaterialStockBatchMutation();

  const {
    material,
    batchCode,
    deliveryDate,
    status,
    actions,
    calcPercentageRemainingQuantity,
    calcTotalConsumedQuantity,
    calcTotalOriginalQuantity,
    calcTotalRemainingQuantity,
  } = stockMaterialTableColumnsData;

  const pagination = usePagination();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const [
    materialItemsByIdModal,
    showMaterialItemsByIdModal,
    hideMaterialItemsByIdModal,
  ] = useModal();

  const [isOpenEditStockMaterial, showEditStockModal, hideEditStockModal] =
    useModal();

  const {
    debouncedValue: debouncedBatchCodeContains,
    handleFilterChange: handleBatchCodeContains,
    value: batchCodeContains,
    clearFilterValue: clearBatchCodeContains,
  } = useDebouncedTableFilter("", 400);

  const {
    dateGreater: deliveryDateGreater,
    dateLesser: deliveryDateLesser,
    handleDateGreater: handlePickerChangeGreater,
    handleDateLesser: handlePickerChangeLesser,
    clearDates: clearWantedDeliveryDateFilter,
    isDataFilterActive: isActiveDeliveryDateFilter,
  } = useDateFilters();

  const {
    data: producedOrderItems,
    isLoading: isLoadingProducedOrderItems,
    isFetching: isFetchingProducedOrderItems,
  } = useGetMaterialStockBatchesProducedInOrderQuery(
    {
      limit,
      offset,
      expand: expandStockMaterialForOrder,
      produced_in_order_process_step_tracker: processStepId,
      ordering,
      batch_code__icontains: debouncedBatchCodeContains,
      delivery_date__gte: deliveryDateGreater || "",
      delivery_date__lte: deliveryDateLesser || "",
    },
    { skip: processStepId === undefined }
  );

  const [materialStockBatchID, setMaterialStockBatchID] = useState<
    null | number
  >(null);

  const selectedMaterialItemFromStock = useMemo(() => {
    return producedOrderItems?.results.find(
      (item) => item.id === materialStockBatchID
    );
  }, [producedOrderItems, materialStockBatchID]);

  const editMaterialHandler = (record: ProducedOrderItem) => {
    showEditStockModal();
    setValues({
      ...values,
      stockMaterial: record,
    });
  };

  const deleteMaterialStockBatchHandler = async (record: ProducedOrderItem) => {
    try {
      await deleteMaterialStockBatch(record).unwrap();
      api.success({
        message: t("stock-material.success-title"),
        description: t("stock-material.success-delete-desc"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("stock-material.error-title")
      );
      api.error({
        message: t("stock-material.error-title"),
        description,
      });
    }
  };

  const showDeleteMaterialStockModal = useConfirmDeleteModal(
    t("stock-material.delete-modal-msg"),
    deleteMaterialStockBatchHandler
  );

  const columns: ColumnsType<ProducedOrderItem> = [
    {
      title: t(material.title),
      key: material.key,
      render: (item: ProducedOrderItem) => (
        <TableButton
          type="title"
          title={item?.material?.name}
          onClick={() => editMaterialHandler(item)}
        />
      ),

      width: material.width,
    },
    {
      title: t(batchCode.title),
      dataIndex: batchCode.dataIndex,
      key: batchCode.key,
      width: batchCode.width,
      sortDirections,
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-blue-600  scale-125": batchCodeContains.length > 0,
          })}
        />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            StockMaterialTableSorting.BATCH_CODE_ACS,
            StockMaterialTableSorting.BATCH_CODE_DESC
          );
        },
      }),
      sorter: true,
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearBatchCodeContains}
          confirm={confirm}
          value={batchCodeContains}
          handleFilterChange={handleBatchCodeContains!}
          visible={visible}
          placeholder={t("stockTableColumns.bCode")!}
          title={t("stockTableColumns.bCode")!}
        />
      ),
    },
    {
      title: t(calcTotalOriginalQuantity.title),
      render: (item: ProducedOrderItem) =>
        `${addThousandsSeparator(item.calc_total_original_quantity!)} - ${
          item.material?.material_category?.unit?.abbreviation
        }`,
      width: calcTotalOriginalQuantity.width,
    },
    {
      title: t(calcTotalConsumedQuantity.title),
      render: (item: ProducedOrderItem) =>
        `${addThousandsSeparator(item.calc_total_consumed_quantity!)} - ${
          item.material?.material_category?.unit?.abbreviation
        }`,
      width: calcTotalConsumedQuantity.width,
    },
    {
      title: t(calcTotalRemainingQuantity.title),
      render: (item: ProducedOrderItem) =>
        `${addThousandsSeparator(item.calc_total_remaining_quantity!)} - ${
          item.material?.material_category?.unit?.abbreviation
        }`,
      width: calcTotalRemainingQuantity.width,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: t(calcPercentageRemainingQuantity.title),
      render: (item: ProducedOrderItem) => {
        if (!item.calc_percentage_remaining_quantity) {
          return "0%";
        }
        return `${item.calc_percentage_remaining_quantity}%`;
      },
      width: calcPercentageRemainingQuantity.width,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: t(deliveryDate.title),
      dataIndex: deliveryDate.dataIndex,
      key: deliveryDate.key,
      width: deliveryDate.width,
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-purple-600 scale-125": isActiveDeliveryDateFilter,
          })}
        />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            StockMaterialTableSorting.DELIVERY_DATE_ASC,
            StockMaterialTableSorting.DELIVERY_DATE_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,

      filterDropdown: () => {
        return (
          <DateRange
            title={t("stock-material-item.date-title")}
            leftPickerTitle={t("stock-material-item.date-title-lesser")}
            rightPickerTitle={t("stock-material-item.date-title-greater")}
            handlePickerChangeGreater={handlePickerChangeGreater}
            handlePickerChangeLesser={handlePickerChangeLesser}
          />
        );
      },
    },
    {
      title: t(status.title),
      render: (item: ProducedOrderItem) => renderStockBatchStatuses(item),
      width: status.width,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            StockMaterialTableSorting.STATUS_ACS,
            StockMaterialTableSorting.STATUS_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
    },
    {
      title: t(actions.title),
      key: actions.key,
      width: "5%",
      render: (record: ProducedOrderItem) => {
        return (
          <div className="w-full flex items-center justify-around">
            {record.is_quantity_in_tracker_table ? (
              <TableButton
                type="eye"
                onClick={() => {
                  showMaterialItemsByIdModal();
                  setMaterialStockBatchID(record.id);
                }}
              />
            ) : (
              <TableButton type="eye-invisible" />
            )}

            <TableButton
              type="delete"
              onClick={() => showDeleteMaterialStockModal(record)}
            />
          </div>
        );
      },
    },
  ];

  const clearAllFilters = () => {
    clearBatchCodeContains();
    clearWantedDeliveryDateFilter();
  };

  const renderButtonAndClearAllFilters = () => {
    const isContainsData =
      !!batchCodeContains?.length ||
      !!deliveryDateGreater?.length ||
      !!deliveryDateLesser?.length;

    if (isContainsData) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }
    return null;
  };

  return (
    <>
      {contextHolder}

      <Modal
        title="Material Stock Batches"
        open={isOpenModal}
        width={isDesktop ? ModalSizeValue.LARGE : ModalSizeValue.FULL}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={hideProducedItemInOrderModal}
      >
        {renderButtonAndClearAllFilters()}

        {!producedOrderItems?.results.length && (
          <Alert
            message={t("stock-material.no-stock-items")}
            className="mb-5"
            showIcon
            type="warning"
          />
        )}

        <Table
          loading={isLoadingProducedOrderItems || isFetchingProducedOrderItems}
          columns={columns}
          dataSource={producedOrderItems?.results}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: producedOrderItems?.count,
          }}
        />

        <MaterialItemsModal
          isOpenModal={materialItemsByIdModal}
          hideMaterialItemsByIdModal={hideMaterialItemsByIdModal}
          materialStockBatchID={materialStockBatchID}
          selectedMaterialItemFromStock={selectedMaterialItemFromStock}
          renderAddButton={false}
        />

        <EditStockMaterialModal
          isOpenEditStockMaterial={isOpenEditStockMaterial}
          hideEditStockModal={hideEditStockModal}
          isActiveDynamicTable
        />
      </Modal>
    </>
  );
};

export { ProducedItemsInOrdersModal };
