import { ProcessTemplateItem } from "shared/api/productCard/models";

export const adaptProcessTemplates = (
  processTemplates: ProcessTemplateItem[]
) => {
  const adaptProcesses = processTemplates?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return adaptProcesses || [];
};
