import * as Yup from "yup";
import {
  createDynamicChoicesValidation,
  createDynamicUnitValidation,
} from "shared/helpers";

export const AddOrEditMaterialSchema = Yup.object().shape({
  name: Yup.string().required(
    "material-category-management.category-name-validation"
  ),
  unit: Yup.string()
    .nullable()
    .required("material-category-management.unit-validation"),
  unique_code: Yup.string()
    .nullable()
    .max(5, "material-category-management.unique-code-length")
    .required("material-category-management.unique-code-validation"),
  sections: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required(
          "material-category-management.section-title-validation"
        ),
        fields: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(
              "material-category-management.name-validation"
            ),
            type: Yup.string()
              .nullable()
              .required("material-category-management.type-validation"),

            choices: createDynamicChoicesValidation(),

            unit: createDynamicUnitValidation(),
          })
        ),
      })
    )
    .nullable(),
});
