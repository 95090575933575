import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface PackageItemProps {
  title: string;
  onClick: () => void;
}

const PackageItem: FC<PackageItemProps> = ({ title, onClick }) => {
  const { t } = useTranslation();

  return (
    <div
      className="
         w-full h-auto mt-2 flex 
         items-center gap-x-3 border-b 
        border-black-100 py-2 bg-gray-50
       "
    >
      <Title level={5} className="!text-[16px] w-[80%] truncate px-3">
        {title}
      </Title>

      <div className="w-[20%] flex items-center flex-col gap-y-2 p-1">
        <Button
          type="primary"
          className="
            w-[80%] bg-green-100 text-[16px] 
            hover:!bg-green-100 hover:!scale-105
            hover:!shadow-dynamicFormCard 
          "
          onClick={onClick}
        >
          {t("packaging.print")}
        </Button>
      </div>
    </div>
  );
};

export { PackageItem };
