import * as Yup from "yup";

export const AddOrEditProcessStepSchema = Yup.object().shape({
  name: Yup.string().required("process-step.name-validation"),
  process_template: Yup.number()
    .nullable()
    .required("process-step.process-type-validation"),
  input_materials: Yup.array()
    .of(
      Yup.object().shape({
        material: Yup.number().required(
          "process-step.material-required-validation"
        ),
      })
    )
    .min(1, "process-step.input-materials-validation")
    .required("process-step.input-materials-validation"),
  output_materials: Yup.array()
    .of(
      Yup.object().shape({
        material: Yup.number().required(
          "process-step.material-required-validation"
        ),
      })
    )
    .min(1, "process-step.output-materials-validation")
    .required("process-step.output-materials-validation"),
});

export const createAddOrUpdateProcessStepSchema = (
  dynamicFieldsValidations: any[]
) => {
  const dynamicFields = dynamicFieldsValidations?.reduce((acc, field) => {
    return {
      ...acc,
      ...field,
    };
  }, {});
  return Yup.object().shape({
    name: Yup.string().nullable().required("process-step.name-validation"),
    process_template: Yup.number()
      .nullable()
      .required("process-step.process-type-validation"),
    input_materials: Yup.array()
      .of(
        Yup.object().shape({
          material: Yup.number().required(
            "process-step.material-required-validation"
          ),
          consumption_type: Yup.string().required(
            "process-step.material-required-validation"
          ),
          consumption_fixed_value: Yup.number().when("consumption_type", {
            is: "FI",
            then: Yup.number().required("process-step.required"),
            otherwise: Yup.number().nullable(true),
          }),
          consumption_proportional_value: Yup.number().when(
            "consumption_type",
            {
              is: "PR",
              then: Yup.number().required("process-step.required"),
              otherwise: Yup.number().nullable(true),
            }
          ),
          consumption_proportional_material: Yup.number().when(
            "consumption_type",
            {
              is: "PR",
              then: Yup.number().required("process-step.required"),
              otherwise: Yup.number().nullable(true),
            }
          ),
        })
      )
      .min(1, "process-step.input-materials-validation")
      .required("process-step.input-materials-validation"),
    output_materials: Yup.array()
      .of(
        Yup.object().shape({
          material: Yup.number().required(
            "process-step.material-required-validation"
          ),
        })
      )
      .min(1, "process-step.output-materials-validation")
      .required("process-step.output-materials-validation"),
    dynamic_process_step_fields: Yup.object({
      ...dynamicFields,
    }),
  });
};
