import { FilePdfOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { ProductionHeaderPdfType } from "shared/constants";
import { useWindowDimensions } from "shared/hooks";

interface HeaderPdfExportButtonProps {
  title: string;
  onClick: () => Promise<void>;
  type: ProductionHeaderPdfType;
  loading: boolean;
}

const HeaderPdfExportButton: FC<HeaderPdfExportButtonProps> = ({
  title,
  onClick,
  type,
  loading,
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <Button
      className={classNames(
        "flex items-center gap-x-1 gap-y-2 p-1 !border-none ",
        {
          "bg-blue-800": type === ProductionHeaderPdfType.AllItemsLabels,
          "bg-green-800": type === ProductionHeaderPdfType.LastSetItemLabels,
          "bg-purple-800": type === ProductionHeaderPdfType.AllPackageLabels,
          "bg-yellow-800":
            type === ProductionHeaderPdfType.LastSetPackageLabels,
          "!h-5": !isDesktop,
        }
      )}
      onClick={onClick}
      loading={loading}
    >
      <Title
        level={5}
        className={classNames("!text-[14px] !text-white !m-0 !font-normal", {
          "!text-[11px] ": !isDesktop,
        })}
      >
        {title}
      </Title>
      {isDesktop ? (
        <FilePdfOutlined className="btn-additional !text-[18px] !text-white" />
      ) : null}
    </Button>
  );
};

export { HeaderPdfExportButton };
