import { useFormikContext } from "formik";
import React, { FC, useEffect, useMemo } from "react";
import Title from "antd/es/typography/Title";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  useProductionInputMaterialsContainerData,
  useWindowDimensions,
} from "shared/hooks";
import { InitialInputMaterials } from "shared/types";
import { OrderStepTrackerResponseItem } from "shared/api";
import { AddProductionRequest } from "./ProcessStepProduction";
import { VisibleInputMaterials } from "./VisibleInputMaterials";
import { HiddenInputMaterials } from "./HiddenInputMaterials";

interface InputMaterialsContainerProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  adaptedInitialInputMaterials: InitialInputMaterials[];
}

const InputMaterialsContainer: FC<InputMaterialsContainerProps> = ({
  orderStepTrackerResponseItem,
  adaptedInitialInputMaterials,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useWindowDimensions();

  const { values, setFieldValue } = useFormikContext<AddProductionRequest>();

  const {
    adaptedMaterialItems,
    adaptedMaterials,
    adaptedStockMaterialBatches,
    materialItems,
    materialStockBatches,
    isLoading: isLoadingInputMaterialsData,
  } = useProductionInputMaterialsContainerData({
    adaptedInitialInputMaterials,
  });

  useEffect(() => {
    setFieldValue("variantValue", null);
  }, [values.variant]);

  // In this array stored all materials that have to be in new section (collapsed by default)
  // Just to make this section clearer
  // Sometimes we don't need to add some changes to the material, so we hide it
  const hiddenMaterials = useMemo(() => {
    return values.input_materials.filter((material) => material.calcHideField);
  }, [values?.input_materials]);

  // Show visibleMaterials as default open items with previous functionality
  const visibleMaterials = useMemo(() => {
    return values.input_materials.filter((material) => !material.calcHideField);
  }, [values?.input_materials]);

  return (
    <div
      className={classNames(
        `
          w-full max-h-[550px] h-auto
          overflow-auto flex flex-col
          shadow-md mt-3 rounded-[10px]
        `,
        {
          "!h-[120px] flex items-center justify-center pb-3":
            !values.input_materials?.length,
        }
      )}
    >
      <div
        className="
          flex w-full items-center justify-between
          bg-blue-primary rounded-t-[10px] h-10
        "
      >
        <Title
          level={5}
          className={classNames("!m-0 !text-white px-2", {
            "!text-[12px]": isTablet,
          })}
        >
          {t("orders.select-input-materials")}
        </Title>
      </div>

      {!values.input_materials?.length && (
        <Alert
          message={t("orders.no-input-items")}
          showIcon
          type="info"
          className="w-[90%] mt-2"
        />
      )}

      {/* In this this map we render materials that are visible by default */}
      {values.input_materials?.map((inputMaterial, inputIndex) => {
        return (
          <VisibleInputMaterials
            key={inputMaterial.id}
            inputMaterial={inputMaterial}
            orderStepTrackerResponseItem={orderStepTrackerResponseItem}
            inputIndex={inputIndex}
            adaptedMaterials={adaptedMaterials}
            adaptedMaterialItems={adaptedMaterialItems}
            adaptedStockMaterialBatches={adaptedStockMaterialBatches}
            adaptedInitialInputMaterials={adaptedInitialInputMaterials}
            materialStockBatches={materialStockBatches}
            materialItems={materialItems}
            isLoadingInputMaterialsData={isLoadingInputMaterialsData}
          />
        );
      })}

      {/* In section rendered hidden by default items. Show them in a separate section to make it clearer */}
      {hiddenMaterials.length > 0 && (
        <HiddenInputMaterials
          visibleMaterials={visibleMaterials}
          hiddenMaterials={hiddenMaterials}
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          adaptedMaterials={adaptedMaterials}
          adaptedMaterialItems={adaptedMaterialItems}
          adaptedStockMaterialBatches={adaptedStockMaterialBatches}
          adaptedInitialInputMaterials={adaptedInitialInputMaterials}
          materialStockBatches={materialStockBatches}
          materialItems={materialItems}
        />
      )}
    </div>
  );
};

export { InputMaterialsContainer };
