import React, { FC } from "react";
import { useFormikContext } from "formik";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { FormSelect } from "shared/ui";
import { renderDynamicPlaceholder } from "shared/helpers";
import {
  useDynamicInputMaterialsProductionData,
  useWindowDimensions,
} from "shared/hooks";
import { AddDynamicOrderSteps, DropdownValues } from "shared/types";
import classNames from "classnames";

interface SelectVariantDropDownProps {
  inputIndex: number;
  fieldIndex: number;
  dynamicStockBatchValue: DropdownValues[];
}

const SelectVariantDropDown: FC<SelectVariantDropDownProps> = ({
  inputIndex,
  fieldIndex,
  dynamicStockBatchValue,
}) => {
  const { t } = useTranslation();
  const { isTablet, isMobile } = useWindowDimensions();
  const { values, handleBlur, setFieldValue } =
    useFormikContext<AddDynamicOrderSteps>();

  const isEmptyDynamicValues = !dynamicStockBatchValue?.length
    ? null
    : values.input_materials?.[inputIndex]?.fields?.[fieldIndex]?.variant;

  const { dynamicInputMaterials } = useDynamicInputMaterialsProductionData({
    dynamicStockBatchValue,
    values,
  });

  if (
    !dynamicStockBatchValue?.length &&
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex]?.selectValue
  ) {
    return (
      <Alert
        showIcon
        type="warning"
        message={t("orders.no-suitable-option")}
        className={classNames(
          "!h-10 w-full p-1 flex items-center justify-center",
          { "!text-[12px]": isTablet, "!text-[10px]": isMobile }
        )}
      />
    );
  }

  return (
    <div className="w-full flex items-center justify-center">
      <FormSelect
        value={isEmptyDynamicValues}
        placeholder={
          renderDynamicPlaceholder(
            values,
            inputIndex,
            fieldIndex,
            dynamicStockBatchValue?.length
          )!
        }
        fieldName={`input_materials.${inputIndex}.fields.${fieldIndex}.variant`}
        options={dynamicInputMaterials}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        showSearch
        className="w-[300px]"
      />
    </div>
  );
};

export { SelectVariantDropDown };
