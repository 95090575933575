export const materialConsumedColumns = {
  material: {
    title: "materialConsumedColumnsTable.material",
    width: "35%",
  },
  materialItem: {
    title: "materialConsumedColumnsTable.materialItem",
    width: "25%",
  },
  stockBatch: {
    title: "materialConsumedColumnsTable.materialStockBatch",
    width: "25%",
  },
  quantity: {
    title: "materialConsumedColumnsTable.qnt",
    width: "15%",
  },
  actions: {
    title: "materialConsumedColumnsTable.actions",
  },
};

export const expandMaterialConsumed =
  "material,material_item,material_stock_batch";

export const orderProductionSetExpand =
  "materials_consumed.material,materials_consumed.material_item,materials_consumed.material_stock_batch";

export const configCardColumns = {
  name: {
    title: "configCardColumns.name",
    width: "10%",
  },
  type: {
    title: "configCardColumns.type",
    width: "10%",
  },
  choices: {
    title: "configCardColumns.choices",
    width: "10%",
  },
  required: {
    title: "configCardColumns.required",
    width: "10%",
  },
  description: {
    title: "configCardColumns.description",
    width: "10%",
  },
  value: {
    title: "configCardColumns.value",
    width: "10%",
  },
};
