import { Button, Modal, UploadFile, notification } from "antd";
import { ModalSizeValue } from "shared/constants";
import React, { FC, useState } from "react";
import { Colors } from "shared/themes";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { AdaptedValues, UploadDocumentValues } from "shared/types";
import {
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  QualityResultResultType,
  useUpdateQualityResultMutation,
  useUploadQualityDocumentMutation,
} from "shared/api";
import {
  adaptMaterialItemToCreateQualityResult,
  adaptStockBatchesToCreateQualityResultUniq,
  adaptUploadDocumentParams,
} from "shared/adapters";
import { handleRequestError } from "shared/helpers";
import { useTableKeys } from "shared/hooks";
import { RequiredToUpdateQualityFields } from "./RequiredToUpdateQualityFields";
import { ExtraData } from "./ExtraData";

interface UpdateQualityResultModalProps {
  isOpenModal: boolean;
  onClose: () => void;
  adaptedMaterials: AdaptedValues[];
  document: File | null;
  handleDocument: (file: File | null) => void;
  selectedStockBatches: MaterialStockBatchItemResponse[];
  selectedMaterialItems: MaterialStockItem[];
  handleStockBatchesRows: (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => void;
  handleMaterialItemsRows: (materialItems: MaterialStockItem[]) => void;
  handleDocumentList: (files: UploadFile[]) => void;
  documentList: UploadFile[];
  adaptedOrders: AdaptedValues[];
  isLoadingMaterials: boolean;
  isLoadingOrders: boolean;
}

const UpdateQualityResultModal: FC<UpdateQualityResultModalProps> = ({
  isOpenModal,
  onClose,
  adaptedMaterials,
  document,
  handleDocument,
  selectedStockBatches,
  selectedMaterialItems,
  handleStockBatchesRows,
  handleMaterialItemsRows,
  handleDocumentList,
  documentList,
  adaptedOrders,
  isLoadingMaterials,
  isLoadingOrders,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const { values, resetForm, isValid } =
    useFormikContext<UploadDocumentValues>();

  const [selectedStockBatchesKeys, handleStockBatchesKeys] = useTableKeys();
  const [selectedMaterialItemsKeys, handleMaterialItemsKeys] = useTableKeys();

  const [modalStep, setModalStep] = useState(1);

  const isFirstModalPage = modalStep === 1;
  const isSecondModalPage = modalStep === 2;

  const handleModalStep = () => {
    setModalStep(isFirstModalPage ? 2 : 1);
  };

  const closeModal = () => {
    resetForm();
    handleDocument(null);
    onClose();
    handleStockBatchesRows([]);
    handleMaterialItemsRows([]);
    handleStockBatchesKeys([]);
    handleMaterialItemsKeys([]);
    setModalStep(1);
    handleDocumentList([]);
  };

  const [updateQualityResult] = useUpdateQualityResultMutation();
  const [uploadQualityDocument] = useUploadQualityDocumentMutation();

  const updateQualityResultHandler = async () => {
    try {
      const response = await updateQualityResult({
        id: values?.id!,
        name: values?.name!,
        result_type: values?.result_type as QualityResultResultType,
        material_stock_batches:
          adaptStockBatchesToCreateQualityResultUniq(selectedStockBatches),
        material_items: adaptMaterialItemToCreateQualityResult(
          selectedMaterialItems
        ),

        curl_cd: values?.curl_cd,
        curl_md: values?.curl_md,
        reel_lab_measurements: values?.reel_lab_measurements,
        remark: values?.remark,
        thickness: values?.thickness,
      }).unwrap();

      if (response) {
        handleModalStep();
      }

      if (document) {
        const uploadDocumentParams = adaptUploadDocumentParams(
          document,
          response
        );

        await uploadQualityDocument(uploadDocumentParams).unwrap();
      }

      api.success({
        message: t("quality.success-title"),
        description: t("quality.success-upd-desc"),
      });

      closeModal();
    } catch (error) {
      api.error({
        message: t("quality.error-title"),
        description: handleRequestError(error, t("quality.error-title")),
      });
    }
  };

  const disableButton = !isValid && document === null;

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpenModal}
        onCancel={closeModal}
        title={
          isFirstModalPage
            ? t("quality.update-quality-item")
            : t("quality.update-extra-data")
        }
        width={
          isSecondModalPage ? ModalSizeValue?.LARGE : ModalSizeValue.XL_MEDIUM
        }
        okButtonProps={{
          disabled: disableButton,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onOk={updateQualityResultHandler}
      >
        {values?.result_type === QualityResultResultType.REEL_LAB_RESULT && (
          <Button
            type="primary"
            className="btn-primary--dark"
            onClick={handleModalStep}
          >
            {isFirstModalPage ? t("quality.next-page") : t("quality.prev-page")}
          </Button>
        )}

        {isFirstModalPage && (
          <RequiredToUpdateQualityFields
            handleDocument={handleDocument}
            adaptedMaterials={adaptedMaterials}
            handleStockBatchesRows={handleStockBatchesRows}
            selectedStockBatches={selectedStockBatches}
            selectedStockBatchesKeys={selectedStockBatchesKeys}
            handleStockBatchesKeys={handleStockBatchesKeys}
            handleMaterialItemsRows={handleMaterialItemsRows}
            selectedMaterialItems={selectedMaterialItems}
            selectedMaterialItemsKeys={selectedMaterialItemsKeys}
            handleMaterialItemsKeys={handleMaterialItemsKeys}
            handleDocumentList={handleDocumentList}
            documentList={documentList}
            adaptedOrders={adaptedOrders}
            isLoadingMaterials={isLoadingMaterials}
            isLoadingOrders={isLoadingOrders}
          />
        )}

        {isSecondModalPage && <ExtraData />}
      </Modal>
    </>
  );
};

export { UpdateQualityResultModal };
