import { AddProcessStepValues, DynamicValues } from "shared/types";

import { useEffect, useMemo } from "react";
import { ConfigDynamicTrackerFields, ProcessTemplateItem } from "shared/api";

interface UseProcessStepDynamicDataProps {
  processTemplates?: ProcessTemplateItem[];
  values: AddProcessStepValues;
  handleDynamicValues: (dynamicValues: DynamicValues[]) => void;
  setFieldValue: (field: string, value: any) => void;
}

export const useProcessStepDynamicData = ({
  processTemplates,
  values,
  handleDynamicValues,
  setFieldValue,
}: UseProcessStepDynamicDataProps) => {
  const fieldName = "dynamic_process_step_fields";

  const dynamicProcessTemplateFields = useMemo(() => {
    return processTemplates?.find(
      (dynamicProcessTypeItem: ProcessTemplateItem) =>
        values?.process_template === dynamicProcessTypeItem.id
    );
  }, [processTemplates, values]);

  const createInitialDynamicProcessStepFields = (
    dynamicProcessTemplateFields?: ProcessTemplateItem
  ) => {
    const initialDynamicProcessStepFields: { [key: string]: any } = {};

    dynamicProcessTemplateFields?.config_dynamic_process_step_fields?.forEach(
      (card) => {
        initialDynamicProcessStepFields[card.title] = {};

        card?.fields.forEach((field) => {
          initialDynamicProcessStepFields[card.title][field.name] =
            values?.dynamic_process_step_fields?.[card.title]?.[field.name];
        });
      }
    );

    setFieldValue(fieldName, initialDynamicProcessStepFields);
  };

  useEffect(() => {
    if (dynamicProcessTemplateFields) {
      createInitialDynamicProcessStepFields(dynamicProcessTemplateFields);
      const dynamicValues =
        dynamicProcessTemplateFields?.config_dynamic_process_step_fields;

      handleDynamicValues(dynamicValues as ConfigDynamicTrackerFields[]);
    }
  }, [dynamicProcessTemplateFields]);

  return {
    dynamicProcessTemplateFields,
  };
};
