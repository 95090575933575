import Title from "antd/es/typography/Title";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { FormSelect } from "shared/ui";
import {
  getDynamicFieldName,
  getDynamicUnitSelectValue,
  handleUnitTypeChange,
} from "shared/helpers";
import { dynamicSpecificationTypesEnum } from "shared/constants";
import { UnitItemWithLabel } from "../../api/materials/models";
import { DynamicFormsValuesType } from "./constants";

interface UnitSelectProps {
  index: number;
  fieldIndex: number;
  adaptedDimensionUnits: UnitItemWithLabel[];
  isEdit?: boolean;
}

const UnitSelect: FC<UnitSelectProps> = ({
  index,
  fieldIndex,
  adaptedDimensionUnits,
  isEdit,
}) => {
  const { t } = useTranslation();

  const { handleBlur, setFieldValue, values } =
    useFormikContext<DynamicFormsValuesType>();

  const isChoices =
    values.sections[index].fields[fieldIndex].type ===
    dynamicSpecificationTypesEnum.CHOICES;

  const isText =
    values.sections[index].fields[fieldIndex].type ===
    dynamicSpecificationTypesEnum.TEXT;

  const dynamicFieldName = getDynamicFieldName(
    isText,
    isChoices,
    index,
    fieldIndex
  );

  const dynamicSelectValue = getDynamicUnitSelectValue(
    isText,
    isChoices,
    index,
    fieldIndex,
    values
  );

  const [editChoicesItems, setEditChoicesItems] = useState<string[]>([]);
  const [editUnitItems, setEditUnitItems] = useState<number | null>(null);

  const [newItemValue, setNewItemValue] = useState("");

  const setUnitOrChoicesValues = () => {
    if (editChoicesItems?.length) {
      setFieldValue(
        `sections[${index}].fields[${fieldIndex}].choices`,
        editChoicesItems
      );
    }

    if (editUnitItems) {
      setFieldValue(
        `sections[${index}].fields[${fieldIndex}].unit`,
        editUnitItems
      );
    }
  };

  const currentField = values?.sections?.[index]?.fields?.[fieldIndex];

  const { choices, unit } = useMemo(() => {
    return currentField || {};
  }, [currentField]);

  useEffect(() => {
    if (isEdit) {
      setEditUnitItems(unit as number);
      setEditChoicesItems(choices as string[]);
    }
  }, [isEdit, choices, unit]);

  useEffect(() => {
    setUnitOrChoicesValues();
  }, [editChoicesItems, editUnitItems]);

  const valueType = values?.sections?.[index]?.fields?.[fieldIndex]?.type;

  useEffect(() => {
    handleUnitTypeChange(values, index, fieldIndex, setFieldValue);
  }, [valueType]);

  return (
    <div className="w-[16%]">
      <Title
        level={5}
        className={classNames("mt-0", {
          "-mt-[8px]": isChoices,
        })}
      >
        {isChoices
          ? t("material-category-management.choices")
          : t("material-category-management.unit")}
      </Title>

      <FormSelect
        key={dynamicFieldName}
        value={dynamicSelectValue}
        placeholder={
          editChoicesItems?.length
            ? t("material-management.unit-select-full")
            : t("material-management.unit-select-empty")
        }
        fieldName={dynamicFieldName!}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        maxTagCount={1}
        maxTagTextLength={2}
        disabled={isText}
        withLabels
        optionsWithLabels={isChoices ? editChoicesItems : adaptedDimensionUnits}
        multiple={isChoices}
        extendedMultipleWithLabel={isChoices}
        addItemSelect={!!isChoices}
        setNewItemValue={setNewItemValue}
        newItemValue={newItemValue}
        setNewSelectItems={setEditChoicesItems}
        newSelectItems={Array.isArray(editChoicesItems) ? editChoicesItems : []}
      />
    </div>
  );
};

export { UnitSelect };
