import { PlusOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import Table from "antd/es/table";
import { FormikProvider, useFormik } from "formik";
import { AddMaterialItemModal } from "pages/SupplyChain/MaterialManagement/ui/MaterialItems/AddMaterialItemModal";
import { EditStockItemModal } from "pages/SupplyChain/MaterialManagement/ui/MaterialItems/EditStockItemModal";
import { createAddOrUpdateMaterialItemSchema } from "pages/SupplyChain/MaterialManagement/ui/MaterialItems/schemas/schemas";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { adaptDynamicFormValues } from "shared/adapters";
import {
  MaterialsItem,
  MaterialStockItem,
  StockMaterialItem,
  useDeleteStockMaterialItemMutation,
  useGetMaterialItemsByIDQuery,
} from "shared/api";
import {
  expandMaterialItemsStringWithUnit,
  materialItemInitialValues,
} from "shared/constants";
import { handleRequestError, noop, Unit } from "shared/helpers";
import {
  useConfirmDeleteModal,
  useDebouncedTableFilter,
  useMaterialItemsColumnInMaterialManagement,
  useModal,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import {
  AddStockMaterialItemValues,
  DynamicValues,
  GlobalMaterialItem,
} from "shared/types";

interface UpdatedMaterialItemsProps {
  materialStockBatchForMaterialItems: MaterialStockItem | null;
  selectedMaterial: MaterialsItem | null;
}

const UpdatedMaterialItems: FC<UpdatedMaterialItemsProps> = ({
  materialStockBatchForMaterialItems,
  selectedMaterial,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [deleteStockMaterialItem] = useDeleteStockMaterialItemMutation();

  const UNIT_ABBREVIATION = (selectedMaterial?.material_category?.unit as Unit)
    ?.abbreviation;

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    usePagination();

  const { ordering, changeSort } = useTableSorting();

  const [
    isOpenAddMaterialItemModal,
    showAddMaterialItemModal,
    hideAddMaterialModal,
  ] = useModal();

  const [
    isOpenEditMaterialItemModal,
    showEditMaterialItemModal,
    hideEditMaterialItemModal,
  ] = useModal();

  const {
    debouncedValue: debouncedStatusIm,
    handleFilterChange: handledStatusIm,
    value: statusInValue,
    clearFilterValue: clearStatusInValue,
  } = useDebouncedTableFilter("", 400);

  const [dynamicValues, setDynamicValues] = useState<DynamicValues[]>([]);
  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  useEffect(() => {
    if (materialStockBatchForMaterialItems) {
      const materialWithUpdatedType =
        materialStockBatchForMaterialItems as unknown as StockMaterialItem;

      const dynamicValues =
        materialWithUpdatedType?.material?.material_category
          ?.config_dynamic_tracker_fields;

      setDynamicValues(dynamicValues);
    }
  }, [materialStockBatchForMaterialItems]);

  const addOrUpdateStockMaterialItemFormik =
    useFormik<AddStockMaterialItemValues>({
      initialValues: materialItemInitialValues,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: createAddOrUpdateMaterialItemSchema(
        dynamicFieldsValidation
      ),
      enableReinitialize: true,
      onSubmit: noop,
    });

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetMaterialItemsByIDQuery(
    {
      material_stock_batch: materialStockBatchForMaterialItems?.id,
      expand: expandMaterialItemsStringWithUnit,
      limit,
      offset,
      ordering,
      status__in: debouncedStatusIm.toUpperCase(),
    },
    { skip: materialStockBatchForMaterialItems === null }
  );

  const editStockMaterialHandler = (record: MaterialStockItem) => {
    showEditMaterialItemModal();
    addOrUpdateStockMaterialItemFormik.setValues({
      id: record.id,
      dynamic_tracker_fields: record.dynamic_tracker_fields,
      material_stock_batch: record.material_stock_batch.id,
      quantity: record.quantity,
      serial_number: record.serial_number,
      status: record.status,
      situation: record.situation,
    });
  };

  const deleteStockMaterialItemHandler = async (record: MaterialStockItem) => {
    try {
      await deleteStockMaterialItem(record).unwrap();
      api.success({
        message: t("stock-material-item.success-title"),
        description: t("stock-material-item.success-delete-desc"),
      });
    } catch (error: any) {
      api.error({
        message: t("stock-material-item.error-title"),
        description: handleRequestError(
          error,
          t("stock-material-item.error-title")
        ),
      });
    }
  };

  const showDeleteCategoryModal = useConfirmDeleteModal(
    t("stock-material-item.delete-modal-msg"),
    deleteStockMaterialItemHandler
  );

  const columns = useMaterialItemsColumnInMaterialManagement({
    t,
    unitAbbreviation: UNIT_ABBREVIATION,
    changeSort,
    clearStatusInValue,
    statusInValue,
    handledStatusIm,
    editStockMaterialHandler,
    showDeleteCategoryModal,
  });

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateStockMaterialItemFormik}>
        <Button
          type="primary"
          className="btn-primary--dark "
          onClick={showAddMaterialItemModal}
        >
          {t("stock-material.add-item")}
          <PlusOutlined />
        </Button>
        <Table
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={materialItems?.results}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: materialItems?.count,
          }}
          className={!materialItems?.results.length ? "hidden" : "block"}
        />

        <AddMaterialItemModal
          isOpenModal={isOpenAddMaterialItemModal}
          hideAddMaterialModal={hideAddMaterialModal}
          materialStockBatchID={
            materialStockBatchForMaterialItems?.id as number
          }
          selectedMaterialItemFromStock={
            selectedMaterial as unknown as GlobalMaterialItem
          }
          dynamicValues={dynamicValues}
          preselectedMaterialName={selectedMaterial?.name}
        />

        <EditStockItemModal
          isOpenModal={isOpenEditMaterialItemModal}
          hideEditMaterialItemModal={hideEditMaterialItemModal}
          materialStockBatchID={
            materialStockBatchForMaterialItems?.id as number
          }
          selectedMaterialItemFromStock={
            selectedMaterial as unknown as GlobalMaterialItem
          }
          dynamicValues={dynamicValues}
          preselectedMaterialName={selectedMaterial?.name}
        />
      </FormikProvider>
    </>
  );
};

export { UpdatedMaterialItems };
