import * as Yup from "yup";

export const addStandAloneSchema = Yup.object().shape({
  name: Yup.string().nullable().required("standAlone.fieldIsRequired"),
  description: Yup.string().nullable().required("standAlone.fieldIsRequired"),
  url: Yup.string().nullable().url().required("standAlone.fieldIsRequired"),
  token: Yup.string().nullable().required("standAlone.fieldIsRequired"),
});

export const updateStandAloneSchema = Yup.object().shape({
  name: Yup.string().nullable().required("standAlone.fieldIsRequired"),
  description: Yup.string().nullable().required("standAlone.fieldIsRequired"),
  url: Yup.string().nullable().url().required("standAlone.fieldIsRequired"),
});
