import { UploadFile, UploadProps, message } from "antd";
import { adaptCompanies } from "shared/adapters";
import { SubmitCreateMaterialValues } from "shared/types";
import {
  ExpandUnitItem,
  MaterialCategoriesItem,
  MaterialCategoriesResponse,
  MaterialStockItem,
  SupplierItemWithCost,
} from "shared/api";
import { UploadExcelFormValues } from "../../../pages/SupplyChain/MaterialManagement/ui/UploadExcel";
import { SupplierCompanyItem } from "../../api/suppliers/models";
import { includesExcelFile } from "../qualityResults";

export const downloadExcelFileHelper = (response: Blob, fileName?: string) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName || Date.now()}.xls`);
  document.body.appendChild(link);
  link.click();
};

export const downloadPdfFileHelper = (response: Blob, fileName?: string) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName || Date.now()}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const filterSuppliersBasedOnIdList = (
  companies: SupplierCompanyItem[],
  suppliers?: SupplierItemWithCost[]
) => {
  const adaptedCompanies = adaptCompanies(companies || []);

  const supplierIds = suppliers?.map((supplier) => supplier?.supplier?.id);

  const filteredItems = supplierIds?.filter((supplierId) =>
    adaptedCompanies?.some((company) => company?.value === supplierId)
  );

  const suppliersForTheCurrentMaterial = filteredItems?.map((id) => ({
    label: adaptedCompanies?.find((company) => company?.value === id)?.label,
    value: id,
  }));

  return suppliersForTheCurrentMaterial;
};

export const generateExcelFileFormData = (
  xlsxFile: File | null,
  materialId: number,
  values: UploadExcelFormValues
) => {
  const formData = new FormData();

  if (xlsxFile) {
    formData.append("file", xlsxFile);
  }
  formData.append("material", materialId?.toString() || "");
  formData.append("supplier", values.supplier?.toString() || "");
  formData.append("delivery_date", values.delivery_date || "");
  // TO-DO: this field is temporary empty string. Waiting for backend update to generate dynamic batch_code
  formData.append("batch_code", "");

  return formData;
};

export const generateUploadExcelFileProps = (
  handleXlsxFile: (file: File | null) => void
) => {
  const props: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isXls =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls) {
        message.error("You can only upload XLS files!");
      }

      if (isXls) {
        handleXlsxFile(file);
      }

      return false;
    },
    onRemove: () => {
      handleXlsxFile(null);
    },
  };

  return props;
};

export const renderUnitName = (
  values: SubmitCreateMaterialValues,
  materialCategories: MaterialCategoriesItem[]
) => {
  const materialCategoryAsNumber =
    values?.material_category as unknown as number;

  const filteredMaterialCategory = materialCategories?.find(
    (item) =>
      item?.id === values?.material_category?.id ||
      item?.id === materialCategoryAsNumber
  );

  const unit = filteredMaterialCategory?.unit as ExpandUnitItem;

  return unit?.name;
};

export const disableStockManagementUploadExcelButton = (
  documentList: UploadFile<any>[],
  isValid: boolean
) => {
  const isContainsExcelFiles = includesExcelFile(documentList);

  return !isContainsExcelFiles || !isValid;
};

export const showCostPerUnitTitle = (
  costPerUnit: string,
  emptyState: string
) => {
  return costPerUnit !== null ? Number(costPerUnit).toFixed(2) : emptyState;
};

export const getDynamicArticleData = (articleData: MaterialStockItem) => {
  const material = articleData?.material_stock_batch?.material;

  return material?.material_category?.config_dynamic_tracker_fields || [];
};

export const getCostPerValues = (
  values: SubmitCreateMaterialValues,
  supplierIndexToChange: number
) => {
  const costPerUnitCurrencyValue =
    values?.suppliers?.[supplierIndexToChange]?.cost_per_unit_currency;

  const costPerUnitCurrencyFieldName = `suppliers.${supplierIndexToChange}.cost_per_unit_currency`;

  const costPerUnitValie =
    values?.suppliers?.[supplierIndexToChange]?.cost_per_unit;

  const costPerUnitFieldName = `suppliers.${supplierIndexToChange}.cost_per_unit`;

  return {
    costPerUnitCurrencyValue,
    costPerUnitCurrencyFieldName,
    costPerUnitValie,
    costPerUnitFieldName,
  };
};

export const updateMaterialCategoryToSetNewInformationField = (
  allMaterialCategories: MaterialCategoriesResponse,
  categoryToShowSuppliers: MaterialCategoriesItem | null,
  handleSetCategoryToShowSuppliers: (
    record: MaterialCategoriesItem | null
  ) => void
) => {
  const itemToUpdateInTheModal = allMaterialCategories?.results?.find(
    (materialCategory) => materialCategory.id === categoryToShowSuppliers?.id
  );

  handleSetCategoryToShowSuppliers(itemToUpdateInTheModal!);
};
