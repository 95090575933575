import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Popover } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";

interface ProcessStepTitleProps {
  decrementProcessStepIndex: () => void;
  incrementProcessStepIndex: () => void;
  processStepId?: number;
  processStepName?: string;
}

const ProcessStepTitle: FC<ProcessStepTitleProps> = ({
  decrementProcessStepIndex,
  incrementProcessStepIndex,
  processStepId,
  processStepName,
}) => {
  const { t } = useTranslation();

  const processStepHyperlink =
    `${Routes.processSteps.hyperlink}/${processStepId}`.replace(":id/", "");

  return (
    <div className="flex gap-x-2">
      <LeftOutlined
        className="w-6 h-6 flex items-center justify-center !text-white self-center"
        onClick={decrementProcessStepIndex}
      />

      <Popover
        placement="right"
        content={
          <Alert showIcon type="info" message={t("orders.process-step-link")} />
        }
      >
        <Title
          level={5}
          className={classNames(
            "!text-white flex items-center !m-0 cursor-pointer !text-[14px]",
            {}
          )}
          onClick={() => openHyperlinkInTab(processStepHyperlink)}
        >
          {processStepName}
        </Title>
      </Popover>

      <RightOutlined
        className="w-6 h-6 flex items-center justify-center !text-white self-center"
        onClick={incrementProcessStepIndex}
      />
    </div>
  );
};

export { ProcessStepTitle };
