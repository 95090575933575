import { Alert } from "antd";
import Title from "antd/es/typography/Title";
import { DynamicFieldsEnum } from "shared/constants";
import React, { FC } from "react";
import { ConfigDynamicTrackerFields } from "shared/api";
import { DynamicSelectInput, DynamicTextTypeInput } from "shared/features";
import { DynamicNumberInput } from "shared/features/DynamicFormsComponents/DynamicIntegerInput";
import { renderDynamicInputType } from "shared/helpers";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { AddStockMaterialItemValues, DynamicValues } from "shared/types";

interface DynamicArticleHyperlinkDataProps {
  dynamicValues: DynamicValues[];
}

const DynamicArticleHyperlinkData: FC<DynamicArticleHyperlinkDataProps> = ({
  dynamicValues,
}) => {
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext<AddStockMaterialItemValues>();

  return (
    <>
      <Title level={5}>{t("material-management.dynamic-tracker-fields")}</Title>

      {!dynamicValues?.length && (
        <Alert
          type="info"
          showIcon
          message={t("material-management.no-dynamic-tracker-fields")}
        />
      )}

      {dynamicValues?.map((card: ConfigDynamicTrackerFields) => (
        <div
          key={card.title}
          className="w-full h-auto p-2.5 mb-2.5 rounded-xl shadow-dynamicFormCard mt-3 "
        >
          <Title level={4}>{card?.title}</Title>
          <div>
            {card?.fields.map((field, fieldIndex: number) => {
              const cardFieldName = `dynamic_tracker_fields.${card.title}.${field.name}`;

              const { value } = getFieldProps(cardFieldName);

              return (
                <div key={cardFieldName}>
                  {field.type === DynamicFieldsEnum.EXPRESSION ? null : (
                    <Title level={5} className="!text-primary">
                      {field.name}. {t("orders.type")} -{" "}
                      {renderDynamicInputType(field?.type)}
                    </Title>
                  )}

                  {field.type === DynamicFieldsEnum.TEXT && (
                    <DynamicTextTypeInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.INTEGER && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.FLOAT && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.CHOICES && (
                    <DynamicSelectInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export { DynamicArticleHyperlinkData };
