import { LeftCircleOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React, { FC } from "react";
import { OrderStepTrackerResponseItem } from "shared/api";
import { Routes } from "shared/routers";
import { Link } from "react-router-dom";
import { GeneratePdfFilesContainer } from "./GeneratePdfFilesContainer";
import { OrderStatus } from "./OrderStatus";
import { OrderCodeInfo } from "./OrderCodeInfo";
import { ProcessStepTitle } from "./ProcessStepTitle";

interface MobileAndTabletProdPageHeaderProps {
  decrementProcessStepIndex: () => void;
  incrementProcessStepIndex: () => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  closeProdPageModal: () => void;
  navigateOrder: (direction: "next" | "previous") => void;
  isLoadingOrderCodes: boolean;
}

const MobileAndTabletProdPageHeader: FC<MobileAndTabletProdPageHeaderProps> = ({
  incrementProcessStepIndex,
  decrementProcessStepIndex,
  orderStepTrackerResponseItem,
  closeProdPageModal,
  navigateOrder,
  isLoadingOrderCodes,
}) => {
  const orderCode = orderStepTrackerResponseItem?.order?.order_code;

  const processStep = orderStepTrackerResponseItem?.process_step;
  const processStepName = processStep?.name;
  const processStepId = processStep?.id;

  return (
    <div
      className="p-2 bg-blue-primary w-full brightness-150"
      style={{ position: "sticky", top: 0, width: "100%", zIndex: 1000 }}
    >
      <div>
        <div className="flex items-center justify-between">
          <div className="flex gap-x-2">
            <OrderCodeInfo
              navigateOrder={navigateOrder}
              orderCode={orderCode}
              isLoadingOrderCodes={isLoadingOrderCodes}
            />

            <OrderStatus
              orderStepTrackerResponseItem={orderStepTrackerResponseItem}
            />
          </div>

          <Link
            to={Routes.orders.url}
            className="self-center  flex items-center justify-center h-full"
          >
            <LeftCircleOutlined
              onClick={closeProdPageModal}
              className="!text-white !ml-2 "
            />
          </Link>
        </div>

        <Divider className="bg-white my-2 w-full" />

        <ProcessStepTitle
          decrementProcessStepIndex={decrementProcessStepIndex}
          incrementProcessStepIndex={incrementProcessStepIndex}
          processStepId={processStepId}
          processStepName={processStepName}
        />

        <Divider className="bg-white my-2 w-full" />

        <GeneratePdfFilesContainer
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        />
      </div>
    </div>
  );
};

export { MobileAndTabletProdPageHeader };
