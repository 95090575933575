import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useUpdatePackageMutation } from "shared/api";
import { AdaptedValues, AddPackageValues } from "shared/types";
import { ModalSizeValue, packageSituations } from "shared/constants";

interface EditPackagingModalProps {
  isOpenModal: boolean;
  hideEditAddPackagingModal: () => void;
  adaptedMaterials: AdaptedValues[];
  orderID: number | null;
  isLoadingMaterials: boolean;
}

const EditPackagingModal: FC<EditPackagingModalProps> = ({
  isOpenModal,
  hideEditAddPackagingModal,
  adaptedMaterials,
  orderID,
  isLoadingMaterials,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const { t } = useTranslation();

  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddPackageValues>();

  const closeModal = () => {
    setFieldValue("order", null);
    hideEditAddPackagingModal();
    resetForm();
  };

  const [updatePackage] = useUpdatePackageMutation();

  const updatePackageRequest = async () => {
    try {
      await updatePackage({
        id: values.id!,
        name: values.name!,
        material: Number(values.material),
        order: orderID!,
        situation: values.situation!,
      }).unwrap();
      api.success({
        message: t("packaging.success"),
        description: t("packaging.update-msg"),
      });
      hideEditAddPackagingModal();
      setFieldValue("name", null);
      setFieldValue("material", null);
    } catch (error) {
      const description = handleRequestError(error, t("packaging.error"));

      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("packaging.edit-item")}
        open={isOpenModal}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          updatePackageRequest();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("packaging.order")}
        </Title>

        <FormInput
          value={`${t("packaging.order")} ${orderID}`}
          fieldName="orderID"
          placeholder={t("packaging.order")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled
          error={errors.order}
          isError={!!errors.order}
        />

        <Title level={5} className="mb-1.25">
          {t("packaging.name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("packaging.name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("packaging.material")}
        </Title>

        <FormSelect
          value={values.material}
          placeholder={t("packaging.material-placeholder")}
          fieldName="material"
          options={adaptedMaterials}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.material?.toString()}
          isError={!!errors.material}
          isLoading={isLoadingMaterials}
        />

        <Title level={5} className="mb-1.25">
          {t("packaging.situation")}
        </Title>

        <FormSelect
          value={values.situation}
          placeholder={t("packaging.situation-placeholder")}
          fieldName="situation"
          options={packageSituations}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          showSearch
        />
      </Modal>
    </>
  );
};

export { EditPackagingModal };
