import { Button, Popover } from "antd";
import React, { FC } from "react";
import { CompanyItem } from "shared/api";
import { useModal } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { UpdateCategorySupplierInformationDialog } from "./UpdateCategorySupplierInformationDialog";

interface CategorySupplierInformationFieldProps {
  company: CompanyItem;
  refetchMaterialCategoriesHandler?: () => void;
}

const CategorySupplierInformationField: FC<
  CategorySupplierInformationFieldProps
> = ({ company, refetchMaterialCategoriesHandler }) => {
  const { t } = useTranslation("", { keyPrefix: "companies" });

  const { information } = company;

  const truncatedInformation =
    information && information?.length > 30
      ? `${information?.substring(0, 30)}...`
      : information;

  const [isOpenInformationModal, showInformationModal, hideInformationModal] =
    useModal();

  return (
    <>
      <Popover content={t("click-to-update-information")}>
        <Button onClick={showInformationModal} className="w-full">
          {truncatedInformation || t("no-information-yet")}
        </Button>
      </Popover>

      <UpdateCategorySupplierInformationDialog
        isOpen={isOpenInformationModal}
        onClose={hideInformationModal}
        company={company}
        refetchMaterialCategoriesHandler={refetchMaterialCategoriesHandler}
      />
    </>
  );
};

export { CategorySupplierInformationField };
