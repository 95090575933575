import { Divider, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterialItemsData, adaptQualityResults } from "shared/adapters";
import {
  QualityResultItem,
  useGetAllMaterialItemsQuery,
  useGetQualityResultsQuery,
} from "shared/api";
import { selectFiltering } from "shared/helpers";
import { Colors } from "shared/themes";
import { AdaptedValues, ExecuteReportValues } from "shared/types";
import { FormSelect, Loader } from "shared/ui";

interface IsReelLabFiltersProps {
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
  selectedMaterialId: number | null;
  onMaterialChange: (value: number | null) => void;
}

const IsReelLabFilters: FC<IsReelLabFiltersProps> = ({
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
  selectedMaterialId,
  onMaterialChange,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values, setFieldValue, handleBlur } =
    useFormikContext<ExecuteReportValues>();

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetAllMaterialItemsQuery(
    {
      order: selectedOrderId ?? undefined,
    },
    { skip: !selectedOrderId }
  );

  const adaptedMaterialItems = useMemo(() => {
    return adaptMaterialItemsData(materialItems ?? []);
  }, [materialItems]);

  const {
    data: qualityResults,
    isLoading: isLoadingQualityResults,
    isFetching: isFetchingQualityResults,
  } = useGetQualityResultsQuery(
    {
      material_items__material_item: Number(selectedMaterialId) ?? undefined,
    },
    { skip: !selectedMaterialId }
  );

  const adaptedQualityResults = useMemo(() => {
    return adaptQualityResults(
      (qualityResults as unknown as QualityResultItem[]) ?? []
    );
  }, [qualityResults]);

  if (
    isLoading ||
    isFetching ||
    isLoadingQualityResults ||
    isFetchingQualityResults
  ) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <div className="flex gap-x-3">
      <div className="flex gap-x-2">
        <Title level={5} className="!w-[80px] !m-0 !text-[14px]">
          {t("selectOrder")}
        </Title>
        {" - "}
        <Select
          value={selectedOrderId}
          placeholder={t("selectOrder")}
          options={adaptedOrders}
          onChange={onOrderChange}
          className="w-full"
          size="small"
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
        />
      </div>

      {selectedOrderId && (
        <>
          <Divider
            type="vertical"
            className="h-full"
            style={{ background: Colors.blue.primary }}
          />

          <div className="flex gap-x-2">
            <Title level={5} className="!w-[160px] !m-0 !text-[14px]">
              {t("selectMaterialItem")}
            </Title>
            {" - "}
            <Select
              value={selectedMaterialId}
              placeholder={t("selectMaterialItem")}
              options={adaptedMaterialItems}
              onChange={onMaterialChange}
              className="w-full"
              size="small"
              showSearch
              filterOption={(input, option) => selectFiltering(input, option)}
            />
          </div>
        </>
      )}

      {selectedMaterialId && (
        <>
          <Divider
            type="vertical"
            className="h-full"
            style={{ background: Colors.blue.primary }}
          />

          <div className="flex gap-x-2">
            <Title level={5} className="!w-[170px] !m-0 !text-[14px]">
              {t("selectQualityResult")}
            </Title>
            {" - "}
            <FormSelect
              value={values?.quality_result_id}
              placeholder={t("selectQualityResult")}
              fieldName="quality_result_id"
              options={adaptedQualityResults}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              showSearch
              size="small"
              className="!m-0"
            />
          </div>
        </>
      )}
    </div>
  );
};

export { IsReelLabFilters };
