import { DynamicFieldsEnum } from "shared/constants";
import { DynamicValues } from "shared/types";
import { ConfigDynamicTrackerFields } from "shared/api";
import * as Yup from "yup";

export const adaptDynamicFormValues = (
  dynamicValues: DynamicValues[] | ConfigDynamicTrackerFields[]
) => {
  const dynamicFieldsValidation = dynamicValues
    ?.map((card: DynamicValues) => {
      return {
        [card.title]: Yup.object({
          ...card.fields.reduce((acc, field) => {
            let yupSchema;

            if (field?.type === DynamicFieldsEnum.INTEGER) {
              yupSchema = Yup.number().integer();
            }

            if (field?.type === DynamicFieldsEnum.FLOAT) {
              yupSchema = Yup.number();
            }

            if (field?.type === DynamicFieldsEnum.TEXT) {
              yupSchema = Yup.string();
            }
            if (field?.type === DynamicFieldsEnum.CHOICES) {
              yupSchema = Yup.string() || Yup.array();
            }

            if (field?.required) {
              yupSchema = yupSchema?.required(`${field.name} is required`);
            }

            if (!field?.required) {
              yupSchema = yupSchema?.optional().nullable();
            }

            // Commented hard & soft limits validations
            // But don't remove this code because we can move this logic back

            // if (field?.hard_minimum) {
            //   yupSchema = yupSchema?.min(
            //     field.hard_minimum,
            //     `${field.name} must be greater than or equal to ${field.hard_minimum}`
            //   );
            // }

            // if (field?.hard_maximum) {
            //   yupSchema = yupSchema?.max(
            //     field.hard_maximum,
            //     `${field.name} must be less than or equal to ${field.hard_maximum}`
            //   );
            // }

            return {
              ...acc,
              [field.name]: yupSchema,
            };
          }, {}),
        }),
      };
    })
    .flat();

  return dynamicFieldsValidation;
};
