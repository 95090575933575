import { useState } from "react";

import { useDebouncedTableFilter } from "./useDebouncedTableFilter";

export const useUpdatedMaterialManagementsFilters = () => {
  const {
    debouncedValue: debouncedNameContains,
    handleFilterChange: handleNamedValue,
    value: nameValue,
    clearFilterValue: clearNameValue,
  } = useDebouncedTableFilter("", 400);

  const [materialCategoryFilteringItem, setMaterialCategoryFilteringItem] =
    useState<number | string>("");

  const handleMaterialCategory = (value: number | string) => {
    setMaterialCategoryFilteringItem(value);
  };

  const isVisibleClearFiltersButton =
    !!debouncedNameContains || materialCategoryFilteringItem;

  const clearMaterialCategoryFilter = () => {
    setMaterialCategoryFilteringItem("");
  };

  const clearAllFilters = () => {
    clearNameValue();
    clearMaterialCategoryFilter();
  };

  return {
    debouncedNameContains,
    handleNamedValue,
    nameValue,
    materialCategoryFilteringItem,
    setMaterialCategoryFilteringItem,
    clearAllFilters,
    isVisibleClearFiltersButton,
    clearNameValue,
    handleMaterialCategory,
  };
};
