import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "shared/ui";
import { UploadDocumentValues } from "shared/types";
import { ExtraDataTable } from "./ExtraDataTable";

const ExtraData = () => {
  const { t } = useTranslation();

  const { values, handleBlur, handleChange } =
    useFormikContext<UploadDocumentValues>();

  return (
    <>
      <Title level={5}>{t("quality.thickness")}</Title>

      <FormInput
        value={values?.thickness}
        fieldName="thickness"
        placeholder={t("quality.thickness")}
        handleBlur={handleBlur}
        handleChange={handleChange}
        numberType
      />

      <Title level={5}>{t("quality.curl_md")}</Title>

      <FormInput
        value={values?.curl_md}
        fieldName="curl_md"
        placeholder={t("quality.curl_md")}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <Title level={5}>{t("quality.curl_cd")}</Title>

      <FormInput
        value={values?.curl_cd}
        fieldName="curl_cd"
        placeholder={t("quality.curl_cd")}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <Title level={5}>{t("quality.remark")}</Title>

      <FormInput
        value={values?.remark}
        fieldName="remark"
        placeholder={t("quality.remark")}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <ExtraDataTable />
    </>
  );
};

export { ExtraData };
