import { Divider, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { ExecuteReportValues, AdaptedValues } from "shared/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { selectFiltering } from "shared/helpers";

interface OrderTypeFilterProps {
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
}

const OrderTypeFilter: FC<OrderTypeFilterProps> = ({
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { setFieldValue } = useFormikContext<ExecuteReportValues>();

  const expandOnOrderChange = (value: number) => {
    onOrderChange(value);
    setFieldValue("order_id", value);
  };

  return (
    <>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />

      <div className="flex gap-x-2">
        <Title level={5} className="!w-[100px] !m-0 !text-start">
          {t("selectOrder")}
        </Title>
        {" - "}
        <Select
          value={selectedOrderId}
          placeholder={t("selectOrder")}
          options={adaptedOrders}
          onChange={expandOnOrderChange}
          className="w-auto"
          size="small"
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
        />
      </div>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />
    </>
  );
};

export { OrderTypeFilter };
