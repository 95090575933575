import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AddDashboardParams,
  Dashboard,
  DashboardByIdParams,
  DeleteDashboardParams,
  UpdateDashboardParams,
  Widget,
} from "./models";

export const dashboardsApi = createApi({
  baseQuery,
  reducerPath: "dashboardsApi",
  tagTypes: ["dashboards"],
  endpoints: (builder) => ({
    getAllDashboards: builder.query<Dashboard[], void>({
      query: () => ({
        url: "/api/v1/dashboards/",
        method: "GET",
      }),
      providesTags: ["dashboards"],
    }),

    getDashboardById: builder.query<Dashboard, DashboardByIdParams>({
      query: (params) => ({
        url: `/api/v1/dashboards/${params.id}/`,
        method: "GET",
        params,
      }),
      providesTags: ["dashboards"],
    }),

    addDashboard: builder.mutation<Dashboard, AddDashboardParams>({
      query: (body) => ({
        url: "/api/v1/dashboards/",
        method: "POST",
        body,
      }),

      invalidatesTags: ["dashboards"],
    }),

    updateDashboard: builder.mutation<void, UpdateDashboardParams>({
      query: (body) => ({
        url: `/api/v1/dashboards/${body.id}/`,
        method: "PUT",
        body,
      }),

      invalidatesTags: ["dashboards"],
    }),

    deleteDashboard: builder.mutation<void, DeleteDashboardParams>({
      query: (params) => ({
        url: `/api/v1/dashboards/${params.id}/`,
        method: "DELETE",
        params,
      }),

      invalidatesTags: ["dashboards"],
    }),

    updateWidget: builder.mutation<void, Widget>({
      query: (body) => ({
        url: `/api/v1/widgets/${body.id}/`,
        method: "PUT",
        body,
      }),

      invalidatesTags: ["dashboards"],
    }),
  }),
});

export const {
  useGetAllDashboardsQuery,
  useGetDashboardByIdQuery,
  useAddDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
  // widgets
  useUpdateWidgetMutation,
} = dashboardsApi;
