import { BatchStatus } from "shared/api";

export const expandMaterials = "suppliers.supplier,material_category.unit";
export const expandSuppliers = "suppliers";
export const expandMaterialCategory = "material_category";
export const expandMaterialReservation = "material.material_category.unit";

export const enum MaterialTableSorting {
  MATERIAL_ASC = "name",
  MATERIAL_DESC = "-name",
  MATERIAL_CATEGORY_ASC = "material_category__name",
  MATERIAL_CATEGORY_DESC = "-material_category__name",
  REMAINING_ASC = "calc_total_remaining_quantity",
  REMAINING_DESC = "-calc_total_remaining_quantity",
  REMAINING_PERCENTAGE_ASC = "calc_percentage_remaining",
  REMAINING_PERCENTAGE_DESC = "-calc_percentage_remaining",
  TOTAL_RESERVED_ASC = "calc_total_reserved_quantity",
  TOTAL_RESERVED_DESC = "-calc_total_reserved_quantity",
  TOTAL_AVAILABLE_ASC = "calc_total_available_quantity",
  TOTAL_AVAILABLE_DESC = "-calc_total_available_quantity",
  PERCENTAGE_AVAILABLE_ASC = "calc_percentage_available",
  PERCENTAGE_AVAILABLE_DESC = "-calc_percentage_available",
  MISSING_ASC = "calc_total_missing_quantity",
  MISSING_DESC = "-calc_total_missing_quantity",
  MISSING_PERCENTAGE_ASC = "calc_percentage_missing",
  MISSING_PERCENTAGE_DESC = "-calc_percentage_missing",
}

export const columnsMaterialManagementData = {
  name: {
    title: "materialManagementColumns.name",
    dataIndex: "name",
    key: "name",
    width: "1%",
  },
  material_category: {
    title: "materialManagementColumns.category",
    dataIndex: "material_category",
    key: "material_category",
    width: "3%",
  },
  targetQuantityInStock: {
    title: "materialManagementColumns.targetQnt",
    dataIndex: "target_quantity_in_stock",
    key: "target_quantity_in_stock",
    width: "8%",
  },
  remaining: {
    title: "materialManagementColumns.remFull",
    shortTitle: "materialManagementColumns.remShort",
    width: "1%",
  },
  remainingPercentage: {
    title: "materialManagementColumns.rem%Full",
    shortTitle: "materialManagementColumns.rem%Short",
    width: "1%",
  },
  reserved: {
    title: "materialManagementColumns.resFull",
    shortTitle: "materialManagementColumns.resShort",
    width: "1%",
  },
  available: {
    title: "materialManagementColumns.avFull",
    shortTitle: "materialManagementColumns.avShort",
    width: "1%",
  },
  availablePercentage: {
    title: "materialManagementColumns.av%Full",
    shortTitle: "materialManagementColumns.av%Short",
    width: "1%",
  },
  missing: {
    title: "materialManagementColumns.misFull",
    shortTitle: "materialManagementColumns.misShort",
    width: "1%",
  },
  missingPercentage: {
    title: "materialManagementColumns.mis%Full",
    shortTitle: "materialManagementColumns.mis%Short",
    width: "1%",
  },

  actions: {
    title: "materialManagementColumns.actions",
    key: "actions",
    width: "1%",
  },
};

export const columnsSuppliersData = {
  key: "sort",
  keyWidth: "5%",
  name: {
    title: "columnsSuppliersData.name",
    dataIndex: "name",
    key: "name",
    width: "10%",
  },
  address: {
    title: "columnsSuppliersData.address",
    dataIndex: "address",
    key: "address",
    width: "30%",
  },
  evaluationAsSupplier: {
    title: "columnsSuppliersData.evaluation",
    dataIndex: "evaluationAsSupplier",
    key: "evaluationAsSupplier",
    width: "2%",
  },
  costPeruUnitCurrency: {
    title: "columnsSuppliersData.costPer",
    dataIndex: "cost_per_unit_currency",
    key: "cost_per_unit_currency",
    width: "5%",
  },
  costPerUnit: {
    title: "columnsSuppliersData.costPer",
    dataIndex: "cost_per_unit",
    key: "cost_per_unit",
    width: "5%",
  },
  actions: {
    title: "columnsSuppliersData.actions",
    key: "actions",
    width: "5%",
  },
};

export const stockMaterialTableColumnsData = {
  material: {
    title: "stockTableColumns.material",
    dataIndex: "material",
    key: "material",
    width: "10%",
  },
  batchCode: {
    title: "stockTableColumns.bCode",
    dataIndex: "batch_code",
    key: "batch_code",
    width: "10%",
  },
  quantity: {
    title: "stockTableColumns.qnt",
    dataIndex: "quantity",
    key: "quantity",
    width: "10%",
  },

  deliveryDate: {
    title: "stockTableColumns.deliveryData",
    dataIndex: "delivery_date",
    key: "delivery_date",
    width: "5%",
  },
  status: {
    title: "stockTableColumns.status",
    dataIndex: "status",
    key: "status",
    width: "10%",
  },
  calcTotalOriginalQuantity: {
    title: "stockTableColumns.toq",
    dataIndex: "calc_total_original_quantity",
    key: "calc_total_original_quantity",
    width: "10%",
  },
  calcTotalConsumedQuantity: {
    title: "stockTableColumns.tcq",
    dataIndex: "calc_total_consumed_quantity",
    key: "calc_total_consumed_quantity",
    width: "10%",
  },
  calcTotalRemainingQuantity: {
    title: "stockTableColumns.trq",
    dataIndex: "calc_total_remaining_quantity",
    key: "calc_total_remaining_quantity",
    width: "10%",
  },
  calcPercentageRemainingQuantity: {
    title: "stockTableColumns.prq",
    dataIndex: "calc_percentage_remaining_quantity",
    key: "calc_percentage_remaining_quantity",
    width: "10%",
  },
  actions: { title: "stockTableColumns.actions", key: "actions", width: "10%" },
};

export const stockMaterialStatuses = [
  {
    label: "Pending",
    value: BatchStatus.PENDING,
  },
  {
    label: "Delivered",
    value: BatchStatus.DELIVERED,
  },
  {
    label: "Canceled",
    value: BatchStatus.CANCELLED,
  },
  {
    label: "Produced",
    value: BatchStatus.PRODUCED,
  },
];

export const expandStockMaterial = "material.material_category.unit,supplier";

export const enum StockMaterialTableSorting {
  BATCH_CODE_ACS = "batch_code",
  BATCH_CODE_DESC = "-batch_code",
  DELIVERY_DATE_ASC = "delivery_date",
  DELIVERY_DATE_DESC = "-delivery_date",
  MATERIAL_NAME_ACS = "material",
  MATERIAL_NAME_DESC = "-material",
  QUANTITY_ACS = "quantity",
  QUANTITY_DESC = "-quantity",
  STATUS_ACS = "status",
  STATUS_DESC = "-status",
  SUPPLIER_ACS = "supplier__name",
  SUPPLIER_DESC = "-supplier__name",
}

export const stockMaterialReservationColumnsData = {
  material: {
    title: "materialReservationTableColumns.material",
    dataIndex: "material",
    key: "material",
    width: "30%",
  },
  reservationDate: {
    title: "materialReservationTableColumns.reservationDate",
    dataIndex: "reservation_date",
    key: "reservation_date",
    width: "30%",
  },
  quantity: {
    title: "materialReservationTableColumns.quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "30%",
  },
  actions: {
    title: "materialReservationTableColumns.actions",
    key: "actions",
    width: "10%",
  },
};

export const materialDetailsColumns = {
  name: {
    title: "materialDetailsColumns.name",
    width: "10%",
  },
  materialStockBatch: {
    title: "materialDetailsColumns.msb",
    dataIndex: "material_stock_batch",
    key: "material_stock_batch",
    width: "10%",
  },
  availableVolume: {
    title: "materialDetailsColumns.avVolume",
    dataIndex: "calc_total_available_quantity",
    key: "calc_total_available_quantity",
    width: "10%",
  },
  reservedVolume: {
    title: "materialDetailsColumns.resVolume",
    dataIndex: "calc_total_reserved_quantity",
    key: "calc_total_reserved_quantity",
    width: "10%",
  },
  materialReservation: {
    title: "materialDetailsColumns.materialReservation",
    dataIndex: "material_reservation",
    key: "material_reservation",
    width: "10%",
  },
  materialConsumed: {
    title: "materialDetailsColumns.materialConsumed",
    dataIndex: "material_consumed",
    key: "material_consumed",
    width: "10%",
  },
  datetime: {
    title: "materialDetailsColumns.dateTime",
    dataIndex: "datetime",
    key: "datetime",
    width: "10%",
  },
  quantityAvailable: {
    title: "materialDetailsColumns.qntAv",
    dataIndex: "quantity_available",
    key: "quantity_available",
    width: "10%",
  },
  quantityReserved: {
    title: "materialDetailsColumns.qntRes",
    dataIndex: "quantity_reserved",
    key: "quantity_reserved",
    width: "10%",
  },

  actions: {
    title: "materialDetailsColumns.title",
    key: "actions",
    width: "10%",
  },
};

export const availableQuantitiesChartColors = {
  available: {
    borderColor: "#49CB90",
    backgroundColor: "#A4F6A5",
  },
  reserved: {
    borderColor: "#002f5f",
    backgroundColor: "#c6d1dd",
  },
  target: {
    borderColor: "#F68787",
    backgroundColor: "#ff3333",
  },
};

export const materialManagementInitialValues = {
  id: null,
  name: "",
  material_category: {
    id: null,
  },
  quantity: null,
  suppliers: [],
  target_quantity_in_stock: null,
  materialCategory: "",
  n_packaging_items: null,
  expanded_label_name: null,
};
