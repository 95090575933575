import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import {
  adaptSuppliersForTheDnDTable,
  findSupplierForTheDnDTable,
} from "shared/adapters";
import {
  SuppliersItem,
  SupplierCompanyItem,
  useGetAllCompaniesQuery,
  SupplierItemWithCost,
} from "shared/api";
import { useModal } from "shared/hooks";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";
import {
  getUnitTitleForTheTableHeader,
  openHyperlinkInTab,
  showCostPerUnitTitle,
} from "shared/helpers";
import { SubmitCreateMaterialValues } from "shared/types";
import { columnsSuppliersData } from "shared/constants";
import { ModalToUpdateSupplier } from "./ModalToUpdateSupplier";

interface SuppliersTableProps {
  dynamicUnitName: string;
  handleDndSuppliers: (suppliers: SupplierItemWithCost[]) => void;
}

const SuppliersTable: FC<SuppliersTableProps> = ({
  dynamicUnitName,
  handleDndSuppliers,
}) => {
  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
  }

  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<SubmitCreateMaterialValues>();

  const { suppliers } = values;

  const { data: companies } = useGetAllCompaniesQuery({});

  const [suppliersState, setSuppliersState] = useState(suppliers);

  const adaptedSuppliers = adaptSuppliersForTheDnDTable(
    values?.suppliers,
    companies ?? []
  );

  const [dataSource, setDataSource] = useState<SupplierItemWithCost[]>(
    adaptedSuppliers as SupplierItemWithCost[]
  );

  useEffect(() => {
    handleDndSuppliers(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (suppliers?.[0]?.supplier?.address) {
      setSuppliersState(suppliers);
    }
    if (!suppliers?.[0]?.supplier?.address) {
      const foundItems = findSupplierForTheDnDTable(companies ?? [], values);
      setSuppliersState(foundItems as SupplierItemWithCost[]);
    }
  }, [suppliers]);

  useEffect(() => {
    setDataSource(adaptedSuppliers as SupplierItemWithCost[]);
  }, [suppliersState]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const removeSupplier = (supplierId: number) => {
    const filteredItems = suppliersState.filter(
      (item) => item.id !== supplierId
    );

    setFieldValue("suppliers", filteredItems);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const [clickedSupplier, setClickedSupplier] =
    useState<SupplierItemWithCost | null>(null);

  const handleClickedSupplier = (company: SupplierItemWithCost) => {
    const clickedSupplier = values?.suppliers?.find(
      (item) => item.supplier.id === company?.supplier?.id
    );

    if (clickedSupplier) {
      setClickedSupplier(clickedSupplier);
    }
  };

  const supplierIndexToChange = values?.suppliers?.findIndex(
    (supplier) => supplier?.supplier?.id === clickedSupplier?.supplier?.id
  );

  const [isOpenSupplierModal, openSupplierModal, closeSupplierModal] =
    useModal();

  const openEditSupplierModal = (supplier: SupplierItemWithCost) => {
    openSupplierModal();
    handleClickedSupplier(supplier);
  };

  const columns: ColumnsType<
    SupplierCompanyItem | SuppliersItem | SupplierItemWithCost
  > = [
    {
      key: columnsSuppliersData.key,
      width: columnsSuppliersData.keyWidth,
    },
    {
      title: t(columnsSuppliersData.name.title),

      render: (item: SupplierItemWithCost) => {
        return (
          <PopConfirmButton
            buttonContent={item?.supplier?.name}
            onClick={() =>
              openHyperlinkInTab(
                `${Routes.companies.hyperlink}/${item.supplier?.id}`.replace(
                  ":companyID/",
                  ""
                )
              )
            }
          />
        );
      },
      width: columnsSuppliersData.name.width,
    },
    {
      title: t(columnsSuppliersData.address.title),
      render: (supplier: SupplierItemWithCost) => (
        <span>{supplier?.supplier?.address}</span>
      ),
      width: columnsSuppliersData.address.width,
    },
    {
      title: t(columnsSuppliersData.evaluationAsSupplier.title),
      render: (supplier: SupplierItemWithCost) => (
        <span>{supplier?.supplier?.evaluation_as_supplier}</span>
      ),
      width: columnsSuppliersData.evaluationAsSupplier.width,
    },
    {
      title: `${t(
        columnsSuppliersData.costPerUnit.title
      )} - ${getUnitTitleForTheTableHeader(dynamicUnitName)}`,
      render: (supplier: SupplierItemWithCost) => (
        <Button onClick={() => openEditSupplierModal(supplier)}>
          {showCostPerUnitTitle(
            supplier?.cost_per_unit,
            t("material-management.no-value")
          )}
        </Button>
      ),
      width: columnsSuppliersData.costPerUnit.width,
    },
    {
      title: `${t(
        columnsSuppliersData.costPeruUnitCurrency.title
      )} - ${getUnitTitleForTheTableHeader(dynamicUnitName)}`,
      render: (supplier: SupplierItemWithCost) => (
        <Button onClick={() => openEditSupplierModal(supplier)}>
          {supplier?.cost_per_unit_currency ||
            t("material-management.no-value")}
        </Button>
      ),

      width: columnsSuppliersData.costPeruUnitCurrency.width,
    },

    {
      title: t(columnsSuppliersData.actions.title),
      key: columnsSuppliersData.actions.key,
      width: columnsSuppliersData.actions.width,
      render: (record: SupplierCompanyItem) => (
        <div className="w-full flex items-center justify-around z-[1000]">
          <DeleteOutlined
            onClick={() => removeSupplier(record.id)}
            className="btn-remove"
          />
        </div>
      ),
    },
  ];

  if (!suppliers?.length) {
    return <Title level={5}>{t("material-management.no-suppliers")}</Title>;
  }

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        {dataSource ? (
          <SortableContext
            items={dataSource?.map((i) => i?.key!) ?? []}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
            />
          </SortableContext>
        ) : null}
      </DndContext>

      <ModalToUpdateSupplier
        isOpenModal={isOpenSupplierModal}
        clickedSupplier={clickedSupplier}
        supplierIndexToChange={supplierIndexToChange}
        close={closeSupplierModal}
      />
    </>
  );
};

export { SuppliersTable };
