import { MaterialsItem, WidgetTypeEnum } from "shared/api";

export const enum GraphMaterialFields {
  WIDGET_TYPE = "MATERIAL",
  MATERIAL_GRAPH_CALC_PERCENTAGE_AVAILABLE = "materialGraphCalcPercentageAvailable",
}

export const adaptGraphMaterialLvl = (materialLevelGraph: MaterialsItem[]) => {
  return materialLevelGraph?.map((material) => ({
    type: WidgetTypeEnum.GRAPH_MATERIAL_LEVEL,

    materialGraphCalcPercentageAvailable: material.calc_percentage_available,
  }));
};
