import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React from "react";
import { MaterialConsumedItem } from "shared/api";
import { useFormikContext, FormikErrors } from "formik";
import { FormInput, TableButton } from "shared/ui";
import { useTranslation } from "react-i18next";
import { OrderProductionSetParams } from "shared/types";
import { materialConsumedColumns } from "shared/constants";

const OrderMaterialConsumed = () => {
  const { t } = useTranslation();

  const { material, materialItem, stockBatch, quantity, actions } =
    materialConsumedColumns;

  const { values, handleBlur, handleChange, errors, setFieldValue } =
    useFormikContext<OrderProductionSetParams>();

  const handleRemoveItem = (tableItemIndex: number) => {
    const updatedMaterialsConsumed = values.materials_consumed.filter(
      (_, materialConsumedIndex) => materialConsumedIndex !== tableItemIndex
    );
    setFieldValue("materials_consumed", updatedMaterialsConsumed);
  };

  const columns: ColumnsType<MaterialConsumedItem> = [
    {
      title: t(material.title),
      width: material.width,
      render: (record: MaterialConsumedItem) => record?.material?.name,
    },
    {
      title: t(materialItem.title),
      width: materialItem.width,
      render: (record: MaterialConsumedItem) =>
        record?.material_item?.serial_number,
    },
    {
      title: t(stockBatch.title),
      width: stockBatch.width,
      render: (record: MaterialConsumedItem) =>
        record?.material_stock_batch?.batch_code,
    },
    {
      title: t(quantity.title),
      width: quantity.width,
      render: (
        _: MaterialConsumedItem,
        record: MaterialConsumedItem,
        index: number
      ) => {
        const error = errors.materials_consumed?.[
          index
        ] as FormikErrors<MaterialConsumedItem>;
        const quantityError = error?.quantity;

        return (
          <FormInput
            value={values?.materials_consumed?.[index]?.quantity}
            fieldName={`materials_consumed.${index}.quantity`}
            placeholder={t("orders.quantity")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            numberType
            error={quantityError}
            isError={!!quantityError}
          />
        );
      },
    },
    {
      title: t(actions.title),
      width: 100,
      render: (
        _: MaterialConsumedItem,
        __: MaterialConsumedItem,
        index: number
      ) => (
        <TableButton type="delete" onClick={() => handleRemoveItem(index)} />
      ),
    },
  ];

  return (
    <div className="w-full">
      <Title level={5}>{t("orders.materials-consumed")}</Title>
      <Table columns={columns} dataSource={values?.materials_consumed} />
    </div>
  );
};

export { OrderMaterialConsumed };
