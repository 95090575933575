import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import {
  useMaterialManagementData,
  useMaterialManagementSteps,
  useModal,
} from "shared/hooks";
import {
  CreateMaterialManagementItemModal,
  GoToTableHeader,
  UpdatedMaterialItems,
  UpdatedMaterialStockManagement,
  UpdatedMaterialManagementTable,
} from "./ui";
import { UpdateMaterialManagementModal } from "./ui/UpdateMaterialManagementModal";
import { MaterialDetails } from "../MaterialManagement/ui/MaterialDetails/MaterialDetails";
import { MaterialConsumed } from "../MaterialManagement/ui/MaterialConsumed/MaterialConsumed";
import { MaterialReservations } from "../MaterialManagement/ui/MaterialReservations/MaterialReservations";

const UpdatedMaterialManagement = () => {
  const { t } = useTranslation();

  const [
    isVisibleAddMaterialModal,
    showAddMaterialModal,
    hideAddMaterialModal,
  ] = useModal();

  const [
    isVisibleEditMaterialModal,
    showEditMaterialModal,
    hideEditMaterialModal,
  ] = useModal();

  const {
    materialCategoriesAdapted,
    materialCategories,
    materialItemToUpdate,
    handleMaterialItemToEdit,
  } = useMaterialManagementData({ showEditMaterialModal });

  const {
    isMaterialManagementTable,
    isStockHistory,
    isMaterialConsumed,
    materialItem: selectedMaterial,
    handelMaterialStockHistoryDetails,
    resetMaterialManagementPage,
    handelMaterialConsumedDetails,
    isMaterialReservation,
    handleMaterialReservationDetails,
    isStockManagement,
    handleStockManagementDetails,
    handleMaterialItemsDetails,
    isMaterialItems,
    materialStockBatchForMaterialItems,
    goToStockManagementFromMaterialItems,
  } = useMaterialManagementSteps();

  return (
    <Page
      title={t("material-management.page-title")}
      hideTitle={!isMaterialManagementTable}
      buttonTitle={
        isMaterialManagementTable
          ? t("material-management.create-material")!
          : undefined
      }
      onClick={showAddMaterialModal}
    >
      <GoToTableHeader
        resetMaterialManagementPage={resetMaterialManagementPage}
        isStockHistory={isStockHistory}
        isMaterialConsumed={isMaterialConsumed}
        selectedMaterial={selectedMaterial}
        isMaterialManagementTable={isMaterialManagementTable}
        isMaterialReservation={isMaterialReservation}
        isStockManagement={isStockManagement}
        isMaterialItems={isMaterialItems}
        goToStockManagementFromMaterialItems={
          goToStockManagementFromMaterialItems
        }
      />

      {isMaterialManagementTable ? (
        <>
          <UpdatedMaterialManagementTable
            handleMaterialItemToEdit={handleMaterialItemToEdit}
            materialCategoriesAdapted={materialCategoriesAdapted}
            handelMaterialStockHistoryDetails={
              handelMaterialStockHistoryDetails
            }
            handelMaterialConsumedDetails={handelMaterialConsumedDetails}
            handleMaterialReservationDetails={handleMaterialReservationDetails}
            handleStockManagementDetails={handleStockManagementDetails}
          />

          <CreateMaterialManagementItemModal
            isOpen={isVisibleAddMaterialModal}
            onClose={hideAddMaterialModal}
            materialCategoriesAdapted={materialCategoriesAdapted}
            materialCategories={materialCategories ?? []}
          />

          <UpdateMaterialManagementModal
            isOpen={isVisibleEditMaterialModal}
            onClose={hideEditMaterialModal}
            materialCategoriesAdapted={materialCategoriesAdapted}
            materialCategories={materialCategories ?? []}
            materialItemToUpdate={materialItemToUpdate}
          />
        </>
      ) : null}

      {isStockHistory ? (
        <MaterialDetails materialItem={selectedMaterial} />
      ) : null}

      {isMaterialConsumed ? (
        <MaterialConsumed selectedMaterial={selectedMaterial} />
      ) : null}

      {isMaterialReservation ? (
        <MaterialReservations selectedMaterial={selectedMaterial} />
      ) : null}

      {isStockManagement ? (
        <UpdatedMaterialStockManagement
          selectedMaterial={selectedMaterial}
          handleMaterialItemsDetails={handleMaterialItemsDetails}
        />
      ) : null}

      {isMaterialItems ? (
        <UpdatedMaterialItems
          materialStockBatchForMaterialItems={
            materialStockBatchForMaterialItems
          }
          selectedMaterial={selectedMaterial}
        />
      ) : null}
    </Page>
  );
};

export { UpdatedMaterialManagement };
