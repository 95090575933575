import { UploadOutlined } from "@ant-design/icons";
import { Upload, UploadFile } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  createUploadDocumentProps,
  generateExcelFileProps,
} from "shared/helpers";
import { QualityResultResultType } from "shared/api";
import { UploadDocumentValues } from "shared/types";

interface UploadDocumentProps {
  handleDocument: (file: File | null) => void;
  handleDocumentList: (files: UploadFile[]) => void;
  documentList: UploadFile[];
}

const { Dragger } = Upload;

const UploadDocument: FC<UploadDocumentProps> = ({
  handleDocument,
  handleDocumentList,
  documentList,
}) => {
  const { t } = useTranslation();

  const { values } = useFormikContext<UploadDocumentValues>();

  const props =
    values?.result_type === QualityResultResultType?.REEL_LAB_RESULT
      ? generateExcelFileProps(handleDocument, handleDocumentList, documentList)
      : createUploadDocumentProps(
          handleDocument,
          handleDocumentList,
          documentList
        );

  return (
    <div className="w-full mb-3">
      <Title level={5}>{t("quality.upload-document")}</Title>

      <Dragger {...props} disabled={!values?.result_type}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">{t("quality.uploadMessage")}</p>
      </Dragger>
    </div>
  );
};

export { UploadDocument };
