import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AllUsersParams,
  OrderedUsers,
  UserItem,
  UsersResponse,
} from "./models";

export const usersApi = createApi({
  baseQuery,
  reducerPath: "usersApi",
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query<UsersResponse, AllUsersParams>({
      query: ({ ordering, limit, offset }) => ({
        url: "/api/v1/users/",
        method: "GET",
        params: { ordering, limit, offset },
      }),
      providesTags: ["Users"],
    }),
    getFilteredUsers: builder.query<UserItem[], OrderedUsers>({
      query: ({ ordering }) => ({
        url: `/api/v1/users/`,
        method: "GET",
        params: {
          ordering,
        },
      }),
      providesTags: ["Users"],
    }),
    getIndividualUser: builder.query<UserItem, number>({
      query: (id) => ({
        url: `/api/v1/users/${id}`,
        method: "GET",
      }),
    }),
    getLoggedUser: builder.query<UserItem, void>({
      query: () => ({
        url: "/api/v1/users/me/",
        method: "GET",
      }),
    }),
    createNewUser: builder.mutation<UserItem, UserItem>({
      query: ({ first_name, last_name, username, email }) => ({
        url: `/api/v1/users/`,
        method: "POST",
        body: {
          first_name,
          last_name,
          username,
          email,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation<UserItem, UserItem>({
      query: (body) => ({
        url: `/api/v1/users/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<void, UserItem>({
      query: (body) => ({
        url: `/api/v1/users/${body.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetIndividualUserQuery,
  useGetLoggedUserQuery,
  useCreateNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetFilteredUsersQuery,
} = usersApi;
