import { Modal } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MaterialCategoriesItem, MaterialCategorySupplier } from "shared/api";
import { companiesColumnsData, ModalSizeValue } from "shared/constants";
import { openHyperlinkInTab, renderCompanyRole } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton, TableImage } from "shared/ui";
import { CategorySupplierInformationField } from "./CategorySupplierInformationField";

interface CategorySuppliersModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryToShowSuppliers: MaterialCategoriesItem | null;
  refetchMaterialCategoriesHandler: () => void;
}

const CategorySuppliersModal: FC<CategorySuppliersModalProps> = ({
  isOpen,
  onClose,
  categoryToShowSuppliers,
  refetchMaterialCategoriesHandler,
}) => {
  const { t } = useTranslation();

  const {
    name,
    address,
    incoTerms,
    customerOrSupplier,
    image,
    evaluationAsSupplier,
    information,
  } = companiesColumnsData;

  const columns: ColumnsType<MaterialCategorySupplier> = [
    {
      title: t(image.title),
      render: (materialCategory: MaterialCategorySupplier) => (
        <TableImage image={materialCategory.company?.image} />
      ),
    },
    {
      title: t(name.title),
      render: (materialCategory: MaterialCategorySupplier) => (
        <PopConfirmButton
          buttonContent={materialCategory?.company?.name}
          onClick={() =>
            openHyperlinkInTab(
              `${Routes.companies.hyperlink}/${materialCategory?.company?.id}`.replace(
                ":companyID/",
                ""
              )
            )
          }
        />
      ),
    },
    {
      title: t(address.title),
      render: (materialCategory: MaterialCategorySupplier) =>
        materialCategory?.company?.address,
    },
    {
      title: t(evaluationAsSupplier.title),
      render: (materialCategory: MaterialCategorySupplier) =>
        materialCategory?.company?.evaluation_as_supplier,
    },
    {
      title: t(incoTerms.title),
      render: (materialCategory: MaterialCategorySupplier) =>
        materialCategory?.company?.inco_terms,
    },
    {
      title: t(information.title),
      render: (materialCategory: MaterialCategorySupplier) => (
        <CategorySupplierInformationField
          company={materialCategory.company}
          refetchMaterialCategoriesHandler={refetchMaterialCategoriesHandler}
        />
      ),
    },
    {
      title: t(customerOrSupplier.title),
      render: (materialCategory: MaterialCategorySupplier) =>
        renderCompanyRole(materialCategory?.company),
    },
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={t("companiesColumns.categorySuppliersModalTitle", {
        categoryName: categoryToShowSuppliers?.name,
      })}
      width={ModalSizeValue.XL_MEDIUM}
      okButtonProps={{ style: { display: "none" } }}
    >
      <Table
        columns={columns}
        dataSource={
          categoryToShowSuppliers?.suppliers as MaterialCategorySupplier[]
        }
        rowKey={(obj) => obj.id!}
      />
    </Modal>
  );
};

export { CategorySuppliersModal };
