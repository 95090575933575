import Title from "antd/es/typography/Title";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { transformObject, updateDynamicProcessStep } from "shared/adapters";
import { DynamicOrderObject, OrderStepTrackerResponseItem } from "shared/api";
import { FieldType } from "shared/types";
import { ConfigCardStepFields } from "../ConfigCardStepFields";

interface DynamicStepsContainerProps {
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined;
}

const DynamicStepsContainer: FC<DynamicStepsContainerProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation();

  const dynamicProcessStep =
    orderStepTrackerResponseItem?.process_step.process_template
      .config_dynamic_process_step_fields;

  const processStep =
    orderStepTrackerResponseItem?.process_step?.dynamic_process_step_fields;

  const transformedProcessStep = useMemo(() => {
    return transformObject(processStep as DynamicOrderObject);
  }, [processStep]);

  const updatedDynamicProcessStep = useMemo(
    () =>
      updateDynamicProcessStep(
        dynamicProcessStep,
        transformedProcessStep,
        (field: FieldType, value: string | number) => ({
          ...field,
          value,
        })
      ),
    [dynamicProcessStep, transformedProcessStep]
  );

  return (
    <div
      className="
        w-full  h-[516px] overflow-auto 
        shadow-dynamicFormCard rounded-xl
      "
    >
      <div className="bg-blue-primary e h-[48px]">
        <Title level={3} className="mb-1.25 pl-2 !text-white pt-2">
          {t("orders.process-information")}
        </Title>
      </div>

      {updatedDynamicProcessStep?.map((card: any, cardIndex: number) => {
        return (
          <Collapse
            accordion
            key={`${card.title}_${cardIndex}`}
            className="!white-arrow p-2"
          >
            <Collapse.Panel
              header={<span className="!text-white">{card.title}</span>}
              key={`${card.title}_${cardIndex}`}
              className="w-full bg-blue-primary !text-white pl-3 py-2"
            >
              <ConfigCardStepFields card={card} key={card.title} />
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </div>
  );
};

export { DynamicStepsContainer };
