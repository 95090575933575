import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { getWidgetTitle } from "shared/helpers";
import { Widget, WidgetTypeEnum } from "shared/api";
import { CloseCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Popover, Skeleton } from "antd";
import { useWidgetTableData } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { PollingData } from "shared/hooks/usePollingSeconds";
import { WidgetTable } from "./WidgetTable";
import { WidgetSettings } from "./WidgetSettings";
import { GaugeData } from "./widgets/GaugeData";

interface WidgetLayoutItemProps {
  widget: Widget;
  onClick?: () => void;
  pollingData: PollingData;
}

const WidgetLayoutItem: FC<WidgetLayoutItemProps> = ({
  widget,
  onClick,
  pollingData,
}) => {
  // trigger
  const { t } = useTranslation();

  const {
    widgetData,
    isLoading,
    hardRefetch,
    materialCategories,
    materials,
    isGaugeWidget,
  } = useWidgetTableData({
    widget,
    pollingData,
  });

  const widgetTitle = t(getWidgetTitle(widget?.type as WidgetTypeEnum));

  if (isLoading) {
    return (
      <div className="!bg-white h-[250px] flex flex-col gap-y-5 p-2">
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }

  return (
    <div>
      <div className="w-full flex items-center justify-between h-auto">
        <Title level={5} className="!text-white">
          {widgetTitle} {widget?.name ? `(${widget.name})` : null}
        </Title>

        <div className="flex gap-x-5">
          <Popover content={t("widgets.pressToRefresh")}>
            <ReloadOutlined
              className="!text-white scale-150 mb-3 !cursor-pointer"
              onClick={() => hardRefetch(widget?.type)}
            />
          </Popover>

          <WidgetSettings
            widget={widget}
            materialCategories={materialCategories}
            materials={materials}
            pollingData={pollingData}
          />

          <Popover content={t("widgets.pressToRemove")}>
            <CloseCircleOutlined
              className="!text-white scale-150 mb-3 !cursor-pointer"
              onClick={onClick}
            />
          </Popover>
        </div>
      </div>
      {isGaugeWidget ? (
        <GaugeData widgetsData={widgetData ?? []} widget={widget} />
      ) : (
        <WidgetTable widgetsData={widgetData ?? []} widget={widget} />
      )}
    </div>
  );
};

const WidgetLayoutItemMemo = React.memo(WidgetLayoutItem);

export { WidgetLayoutItemMemo as WidgetLayoutItem };
