import { Modal, UploadFile, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { DynamicForm, FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import {
  adaptDynamicProcessTemplateField,
  handleRequestError,
} from "shared/helpers";

import { useCompanyImageUpload } from "shared/hooks";
import {
  UnitItemWithLabel,
  useAddProcessTemplateImageMutation,
  useUpdateProcessTemplateMutation,
} from "shared/api";
import { ModalSizeValue } from "shared/constants";
import { AddProcessTemplatesValues } from "shared/types";
import { ProcessTemplateLogo } from "./ProcessTemplateLogo";

interface EditProcessTemplateModalProps {
  isOpenUpdateProcessTemplateModal: boolean;
  adaptedDimensionUnits: UnitItemWithLabel[];
  hideUpdateProcessTemplateModal: () => void;
  documentList: UploadFile[];
  handleDocumentList: (files: UploadFile[]) => void;
}

const EditProcessTemplateModal: FC<EditProcessTemplateModalProps> = ({
  isOpenUpdateProcessTemplateModal,
  hideUpdateProcessTemplateModal,
  adaptedDimensionUnits,
  documentList,
  handleDocumentList,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const { values, resetForm, handleBlur, handleChange, errors, isValid } =
    useFormikContext<AddProcessTemplatesValues>();

  const closeModal = () => {
    hideUpdateProcessTemplateModal();
    resetForm();
    handleDocumentList([]);
  };

  const [updateProcessTemplate] = useUpdateProcessTemplateMutation();
  const [addProcessStepImage] = useAddProcessTemplateImageMutation();

  const {
    uploadedFile,
    previewLogoImage,
    uploadCompanyImageProps,
    closeModalAndClearUploadedImage,
  } = useCompanyImageUpload({
    documentList,
    handleDocumentList,
    closeModal,
  });

  const updateProcessTemplateRequest = async () => {
    try {
      const response = await updateProcessTemplate({
        id: values.id!,
        name: values.name!,
        description: values.description!,
        config_dynamic_process_step_fields:
          adaptDynamicProcessTemplateField(values),
        abbreviation: values.abbreviation!,
      }).unwrap();

      if (response && uploadedFile) {
        const formData = new FormData();
        formData.append("file", uploadedFile);

        await addProcessStepImage({
          id: response?.id,
          image: formData,
        }).unwrap();
      }

      api.success({
        message: t("process-templates.success"),
        description: t("process-templates.success-update-msg"),
      });

      closeModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("process-templates.error")
      );

      api.error({
        message: t("process-templates.error"),
        description,
      });
    } finally {
      closeModalAndClearUploadedImage();
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("process-templates.edit-modal-msg")}
        open={isOpenUpdateProcessTemplateModal}
        width={ModalSizeValue.LARGE}
        okButtonProps={{
          disabled: !isValid,
          style: { background: `${Colors.blue.primary}` },
        }}
        onCancel={closeModal}
        onOk={updateProcessTemplateRequest}
      >
        <ProcessTemplateLogo
          documentList={documentList}
          uploadCompanyImageProps={uploadCompanyImageProps}
          previewLogoImage={previewLogoImage}
        />

        <Title level={5} className="mb-1.25">
          {t("process-templates.input-title")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("process-templates.name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("process-templates.description-title")}
        </Title>

        <FormInput
          value={values.description}
          fieldName="description"
          placeholder={t("process-templates.description-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.description}
          isError={!!errors.description}
        />

        <Title level={5} className="mb-1.25">
          {t("process-templates.abbreviation")}
        </Title>

        <FormInput
          value={values.abbreviation}
          fieldName="abbreviation"
          placeholder={t("process-templates.abbreviation")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.abbreviation}
          isError={!!errors.abbreviation}
        />

        <DynamicForm adaptedDimensionUnits={adaptedDimensionUnits} />
      </Modal>
    </>
  );
};

export { EditProcessTemplateModal };
