import React, { FC } from "react";
import { WidgetTableData } from "shared/api";
import { MachinesXFields, openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";

interface MachinesXWidgetProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

const MachinesXWidget: FC<MachinesXWidgetProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const machineName = record?.machineXName as string;
  const processTemplateName = record?.machineXProcessTemplate as string;

  const machinesLink =
    `${Routes.machines.hyperlinkUrl}/${record.machineXId}`?.replace(":id/", "");

  const processTemplate =
    `${Routes.processTemplates.hyperlink}/${record.machineXProcessTemplateId}`?.replace(
      ":id/",
      ""
    );

  if (fieldKey === MachinesXFields.MACHINE_X_NAME) {
    return (
      <PopConfirmButton
        buttonContent={machineName}
        onClick={() => openHyperlinkInTab(machinesLink)}
      />
    );
  }

  if (fieldKey === MachinesXFields.MACHINE_X_PROCESS_TEMPLATE) {
    return (
      <PopConfirmButton
        buttonContent={processTemplateName}
        onClick={() => openHyperlinkInTab(processTemplate)}
      />
    );
  }

  return <span>{text}</span>;
};

const MachinesXWidgetMemo = React.memo(MachinesXWidget);

export { MachinesXWidgetMemo as MachinesXWidget };
