import { useMemo } from "react";
import { adaptMachines } from "shared/adapters";
import {
  OrderStepTrackerResponseItem,
  useGetAllMachinesWithNoParamsQuery,
} from "shared/api";

interface UseProdPageContentDataProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  orderId?: string;
}

const useProdPageContentData = ({
  orderId,
  orderStepTrackerResponseItem,
}: UseProdPageContentDataProps) => {
  const { data: machines } = useGetAllMachinesWithNoParamsQuery();

  const processTemplate =
    orderStepTrackerResponseItem?.process_step?.process_template?.id;

  const adaptedMachines = useMemo(() => {
    const filteredMachines = machines?.filter(
      (machine) =>
        (machine?.process_template as unknown as number) === processTemplate
    );

    return adaptMachines(filteredMachines || []);
  }, [machines, orderId, processTemplate]);

  return {
    adaptedMachines,
  };
};

export { useProdPageContentData };
