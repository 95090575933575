export const widgetsRequestsParams = {
  articleFlowParams: {
    expand:
      "order_production_set.order_process_step_tracker.machine,order_production_set.order_process_step_tracker.process_step.output_materials",
    limit: 3,
    ordering: "-timestamp",
  },
  materialConsumed: {
    expand:
      "order_production_set.order_process_step_tracker.machine,material,material_stock_batch.material",
  },
  orderStatus: {
    expand: "process_step_trackers.machine,",
  },
  machinesX: {
    expand: "process_template",
  },
  materialTargetAlert: {
    expand: "material_category",
  },
};
