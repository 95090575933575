import { ModalWidget } from "shared/api";

export const widgetsData: ModalWidget[] = [
  {
    category: "widgetsModal.logisticsCategory",
    widgets: [
      {
        id: 1,
        name: "widgetsModal.articleFlow",
        type: "ITEM_FLOW",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
      {
        id: 2,
        name: "widgetsModal.materialConsumption",
        type: "MATERIAL_CONSUMPTION",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
      {
        id: 3,
        name: "widgetsModal.orderStatus",
        type: "ORDER_STATUS",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
      {
        id: 4,
        name: "widgetsModal.machineXProgressOnOrder",
        type: "MACHINES",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
      {
        id: 7,
        name: "widgetsModal.materialTargetAlert",
        type: "MATERIAL",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
      {
        id: 8,
        name: "widgetsModal.materialLvlGraph",
        type: "GRAPH_MATERIAL_LEVEL",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
    ],
  },
  {
    category: "widgetsModal.qualityCategory",
    widgets: [
      {
        id: 8,
        name: "widgetsModal.qualityTracking",
        type: "QUALITY_TRACKING",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },

      {
        id: 10,
        name: "widgetsModal.productionDataTracker",
        type: "PRODUCTION_DATA_TRACKER",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
    ],
  },
  {
    category: "widgetsModal.standAlone",
    widgets: [
      {
        id: 8,
        name: "widgetsModal.standAlone",
        type: "STANDALONE_LINE_GRAPH",
        filter: [],
        configuration: [],
        influxdb_server: null,
        standalone_query: null,
      },
    ],
  },
];

export const hideButtonStyles = {
  style: {
    display: "none",
  },
};
