const initialArticleFlowColumns = [
  "articleImage",
  "articleDateTime",
  "articleMachine",
  "articleArticle",
  "articleStatus",
];

const initialMaterialConsumedColumns = [
  "icon",
  "consumedDateTime",
  "consumedOrderProductionSet",
  "consumedMaterial",
  "consumedMaterialStockBatch",
  "consumedMaterialItem",
  "consumedQuantity",
  "consumedStatus",
];

const initialOrderStatusColumns = [
  "icon",
  "orderStatusDateTime",
  "orderStatusOrder",
  "orderStatus",
  "orderStatusMachines",
  "orderStatusProgress",
  "orderProductionTime",
  "orderDeliveryTime",
];

const materialTargetAlertInitialColumns = [
  "adaptMaterialTargetName",
  "adaptMaterialTargetMaterialCategory",
  "adaptMaterialTargetCalcTotalRemainingQuantity",
  "adaptMaterialTargetCalcTotalReservedQuantity",
  "adaptMaterialTargetCalcTotalAvailableQuantity",
  "adaptMaterialTargetCalcTotalMissingQuantity",
  "adaptMaterialTargetCalcPercentageRemaining",
  "adaptMaterialTargetCalcPercentageAvailable",
  "adaptMaterialTargetCalcPercentageMissing",
];

const initialProductionDataTrackerColumns = [
  "icon",
  "productionTrackingTimeStamp",
  "productionTrackingSerialNumber",
  "productionTrackingProperty",
  "productionTrackingValue",
];

const initialMachinesXColumns = ["machineXName", "machineXProcessTemplate"];

export const initialWidgetColumns = {
  initialArticleFlowColumns,
  initialMaterialConsumedColumns,
  initialOrderStatusColumns,
  materialTargetAlertInitialColumns,
  initialProductionDataTrackerColumns,
  initialMachinesXColumns,
};
