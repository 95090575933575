import classNames from "classnames";
import React, { FC } from "react";
import { useProdPageContentData, useWindowDimensions } from "shared/hooks";
import { OrderStepTrackerResponseItem } from "shared/api";
import { OrderCard } from "./OrderCard";
import { DynamicStepsContainer } from "./DynamicStepsContainer";

interface ProdPageInfoBlockProps {
  processStepId?: number;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  openProducedItemInOrderModal: () => void;
  orderId?: string;
}

const ProdPageInfoBlock: FC<ProdPageInfoBlockProps> = ({
  orderStepTrackerResponseItem,
  processStepId,
  openProducedItemInOrderModal,
  orderId,
}) => {
  const { isDesktop } = useWindowDimensions();

  const { adaptedMachines } = useProdPageContentData({
    orderId,
    orderStepTrackerResponseItem,
  });

  return (
    <div
      className={classNames("w-full flex gap-x-5 items-start justify-between", {
        "flex-col gap-y-3": !isDesktop,
      })}
    >
      <OrderCard
        processStepId={processStepId}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        adaptedMachines={adaptedMachines}
        openProducedItemInOrderModal={openProducedItemInOrderModal}
      />

      <DynamicStepsContainer
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />
    </div>
  );
};

export { ProdPageInfoBlock };
