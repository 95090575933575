import {
  AllFilterKeys,
  ArticleOrderProcessStepTracker,
  WidgetStatuses,
} from "shared/helpers";

export type WidgetType =
  | "ITEM_FLOW"
  | "MATERIAL_CONSUMPTION"
  | "ORDER_STATUS"
  | "MACHINES"
  | "MATERIAL"
  | "QUALITY_TRACKING"
  | "PRODUCTION_DATA_TRACKER"
  | "GRAPH_MATERIAL_LEVEL"
  | "STANDALONE_LINE_GRAPH";

export const enum WidgetTypeEnum {
  ITEM_FLOW = "ITEM_FLOW",
  MATERIAL_CONSUMPTION = "MATERIAL_CONSUMPTION",
  ORDER_STATUS = "ORDER_STATUS",
  MACHINES = "MACHINES",
  MATERIAL_TARGET_ALERT = "MATERIAL",
  QUALITY_TRACKING = "QUALITY_TRACKING",
  PRODUCTION_DATA_TRACKER = "PRODUCTION_DATA_TRACKER",
  GRAPH_MATERIAL_LEVEL = "GRAPH_MATERIAL_LEVEL",
  STANDALONE_LINE_GRAPH = "STANDALONE_LINE_GRAPH",
}

export type ArticleFlowColumns =
  | "articleImage"
  | "articleDateTime"
  | "articleMachine"
  | "articleArticle"
  | "articleStatus";

export type OrderStatusColumns = "orderStatusProgress";

export type ColumnNames = ArticleFlowColumns[];

export interface WidgetFilter {
  filter_parameter: AllFilterKeys;
  filter_value: string | number | number[] | string[];
}

export interface WidgetConfiguration {
  configuration_parameter: AllFilterKeys;
  configuration_value: string | number | number[] | string[];
}

export interface Widget {
  id?: number;
  name?: string | null;
  column?: number;
  row?: number;
  type: WidgetType;
  widgetData?: WidgetTableData[];
  uuid?: string;
  column_names?: ColumnNames;
  limit_rows?: number;
  sort_by?: string[];
  filter: WidgetFilter[];
  configuration: WidgetConfiguration[];
  dashboard?: number;
  standalone_query: string | null;
  influxdb_server: number | null;
}

export interface ModalWidget {
  category: string;
  widgets: Widget[];
}

export interface DashboardConfiguration {
  configuration_parameter: string;
  configuration_value: string;
}

export interface Dashboard {
  id: number;
  name: string;
  widgets: Widget[];
  settings?: Widget;
  configuration: DashboardConfiguration[];
}

export interface DashboardByIdParams {
  id: number;
}

export interface DeleteDashboardParams {
  id: number;
}

export interface AddDashboardParams {
  name: string;
  widgets: Widget[];
  configuration: DashboardConfiguration[];
}

export interface UpdateWidgets {
  type: WidgetType;
  column: number;
  row: number;
  uuid: string;
  column_names: ColumnNames;
  limit_rows: number;
  sort_by: string[];
  filter: WidgetFilter[];
  configuration: WidgetConfiguration[];
  name?: string | null;
}

export interface UpdateDashboardParams {
  id: number;
  name: string;
  widgets: UpdateWidgets[];
  configuration: DashboardConfiguration[];
}

interface BaseWidgetItem {
  type: string;
}

export interface WidgetTableData extends BaseWidgetItem {
  [key: string]: any;
}

export interface ArticleFlowWidgetType {
  type: WidgetType;
  articleImage: WidgetStatuses;
  articleDateTime: string;
  articleMachine: ArticleOrderProcessStepTracker;
  articleArticle: {
    id: number;
    serial_number: string;
  };
  articleStatus: WidgetStatuses;
}

export const enum DashboardModes {
  ONE_COLUMN = "oneColumn",
  DOUBLE_COLUMNS = "doubleColumn",
}

export const enum DashboardConfigurationParameter {
  MODE = "mode",
}
