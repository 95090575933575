import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ConfigurationsAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      message={t("widgets.configurationAlert")}
      showIcon
      type="warning"
      className="h-10"
    />
  );
};

export { ConfigurationsAlert };
