import { RightOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import classNames from "classnames";
import React from "react";

interface MinimizedSidebarProps {
  closeMinimizedSidebar: () => void;
}

const MinimizedSidebar = ({ closeMinimizedSidebar }: MinimizedSidebarProps) => {
  return (
    <div
      className={classNames(
        "h-screen  w-5 sticky top-0 bg-blue-primary shadow-sidebar  transition-transform duration-300"
      )}
    >
      <Popover content="Open Full Size Sidebar" placement="bottom">
        <Button
          onClick={closeMinimizedSidebar}
          className="
                bg-white rounded-full !m-0 p-0 w-8 h-8 
                flex items-center justify-center mb-2 
                absolute -right-3 top-2 !shadow-2xl
                border-1px border-blue-primary
              "
        >
          <RightOutlined className="text-black  !m-0 !p-0" />
        </Button>
      </Popover>
    </div>
  );
};

export { MinimizedSidebar };
