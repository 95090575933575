import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import {
  columnsMaterialItemsData,
  materialItemStatuses,
} from "shared/constants";
import { MaterialItem, MaterialStockItem } from "shared/api";
import {
  addThousandsSeparator,
  openHyperlinkInTab,
  renderMaterialItemsStatuses,
} from "shared/helpers";
import { Routes } from "shared/routers";
import {
  DatePickerValue,
  DateRange,
  DateWithTimeRow,
  PopConfirmButton,
  TableButton,
  TableRadioGroup,
  TableSearchFilter,
} from "shared/ui";
import { FilterFilled } from "@ant-design/icons";

interface UseGenerateTrackingListMaterialItemsColumnsProps {
  t: TFunction;

  openModalAndSelectOrder: (item: MaterialStockItem) => void;
  handleDateGreater: (date: DatePickerValue, dateString: string) => void;
  handleDateLesser: (date: DatePickerValue, dateString: string) => void;
  createPackageReport: (record: MaterialStockItem) => Promise<void>;
  createMaterialItemReport: (
    record: MaterialItem | MaterialStockItem
  ) => Promise<void>;
  showPackagesModal: () => void;
  handleMaterialReports: (materialItem: MaterialStockItem) => Promise<void>;
  handlePackageReports: (materialItem: MaterialStockItem) => Promise<void>;
  serialNumberValue: string;
  handleSerialNumberValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSerialNumberValue: () => void;
  setValue: string;
  handleSetValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSetValue: () => void;
  posValue: string;
  handlePosValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearPosValue: () => void;
  statusFilter: string | number;
  handleStatusFilter: (value: number | string) => void;
  isDataFilterActive: boolean;
}

const {
  materialName,
  serialNumber,
  calcPercentageRemainingQuantity,
  calcTotalConsumedQuantity,
  calcTotalOriginalQuantity,
  calcTotalRemainingQuantity,
  status,
  actions,
  timestamp,
  set,
  pos,
} = columnsMaterialItemsData;

export const useGenerateTrackingListMaterialItemsColumns = ({
  t,
  openModalAndSelectOrder,
  setValue,
  handleSetValue,
  clearSetValue,
  handleDateGreater,
  handleDateLesser,
  createPackageReport,
  createMaterialItemReport,
  showPackagesModal,
  serialNumberValue,
  handleSerialNumberValue,
  clearSerialNumberValue,
  posValue,
  handlePosValue,
  clearPosValue,
  statusFilter,
  handleStatusFilter,
  isDataFilterActive,
}: UseGenerateTrackingListMaterialItemsColumnsProps): ColumnsType<MaterialStockItem> => [
  {
    title: t(materialName.title),
    render: (materialItem: MaterialStockItem) => {
      const materialLink =
        `${Routes.materialManagement.hyperlink}/${materialItem?.material_stock_batch?.material?.id}`?.replace(
          ":id/",
          ""
        );

      return (
        <PopConfirmButton
          buttonContent={materialItem?.material_stock_batch?.material?.name}
          onClick={() => {
            openHyperlinkInTab(materialLink);
          }}
        />
      );

      // return materialItem?.material_stock_batch?.material?.name;
    },
  },

  {
    title: t(serialNumber.title),
    render: (item: MaterialStockItem) => (
      <PopConfirmButton
        buttonContent={item?.serial_number}
        onClick={() =>
          openHyperlinkInTab(
            `${Routes.materialManagement.url}/article/${item?.id}`
          )
        }
        redirect={() =>
          openHyperlinkInTab(
            `${Routes.materialManagement.url}/article/${item?.id}`
          )
        }
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": !!serialNumberValue,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearSerialNumberValue}
        confirm={confirm}
        value={serialNumberValue}
        handleFilterChange={handleSerialNumberValue}
        visible={visible}
        placeholder={t("orders.serial-number")!}
        title={t("orders.enter-serial-number")!}
      />
    ),
  },
  {
    title: set.title,
    render: (item: MaterialStockItem) => (
      <Button onClick={() => openModalAndSelectOrder(item)}>
        {item?.order_production_set?.set_number}.
        {item?.order_production_set?.sub_set_number}
      </Button>
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearSetValue}
        confirm={confirm}
        value={setValue}
        handleFilterChange={handleSetValue}
        visible={visible}
        placeholder={t("orders.set-value")!}
        title={t("orders.exact-value")!}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-green-600  scale-125": !!setValue,
        })}
      />
    ),
  },
  {
    title: pos.title,
    render: (item: MaterialStockItem) => item?.order_production_set_position,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearPosValue}
        confirm={confirm}
        value={posValue}
        handleFilterChange={handlePosValue}
        visible={visible}
        placeholder={t("orders.pos-value")!}
        title={t("orders.exact-value")!}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-yellow-600  scale-125": !!posValue,
        })}
      />
    ),
  },
  {
    title: t(timestamp.title),
    render: (item: MaterialStockItem) => (
      <DateWithTimeRow text={item?.timestamp as string} />
    ),
    filterDropdown: () => {
      return (
        <DateRange
          title={t("orders.created-date")}
          leftPickerTitle={t("orders.created-date-lesser")}
          rightPickerTitle={t("orders.created-date-greater")}
          handlePickerChangeGreater={handleDateGreater}
          handlePickerChangeLesser={handleDateLesser}
        />
      );
    },
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-yellow-600  scale-125": !!isDataFilterActive,
        })}
      />
    ),
  },
  {
    title: t(calcTotalOriginalQuantity.title),
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_original_quantity ?? null),
  },
  {
    title: t(calcTotalConsumedQuantity.title),
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_consumed_quantity ?? null),
  },
  {
    title: t(calcTotalRemainingQuantity.title),
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_remaining_quantity ?? null),
  },

  {
    title: t(calcPercentageRemainingQuantity.title),
    render: (item: MaterialStockItem) =>
      `${item.calc_percentage_remaining_quantity}%`,
  },
  {
    title: t(status.title),
    render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
    filterDropdown: () => (
      <TableRadioGroup
        options={materialItemStatuses}
        selectedValue={statusFilter}
        onChange={handleStatusFilter}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-red-600  scale-125": !!statusFilter,
        })}
      />
    ),
  },
  {
    title: t(actions.title),
    render: (item: MaterialItem | MaterialStockItem) => (
      <div className="w-full flex items-center justify-around">
        <TableButton
          type="pdf-file"
          onClick={() => createPackageReport(item as MaterialStockItem)}
          className={classNames("text-gray-600", {
            "opacity-20 pointer-events-none": item?.packaging === null,
          })}
          popoverString={t("packaging.generate-package-report")!}
          disabled={item?.packaging === null}
        />
        <TableButton
          type="pdf-file"
          onClick={() => createMaterialItemReport(item)}
          className="text-purple-100"
        />

        <TableButton
          type="box"
          onClick={showPackagesModal}
          className="text-green-300"
        />
      </div>
    ),
  },
];
