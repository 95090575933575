import { Select } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DynamicStatusesType } from "shared/helpers";
import { UnitItemWithLabel } from "shared/api";
import { AddItemSelect, MultipleSelectWithLabels } from "./ui";

export interface OptionItem {
  label?: string;
  value: string | number | boolean | null;
}

interface GlobalSelectProps {
  value: any;
  fieldName: string;
  placeholder: string;
  options?: OptionItem[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  error?: string;
  isError?: boolean;
  showSearch?: boolean;
  multiple?: boolean;
  maxTagCount?: number;
  maxTagTextLength?: number;
  disabled?: boolean;
  withLabels?: boolean;
  optionsWithLabels?: UnitItemWithLabel[] | string[];
  extendedMultipleWithLabel?: boolean;
  addItemSelect?: boolean;
  setNewSelectItems?: React.Dispatch<React.SetStateAction<string[]>>;
  newSelectItems?: string[];
  setNewItemValue?: React.Dispatch<React.SetStateAction<string>>;
  newItemValue?: string;
  className?: string;
  styleType?: DynamicStatusesType;
  colorfulOptions?: string[];
  isLoading?: boolean;
  size?: "small" | "large";
}

const FormSelect: FC<GlobalSelectProps> = ({
  value,
  fieldName,
  placeholder,
  options,
  handleBlur,
  setFieldValue,
  error,
  isError,
  showSearch,
  multiple,
  maxTagCount,
  maxTagTextLength,
  disabled,
  withLabels,
  optionsWithLabels,
  extendedMultipleWithLabel,
  addItemSelect,
  setNewSelectItems,
  newSelectItems,
  setNewItemValue,
  newItemValue,
  className,
  styleType,
  colorfulOptions,
  isLoading,
  size = "large",
}) => {
  const { t } = useTranslation();

  if (addItemSelect) {
    return (
      <AddItemSelect
        placeholder={placeholder}
        fieldName={fieldName!}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        disabled={disabled}
        setNewItemValue={setNewItemValue}
        newItemValue={newItemValue}
        setNewSelectItems={setNewSelectItems}
        newSelectItems={newSelectItems}
        error={error}
        isError={isError}
      />
    );
  }

  if (extendedMultipleWithLabel) {
    return (
      <MultipleSelectWithLabels
        value={value}
        fieldName={fieldName}
        placeholder={placeholder}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        error={error}
        isError={isError}
        showSearch={showSearch}
        optionsWithLabels={optionsWithLabels as UnitItemWithLabel[]}
        disabled={disabled}
        maxTagCount={maxTagCount}
        maxTagTextLength={maxTagTextLength}
      />
    );
  }

  if (withLabels) {
    return (
      <>
        <Select
          bordered
          placeholder={placeholder}
          labelInValue
          size="large"
          className="w-full mb-1.25"
          onBlur={handleBlur}
          onSelect={(value: any) => {
            setFieldValue(`${fieldName}`, value.value);
          }}
          value={value}
          options={optionsWithLabels as UnitItemWithLabel[]}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          showSearch={showSearch}
          disabled={disabled}
        />
        {!!isError && (
          <div className="text-red-100 mt-1px">{t(`${error}`)}</div>
        )}
      </>
    );
  }

  if (multiple && Array.isArray(value)) {
    return (
      <>
        <Select
          bordered
          placeholder={placeholder}
          labelInValue
          className={classNames("w-full", {}, className)}
          onBlur={handleBlur}
          onSelect={(item) => {
            setFieldValue(`${fieldName}`, [...value, item.value]);
          }}
          onDeselect={(item) => {
            setFieldValue(
              `${fieldName}`,
              value.filter((option) => option !== item.value)
            );
          }}
          value={value}
          options={options}
          showSearch={showSearch}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          mode="multiple"
          size={size}
          maxTagCount={maxTagCount}
          maxTagTextLength={maxTagTextLength}
          disabled={disabled}
        />
        {!!isError && (
          <div className="text-red-100 mt-1px">{t(`${error}`)}</div>
        )}
      </>
    );
  }

  return (
    <>
      <Select
        bordered
        placeholder={placeholder}
        labelInValue
        size={size}
        className={classNames(
          "w-full mb-1.25",
          {
            "custom-select-error": styleType === "error",
            "custom-select-success": styleType === "success",
            "custom-select-warning": styleType === "warning",
          },
          className
        )}
        onBlur={handleBlur}
        onSelect={(value: any) => {
          setFieldValue(`${fieldName}`, value.value);
        }}
        value={value}
        options={colorfulOptions?.length ? undefined : options}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        showSearch={showSearch}
        disabled={disabled}
        loading={isLoading}
      >
        {colorfulOptions?.length &&
          options?.map((option, index) => {
            return (
              <Select.Option
                key={option.value as string}
                value={option.value}
                style={{
                  background: colorfulOptions?.[index],
                  marginBottom: "2px",
                }}
              >
                {option.label}
              </Select.Option>
            );
          })}
      </Select>
      {!!isError && <div className="text-red-100 mt-1px">{t(`${error}`)}</div>}
    </>
  );
};

export { FormSelect };
