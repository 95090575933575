import { Input, InputNumber } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface FormInputProps {
  value: string | number | null | undefined;
  fieldName: string;
  placeholder: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  error?: string;
  isError?: boolean;
  numberType?: boolean;
  disabled?: boolean;
  className?: string;
}

const FormInput: FC<FormInputProps> = ({
  value,
  fieldName,
  placeholder,
  handleChange,
  handleBlur,
  error,
  isError,
  numberType,
  disabled,
  className,
}) => {
  const { t } = useTranslation();

  const renderNumberInput = () => (
    <InputNumber
      value={value}
      size="large"
      className={`mb-1.25 no-arrows ${className}`}
      placeholder={t(`${placeholder}`)!}
      disabled={disabled}
      onChange={(value) => handleChange({ target: { name: fieldName, value } })}
      onBlur={handleBlur}
      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
        e.currentTarget.blur()
      }
    />
  );

  const renderBasicInput = () => (
    <Input
      type="text"
      value={value || ""}
      name={fieldName}
      className={`mb-1.25 ${className}`}
      placeholder={t(`${placeholder}`)!}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      size="large"
      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
        e.currentTarget.blur()
      }
    />
  );

  return (
    <>
      {numberType ? renderNumberInput() : renderBasicInput()}
      {!!isError && <div className="text-red-100 mt-1px">{t(`${error}`)}</div>}
    </>
  );
};

export { FormInput };
