import { Alert } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ExecuteReportValues, AdaptedValues } from "shared/types";
import * as Yup from "yup";
import { ReportItem } from "shared/api";
import { MaterialItemFilter } from "./MaterialItemFilter";
import { PackagingFilter } from "./PackagingFilter";
import { MultiplePackagingFilter } from "./MultiplePackagingFilter";
import { OrderTypeFilter } from "./OrderTypeFilter";
import { MultipleOrderFiltering } from "./MultipleOrderFiltering";
import { ExportReportHeader } from "./ExportReportHeader";
import { IsReelLabFilters } from "./IsReelLabFilters";
import { MultipleQualityFiltering } from "./MultipleQualityFiltering";
import { MultipleItemFilter } from "./MultipleItemFilter";

export interface ExecuteReports {
  handleExecuteReport: () => Promise<void>;
  isLoadingReportExport: boolean;
}

export interface ReportsData {
  handleGetReportData: () => Promise<void>;
  isLoadingReportData: boolean;
}

interface ExecuteReportFormProps {
  isNotSupportedYetType: boolean;
  isMaterialItem: boolean;
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
  isPackaging: boolean;
  isMultiplePackaging: boolean;
  isOrderType: boolean;
  isMultipleOrderType: boolean;
  executeReports: ExecuteReports;
  isValid: boolean;
  validationSchema?: Yup.AnyObjectSchema;
  getReportData: ReportsData;
  selectedReport?: ReportItem;
  pdfUrl: string | null;
  clearPdfUrl: () => void;
  isReelLabResultType: boolean;
  selectedMaterialId: number | null;
  onMaterialChange: (value: number | null) => void;
  isMultipleQualityType: boolean;
  isMultipleItemType: boolean;
}

const ExecuteReportForm: FC<ExecuteReportFormProps> = ({
  isMaterialItem,
  isNotSupportedYetType,
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
  isPackaging,
  isMultiplePackaging,
  isOrderType,
  isMultipleOrderType,
  executeReports,
  isValid,
  validationSchema,
  getReportData,
  selectedReport,
  pdfUrl,
  clearPdfUrl,
  isReelLabResultType,
  selectedMaterialId,
  onMaterialChange,
  isMultipleQualityType,
  isMultipleItemType,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values } = useFormikContext<ExecuteReportValues>();

  if (!values?.id) {
    return (
      <Alert
        showIcon
        type="warning"
        message={t("selectReport")}
        className="h-10 w-full"
      />
    );
  }

  return (
    <div className="w-full flex flex-col">
      <ExportReportHeader
        pdfUrl={pdfUrl}
        selectedReport={selectedReport}
        executeReports={executeReports}
        isValid={isValid}
        validationSchema={validationSchema}
        getReportData={getReportData}
        clearPdfUrl={clearPdfUrl}
      />

      {isNotSupportedYetType && (
        <Alert
          type="warning"
          showIcon
          className="mt-2"
          message={t("reportTypeIsNotSupportedYet")}
        />
      )}

      {isMaterialItem && (
        <MaterialItemFilter
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}

      {isPackaging && (
        <PackagingFilter
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}

      {isMultiplePackaging && (
        <MultiplePackagingFilter
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}

      {isOrderType && (
        <OrderTypeFilter
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}

      {isMultipleOrderType && (
        <MultipleOrderFiltering selectedReport={selectedReport} />
      )}

      {isReelLabResultType && (
        <IsReelLabFilters
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
          selectedMaterialId={selectedMaterialId}
          onMaterialChange={onMaterialChange}
        />
      )}

      {isMultipleQualityType && (
        <MultipleQualityFiltering
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}

      {isMultipleItemType && (
        <MultipleItemFilter
          adaptedOrders={adaptedOrders}
          selectedOrderId={selectedOrderId}
          onOrderChange={onOrderChange}
        />
      )}
    </div>
  );
};

export { ExecuteReportForm };
