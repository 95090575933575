import { Popover } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { WidgetTableData } from "shared/api";
import {
  ProductionDataTrackerFields,
  getHardAndSoftValues,
  getQualityResultIcon,
  openHyperlinkInTab,
} from "shared/helpers";
import { Routes } from "shared/routers";
import {
  DateWithTimeRow,
  PopConfirmButton,
  SoftHardValues,
  TableImage,
} from "shared/ui";

interface ProductionDataTrackerProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

const ProductionDataTracker: FC<ProductionDataTrackerProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const dynamicProdValue = record.productionTrackingValue;
  const articleId = record.productionTrackingArticleId;
  const serialNumber = record.productionTrackingSerialNumber;

  const { hardMaximum, hardMinimum, softMaximum, softMinimum } =
    getHardAndSoftValues(record);

  const isNoHardSoftValues =
    !hardMaximum && !hardMinimum && !softMaximum && !softMinimum;

  const isOutsideHardLimits =
    Number(dynamicProdValue) < hardMinimum ||
    Number(dynamicProdValue) > hardMaximum;

  const isInsideLimits =
    Number(dynamicProdValue) >= softMinimum &&
    Number(dynamicProdValue) <= softMaximum;

  if (fieldKey === ProductionDataTrackerFields.ICON) {
    return (
      <TableImage
        image={getQualityResultIcon(isOutsideHardLimits, isInsideLimits)}
        preview={false}
        className="w-9 h-9"
      />
    );
  }

  if (
    fieldKey === ProductionDataTrackerFields.PRODUCTION_TRACKING_SERIAL_NUMBER
  ) {
    const redirectLink =
      `${Routes.materialManagement.url}/article/${articleId}`.replace(
        ":id/",
        ""
      );

    return (
      <PopConfirmButton
        buttonContent={serialNumber.toString()}
        onClick={() => openHyperlinkInTab(redirectLink)}
      />
    );
  }

  if (fieldKey === ProductionDataTrackerFields.PRODUCTION_TRACKING_VALUE) {
    return (
      <Popover
        content={
          <SoftHardValues
            size="small"
            softMaximum={softMaximum}
            softMinimum={softMinimum}
            hardMaximum={hardMaximum}
            hardMinimum={hardMinimum}
          />
        }
      >
        <div
          className={classNames(
            `w-full h-6 flex items-center justify-center flex-col rounded-md`,
            {
              "!bg-red-100 !text-white": isOutsideHardLimits,
              "!bg-green-100 !text-white": isInsideLimits,
              "!bg-yellow-100": !isInsideLimits && !isOutsideHardLimits,
              "!bg-transparent": isNoHardSoftValues,
            }
          )}
        >
          {dynamicProdValue ? dynamicProdValue.toString() : "No Data"}
        </div>
      </Popover>
    );
  }

  if (fieldKey === ProductionDataTrackerFields.PRODUCTION_TRACKING_TIMESTAMP) {
    return <DateWithTimeRow text={text} />;
  }

  return <span>{text}</span>;
};

const ProductionDataTrackerMemo = React.memo(ProductionDataTracker);
export { ProductionDataTrackerMemo as ProductionDataTracker };
