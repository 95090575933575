import { Modal, notification } from "antd";
import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError, handleValidOrderDate } from "shared/helpers";
import {
  useGetAllCompaniesQuery,
  useUpdateOrderMutation,
  SupplierCompanyItem,
  useGetAllProductCardsWithNoParamsQuery,
} from "shared/api";
import { adaptCompanies, adaptProductCard } from "shared/adapters";
import { useValidOrderDate } from "shared/hooks";
import { ModalSizeValue, StatusDropdownValues } from "shared/constants";
import { AddOrUpdateOrderValues } from "shared/types";

import { DynamicCompanyFields } from "./DynamicCompanyFields";

interface EditOrderModalProps {
  isOpenModal: boolean;
  hideEditOrderModal: () => void;
  dynamicCompanyFields?: SupplierCompanyItem;
}

const EditOrderModal: FC<EditOrderModalProps> = ({
  isOpenModal,
  hideEditOrderModal,
  dynamicCompanyFields,
}) => {
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    isValid,
  } = useFormikContext<AddOrUpdateOrderValues>();

  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const timezone = localStorage.getItem("timezone") ?? "UTC";

  const closeModal = () => {
    hideEditOrderModal();
    resetForm();
  };

  const { data: productCards } = useGetAllProductCardsWithNoParamsQuery({});
  const adaptedProductCard1 = adaptProductCard(productCards || []);

  const handleWantedDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue(
      "wanted_delivery_datetime",
      dayjs.tz(dateString, timezone).format()
    );
  };

  const handleProductionDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue(
      "production_datetime",
      dayjs.tz(dateString, timezone).format()
    );
  };

  const [updateOrder] = useUpdateOrderMutation();

  const updateOrderRequest = async () => {
    try {
      await updateOrder({
        id: values.id!,
        buyer: values.subBuyer ? values.subBuyer : values.buyer!,
        po_number: values.po_number!,
        product_card: values.product_card!,
        production_datetime: dayjs(values.production_datetime!).utc().format(),
        quantity_expected: values.quantity_expected!,
        status: values.status!,
        wanted_delivery_datetime: dayjs(values.wanted_delivery_datetime!)
          .utc()
          .format(),
        dynamic_order_fields: values.dynamic_order_fields,
        customer_order_number: values.customer_order_number!,
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.update-msg"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(error, t("orders.error"));

      api.error({
        message: t("orders.error"),
        description,
      });
    }
  };

  const isValidDate = useValidOrderDate(
    isOpenModal,
    values,
    handleValidOrderDate,
    api
  );

  const { data: companies } = useGetAllCompaniesQuery(
    {},
    {
      refetchOnReconnect: true,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: values.buyer === null,
    }
  );

  const adaptedCompanies = useMemo(() => {
    return adaptCompanies(companies || []);
  }, [companies]);

  const orderBuyer = useMemo(() => {
    return companies?.find((item) => item.id === values.buyer);
  }, [values.buyer, companies]);

  return (
    <>
      {contextHolder}
      <Modal
        title={t("orders.edit-order")}
        open={isOpenModal}
        width={ModalSizeValue.X_MEDIUM}
        okButtonProps={{
          disabled: !isValid || !isValidDate,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          updateOrderRequest();
        }}
      >
        <Title level={5}>{t("orders.buyer")}</Title>

        <FormSelect
          value={orderBuyer?.id}
          placeholder={t("orders.buyer-placeholder")}
          fieldName="buyer"
          options={adaptedCompanies}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.buyer?.toString()}
          isError={!!errors.buyer}
          showSearch
        />

        <Title level={5} className="mb-1.25">
          {t("orders.po-number")}
        </Title>

        <FormInput
          value={values.po_number}
          fieldName="po_number"
          placeholder={t("orders.po-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.po_number}
          isError={!!errors.po_number}
        />

        <Title level={5} className="mb-1.25">
          {t("orders.customer-order-number")}
        </Title>

        <FormInput
          value={values.customer_order_number!}
          fieldName="customer_order_number"
          placeholder={t("orders.customer-order-number-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.customer_order_number}
          isError={!!errors.customer_order_number}
        />

        <Title level={5} className="mb-2 mt-2">
          {t("orders.production-datetime")}
        </Title>

        <GlobalDatePicker
          value={dayjs(values.production_datetime).tz(timezone)}
          handlePickerChange={handleProductionDateTimeChange}
          error={errors.production_datetime}
          isError={!!errors.production_datetime}
          showTime
        />

        <Title level={5} className="mb-1.25">
          {t("orders.wanter-delivery-datetime")}
        </Title>

        <GlobalDatePicker
          value={dayjs(values.wanted_delivery_datetime).tz(timezone)}
          handlePickerChange={handleWantedDateTimeChange}
          error={errors.wanted_delivery_datetime}
          isError={!!errors.wanted_delivery_datetime}
          minDate={dayjs.tz(values.production_datetime, timezone)}
          disabled={!values.production_datetime}
          showTime
        />

        <Title level={5} className="mt-1.25">
          {t("orders.product-card")}
        </Title>

        <FormSelect
          value={values.product_card}
          placeholder={t("orders.product-card-placeholder")}
          fieldName="product_card"
          options={adaptedProductCard1}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.product_card?.toString()}
          isError={!!errors.product_card}
          showSearch
          disabled
        />

        <Title level={5} className="mb-1.25">
          {t("orders.quantity-expected")}
        </Title>

        <FormInput
          value={values.quantity_expected}
          fieldName="quantity_expected"
          placeholder={t("orders.quantity-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.quantity_expected}
          isError={!!errors.quantity_expected}
          numberType
        />

        <Title level={5}>{t("orders.status")}</Title>

        <FormSelect
          value={values.status}
          placeholder={t("orders.status-placeholder")}
          fieldName="status"
          options={StatusDropdownValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status?.toString()}
          isError={!!errors.status}
          showSearch
        />

        <DynamicCompanyFields dynamicCompanyFields={dynamicCompanyFields} />
      </Modal>
    </>
  );
};

export { EditOrderModal };
