import { Badge, Select } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import {
  ConfigDynamicTrackerFields,
  DynamicOutputMaterialData,
  MaterialCategoryFieldItem,
} from "shared/api";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface ProdChoicesInputProps {
  card: ConfigDynamicTrackerFields;
  cardIndex: number;
  dynamicTracker: DynamicOutputMaterialData[];
  field: MaterialCategoryFieldItem;
  inputIndex: number;
  memoizedSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}
// eslint-disable-next-line react/display-name
const ProdChoicesInput: FC<ProdChoicesInputProps> = React.memo(
  ({
    card,
    cardIndex,
    dynamicTracker,
    field,
    inputIndex,
    memoizedSetFieldValue,
  }) => {
    const { t } = useTranslation("", { keyPrefix: "orders" });

    const value =
      dynamicTracker?.[0]?.dynamic_tracker_fields?.[card?.title]?.[field.name];

    const currentField = card?.fields?.[cardIndex];
    const fieldName = `output_materials.${inputIndex}.dynamicData.${0}.dynamic_tracker_fields.${
      card.title
    }.${field.name}`;

    const { isRequired } = useMemo(
      () => ({
        isRequired: currentField?.required,
      }),
      [currentField]
    );

    const handleChange = useCallback(
      (value: string | number) => {
        memoizedSetFieldValue(fieldName, value);
      },
      [inputIndex, memoizedSetFieldValue, 0, card.title, field.name]
    );

    return (
      <div className="flex flex-col gap-y-2">
        <Badge.Ribbon text="" color={isRequired ? "red" : "transparent"}>
          <Select
            value={value}
            className={classNames("w-full", {})}
            placeholder={t("selectPlaceholder", { field: field.name })!}
            onChange={(value) => handleChange(value)}
          >
            {(field?.choices as string[] | number[])?.map((choice) => (
              <Select.Option key={choice} value={choice}>
                {choice}
              </Select.Option>
            ))}
          </Select>
        </Badge.Ribbon>
      </div>
    );
  }
);

export { ProdChoicesInput };
