import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Sidebar } from "shared/ui";
import {
  useConfirmDeleteModal,
  useDashboardMainPageRequests,
  useModal,
} from "shared/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { handleRequestError, noop } from "shared/helpers";
import { useDeleteDashboardMutation } from "shared/api";
import { Routes } from "shared/routers";
import { WidgetsLayout, WidgetsModal } from "./ui";

interface DashboardsProps {}

const Dashboards: FC<DashboardsProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteDashboard] = useDeleteDashboardMutation();

  const [isOpenWidgetsModal, showWidgetsModal, hideWidgetsModal] = useModal();

  const { dashboardsData, isLoading, sortedWidgetsWithArticle } =
    useDashboardMainPageRequests({
      dashboardId: id,
    });

  const handleDeleteDashboard = async () => {
    try {
      await deleteDashboard({ id: Number(id) });
      navigate(Routes.orders.url);
    } catch (error) {
      handleRequestError(error, t("dashboards.error-title"));
    }
  };

  const showDeleteDashboardModal = useConfirmDeleteModal(
    t("dashboards.delete-dashboard-warning"),
    handleDeleteDashboard
  );

  const widgetsFormik = useFormik({
    initialValues: {
      id: dashboardsData?.id,
      name: dashboardsData?.name,
      widgets: dashboardsData?.widgets ?? [],
      settings: null,
      configuration: dashboardsData?.configuration ?? [],
    },
    enableReinitialize: true,
    onSubmit: noop,
  });

  if (isLoading) return <Loader type="fullscreen" />;

  return (
    <FormikProvider value={widgetsFormik}>
      <div className="w-full h-full flex">
        <WidgetsModal isOpen={isOpenWidgetsModal} onClose={hideWidgetsModal} />

        <Sidebar isOpenSidebar />

        <div className="w-full">
          <div className="p-3.75">
            <div className="flex gap-x-5">
              <Title className="capitalize">
                {t("dashboards.title", {
                  name: dashboardsData?.name,
                })}
              </Title>

              <Button
                type="primary"
                danger
                onClick={() => showDeleteDashboardModal(dashboardsData)}
                className="mt-2 hover:!text-white !bg-red-700 hover:!bg-red-100"
              >
                {t("dashboards.delete-dashboard-title")}
              </Button>
            </div>

            <div className="flex justify-between items-center">
              <Button
                type="primary"
                className="btn-primary--dark"
                onClick={showWidgetsModal}
              >
                {t("dashboards.add-widget")}
                <PlusOutlined />
              </Button>
            </div>
          </div>
          <WidgetsLayout
            dashboardsData={dashboardsData}
            sortedWidgetsWithArticle={sortedWidgetsWithArticle}
          />
        </div>
      </div>
    </FormikProvider>
  );
};

const DashboardsMemo = React.memo(Dashboards);

export { DashboardsMemo as Dashboards };
