import { ConfigDynamicTrackerFields } from "../materials/models";

export const enum PackageSituationEnum {
  SE = "SE",
  NO = "NO",
}
export interface PackagingItem {
  id: number;
  name: string;
  material: {
    id: number;
    name: string;
    material_category: number;
    suppliers: number[];
    target_quantity_in_stock: number;
    n_packaging_items: number;
  };
  calc_count_material_items: number;
  order: number;
  material_items: MaterialItem[];
  situation?: PackageSituationEnum; // Read only field
}

export interface MaterialItem {
  id: number;
  material_stock_batch: number;
  packaging: number;
  serial_number: string;
  quantity: number;
  dynamic_tracker_fields: ConfigDynamicTrackerFields;
  status: string;
  order_production_set: number | null;
  order_production_set_position: number | null;
  production_number: number | null;
}

export interface PackagingResponse {
  count: number;
  next: string;
  previous: string;
  results: PackagingItem[];
}

export interface PackagingParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string | null;
  name__icontains?: string;
  order?: null | number;
  material?: number | string;
}

export interface CreatePackage {
  name: string;
  material: number;
  order: number;
  situation: PackageSituationEnum;
}

export interface UpdatePackage {
  id: number;
  name: string;
  material: number;
  order: number;
  situation: PackageSituationEnum;
}

export interface GeneratePackageReportParams {
  id: number;
  name: string;
  material: number;
  order: number;
}

export interface PackagesParams {
  order?: number;
}
