export const expandMaterialCategoriesWithSuppliersAndUnit =
  "unit,suppliers.company";
export const expandUnit = "unit";
export const expandDimensionUnits = "dimension";

export const enum ModalSize {
  SMALL = "500px",
  LARGE = "80%",
}

export const enum dynamicSpecificationTypesEnum {
  INTEGER = "integer",
  TEXT = "text",
  CHOICES = "choices",
  FLOAT = "float",
}

export const pushNewRowInitialValues = {
  name: "",
  type: null,
  description: "",
  required: false,
};

export const dynamicSpecificationTypes = [
  {
    label: "Integer",
    value: "integer",
  },
  {
    label: "Float",
    value: "float",
  },
  { label: "Text", value: "text" },
  {
    label: "Choices",
    value: "choices",
  },
];

export const materialTableColumnsData = {
  name: {
    title: "materialTableColumns.name",
    dataIndex: "name",
    key: "name",
    width: "80%",
  },
  unit: {
    title: "materialTableColumns.unit",
    dataIndex: "unit",
    key: "unit",
    width: "10%",
  },
  uniqueCode: {
    title: "materialTableColumns.uniqueCode",
    dataIndex: "unique_code",
    key: "unique_code",
    width: "10%",
  },
  suppliers: {
    title: "materialTableColumns.suppliers",
  },
  actions: {
    title: "materialTableColumns.actions",
    key: "actions",
    width: "10%",
  },
};

export const initialMaterialCategoryValues = {
  id: null,
  lock_material_consume_variant: false,
  default_material_consume_variant: null,
  name: "",
  unit: null,
  unique_code: null,
  newCardName: "",
  sections: [],
  export_col_for_serial_number: null,
  export_col_for_quantity: null,
  suppliers: [],
};

export const materialCategoryInitialValues = {
  id: null,
  name: "",
  unit: null,
  unique_code: null,
  newCardName: "",
  sections: [],
};

export const lockMaterialCategoryValues = [
  { value: true, label: "Lock" },
  { value: false, label: "Unlock" },
];

export const variantMaterialSelectValues = [
  { label: "Material", value: "material" },
  { label: "Material item", value: "material_item" },
  {
    label: "Stock Batch",
    value: "material_stock_batch",
  },
];
