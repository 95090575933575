import { useState } from "react";
import { useDebouncedValue } from "./useDebouncedValue";

const useDebouncedTableFilter = (initialValue: string, delay: number) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const clearFilterValue = () => setValue("");

  const debouncedValue = useDebouncedValue(value, delay);

  return {
    value,
    handleFilterChange,
    debouncedValue,
    clearFilterValue,
  };
};

export { useDebouncedTableFilter };
