export const Routes = {
  signIn: {
    url: "/",
    title: "ChainLinx",
  },
  users: {
    url: "/users",
    title: "Users",
  },
  supplyChain: {
    url: "/supply-chain",
    title: "Supply Chain",
  },
  materialManagement: {
    url: "/material-management",
    articleUrl: "/material-management/article/:id",
    title: "Material Management",
    hyperlink: "/material-management/hyperlink/:id",
  },
  createMaterial: {
    url: "/create-material",
    title: "Create Material",
  },
  materialCategoryManagement: {
    url: "/material-category-management",
    title: "Category Management",
    hyperlink: "/material-category-management/hyperlink/:id",
  },
  stockMaterial: {
    url: "/add-stock-material",
    title: "Stock Material",
  },
  processTemplates: {
    url: "/process-templates",
    title: "Process Templates",
    hyperlink: "/process-templates/hyperlink/:id",
  },
  machines: {
    url: "/machines",
    hyperlinkUrl: "/machines/hyperlink/:id",
    title: "Machines",
  },
  productCards: {
    url: "/product-cards",
    title: "Product Cards",
    hyperlink: "/product-cards/hyperlink/:id",
  },
  processSteps: {
    url: "/process-steps",
    title: "Process Steps",
    hyperlink: "/process-steps/hyperlink/:id",
  },
  companies: {
    url: "/companies",
    title: "Companies",
    hyperlink: "/companies/hyperlink/:companyID",
  },
  orders: {
    url: "/orders",
    title: "Orders",
    nestedTitle: "Orders Table",
    hyperlinkUrl: "/orders/hyperlink/:orderID",
    reportsUrl: "/orders/reports/",
  },
  processStepProduction: {
    url: "/process-step-production/:orderCode/index-:index",
    nestedUrl: "/process-step-production/:id/:productCardID",
  },
  orderPlans: {
    url: "/order-plans",
    nestedUrl: "/order-plans/:id",
    title: "Order Plans",
  },
  packaging: {
    url: "/packaging",
    title: "Packaging",
  },
  quality: {
    url: "/quality",
    title: "Quality",
  },
  config: {
    url: "/config",
    title: "Config",
  },
  templateConfig: {
    url: "/template-config",
    title: "Template Config",
  },
  dashboards: {
    url: "/dashboards/:id",
    title: "Dashboards",
  },
  executeReport: {
    url: "/execute-report",
    title: "Execute Report",
  },
  itemView: {
    url: "/item-view",
    title: "Item View",
  },
  standAlone: {
    url: "/standalone",
    title: "Stand Alone",
  },
};
