import { MaterialStockItem, MaterialStockItemsStatus } from "shared/api";
import { MaterialItemSituation } from "shared/constants";

export const renderMaterialItemsStatuses = (item: MaterialStockItem) => {
  if (item.status === MaterialStockItemsStatus.PRIMA) return "Prima";
  if (item.status === MaterialStockItemsStatus.WASTE) return "Waste";
  if (item.status === MaterialStockItemsStatus.INVESTIGATION)
    return "Investigation";

  return "Unknown status";
};

export const materialItemsSituations: Record<MaterialItemSituation, string> = {
  [MaterialItemSituation.NORMAL]: "Normal",
  [MaterialItemSituation.SENT]: "Sent",
  [MaterialItemSituation.USED]: "Used",
};

export const renderMaterialItemSituation = (situation: string) => {
  const status = materialItemsSituations[situation as MaterialItemSituation];
  return status || "Unknown Situation";
};
