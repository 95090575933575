import React, { FC } from "react";
import { Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { availableQuantitiesChartColors } from "shared/constants";
import { MaterialsItem } from "shared/api";

interface VolumesProps {
  materialItem: MaterialsItem | null;
}

const Volumes: FC<VolumesProps> = ({ materialItem }) => {
  const { t } = useTranslation();

  return (
    <Space className="mb-3">
      <Tag
        color={availableQuantitiesChartColors.available.backgroundColor}
        className="
            !text-black h-auto px-2 
            py-1 flex items-center 
            justify-center !text-[14px]
          "
      >
        {t("material-management.available")}:
        {materialItem?.calc_total_available_quantity}
      </Tag>

      <Tag
        color={availableQuantitiesChartColors.reserved.backgroundColor}
        className="
            !text-black h-auto px-2 
            py-1 flex items-center 
            justify-center !text-[14px]
          "
      >
        {t("material-management.reserved")}:
        {materialItem?.calc_total_reserved_quantity}
      </Tag>
    </Space>
  );
};

export { Volumes };
