export const machinesColumnsData = {
  name: {
    title: "machinesTableColumns.name",
    dataIndex: "name",
    key: "name",
    width: "45%",
  },
  processTemplate: {
    title: "machinesTableColumns.processTemplate",
    key: "process_template",
    width: "45%",
  },
  actions: {
    title: "machinesTableColumns.actions",
    key: "actions",
    width: "10%",
  },
};

export const expandMachinesString = "process_template";

export const enum MachinesSorting {
  NAME_ASC = "name",
  NAME_DESC = "-name",
}

export const machinesInitialValues = {
  id: null,
  name: null,
  process_template: null,
};
