import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import {
  UpdateDashboardParams,
  UpdateWidgets,
  Widget,
  WidgetType,
  WidgetTypeEnum,
  useUpdateDashboardMutation,
} from "shared/api";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  handleRequestError,
  returnInitialColumnNamesByWidgetType,
  returnInitialConfigurationsByWidgetType,
  returnInitialFiltersByWidgetType,
} from "shared/helpers";
import { useFormikContext } from "formik";
import { WidgetCard } from "./WidgetCard";

interface WidgetsSectionProps {
  category: {
    category: string;
    widgets: Widget[];
  };
  onClose: () => void;
}

const WidgetsSection: FC<WidgetsSectionProps> = ({ category, onClose }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [updateDashboard] = useUpdateDashboardMutation();

  const { values } = useFormikContext<UpdateDashboardParams>();

  const addWidget = async (name: string, widgetType: WidgetType) => {
    try {
      await updateDashboard({
        id: values?.id,
        name: values?.name,
        widgets: [
          ...(values?.widgets ?? []),
          {
            type: widgetType,
            row: Number(values?.widgets?.length) + 1,
            column: 1,
            column_names: returnInitialColumnNamesByWidgetType(
              widgetType as WidgetTypeEnum
            ),
            limit_rows: 3,
            sort_by: [],
            filter: returnInitialFiltersByWidgetType(
              widgetType as WidgetTypeEnum
            ),
            configuration: returnInitialConfigurationsByWidgetType(
              widgetType as WidgetTypeEnum
            ),
          } as unknown as UpdateWidgets,
        ],
        configuration: values?.configuration ?? [],
      }).unwrap();

      api.success({
        message: t("dashboards.widget-added"),
        description: t("dashboards.widget-added-message", { name }),
      });
    } catch (error) {
      api.error({
        message: t("dashboards.error-title"),
        description: handleRequestError(error, t("dashboards.error-title")),
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      {contextHolder}

      <div key={category.category} className="w-full">
        <Title level={5}>{t(category.category)}</Title>

        <div className="flex flex-wrap gap-2">
          {category.widgets.map((widget) => (
            <WidgetCard
              key={widget?.id}
              title={widget?.name!}
              onClick={() => addWidget(widget?.name!, widget?.type)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const WidgetsSectionMemo = React.memo(WidgetsSection);

export { WidgetsSectionMemo as WidgetsSection };
