import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AddConfigurationImage,
  ConfigurationImage,
  ConfigurationItem,
  ConfigurationParams,
} from "./models";

export const configurationsApi = createApi({
  baseQuery,
  reducerPath: "configurationsApi",
  tagTypes: ["configurations"],
  endpoints: (builder) => ({
    getAllConfigurations: builder.query<
      ConfigurationItem[],
      ConfigurationParams
    >({
      query: (params) => ({
        url: "/api/v1/configurations/",
        method: "GET",
        params,
      }),
      providesTags: ["configurations"],
    }),

    addConfigurationField: builder.mutation<void, ConfigurationItem>({
      query: (body) => ({
        url: "/api/v1/configurations/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["configurations"],
    }),

    updateConfigurationField: builder.mutation<void, ConfigurationItem>({
      query: (body) => ({
        url: `/api/v1/configurations/${body.key}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["configurations"],
    }),

    getConfigurationsImage: builder.query<ConfigurationImage, void>({
      query: () => ({
        url: "/api/v1/configurations/image/",
        method: "GET",
      }),
      providesTags: ["configurations"],
    }),

    uploadConfigurationImage: builder.mutation<void, AddConfigurationImage>({
      query: (body) => ({
        url: "/api/v1/configurations/image/",
        method: "POST",
        body: body.image,
        formData: true,
      }),

      invalidatesTags: ["configurations"],
    }),
  }),
});

export const {
  useGetAllConfigurationsQuery,
  useAddConfigurationFieldMutation,
  useUpdateConfigurationFieldMutation,
  useGetConfigurationsImageQuery,
  useUploadConfigurationImageMutation,
} = configurationsApi;
