import * as Yup from "yup";
import {
  createDynamicChoicesValidation,
  createDynamicUnitValidation,
} from "shared/helpers";

export const AddCompanySchema = Yup.object().shape({
  name: Yup.string().required("companies.company-required-validation"),
  address: Yup.string().required("companies.address-required-validation"),
  payment_term_net_days: Yup.number()
    .nullable()
    .required("companies.payment-term-net-days-required-validation"),
  inco_terms: Yup.string()
    .min(3, "companies.inco-terms-min-validation")
    .max(3, "companies.inco-terms-max-validation")
    .required("companies.inco-terms-validation"),
  evaluation_as_supplier: Yup.number()
    .integer()
    .nullable()
    .min(0)
    .max(10, "companies.evaluation-as-supplier-validation-max")
    .when("is_supplier", {
      is: "customer",
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number()
        .nullable()
        .required("companies.evaluation-as-supplier-validation"),
    }),
  is_supplier: Yup.mixed()
    .oneOf(["customer", "supplier", "both"], "companies.error-validation-msg")
    .required("companies.error-validation-msg"),
  is_buyer: Yup.boolean().required("companies.error-validation-msg"),
  categories_supplied: Yup.array().of(
    Yup.object().shape({
      material_category: Yup.number().required("material-management.required"),
    })
  ),
  vat_number: Yup.string().nullable(),
  email: Yup.string()
    .email()

    .nullable(),
  phone: Yup.string().nullable(),
  sections: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required(
        "material-category-management.section-title-validation"
      ),
      fields: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(
            "material-category-management.name-validation"
          ),
          type: Yup.string()
            .nullable()
            .required("material-category-management.type-validation"),

          choices: createDynamicChoicesValidation(),

          unit: createDynamicUnitValidation(),
        })
      ),
    })
  ),
});

export const EditCompanySchema = Yup.object().shape({
  name: Yup.string().required("companies.company-required-validation"),
  address: Yup.string().required("companies.address-required-validation"),
  payment_term_net_days: Yup.number()
    .nullable()
    .required("companies.payment-term-net-days-required-validation"),
  inco_terms: Yup.string()
    .min(3, "companies.inco-terms-min-validation")
    .max(3, "companies.inco-terms-max-validation")
    .required("companies.inco-terms-validation"),
  evaluation_as_supplier: Yup.number()
    .integer()
    .nullable()
    .min(0)
    .max(10, "companies.evaluation-as-supplier-validation-max")
    .when("is_supplier", {
      is: "customer",
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number()
        .nullable()
        .required("companies.evaluation-as-supplier-validation"),
    }),
  is_supplier: Yup.mixed()
    .oneOf(["customer", "supplier", "both"], "companies.error-validation-msg")
    .required("companies.error-validation-msg"),
  is_buyer: Yup.boolean().required("companies.error-validation-msg"),
  categories_supplied: Yup.array().of(
    Yup.object().shape({
      material_category: Yup.object().shape({
        id: Yup.number().required("material-management.required"),
      }),
    })
  ),
  vat_number: Yup.string().nullable(),
  email: Yup.string().email().nullable(),
  phone: Yup.string().nullable(),
  sections: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required(
        "material-category-management.section-title-validation"
      ),
      fields: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(
            "material-category-management.name-validation"
          ),
          type: Yup.string()
            .nullable()
            .required("material-category-management.type-validation"),

          choices: createDynamicChoicesValidation(),

          unit: createDynamicUnitValidation(),
        })
      ),
    })
  ),
});
