import { FieldArray, FieldArrayRenderProps } from "formik";
import React, { FC } from "react";
import { CardHeader } from "shared/features";
import { DynamicCardRow } from "shared/ui/DynamicCardRow";
import {
  ModalValuesProps,
  NewSectionNameModal,
} from "shared/ui/NewSectionNameModal";
import { UnitItemWithLabel } from "shared/api";
import {
  categorySectionContainer,
  formContainer,
  formRowContainer,
} from "../styles";

interface DynamicCardProps {
  index: number;
  showNewSectionModal: () => void;
  remove?: (index: number) => void;
  adaptedDimensionUnits: UnitItemWithLabel[];
  isOpenNewSectionModal: boolean;
  hideNewSectionModal: () => void;
  push: (obj: any) => void;
}

const DynamicCard: FC<DynamicCardProps> = ({
  index,
  showNewSectionModal,
  remove,
  adaptedDimensionUnits,
  isOpenNewSectionModal,
  hideNewSectionModal,
  push,
}) => {
  return (
    <div key={`sections.${index}`} style={categorySectionContainer}>
      <CardHeader
        index={index}
        onClick={showNewSectionModal}
        remove={() => remove?.(index)}
      />
      <div style={formContainer}>
        <div style={formRowContainer}>
          <div className="w-full">
            <FieldArray name={`sections.${index}.fields`}>
              {(fieldArrayProps: FieldArrayRenderProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;

                return values?.sections?.[index]?.fields?.map(
                  (item: ModalValuesProps, fieldIndex: number) => (
                    <DynamicCardRow
                      key={fieldIndex}
                      index={index}
                      fieldIndex={fieldIndex}
                      adaptedDimensionUnits={adaptedDimensionUnits}
                      remove={remove}
                      push={push}
                    />
                  )
                );
              }}
            </FieldArray>
          </div>
        </div>
      </div>
      <NewSectionNameModal
        isOpenNewSectionModal={isOpenNewSectionModal}
        hideNewSectionModal={hideNewSectionModal}
        push={push}
      />
    </div>
  );
};

export { DynamicCard };
