import { Modal, notification } from "antd";
import React, { FC, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormSelect,
  GlobalDatePicker,
  FormInput,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useCreateMaterialConsumedMutation, MaterialsItem } from "shared/api";
import {
  ModalSizeValue,
  ConsumedSelectFieldVariant,
  ConsumedStatusesDropDown,
  SelectFieldValues,
} from "shared/constants";
import { AddMaterialConsumedValues } from "shared/types";

import { FilteredStockBatchTable } from "./FilteredStockBatchTable";
import { FilteredMaterialItemsTable } from "./FilteredMaterialItemsTable";

interface AddConsumedModalProps {
  isOpenModal: boolean;
  setOpenAddConsumedModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMaterial: MaterialsItem | null;
}

const AddConsumedModal: FC<AddConsumedModalProps> = ({
  isOpenModal,
  setOpenAddConsumedModal,
  selectedMaterial,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    resetForm,
    isValid,
  } = useFormikContext<AddMaterialConsumedValues>();

  useEffect(() => {
    if (values.fieldVariant === SelectFieldValues.MATERIAL) {
      setFieldValue("material", selectedMaterial?.id);
      setFieldValue("material_stock_batch", null);
      setFieldValue("material_item", null);
    }
  }, [values.fieldVariant]);

  const handleConsumedDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("consumed_datetime", dateString);
  };

  const [createMaterialConsumed] = useCreateMaterialConsumedMutation();

  const createMaterialConsumedItemRequest = async () => {
    try {
      await createMaterialConsumed({
        material: values.material,
        quantity: values.quantity!,
        consumed_datetime: values.consumed_datetime!,
        material_item: values.material_item,
        material_stock_batch: values.material_stock_batch,
        order_process_step_tracker: values.order_process_step_tracker,
        reason: values.reason!,
        status: values.status!,
      }).unwrap();
      api.success({
        message: t("material-consumed.success"),
        description: t("material-consumed.success-msg"),
      });
      setOpenAddConsumedModal(false);
      resetForm();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-consumed.error")
      );

      api.error({
        message: t("material-consumed.error"),
        description,
      });
    }
  };

  const changeModalWidth = () => {
    if (values.fieldVariant === "material") {
      return ModalSizeValue.PRIMARY;
    }

    if (
      values.fieldVariant === "material_item" ||
      values.fieldVariant === "material_stock_batch"
    ) {
      return ModalSizeValue.LARGE;
    }

    return ModalSizeValue.PRIMARY;
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("material-consumed.add-consumed")}
        open={isOpenModal}
        width={changeModalWidth()}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={() => {
          setOpenAddConsumedModal(false);
          resetForm();
        }}
        onOk={() => {
          createMaterialConsumedItemRequest();
        }}
      >
        <Title level={5}> {t("material-consumed.date-time")}</Title>

        <GlobalDatePicker
          handlePickerChange={handleConsumedDateTimeChange}
          error={errors.consumed_datetime}
          isError={!!errors.consumed_datetime}
        />

        <Title level={5} className="mb-1.25">
          {t("material-consumed.quantity")}
        </Title>

        <FormInput
          value={values.quantity}
          fieldName="quantity"
          placeholder={t("material-consumed.quantity-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.quantity}
          isError={!!errors.quantity}
          numberType
        />

        <Title level={5}> {t("material-consumed.field-variant")}</Title>

        <FormSelect
          value={values.fieldVariant}
          placeholder={t("material-consumed.variant-placeholder")}
          fieldName="fieldVariant"
          options={ConsumedSelectFieldVariant}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.fieldVariant?.toString()}
          isError={!!errors.fieldVariant}
        />

        {values.fieldVariant === SelectFieldValues.MATERIAL && (
          <>
            <Title level={5}> {t("material-consumed.material")}</Title>

            <FormInput
              value={selectedMaterial?.name}
              fieldName="material"
              placeholder={t("material-consumed.material-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.material}
              isError={!!errors.material}
              disabled
            />
          </>
        )}

        {values.fieldVariant === SelectFieldValues.MATERIAL_STOCK_BATCH && (
          <FilteredStockBatchTable selectedMaterial={selectedMaterial} />
        )}

        {values.fieldVariant === SelectFieldValues.MATERIAL_ITEM && (
          <FilteredMaterialItemsTable selectedMaterial={selectedMaterial} />
        )}

        <Title level={5} className="mb-1.25">
          {t("material-consumed.reason")}
        </Title>

        <FormInput
          value={values.reason}
          fieldName="reason"
          placeholder={t("material-consumed.reason-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Title level={5}> {t("material-consumed.status")}</Title>

        <FormSelect
          value={values.status}
          placeholder={t("material-consumed.status-placeholder")}
          fieldName="status"
          options={ConsumedStatusesDropDown}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status?.toString()}
          isError={!!errors.status}
        />
      </Modal>
    </>
  );
};

export { AddConsumedModal };
