import React, { FC } from "react";
import { Loader } from "shared/ui";
import { OrderStepTrackerResponseItem } from "shared/api";
import { useModal } from "shared/hooks";
import { ProducedItemsInOrdersModal } from "./ProducedItemsInOrdersModal";
import { ProdPageHeader } from "./ProdPageHeader";
import { ProdPageContent } from "./ProdPageContent";

interface DynamicProcessStepsForTheOrderProps {
  processStepId?: number;
  orderId?: string;
  incrementProcessStepIndex: () => void;
  decrementProcessStepIndex: () => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  isLoadingOrderData: boolean;
  clearSelectedOrder: () => void;
  refetchOrderProcessStepTracker: () => void;
  isLoadingOrderCodes: boolean;
  navigateOrder: (direction: "next" | "previous") => void;
}

const DynamicProcessStepsForTheOrder: FC<
  DynamicProcessStepsForTheOrderProps
> = ({
  processStepId,
  orderId,
  incrementProcessStepIndex,
  decrementProcessStepIndex,
  orderStepTrackerResponseItem,
  isLoadingOrderData,
  clearSelectedOrder,
  refetchOrderProcessStepTracker,
  isLoadingOrderCodes,
  navigateOrder,
}) => {
  const [
    producedItemInOrderModal,
    openProducedItemInOrderModal,
    hideProducedItemInOrderModal,
  ] = useModal();

  const closeProdPageModal = () => {
    hideProducedItemInOrderModal();
    clearSelectedOrder();
  };

  return (
    <>
      <ProdPageHeader
        incrementProcessStepIndex={incrementProcessStepIndex}
        decrementProcessStepIndex={decrementProcessStepIndex}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        closeProdPageModal={closeProdPageModal}
        isLoadingOrderCodes={isLoadingOrderCodes}
        navigateOrder={navigateOrder}
      />

      {!isLoadingOrderData ? (
        <ProdPageContent
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          processStepId={processStepId}
          orderId={orderId}
          openProducedItemInOrderModal={openProducedItemInOrderModal}
          refetchOrderProcessStepTracker={refetchOrderProcessStepTracker}
        />
      ) : (
        <Loader />
      )}

      {producedItemInOrderModal && (
        <ProducedItemsInOrdersModal
          isOpenModal={producedItemInOrderModal}
          hideProducedItemInOrderModal={hideProducedItemInOrderModal}
          processStepId={processStepId}
        />
      )}
    </>
  );
};

export { DynamicProcessStepsForTheOrder };
