import Title from "antd/es/typography/Title";
import React, { FC, useState } from "react";
import { FormInput, FormSelect, RadioButtons } from "shared/ui";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { AdaptedValues, UploadDocumentValues } from "shared/types";
import { MaterialStockBatchItemResponse, MaterialStockItem } from "shared/api";
import { UploadFile } from "antd";
import { qualityResultsTypeValues } from "shared/constants";
import { UploadDocument } from "./UploadDocument";
import { StockBatchesTable } from "./StockBatchesTable";
import { MaterialItemsTable } from "./MaterialItemsTable";

interface RequiredQualityFieldsProps {
  handleDocument: (file: File | null) => void;
  adaptedMaterials: AdaptedValues[];
  handleStockBatchesRows: (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => void;
  selectedStockBatches: MaterialStockBatchItemResponse[];
  selectedStockBatchesKeys: React.Key[];
  handleStockBatchesKeys: (keys: React.Key[]) => void;
  handleMaterialItemsRows: (materialItems: MaterialStockItem[]) => void;
  selectedMaterialItems: MaterialStockItem[];
  selectedMaterialItemsKeys: React.Key[];
  handleMaterialItemsKeys: (keys: React.Key[]) => void;
  handleDocumentList: (files: UploadFile[]) => void;
  documentList: UploadFile[];
  adaptedOrders: AdaptedValues[];
  isLoadingMaterials: boolean;
  isLoadingOrders: boolean;
}

const RequiredToUpdateQualityFields: FC<RequiredQualityFieldsProps> = ({
  handleDocument,
  adaptedMaterials,
  handleStockBatchesRows,
  selectedStockBatches,
  selectedStockBatchesKeys,
  handleStockBatchesKeys,
  handleMaterialItemsRows,
  selectedMaterialItems,
  selectedMaterialItemsKeys,
  handleMaterialItemsKeys,
  handleDocumentList,
  documentList,
  adaptedOrders,
  isLoadingMaterials,
  isLoadingOrders,
}) => {
  const { t } = useTranslation();

  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext<UploadDocumentValues>();

  const [filteringVariant, setFilteringVariant] = useState(true);

  const handleMaterialAsFilter = () => {
    setFilteringVariant(true);
    setFieldValue("order", "");
  };

  const handleOrderAsFilter = () => {
    setFilteringVariant(false);
    setFieldValue("material", "");
  };

  return (
    <>
      <Title level={5}>{t("quality.name")}</Title>

      <FormInput
        value={values?.name}
        fieldName="name"
        placeholder={t("quality.name")}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <Title level={5}>{t("quality.select-type")}</Title>

      <FormSelect
        value={values?.result_type}
        fieldName="result_type"
        placeholder={t("quality.select-type")}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        options={qualityResultsTypeValues}
        disabled
      />

      <UploadDocument
        handleDocument={handleDocument}
        handleDocumentList={handleDocumentList}
        documentList={documentList}
      />

      <RadioButtons
        isActive={filteringVariant}
        selectLeftOption={handleMaterialAsFilter}
        selectRightOption={handleOrderAsFilter}
        title={t("quality.filter-by")}
        leftVariantTitle={t("quality.material")}
        rightVariantTitle={t("quality.order")}
      />

      {filteringVariant ? (
        <>
          <Title level={5}>{t("quality.select-material")}</Title>

          <FormSelect
            value={values?.material || null}
            fieldName="material"
            placeholder={t("quality.select-material")}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            options={adaptedMaterials}
            showSearch
            isLoading={isLoadingMaterials}
          />
        </>
      ) : (
        <>
          <Title level={5}>{t("quality.select-order")}</Title>

          <FormSelect
            value={values?.order || null}
            fieldName="order"
            placeholder={t("quality.select-order")}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            options={adaptedOrders}
            showSearch
            isLoading={isLoadingOrders}
          />
        </>
      )}

      <StockBatchesTable
        handleStockBatchesRows={handleStockBatchesRows}
        isEditMode
        selectedStockBatches={selectedStockBatches}
        selectedStockBatchesKeys={selectedStockBatchesKeys}
        handleStockBatchesKeys={handleStockBatchesKeys}
      />

      {selectedStockBatchesKeys?.length ? (
        <MaterialItemsTable
          handleMaterialItemsRows={handleMaterialItemsRows}
          isEditMode
          selectedMaterialItems={selectedMaterialItems}
          selectedMaterialItemsKeys={selectedMaterialItemsKeys}
          handleMaterialItemsKeys={handleMaterialItemsKeys}
          selectedStockBatchesKeys={selectedStockBatchesKeys}
        />
      ) : null}
    </>
  );
};

export { RequiredToUpdateQualityFields };
