export const expandItemsString =
  "process_template,output_materials.material_category,input_materials.material_category,input_materials.suppliers.supplier,output_materials.suppliers.supplier,input_materials.material.suppliers.supplier,output_materials.material.suppliers.supplier";

export const DynamicFieldsEnum = {
  TEXT: "text",
  INTEGER: "integer",
  CHOICES: "choices",
  FLOAT: "float",
  EXPRESSION: "expression",
};

export const enum ConsumptionTypes {
  MA = "MA",
  FI = "FI",
  PR = "PR",
}

export const ConsumptionTypesValues = [
  { value: ConsumptionTypes.MA, label: "Manual" },
  { value: ConsumptionTypes.FI, label: "Fixed value" },
  { value: ConsumptionTypes.PR, label: "Proportional" },
];

export const processStepColumnsData = {
  name: {
    title: "processStepsColumns.name",
    dataIndex: "name",
    key: "name",
    width: "25%",
  },
  processTemplate: {
    title: "processStepsColumns.processTemplate",
    width: "15%",
  },
  inputMaterials: {
    title: "processStepsColumns.inputMaterial",
    width: "25%",
  },
  outputMaterials: {
    title: "processStepsColumns.outputMaterial",
    width: "25%",
  },
  actions: {
    title: "processStepsColumns.actions",
    key: "actions",
    width: "5%",
  },
};

export const processStepsInitialValues = {
  id: null,
  name: null,
  process_template: null,
  dynamic_process_step_fields: {},
  input_materials: [],
  output_materials: [],
};

export const initialValuesForOutputMaterialsRow = {
  material: null,
  default_number_of_items: null,
};
