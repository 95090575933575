import React, { FC } from "react";
import { AlertOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormInput, SoftHardValues } from "shared/ui";
import { ConfigDynamicTrackerFields } from "shared/api";
import classNames from "classnames";
import { getSoftAndHardValues } from "shared/helpers";
import {
  AddOrUpdateOrderValues,
  Card,
  AddProcessStepValues,
} from "shared/types";

interface DynamicNumberTypeInputProps {
  card: ConfigDynamicTrackerFields | Card;
  fieldIndex: number;
  fieldName?: string;
  value?: number | string;
}

const DynamicNumberInput: FC<DynamicNumberTypeInputProps> = ({
  card,
  fieldIndex,
  fieldName,
  value,
}) => {
  const { t } = useTranslation();
  const { handleBlur, handleChange } = useFormikContext<
    AddOrUpdateOrderValues | AddProcessStepValues
  >();

  const cardForSoftHardValues = card as ConfigDynamicTrackerFields;

  const {
    softMinimum,
    softMaximum,
    hardMinimum,
    hardMaximum,
    hideSoftHardValues,
    disableDynamicStyling,
    errorStyling,
    warningStyling,
  } = getSoftAndHardValues(cardForSoftHardValues, fieldIndex, value);

  return (
    <div className="relative">
      {hideSoftHardValues ? null : (
        <SoftHardValues
          softMaximum={softMaximum}
          softMinimum={softMinimum}
          hardMaximum={hardMaximum}
          hardMinimum={hardMinimum}
        />
      )}

      <FormInput
        value={value}
        fieldName={fieldName!}
        placeholder={t("orders.placeholder-input")}
        handleChange={handleChange}
        handleBlur={handleBlur}
        numberType
        className={classNames("", {
          "!bg-white": disableDynamicStyling,
          "bg-red-200": errorStyling,
          "bg-yellow-200": warningStyling,
        })}
      />

      {card.fields?.[fieldIndex]?.required === true && !value && (
        <AlertOutlined
          className={classNames("btn-remove absolute top-2 right-2", {
            "!top-12": !hideSoftHardValues,
          })}
        />
      )}
    </div>
  );
};

const DynamicNumberInputMemo = React.memo(DynamicNumberInput);

export { DynamicNumberInputMemo as DynamicNumberInput };
