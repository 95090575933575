import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllStandAloneConfigurationsQuery, Widget } from "shared/api";
import { FormSelect } from "shared/ui";

const StandAloneWidgetConfiguration = () => {
  const { t } = useTranslation();

  const { data: standAloneItems, isLoading: isLoadingStandAloneItems } =
    useGetAllStandAloneConfigurationsQuery();

  const adaptedStandAloneItems = useMemo(() => {
    return standAloneItems?.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }));
  }, [standAloneItems]);

  const { values, setFieldValue, handleBlur } = useFormikContext<Widget>();

  const influxDbServer = values?.influxdb_server;
  const influxDbFieldName = "influxdb_server";

  const query = values?.standalone_query;
  const queryFieldName = "standalone_query";

  return (
    <div>
      <Title level={5}>{t("widgets.influxDbServer")}</Title>
      <FormSelect
        value={influxDbServer || null}
        placeholder={t("widgets.influxDbServerPlaceholder")}
        fieldName={influxDbFieldName}
        options={adaptedStandAloneItems}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        isLoading={isLoadingStandAloneItems}
      />

      <Title level={5}>{t("widgets.query")}</Title>
      <TextArea
        value={query || ""}
        onChange={(e) => setFieldValue(queryFieldName, e.target.value)}
        rows={5}
        placeholder={t("widgets.queryPlaceholder")!}
      />
    </div>
  );
};

export { StandAloneWidgetConfiguration };
