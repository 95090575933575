import { Modal } from "antd";
import React, { FC } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";

interface Field {
  name: string;
  type: string;
  description: string;
  choices: number[] | number | string[];
  required: boolean;
  unit?: number;
  hard_minimum?: number;
  soft_minimum?: number;
  soft_maximum?: number;
  hard_maximum?: number;
}

interface Section {
  title: string;
  fields: Field[];
}

export interface ModalValuesProps {
  newCardName?: string | null;
  sections: Section[];
}

interface NewSectionNameModalProps {
  isOpenNewSectionModal: boolean;
  push: (obj: any) => void;
  hideNewSectionModal?: () => void;
}

const NewSectionNameModal: FC<NewSectionNameModalProps> = ({
  isOpenNewSectionModal,
  push,
  hideNewSectionModal,
}) => {
  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<ModalValuesProps>();

  const { t } = useTranslation();

  const initialFields = [
    {
      name: "",
      type: null,
      description: "",
      required: false,
    },
  ];

  const pushNewRow = () => {
    push({
      hideNewSectionModal,
      title: values.newCardName,
      fields: initialFields,
    });
  };

  const addNewSection = () => {
    hideNewSectionModal?.();
    pushNewRow();
    setFieldValue("newCardName", "");
  };

  const closeModal = () => {
    hideNewSectionModal?.();
    setFieldValue("newCardName", "");
  };

  return (
    <Modal
      title={t("material-category-management.add-new-section")}
      open={isOpenNewSectionModal}
      onOk={addNewSection}
      onCancel={closeModal}
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
        },
        disabled: values.newCardName?.length === 0,
      }}
    >
      <FormInput
        value={values.newCardName!}
        fieldName="newCardName"
        placeholder={t("material-category-management.new-section-placeholder")}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </Modal>
  );
};

export { NewSectionNameModal };
