import { useFormikContext } from "formik";
import { InitialInputMaterials } from "shared/types";
import { AddProductionRequest } from "pages/Orders/Orders/UI/ProcessStepProduction/ProcessStepProduction";
import { useEffect, useState } from "react";
import { adaptInitialInputValues } from "shared/adapters";
import { OrderStepTrackerResponseItem } from "shared/api";

interface UseProductionPageDataProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

export const useInputMaterialsProductionPageData = ({
  orderStepTrackerResponseItem,
}: UseProductionPageDataProps) => {
  const { setFieldValue } = useFormikContext<AddProductionRequest>();

  const inputMaterials =
    orderStepTrackerResponseItem?.process_step.input_materials;

  const [adaptedInitialInputMaterials, setAdaptedInitialInputMaterials] =
    useState<InitialInputMaterials[]>([]);

  useEffect(() => {
    const adaptedMaterials = adaptInitialInputValues(
      orderStepTrackerResponseItem
    );

    setAdaptedInitialInputMaterials(
      orderStepTrackerResponseItem ? adaptedMaterials : []
    );
  }, [orderStepTrackerResponseItem]);

  useEffect(() => {
    setFieldValue("input_materials", adaptedInitialInputMaterials);
  }, [adaptedInitialInputMaterials]);

  return {
    inputMaterials,
    adaptedInitialInputMaterials,
  };
};
