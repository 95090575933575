import React, { FC } from "react";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { useModal } from "shared/hooks";
import { UnitItemWithLabel } from "shared/api";
import { ModalValuesProps } from "shared/ui";

import { AddNewSection, DynamicCard } from "./ui";

interface DynamicFormProps {
  adaptedDimensionUnits: UnitItemWithLabel[];
}

const DynamicForm: FC<DynamicFormProps> = ({ adaptedDimensionUnits }) => {
  const [isOpenNewSectionModal, showNewSectionModal, hideNewSectionModal] =
    useModal();

  return (
    <div className="w-full h-full mt-2.5">
      <div className="w-full flex items-center justify-end" />
      <FieldArray name="sections">
        {(fieldArrayProps: FieldArrayRenderProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;

          if (!values?.sections?.length) {
            return (
              <AddNewSection
                showNewSectionModal={showNewSectionModal}
                isOpenNewSectionModal={isOpenNewSectionModal}
                hideNewSectionModal={hideNewSectionModal}
                push={push}
              />
            );
          }

          return values.sections
            ? values.sections.map((item: ModalValuesProps, index: number) => (
                <DynamicCard
                  key={item?.newCardName}
                  index={index}
                  showNewSectionModal={showNewSectionModal}
                  remove={remove}
                  adaptedDimensionUnits={adaptedDimensionUnits}
                  isOpenNewSectionModal={isOpenNewSectionModal}
                  hideNewSectionModal={hideNewSectionModal}
                  push={push}
                />
              ))
            : null;
        }}
      </FieldArray>
    </div>
  );
};

const DynamicFormMemo = React.memo(DynamicForm);

export { DynamicFormMemo as DynamicForm };
