import { useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Loader, PopConfirmButton } from "shared/ui";
import { Routes } from "shared/routers";
import { openHyperlinkInTab } from "shared/helpers";
import { useTranslation } from "react-i18next";
import { AddCompanyValues } from "shared/types";
import { productCardsColumnsData } from "shared/constants";
import { ProductCardItem } from "../../../shared/api/productCard/models";

interface ProductCardTableProps {
  productCards: ProductCardItem[] | undefined;
  setReorderingIds: React.Dispatch<React.SetStateAction<number[]>>;
  isLoadingProductCards?: boolean;
}

interface TableItem {
  id: number;
  name: string;
  process_steps: number[];
  key: number;
  company?: string;
}

const ProductCardTable: FC<ProductCardTableProps> = ({
  productCards,
  setReorderingIds,
  isLoadingProductCards,
}) => {
  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
  }

  const { t } = useTranslation();

  const { values } = useFormikContext<AddCompanyValues>();

  const dynamicProductCards = productCards?.filter((item: ProductCardItem) =>
    values.product_cards?.includes(item.id as never)
  );

  const productCardItems = !values?.product_cards?.length
    ? dynamicProductCards?.map((item) => item.id)
    : values.product_cards;

  const adaptedProductCardsForTheTable = productCardItems?.map(
    (item: any, index: number) => {
      const productCard = dynamicProductCards?.find(
        (cardItem) => cardItem.id === item
      );
      return {
        ...productCard,
        key: index + 1,
      };
    }
  );

  const [dataSource, setDataSource] = useState<TableItem[]>(
    (adaptedProductCardsForTheTable as TableItem[]) || []
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const removeProcessStep = (productCard: ProductCardItem) => {
    const filtered = dataSource.filter(
      (item) => item.name !== productCard.name
    );
    setDataSource(filtered);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const { key, name, actions } = productCardsColumnsData;

  const columns: ColumnsType<TableItem | ProductCardItem> = [
    {
      key: key.key,
    },
    {
      title: t(name.title),
      width: name.width,
      render: (item: ProductCardItem) => {
        const redirectLink =
          `${Routes.productCards.hyperlink}/${item?.id}`?.replace(":id/", "");

        return (
          <PopConfirmButton
            buttonContent={item.name}
            onClick={() => openHyperlinkInTab(redirectLink)}
            popupClassName="left-0"
          />
        );
      },
    },

    {
      title: t(actions.title),
      key: actions.key,
      width: actions.width,
      render: (record: ProductCardItem) => (
        <DeleteOutlined
          className="btn-remove"
          onClick={() => {
            removeProcessStep(record);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setDataSource((adaptedProductCardsForTheTable as TableItem[]) || []);
  }, [values]);

  useEffect(() => {
    const processCardIDS = dataSource.map((item) => item.id);
    setReorderingIds(processCardIDS || []);
  }, [dataSource]);

  if (isLoadingProductCards) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
          columns={columns}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  );
};

export { ProductCardTable };
