import { FilterFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import {
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  StockMaterialItem,
  SuppliersItem,
} from "shared/api";
import {
  MaterialItemsSorting,
  sortDirections,
  stockMaterialTableColumnsData,
  StockMaterialTableSorting,
} from "shared/constants";
import {
  addThousandsSeparator,
  renderStockBatchStatuses,
} from "shared/helpers";
import {
  DatePickerValue,
  DateRange,
  TableButton,
  TableSearchFilter,
} from "shared/ui";

const {
  material,
  batchCode,
  deliveryDate,
  status,
  actions,
  calcPercentageRemainingQuantity,
  calcTotalConsumedQuantity,
  calcTotalOriginalQuantity,
  calcTotalRemainingQuantity,
} = stockMaterialTableColumnsData;

interface StockManagementsColumnsProps {
  t: TFunction;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  batchCodeValue: string;
  clearBatchCode: () => void;
  handleBatchCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  supplierValue: string;
  clearSupplierValue: () => void;
  handleSupplierValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isActiveDeliveryDateFilter: boolean;
  handlePickerChangeGreater: (
    date: DatePickerValue,
    dateString: string
  ) => void;
  handlePickerChangeLesser: (date: DatePickerValue, dateString: string) => void;
  showEditModalAndSetItemToEdit: (item: MaterialStockBatchItemResponse) => void;
  handleMaterialItemsDetails: (
    materialStockBatchMaterialId: MaterialStockItem
  ) => void;
  showDeleteMaterialStockModal: (record: StockMaterialItem) => void;
}

const useStockManagementsColumns = ({
  t,
  changeSort,
  batchCodeValue,
  clearBatchCode,
  handleBatchCode,
  supplierValue,
  handleSupplierValue,
  clearSupplierValue,
  isActiveDeliveryDateFilter,
  handlePickerChangeGreater,
  handlePickerChangeLesser,
  showEditModalAndSetItemToEdit,
  handleMaterialItemsDetails,
  showDeleteMaterialStockModal,
}: StockManagementsColumnsProps): ColumnsType<MaterialStockBatchItemResponse> => [
  {
    title: t(batchCode.title),
    render: (item: MaterialStockBatchItemResponse) => (
      <TableButton
        type="title"
        title={item?.batch_code}
        onClick={() => showEditModalAndSetItemToEdit(item)}
      />
    ),
    sortDirections,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": batchCodeValue.length > 0,
        })}
      />
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          StockMaterialTableSorting.BATCH_CODE_ACS,
          StockMaterialTableSorting.BATCH_CODE_DESC
        );
      },
    }),
    sorter: true,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearBatchCode}
        confirm={confirm}
        value={batchCodeValue!}
        handleFilterChange={handleBatchCode!}
        visible={visible}
        placeholder={t("material-management.filter-by-batch-code")!}
        title={t("material-management.filter-by-batch-code")!}
      />
    ),
  },
  {
    title: t(material.title),
    key: material.key,
    render: (item: StockMaterialItem) => item?.material?.name,
  },
  {
    title: t("material-management.supplier"),
    render: (item: StockMaterialItem) => (item.supplier as SuppliersItem)?.name,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          StockMaterialTableSorting.SUPPLIER_ACS,
          StockMaterialTableSorting.SUPPLIER_DESC
        );
      },
    }),
    sorter: true,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-green-600  scale-125": supplierValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearSupplierValue}
        confirm={confirm}
        value={supplierValue}
        handleFilterChange={handleSupplierValue!}
        visible={visible}
        placeholder={t("material-management.filter-by-supplier")!}
        title={t("material-management.filter-by-supplier")!}
      />
    ),
  },
  {
    title: t(calcTotalOriginalQuantity.title),
    render: (item: StockMaterialItem) =>
      `${addThousandsSeparator(item.calc_total_original_quantity!)} - ${
        item.material?.material_category?.unit?.abbreviation
      }`,
  },
  {
    title: t(calcTotalConsumedQuantity.title),
    render: (item: StockMaterialItem) =>
      `${addThousandsSeparator(item.calc_total_consumed_quantity!)} - ${
        item.material?.material_category?.unit?.abbreviation
      }`,
  },
  {
    title: t(calcTotalRemainingQuantity.title),
    render: (item: StockMaterialItem) =>
      `${addThousandsSeparator(item.calc_total_remaining_quantity!)} - ${
        item.material?.material_category?.unit?.abbreviation
      }`,
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
          MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(calcPercentageRemainingQuantity.title),
    render: (item: StockMaterialItem) => {
      if (!item.calc_percentage_remaining_quantity) {
        return "0%";
      }
      return `${item.calc_percentage_remaining_quantity}%`;
    },
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
          MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(deliveryDate.title),
    dataIndex: deliveryDate.dataIndex,
    key: deliveryDate.key,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-purple-600 scale-125": isActiveDeliveryDateFilter,
        })}
      />
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          StockMaterialTableSorting.DELIVERY_DATE_ASC,
          StockMaterialTableSorting.DELIVERY_DATE_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,

    filterDropdown: () => {
      return (
        <DateRange
          title={t("stock-material-item.date-title")}
          leftPickerTitle={t("stock-material-item.date-title-lesser")}
          rightPickerTitle={t("stock-material-item.date-title-greater")}
          handlePickerChangeGreater={handlePickerChangeGreater}
          handlePickerChangeLesser={handlePickerChangeLesser}
        />
      );
    },
  },
  {
    title: t(status.title),
    render: (item: StockMaterialItem) => renderStockBatchStatuses(item),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          StockMaterialTableSorting.STATUS_ACS,
          StockMaterialTableSorting.STATUS_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
  },
  {
    title: t(actions.title),
    key: actions.key,
    render: (record: StockMaterialItem) => {
      return (
        <div className="w-full flex items-center justify-around">
          {record.is_quantity_in_tracker_table ? (
            <TableButton
              type="eye"
              onClick={() => {
                // @ts-ignore
                handleMaterialItemsDetails(record);
              }}
            />
          ) : (
            <TableButton type="eye-invisible" />
          )}

          <TableButton
            type="delete"
            onClick={() => showDeleteMaterialStockModal(record)}
          />
        </div>
      );
    },
  },
];

export { useStockManagementsColumns };
