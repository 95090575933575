import { Input, Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyItem, useUpdateCompanyMutation } from "shared/api";
import { handleRequestError } from "shared/helpers";
import { Colors } from "shared/themes";

const { TextArea } = Input;

interface UpdateCategorySupplierInformationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  company: CompanyItem;
  refetchMaterialCategoriesHandler?: () => void;
}

const UpdateCategorySupplierInformationDialog: FC<
  UpdateCategorySupplierInformationDialogProps
> = ({ onClose, isOpen, company, refetchMaterialCategoriesHandler }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { information } = company;

  const [updateCompany] = useUpdateCompanyMutation();

  const [newInformationValue, setNewInformationValue] = useState(information);

  const onChangeInformation = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNewInformationValue(e?.target?.value);

  const closeModalAfterInformationUpdate = () => {
    setNewInformationValue(information);
    refetchMaterialCategoriesHandler?.();
    onClose();
  };

  const closeModal = () => {
    setNewInformationValue(information);
    onClose();
  };

  const updateCompanyRequest = async () => {
    try {
      await updateCompany({
        id: company.id!,
        name: company.name,
        address: company.address,
        payment_term_net_days: company.payment_term_net_days!,
        inco_terms: company.inco_terms,
        is_supplier: company.is_supplier,
        is_buyer: company.is_buyer,
        config_dynamic_order_fields: company.config_dynamic_order_fields,
        product_cards: company.product_cards,
        evaluation_as_supplier: company.evaluation_as_supplier,
        categories_supplied: company.categories_supplied,
        parent_company: company.parent_company,
        child_companies: company.child_companies,
        vat_number: company.vat_number?.length ? company.vat_number : null,
        email: company.email?.length ? company?.email : null,
        phone: company.phone?.length ? company.phone : null,
        contact_name: company.contact_name?.length
          ? company.contact_name
          : null,
        information: newInformationValue,
      }).unwrap();

      closeModalAfterInformationUpdate();
    } catch (error) {
      api.error({
        message: t("users-page.error-title"),
        description: handleRequestError(error, t("users-page.error-title")),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={closeModal}
        onOk={updateCompanyRequest}
        okButtonProps={{
          disabled: !newInformationValue?.length,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        <Title level={5}>{t("companies.information")}</Title>
        <TextArea
          value={newInformationValue as string}
          onChange={onChangeInformation}
        />
      </Modal>
    </>
  );
};

export { UpdateCategorySupplierInformationDialog };
