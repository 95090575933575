import * as Yup from "yup";

export const AddOrUpdateMaterialConsumedSchema = Yup.object().shape({
  consumed_datetime: Yup.string()
    .nullable()
    .required("material-consumed-validation.consumed_datetime"),
  quantity: Yup.number()
    .nullable()
    .required("material-consumed-validation.quantity"),
  status: Yup.string()
    .nullable()
    .required("material-consumed-validation.status"),
  fieldVariant: Yup.string()
    .nullable()
    .required("material-consumed-validation.fieldVariant"),
});
