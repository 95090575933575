import { useEffect, RefObject } from "react";

type ElementType = HTMLElement | null;

type CallbackFunction = (event: MouseEvent) => void;

function useOutsideClick<T extends ElementType>(
  ref: RefObject<T>,
  callback: CallbackFunction
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export { useOutsideClick };
