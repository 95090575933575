import { useState } from "react";

const useGetOrderIdToUpdate = () => {
  const [orderId, setOrderId] = useState<number | null>(null);

  const selectOrderIdToUpdate = (orderId: number) => setOrderId(orderId);

  const clearOrderId = () => setOrderId(null);

  return {
    orderId,
    selectOrderIdToUpdate,
    clearOrderId,
  };
};

export { useGetOrderIdToUpdate };
