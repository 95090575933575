import { Badge, Input } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProductionOutputMaterial } from "shared/api";

interface UpdatedQuantityInputProps {
  notDyNamicValues: ProductionOutputMaterial;
  inputIndex: number;
  unitName?: string;
  memoizedSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const UpdatedQuantityInput: FC<UpdatedQuantityInputProps> = ({
  notDyNamicValues,
  inputIndex,
  unitName,
  memoizedSetFieldValue,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const quantityValueNumber = notDyNamicValues?.dynamicData?.[0]?.quantity;
  const quantityFieldName = `output_materials.${inputIndex}.dynamicData.${0}.quantity`;

  return (
    <div className="w-full">
      <Title level={5}>{unitName}</Title>

      <Badge.Ribbon color="red" text="" className="!right-[1px]">
        <Input
          value={quantityValueNumber}
          className={classNames("mt-1", {})}
          type="number"
          min={1}
          placeholder={t("quantityPlaceholder", { unitName })!}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            memoizedSetFieldValue(quantityFieldName, Number(e.target.value));
          }}
          onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
            e.currentTarget.blur()
          }
        />
      </Badge.Ribbon>
    </div>
  );
};

export { UpdatedQuantityInput };
