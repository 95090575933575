import React, { FC, useMemo, useCallback, ChangeEvent } from "react";
import { Badge, Input, Popover } from "antd";
import classNames from "classnames";
import { SoftHardValues } from "shared/ui";
import {
  ConfigDynamicTrackerFields,
  DynamicOutputMaterialData,
  MaterialCategoryFieldItem,
} from "shared/api";

interface ProdNumberInputProps {
  card: ConfigDynamicTrackerFields;
  cardIndex: number;
  dynamicTracker: DynamicOutputMaterialData[];
  field: MaterialCategoryFieldItem;
  inputIndex: number;
  memoizedSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  placeholder: string;
}

// eslint-disable-next-line react/display-name
const ProdNumberInput: FC<ProdNumberInputProps> = React.memo(
  ({
    card,
    cardIndex,
    dynamicTracker,
    field,
    inputIndex,
    memoizedSetFieldValue,
    placeholder,
  }) => {
    const value =
      dynamicTracker?.[0]?.dynamic_tracker_fields?.[card?.title]?.[field.name];

    const currentField = card?.fields?.[cardIndex];
    const fieldName = `output_materials.${inputIndex}.dynamicData.${0}.dynamic_tracker_fields.${
      card.title
    }.${field.name}`;

    const { softMaximum, softMinimum, hardMinimum, hardMaximum, isRequired } =
      useMemo(
        () => ({
          softMaximum: currentField?.soft_maximum,
          softMinimum: currentField?.soft_minimum,
          hardMinimum: currentField?.hard_minimum,
          hardMaximum: currentField?.hard_maximum,
          isRequired: currentField?.required,
        }),
        [currentField]
      );

    const {
      lessThenSoftMin,
      moreThenSoftMax,
      lessThenHardMin,
      moreThenHardMax,
      disableDynamicStyling,
    } = useMemo(
      () => ({
        lessThenSoftMin: softMinimum && Number(value) < softMinimum,
        moreThenSoftMax: softMaximum && Number(value) > softMaximum,
        lessThenHardMin: hardMinimum && Number(value) < hardMinimum,
        moreThenHardMax: hardMaximum && Number(value) > hardMaximum,
        disableDynamicStyling: value === "",
      }),
      [value, softMinimum, softMaximum, hardMinimum, hardMaximum]
    );

    const { errorStyling, warningStyling } = useMemo(
      () => ({
        errorStyling: lessThenHardMin || moreThenHardMax,
        warningStyling:
          (lessThenSoftMin && !lessThenHardMin) ||
          (moreThenSoftMax && !moreThenHardMax),
      }),
      [lessThenSoftMin, moreThenSoftMax, lessThenHardMin, moreThenHardMax]
    );

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        memoizedSetFieldValue(fieldName, Number(e.target.value));
      },
      [inputIndex, memoizedSetFieldValue, 0, card.title, field.name]
    );

    return (
      <div className="flex flex-col gap-y-2 ">
        <Badge.Ribbon text="" color={isRequired ? "red" : "transparent"}>
          <Popover
            open={
              !!softMaximum || !!softMinimum || !!hardMaximum || !!hardMinimum
            }
            content={
              <SoftHardValues
                softMaximum={softMaximum}
                softMinimum={softMinimum}
                hardMaximum={hardMaximum}
                hardMinimum={hardMinimum}
                size="small"
                className="!mb-0"
              />
            }
          >
            <Input
              type="number"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              placeholder={placeholder}
              className={classNames("mt-1 relative", {
                "!bg-white": disableDynamicStyling,
                "!bg-red-50": errorStyling,
                "!bg-yellow-200": warningStyling,
              })}
              onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                e.currentTarget.blur()
              }
            />
          </Popover>
        </Badge.Ribbon>
      </div>
    );
  }
);

export { ProdNumberInput };
