import { InitialInputMaterials, InitialOutputMaterials } from "shared/types";
import { AddProductionRequest } from "pages/Orders/Orders/UI/ProcessStepProduction/ProcessStepProduction";
import { availableQuantitiesChartColors } from "shared/constants";
import {
  ConfigDynamicTrackerFields,
  InputMaterialFormik,
  InputMaterials,
  MaterialDetailsItem,
  MaterialStockItem,
  MaterialsItem,
  OrderStepTrackerResponseItem,
  OutputMaterialsFormik,
  ProductionInputMaterial,
  ProductionOutputMaterial,
} from "shared/api";
import { ProcessStepItem } from "shared/api/productCard/models";
import * as Yup from "yup";
import dayjs from "dayjs";
import { AnyType, Unit } from "shared/helpers";

export const adaptMaterials = (allMaterials: MaterialsItem[]) => {
  const adaptedMaterials = allMaterials?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return adaptedMaterials || [];
};

export const adaptMaterialObjectToArray = (
  selectedMaterial: MaterialStockItem | undefined
) => {
  const arrayWithMaterial = selectedMaterial ? [{ ...selectedMaterial }] : [];
  return arrayWithMaterial;
};

export const adaptMaterialItems = (allMaterials: MaterialStockItem[]) => {
  const adaptedMaterialItems = allMaterials?.map((item) => ({
    label: item.material_stock_batch.material.name,
    value: item.id,
  }));
  return adaptedMaterialItems || [];
};

const generateDynamicData = (
  configData: ConfigDynamicTrackerFields[],
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined,
  defaultValues: null = null
) => {
  const dynamicData: AnyType = {};

  if (configData && Array.isArray(configData)) {
    configData.forEach(({ title, fields }) => {
      dynamicData[title] = {};
      fields.forEach(({ name }) => {
        const defaultValue =
          orderStepTrackerResponseItem?.calc_default_dynamic_tracker_fields?.[
            title as unknown as number
          ]?.[name] ?? defaultValues;

        dynamicData[title][name] = defaultValue;
      });
    });
  } else {
    // eslint-disable-next-line no-console
    console.error("configData is null or not an array");
  }

  return dynamicData;
};

export const adaptOutputMaterialValues = (
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined
) => {
  const initialOutputMaterialsValues =
    orderStepTrackerResponseItem?.process_step.output_materials?.map(
      (materialItem) => ({
        id: materialItem?.material?.id,

        unit: materialItem?.material?.material_category
          ?.unit as unknown as Unit,

        numberOfMaterialItems: materialItem?.default_number_of_items,

        dynamicData: [
          {
            dynamic_tracker_fields: generateDynamicData(
              materialItem?.material?.material_category
                ?.config_dynamic_tracker_fields,
              orderStepTrackerResponseItem,
              null
            ),
            status: "P", // Set initial status as P = Prima
          },
        ],
      })
    ) || [];
  return initialOutputMaterialsValues as InitialOutputMaterials[];
};

export const adaptInputMaterialsValues = (values: AddProductionRequest) => {
  const adaptedValues: InputMaterialFormik[] | undefined =
    values.input_materials?.flatMap((material) => {
      const formikInputValues = material.fields?.map((_, detailsIndex) => ({
        id: material?.id,
        variant:
          material.selectValue?.[detailsIndex] === undefined
            ? null
            : material.selectValue?.[detailsIndex]!,
        quantity: material.fields?.[detailsIndex]?.quantity as number,
      }));

      return formikInputValues || [];
    });

  return adaptedValues || [];
};

export const adaptOutputMaterialsValues = (
  values: AddProductionRequest,
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const formikOutputValues = values.output_materials?.map((material, index) => {
    const materialId =
      orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
        ?.material?.id;

    const itemsToProduce = material?.dynamicData?.map((item) => ({
      quantity: Number(item?.quantity),
      dynamic_tracker_fields: item.dynamic_tracker_fields,
      status: item.status,
    }));

    return {
      material_id: materialId,
      items_to_produce: itemsToProduce,
    };
  });

  return formikOutputValues as OutputMaterialsFormik[];
};

export const adaptInitialInputValues = (
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const initialInputMaterialsValues =
    orderStepTrackerResponseItem?.process_step.input_materials.map(
      (materialItem) => ({
        id: materialItem?.material?.id,

        consumptionType: materialItem?.consumption_type,

        consumptionFixedValue: materialItem?.consumption_fixed_value,

        consumptionProportionalValue:
          materialItem?.consumption_proportional_value,

        consumptionProportionalMaterial:
          materialItem.consumption_proportional_material,

        calcHideField: materialItem?.calc_hide_field,

        fields: [
          {
            variant: null,

            quantity: null,

            selectValue:
              materialItem?.material?.material_category
                ?.default_material_consume_variant || null,

            consumptionType: materialItem?.consumption_type,
          },
        ],
      })
    ) || [];

  return initialInputMaterialsValues;
};

export const adaptInitialValuesAfterRequest = (
  inputMaterial: ProductionInputMaterial[],
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const initialInputMaterialsValues =
    orderStepTrackerResponseItem?.process_step.input_materials.map(
      (materialItem, index) => {
        return {
          id: materialItem?.material?.id,
          materialName: materialItem?.material?.name,
          selectValue: inputMaterial?.[index]?.selectValue || null,
          consumptionType: materialItem?.consumption_type,
          consumptionFixedValue: materialItem?.consumption_fixed_value,
          consumptionProportionalValue:
            materialItem?.consumption_proportional_value,
          consumptionProportionalMaterial:
            materialItem.consumption_proportional_material,
          fields: [
            {
              variant: null,
              quantity: null,
            },
          ],
        };
      }
    ) || [];

  return initialInputMaterialsValues;
};

export const getInitialInputMaterialIds = (
  adaptedInitialInputMaterials: InitialInputMaterials[]
) => {
  const initialInputMaterialIds = adaptedInitialInputMaterials
    .map((material) => material.id)
    .join(",");

  return initialInputMaterialIds;
};

export const adaptOutputMaterials = (
  outputMaterials: ProductionOutputMaterial[]
) => {
  const adaptedOutputMaterial = outputMaterials?.map((item) => ({
    value: item?.material?.id,
    label: item?.material?.name,
  }));

  return adaptedOutputMaterial;
};

export const adaptInputMaterials = (inputMaterials: InputMaterials[]) => {
  const inputMaterialValidation = inputMaterials?.map((inputMaterial) => {
    return {
      [inputMaterial.selectValue]: Yup.object({
        materialName: Yup.string().required("Material name is required"),
        fields: Yup.array()
          .of(
            Yup.object().shape({
              variant: Yup.string().required("Variant is required"),
              quantity: Yup.number().required("Quantity is required"),
            })
          )
          .required("Fields are required"),
      }),
    };
  });

  return inputMaterialValidation;
};

export const adaptedInputMaterialForProcessStep = (inputMaterial: number[]) => {
  const adaptedInputMaterials = inputMaterial?.map((item) => ({
    material: item,
  }));

  return adaptedInputMaterials ?? [];
};

export const adaptedOutputMaterialForProcessStep = (
  outputMaterials: number[]
) => {
  const adaptedOutputMaterials = outputMaterials?.map((item) => ({
    material: item,
    default_number_of_items: 1,
  }));

  return adaptedOutputMaterials ?? [];
};

export const adaptProcessStepOutputMaterialToEdit = (
  record: ProcessStepItem
) => {
  const adaptedOutputMaterials = record?.output_materials?.map(
    (outputMaterial) => ({
      material: (outputMaterial?.material as MaterialsItem)?.id,
      default_number_of_items: outputMaterial?.default_number_of_items,
    })
  );

  return adaptedOutputMaterials ?? [];
};

export const adaptProcessStepInputMaterialToEdit = (
  record: ProcessStepItem
) => {
  const adaptedInputMaterials = record?.input_materials?.map(
    (inputMaterial) => ({
      ...inputMaterial,
      material: (inputMaterial?.material as MaterialsItem)?.id,
    })
  );

  return adaptedInputMaterials ?? [];
};

export const adaptMaterialDetailChardData = (
  materialDetailsForTheChart: MaterialDetailsItem[] | undefined,
  materialItem: MaterialsItem | null
) => {
  const graphData = materialDetailsForTheChart?.map((item) => ({
    ...item,
    targetQuantity: materialItem?.target_quantity_in_stock,
  }));

  const chartLabels = graphData?.map((item) =>
    dayjs.utc(item?.datetime).format("YYYY-MM-DD")
  );

  const chartData = {
    labels: chartLabels || [],
    datasets: [
      {
        fill: false,
        label: "Target Quantity",
        stack: "Available",
        data: graphData?.map((target) => target.targetQuantity!),
        borderColor: availableQuantitiesChartColors.target.borderColor,
        backgroundColor: availableQuantitiesChartColors.target.backgroundColor,
        zIndex: 1000,
      },
      {
        fill: true,
        stack: "stack1",
        label: "Available",
        data: graphData?.map((available) => available?.quantity_available),
        borderColor: availableQuantitiesChartColors.available.borderColor,
        backgroundColor:
          availableQuantitiesChartColors.available.backgroundColor,
      },
      {
        fill: true,
        stack: "stack1",
        label: "Reserved",
        data: graphData?.map((reserved) => reserved?.quantity_reserved),
        borderColor: availableQuantitiesChartColors.reserved.borderColor,
        backgroundColor:
          availableQuantitiesChartColors.reserved.backgroundColor,
      },
    ],
  };

  return chartData;
};
