import { Widget, WidgetTableData, WidgetTypeEnum } from "shared/api";

export const getGaugeFields = (
  widgetsData: WidgetTableData[],
  widget: Widget
) => {
  const type = widget?.type;
  const isSimpleGauge = type === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL;

  const value = widgetsData?.[0]
    ?.materialGraphCalcPercentageAvailable as number;

  const showConfigurationsAlert =
    widget?.type === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL && !value;

  const redLimit =
    Number(widget?.configuration?.[1]?.configuration_value) ?? undefined;

  const yellowLimit =
    Number(widget?.configuration?.[2]?.configuration_value) ?? undefined;

  return {
    isSimpleGauge,
    value,
    showConfigurationsAlert,
    redLimit,
    yellowLimit,
  };
};
