import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { Sidebar } from "shared/ui";

interface OrderReportsProps {}

const OrderReports: FC<OrderReportsProps> = () => {
  // TODO Temporary without i18n. Because it's just template for now

  return (
    <div className="w-full h-full flex ">
      <Sidebar isOpenSidebar />

      <div className="w-full">
        <div className="p-3.75 ">
          <Title className="capitalize">Order Reports</Title>
          <div className="flex justify-between items-center">
            <Button type="primary" className="btn-primary--dark">
              Add Order Report
              <PlusOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrderReports };
