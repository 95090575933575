/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { Navigate } from "react-router-dom";
import { useTypedSelector } from "../stores";
import { userSelectors } from "../stores/user/selectors";
import { Routes } from "./Routes";

interface ProtectedRouteProps {
  children?: React.ReactNode | null;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children = null,
}) => {
  const token = useTypedSelector(userSelectors.token);

  if (!token) {
    return <Navigate to={Routes.signIn.url} replace />;
  }

  return <>{children}</>;
};
