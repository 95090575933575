import React, { FC } from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { MaterialsItem } from "../../../../../shared/api/materials/models";

interface ProgressBarProps {
  item: MaterialsItem;
}

interface Unit {
  id: number;
  dimension: number;
  name: string;
  abbreviation: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ item }) => {
  const { t } = useTranslation();

  const {
    target_quantity_in_stock,
    calc_total_available_quantity,
    calc_total_reserved_quantity,
    material_category,
    calc_total_remaining_quantity,
  } = item;

  const unit = material_category?.unit as Unit;
  const abbreviation = unit?.abbreviation;

  const targetQuantityInStock = target_quantity_in_stock ?? 0;
  const remainingQuantity = calc_total_remaining_quantity ?? 0;

  const totalQuantity = targetQuantityInStock;

  const missingQuantityPercentage = Math.min(
    100,
    Math.max(0, ((totalQuantity - remainingQuantity) / totalQuantity) * 100)
  );

  const availableQuantityPercentage =
    ((calc_total_available_quantity ?? 0) / totalQuantity) * 100;

  const reservedQuantityPercentage =
    ((calc_total_reserved_quantity ?? 0) / totalQuantity) * 100;

  const missedItemsCount =
    targetQuantityInStock -
    (calc_total_available_quantity ?? 0) -
    (calc_total_reserved_quantity ?? 0);

  const totalInStock =
    (calc_total_available_quantity ?? 0) +
      (calc_total_reserved_quantity ?? 0) ?? 0;

  const redLineValue =
    totalInStock > targetQuantityInStock
      ? 100 * (targetQuantityInStock / totalInStock)
      : 0;

  return (
    <div
      className={classNames(
        "min-w-[180px] w-auto h-8 border border-primary flex !relative",
        {
          "bg-red-200": missingQuantityPercentage === 100,
          "bg-yellow-200": reservedQuantityPercentage === 100,
          "bg-green-200": availableQuantityPercentage === 100,
        }
      )}
    >
      <Popover
        content={`${t(
          "progress-bar.available"
        )}  ${calc_total_available_quantity} ${abbreviation}`}
      >
        <div
          style={{ width: `${availableQuantityPercentage}%` }}
          className={classNames(
            "bg-green-200 flex items-center justify-center text-[10px] overflow-hidden ",
            {
              hidden: availableQuantityPercentage === 0,
            }
          )}
        >
          {availableQuantityPercentage.toFixed(2)}%
        </div>
      </Popover>

      <Popover
        content={`${t(
          "progress-bar.reserved"
        )}  ${calc_total_reserved_quantity} ${abbreviation}`}
      >
        <div
          style={{ width: `${reservedQuantityPercentage}%` }}
          className={classNames(
            "bg-yellow-200 flex items-center justify-center text-[10px] overflow-hidden",
            {
              hidden: reservedQuantityPercentage === 0,
            }
          )}
        >
          {reservedQuantityPercentage.toFixed(2)}%
        </div>
      </Popover>

      <Popover
        content={`${t(
          "progress-bar.missed"
        )}  ${missedItemsCount} ${abbreviation}`}
      >
        <div
          style={{ width: `${missingQuantityPercentage}%` }}
          className={classNames(
            "bg-red-200 flex items-center justify-center text-[10px] overflow-hidden",
            {
              hidden: missingQuantityPercentage === 0,
            }
          )}
        >
          {missingQuantityPercentage.toFixed(2)}%
        </div>
      </Popover>

      {availableQuantityPercentage > 100 && (
        <Popover
          content={`${t(
            "progress-bar.target-in-stock"
          )} - ${targetQuantityInStock} ${abbreviation}`}
        >
          <div
            style={{
              right: `${redLineValue.toFixed(2)}%`,
            }}
            className={`w-[4px] h-full bg-red-200 absolute `}
          />
        </Popover>
      )}
    </div>
  );
};

export { ProgressBar };
