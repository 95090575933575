import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";

interface ErrorMessageProps {
  errorMessage: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ errorMessage }) => {
  const { t } = useTranslation();
  return (
    <div style={{ color: `${Colors.red[100]}`, marginTop: "1px" }}>
      {t(`${errorMessage}`)}
    </div>
  );
};

export { ErrorMessage };
