import React, { FC } from "react";
import { CompanyItem, MaterialCategoriesItem } from "shared/api";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";

interface CategoriesSuppliedByCompanyProps {
  item: CompanyItem;
}

const CategoriesSuppliedByCompany: FC<CategoriesSuppliedByCompanyProps> = ({
  item,
}) => {
  return (
    <div className="flex flex-wrap gap-x-2">
      {item?.categories_supplied?.map((category) => {
        const hyperlink = `${Routes.materialCategoryManagement.hyperlink}/${
          (category?.material_category as MaterialCategoriesItem)?.id
        }`.replace(":id/", "");

        return (
          <PopConfirmButton
            key={category?.id}
            buttonContent={
              (category?.material_category as MaterialCategoriesItem)?.name
            }
            onClick={() => openHyperlinkInTab(hyperlink)}
          />
        );
      })}
    </div>
  );
};

export { CategoriesSuppliedByCompany };
