import { AdaptedValues } from "shared/types";
import { PackagingResponse } from "shared/api";

export const getMaterialsForThePackage = (packages: PackagingResponse) => {
  let adaptedValues = [] as AdaptedValues[];

  packages.results.forEach((item) => {
    const key = item.material.id;
    const isDuplicate = adaptedValues.some((value) => value.value === key);

    if (!isDuplicate) {
      adaptedValues = adaptedValues.concat({
        value: key,
        label: item.material.name,
      });
    }
  });

  return adaptedValues;
};
