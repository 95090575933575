import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ConfigStepFields } from "shared/api";
import Table, { ColumnsType } from "antd/es/table";
import { ConfigCardStep } from "shared/types";
import { configCardColumns } from "shared/constants";

interface ConfigCardStepFieldsProps {
  field?: ConfigStepFields;
  card?: ConfigCardStep;
}

const ConfigCardStepFields: FC<ConfigCardStepFieldsProps> = ({
  card,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  field,
}) => {
  const { t } = useTranslation();

  const {
    name,
    value,
    // Don't remove. Commented label for columns. Maybe we will be able to uncomment them later.
    // type,
    // choices,
    // required,
    // description,
  } = configCardColumns;

  // Don't remove. Maybe we will be able to uncomment this request later.

  // const { data: dimensionUnits } = useGetDimensionUnitsQuery({
  //   expand: expandDimensionUnits,
  // });

  // Don't remove. Maybe we will be able to uncomment this function later.

  // const renderChoices = (choices: number | string[] | number[]) => {
  //   if (Array.isArray(choices) && choices?.length) {
  //     return choices?.join(", ");
  //   }

  //   if (typeof choices === "number") {
  //     const findItem = dimensionUnits?.find((item) => item.id === choices);
  //     return findItem?.name;
  //   }

  //   return "Empty";
  // };

  const columns: ColumnsType<ConfigStepFields> = [
    {
      title: t(name.title),
      render: (record: ConfigStepFields) => <span>{record?.name}</span>,
      width: name.width,
    },
    // Don't remove. Some table columns are commented. Maybe we will be able to uncomment them later.

    // {
    //   title: t(type.title),
    //   render: (record: ConfigStepFields) => <span>{record?.type}</span>,
    //   width: type.width,
    // },
    // {
    //   title: t(choices.title),
    //   render: (record: ConfigStepFields) => (
    //     <span>{renderChoices(record?.choices)}</span>
    //   ),
    //   width: choices.width,
    // },
    // {
    //   title: t(required.title),
    //   render: (record: ConfigStepFields) => (
    //     <span>{record?.required.toString()}</span>
    //   ),
    //   width: required.width,
    // },
    // {
    //   title: t(description.title),
    //   render: (record: ConfigStepFields) => <span>{record?.description}</span>,
    //   width: description.width,
    // },
    {
      title: t(value.title),
      render: (record: ConfigStepFields) => (
        <span>{record?.value ?? t("configCardColumns.noValue")}</span>
      ),
      width: value.width,
    },
  ];

  return (
    <Table columns={columns} dataSource={card?.fields} pagination={false} />
  );
};

export { ConfigCardStepFields };
