import { useCallback, useState } from "react";
import { MaterialStockItem } from "shared/api";
import { useModal } from "./useModal";

export const useOrderProductionSet = () => {
  const [
    isVisibleOrderProductionSetModal,
    showOrderProductionSetModal,
    hideOrderProductionSetModal,
  ] = useModal();

  const [orderProductionSetId, setOrderProductionSetId] = useState<
    number | null
  >(null);

  const openModalAndSelectOrder = useCallback(
    (item: MaterialStockItem) => {
      const orderProductionSetId = item?.order_production_set?.id;

      setOrderProductionSetId(orderProductionSetId || null);
      showOrderProductionSetModal();
    },
    [setOrderProductionSetId, showOrderProductionSetModal]
  );

  const closeModalAndClearOrder = useCallback(() => {
    setOrderProductionSetId(null);
    hideOrderProductionSetModal();
  }, [hideOrderProductionSetModal, setOrderProductionSetId]);

  return {
    isVisibleOrderProductionSetModal,
    openModalAndSelectOrder,
    closeModalAndClearOrder,
    orderProductionSetId,
  };
};
