import * as Yup from "yup";

export const UpdateOrderProdSetValidation = Yup.object().shape({
  materials_consumed: Yup.array()
    .of(
      Yup.object().shape({
        quantity: Yup.number()
          .nullable()
          .min(1, "orders.min-qnt-validation")
          .required("orders.qnt-required"),
      })
    )
    .required("orders.required"),
});
