import React, { FC, useState } from "react";
import { Popconfirm, Button } from "antd";
import classNames from "classnames";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { useFormikContext } from "formik";
import { DynamicFormsValuesType } from "shared/features/DynamicFormsComponents/constants";
import { dynamicSpecificationTypesEnum } from "shared/constants";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DynamicFormPopConfirmProps {
  index: number;
  fieldIndex: number;
}

const DynamicFormPopConfirm: FC<DynamicFormPopConfirmProps> = ({
  fieldIndex,
  index,
}) => {
  const { t } = useTranslation();

  const { handleBlur, handleChange, values, setFieldValue } =
    useFormikContext<DynamicFormsValuesType>();

  const dynamicFieldsValue = values.sections[index].fields?.[fieldIndex];

  const disablePopup =
    dynamicFieldsValue.type === dynamicSpecificationTypesEnum.TEXT ||
    dynamicFieldsValue.type === dynamicSpecificationTypesEnum.CHOICES;

  const hardMinValue = dynamicFieldsValue?.hard_minimum;
  const softMinValue = dynamicFieldsValue?.soft_minimum;
  const softMaxValue = dynamicFieldsValue?.soft_maximum;
  const hardMaxValue = dynamicFieldsValue?.hard_maximum;

  const hardMinimumFieldName = `sections[${index}].fields[${fieldIndex}].hard_minimum`;
  const softMinimumFieldName = `sections[${index}].fields[${fieldIndex}].soft_minimum`;
  const softMaximumFieldName = `sections[${index}].fields[${fieldIndex}].soft_maximum`;
  const hardMaximumFieldName = `sections[${index}].fields[${fieldIndex}].hard_maximum`;

  const [visible, setVisible] = useState(false);

  const openPopup = () => setVisible(true);
  const closePopup = () => setVisible(false);

  const handleCancel = () => {
    setFieldValue(hardMinimumFieldName, null);
    setFieldValue(softMinimumFieldName, null);
    setFieldValue(softMaximumFieldName, null);
    setFieldValue(hardMaximumFieldName, null);
    setVisible(false);
  };

  if (disablePopup) {
    return (
      <div className="w-[6%]">
        <Title level={5} className={classNames("-mt-2", {})}>
          {t("material-category-management.extra")}
        </Title>

        <Button disabled className="h-10 w-full">
          <CloseOutlined />
        </Button>
      </div>
    );
  }

  return (
    <div className="w-[6%]">
      <Title level={5} className={classNames("-mt-2", {})}>
        {t("material-category-management.limit")}
      </Title>
      <Popconfirm
        icon={null}
        title={
          <div className="flex flex-col gap-y-1">
            <Title level={5} className="text-[10px] mb-1">
              {t("material-category-management.hard-minimum")}
            </Title>
            <FormInput
              value={hardMinValue!}
              fieldName={hardMinimumFieldName}
              placeholder="Hard minimum value"
              handleBlur={handleBlur}
              handleChange={handleChange}
              numberType
            />

            <Title level={5} className="text-[10px] !mb-1">
              {t("material-category-management.soft-minimum")}
            </Title>
            <FormInput
              value={softMinValue!}
              fieldName={softMinimumFieldName}
              placeholder="Soft minimum value"
              handleBlur={handleBlur}
              handleChange={handleChange}
              numberType
            />

            <Title level={5} className="text-[10px] !mb-1">
              {t("material-category-management.soft-maximum")}
            </Title>
            <FormInput
              value={softMaxValue!}
              fieldName={softMaximumFieldName}
              placeholder="Soft maximum value"
              handleBlur={handleBlur}
              handleChange={handleChange}
              numberType
            />

            <Title level={5} className="text-[10px] !mb-1">
              {t("material-category-management.hard-maximum")}
            </Title>
            <FormInput
              value={hardMaxValue!}
              fieldName={hardMaximumFieldName}
              placeholder="Hard maximum value"
              handleBlur={handleBlur}
              handleChange={handleChange}
              numberType
            />
          </div>
        }
        open={visible}
        onConfirm={closePopup}
        onCancel={handleCancel}
        okText="Ok"
        cancelText="Clear"
        className="h-10"
        okButtonProps={{ className: "w-1/3" }}
        cancelButtonProps={{ className: "w-1/3" }}
      >
        <Button onClick={openPopup} className="w-full">
          {t("material-category-management.open")}
        </Button>
      </Popconfirm>
    </div>
  );
};

export { DynamicFormPopConfirm };
