import { Menu } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import React, { FC } from "react";

interface SidebarMenuProps {
  handleActiveLink: (e: { key: React.Key }) => void;
  pathname: string;
  translatedSidebarItems: ItemType[];
  selectMenuKeys: () => string[];
}

const SidebarMenu: FC<SidebarMenuProps> = ({
  handleActiveLink,
  pathname,
  translatedSidebarItems,
  selectMenuKeys,
}) => {
  return (
    <div className="h-[70%] overflow-auto sidebar">
      <Menu
        mode="inline"
        onClick={handleActiveLink}
        selectedKeys={[pathname]}
        className="text-[12px] bg-blue-primary"
        items={translatedSidebarItems as ItemType[]}
        defaultSelectedKeys={selectMenuKeys()}
        defaultOpenKeys={selectMenuKeys()}
      />
    </div>
  );
};

export { SidebarMenu };
