import { FormikErrors } from "formik";
import { ExecuteReportValues } from "shared/types";
import {
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  PackageSituationEnum,
  PackagingItem,
  QualityResultItem,
  ReportItem,
} from "shared/api";

export const adaptExecutePageReportsData = (reports: ReportItem[]) => {
  return reports?.map((report) => {
    return {
      label: report?.name,
      value: report?.id,
    };
  });
};

export const adaptMaterialStockBatchesData = (
  stockBatches: MaterialStockBatchItemResponse[]
) => {
  return stockBatches?.map((stockBatch) => ({
    value: stockBatch?.material?.id,
    label: stockBatch?.material?.name,
  }));
};

export const adaptPackagesData = (packages: PackagingItem[]) => {
  return packages?.map((packageItem) => ({
    value: packageItem?.id,
    label: packageItem?.name,
  }));
};

export const adaptMaterialItemsData = (materialItems: MaterialStockItem[]) => {
  return materialItems?.map((materialItem) => ({
    label: materialItem?.serial_number,
    value: materialItem?.id,
  }));
};

export const adaptQualityResults = (qualityResults: QualityResultItem[]) => {
  return qualityResults?.map((qualityResult) => ({
    label: qualityResult?.name,
    value: qualityResult?.id,
  }));
};

function naturalSort(a: string, b: string): number {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
}

export const adaptPackagesForExecuteReport = (packages: PackagingItem[]) => {
  return (packages as unknown as PackagingItem[])
    ?.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    ?.sort((a, b) => naturalSort(a.label, b.label));
};

export const adaptPackagesForMultiplePackagingReport = (
  packages: PackagingItem[]
) => {
  return (packages as unknown as PackagingItem[])
    ?.map((item) => ({
      label:
        item?.situation === PackageSituationEnum?.SE
          ? `${`${item?.name} (Sent)`}`
          : item?.name,
      value: item.id,
    }))
    ?.sort((a, b) => naturalSort(a.label, b.label));
};

export const clearFormikValueOnReportChange = (
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ExecuteReportValues>>,
  handleOrderChange: (value: number | null) => void
) => {
  setFieldValue("material_item_id", null);
  setFieldValue("packaging_id", null);
  setFieldValue("order_id", null);
  setFieldValue("packaging_id", null);
  setFieldValue("material_id", null);
  setFieldValue("order_wanted_delivery_datetime_start", null);
  setFieldValue("order_wanted_delivery_datetime_end", null);
  setFieldValue("quality_result_id", null);
  setFieldValue("packaging_ids", []);

  // TODO: double check this clearing
  // In <DateBlock> we preselect default values
  // So, this clearing is not needed (maybe)
  // setFieldValue("order_production_datetime_start", null);
  // setFieldValue("order_production_datetime_end", null);

  handleOrderChange(null);
};

export const clearFormikValuesOnOrderChange = (
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ExecuteReportValues>>,
  onMaterialChange: (value: number | null) => void
) => {
  setFieldValue("material_item_id", null);
  setFieldValue("packaging_id", null);
  setFieldValue("packaging_id", null);
  setFieldValue("material_id", null);
  setFieldValue("order_wanted_delivery_datetime_start", null);
  setFieldValue("order_wanted_delivery_datetime_end", null);
  setFieldValue("order_production_datetime_start", null);
  setFieldValue("order_production_datetime_end", null);
  setFieldValue("quality_result_id", null);
  setFieldValue("packaging_ids", []);
  onMaterialChange(null);
};
