import { Alert, Modal, Upload, UploadFile, notification } from "antd";
import React, { FC, useState } from "react";
import Title from "antd/es/typography/Title";
import { useFormik } from "formik";
import { FormSelect, GlobalDatePicker, DatePickerValue } from "shared/ui";
import { Colors } from "shared/themes";
import {
  handleRequestError,
  filterSuppliersBasedOnIdList,
  generateExcelFileFormData,
  noop,
  generateExcelFileProps,
  disableStockManagementUploadExcelButton,
} from "shared/helpers";
import {
  useUploadMaterialItemsExcelFileMutation,
  useGetAllCompaniesQuery,
  MaterialsItem,
} from "shared/api";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { ModalSizeValue } from "shared/constants";
import { UploadStockManagementExcelSchema } from "shared/schemas";

interface UploadExcelProps {
  isOpen: boolean;
  onClose: () => void;
  stockMaterialToManage?: MaterialsItem | null;
}

const { Dragger } = Upload;

export interface UploadExcelFormValues {
  material: number | string;
  batch_code: string;
  supplier?: number | string;
  delivery_date?: string;
}

const UploadExcel: FC<UploadExcelProps> = ({
  isOpen,
  onClose,
  stockMaterialToManage,
}) => {
  const uploadExcelFormik = useFormik<UploadExcelFormValues>({
    initialValues: {
      material: "",
      batch_code: "",
      supplier: "",
      delivery_date: "",
    },
    validationSchema: UploadStockManagementExcelSchema,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: noop,
  });

  const { t } = useTranslation();

  const { setFieldValue, handleBlur, values, isValid, resetForm } =
    uploadExcelFormik;

  const [api, contextHolder] = notification.useNotification();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const [document, setDocument] = useState<File | null>(null);
  const handleDocument = (file: File | null) => setDocument(file);

  const closeModal = () => {
    onClose();
    setDocumentList([]);
    resetForm();
  };

  const { data: companies, isLoading } = useGetAllCompaniesQuery({});

  const suppliersForTheCurrentMaterial = filterSuppliersBasedOnIdList(
    companies || [],
    stockMaterialToManage?.suppliers
  );

  const handleDateTimeChange = (_: DatePickerValue, dateString: string) => {
    setFieldValue("delivery_date", dateString);
  };

  const fileLoaderProps = generateExcelFileProps(
    handleDocument,
    handleDocumentList,
    documentList
  );

  const [uploadMaterialItemsExcelFile] =
    useUploadMaterialItemsExcelFileMutation();

  const uploadExcelFile = async () => {
    const excelFormData = generateExcelFileFormData(
      document,
      stockMaterialToManage?.id as number,
      values
    );

    try {
      await uploadMaterialItemsExcelFile({
        formData: excelFormData,
      }).unwrap();

      api.success({
        message: t("material-management.success"),
        description: t("material-management.excel-success-msg"),
      });
    } catch (error) {
      api.error({
        message: t("material-management.error"),
        description: handleRequestError(error, t("material-management.error")),
      });
    } finally {
      closeModal();
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("material-management.upload-excel")}
        open={isOpen}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: disableStockManagementUploadExcelButton(
            documentList,
            isValid
          ),
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={uploadExcelFile}
      >
        <Dragger {...fileLoaderProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">{t("quality.uploadMessage")}</p>
        </Dragger>

        <Title
          level={5}
          className={classNames("mb-1.25", {
            "mt-2": !!documentList.length,
          })}
        >
          {t("material-management.suppliers")}
        </Title>

        {suppliersForTheCurrentMaterial?.length ? (
          <FormSelect
            value={values.supplier || null}
            placeholder={t("material-management.supplier-for-material")}
            fieldName="supplier"
            options={suppliersForTheCurrentMaterial}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            showSearch
            isLoading={isLoading}
          />
        ) : (
          <Alert
            message={t("material-management.no-suppliers")}
            type="warning"
            showIcon
            className="my-1.25 h-10"
          />
        )}

        <Title level={5} className="mb-1.25">
          {t("material-management.delivery-date")}
        </Title>

        <GlobalDatePicker handlePickerChange={handleDateTimeChange} />
      </Modal>
    </>
  );
};

const UploadExcelMemo = React.memo(UploadExcel);

export { UploadExcelMemo as UploadExcel };
