import {
  Dashboard,
  UpdateWidgets,
  Widget,
  WidgetFilter,
  WidgetTypeEnum,
} from "shared/api";
import { materialConsumptionColumnsValues } from "./materialConsumption";
import { orderStatusColumnsValues } from "./orderStatus";
import { machinesXColumnsValues } from "./machinesX";
import { adaptMaterialTargetColumnsValues } from "./materialTargetAlert";
import { productionTrackerColumnsValues } from "./productionDataTracker";
import { initialWidgetColumns } from "./widget.initialColumnsValues";
import { widgetSorting } from "./widget.sortings";
import { initialWidgetFilters } from "./widget.initialFilters";
import { initialWidgetConfigurations } from "./widget.initialConfiguration";
import { articleFlowColumnsValues } from "./articleFlow";

export const enum WidgetStatuses {
  PRIMA = "Prima",
  WASTE = "Waste",
  INVESTIGATION = "Investigation",
}

export const noWidgetFieldData = "No data";

const dynamicWidgetTitles: Record<WidgetTypeEnum, string> = {
  [WidgetTypeEnum.ITEM_FLOW]: "widgetsModal.articleFlow",
  [WidgetTypeEnum.MATERIAL_CONSUMPTION]: "widgetsModal.materialConsumption",
  [WidgetTypeEnum.ORDER_STATUS]: "widgetsModal.orderStatus",
  [WidgetTypeEnum.MACHINES]: "widgetsModal.machineXProgressOnOrder",
  [WidgetTypeEnum.MATERIAL_TARGET_ALERT]: "widgetsModal.materialTargetAlert",
  [WidgetTypeEnum.QUALITY_TRACKING]: "widgetsModal.qualityTracking",
  [WidgetTypeEnum.PRODUCTION_DATA_TRACKER]:
    "widgetsModal.productionDataTracker",
  [WidgetTypeEnum.GRAPH_MATERIAL_LEVEL]: "widgetsModal.materialLvlGraph",
  [WidgetTypeEnum.STANDALONE_LINE_GRAPH]: "widgetsModal.standAloneLineGraph",
};

export const getWidgetTitle = (type: WidgetTypeEnum) => {
  return dynamicWidgetTitles[type] || "Unknown Widget";
};

export const returnInitialColumnNamesByWidgetType = (type: WidgetTypeEnum) => {
  switch (type) {
    case WidgetTypeEnum.ITEM_FLOW:
      return initialWidgetColumns.initialArticleFlowColumns;

    case WidgetTypeEnum.QUALITY_TRACKING:
      return initialWidgetColumns.initialArticleFlowColumns;

    case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
      return initialWidgetColumns.initialProductionDataTrackerColumns;

    case WidgetTypeEnum.MATERIAL_CONSUMPTION:
      return initialWidgetColumns.initialMaterialConsumedColumns;

    case WidgetTypeEnum.ORDER_STATUS:
      return initialWidgetColumns.initialOrderStatusColumns;

    case WidgetTypeEnum.MACHINES:
      return initialWidgetColumns.initialMachinesXColumns;

    case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
      return initialWidgetColumns.materialTargetAlertInitialColumns;

    default:
      return [];
  }
};

export const returnInitialFiltersByWidgetType = (type: WidgetTypeEnum) => {
  switch (type) {
    case WidgetTypeEnum.QUALITY_TRACKING:
      return initialWidgetFilters.qualityTracking;

    default:
      return [];
  }
};

export const returnInitialConfigurationsByWidgetType = (
  type: WidgetTypeEnum
) => {
  switch (type) {
    case WidgetTypeEnum.GRAPH_MATERIAL_LEVEL:
      return initialWidgetConfigurations.graphMaterialLevel;

    default:
      return [];
  }
};

export const getColumnsValuesByWidgetType = (type: WidgetTypeEnum) => {
  switch (type) {
    case WidgetTypeEnum.ITEM_FLOW:
      return articleFlowColumnsValues;

    case WidgetTypeEnum.QUALITY_TRACKING:
      return articleFlowColumnsValues;

    case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
      return productionTrackerColumnsValues;

    case WidgetTypeEnum.MATERIAL_CONSUMPTION:
      return materialConsumptionColumnsValues;

    case WidgetTypeEnum.ORDER_STATUS:
      return orderStatusColumnsValues;

    case WidgetTypeEnum.MACHINES:
      return machinesXColumnsValues;

    case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
      return adaptMaterialTargetColumnsValues;

    default:
      return [];
  }
};

export const getWidgetFormikValues = (
  widgetIndex: number,
  values: Dashboard
) => {
  return {
    columnNamesValue: values?.widgets?.[widgetIndex]?.column_names,
    columnNamesFieldName: `widgets.${widgetIndex}.column_names`,
    limitRowsValue: values?.widgets?.[widgetIndex]?.limit_rows,
    limitRowsFieldName: `widgets.${widgetIndex}.limit_rows`,
    sortByValue: values?.widgets?.[widgetIndex]?.sort_by,
    sortByFieldName: `widgets.${widgetIndex}.sort_by`,
    filterValues: values?.widgets?.[widgetIndex]?.filter,
    filterFieldName: `widgets.${widgetIndex}.filter`,
  };
};

export const getSortByOptionsByWidgetType = (type: WidgetTypeEnum) => {
  switch (type) {
    case WidgetTypeEnum.ITEM_FLOW:
      return widgetSorting.articleFlowSortByOptions;

    case WidgetTypeEnum.QUALITY_TRACKING:
      return widgetSorting.articleFlowSortByOptions;

    case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
      return widgetSorting.articleFlowSortByOptions;

    case WidgetTypeEnum.MATERIAL_CONSUMPTION:
      return widgetSorting.materialConsumptionSortByOptions;

    case WidgetTypeEnum.ORDER_STATUS:
      return widgetSorting.orderStatusSortByOptions;

    case WidgetTypeEnum.MACHINES:
      return widgetSorting.machinesXSortByOptions;

    case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
      return widgetSorting.adaptMaterialTargetSortByOptions;

    default:
      return [];
  }
};

export const adaptWidgetToUpdate = (values: Dashboard) => {
  return values?.widgets?.map((widget) => ({
    ...widget,
    sort_by: [widget?.sort_by].flat() as unknown as string[],
    filter: widget?.filter?.map((filter) => ({
      filter_parameter: filter?.filter_parameter,
      filter_value: [filter?.filter_value]
        .flat()
        .join(",") as unknown as string[],
    })),
  })) as UpdateWidgets[];
};

export const articleFlowStatuses = [
  { value: "P", label: "Prima" },
  { value: "W", label: "WASTED" },
  { value: "I", label: "INVESTIGATION" },
];

export const materialConsumptionStatuses = [
  { value: "WA", label: "Wasted" },
  { value: "LO", label: "Lost" },
  { value: "US", label: "Used" },
];

export const initialParseFilterValueToArray = (widgets: Widget[]) => {
  return widgets?.map((widget) => ({
    ...widget,
    filter: widget.filter?.map((filter) => ({
      ...filter,
      // Fixed backend issue with filter_value type. Here we need an array, but now have a string
      filter_value: (filter?.filter_value as string)?.split(","),
    })),
  })) as Widget[];
};

export const returnAdaptedPollingTime = (pollingSeconds: number) => {
  const adjustedSeconds = pollingSeconds / 1000;
  const minutes = Math.floor(adjustedSeconds / 60);
  const seconds = adjustedSeconds % 60;

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export const adaptFilterForTheWidgetValues = (filter: WidgetFilter[]) => {
  return filter?.map((filter) => {
    let filterValue;
    switch (filter?.filter_parameter) {
      case "material_stock_batch__material__in":
        filterValue = (filter?.filter_value as string)?.split(",").map(Number);
        break;

      case "process_template":
        filterValue = (filter?.filter_value as string)?.split(",").map(Number);
        break;

      case "material_category__in":
        filterValue = (filter?.filter_value as string)?.split(",").map(Number);
        break;

      case "production_tracking_material_stock_batch__material__in":
        filterValue = (filter?.filter_value as string)?.split(",").map(Number);
        break;

      case "process_step_trackers__machine__in":
        filterValue = (filter?.filter_value as string)?.split(",").map(Number);
        break;

      default:
        filterValue = (filter?.filter_value as string)?.split(",");
    }

    return {
      ...filter,
      // Fixed backend issue with filter_value type. Here we need an array, but now have a string
      filter_value: filterValue,
    };
  });
};
