import { BatchStatus, ProducedOrderItem, StockMaterialItem } from "shared/api";

export const renderStockBatchStatuses = (
  item: StockMaterialItem | ProducedOrderItem
) => {
  if (item.status === BatchStatus.CANCELLED) return "Cancelled";
  if (item.status === BatchStatus.DELIVERED) return "Delivered";
  if (item.status === BatchStatus.PENDING) return "Pending";
  if (item.status === BatchStatus.PRODUCED) return "Produced";
  return "Unknown status";
};
