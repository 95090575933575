import { WidgetFiltersKeys } from "./types";

export const widgetFiltersKeys: WidgetFiltersKeys = {
  articleFlow: {
    material: "material_stock_batch__material__in",
    status: "status__in",
  },
  materialConsumption: {
    status: "material_consumption_status__in",
  },
  machinesX: {
    processTemplate: "process_template",
  },
  materialTargetAlert: {
    materialCategory: "material_category__in",
  },
  orderStatus: {
    status: "order_status_status__in",
    machines: "process_step_trackers__machine__in",
  },
  productionTracker: {
    material: "production_tracking_material_stock_batch__material__in",
    status: "production_tracking_status__in",
  },
};

const articleFlowFilters = [
  {
    value: "material_stock_batch__material__in",
    label: "Material",
  },
  {
    value: "status__in",
    label: "Status",
  },
];

const materialConsumptionFilters = [
  {
    value: "material_consumption_status__in",
    label: "Status",
  },
];

const machinesXFilters = [
  {
    value: "process_template",
    label: "Process Template",
  },
];

const materialTargetAlertFilters = [
  {
    value: "material_category__in",
    label: "Material Category",
  },
];

const orderStatusFilters = [
  {
    value: "order_status_status__in",
    label: "Status",
  },
  {
    value: "process_step_trackers__machine__in",
    label: "Machines",
  },
];

const productionTrackerFilters = [
  {
    value: "production_tracking_material_stock_batch__material__in",
    label: "Material",
  },
  {
    value: "production_tracking_status__in",
    label: "Status",
  },
];

export const widgetsFilters = {
  articleFlow: articleFlowFilters,
  materialConsumption: materialConsumptionFilters,
  machinesX: machinesXFilters,
  materialTargetAlert: materialTargetAlertFilters,
  productionTracking: productionTrackerFilters,
  orderStatus: orderStatusFilters,
};
