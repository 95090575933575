import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  SupplierItemWithCost,
  UpdateMaterial,
  useGetMaterialByIdQuery,
  useGetMaterialCategoriesWithNoParamsQuery,
  useUpdateMaterialMutation,
} from "shared/api";
import { FormInput, FormSelect, HyperlinkPageHeader, Loader } from "shared/ui";
import {
  handleRequestError,
  noop,
  renderUnitName,
  returnUpdateMaterialParams,
} from "shared/helpers";
import { adaptMaterialCategories } from "shared/adapters";
import { Button, Input, Space, notification } from "antd";
import { SubmitCreateMaterialValues } from "shared/types";
import { expandMaterials } from "shared/constants";
import { EditMaterialSchema } from "shared/schemas";
import { useModal } from "shared/hooks";
import { AddSupplierToMaterialModal, SuppliersTable } from "./ui";

const MaterialHyperlink = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const [
    isOpenAddNewSuppliersModal,
    showAddNewSupplierModal,
    hideAddNewSuppliersModal,
  ] = useModal();

  const { data: material, isLoading: isLoadingMaterial } =
    useGetMaterialByIdQuery(
      { id: Number(id), expand: expandMaterials },
      { skip: !id }
    );

  const [dndSuppliers, setDndSuppliers] = useState<SupplierItemWithCost[]>([]);

  const handleDndSuppliers = (suppliers: SupplierItemWithCost[]) =>
    setDndSuppliers(suppliers);

  const { data: materialCategories, isLoading: isLoadingMaterialCategories } =
    useGetMaterialCategoriesWithNoParamsQuery({ expand: "unit" });

  const materialCategoriesAdapted = useMemo(() => {
    return adaptMaterialCategories(materialCategories || []);
  }, [materialCategories]);

  const addOrUpdateMaterialManagementFormik = useFormik({
    initialValues: { ...material?.[0] },
    validationSchema: EditMaterialSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const { values, handleBlur, handleChange, errors, setFieldValue, isValid } =
    addOrUpdateMaterialManagementFormik;

  const dynamicUnitName = useMemo(() => {
    return renderUnitName(
      values as SubmitCreateMaterialValues,
      materialCategories ?? []
    );
  }, [values, materialCategories]);

  const [updateMaterial] = useUpdateMaterialMutation();

  const updateMaterialItem = async (params: UpdateMaterial) => {
    try {
      await updateMaterial(params).unwrap();
      api.success({
        message: t("material-management.success-title"),
        description: t("material-management.success-update-desc"),
      });
    } catch (error) {
      api.error({
        message: t("material-category-management.error-title"),
        description: handleRequestError(
          error,
          t("material-category-management.error-title")
        ),
      });
    }
  };

  if (isLoadingMaterial || isLoadingMaterialCategories) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateMaterialManagementFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Material Hyperlink - ${material?.[0]?.name}`}
            isValid={isValid}
            onOk={() =>
              updateMaterialItem(
                returnUpdateMaterialParams(
                  values as SubmitCreateMaterialValues,
                  dndSuppliers
                )
              )
            }
          />

          <div className="p-4 w-full">
            <Title level={5}>{t("material-management.name")}</Title>

            <FormInput
              value={values.name}
              fieldName="name"
              placeholder={t(
                "material-management.update-modal-name-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name?.toString()}
              isError={!!errors.name}
            />

            <Title level={5}>{t("material-management.expanded-name")}</Title>

            <FormInput
              value={values.expanded_label_name}
              fieldName="expanded_label_name"
              placeholder={t(
                "material-management.create-modal-expanded-name-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.expanded_label_name?.toString()}
              isError={!!errors.expanded_label_name}
            />

            <Title level={5}>{t("material-management.category-name")}</Title>

            <FormSelect
              value={values?.material_category?.id || values?.material_category}
              placeholder={t(
                "material-management.update-modal-material-category-placeholder"
              )}
              fieldName="material_category.id"
              options={materialCategoriesAdapted}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            <Title level={5}>
              {t("material-management.target-quantity-in-stock")}
            </Title>

            <FormInput
              value={values.target_quantity_in_stock}
              fieldName="target_quantity_in_stock"
              placeholder={t(
                "material-management.target-quantity-in-stock-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.target_quantity_in_stock?.toString()}
              isError={!!errors.target_quantity_in_stock}
              numberType
            />

            <Title level={5}>
              {t("material-management.n-packaging-items")}
            </Title>

            <FormInput
              value={values.n_packaging_items}
              fieldName="n_packaging_items"
              placeholder={t(
                "material-management.n-packaging-items-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.n_packaging_items?.toString()}
              isError={!!errors.n_packaging_items}
              numberType
            />

            <Title level={5}>{t("material-management.unit")}</Title>

            <Input disabled value={dynamicUnitName} size="large" />

            <div className="flex items-center justify-between mt-2.5 mb-2.5">
              <Title level={5} className="mt-2.5">
                {t("material-management.suppliers")}
              </Title>

              <Space className="flex items-center justify-center">
                <Button
                  type="primary"
                  onClick={showAddNewSupplierModal}
                  className="btn-primary--dark mb-0"
                >
                  {t("material-management.new-supplier")}
                </Button>
              </Space>
            </div>

            <SuppliersTable
              handleDndSuppliers={handleDndSuppliers}
              dynamicUnitName={dynamicUnitName}
            />

            <AddSupplierToMaterialModal
              isOpen={isOpenAddNewSuppliersModal}
              hideAddNewSuppliersModal={hideAddNewSuppliersModal}
            />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { MaterialHyperlink };
