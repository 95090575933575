import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { columnsMaterialItemsData } from "shared/constants";
import React from "react";
import { MaterialStockItem } from "shared/api";
import {
  addThousandsSeparator,
  renderMaterialItemsStatuses,
} from "shared/helpers";

const {
  materialName,
  serialNumber,
  calcPercentageRemainingQuantity,
  calcTotalConsumedQuantity,
  calcTotalOriginalQuantity,
  calcTotalRemainingQuantity,
  status,
  timestamp,
  set,
  pos,
} = columnsMaterialItemsData;

interface UseGenerateMoveItemsColumnsProps {
  t: TFunction;
}

export const useGenerateMoveItemsColumns = ({
  t,
}: UseGenerateMoveItemsColumnsProps): ColumnsType<MaterialStockItem> => [
  {
    title: t(materialName.title),
    width: materialName.width,
    render: (materialItem: MaterialStockItem) => {
      return materialItem?.material_stock_batch?.material?.name;
    },
  },
  {
    title: t(serialNumber.title),
    render: (materialItem: MaterialStockItem) => {
      return materialItem?.serial_number;
    },
    width: serialNumber.width,
  },
  {
    title: set.title,
    render: (item: MaterialStockItem) => (
      <span>
        {item?.order_production_set?.set_number}.
        {item?.order_production_set?.sub_set_number}
      </span>
    ),
    width: set.width,
  },
  {
    title: pos.title,
    render: (item: MaterialStockItem) => item?.order_production_set_position,
    width: pos.width,
  },
  {
    title: t(timestamp.title),
    width: timestamp.width,
    render: (item: MaterialStockItem) =>
      dayjs.utc(item?.timestamp).format("DD/MM/YYYY"),
  },
  {
    title: t(calcTotalOriginalQuantity.title),
    width: calcTotalOriginalQuantity.width,
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_original_quantity ?? null),
  },
  {
    title: t(calcTotalConsumedQuantity.title),
    width: calcTotalConsumedQuantity.width,
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_consumed_quantity ?? null),
  },
  {
    title: t(calcTotalRemainingQuantity.title),
    width: calcTotalRemainingQuantity.width,
    render: (item: MaterialStockItem) =>
      addThousandsSeparator(item?.calc_total_remaining_quantity ?? null),
  },

  {
    title: t(calcPercentageRemainingQuantity.title),
    width: calcPercentageRemainingQuantity.width,
    render: (item: MaterialStockItem) =>
      `${item.calc_percentage_remaining_quantity}%`,
  },
  {
    title: t(status.title),
    width: status.width,
    render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
  },
];
