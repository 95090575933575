import { Alert, Button, Table, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { PlusOutlined } from "@ant-design/icons";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Loader, TableButton } from "shared/ui";
import { useConfirmDeleteModal, usePagination } from "shared/hooks";
import {
  handleRequestError,
  AnyType,
  addThousandsSeparator,
  noop,
} from "shared/helpers";
import { adaptMaterials } from "shared/adapters";
import {
  useDeleteMaterialReservationItemMutation,
  useGetAllMaterialsNoPaginationQuery,
  useGetMaterialReservationsQuery,
  MaterialReservationItem,
  MaterialReservationItemWithDetails,
  MaterialsItem,
} from "shared/api";
import {
  expandMaterialReservation,
  stockMaterialReservationColumnsData,
  materialReservationInitialValues,
} from "shared/constants";
import { AddReservationModal } from "./AddReservationModal";
import { EditReservationModal } from "./EditReservationModal";
import { AddOrUpdateMaterialReservationItemSchema } from "./schemas/schemas";

interface MaterialReservationsProps {
  selectedMaterial: MaterialsItem | null;
}

const MaterialReservations: FC<MaterialReservationsProps> = ({
  selectedMaterial,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const { material, reservationDate, quantity, actions } =
    stockMaterialReservationColumnsData;

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery();
  const adaptedMaterials = adaptMaterials(materials || []);

  const [isOpenAddReservationItemModal, setIsOpenAddReservationItemModal] =
    useState(false);
  const [isOpenEditReservationItemModal, setIsOpenEditReservationItemModal] =
    useState(false);

  const {
    data: materialReservation,
    isLoading: isLoadingMaterialReservation,
    isFetching: isFetchingMaterialReservation,
  } = useGetMaterialReservationsQuery(
    {
      limit,
      offset,
      expand: expandMaterialReservation,
      material: selectedMaterial?.id,
    },
    { skip: selectedMaterial?.id === null }
  );

  const addOrUpdateMaterialReservationItemFormik = useFormik({
    initialValues: materialReservationInitialValues,
    validationSchema: AddOrUpdateMaterialReservationItemSchema,
    onSubmit: noop,
  });

  const [deleteMaterialReservationItem] =
    useDeleteMaterialReservationItemMutation();

  const deleteMaterialReservationItemHandler = async (
    record: MaterialReservationItem
  ) => {
    try {
      await deleteMaterialReservationItem(record).unwrap();
      api.success({
        message: t("material-reservation.success-title"),
        description: t("material-reservation.success-delete-desc"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-reservation.error-title")
      );

      api.error({
        message: t("material-reservation.error-title"),
        description,
      });
    }
  };

  const showMaterialReservationItemDeleteModal = useConfirmDeleteModal(
    t("material-reservation.delete-msg"),
    deleteMaterialReservationItemHandler
  );

  const { setValues } = addOrUpdateMaterialReservationItemFormik;

  const editReservationMaterialHandler = (record: AnyType) => {
    setIsOpenEditReservationItemModal(true);
    setValues({
      id: record.id,
      material: record.material.id,
      quantity: record.quantity,
      reservation_date: record.reservation_date,
    });
  };

  useEffect(() => {
    addOrUpdateMaterialReservationItemFormik.setFieldValue(
      "material",
      selectedMaterial?.id
    );
  }, [selectedMaterial]);

  const columns: ColumnsType<
    MaterialReservationItem | MaterialReservationItemWithDetails
  > = [
    {
      title: t(material.title),
      render: (item: MaterialReservationItem) => (
        <TableButton
          type="title"
          title={item?.material?.name}
          onClick={() => editReservationMaterialHandler(item)}
        />
      ),
    },
    {
      title: t(reservationDate.title),
      dataIndex: reservationDate.dataIndex,
      key: reservationDate.key,
    },
    {
      title: t(quantity.title),
      render: (item: MaterialReservationItemWithDetails) =>
        `${addThousandsSeparator(item.quantity)} -  ${
          item.material?.material_category?.unit?.abbreviation
        }`,
    },

    {
      title: t(actions.title),
      key: actions.key,

      render: (record: MaterialReservationItem) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => {
                showMaterialReservationItemDeleteModal(record);
              }}
            />
          </div>
        );
      },
    },
  ];

  if (isLoadingMaterials) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <FormikProvider value={addOrUpdateMaterialReservationItemFormik}>
      {contextHolder}
      <>
        <Button
          type="primary"
          className="btn-primary--dark"
          onClick={() => setIsOpenAddReservationItemModal(true)}
        >
          {t("material-reservation.add-item-title")}
          <PlusOutlined />
        </Button>

        {!materialReservation?.results.length && (
          <Alert
            type="warning"
            showIcon
            message={t("material-reservation.no-items")}
            className="mb-5"
          />
        )}

        <Table
          loading={
            isLoadingMaterialReservation || isFetchingMaterialReservation
          }
          columns={columns}
          dataSource={materialReservation?.results}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: materialReservation?.count,
          }}
          className={!materialReservation?.results.length ? "hidden" : "block"}
        />
      </>

      <AddReservationModal
        isOpenModal={isOpenAddReservationItemModal}
        setIsOpenAddReservationItemModal={setIsOpenAddReservationItemModal}
        adaptedMaterials={adaptedMaterials}
        selectedMaterial={selectedMaterial}
      />

      <EditReservationModal
        isOpenModal={isOpenEditReservationItemModal}
        setIsOpenEditReservationItemModal={setIsOpenEditReservationItemModal}
        adaptedMaterials={adaptedMaterials}
        selectedMaterial={selectedMaterial}
      />
    </FormikProvider>
  );
};

export { MaterialReservations };
