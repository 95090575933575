import {
  ProcessStepOutputMaterial,
  AdaptedValues,
  AddProcessTemplatesValues,
} from "shared/types";
import { AddProductCardValues } from "pages/ProductCards/ProductCards/ProductCards";
import { dynamicSpecificationTypesEnum } from "shared/constants";
import { ConfigDynamicTrackerFields, ProcessStepItem } from "shared/api";
import { DynamicFormsValuesType } from "shared/features/DynamicFormsComponents/constants";

export const filterProportionalMaterials = (
  outputMaterials: ProcessStepOutputMaterial[],
  adaptedMaterials: AdaptedValues[]
) => {
  const materialIds = outputMaterials?.map((item) => item?.material);

  return adaptedMaterials?.filter((item) => materialIds.includes(item.value));
};

export const getProcessStepDndValues = (
  values: AddProductCardValues,
  processSteps?: ProcessStepItem[]
) => {
  const adaptedProcessStepsType = values?.process_steps as ProcessStepItem[];
  const stepWithId = !!adaptedProcessStepsType?.[0]?.id;
  const stepsIds = adaptedProcessStepsType?.map((item) => item.id) as number[];

  const processStepValues = stepWithId
    ? stepsIds
    : (values?.process_steps as number[]);

  const dynamicProcessSteps = processStepValues?.length
    ? processSteps?.filter((item: ProcessStepItem) =>
        processStepValues?.includes(item.id)
      )
    : [];

  const processStepItems = !values?.process_steps?.length
    ? dynamicProcessSteps?.map((item) => item.id)
    : processStepValues;

  const adaptedProcessStepsForTheTable = (processStepItems as number[])?.map(
    (item: number, index: number) => {
      const processStep = dynamicProcessSteps?.find(
        (stepItem) => stepItem.id === item
      );
      return {
        ...processStep,
        key: index + 1,
      };
    }
  );

  return adaptedProcessStepsForTheTable as ProcessStepItem[];
};

export const handleUnitTypeChange = (
  values: DynamicFormsValuesType,
  index: number,
  fieldIndex: number,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const choicesData = values?.sections?.[index]?.fields?.[fieldIndex]?.choices;
  const unitData = values?.sections?.[index]?.fields?.[fieldIndex]?.unit;

  const isChoices =
    values.sections[index].fields[fieldIndex].type ===
    dynamicSpecificationTypesEnum.CHOICES;

  const isInteger =
    values.sections[index].fields[fieldIndex].type ===
    dynamicSpecificationTypesEnum.INTEGER;

  const isFloat =
    values.sections[index].fields[fieldIndex].type ===
    dynamicSpecificationTypesEnum.FLOAT;

  const isChoicesAndNoData = isChoices && !(choicesData as string[])?.length;
  const isChoicesAndHaveData = isChoices && !!(choicesData as string[])?.length;

  const isIntegerOrFloatAndNoUnit = (isInteger || isFloat) && !unitData;
  const isIntegerOrFloatAndHaveUnit = (isInteger || isFloat) && unitData;

  if (isChoicesAndNoData) {
    setFieldValue(`sections[${index}].fields[${fieldIndex}].choices`, []);
  }

  if (isChoicesAndHaveData) {
    setFieldValue(
      `sections[${index}].fields[${fieldIndex}].choices`,
      choicesData
    );
  }

  if (isIntegerOrFloatAndNoUnit) {
    setFieldValue(`sections[${index}].fields[${fieldIndex}].unit`, undefined);
  }

  if (isIntegerOrFloatAndHaveUnit) {
    setFieldValue(`sections[${index}].fields[${fieldIndex}].unit`, unitData);
  }
};

export const adaptDynamicProcessTemplateField = (
  values: AddProcessTemplatesValues
) => {
  const adaptedData = values?.sections?.map((item) => {
    const fields = item.fields?.map((field) => {
      const { unit, choices, ...otherFields } = field;

      const data = {
        ...otherFields,

        ...(field.type === "integer" ||
        field.type === "float" ||
        field.type === "text"
          ? { unit }
          : {}),

        ...(field.type === "choices" ? { choices } : {}),
      };

      if (field.type !== "choices") {
        delete data.choices;
      }

      if (
        field.type !== "integer" &&
        field.type !== "float" &&
        field.type !== "text"
      ) {
        delete data.unit;
      }
      return data;
    });

    return {
      title: item.title,
      fields,
    };
  });

  return adaptedData as ConfigDynamicTrackerFields[];
};
