import React, { FC, useCallback, useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { Button, Divider, Input, Space } from "antd";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";
import { CloseOutlined } from "@ant-design/icons";
import { RenderClearFiltersButton } from "shared/ui";
import {
  calculateOffset,
  addThousandsSeparator,
  renderMaterialItemsStatuses,
} from "shared/helpers";
import { adaptMaterialObjectToArray } from "shared/adapters";
import {
  useGetMaterialItemsByIDNoParamsQuery,
  useGetMaterialItemsByIDQuery,
  MaterialConsumedItem,
  MaterialStockItem,
  MaterialsItem,
} from "shared/api";
import { useTableSorting } from "shared/hooks";
import { useTranslation } from "react-i18next";
import {
  sortDirections,
  MaterialItemsSorting,
  MaterialItemsWidth,
  columnsMaterialItemsData,
  expandMaterialItemsStringWithUnit,
} from "shared/constants";
import { AddMaterialConsumedValues } from "shared/types";

interface FilteredMaterialItemsTableProps {
  selectedMaterial: MaterialsItem | null;
  edit?: boolean;
  dynamicItemFromTable?: MaterialConsumedItem | null;
}

const FilteredMaterialItemsTable: FC<FilteredMaterialItemsTableProps> = ({
  selectedMaterial,
  edit,
  dynamicItemFromTable,
}) => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const isFirstPage = currentPage === 1;
  const { setFieldValue } = useFormikContext<AddMaterialConsumedValues>();

  const [serialNumberContains, setSerialNumberContains] = useState("");

  let externalSerialNumberReset: () => void;

  const handleSerialNumberContainsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSerialNumberContains(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSerialNumberContainsChange, 400),
    []
  );

  useEffect(() => {
    if (isFirstPage) {
      setOffset(0);
    } else {
      setOffset(calculateOffset(pageSize, currentPage));
    }
    setLimit(pageSize);
  }, [currentPage, pageSize]);

  const { ordering, changeSort } = useTableSorting();

  const [statusIN, setStatusIn] = useState("");
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusIn(e.target.value);
  };
  const debouncedChangeStatisInHandler = useCallback(
    debounce(handleStatusChange, 400),
    []
  );

  const {
    data: materialItems,
    isLoading: isLoadingMaterialItems,
    isFetching: isFetchingMaterialItems,
  } = useGetMaterialItemsByIDQuery({
    material: selectedMaterial?.id,
    limit,
    offset,
    expand: expandMaterialItemsStringWithUnit,
    serial_number__icontains: serialNumberContains,
    ordering,
    status__in: statusIN,
  });

  const {
    materialName,
    serialNumber,
    calcPercentageRemainingQuantity,
    calcTotalConsumedQuantity,
    calcTotalOriginalQuantity,
    calcTotalRemainingQuantity,
    status,
  } = columnsMaterialItemsData;

  const { data: allMaterialItems } = useGetMaterialItemsByIDNoParamsQuery({
    material: selectedMaterial?.id,
    expand: expandMaterialItemsStringWithUnit,
  });

  const selectedMaterialFormTable = allMaterialItems?.find(
    (item: MaterialStockItem) =>
      item?.serial_number === dynamicItemFromTable?.material_item?.serial_number
  );

  const arrayWithSelectedMaterial = adaptMaterialObjectToArray(
    selectedMaterialFormTable
  );

  const renderQuantityWithAbbreviation = (
    calc_total_original_quantity: number | null,
    calc_total_consumed_quantity: number | null,
    calc_total_remaining_quantity: number | null,
    selectedMaterial: MaterialsItem
  ) => {
    let message;

    if (calc_total_original_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_original_quantity)} - ${
        typeof selectedMaterial.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_consumed_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_consumed_quantity)} - ${
        typeof selectedMaterial.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_remaining_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_remaining_quantity)} - ${
        typeof selectedMaterial.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    return message;
  };

  const columnMaterialItems: ColumnsType<MaterialStockItem> = [
    {
      title: materialName.title,
      width: MaterialItemsWidth.materialName,
      render: (materialItem: MaterialStockItem) => {
        return materialItem?.material_stock_batch?.material?.name;
      },
    },

    {
      title: serialNumber.title,
      dataIndex: serialNumber.dataIndex,
      key: serialNumber.key,
      width: MaterialItemsWidth.serialNumber,
      filterDropdown: ({ selectedKeys, confirm, setSelectedKeys }) => {
        externalSerialNumberReset = () => setSelectedKeys([]);

        return (
          <Space className="flex flex-col items-center p-2.5 w-full">
            <Title level={5}>
              {t("material-consumed.find-by-serial-number")}
            </Title>

            <div className="flex items-center justify-between">
              <Input
                placeholder={t("material-consumed.enter-serial-number")!}
                value={selectedKeys[0]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debouncedChangeHandler(e);
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onPressEnter={() => {
                  confirm();
                }}
              />

              <Button
                type="primary"
                onClick={() => {
                  setSerialNumberContains("");
                  setSelectedKeys([]);
                  confirm({ closeDropdown: false });
                }}
                className="btn-filter--x_mark"
              >
                <CloseOutlined />
              </Button>
            </div>
          </Space>
        );
      },
    },

    {
      title: calcTotalOriginalQuantity.title,
      width: MaterialItemsWidth.calcTotalOriginalQuantity,
      render: (item: MaterialStockItem) =>
        renderQuantityWithAbbreviation(
          item.calc_total_original_quantity!,
          null,
          null,
          selectedMaterial!
        ),
    },
    {
      title: calcTotalConsumedQuantity.title,
      width: MaterialItemsWidth.calcTotalConsumedQuantity,
      render: (item: MaterialStockItem) =>
        renderQuantityWithAbbreviation(
          null,
          item.calc_total_consumed_quantity!,
          null,
          selectedMaterial!
        ),
    },
    {
      title: calcTotalRemainingQuantity.title,
      width: MaterialItemsWidth.calcTotalRemainingQuantity,
      render: (item: MaterialStockItem) =>
        renderQuantityWithAbbreviation(
          null,
          null,
          item.calc_total_remaining_quantity!,
          selectedMaterial!
        ),
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: calcPercentageRemainingQuantity.title,
      render: (item: MaterialStockItem) =>
        `${item.calc_percentage_remaining_quantity}%`,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: status.title,
      width: status.width,
      render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.STATUS_ASC,
            MaterialItemsSorting.STATUS_DESC
          );
        },
      }),
      sorter: true,
      filterDropdown: ({ selectedKeys, confirm, setSelectedKeys }) => {
        return (
          <Space className="flex flex-col items-center p-2.5 w-full">
            <Title level={5}>{t("material-consumed.filter-by-status")}</Title>

            <div className="flex items-center justify-between">
              <Input
                placeholder={t("material-consumed.filter-examples")!}
                value={selectedKeys[0]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debouncedChangeStatisInHandler(e);
                  setSelectedKeys(
                    e.target.value.toUpperCase()
                      ? [e.target.value.toUpperCase()]
                      : []
                  );
                }}
                onPressEnter={() => {
                  confirm();
                }}
              />

              <Button
                type="primary"
                onClick={() => {
                  setStatusIn("");
                  setSelectedKeys([]);
                  confirm({ closeDropdown: false });
                }}
                className="btn-filter--x_mark"
              >
                <CloseOutlined />
              </Button>
            </div>
          </Space>
        );
      },
    },
  ];

  const rowMaterialItemSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: MaterialStockItem[]
    ) => {
      setFieldValue("material_item", selectedRows?.[0].id);
      setFieldValue("material", null);
      setFieldValue("material_stock_batch", null);
    },
    getCheckboxProps: (record: MaterialStockItem) => ({
      name: record.id.toString(),
    }),
  };

  const clearAllFilters = () => {
    externalSerialNumberReset();
    setSerialNumberContains("");
  };

  const renderButtonAndClearAllFilters = () => {
    if (serialNumberContains && serialNumberContains.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    return null;
  };

  const returnSelectedMaterial = () => {
    return (
      <div className="mb-4 mt-3">
        <Title level={5}>{t("material-consumed.selected-item")}</Title>
        {renderButtonAndClearAllFilters()}
        <Table
          columns={columnMaterialItems}
          dataSource={arrayWithSelectedMaterial}
          rowKey={(obj) => obj.id!}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      {arrayWithSelectedMaterial.length && edit
        ? returnSelectedMaterial()
        : null}
      <Title level={5}>{t("material-consumed.material-items")}</Title>
      {renderButtonAndClearAllFilters()}
      <Table
        rowSelection={{
          type: "radio",
          ...rowMaterialItemSelection,
        }}
        loading={isLoadingMaterialItems || isFetchingMaterialItems}
        columns={columnMaterialItems}
        dataSource={materialItems?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: materialItems?.count,
        }}
      />

      <Divider />
    </>
  );
};

export { FilteredMaterialItemsTable };
