import React, { FC } from "react";
import { OrderStepTrackerResponseItem } from "shared/api";
import { useFormikContext } from "formik";
import {
  useInputMaterialsProductionPageData,
  useOutputMaterialDataForProductionPage,
  useOutputMaterialsProductionPageData,
} from "shared/hooks";
import { InputMaterialsContainer } from "./InputMaterialsContainer";
import { TrackingList } from "./TrackingList";
import { AddProductionRequest } from "./ProcessStepProduction";
import { OutputMaterialsContainer } from "./OutputMaterialsTable";
import { ProdPageInfoBlock } from "./ProdPageInfoBlock";
import { SubmitProductionFormInfo } from "./SubmitProductionFormInfo";

interface ProdPageContentProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  processStepId?: number;
  orderId?: string;
  openProducedItemInOrderModal: () => void;
  refetchOrderProcessStepTracker: () => void;
}

const ProdPageContent: FC<ProdPageContentProps> = ({
  orderStepTrackerResponseItem,
  processStepId,
  orderId,
  openProducedItemInOrderModal,
  refetchOrderProcessStepTracker,
}) => {
  const { setFieldValue, isValid, values } =
    useFormikContext<AddProductionRequest>();

  const { inputMaterials, adaptedInitialInputMaterials } =
    useInputMaterialsProductionPageData({
      orderStepTrackerResponseItem,
    });

  const { outputMaterials, adaptedInitialOutputMaterials } =
    useOutputMaterialsProductionPageData({
      orderStepTrackerResponseItem,
      setFieldValue,
    });

  const {
    countOfItems,
    handleCountOfItemsChange,
    isEditOutputMaterialsMode,
    toggleEditMode,
    isSomeEditModeOpen,
  } = useOutputMaterialDataForProductionPage({
    mainValues: values,
    orderStepTrackerResponseItem,
  });

  return (
    <div className="min-h-screen bg-gray-50 py-2 px-5 w-screen">
      <div className="flex flex-col gap-5 w-full mb-8 items-start justify-center">
        {inputMaterials && (
          <InputMaterialsContainer
            orderStepTrackerResponseItem={orderStepTrackerResponseItem!}
            adaptedInitialInputMaterials={adaptedInitialInputMaterials}
          />
        )}

        {outputMaterials && (
          <OutputMaterialsContainer
            orderStepTrackerResponseItem={orderStepTrackerResponseItem}
            countOfItems={countOfItems}
            handleCountOfItemsChange={handleCountOfItemsChange}
            isEditMode={isEditOutputMaterialsMode}
            toggleEditMode={toggleEditMode}
          />
        )}

        <SubmitProductionFormInfo
          outputMaterials={values?.output_materials}
          isSomeEditModeOpen={isSomeEditModeOpen}
          isValidProductionForm={isValid}
          processStepId={processStepId}
          values={values}
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          setFieldValue={setFieldValue}
          handleCountOfItemsChange={handleCountOfItemsChange}
          adaptedInitialInputMaterials={adaptedInitialInputMaterials}
          adaptedInitialOutputMaterials={adaptedInitialOutputMaterials}
          refetchOrderProcessStepTracker={refetchOrderProcessStepTracker}
        />
      </div>

      <ProdPageInfoBlock
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        processStepId={processStepId}
        openProducedItemInOrderModal={openProducedItemInOrderModal}
        orderId={orderId}
      />

      <TrackingList
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />
    </div>
  );
};

export { ProdPageContent };
