export const enum ModalSizeValue {
  PRIMARY = "25%",
  SECONDARY = "30%",
  MEDIUM = "40%",
  X_MEDIUM = "50%",
  XL_MEDIUM = "65%",
  LARGE = "80%",
  X_LARGE = "90%",
  FULL = "100%",
}
