import * as Yup from "yup";

export const AddOrUpdateMaterialItemSchema = Yup.object().shape({
  serial_number: Yup.string().required("Serial number is required"),
  quantity: Yup.number().nullable().required("Quantity is required"),
});

export const createAddOrUpdateMaterialItemSchema = (
  dynamicFieldsValidations: any[]
) => {
  const dynamicFields = dynamicFieldsValidations?.reduce((acc, field) => {
    return {
      ...acc,
      ...field,
    };
  }, {});
  return Yup.object().shape({
    serial_number: Yup.string().required("Serial number is required"),
    quantity: Yup.number().nullable().required("Quantity is required"),
    status: Yup.string().nullable().required("Status is required"),
    dynamic_tracker_fields: Yup.object({
      ...dynamicFields,
    }),
  });
};
