import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateStandAloneItem,
  DeleteStandAloneItem,
  StandAloneItem,
  StandAloneParams,
  StandAloneResponse,
  StandAloneWidgetResponse,
} from "./models";
import { Widget } from "../dashboards";

export const standAloneApi = createApi({
  baseQuery,
  reducerPath: "standAloneApi",
  tagTypes: ["standAlone"],
  endpoints: (builder) => ({
    getStandAloneConfigurations: builder.query<
      StandAloneResponse,
      StandAloneParams
    >({
      query: (params) => ({
        url: "/api/v1/influxdbservers/",
        method: "GET",
        params,
      }),
      providesTags: ["standAlone"],
    }),

    getAllStandAloneConfigurations: builder.query<StandAloneItem[], void>({
      query: () => ({
        url: "/api/v1/influxdbservers/",
        method: "GET",
      }),
      providesTags: ["standAlone"],
    }),

    createStandAloneConfigurations: builder.mutation<
      void,
      CreateStandAloneItem
    >({
      query: (body) => ({
        url: "/api/v1/influxdbservers/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["standAlone"],
    }),

    deleteStandAloneConfigurations: builder.mutation<
      void,
      DeleteStandAloneItem
    >({
      query: (params) => ({
        url: `/api/v1/influxdbservers/${params.id}`,
        method: "DELETE",
        params,
      }),
      invalidatesTags: ["standAlone"],
    }),

    updateStandAloneConfigurations: builder.mutation<void, StandAloneItem>({
      query: (body) => ({
        url: `/api/v1/influxdbservers/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["standAlone"],
    }),

    runStandAloneWidgetData: builder.query<StandAloneWidgetResponse[], Widget>({
      query: (body) => ({
        url: `/api/v1/widgets/${body.id}/run_standalone_query/`,
        method: "GET",
        // body,
      }),
      providesTags: ["standAlone"],
    }),
  }),
});

export const {
  useGetStandAloneConfigurationsQuery,
  useCreateStandAloneConfigurationsMutation,
  useDeleteStandAloneConfigurationsMutation,
  useUpdateStandAloneConfigurationsMutation,
  useRunStandAloneWidgetDataQuery,
  useGetAllStandAloneConfigurationsQuery,
} = standAloneApi;
