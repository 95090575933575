import { Alert } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AddProductionRequest } from "../ProcessStepProduction";

interface MaterialSectionProps {
  children?: React.ReactNode;
}

const MaterialSection: FC<MaterialSectionProps> = ({ children }) => {
  const { t } = useTranslation();

  const { values } = useFormikContext<AddProductionRequest>();

  return (
    <div
      className={classNames(
        `
      w-full flex flex-col mt-3 h-auto
      shadow-md overflow-auto 
      rounded-[10px] 
    `,
        {
          "!h-[120px] flex items-center justify-center pb-3":
            !values.output_materials?.length,
        }
      )}
    >
      <div
        className="
        flex w-full items-center justify-between 
        bg-blue-primary rounded-t-[10px] h-10
      "
      >
        <Title level={5} className="!m-0 !text-white px-2">
          {t("orders.output-materials")}
        </Title>
      </div>

      {!values.output_materials?.length && (
        <Alert
          message={t("orders.no-output-items")}
          showIcon
          type="info"
          className="w-[90%] mt-2"
        />
      )}

      {children}
    </div>
  );
};

export { MaterialSection };
