import {
  AddProcessStepValues,
  DynamicProcessStep,
  ProcessStepInputMaterial,
  ResultItem,
} from "shared/types";
import { ConsumptionTypes } from "shared/constants";
import { ProcessStepItem } from "shared/api/productCard/models";

export const adaptProcessSteps = (processSteps: ProcessStepItem[]) => {
  const adaptSteps = processSteps?.map((item) => ({
    label: item.name,
    value: item.id,
    key: item.id,
  }));
  return adaptSteps || [];
};

export const updateDynamicProcessStep = (
  dynamicProcessStep: DynamicProcessStep,
  result: ResultItem[],
  updateFieldsCallback: any
) => {
  return (dynamicProcessStep ?? []).map((item) => {
    const updatedItem = { ...item };

    updatedItem.fields = updatedItem.fields.map((field) => {
      const matchingResult = result.find(
        (resultItem) =>
          resultItem.title === updatedItem.title &&
          resultItem.field === field.name
      );

      if (matchingResult) {
        return updateFieldsCallback(field, matchingResult.value);
      }

      return field;
    });

    return updatedItem;
  });
};

export const getInputMaterialConsumptionValues = (
  values: AddProcessStepValues,
  index: number
) => {
  const materialConsumptionTypeValue =
    values?.input_materials[index]?.consumption_type || null;

  const isFixedValue = materialConsumptionTypeValue === ConsumptionTypes.FI;

  const isProportionalValue =
    materialConsumptionTypeValue === ConsumptionTypes.PR;

  const consumptionFixedValue =
    values?.input_materials[index]?.consumption_fixed_value || null;

  const consumptionProportionalValue =
    values.input_materials?.[index]?.consumption_proportional_value;

  const consumptionProportionalMaterial =
    values.input_materials?.[index]?.consumption_proportional_material;

  const materialConsumptionTypeFieldName = `input_materials.${index}.consumption_type`;
  const consumptionFixedValueFieldName = `input_materials.${index}.consumption_fixed_value`;

  const consumptionProportionalFieldName = `input_materials.${index}.consumption_proportional_value`;
  const consumptionProportionalMaterialFieldName = `input_materials.${index}.consumption_proportional_material`;

  return {
    materialConsumptionTypeValue,
    isFixedValue,
    isProportionalValue,
    consumptionFixedValue,
    consumptionProportionalValue,
    consumptionProportionalMaterial,
    materialConsumptionTypeFieldName,
    consumptionFixedValueFieldName,
    consumptionProportionalFieldName,
    consumptionProportionalMaterialFieldName,
  };
};

export const adaptProcessStepInputMaterials = (
  input_materials: ProcessStepInputMaterial[]
): ProcessStepInputMaterial[] => {
  return input_materials?.map((item) => {
    const {
      material,
      consumption_type,
      consumption_fixed_value,
      consumption_proportional_value,
      consumption_proportional_material,
    } = item;

    switch (consumption_type) {
      case "MA":
        return {
          material,
          consumption_type,
          consumption_fixed_value: null,
          consumption_proportional_value: null,
          consumption_proportional_material: null,
        };
      case "FI":
        return {
          material,
          consumption_type,
          consumption_fixed_value,
          consumption_proportional_value: null,
          consumption_proportional_material: null,
        };
      case "PR":
        return {
          material,
          consumption_type,
          consumption_fixed_value: null,
          consumption_proportional_value,
          consumption_proportional_material,
        };
      default:
        return {
          material,
          consumption_type,
          consumption_fixed_value: null,
          consumption_proportional_value: null,
          consumption_proportional_material: null,
        };
    }
  });
};

interface DynamicProcessStepFields {
  [section: string]: {
    [field: string]: string | null;
  };
}

export const adaptDynamicProcessStepFields = (
  fields: DynamicProcessStepFields
): DynamicProcessStepFields => {
  const adaptedFields: DynamicProcessStepFields = {};

  Object.keys(fields).forEach((section) => {
    adaptedFields[section] = {};
    Object.keys(fields[section]).forEach((field) => {
      adaptedFields[section][field] = fields[section][field] ?? null;
    });
  });

  return adaptedFields;
};
