import { Image, Popover, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import React, { useMemo } from "react";
import { ConfigurationType, useGetAllConfigurationsQuery } from "shared/api";
import { noop } from "shared/helpers";
import { useModal } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { ConfigurationsModal } from "./ConfigurationsModal";

const CustomerLogo = () => {
  const { t } = useTranslation("", { keyPrefix: "sidebar" });

  const { data: configurations, isLoading: isLoadingConfigurations } =
    useGetAllConfigurationsQuery({});

  const imageConfiguration = useMemo(() => {
    return configurations?.find(
      (config) => config.key === ConfigurationType.Image
    );
  }, [configurations]);

  const companyNameConfiguration = useMemo(() => {
    const newCompanyName = configurations?.find(
      (config) => config.key === ConfigurationType.CompanyName
    );

    if (newCompanyName) {
      localStorage.setItem(ConfigurationType.CompanyName, newCompanyName.value);
    }

    return newCompanyName;
  }, [configurations]);

  const timezoneConfiguration = useMemo(() => {
    const timezone = configurations?.find(
      (config) => config.key === ConfigurationType.Timezone
    );

    if (timezone) {
      localStorage.setItem(ConfigurationType.Timezone, timezone.value);
    }

    return timezone;
  }, [configurations]);

  const [isOpenUploadLogoModal, showUploadLogoModal, hideUploadLogoModal] =
    useModal();

  const customerLogoFormik = useFormik({
    initialValues: {
      image: imageConfiguration?.file,
      companyName: companyNameConfiguration?.value,
      timezone: timezoneConfiguration?.value,
    },
    enableReinitialize: true,
    onSubmit: noop,
  });

  const { values } = customerLogoFormik;

  if (isLoadingConfigurations) {
    return <Skeleton.Button size="large" active className="!h-10 !w-[180px]" />;
  }

  return (
    <FormikProvider value={customerLogoFormik}>
      <div className="w-full h-full flex gap-x-4 items-center">
        {imageConfiguration?.file ? (
          <Image
            src={values?.image!}
            preview={false}
            className="!h-[60px] !w-[240px] !object-contain !cursor-pointer"
            onClick={showUploadLogoModal}
          />
        ) : (
          <Popover content={t("pressToUpload")}>
            <Title
              level={4}
              className="!m-0 cursor-pointer"
              onClick={showUploadLogoModal}
            >
              {t("uploadLogo")}
            </Title>
          </Popover>
        )}

        <ConfigurationsModal
          isOpen={isOpenUploadLogoModal}
          hideModal={hideUploadLogoModal}
          companyNameConfiguration={companyNameConfiguration}
          timezoneConfiguration={timezoneConfiguration}
        />
      </div>
    </FormikProvider>
  );
};

export { CustomerLogo };
