import { useNavigate } from "react-router-dom";
import { addDashboardKey, translateSidebarItems } from "shared/helpers";
import { Routes } from "shared/routers";
import { useMemo, useState } from "react";
import {
  configKeyArray,
  ordersKeyArray,
  productCardsKeyArray,
  reportsKeyArray,
  supplyChainKeysArray,
} from "shared/ui/Sidebar/constants";
import { useTranslation } from "react-i18next";
import { useModal } from "./useModal";
import { useDashboardSidebarItems } from "./useDashboardSidebarItems";

interface UseSidebarDataProps {
  pathname: string;
}

export const useSidebarData = ({ pathname }: UseSidebarDataProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const openMinimizedSidebar = () => setMinimizedSidebar(true);
  const closeMinimizedSidebar = () => setMinimizedSidebar(false);

  const [isSelectedSupplyChainMenu, setIsSelectedSupplyChainMenu] =
    useState(false);

  const [isSelectedProductCardMenu, setIsSelectedProductCardMenu] =
    useState(false);

  const [isSelectedOrdersMenu, setSelectedOrdersMenu] = useState(false);

  const [isSelectedConfigMenu, setSelectedConfigMenu] = useState(false);

  const [isSelectedReportsMenu, setSelectedReportsMenu] = useState(false);

  const [
    isVisibleAddDashboardModal,
    showAddDashboardModal,
    hideDashboardModal,
  ] = useModal();

  const handleActiveLink = (e: { key: React.Key }) => {
    const { key } = e;
    if (key === addDashboardKey) {
      showAddDashboardModal();
    } else {
      navigate(key.toString());
    }
  };

  const {
    materialCategoryManagement,
    materialManagement,
    stockMaterial,
    processTemplates,
    machines,
    processSteps,
    productCards,
    orderPlans,
    orders,
    packaging,
    templateConfig,
    users,
    executeReport,
    itemView,
    standAlone,
  } = Routes;

  useMemo(() => {
    const supplyChainUrls = [
      materialCategoryManagement.url,
      materialManagement.url,
      stockMaterial.url,
      itemView.url,
    ];
    setIsSelectedSupplyChainMenu(supplyChainUrls.includes(pathname));

    const productCardUrls = [
      processTemplates.url,
      machines.url,
      processSteps.url,
      productCards.url,
    ];
    setIsSelectedProductCardMenu(productCardUrls.includes(pathname));

    const ordersUrls = [orderPlans.url, orders.url, packaging.url];
    setSelectedOrdersMenu(ordersUrls.includes(pathname));

    const configUrls = [templateConfig.url, users.url, standAlone.url];
    setSelectedConfigMenu(configUrls.includes(pathname));

    const reportsUrls = [executeReport.url];
    setSelectedReportsMenu(reportsUrls.includes(pathname));
  }, [pathname]);

  const selectMenuKeys = () => {
    if (isSelectedSupplyChainMenu) {
      return supplyChainKeysArray;
    }

    if (isSelectedProductCardMenu) {
      return productCardsKeyArray;
    }

    if (isSelectedOrdersMenu) {
      return ordersKeyArray;
    }

    if (isSelectedConfigMenu) {
      return configKeyArray;
    }

    if (isSelectedReportsMenu) {
      return reportsKeyArray;
    }

    return [];
  };

  const sidebarItems = useDashboardSidebarItems();

  const translatedSidebarItems = useMemo(() => {
    return translateSidebarItems(sidebarItems, t);
  }, [sidebarItems, t]);

  return {
    minimizedSidebar,
    openMinimizedSidebar,
    closeMinimizedSidebar,
    handleActiveLink,
    selectMenuKeys,
    isVisibleAddDashboardModal,
    hideDashboardModal,
    showAddDashboardModal,
    translatedSidebarItems,
  };
};
