import { createApi } from "@reduxjs/toolkit/query/react";
import base64 from "base-64";
import { baseQuery } from "../baseQuery";
import { LoginParams, LoginResponse } from "./models";

export const authApi = createApi({
  baseQuery,
  reducerPath: "authApi",
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginParams>({
      query: ({ username, password }) => ({
        url: "/api/v1/auth/login/",
        method: "POST",
        headers: {
          Authorization: `Basic ${base64.encode(`${username}:${password}`)}`,
        },
        body: {
          username,
          password,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/api/v1/auth/logout/",
        method: "POST",
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
