import { Popover } from "antd";
import classNames from "classnames";
import React, { FC } from "react";

interface SoftHardValuesProps {
  softMinimum?: number;
  softMaximum?: number;
  hardMinimum?: number;
  hardMaximum?: number;
  size?: "small";
  className?: string;
}

const SoftHardValues: FC<SoftHardValuesProps> = ({
  softMaximum,
  softMinimum,
  hardMaximum,
  hardMinimum,
  size,
  className,
}) => {
  const isSmall = size === "small";

  return (
    <div className={classNames("h-5 w-full flex gap-x-2 mb-4", {}, className)}>
      {hardMinimum ? (
        <Popover content={`Hard Minimum - ${hardMinimum}`}>
          <div
            className={classNames(
              "w-auto h-6  p-1 rounded-full bg-[#ff3333] text-white",
              {
                "w-5 h-auto p-1 text-[10px]": isSmall,
              }
            )}
          >
            {hardMinimum}
          </div>
        </Popover>
      ) : null}

      {softMinimum ? (
        <Popover content={`Soft Minimum - ${softMinimum}`}>
          <div
            className={classNames(
              "w-auto h-6  p-1 rounded-full bg-yellow-400 text-white",
              {
                "w-5 h-auto p-1 text-[10px]": isSmall,
              }
            )}
          >
            {softMinimum}
          </div>
        </Popover>
      ) : null}

      {softMaximum ? (
        <Popover content={`Soft Maximum - ${softMaximum}`}>
          <div
            className={classNames(
              "w-auto h-6  p-1 rounded-full  bg-yellow-400 text-white",
              {
                "w-5 h-auto p-1 text-[10px]": isSmall,
              }
            )}
          >
            {softMaximum}
          </div>
        </Popover>
      ) : null}

      {hardMaximum ? (
        <Popover content={`Hard Maximum - ${hardMaximum}`}>
          <div
            className={classNames(
              "w-auto h-6  p-1 rounded-full bg-[#ff3333] text-white",
              {
                "w-5 h-auto p-1 text-[10px]": isSmall,
              }
            )}
          >
            {hardMaximum}
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export { SoftHardValues };
