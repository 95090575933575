export interface ConfigurationItem {
  id?: number;
  file: string | null;
  key: string;
  value: string;
}

export type ConfigurationKey = "image" | "companyName" | "timezone";

export const enum ConfigurationType {
  Image = "image",
  CompanyName = "companyName",
  Timezone = "timezone",
}
export interface ConfigurationParams {
  key?: ConfigurationKey;
}

export interface AddConfigurationImage {
  image: FormData;
}

export interface ConfigurationImage {
  file: string;
}
