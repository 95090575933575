import { Button } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { WidgetTableData } from "shared/api";
import {
  MaterialConsumptionFields,
  colorMaterialConsumptionStatusButton,
  getMaterialConsumptionImage,
} from "shared/helpers";
import { DateWithTimeRow, TableImage } from "shared/ui";

interface MaterialConsumptionWidgetProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

const MaterialConsumptionWidget: FC<MaterialConsumptionWidgetProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const { t } = useTranslation();

  if (fieldKey === MaterialConsumptionFields.ICON) {
    return (
      <TableImage
        image={getMaterialConsumptionImage(record?.consumedStatus)}
        preview={false}
        className="w-9 h-9"
      />
    );
  }

  if (text === MaterialConsumptionFields.WIDGET_TYPE) {
    return <span>{t(`widgets.${text}`)} </span>;
  }

  if (fieldKey === MaterialConsumptionFields.MATERIAL_CONSUMED_DATE) {
    return <DateWithTimeRow text={text} />;
  }

  if (fieldKey === MaterialConsumptionFields.MATERIAL_CONSUMED_STATUS) {
    return (
      <Button
        className={classNames(
          "w-full",
          colorMaterialConsumptionStatusButton(record)
        )}
      >
        {t(`widgets.${text}`)}
      </Button>
    );
  }

  return <span>{text}</span>;
};

const MaterialConsumptionWidgetMemo = React.memo(MaterialConsumptionWidget);

export { MaterialConsumptionWidgetMemo as MaterialConsumptionWidget };
