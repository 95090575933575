import classNames from "classnames";
import React, { FC } from "react";
import { ProductionInputMaterial } from "shared/api";
import { DynamicQuantityDropDown } from "./DynamicQuantityDropDown";

interface DynamicInputMaterialQuantityProps {
  moreThanOneMaterial: boolean;
  inputIndex: number;
  inputMaterial: ProductionInputMaterial;
  fieldIndex: number;
  removeItem: (fieldIndex: number) => void;
}

const DynamicInputMaterialQuantity: FC<DynamicInputMaterialQuantityProps> = ({
  moreThanOneMaterial,
  inputIndex,
  inputMaterial,
  fieldIndex,
  removeItem,
}) => {
  return (
    <div
      className={classNames("w-[30%]", {
        "w-full": moreThanOneMaterial,
      })}
    >
      <DynamicQuantityDropDown
        inputIndex={inputIndex}
        inputMaterial={inputMaterial}
        fieldIndex={fieldIndex}
        removeItem={removeItem}
      />
    </div>
  );
};

export { DynamicInputMaterialQuantity };
