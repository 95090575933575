import { Radio } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";

interface RadioButtonsProps {
  isActive: boolean;
  title: string;
  leftVariantTitle: string;
  rightVariantTitle: string;
  selectLeftOption: () => void;
  selectRightOption: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const RadioButtons: FC<RadioButtonsProps> = ({
  isActive,
  title,
  leftVariantTitle,
  rightVariantTitle,
  selectLeftOption,
  selectRightOption,
  leftIcon,
  rightIcon,
}) => {
  return (
    <>
      <Title level={5} className="mt-2 ">
        {title}
      </Title>

      <Radio.Group className="w-full m-0 flex mb-2">
        <Radio.Button
          value="true"
          onClick={selectLeftOption}
          className={classNames("w-1/2 flex items-center justify-center", {
            "!bg-blue-primary !text-white": isActive,
            "!bg-gray-50 !text-blue-primary": !isActive,
            "!bg-gray-50 !text-blue-primary !flex !items-center !justify-between":
              !leftIcon,
          })}
        >
          <div className="flex h-full items-center">
            {leftVariantTitle}
            {leftIcon && leftIcon}
          </div>
        </Radio.Button>

        <Radio.Button
          value="false"
          onClick={selectRightOption}
          className={classNames("w-1/2 flex items-center justify-center", {
            "!bg-blue-primary !text-white": !isActive,
            "!bg-gray-50 !text-blue-primary": isActive,
          })}
        >
          <div className="flex h-full items-center">
            {rightVariantTitle}
            {rightIcon && rightIcon}
          </div>
        </Radio.Button>
      </Radio.Group>
    </>
  );
};

export { RadioButtons };
