import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Image, Modal, Select, Upload, UploadFile } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { ModalSizeValue } from "shared/constants";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigurationItem } from "shared/api";

import { useCompanyImageUpload, useConfigurations } from "shared/hooks";
import { Colors } from "shared/themes";
import { FormInput } from "shared/ui/FormInput";
import timezones from "timezones-list";
import { selectFiltering } from "shared/helpers";

export interface ConfigurationForm {
  image: string | null;
  companyName: string | null;
  timezone: string | null;
}

interface ConfigurationsModalProps {
  isOpen: boolean;
  hideModal: () => void;
  companyNameConfiguration?: ConfigurationItem;
  timezoneConfiguration?: ConfigurationItem;
}

const ConfigurationsModal: FC<ConfigurationsModalProps> = ({
  isOpen,
  hideModal,
  companyNameConfiguration,
  timezoneConfiguration,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<ConfigurationForm>();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const isDocumentListLength = !!documentList.length;

  const {
    uploadedFile,
    previewLogoImage,
    uploadCompanyImageProps,
    closeModalAndClearUploadedImage,
  } = useCompanyImageUpload({
    documentList,
    handleDocumentList,
    closeModal: hideModal,
  });

  const { handleConfigurationsRequests, contextHolder } = useConfigurations({
    uploadedFile,
    closeModalAndClearUploadedImage,
    values,
    companyNameConfiguration,
    timezoneConfiguration,
  });

  const handleTimezoneChange = (value: string) => {
    setFieldValue("timezone", value);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={hideModal}
        onOk={handleConfigurationsRequests}
        width={ModalSizeValue.MEDIUM}
        okButtonProps={{
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        <div>
          <Title level={5}>{t("sidebar.companyName")}</Title>

          <FormInput
            value={values?.companyName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={t("sidebar.companyName")}
            fieldName="companyName"
            className="mb-4"
          />

          <Title level={5}>{t("sidebar.timezone")}</Title>

          <Select
            value={values?.timezone}
            onChange={handleTimezoneChange}
            placeholder={t("sidebar.selectTimezone")}
            className="mb-4 w-full"
            showSearch
            filterOption={(input, option) => selectFiltering(input, option)}
          >
            {timezones.map((timezone) => (
              <Select.Option key={timezone.tzCode} value={timezone.tzCode}>
                {timezone.label}
              </Select.Option>
            ))}
          </Select>

          {values?.image ? (
            <div className="w-full flex items-center justify-between ">
              <Title level={5} className="mb-1.25">
                {t("companies.logo")}
              </Title>

              <div className="flex gap-x-3">
                {!isDocumentListLength && (
                  <Image
                    src={values?.image!}
                    className="!h-[45px] !w-[180px] !object-contain"
                  />
                )}

                <Upload {...uploadCompanyImageProps}>
                  <Button className="w-12 h-12 rounded-full p-0 !mt-1">
                    <PlusOutlined className="p-0 mb-1" />
                  </Button>
                </Upload>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-between">
              <Title level={5} className="mb-1.25">
                {t("companies.logo")}
              </Title>

              <div>
                {previewLogoImage && (
                  <Avatar
                    src={previewLogoImage}
                    alt="preview"
                    className="ml-3"
                  />
                )}

                <Upload {...uploadCompanyImageProps}>
                  {!previewLogoImage && (
                    <Button className="w-8 h-8 rounded-full p-0">
                      <PlusOutlined className="p-0 mb-1" />
                    </Button>
                  )}
                </Upload>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export { ConfigurationsModal };
