import { Table, notification } from "antd";
import React, { FC, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableButton } from "shared/ui";
import { useConfirmDeleteModal, usePagination } from "shared/hooks";
import { handleRequestError, renderOrderPlanStatus } from "shared/helpers";
import { adaptOrderPlansForMachines } from "shared/adapters";
import {
  useDeleteOrderPlanMutation,
  useGetOrderPlansQuery,
  OrderPlan,
} from "shared/api";
import { AddOrderPlanValues, ScheduleItem } from "shared/types";
import { orderPlansColumnsData, orderPlansParams } from "shared/constants";

interface OrderPlansTableProps {
  setOpenEditPlanModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOrderPlan: React.Dispatch<React.SetStateAction<OrderPlan | null>>;
  setItems: React.Dispatch<React.SetStateAction<ScheduleItem[]>>;
  selectedOrderPlan: OrderPlan | null;
}

const OrderPlansTable: FC<OrderPlansTableProps> = ({
  setOpenEditPlanModal,
  setSelectedOrderPlan,
  setItems,
  selectedOrderPlan,
}) => {
  const { t } = useTranslation();
  const { name, status, actions } = orderPlansColumnsData;
  const [api, contextHolder] = notification.useNotification();
  const { setValues } = useFormikContext<AddOrderPlanValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const [deleteOrderPlan] = useDeleteOrderPlanMutation();

  const {
    data: orderPlans,
    isLoading: isLoadingOrderPlans,
    isFetching: isFetchingOrderPlans,
  } = useGetOrderPlansQuery(
    {
      offset,
      limit,
      expand: orderPlansParams.expand,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    const selectedOrderPlanUpdated = orderPlans?.results.find(
      (item) => item.id === selectedOrderPlan?.id
    );
    setSelectedOrderPlan(selectedOrderPlanUpdated ?? null);

    const adaptedOrderPlansForMachines = adaptOrderPlansForMachines(
      selectedOrderPlanUpdated || null
    );

    setItems(adaptedOrderPlansForMachines as ScheduleItem[]);
  }, [orderPlans]);

  const editOrderPlan = (record: OrderPlan) => {
    setOpenEditPlanModal(true);
    setValues({
      id: record.id!,
      name: record.name!,
      status: record.status!,
    });
  };

  const deleteOrderPlanHandler = async (record: OrderPlan) => {
    try {
      await deleteOrderPlan(record).unwrap();
      api.success({
        message: t("plans.success-title"),
        description: t("plans.success-delete-msg"),
      });
    } catch (error: any) {
      const description = handleRequestError(error, t("companies.error"));

      api.error({
        message: t("plans.error-title"),
        description,
      });
    }
  };

  const showOrderPlanDeleteModal = useConfirmDeleteModal(
    t("plans.delete-alert-msg"),
    deleteOrderPlanHandler
  );

  const navigate = useNavigate();

  const columns: ColumnsType<OrderPlan> = [
    {
      title: t(name.title),
      render: (item: OrderPlan) => (
        <TableButton
          type="title"
          title={item?.name}
          onClick={() => editOrderPlan(item)}
        />
      ),
      width: name.width,
    },
    {
      title: t(status.title),
      render: (item: OrderPlan) => renderOrderPlanStatus(item),
      width: status.width,
    },

    {
      title: t(actions.title),
      key: actions.key,
      width: actions.width,
      render: (record: OrderPlan) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="schedule"
              onClick={() => navigate(`/order-plans/${record.id}`)}
            />
            <TableButton
              type="delete"
              onClick={() => showOrderPlanDeleteModal(record)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        loading={isLoadingOrderPlans || isFetchingOrderPlans}
        columns={columns}
        dataSource={orderPlans?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: orderPlans?.count,
        }}
      />
    </>
  );
};

export { OrderPlansTable };
