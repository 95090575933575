import React, { FC } from "react";
import { Widget, WidgetTableData } from "shared/api";
import { getGaugeFields } from "shared/helpers";
import { SimpleGauge } from "./MaterialLevelGraph/ui";
import { ConfigurationsAlert } from "../ConfigurationsAlert";

interface GaugeDataProps {
  widgetsData: WidgetTableData[];
  widget: Widget;
}

const GaugeData: FC<GaugeDataProps> = ({ widgetsData, widget }) => {
  const {
    isSimpleGauge,
    value,
    showConfigurationsAlert,
    redLimit,
    yellowLimit,
  } = getGaugeFields(widgetsData, widget);

  if (showConfigurationsAlert) {
    return <ConfigurationsAlert />;
  }

  return (
    <div className="bg-blue-primary flex items-center justify-center h-[275px]">
      {isSimpleGauge && (
        <SimpleGauge
          value={value}
          redLimit={redLimit}
          yellowLimit={yellowLimit}
        />
      )}
    </div>
  );
};

const GaugeDataMemo = React.memo(GaugeData);

export { GaugeDataMemo as GaugeData };
