import { Widget, WidgetTypeEnum } from "shared/api";
import { AllFilterKeys } from "./types";
import { widgetsFilters } from "./widget.filtersConstants";

export const getFiltersByWidgetType = (widgetType: WidgetTypeEnum) => {
  switch (widgetType) {
    case WidgetTypeEnum.ITEM_FLOW:
      return widgetsFilters.articleFlow;

    case WidgetTypeEnum.QUALITY_TRACKING:
      return widgetsFilters.articleFlow;

    case WidgetTypeEnum.MATERIAL_CONSUMPTION:
      return widgetsFilters.materialConsumption;

    case WidgetTypeEnum.ORDER_STATUS:
      return widgetsFilters.orderStatus;

    case WidgetTypeEnum.MACHINES:
      return widgetsFilters.machinesX;

    case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
      return widgetsFilters.materialTargetAlert;

    case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
      return widgetsFilters.productionTracking;

    default:
      return [];
  }
};

export const adaptWidgetFiltersToUpdate = (widget: Widget) => {
  return widget?.filter?.map((filter) => ({
    ...filter,
    filter_value: Array.isArray(filter?.filter_value)
      ? filter?.filter_value?.join(",")
      : filter?.filter_value,
  }));
};

export const getWidgetFilterFormikValues = (
  values: Widget,
  filterIndex: number
) => {
  return {
    filterParameter: {
      value: values?.filter?.[filterIndex]?.filter_parameter || null,
      fieldName: `filter.${filterIndex}.filter_parameter`,
    },
    filterValue: {
      value: values?.filter?.[filterIndex]?.filter_value,
      fieldName: `filter.${filterIndex}.filter_value`,
    },
  };
};

export const filterFieldArrayNewRow = {
  filter_parameter: "",
  filter_value: [],
};

export const renderTranslatedFilterName = (filterKey: AllFilterKeys) => {
  switch (filterKey) {
    case "material_stock_batch__material__in":
      return "Material";

    case "status__in":
      return "Status";

    case "material_consumption_status__in":
      return "Status";

    case "process_template":
      return "Process Template";

    case "material_category__in":
      return "Material Category";

    case "order_status_status__in":
      return "Status";

    case "production_tracking_material_stock_batch__material__in":
      return "Material";

    case "production_tracking_status__in":
      return "Status";

    case "process_step_trackers__machine__in":
      return "Machine";

    default:
      return null;
  }
};
