import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useSidebarData } from "shared/hooks";
import { AddDashboardModal } from "./ui/AddDashboardModal";
import {
  MinimizedSidebar,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
} from "./ui";

interface SidebarProps {
  isOpenSidebar?: boolean;
  dynamicPageId?: string | undefined;
}

const Sidebar: FC<SidebarProps> = ({ isOpenSidebar, dynamicPageId }) => {
  const { pathname } = useLocation();

  const {
    minimizedSidebar,
    closeMinimizedSidebar,
    openMinimizedSidebar,
    isVisibleAddDashboardModal,
    hideDashboardModal,
    selectMenuKeys,
    handleActiveLink,
    translatedSidebarItems,
  } = useSidebarData({ pathname });

  if (minimizedSidebar) {
    return <MinimizedSidebar closeMinimizedSidebar={closeMinimizedSidebar} />;
  }

  if (!dynamicPageId && !isOpenSidebar) {
    return null;
  }

  return (
    <div
      className={classNames(
        "w-60 h-screen sticky  pb-7 top-0 bg-blue-primary shadow-sidebar  transition-transform duration-300 sidebar"
      )}
    >
      <SidebarHeader openMinimizedSidebar={openMinimizedSidebar} />

      <div className="h-90% flex flex-col justify-between mt-5 sidebar">
        <SidebarMenu
          handleActiveLink={handleActiveLink}
          pathname={pathname}
          translatedSidebarItems={translatedSidebarItems}
          selectMenuKeys={selectMenuKeys}
        />
        <SidebarFooter />
      </div>

      <AddDashboardModal
        isOpen={isVisibleAddDashboardModal}
        onClose={hideDashboardModal}
      />
    </div>
  );
};

const SidebarMemo = React.memo(Sidebar);

export { SidebarMemo as Sidebar };
