import { SubmitCreateMaterialValues } from "shared/types";
import { SupplierCompanyItem, SupplierItemWithCost } from "shared/api";

export const adaptSuppliers = (suppliers: SupplierItemWithCost[]) => {
  const suppliersMaterials = suppliers?.map((item) => {
    if (typeof item === "number") {
      return item;
    }
    return item?.id;
  });
  return suppliersMaterials || [];
};

export const adaptCompaniesIsSuppliers = (
  companiesAdapted: SupplierCompanyItem[]
) => {
  const adaptCompanies = companiesAdapted
    ?.filter((item) => item.is_supplier === true)
    .map((item) => ({
      label: `${item.name}`,
      value: item.id!,
    }));
  return adaptCompanies || [];
};

export const adaptSuppliersToUpdateMaterial = (
  suppliers: SupplierItemWithCost[]
) => {
  const adapted = suppliers?.map((item) => {
    const cost_per_unit_currency = item?.cost_per_unit_currency;
    const cost_per_unit = parseFloat(item.cost_per_unit).toFixed(2);
    const supplier = item?.supplier?.id ? item?.supplier?.id : item?.supplier;

    return {
      cost_per_unit_currency,
      cost_per_unit: Number(cost_per_unit),
      supplier,
    };
  });

  return adapted ?? [];
};

export const adaptSuppliersForTheDnDTable = (
  suppliers: SupplierItemWithCost[],
  companies: SupplierCompanyItem[]
) => {
  const adaptedSuppliers = suppliers?.map(
    (item: SupplierItemWithCost, index: number) => {
      if (item?.supplier?.address) {
        return {
          ...item,
          key: index + 1,
        };
      }

      if (typeof item?.supplier === "number") {
        const supplierId = item?.supplier as number;

        const fullSupplierInfo = companies?.find(
          (company) => company?.id === supplierId
        );

        return {
          ...item,
          key: index + 1,
          supplier: fullSupplierInfo,
        };
      }

      return item;
    }
  );

  return adaptedSuppliers ?? [];
};

export const findSupplierForTheDnDTable = (
  companies: SupplierCompanyItem[],
  values: SubmitCreateMaterialValues
) => {
  const foundItems = companies
    ?.map((item, index) => ({ ...item, key: index + 1 }))
    .filter((item) =>
      values?.suppliers?.some((supplier) => {
        if (typeof supplier === "number") {
          return supplier === item.id;
        }

        return supplier.id === item.id;
      })
    );

  return foundItems;
};
