import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormInput } from "shared/ui";
import { DynamicFormsValuesType } from "./constants";

interface NameSelectProps {
  index: number;
  fieldIndex: number;
}

const NameSelect: FC<NameSelectProps> = ({ index, fieldIndex }) => {
  const { t } = useTranslation();

  const { handleBlur, handleChange, values } =
    useFormikContext<DynamicFormsValuesType>();

  return (
    <div className="w-[16%]">
      <Title level={5}>{t("material-category-management.name")}</Title>
      <FormInput
        key={`sections[${index}].fields[${fieldIndex}].name`}
        value={values.sections[index].fields?.[fieldIndex]?.name}
        fieldName={`sections[${index}].fields[${fieldIndex}].name`}
        placeholder={t("material-category-management.name-placeholder")}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </div>
  );
};

export { NameSelect };
