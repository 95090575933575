import React, { FC } from "react";
import Table from "antd/es/table";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  useConfirmDeleteModal,
  useGetMachinesTableColumns,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import {
  useDeleteMachineMutation,
  useGetAllMachinesQuery,
  MachineItem,
} from "shared/api";
import { AddMachinesValues } from "shared/types";
import { expandMachinesString } from "shared/constants";

interface MachinesTableProps {
  showEditMachineModal: () => void;
}

const MachinesTable: FC<MachinesTableProps> = ({ showEditMachineModal }) => {
  const { t } = useTranslation();

  const { setValues } = useFormikContext<AddMachinesValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    data: machines,
    isLoading: isLoadingProcessTypes,
    isFetching: isFetchingProcessTypes,
  } = useGetAllMachinesQuery({
    offset,
    limit,
    expand: expandMachinesString,
    ordering,
  });

  const [deleteMachine] = useDeleteMachineMutation();
  const [api, contextHolder] = notification.useNotification();

  const deleteMachineHandler = async (record: MachineItem) => {
    try {
      await deleteMachine(record).unwrap();
      api.success({
        message: t("machines.success-title"),
        description: t("machines.success-delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(error, t("machines.error"));

      api.error({
        message: t("machines.error"),
        description,
      });
    }
  };

  const showMachineDeleteModal = useConfirmDeleteModal(
    t("machines.delete-alert-msg"),
    deleteMachineHandler
  );

  const editMachinesHandler = (record: MachineItem) => {
    showEditMachineModal();
    setValues({
      id: record?.id,
      name: record?.name,
      process_template: record.process_template.id,
    });
  };

  const columns = useGetMachinesTableColumns({
    t,
    editMachinesHandler,
    showMachineDeleteModal,
    changeSort,
  });

  return (
    <>
      {contextHolder}
      <Table
        loading={isLoadingProcessTypes || isFetchingProcessTypes}
        columns={columns}
        dataSource={machines?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: machines?.count,
        }}
      />
    </>
  );
};

export { MachinesTable };
