import { useFormikContext } from "formik";
import { AdaptedValues, AddCompanyValues } from "shared/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Modal } from "antd";
import Title from "antd/es/typography/Title";
import Table from "antd/es/table";
import { useCompaniesSupplied, useModal } from "shared/hooks";
import { Colors } from "shared/themes";
import { MaterialCategoriesItem } from "shared/api";
import { ModalSizeValue } from "shared/constants";

interface CategoriesSuppliedDynamicFormProps {
  adaptedMaterialCategories: AdaptedValues[];
  isEditMode?: boolean;
  materialCategories: MaterialCategoriesItem[];
}

const CategoriesSuppliedDynamicForm: FC<CategoriesSuppliedDynamicFormProps> = ({
  adaptedMaterialCategories,
  isEditMode,
  materialCategories,
}) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<AddCompanyValues>();

  const [isVisible, showModal, hideModal] = useModal();

  const {
    rowSelection,
    newItemsColumns,
    selectedColumns,
    addCategories,
    selectedMaterialCategories,
    addedItemsColumns,
  } = useCompaniesSupplied({
    t,
    values,
    setFieldValue,
    adaptedMaterialCategories,
    hideModal,
    isEditMode,
    materialCategories: materialCategories ?? [],
  });

  return (
    <>
      <div className="w-full ">
        <div className="flex items-center justify-between">
          <Title level={5}>{t("companies.category-supplied")}</Title>
          <Button
            type="primary"
            className="btn-primary--dark"
            onClick={showModal}
          >
            {t("companies.add-category-supplied")}
          </Button>
        </div>

        {values?.categories_supplied?.length ? (
          <Table
            columns={addedItemsColumns}
            dataSource={selectedMaterialCategories}
          />
        ) : null}
      </div>

      <Modal
        open={isVisible}
        onCancel={hideModal}
        onOk={addCategories}
        okButtonProps={{ style: { background: Colors.blue.primary } }}
        width={ModalSizeValue.XL_MEDIUM}
      >
        <div className="w-full flex gap-x-10 min-h-[600px]">
          <div className="w-1/2 flex flex-col gap-y-2">
            <Title level={5}>{t("companies.add-material-category")}</Title>

            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={newItemsColumns}
              dataSource={adaptedMaterialCategories}
              pagination={false}
              className="max-h-[820px] overflow-auto"
            />
          </div>

          <div className="w-1/2 flex flex-col gap-y-2">
            <Title level={5}>
              {t("companies.selected-material-categories")}
            </Title>

            {selectedMaterialCategories.length ? (
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={selectedColumns}
                dataSource={selectedMaterialCategories}
              />
            ) : (
              <Alert
                type="info"
                showIcon
                message={t("companies.no-data")}
                className="w-full h-14"
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { CategoriesSuppliedDynamicForm };
