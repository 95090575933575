import React, { FC, useMemo } from "react";
import { AlertOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormSelect } from "shared/ui";
import { adaptDynamicSelectValues } from "shared/adapters";
import {
  AddOrUpdateOrderValues,
  Card,
  AddProcessStepValues,
  AddStockMaterialItemValues,
} from "shared/types";
import { ConfigDynamicTrackerFields } from "../../api/materials/models";

interface DynamicSelectInputProps {
  card: ConfigDynamicTrackerFields | Card;
  fieldIndex: number;
  fieldName?: string;
  value?: number | string;
}

const DynamicSelectInput: FC<DynamicSelectInputProps> = ({
  card,
  fieldIndex,
  fieldName,
  value,
}) => {
  const { t } = useTranslation();
  const { handleBlur, setFieldValue } = useFormikContext<
    | AddOrUpdateOrderValues
    | AddProcessStepValues
    | AddStockMaterialItemValues
    | any
  >();

  const adaptedValues = useMemo(() => {
    return adaptDynamicSelectValues(card, fieldIndex);
  }, [card, fieldIndex]);

  return (
    <div className="relative">
      <FormSelect
        value={value}
        fieldName={fieldName!}
        placeholder={t("orders.placeholder-select")}
        options={adaptedValues}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
      />
      {card.fields?.[fieldIndex]?.required === true && !value && (
        <AlertOutlined className="btn-remove absolute top-2 right-2 mr-5" />
      )}
    </div>
  );
};

const DynamicSelectInputMemo = React.memo(DynamicSelectInput);

export { DynamicSelectInputMemo as DynamicSelectInput };
