import { useEffect, useState } from "react";
import {
  QualityResultItem,
  useExportReelLabArchiveQuery,
  useExportReelLabResultQuery,
} from "shared/api";
import { downloadExcelFileHelper, downloadPdfFileHelper } from "shared/helpers";

export const useQualityResultsDownload = () => {
  const [qualityResultXlsId, setQualityResultXlsId] =
    useState<QualityResultItem | null>(null);

  const [qualityArchiveXlsId, setQualityArchivesId] =
    useState<QualityResultItem | null>(null);

  const { data: reelLabResult } = useExportReelLabResultQuery(
    { id: qualityResultXlsId?.id as number },
    { skip: !qualityResultXlsId?.id }
  );

  const { data: reelLabArchive } = useExportReelLabArchiveQuery(
    { id: qualityArchiveXlsId?.id as number },
    { skip: !qualityArchiveXlsId?.id }
  );

  const handleExportReelLabResult = (report: QualityResultItem) => {
    setQualityResultXlsId(report);
  };

  const handleExportReelLabArchive = async (report: QualityResultItem) => {
    setQualityArchivesId(report);
  };

  const downloadReelXls = () => {
    const adaptedResponseToBlob = reelLabResult as unknown as Blob;
    downloadExcelFileHelper(adaptedResponseToBlob, qualityResultXlsId?.name);
  };

  const downloadReelPdf = () => {
    const adaptedResponseToBlob = reelLabArchive as unknown as Blob;
    downloadPdfFileHelper(adaptedResponseToBlob, qualityArchiveXlsId?.name);
  };

  useEffect(() => {
    if (reelLabResult) {
      downloadReelXls();
      setQualityResultXlsId(null);
    }
  }, [reelLabResult]);

  useEffect(() => {
    if (reelLabArchive) {
      downloadReelPdf();
      setQualityArchivesId(null);
    }
  }, [reelLabArchive]);

  return {
    handleExportReelLabResult,
    handleExportReelLabArchive,
  };
};
