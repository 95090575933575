import React, { FC } from "react";
import { Button, Popover } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GroupItem } from "shared/types";

interface CustomGroupHeaderProps {
  group: GroupItem;
  openDynamicItemsModal: (group: GroupItem) => void;
}

const CustomGroupHeader: FC<CustomGroupHeaderProps> = ({
  group,
  openDynamicItemsModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-auto h-full flex items-center justify-between p-2">
      <Popover content={group.group.title}>
        <div className="w-[70%] !truncate">{group.group.title}</div>
      </Popover>

      <Popover content={t("planner.add-item")}>
        <Button
          className="btn-primary--dark mt-2 ml-2 flex items-center justify-center w-[30%] "
          onClick={() => openDynamicItemsModal(group)}
        >
          <PlusOutlined className="!text-white scale-125" />
        </Button>
      </Popover>
    </div>
  );
};

export { CustomGroupHeader };
