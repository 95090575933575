import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MaterialStockBatchItemResponse,
  SuppliersItem,
  useGetMaterialStockBatchesQuery,
} from "shared/api";
import {
  useDebouncedTableFilter,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { useFormikContext } from "formik";
import { Alert } from "antd";
import { RenderClearFiltersButton, TableSearchFilter } from "shared/ui";
import {
  sortDirections,
  StockBatchOrdering,
  expandStockBatchString,
  stockBatchesColumn,
} from "shared/constants";
import { FilterFilled } from "@ant-design/icons";
import { UploadDocumentValues } from "shared/types";

interface StockBatchesTableProps {
  handleStockBatchesRows: (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => void;
  isEditMode?: boolean;
  selectedStockBatches?: MaterialStockBatchItemResponse[];
  selectedStockBatchesKeys: React.Key[];
  handleStockBatchesKeys: (keys: React.Key[]) => void;
}

const StockBatchesTable: FC<StockBatchesTableProps> = ({
  handleStockBatchesRows,
  isEditMode,
  selectedStockBatches,
  selectedStockBatchesKeys,
  handleStockBatchesKeys,
}) => {
  const { t } = useTranslation();

  const { values } = useFormikContext<UploadDocumentValues>();

  const pagination = usePagination();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedBatchCodeValue,
    handleFilterChange: handleBatchCodeValue,
    value: batchCodeValue,
    clearFilterValue: clearBatchCodeValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedSuppliersValue,
    handleFilterChange: handleSuppliersValue,
    value: suppliersValue,
    clearFilterValue: clearSuppliersValue,
  } = useDebouncedTableFilter("", 400);

  const {
    data: stockBatches,
    isLoading,
    isFetching,
  } = useGetMaterialStockBatchesQuery({
    offset,
    limit,
    ordering,
    expand: expandStockBatchString,
    material: values?.material || "",
    batch_code__icontains: debouncedBatchCodeValue,
    produced_in_order_process_step_tracker__order: values?.order || "",
    supplier__name__icontains: debouncedSuppliersValue,
  });

  const noFilteringResults =
    stockBatches?.results?.length === 0 && !!batchCodeValue.length;

  const clearFilters = () => {
    clearBatchCodeValue();
    clearSuppliersValue();
  };

  const { name, suppliers, batchCode } = stockBatchesColumn;

  const columns: ColumnsType<MaterialStockBatchItemResponse> = [
    {
      title: t(name.title),
      width: name.width,
      render: (record: MaterialStockBatchItemResponse) =>
        record?.material?.name,
    },
    {
      title: t(suppliers.title),
      width: suppliers.width,
      render: (record: { supplier: SuppliersItem }) =>
        (record?.supplier as SuppliersItem)?.name || t("quality.no-supplier"),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            StockBatchOrdering.SUPPLIER_ASC,
            StockBatchOrdering.SUPPLIER_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearSuppliersValue}
          confirm={confirm}
          value={suppliersValue}
          handleFilterChange={handleSuppliersValue!}
          visible={visible}
          placeholder={t("quality.filter-by-suppliers")!}
          title={t("quality.filter-by-suppliers")!}
        />
      ),

      filterIcon: (
        <FilterFilled
          className={suppliersValue?.length ? "!text-yellow-600" : ""}
        />
      ),
    },
    {
      title: t(batchCode.title),
      width: batchCode.width,
      render: (record: MaterialStockBatchItemResponse) => record?.batch_code,
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearBatchCodeValue}
          confirm={confirm}
          value={batchCodeValue}
          handleFilterChange={handleBatchCodeValue}
          visible={visible}
          placeholder={t("quality.filter-by-batch-code")!}
          title={t("quality.filter-by-batch-code")!}
        />
      ),

      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            StockBatchOrdering.BATCH_CODE_ASC,
            StockBatchOrdering.SUPPLIER_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterIcon: (
        <FilterFilled
          className={batchCodeValue?.length ? "!text-blue-600" : ""}
        />
      ),
    },
  ];

  const selectTableRows = {
    onChange: (
      _selectedRowKeys: React.Key[],
      selectedRows: MaterialStockBatchItemResponse[]
    ) => {
      handleStockBatchesRows(selectedRows);
      handleStockBatchesKeys(selectedRows.map((row) => row.id));
    },
    getCheckboxProps: (record: MaterialStockBatchItemResponse) => ({
      name: record?.material?.name,
    }),
  };

  useEffect(() => {
    if (isEditMode && selectedStockBatches?.length) {
      const keys = selectedStockBatches.map((item) => item.id);
      handleStockBatchesKeys(keys);
    }
  }, [isEditMode, selectedStockBatches]);

  if (noFilteringResults) {
    return (
      <div className="mt-5">
        <RenderClearFiltersButton onClick={clearFilters} />

        <Alert
          type="warning"
          showIcon
          message={t("quality.no-stock-batches")}
          className="my-3"
        />
      </div>
    );
  }

  return (
    <>
      <Title level={5} className="mt-3">
        {t("quality.previously-selected")}
      </Title>

      {isEditMode && (
        <Table
          rowSelection={{
            type: "checkbox",
            columnWidth: "5%",
            selectedRowKeys: selectedStockBatchesKeys,
            ...selectTableRows,
          }}
          loading={isLoading || isFetching}
          columns={columns}
          dataSource={selectedStockBatches}
          rowKey={(obj) => obj.id!}
          pagination={{
            pageSize,
            showSizeChanger: true,
            current: currentPage,
            onShowSizeChange(page, pageSize) {
              setPageSize(pageSize);
              setCurrentPage(page);
            },
            onChange(page) {
              setCurrentPage(page);
            },
            total: stockBatches?.count,
          }}
        />
      )}

      <Title level={5}>{t("quality.material-stock-batches")}</Title>

      <Table
        rowSelection={{
          type: "checkbox",
          columnWidth: "5%",
          selectedRowKeys: selectedStockBatchesKeys,
          ...selectTableRows,
        }}
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={stockBatches?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: stockBatches?.count,
        }}
      />
    </>
  );
};

export { StockBatchesTable };
