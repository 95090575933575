import { Alert, Button, Table, notification } from "antd";
import React, { FC, useEffect, useMemo } from "react";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { FormSelect, Loader, TableButton } from "shared/ui";
import { useConfirmDeleteModal, usePagination } from "shared/hooks";
import { handleRequestError, renderPackageSituation } from "shared/helpers";
import {
  useDeletePackageMutation,
  useGeneratePackageReportMutation,
  useGetAllPackagesQuery,
  PackagingItem,
  PackageSituationEnum,
} from "shared/api";
import { getMaterialsForThePackage } from "shared/adapters";
import { AddPackageValues } from "shared/types";
import { expandPackages, logisticsTableData } from "shared/constants";

interface PackagingTableProps {
  showEditAddPackagingModal: () => void;
  showOpenExportLabelModal: () => void;
  orderID: number | null;
  setPackageMaterialItems: React.Dispatch<
    React.SetStateAction<PackagingItem | null>
  >;
  materialFilter: string | number;
  handleMaterialFilter: (value: number | string) => void;
  orderCode: string | null;
}

const PackagingTable: FC<PackagingTableProps> = ({
  showEditAddPackagingModal,
  showOpenExportLabelModal,
  orderID,
  setPackageMaterialItems,
  materialFilter,
  handleMaterialFilter,
  orderCode,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [deletePackage] = useDeletePackageMutation();
  const [generatePackageReport] = useGeneratePackageReportMutation();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const {
    name,
    actions,
    material,
    calcCountMaterialItems,
    materialItems,
    situation,
  } = logisticsTableData;

  const { setValues, values, handleBlur, setFieldValue } =
    useFormikContext<AddPackageValues>();

  useEffect(() => {
    if (values?.materialFilter) {
      handleMaterialFilter(values?.materialFilter);
    }
  }, [values?.materialFilter]);

  const {
    data: packages,
    isLoading: isLoadingPackages,
    isFetching: isFetchingPackages,
  } = useGetAllPackagesQuery(
    {
      offset,
      limit,
      expand: expandPackages.query,
      order: orderID,
    },
    { skip: !orderID }
  );

  const adaptedMaterials = useMemo(() => {
    if (packages?.results?.length) {
      return getMaterialsForThePackage(packages);
    }
    return [];
  }, [packages, orderCode, orderID]);

  const editLogisticsItem = (record: PackagingItem) => {
    showEditAddPackagingModal();
    setValues({
      id: record?.id,
      name: record.name,
      material: record.material.id,
      calc_count_material_items: record.calc_count_material_items,
      order: record.order,
      situation: record.situation!,
    });
  };

  const showExportLabel = (record: PackagingItem) => {
    showOpenExportLabelModal();
    setPackageMaterialItems(record);
  };

  const deletePackageHandler = async (record: PackagingItem) => {
    try {
      await deletePackage(record).unwrap();
      api.success({
        message: t("packaging.success"),
        description: t("packaging.delete-msg"),
      });
    } catch (error: any) {
      const description = handleRequestError(error, t("packaging.error"));

      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  const showDeleteLogisticItemModal = useConfirmDeleteModal(
    t("packaging.delete-warning"),
    deletePackageHandler
  );

  const createPackageReport = async (record: PackagingItem) => {
    try {
      const response = await generatePackageReport({
        id: record.id,
        name: record.name,
        material: record.material.id,
        order: record.order,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      const description = handleRequestError(error, t("packaging.error"));
      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  const columns: ColumnsType<PackagingItem> = [
    {
      title: t(name.title),
      render: (item: PackagingItem) => (
        <TableButton
          type="title"
          title={item?.name}
          onClick={() => editLogisticsItem(item)}
        />
      ),
    },
    {
      title: t(material.title),
      render: (item: PackagingItem) => item?.material?.name,
      width: material.width,
    },
    {
      title: t(calcCountMaterialItems.title),
      dataIndex: calcCountMaterialItems.dataIndex,
      key: calcCountMaterialItems.key,
    },
    {
      title: t(materialItems.title),
      render: (record: PackagingItem) => (
        <div className="w-hull h-full flex flex-wrap gap-x-2">
          {!record?.material_items?.length && (
            <Alert
              message={t("packaging.no-items")}
              type="warning"
              showIcon
              className="w-full"
            />
          )}

          {record.material_items.map((materialItem) => {
            return (
              <div
                key={materialItem.id}
                className="text-[14px] pb-2 last:pb-0 hover:underline transition-all duration-1000"
              >
                {materialItem.serial_number}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: t(situation.title),
      render: (record: PackagingItem) =>
        t(renderPackageSituation(record?.situation as PackageSituationEnum)),
    },
    {
      title: t(actions.title),
      key: actions.key,
      render: (record: any) => {
        const isCompletedOrder = record.order === "Completed";

        return (
          <div
            className={classNames(
              "w-full flex items-center justify-around",
              {}
            )}
          >
            {isCompletedOrder ? (
              <div className="flex flex-col gap-y-2">
                <Button> {t("packaging.view-order")}</Button>

                <Button onClick={() => showExportLabel(record)}>
                  {t("packaging.generate-export-label")}
                </Button>
              </div>
            ) : (
              <>
                <TableButton
                  type="pdf-file"
                  onClick={() => createPackageReport(record)}
                  popoverString={t("packaging.generate-package-report")!}
                />

                <TableButton
                  type="pdf-file"
                  className="!text-purple-100"
                  popoverString={t("packaging.generate-material-items-report")!}
                  onClick={() => showExportLabel(record)}
                />

                <TableButton
                  type="delete"
                  onClick={() => showDeleteLogisticItemModal(record)}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (adaptedMaterials?.length) {
      const newMaterialFilter = adaptedMaterials[0].value;
      setFieldValue("materialFilter", newMaterialFilter);
    }
  }, [adaptedMaterials, setFieldValue]);

  if (isLoadingPackages || isFetchingPackages) {
    return <Loader />;
  }

  if (!adaptedMaterials?.length) {
    return (
      <Alert
        message={t("packaging.no-packages", { orderCode })!}
        type="warning"
        showIcon
        className="my-4"
      />
    );
  }

  return (
    <>
      {contextHolder}

      <FormSelect
        key={materialFilter}
        options={adaptedMaterials ?? []}
        value={materialFilter}
        fieldName="materialFilter"
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        placeholder={t("packaging.select-material")!}
      />

      <Table
        loading={isLoadingPackages || isFetchingPackages}
        columns={columns}
        dataSource={packages?.results.filter(
          (item) => item?.material?.id === materialFilter
        )}
        rowKey={(obj) => obj.id!}
        className={!packages?.results.length ? "hidden" : "block mt-3"}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: packages?.count,
        }}
      />
    </>
  );
};

export { PackagingTable };
