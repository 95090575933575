import { notification, Table } from "antd";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useConfirmDeleteModal,
  useDebouncedTableFilter,
  useGetMaterialCategoryColumns,
  useModal,
  usePagination,
} from "shared/hooks";
import {
  handleRequestError,
  updateMaterialCategoryToSetNewInformationField,
} from "shared/helpers";
import {
  useDeleteMaterialCategoryMutation,
  useGetMaterialCategoriesQuery,
  MaterialCategoriesItem,
  ExpandUnitItem,
} from "shared/api";
import { AddOrEditMateriaCategoryValues } from "shared/types";
import { expandMaterialCategoriesWithSuppliersAndUnit } from "shared/constants";
import { CategorySuppliersModal } from "./CategorySuppliersModal";

interface MaterialsCategoryTableProps {
  showEditMaterialCategoryModal: () => void;
}
const MaterialsCategoryTable: FC<MaterialsCategoryTableProps> = ({
  showEditMaterialCategoryModal,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [deleteMaterialCategory] = useDeleteMaterialCategoryMutation();

  const { setValues } = useFormikContext<AddOrEditMateriaCategoryValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const {
    debouncedValue: nameContains,
    handleFilterChange: handleNameContainsValue,
    value: nameContainsValue,
    clearFilterValue: clearNameContainsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    data: allMaterialCategories,
    isLoading: isLoadingMaterialCategories,
    isFetching: isFetchingMaterialCategories,
    refetch: refetchMaterialCategories,
  } = useGetMaterialCategoriesQuery({
    limit,
    offset,
    expand: expandMaterialCategoriesWithSuppliersAndUnit,
    name__icontains: nameContains,
  });

  const refetchMaterialCategoriesHandler = () => {
    refetchMaterialCategories();
  };

  const deleteMaterialCategoryHandler = async (
    record: MaterialCategoriesItem
  ) => {
    try {
      await deleteMaterialCategory(record).unwrap();
      api.success({
        message: t("material-category-management.success-title"),
        description: t("material-category-management.success-delete-desc"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-category-management.error-title")
      );

      api.error({
        message: t("material-category-management.error-title"),
        description,
      });
    }
  };

  const showDeleteCategoryModal = useConfirmDeleteModal(
    t("material-category-management.delete-modal-msg"),
    deleteMaterialCategoryHandler
  );

  const editMaterialHandler = (record: MaterialCategoriesItem) => {
    showEditMaterialCategoryModal();
    setValues({
      id: record?.id,
      name: record?.name,
      unit: (record?.unit as ExpandUnitItem)?.id,
      sections: record?.config_dynamic_tracker_fields || [],
      unique_code: record.unique_code!,
      lock_material_consume_variant: record?.lock_material_consume_variant!,
      default_material_consume_variant:
        record?.default_material_consume_variant!,
      export_col_for_quantity: record?.export_col_for_quantity!,
      export_col_for_serial_number: record?.export_col_for_serial_number!,
      suppliers: (record?.suppliers as number[]) || [],
    });
  };

  const [categoryToShowSuppliers, setCategoryToShowSuppliers] =
    useState<null | MaterialCategoriesItem>(null);

  const handleSetCategoryToShowSuppliers = (
    record: MaterialCategoriesItem | null
  ) => {
    setCategoryToShowSuppliers(record);
  };

  const [isVisibleChildrenCompanies, showChildCompanies, hideChildCompanies] =
    useModal();

  const handleCategorySuppliersModal = (record: MaterialCategoriesItem) => {
    showChildCompanies();
    handleSetCategoryToShowSuppliers(record);
  };

  const hideChildModalAndClearCompany = () => {
    hideChildCompanies();
    handleSetCategoryToShowSuppliers(null);
  };

  // Added this useEffect to update information field in CategorySuppliersModal after successful PUT request
  useEffect(() => {
    if (allMaterialCategories?.results) {
      updateMaterialCategoryToSetNewInformationField(
        allMaterialCategories ?? [],
        categoryToShowSuppliers,
        handleSetCategoryToShowSuppliers
      );
    }
  }, [allMaterialCategories]);

  const columns = useGetMaterialCategoryColumns({
    t,
    editMaterialHandler,
    showDeleteCategoryModal,
    nameContainsValue,
    handleNameContainsValue,
    clearNameContainsValue,
    handleCategorySuppliersModal,
  });

  return (
    <>
      {contextHolder}
      <Table
        loading={isFetchingMaterialCategories || isLoadingMaterialCategories}
        columns={columns}
        dataSource={allMaterialCategories?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: allMaterialCategories?.count,
        }}
      />

      <CategorySuppliersModal
        isOpen={isVisibleChildrenCompanies}
        onClose={hideChildModalAndClearCompany}
        categoryToShowSuppliers={categoryToShowSuppliers}
        refetchMaterialCategoriesHandler={refetchMaterialCategoriesHandler}
      />
    </>
  );
};

export { MaterialsCategoryTable };
