import { Modal } from "antd";
import React, { FC, useMemo } from "react";
import { Colors } from "shared/themes";
import { adaptMachinesToGroup } from "shared/adapters";
import { OrderPlan, useGetAllMachinesWithNoParamsQuery } from "shared/api";
import { ModalSizeValue } from "shared/constants";
import { TimelineScheduler } from "./TimelineScheduler";

interface PlannerViewModalProps {
  isOpenModal: boolean;
  setPlannerView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrderPlan: OrderPlan | null;
}

const PlannerViewModal: FC<PlannerViewModalProps> = ({
  isOpenModal,
  setPlannerView,
  selectedOrderPlan,
}) => {
  const { data: machines, isLoading } = useGetAllMachinesWithNoParamsQuery();

  const groups = useMemo(() => {
    return adaptMachinesToGroup(machines || []);
  }, [machines]);

  return (
    <Modal
      title="Planner view"
      open={isOpenModal}
      width={ModalSizeValue.FULL}
      className="top-0"
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
        },
      }}
      onCancel={() => {
        setPlannerView(false);
      }}
      onOk={() => {}}
    >
      {!isLoading && (
        <TimelineScheduler
          groups={groups}
          selectedOrderPlan={selectedOrderPlan}
        />
      )}
    </Modal>
  );
};

export { PlannerViewModal };
