import { Divider, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { ExecuteReportValues, AdaptedValues } from "shared/types";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptPackagesData } from "shared/adapters";
import { useGetAllPackagesWithoutParamsQuery } from "shared/api";
import { Colors } from "shared/themes";
import { FormSelect, Loader } from "shared/ui";
import { selectFiltering } from "shared/helpers";

interface PackagingFilterProps {
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
}

const PackagingFilter: FC<PackagingFilterProps> = ({
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values, setFieldValue, handleBlur } =
    useFormikContext<ExecuteReportValues>();

  const {
    data: packages,
    isLoading,
    isFetching,
  } = useGetAllPackagesWithoutParamsQuery({
    order: selectedOrderId ?? undefined,
  });

  const adaptedPackages = useMemo(() => {
    return adaptPackagesData(packages ?? []);
  }, [packages]);

  if (isLoading || isFetching) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />
      <div className="flex gap-x-3">
        <div className="flex gap-x-2">
          <Title level={5} className="!w-[100px] !m-0">
            {t("selectOrder")}
          </Title>
          {" - "}
          <Select
            value={selectedOrderId}
            placeholder={t("selectOrder")}
            options={adaptedOrders}
            onChange={onOrderChange}
            className="w-full"
            size="small"
            showSearch
            filterOption={(input, option) => selectFiltering(input, option)}
          />
        </div>

        {selectedOrderId && (
          <>
            <Divider
              type="vertical"
              className="h-full"
              style={{ background: Colors.blue.primary }}
            />

            <div className="flex gap-x-2">
              <Title level={5} className="!w-[140px]  !m-0">
                {t("selectPackage")}
              </Title>
              {" - "}
              <FormSelect
                value={values?.packaging_id}
                placeholder={t("selectPackage")}
                fieldName="packaging_id"
                options={adaptedPackages}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                showSearch
                size="small"
                className="!m-0"
              />
            </div>
          </>
        )}
      </div>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />
    </>
  );
};

export { PackagingFilter };
