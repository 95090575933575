import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { getProcessStepDndValues, openHyperlinkInTab } from "shared/helpers";
import { PopConfirmButton } from "shared/ui";
import { Routes } from "shared/routers";
import { useTranslation } from "react-i18next";
import { processStepsColumnsData } from "shared/constants";
import { ProcessStepItem } from "shared/api";
import { AddProductCardValues } from "../ProductCards";

interface ProcessStepsTableProps {
  processSteps: ProcessStepItem[] | undefined;
  setReorderingIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const ProcessStepsTable: FC<ProcessStepsTableProps> = ({
  processSteps,
  setReorderingIds,
}) => {
  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
  }

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AddProductCardValues>();

  const adaptedProcessStepsForTheTable = useMemo(() => {
    return getProcessStepDndValues(values, processSteps);
  }, [processSteps, values]);

  const [dataSource, setDataSource] = useState<ProcessStepItem[]>(
    adaptedProcessStepsForTheTable || []
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const removeProcessStep = (processStep: ProcessStepItem) => {
    const filtered = dataSource.filter(
      (item) => item.name !== processStep.name
    );

    setDataSource(filtered);

    setFieldValue(
      "process_steps",
      filtered.map((item) => item.id)
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const columns: ColumnsType<ProcessStepItem> = [
    {
      key: processStepsColumnsData.key.key,
    },
    {
      title: t(processStepsColumnsData.name.title),
      width: processStepsColumnsData.name.width,
      render: (item: ProcessStepItem) => {
        const processStepHyperlink =
          `${Routes.processSteps.hyperlink}/${item?.id}`.replace(":id/", "");

        return (
          <PopConfirmButton
            buttonContent={item?.name}
            className=""
            onClick={() => openHyperlinkInTab(processStepHyperlink)}
            popupClassName=""
            redirect={() =>
              openHyperlinkInTab(`${Routes.processSteps.url}/${item.id}`)
            }
          />
        );
      },
    },

    {
      title: t(processStepsColumnsData.actions.title),
      key: processStepsColumnsData.actions.key,
      width: processStepsColumnsData.actions.width,
      render: (record: ProcessStepItem) => (
        <DeleteOutlined
          className="btn-remove"
          onClick={() => {
            removeProcessStep(record);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setDataSource(adaptedProcessStepsForTheTable || []);
  }, [values]);

  useEffect(() => {
    const processStepsIDS = dataSource.map((item) => item.id);
    setReorderingIds(processStepsIDS);
  }, [dataSource]);

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
          columns={columns}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  );
};

export { ProcessStepsTable };
