import { FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "shared/helpers";
import { Loader, Page } from "shared/ui";
import { useExecuteReportsData, useExecuteReportsRequests } from "shared/hooks";
import * as Yup from "yup";
import {
  clearFormikValueOnReportChange,
  clearFormikValuesOnOrderChange,
} from "shared/adapters";
import { initialExecuteReportValues } from "shared/constants";
import { ExecuteReportValues } from "shared/types";
import {
  EmptyReportsMessage,
  ExecuteReportForm,
  PdfReport,
  ReportsSidebar,
} from "./ui";

interface ExecuteReportProps {}

const ExecuteReport: FC<ExecuteReportProps> = () => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [selectedMaterialId, setSelectedMaterialId] = useState<number | null>(
    null
  );

  const [validationSchema, setValidationSchema] =
    useState<Yup.AnyObjectSchema>();

  const onOrderChange = (value: number) => {
    setSelectedOrderId(value);
  };

  const handleOrderChange = (value: number | null) => {
    setSelectedOrderId(value);
  };

  const onMaterialChange = (value: number | null) => {
    setSelectedMaterialId(value);
  };

  const reportsFormik = useFormik<ExecuteReportValues>({
    initialValues: initialExecuteReportValues,
    validationSchema,
    onSubmit: noop,
  });

  const { values, setFieldValue, isValid } = reportsFormik;

  const {
    selectedReport,
    groupedQuests,
    isLoadingReports,
    selectedFilter: {
      isMaterialItem,
      isPackaging,
      isMultiplePackaging,
      isOrderType,
      isMultipleOrderType,
      isNotSupportedYetType,
      isReelLabResultType,
      isMultipleQualityType,
      isMultipleItemType,
    },
    adaptedOrders,
    conditionSchemaMap,
    isEmptyReportsResponse,
  } = useExecuteReportsData({
    values,
  });

  const { executeReports, getReportData, contextHolder, pdfUrl, clearPdfUrl } =
    useExecuteReportsRequests({
      values,
      selectedReport,
    });

  useMemo(() => {
    const matchedSchema = conditionSchemaMap.find(
      (item) => item.condition
    )?.schema;

    if (matchedSchema) {
      setValidationSchema(matchedSchema);
    }

    return null;
  }, [
    isMaterialItem,
    isPackaging,
    isMultiplePackaging,
    isOrderType,
    isMultipleOrderType,
    isReelLabResultType,
    isMultipleQualityType,
    isMultipleItemType,
  ]);

  //  Clear filters values when report (and report type) is changed
  //  Prevent useless values for export request
  useEffect(() => {
    if (values?.id) {
      clearFormikValueOnReportChange(setFieldValue, handleOrderChange);
    }
  }, [values?.id]);

  //  Clear filters values when selectedOrderId  is changed
  //  Prevent useless values for export request
  useEffect(() => {
    clearFormikValuesOnOrderChange(setFieldValue, onMaterialChange);
  }, [selectedOrderId]);

  if (isLoadingReports) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={reportsFormik}>
        <Page title={t("title")}>
          {isEmptyReportsResponse ? (
            <EmptyReportsMessage />
          ) : (
            <div className="w-full flex gap-x-5">
              <ReportsSidebar
                groupedQuests={groupedQuests}
                pdfUrl={pdfUrl}
                clearPdfUrl={clearPdfUrl}
              />

              <div className="flex flex-col gap-y-2 w-full">
                <ExecuteReportForm
                  isMaterialItem={isMaterialItem}
                  isNotSupportedYetType={isNotSupportedYetType}
                  adaptedOrders={adaptedOrders}
                  selectedOrderId={selectedOrderId}
                  onOrderChange={onOrderChange}
                  isPackaging={isPackaging}
                  isMultiplePackaging={isMultiplePackaging}
                  isOrderType={isOrderType}
                  isMultipleOrderType={isMultipleOrderType}
                  executeReports={executeReports}
                  isValid={isValid}
                  validationSchema={validationSchema}
                  getReportData={getReportData}
                  selectedReport={selectedReport}
                  pdfUrl={pdfUrl}
                  clearPdfUrl={clearPdfUrl}
                  isReelLabResultType={isReelLabResultType}
                  selectedMaterialId={selectedMaterialId}
                  onMaterialChange={onMaterialChange}
                  isMultipleQualityType={isMultipleQualityType}
                  isMultipleItemType={isMultipleItemType}
                />

                <PdfReport pdfUrl={pdfUrl} />
              </div>
            </div>
          )}
        </Page>
      </FormikProvider>
    </>
  );
};

export { ExecuteReport };
