import { Image } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { OrderItem } from "shared/api";

interface OrderProcessStepDetailsProps {
  order: OrderItem;
}

const OrderProcessStepDetails: FC<OrderProcessStepDetailsProps> = ({
  order,
}) => {
  return (
    <div className="min-w-[180px] h-full flex flex-wrap gap-y-1">
      {order?.process_step_trackers?.map((tracker, index) => {
        const processTemplate = tracker.process_step?.process_template;

        const name = processTemplate?.name;
        const abbreviation = processTemplate?.abbreviation;
        const image = processTemplate?.image;

        return (
          <div key={tracker.id} className="w-full flex items-center gap-x-1">
            {image ? (
              <Image src={image} height={15} width={15} />
            ) : (
              <Title level={5} className="!text-[12px] !m-0 !">
                {abbreviation ? `${{ abbreviation }}-` : null}
              </Title>
            )}

            <Link
              to={`/process-step-production/${order?.order_code}/index-${index}`}
            >
              <Title level={5} className="!m-0 !font-normal !text-[12px]">
                {name}
              </Title>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export { OrderProcessStepDetails };
