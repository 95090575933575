import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { templateConfigsColumnsData } from "shared/constants";
import { ReportItem } from "shared/api";
import { renderIsDefaultString, renderReportType } from "shared/helpers";
import { TableButton } from "shared/ui";

const { name, templateId, reportType, isDefault, actions } =
  templateConfigsColumnsData;

interface UseTableConfigColumnsProps {
  t: TFunction;
  handleEditReport: (record: ReportItem) => void;
  showDeleteReportModal: (record: ReportItem) => void;
}

export const useTableConfigColumns = ({
  t,
  handleEditReport,
  showDeleteReportModal,
}: UseTableConfigColumnsProps): ColumnsType<ReportItem> => [
  {
    title: t(name.title),
    render: (report: ReportItem) => (
      <Button
        onClick={() => handleEditReport(report)}
        className="border-none hover:border-none bg-transparent shadow-none"
      >
        <span className="underline"> {report?.name}</span>
      </Button>
    ),
  },
  {
    title: t(templateId.title),
    render: (report: ReportItem) => report?.template_id,
  },
  {
    title: t(reportType.title),
    render: (report: ReportItem) => renderReportType(report),
  },
  {
    title: t(isDefault.title),
    render: (report: ReportItem) => renderIsDefaultString(report),
  },
  {
    title: t(actions.title),
    render: (report: ReportItem) => (
      <TableButton
        type="delete"
        onClick={() => showDeleteReportModal(report)}
      />
    ),
  },
];
