export const expandQualityResultsString =
  "material_items.material_item.material_stock_batch.material,material_stock_batches.material_stock_batch.material,,material_stock_batches.material_stock_batch.supplier";

export const qualityResultsColumn = {
  name: {
    title: "qualityTableColumns.name",
    width: "20%",
  },
  date: {
    title: "qualityTableColumns.date",
    width: "10%",
  },
  type: {
    title: "qualityTableColumns.type",
    width: "10%",
  },
  batchCode: {
    title: "qualityTableColumns.bCode",
    width: "15%",
  },
  serialNumber: {
    title: "qualityTableColumns.serialNumber",
    width: "15%",
  },
  suppliers: {
    title: "qualityTableColumns.suppliers",
    width: "15%",
  },
  document: {
    title: "qualityTableColumns.document",
    width: "20%",
  },
  actions: {
    title: "qualityTableColumns.actions",
    width: "5%",
  },
};

export const qualityResultsTypeValues = [
  { value: "FILE_UPLOAD", label: "File Upload" },
  { value: "REEL_LAB_RESULT", label: "Reel Lab Result" },
];

export const stockBatchesColumn = {
  name: {
    title: "stockBatchesColumn.name",
    width: "20%",
  },
  suppliers: {
    title: "stockBatchesColumn.suppliers",
    width: "20%",
  },
  batchCode: {
    title: "stockBatchesColumn.bCode",
    width: "20%",
  },
};

export const expandStockBatchString = "material,supplier";

export const materialItemsColumn = {
  name: {
    title: "materialItemsColumn.name",
    width: "40%",
  },
  timestamp: {
    title: "materialItemsColumn.timestamp",
    width: "20%",
  },
  serialNumber: {
    title: "materialItemsColumn.serialNumber",
    width: "40%",
  },
};

export const expandMaterialItemsString =
  "material_stock_batch.material.material_category.unit";

export const initialUploadDocumentValues = {
  id: null,
  document: null,
  material: "",
  order: "",
  name: null,
  result_type: null,
  material_items: [],
  material_stock_batches: [],
  reel_lab_measurements: [],
  thickness: null,
  curl_md: null,
  curl_cd: null,
  remark: null,
};

export const extraDataColumns = {
  number: {
    title: "extraDataColumns.number",
    width: "20%",
  },
  tensileMd: {
    title: "extraDataColumns.tensileMD",
    width: "20%",
  },
  tensileCd: {
    title: "extraDataColumns.tensileCD",
    width: "20%",
  },
  elongationMd: {
    title: "extraDataColumns.elongationMD",
    width: "20%",
  },
  elongationCd: {
    title: "extraDataColumns.elongationCD",
    width: "20%",
  },
};

export const enum QualityOrdering {
  NAME_ASC = "name",
  NAME_DESC = "-name",
  TIMESTAMP_ASC = "timestamp",
  TIMESTAMP_DESC = "-timestamp",
}

export const enum StockBatchOrdering {
  SUPPLIER_ASC = "supplier__name",
  SUPPLIER_DESC = "-supplier__name",
  BATCH_CODE_ASC = "batch_code",
  BATCH_CODE_DESC = "-batch_code",
}

export const enum MaterialItemsOrdering {
  SERIAL_NUMBER_ASC = "serial_number",
  SERIAL_NUMBER_DESC = "-serial_number",
  TIMESTAMP_ASC = "timestamp",
  TIMESTAMP_DESC = "-timestamp",
}
