import { Alert, Button } from "antd";
import classNames from "classnames";
import React, { FC, useRef } from "react";
import { useModal, useOutsideClick } from "shared/hooks";

interface PopConfirmButtonProps {
  onClick?: () => void;
  buttonContent: string;
  className?: string;
  redirect?: () => void;
  popupClassName?: string;
}

const PopConfirmButton: FC<PopConfirmButtonProps> = ({
  onClick,
  buttonContent,
  className,
  redirect,
  popupClassName,
}) => {
  const ref = useRef(null);

  const [showPopConfirm, openPopConfirm, closePopConfirm] = useModal();

  useOutsideClick(ref, closePopConfirm);

  const onContextMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    openPopConfirm();
  };

  const redirectAndClosePopup = () => {
    closePopConfirm();
    redirect?.();
  };

  return (
    <div ref={ref}>
      <button
        type="button"
        onContextMenu={onContextMenuClick}
        onClick={onClick}
        className={classNames(
          "text-left text-blue-500 hover:underline !text-[12px]",
          {},
          className
        )}
      >
        {buttonContent}
      </button>

      {showPopConfirm && (
        <div
          className={classNames(
            `
            w-[350px] h-[100px] bg-white 
            absolute top-0 -right-[100px] !z-50
            rounded-md shadow-2xl p-2 flex flex-col
            justify-between items-center
          `,
            {},
            popupClassName
          )}
        >
          <Alert
            showIcon
            type="info"
            message="Open this link in the the new tab?"
          />

          <div className="w-full flex items-center justify-around ">
            <Button
              danger
              className="
                w-[40%] hover:bg-red-200 hover:!border-[1px] 
                hover:!border-red-100 hover:!text-white
              "
              onClick={closePopConfirm}
            >
              No
            </Button>

            <Button
              className="
                w-[40%] hover:bg-blue-400 hover:!border-[1px] 
                hover:!border-blue-primary hover:!text-white
              "
              onClick={redirectAndClosePopup}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { PopConfirmButton };
