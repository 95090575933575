import { CloseCircleFilled } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterialCategories } from "shared/adapters";
import { MaterialCategoriesItem, Widget } from "shared/api";
import {
  getConfigurationValues,
  getDynamicOptionsFromMaterialCategory,
} from "shared/helpers";
import { FormSelect, Loader } from "shared/ui";

interface ProductionDataTrackerConfigurationProps {
  materialCategories: {
    materialCategories: MaterialCategoriesItem[] | undefined;
    isLoadingMaterialCategories: boolean;
  };
}

const ProductionDataTrackerConfiguration: FC<
  ProductionDataTrackerConfigurationProps
> = ({ materialCategories }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, handleBlur } = useFormikContext<Widget>();

  const {
    materialCategoryValue,
    materialCategoryField,
    dynamicFieldValue,
    dynamicFieldField,
  } = getConfigurationValues(values);

  const clearDynamicFieldValue = () => {
    setFieldValue(dynamicFieldField, undefined);
  };

  const adaptedMaterialCategories = useMemo(() => {
    return adaptMaterialCategories(materialCategories.materialCategories ?? []);
  }, [materialCategories]);

  const dynamicMaterialCategoriesOptions = useMemo(() => {
    return getDynamicOptionsFromMaterialCategory(
      materialCategories.materialCategories ?? [],
      materialCategoryValue
    );
  }, [materialCategories, materialCategoryValue]);

  if (materialCategories.isLoadingMaterialCategories) {
    return <Loader />;
  }

  return (
    <div className="bg-white w-full self-start p-1">
      <div>
        <Title level={5}>{t("widgets.materialCategory")}</Title>
        <FormSelect
          value={materialCategoryValue || null}
          placeholder={t("widgets.selectMaterialCategory")}
          fieldName={materialCategoryField}
          options={adaptedMaterialCategories}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          disabled={!!dynamicFieldValue}
        />
      </div>

      {materialCategoryValue ? (
        <>
          <Title level={5}>{t("widgets.dynamicField")}</Title>

          <div className="w-full flex gap-x-3 item-center justify-center">
            <FormSelect
              value={dynamicFieldValue || null}
              placeholder={t("widgets.selectField")}
              fieldName={dynamicFieldField}
              options={dynamicMaterialCategoriesOptions}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />
            {dynamicFieldValue && (
              <CloseCircleFilled
                onClick={clearDynamicFieldValue}
                className="scale-125 mt-4"
              />
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const ProductionDataTrackerConfigurationMemo = React.memo(
  ProductionDataTrackerConfiguration
);

export { ProductionDataTrackerConfigurationMemo as ProductionDataTrackerConfiguration };
