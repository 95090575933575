import { Dashboard } from "shared/api";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { addDashboardKey } from "../global";

export const getDashboardChildren = (dashboards: Dashboard[]) => {
  const addButtonElement = {
    label: "Add Dashboard",
    style: {
      width: "99%",
      background: Colors.blue[100],
    },
    key: addDashboardKey,
  };

  const dashboardElements = dashboards.map((dashboard) => ({
    label: dashboard.name,
    key: `${Routes.dashboards.url.replace(":id", "")}${dashboard.id}`,
    style: {
      width: "99%",
    },
  }));

  return [addButtonElement, ...dashboardElements];
};
