import {
  MaterialCategoriesItem,
  MaterialStockItem,
  Widget,
  WidgetTableData,
  WidgetTypeEnum,
} from "shared/api";
import { widgetsIcons } from "shared/assets";

export const getConfigurationValues = (values: Widget) => {
  return {
    materialCategoryValue: Number(
      values?.configuration?.[0]?.configuration_parameter
    ),
    materialCategoryField: "configuration.[0].configuration_parameter",

    dynamicFieldValue: values?.configuration?.[0]?.configuration_value,
    dynamicFieldField: "configuration.[0].configuration_value",
  };
};

export const getConfigurationsColors = (values: Widget) => {
  return {
    redColorParameterField: "configuration.[1].configuration_parameter",
    redColorValueField: "configuration.[1].configuration_value",
    redColorValue: values?.configuration?.[1]?.configuration_value,

    yellowColorParameterField: "configuration.[2].configuration_parameter",
    yellowColorValueField: "configuration.[2].configuration_value",
    yellowColorValue: values?.configuration?.[2]?.configuration_value,
  };
};

export const getDynamicOptionsFromMaterialCategory = (
  materialCategories: MaterialCategoriesItem[],
  materialCategoryValue: number
) => {
  return materialCategories?.reduce((acc, category) => {
    if (category.id === (materialCategoryValue as unknown as number)) {
      category.config_dynamic_tracker_fields?.forEach((section) => {
        section.fields.forEach((field) => {
          acc.push({ value: field.name, label: field.name });
        });
      });
    }
    return acc;
  }, [] as { value: string; label: string }[]);
};

export const enum ProductionDataTrackerFields {
  ICON = "icon",
  WIDGET_TYPE = "PRODUCTION_DATA_TRACKER",
  PRODUCTION_TRACKING_TIMESTAMP = "productionTrackingTimeStamp",
  PRODUCTION_TRACKING_SERIAL_NUMBER = "productionTrackingSerialNumber",
  PRODUCTION_TRACKING_PROPERTY = "productionTrackingProperty",
  PRODUCTION_TRACKING_VALUE = "productionTrackingValue",
}

export const productionTrackerColumnsValues = [
  { value: "productionTrackingTimeStamp", label: "Timestamp" },
  { value: "productionTrackingSerialNumber", label: "Serial Number" },
  { value: "productionTrackingProperty", label: "Property" },
  { value: "productionTrackingValue", label: "Value" },
];

function findField(obj: Record<string, any>, dynamicName: string): any {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    if (key.toLowerCase() === dynamicName.toLowerCase()) {
      return obj[key];
    }
    if (typeof obj[key] === "object" && obj[key] !== null) {
      const result = findField(obj[key], dynamicName);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export const adaptProductionTrackingData = (
  dynamicName: string,
  materialCategories?: MaterialCategoriesItem[],
  data?: MaterialStockItem[]
) => {
  const dynamicValueAndHardSoftValues = materialCategories
    ?.flatMap((category) =>
      category?.config_dynamic_tracker_fields?.flatMap((section) =>
        section?.fields?.filter((field) => field?.name === dynamicName)
      )
    )
    .filter(Boolean);

  return data?.map((item) => {
    const result = findField(item?.dynamic_tracker_fields, dynamicName);

    return {
      type: WidgetTypeEnum.PRODUCTION_DATA_TRACKER,

      icon: true,

      productionTrackingTimeStamp: item?.timestamp,

      productionTrackingSerialNumber: item?.serial_number,

      productionTrackingArticleId: item?.id,

      productionTrackingProperty: dynamicName,

      productionTrackingValue: result,

      productionTrackingHardSortValues: {
        hardMaximum: dynamicValueAndHardSoftValues?.[0]?.hard_maximum,
        hardMinimum: dynamicValueAndHardSoftValues?.[0]?.hard_minimum,
        softMaximum: dynamicValueAndHardSoftValues?.[0]?.soft_maximum,
        softMinimum: dynamicValueAndHardSoftValues?.[0]?.soft_minimum,
      },
    };
  });
};

interface HardSoftValues {
  hardMaximum: number;
  hardMinimum: number;
  softMaximum: number;
  softMinimum: number;
}

export const getHardAndSoftValues = (record: WidgetTableData) => {
  return {
    hardMaximum: (
      record?.productionTrackingHardSortValues as unknown as HardSoftValues
    )?.hardMaximum,

    hardMinimum: (
      record?.productionTrackingHardSortValues as unknown as HardSoftValues
    )?.hardMinimum,

    softMaximum: (
      record?.productionTrackingHardSortValues as unknown as HardSoftValues
    )?.softMaximum,

    softMinimum: (
      record?.productionTrackingHardSortValues as unknown as HardSoftValues
    )?.softMinimum,
  };
};

export const getQualityResultIcon = (
  isOutsideHardLimits: boolean,
  isInsideLimits: boolean
) => {
  if (isOutsideHardLimits) return widgetsIcons.qualityRed;
  if (isInsideLimits) return widgetsIcons.qualityGreen;

  return undefined;
};

export const getStandAloneWidgetConfigurationValues = (values: Widget) => {
  return {
    influxDbParameterValue: values?.configuration?.[0]?.configuration_parameter,
    influxDbParameterFieldName: "configuration.[0].configuration_parameter",

    influxDbValue: values?.configuration?.[0]?.configuration_value,
    influxDbValueFieldName: "configuration.[0].configuration_value",

    queryParameterValue: values?.configuration?.[1]?.configuration_parameter,
    queryParameterFieldName: "configuration.[1].configuration_parameter",

    queryDbValue: values?.configuration?.[1]?.configuration_value,
    queryDbValueFieldName: "configuration.[1].configuration_value",
  };
};
