export const Colors = {
  gray: {
    primary: "#63666a",
    20: "#636633",
    100: "#E0E0E0",
    200: "#E0E0E0",
    300: "#ededed",
    400: "#9E9E9E",
  },
  red: {
    100: "#ff3333",
  },
  black: {
    100: "#242424",
  },
  blue: {
    primary: "#002f5f",
    100: "#61AFFE",
  },
  green: {
    100: "#49CB90",
    300: "#1AAE9F",
  },
  white: {
    100: "#ffffff",
  },
};
