import { Input } from "antd";
import Title from "antd/es/typography/Title";
import { FormikErrors } from "formik";
import React, { FC } from "react";
import {
  DynamicOutputMaterialData,
  ProductionOutputMaterial,
} from "shared/api";

interface UpdateOutputMaterialQuantityProps {
  materials: DynamicOutputMaterialData[];
  currentPage: number;
  unitName?: string;
  index: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          output_materials: ProductionOutputMaterial[];
        }>
      >;
}

const UpdateOutputMaterialQuantity: FC<UpdateOutputMaterialQuantityProps> = ({
  materials,
  currentPage,
  setFieldValue,
  unitName,
  index,
}) => {
  const value = materials?.[currentPage]?.quantity;
  const fieldName = `output_materials.${index}.dynamicData.${currentPage}.quantity`;

  return (
    <div>
      <Title level={5}>{unitName}</Title>
      <Input
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue(fieldName, Number(e?.target?.value))
        }
        type="number"
        min={1}
      />
    </div>
  );
};

export { UpdateOutputMaterialQuantity };
