import classNames from "classnames";
import React, { FC } from "react";

interface OrderStatusProgressProps {
  calcProgress: number | null;
}

const OrderStatusProgress: FC<OrderStatusProgressProps> = ({
  calcProgress,
}) => {
  if (calcProgress === null) {
    return (
      <div className="w-full flex items-center justify-center rounded-xl bg-transparent">
        N/A
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "w-auto flex items-center justify-center rounded-xl h-8 p-1",
        {
          "bg-green-100 text-white": calcProgress >= 95 && calcProgress <= 105,
          "bg-yellow-500 text-white":
            (calcProgress >= 85 && calcProgress < 95) ||
            (calcProgress > 105 && calcProgress <= 115),
          "bg-red-100 text-white": calcProgress < 85 || calcProgress > 115,
          "!bg-transparent": calcProgress === null,
        }
      )}
    >
      {typeof calcProgress === "number" && `${calcProgress.toFixed(1)}% `}
    </div>
  );
};

export { OrderStatusProgress };
