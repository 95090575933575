import React from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import dayjs from "dayjs";
import classNames from "classnames";
import { useWindowDimensions } from "shared/hooks";
import { adaptDurationString } from "shared/adapters";
import { ScheduleItem } from "shared/types";

const CustomTimelineItem = ({
  item,
  getItemProps,
}: ReactCalendarItemRendererProps<ScheduleItem>) => {
  const { isTablet } = useWindowDimensions();

  const start_time = dayjs(item.start_time);
  const end_time = dayjs(item.end_time);
  const timeDifference = end_time.diff(start_time, "seconds");

  const adaptedDuration = adaptDurationString(timeDifference);

  return (
    <div {...getItemProps(item)} className={item.className}>
      <div
        className={classNames(
          "h-[75%] w-full flex items-start justify-center text-center",
          {}
        )}
      >
        {item.title}
      </div>
      <div
        className={classNames(
          "h-[25%]  w-full flex items-end justify-center text-center text-[10px]",
          { "!text-[7px] !px-[1px]": isTablet }
        )}
      >
        {adaptedDuration}
      </div>
    </div>
  );
};

export { CustomTimelineItem };
