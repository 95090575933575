import { Button } from "antd";
import React, { FC } from "react";

interface MasterCardOverlayProps {
  handleNext: () => void;
}

const MasterCardOverlay: FC<MasterCardOverlayProps> = ({ handleNext }) => {
  return (
    <div className="absolute inset-0 bg-gray-700 bg-opacity-50 flex flex-col items-center justify-center z-10">
      <div className="text-white text-lg">
        This is the master card. Editing is disabled.
      </div>

      <Button
        onClick={handleNext}
        type="primary"
        className="btn-primary--dark mt-3"
      >
        Go to item#:2
      </Button>
    </div>
  );
};

export { MasterCardOverlay };
