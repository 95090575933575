import { useState } from "react";
import { useDebouncedTableFilter } from "./useDebouncedTableFilter";

export const useProcessStepTableFilters = () => {
  const {
    debouncedValue: debouncedNameContains,
    handleFilterChange: handleNameContainsValue,
    value: nameContainsValue,
    clearFilterValue: clearNameContainsValue,
  } = useDebouncedTableFilter("", 400);

  const [processTemplateFilter, setProcessTemplateFilter] = useState<
    number | string
  >("");

  const handleProcessTemplateFilter = (value: number | string) => {
    setProcessTemplateFilter(value);
  };

  const isVisibleClearFiltersButton =
    !!debouncedNameContains || processTemplateFilter;

  const clearProcessTemplateFilter = () => {
    setProcessTemplateFilter("");
  };

  const clearAllFilters = () => {
    clearNameContainsValue();
    clearProcessTemplateFilter();
  };

  return {
    debouncedNameContains,
    handleNameContainsValue,
    nameContainsValue,
    clearNameContainsValue,
    processTemplateFilter,
    setProcessTemplateFilter,
    clearProcessTemplateFilter,
    clearAllFilters,
    isVisibleClearFiltersButton,
    handleProcessTemplateFilter,
  };
};
