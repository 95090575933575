/* eslint-disable no-plusplus */
import React, { FC } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { DynamicNumberInput } from "shared/features/DynamicFormsComponents/DynamicIntegerInput";
import { renderDynamicInputType } from "shared/helpers";
import { AddProcessStepValues } from "shared/types";
import { DynamicFieldsEnum } from "shared/constants";
import { ProcessTemplateItem } from "../../../../shared/api/productCard/models";
import {
  DynamicTextTypeInput,
  DynamicSelectInput,
} from "../../../../shared/features";

interface ProcessStepDynamicFieldsProps {
  dynamicProcessTemplateFields?: ProcessTemplateItem;
}

const ProcessStepDynamicFields: FC<ProcessStepDynamicFieldsProps> = ({
  dynamicProcessTemplateFields,
}) => {
  const { getFieldProps } = useFormikContext<AddProcessStepValues>();

  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dynamicProcessTemplateFields?.config_dynamic_process_step_fields?.map(
        (card) => (
          <div
            key={card.title}
            className="w-full h-auto p-2.5 mb-2.5 rounded-xl shadow-dynamicFormCard"
          >
            <Title level={4}>{card?.title}</Title>
            <div>
              {card?.fields.map((field, fieldIndex: number) => {
                const cardFieldName = `dynamic_process_step_fields.${card.title}.${field.name}`;

                const { value } = getFieldProps(cardFieldName);

                return (
                  <div key={field?.name}>
                    {/* TO-DO temporary hide EXPRESSION Title. Backend do not have finished this type */}
                    {field.type === DynamicFieldsEnum.EXPRESSION ? null : (
                      <Title level={5} className="!text-primary">
                        {field.name}. {t("orders.type")} -{" "}
                        {renderDynamicInputType(field?.type)}
                      </Title>
                    )}

                    {field.type === DynamicFieldsEnum.TEXT && (
                      <DynamicTextTypeInput
                        card={card}
                        fieldIndex={fieldIndex}
                        value={value}
                        fieldName={cardFieldName}
                      />
                    )}

                    {field.type === DynamicFieldsEnum.INTEGER && (
                      <DynamicNumberInput
                        card={card}
                        fieldIndex={fieldIndex}
                        value={value}
                        fieldName={cardFieldName}
                      />
                    )}

                    {field.type === DynamicFieldsEnum.FLOAT && (
                      <DynamicNumberInput
                        card={card}
                        fieldIndex={fieldIndex}
                        value={value}
                        fieldName={cardFieldName}
                      />
                    )}

                    {field.type === DynamicFieldsEnum.CHOICES && (
                      <DynamicSelectInput
                        card={card}
                        fieldIndex={fieldIndex}
                        value={value}
                        fieldName={cardFieldName}
                      />
                    )}

                    {/* TO-DO temporary null. Backend do not have finished this type */}
                    {field.type === DynamicFieldsEnum.EXPRESSION && null}
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}
    </>
  );
};

export { ProcessStepDynamicFields };
