import {
  MaterialStockItem,
  PackagingItem,
  ReportItem,
  ReportType,
} from "shared/api";

export const reportTypes: Record<ReportType, string> = {
  [ReportType.MATERIAL_ITEM]: "Material Item",
  [ReportType.PACKAGING]: "Packaging",
  [ReportType.REEL_LAB_ARCHIVE]: "Reel Lab Archive",
  [ReportType.ORDER]: "Order",
  [ReportType.MULTIPLE_ORDER]: "Multiple Order",
  [ReportType.MULTIPLE_PACKAGING]: "Multiple Packaging",
  [ReportType.MULTIPLE_MATERIAL]: "Multiple Material",
  [ReportType.MULTIPLE_QUALITY]: "Multiple Quality",
  [ReportType.MULTIPLE_MATERIAL_ITEM]: "Multiple Material Item",
};

export const renderReportType = (report: ReportItem) => {
  const status = reportTypes[report?.report_type as ReportType];
  return status || "Unknown Type";
};

export const renderIsDefaultString = (report: ReportItem) => {
  return report?.is_default ? "Default" : "Not Default";
};

export const renderReportTypeTitle = (reportType: string) => {
  const status = reportTypes[reportType as ReportType];
  return status || "Unknown Type";
};

export const getPackagingDataFromMateriaStockItem = (
  materialStockItem: MaterialStockItem
) => {
  const materialPackagingData = materialStockItem?.packaging as PackagingItem;
  const packagingMaterialId =
    materialPackagingData?.material as unknown as number;

  return {
    id: materialPackagingData?.id,
    name: materialPackagingData?.name,
    material: packagingMaterialId,
    order: materialPackagingData?.order,
  };
};
