import * as Yup from "yup";

export const materialItemFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  material_item_id: Yup.number().nullable().required(),
});

export const packagingFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  packaging_id: Yup.number().nullable().required(),
});

export const multiplePackagingFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  order_id: Yup.number().nullable().required(),
  material_id: Yup.number().nullable().required(),
});

export const orderFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  order_id: Yup.number().nullable().required(),
});

export const multipleOrderFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
});

export const reelLabValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  quality_result_id: Yup.number().nullable().required(),
});

export const multipleQualityValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  order_id: Yup.number().nullable().required(),
});

export const multipleItemFilterValidation = Yup.object().shape({
  id: Yup.number().nullable().required(),
  order_id: Yup.number().nullable().required(),
  material_id: Yup.number().nullable().required(),
});
