import { Button, Modal } from "antd";
import React, { FC, useState } from "react";
import { dayjs } from "shared/helpers";
import { useModal } from "shared/hooks";
import { OrderItem } from "shared/api";
import { ModalSize } from "shared/constants";
import { Colors } from "shared/themes";
import { DatePickerValue, GlobalDatePicker } from "../GlobalDatePicker";

interface TableOrdersDatepickerProps {
  value: string;
  onOk: (item: OrderItem, time: string) => Promise<void>;
  item: OrderItem;
  modalTitle: string;
  originalDate: string;
}

const TableOrdersDatepicker: FC<TableOrdersDatepickerProps> = ({
  value,
  onOk,
  item,
  modalTitle,
  originalDate,
}) => {
  const [isVisibleModal, showModal, hideModal] = useModal();
  const [date, setDate] = useState<DatePickerValue | string | null>(null);

  const timezone = localStorage.getItem("timezone") ?? "UTC";

  const showModalAndSetDate = () => {
    setDate(dayjs(value).format("YYYY-MM-DD HH:mm"));
    showModal();
  };

  const handleWantedDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setDate(dayjs(dateString).format("YYYY-MM-DD HH:mm"));
  };

  const confirmDateUpdate = () => {
    onOk(item, date as string);
    setDate(null);
    hideModal();
  };

  return (
    <>
      <Button
        onClick={showModalAndSetDate}
        className="border-none bg-transparent shadow-none text-[12px]"
      >
        {dayjs(originalDate).tz(timezone).format("DD/MM/YYYY HH:mm")}
        {/* {value} */}
      </Button>

      <Modal
        open={isVisibleModal}
        onCancel={hideModal}
        onOk={confirmDateUpdate}
        title={modalTitle}
        className={ModalSize.SMALL}
        okButtonProps={{ style: { background: Colors.blue.primary } }}
      >
        <div className="w-full p-2">
          <GlobalDatePicker
            handlePickerChange={handleWantedDateTimeChange}
            value={dayjs(date)}
            showTime
          />
        </div>
      </Modal>
    </>
  );
};

export { TableOrdersDatepicker };
