/* eslint-disable @typescript-eslint/naming-convention */
import { Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
  Loader,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  useAddMaterialStockBatchMutation,
  MaterialsItem,
  StockMaterialItem,
} from "shared/api";
import { ModalSizeValue, stockMaterialStatuses } from "shared/constants";
import { AdaptedValues } from "shared/types";

interface AddStockMaterialModalProps {
  isOpenAddStockMaterial: boolean;
  hideAddStockModal: () => void;
  selectedStockMaterial: MaterialsItem | null;
  companiesData: {
    companiesAdapted: AdaptedValues[];
    isLoading: boolean;
  };
}

const AddStockMaterialModal: FC<AddStockMaterialModalProps> = ({
  isOpenAddStockMaterial,
  hideAddStockModal,
  selectedStockMaterial,
  companiesData,
}) => {
  const { t } = useTranslation();

  const materialOptions = [
    {
      value: selectedStockMaterial?.id!,
      label: selectedStockMaterial?.name,
    },
  ];

  const {
    values,
    resetForm,
    isValid,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
  } = useFormikContext<StockMaterialItem>();

  const closeModal = () => {
    hideAddStockModal();
    resetForm();
    setFieldValue("material", null);
  };

  const handlePickerChange = (_: DatePickerValue, dateString: string) => {
    setFieldValue("delivery_date", dateString);
  };

  const [addMaterialStockBatch] = useAddMaterialStockBatchMutation();

  const [api, contextHolder] = notification.useNotification();

  const createStockMaterialItem = async () => {
    try {
      await addMaterialStockBatch({
        material: selectedStockMaterial?.id!,
        batch_code: values.batch_code,
        is_quantity_in_tracker_table: values.is_quantity_in_tracker_table!,
        quantity: values.quantity!,
        delivery_date: values.delivery_date,
        status: values.status!,
        supplier: values.supplier as number,
      }).unwrap();
      api.success({
        message: t("stock-material-modals.success-title"),
        description: t("stock-material-modals.success-update-desc"),
      });
      hideAddStockModal();
      resetForm();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("stock-material.error-title")
      );
      api.error({
        message: t("stock-material.error-title"),
        description,
      });
    }
  };

  useEffect(() => {
    setFieldValue("material", selectedStockMaterial?.id);
  }, [selectedStockMaterial]);

  if (companiesData.isLoading) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <>
      {contextHolder}

      <Modal
        title={t("stock-material.add-modal-title")}
        open={isOpenAddStockMaterial}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={() => {
          createStockMaterialItem();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.material")}
        </Title>

        <FormSelect
          value={selectedStockMaterial?.id!}
          placeholder={t("stock-material-modals.material-placeholder")}
          fieldName="material"
          options={materialOptions}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.material as string}
          isError={!!errors.material}
          disabled
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.batchNumber")}
        </Title>

        <FormInput
          value={values.batch_code}
          fieldName="batch_code"
          placeholder={t("stock-material-modals.batchCode-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.batch_code}
          isError={!!errors.batch_code}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.bulk-or-items")}
        </Title>

        <FormSelect
          value={values.is_quantity_in_tracker_table}
          placeholder={t("stock-material-modals.yes-or-no")}
          fieldName="is_quantity_in_tracker_table"
          options={[
            { value: true, label: t("stock-material-modals.items").toString() },
            { value: false, label: t("stock-material-modals.bulk").toString() },
          ]}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.supplier?.toString()}
          isError={!!errors.supplier}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.quantity")}
        </Title>

        <FormInput
          numberType
          value={values.is_quantity_in_tracker_table ? null : values.quantity}
          fieldName="quantity"
          placeholder={
            values.is_quantity_in_tracker_table
              ? "null"
              : t("stock-material-modals.quantity-placeholder")
          }
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.quantity}
          isError={!!errors.quantity}
          disabled={!!values.is_quantity_in_tracker_table}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.deliveryDate")}
        </Title>

        <GlobalDatePicker
          handlePickerChange={handlePickerChange}
          error={errors.delivery_date}
          isError={!!errors.delivery_date}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.status")}
        </Title>
        <FormSelect
          value={values.status}
          placeholder={t("stock-material-modals.status-placeholder")}
          fieldName="status"
          options={stockMaterialStatuses}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status}
          isError={!!errors.status}
        />

        <Title level={5} className="w-full mt-1.25 mb-0">
          {t("stock-material-modals.supplier")}
        </Title>

        <FormSelect
          value={values.supplier || []}
          placeholder={t("material-management.suppliers-placeholder")}
          fieldName="supplier"
          options={companiesData.companiesAdapted}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.supplier?.toString()}
          isError={!!errors.supplier}
          showSearch
        />
      </Modal>
    </>
  );
};

const AddStockMaterialModalMemo = React.memo(AddStockMaterialModal);

export { AddStockMaterialModalMemo as AddStockMaterialModal };
