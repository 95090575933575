import { Button, Divider, Input, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useCallback, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";
import { CloseOutlined } from "@ant-design/icons";
import { RenderClearFiltersButton } from "shared/ui";
import {
  calculateOffset,
  addThousandsSeparator,
  renderStockBatchStatuses,
} from "shared/helpers";
import { adaptStockBatchObjectToArray } from "shared/adapters";
import {
  MaterialConsumedItem,
  MaterialsItem,
  StockMaterialItem,
  useGetMaterialStockBatchesQuery,
  useGetMaterialStockBatchesWithoutPaginationQuery,
} from "shared/api";
import { useTableSorting } from "shared/hooks";
import { useTranslation } from "react-i18next";
import {
  sortDirections,
  stockMaterialTableColumnsData,
  MaterialItemsSorting,
  expandFilteredStockTable,
} from "shared/constants";
import { AddMaterialConsumedValues } from "shared/types";

interface FilteredStockBatchTableProps {
  selectedMaterial: MaterialsItem | null;
  edit?: boolean;
  dynamicItemFromTable?: MaterialConsumedItem | null;
}

const FilteredStockBatchTable: FC<FilteredStockBatchTableProps> = ({
  selectedMaterial,
  edit,
  dynamicItemFromTable,
}) => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const isFirstPage = currentPage === 1;
  const { setFieldValue } = useFormikContext<AddMaterialConsumedValues>();

  useEffect(() => {
    if (isFirstPage) {
      setOffset(0);
    } else {
      setOffset(calculateOffset(pageSize, currentPage));
    }
    setLimit(pageSize);
  }, [currentPage, pageSize]);

  const [batchCodeContains, setBatchCodeContains] = useState("");

  let externalBatchCodeReset: () => void;

  const handleBatchCodeContainsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBatchCodeContains(e.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleBatchCodeContainsChange, 400),
    []
  );

  const { ordering, changeSort } = useTableSorting();

  const {
    data: materialStockBatch,
    isLoading: isLoadingMaterialStockBatch,
    isFetching: isFetchingMaterialStockBatch,
  } = useGetMaterialStockBatchesQuery({
    material: selectedMaterial?.id,
    limit,
    offset,
    batch_code__icontains: batchCodeContains,
    ordering,
    expand: expandFilteredStockTable,
  });

  const { data: allMaterialStockBatch } =
    useGetMaterialStockBatchesWithoutPaginationQuery({
      batch_code__icontains: batchCodeContains,
      material: selectedMaterial?.id,
    });

  const selectedStockBatchFormTable = allMaterialStockBatch?.find(
    (item: any) =>
      item?.batch_code ===
      dynamicItemFromTable?.material_stock_batch?.batch_code
  );

  const arrayWithSelectedStockBatch = adaptStockBatchObjectToArray(
    selectedStockBatchFormTable
  );

  const rowMaterialStockItemSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: StockMaterialItem[]
    ) => {
      setFieldValue("material_stock_batch", selectedRows?.[0].id);
      setFieldValue("material", null);
      setFieldValue("material_item", null);
    },
    getCheckboxProps: (record: StockMaterialItem) => ({
      name: record.batch_code,
    }),
  };

  const {
    material,
    batchCode,
    deliveryDate,
    status,
    calcPercentageRemainingQuantity,
    calcTotalConsumedQuantity,
    calcTotalOriginalQuantity,
    calcTotalRemainingQuantity,
  } = stockMaterialTableColumnsData;

  const renderQuantityWithAbbreviation = (
    calc_total_original_quantity: number | null,
    calc_total_consumed_quantity: number | null,
    calc_total_remaining_quantity: number | null,
    selectedMaterial: MaterialsItem
  ) => {
    let message;

    if (calc_total_original_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_original_quantity)} - ${
        typeof selectedMaterial?.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_consumed_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_consumed_quantity)} - ${
        typeof selectedMaterial?.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_remaining_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_remaining_quantity)} - ${
        typeof selectedMaterial?.material_category.unit === "object"
          ? selectedMaterial.material_category.unit.abbreviation
          : ""
      }`;
    }

    return message;
  };

  const columns: ColumnsType<StockMaterialItem> = [
    {
      title: t(material.title),
      key: material.key,
      render: () => selectedMaterial?.name,
    },
    {
      title: t(batchCode.title),
      dataIndex: batchCode.dataIndex,
      key: batchCode.key,
      filterDropdown: ({ selectedKeys, confirm, setSelectedKeys }) => {
        externalBatchCodeReset = () => setSelectedKeys([]);

        return (
          <Space className="flex flex-col items-center p-2.5 w-full">
            <Title level={5}>{t("material-consumed.find-by-batch-code")}</Title>

            <div className="flex items-center justify-between">
              <Input
                placeholder={t("material-consumed.enter-batch-code")!}
                value={selectedKeys[0]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debouncedChangeHandler(e);
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onPressEnter={() => {
                  confirm();
                }}
              />

              <Button
                type="primary"
                onClick={() => {
                  setBatchCodeContains("");
                  setSelectedKeys([]);
                  confirm({ closeDropdown: false });
                }}
                className="btn-filter--x_mark"
              >
                <CloseOutlined />
              </Button>
            </div>
          </Space>
        );
      },
    },

    {
      title: deliveryDate.title,
      dataIndex: deliveryDate.dataIndex,
      key: deliveryDate.key,
      width: deliveryDate.width,
    },
    {
      title: status.title,
      render: (item: StockMaterialItem) => renderStockBatchStatuses(item),
      width: status.width,
    },
    {
      title: calcTotalOriginalQuantity.title,
      render: (item: StockMaterialItem) =>
        renderQuantityWithAbbreviation(
          item.calc_total_original_quantity!,
          null,
          null,
          selectedMaterial!
        ),
      width: calcTotalOriginalQuantity.width,
    },
    {
      title: calcTotalConsumedQuantity.title,
      render: (item: StockMaterialItem) =>
        renderQuantityWithAbbreviation(
          null,
          item.calc_total_consumed_quantity!,
          null,
          selectedMaterial!
        ),
      width: calcTotalConsumedQuantity.width,
    },
    {
      title: calcTotalRemainingQuantity.title,
      render: (item: StockMaterialItem) =>
        renderQuantityWithAbbreviation(
          null,
          null,
          item.calc_total_remaining_quantity!,
          selectedMaterial!
        ),
      width: calcTotalRemainingQuantity.width,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: calcPercentageRemainingQuantity.title,
      render: (item: StockMaterialItem) =>
        `${item.calc_percentage_remaining_quantity}%`,
      width: calcPercentageRemainingQuantity.width,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
          );
        },
      }),
      sorter: true,
    },
  ];

  const clearAllFilters = () => {
    externalBatchCodeReset();
    setBatchCodeContains("");
  };

  const renderButtonAndClearAllFilters = () => {
    if (batchCodeContains && batchCodeContains.length > 0) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    return null;
  };

  const returnSelectedStockBatch = () => {
    return (
      <div className="mb-4 mt-3">
        <Title level={5}>{t("material-consumed.selected-item")!}</Title>
        <Table
          columns={columns}
          dataSource={arrayWithSelectedStockBatch}
          rowKey={(obj) => obj.id!}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      {selectedStockBatchFormTable && edit ? returnSelectedStockBatch() : null}
      <Title level={5}>{t("material-consumed.material-stock-batch")!}</Title>
      {renderButtonAndClearAllFilters()}
      <Table
        loading={isLoadingMaterialStockBatch || isFetchingMaterialStockBatch}
        rowSelection={{
          type: "radio",
          ...rowMaterialStockItemSelection,
        }}
        columns={columns}
        dataSource={materialStockBatch?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: materialStockBatch?.count,
        }}
      />

      <Divider />
    </>
  );
};

export { FilteredStockBatchTable };
