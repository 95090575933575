import { Popover } from "antd";
import React, { FC } from "react";

interface ColumnNameProps {
  fullTitle: string;
  shortTitle: string;
}

const ColumnName: FC<ColumnNameProps> = ({ fullTitle, shortTitle }) => {
  return (
    <Popover content={fullTitle} placement="bottom">
      <span className="text-[11px]"> {shortTitle}</span>
    </Popover>
  );
};

export { ColumnName };
