import { ProductCardItem } from "shared/api/productCard/models";

export const adaptProductCard = (productCards: ProductCardItem[]) => {
  const adaptProductCard = productCards?.map((item) => ({
    label: item.name,
    value: item.id,
    key: item.id,
  }));
  return adaptProductCard || [];
};
