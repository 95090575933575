import { MaterialStockItem, WidgetTableData, WidgetTypeEnum } from "shared/api";
import { renderMaterialItemsStatuses } from "shared/helpers/materialStockItem";
import { widgetsIcons } from "shared/assets";
import { noWidgetFieldData } from "../widgets";
import { ArticleOrderProcessStepTracker } from "../articleFlow";

export const adaptQualityTrackingData = (data?: MaterialStockItem[]) => {
  return data?.map((item) => {
    return {
      type: WidgetTypeEnum.QUALITY_TRACKING,
      articleImage: renderMaterialItemsStatuses(item) || noWidgetFieldData,
      articleDateTime: item?.timestamp || noWidgetFieldData,
      articleMachine:
        (
          item?.order_production_set
            ?.order_process_step_tracker as unknown as ArticleOrderProcessStepTracker
        )?.machine || noWidgetFieldData,

      articleArticle:
        {
          id: item?.id,
          serial_number: item?.serial_number,
        } || noWidgetFieldData,
      articleStatus: renderMaterialItemsStatuses(item) || noWidgetFieldData,
    };
  });
};

export const qualityTrackingIcons = {
  Prima: widgetsIcons.qualityGreen,
  Waste: widgetsIcons.qualityRed,
  Investigation: widgetsIcons.qualityYellow,
};

export const renderQualityTrackingIcon = (record: WidgetTableData) => {
  return qualityTrackingIcons[
    record.articleImage as keyof typeof qualityTrackingIcons
  ];
};
