import { Modal } from "antd";
import { Colors } from "shared/themes";

const useConfirmDeleteModal = <T extends unknown>(
  title: string,
  onConfirm: (record: T) => Promise<void>,
  isLoading?: boolean
) => {
  const showModal = (record: T) => {
    Modal.confirm({
      title,
      width: "450px",
      onOk: () => {
        onConfirm(record);
      },
      okButtonProps: {
        loading: isLoading,
        style: { background: `${Colors.blue.primary}` },
      },
    });
  };

  return showModal;
};

export { useConfirmDeleteModal };
