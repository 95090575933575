import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface WidgetProps {
  title?: string;
  onClick?: () => void;
}

const WidgetCard: FC<WidgetProps> = ({ title, onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        `
        w-[32.4%] border-[1px] border-gray-200 
        p-8 h-auto hover:bg-blue-primary 
        hover:text-white transition-colors duration-300
        shadow-md
        `,
        {}
      )}
    >
      {t(title!)}
    </button>
  );
};

const WidgetCardMemo = React.memo(WidgetCard);

export { WidgetCardMemo as WidgetCard };
