import React, { FC } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { AnyType, renderDynamicInputType } from "shared/helpers";
import { DynamicNumberInput } from "shared/features/DynamicFormsComponents/DynamicIntegerInput";
import { ConfigDynamicTrackerFields } from "shared/api";
import { DynamicFieldsEnum } from "shared/constants";
import { DynamicTextTypeInput, DynamicSelectInput } from "shared/features";
import { AddStockMaterialItemValues } from "shared/types";

interface DynamicFormMaterialItemsProps {
  dynamicValues: AnyType;
}

const DynamicFormMaterialItems: FC<DynamicFormMaterialItemsProps> = ({
  dynamicValues,
}) => {
  const { getFieldProps } = useFormikContext<AddStockMaterialItemValues>();

  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dynamicValues?.map((card: ConfigDynamicTrackerFields) => (
        <div
          key={card.title}
          className="w-full h-auto p-2.5 mb-2.5 rounded-xl shadow-dynamicFormCard mt-3"
        >
          <Title level={4}>{card?.title}</Title>
          <div>
            {card?.fields.map((field, fieldIndex: number) => {
              const cardFieldName = `dynamic_tracker_fields.${card.title}.${field.name}`;
              const { value } = getFieldProps(cardFieldName);

              return (
                <div key={cardFieldName}>
                  <Title level={5} className="!text-primary">
                    {field.name}. {t("orders.type")} -{" "}
                    {renderDynamicInputType(field?.type)}
                  </Title>

                  {field.type === DynamicFieldsEnum.TEXT && (
                    <DynamicTextTypeInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.INTEGER && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.FLOAT && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.CHOICES && (
                    <DynamicSelectInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={value}
                      fieldName={cardFieldName}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

const DynamicFormMaterialItemsMemo = React.memo(DynamicFormMaterialItems);

export { DynamicFormMaterialItemsMemo as DynamicFormMaterialItems };
