import { Modal, UploadFile, notification } from "antd";
import { ModalSizeValue } from "shared/constants";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import {
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  QualityResultItem,
  QualityResultResultType,
  useCreateQualityResultMutation,
  useUpdateQualityResultMutation,
  useUploadQualityDocumentMutation,
} from "shared/api";
import {
  adaptMaterialItemToCreateQualityResult,
  adaptMaterialItemToCreateQualityResultUniq,
  adaptStockBatchesToCreateQualityResult,
  adaptStockBatchesToCreateQualityResultUniq,
  adaptUploadDocumentParams,
} from "shared/adapters";
import { useFormikContext } from "formik";
import { disableQualityResultsForm, handleRequestError } from "shared/helpers";
import { AdaptedValues, UploadDocumentValues } from "shared/types";
import { useTableKeys } from "shared/hooks";
import { RequiredQualityFields } from "./RequiredQualityFields";
import { ExtraData } from "./ExtraData";

interface UploadDocumentModalProps {
  isOpenModal: boolean;
  onClose: () => void;
  adaptedMaterials: AdaptedValues[];
  document: File | null;
  handleDocument: (file: File | null) => void;
  selectedStockBatches: MaterialStockBatchItemResponse[];
  selectedMaterialItems: MaterialStockItem[];
  handleStockBatchesRows: (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => void;
  handleMaterialItemsRows: (materialItems: MaterialStockItem[]) => void;
  handleDocumentList: (files: UploadFile[]) => void;
  documentList: UploadFile[];
  adaptedOrders: AdaptedValues[];
  isLoadingMaterials: boolean;
  isLoadingOrders: boolean;
}

const UploadDocumentModal: FC<UploadDocumentModalProps> = ({
  isOpenModal,
  onClose,
  adaptedMaterials,
  document,
  handleDocument,
  selectedStockBatches,
  selectedMaterialItems,
  handleStockBatchesRows,
  handleMaterialItemsRows,
  handleDocumentList,
  documentList,
  adaptedOrders,
  isLoadingMaterials,
  isLoadingOrders,
}) => {
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [selectedStockBatchesKeys, handleStockBatchesKeys] = useTableKeys();
  const [selectedMaterialItemsKeys, handleMaterialItemsKeys] = useTableKeys();

  const [modalStep, setModalStep] = useState(1);

  const isFirstPage = modalStep === 1;
  const isSecondPage = modalStep === 2;

  const { values, resetForm, isValid, setFieldValue, setValues } =
    useFormikContext<UploadDocumentValues>();

  const openExtraDataModalStep = (responseId: number) => {
    setFieldValue("id", responseId);
    setModalStep(2);
  };

  const closeModal = () => {
    resetForm();
    handleDocument(null);
    handleStockBatchesRows([]);
    handleMaterialItemsRows([]);
    handleDocument(null);
    handleStockBatchesKeys([]);
    handleMaterialItemsKeys([]);
    onClose();
    setModalStep(1);
    handleDocumentList([]);
  };

  const [createQualityResult] = useCreateQualityResultMutation();
  const [uploadQualityDocument] = useUploadQualityDocumentMutation();
  const [updateQualityResult] = useUpdateQualityResultMutation();

  const handleCreateQualityResult = async () => {
    try {
      if (document) {
        const response = await createQualityResult({
          name: values?.name!,
          result_type: values?.result_type as QualityResultResultType,
          material_stock_batches:
            adaptStockBatchesToCreateQualityResult(selectedStockBatches),
          material_items: adaptMaterialItemToCreateQualityResult(
            selectedMaterialItems
          ),
        }).unwrap();

        const adaptedTypeResponse = response as unknown as QualityResultItem;

        const isLabResultResponse =
          adaptedTypeResponse?.result_type ===
          QualityResultResultType?.REEL_LAB_RESULT;

        if (isLabResultResponse) {
          openExtraDataModalStep(adaptedTypeResponse?.id);
        } else {
          closeModal();
        }

        const uploadDocumentParams = adaptUploadDocumentParams(
          document,
          response
        );

        const uploadDocumentResponse = await uploadQualityDocument(
          uploadDocumentParams
        ).unwrap();

        const adaptedUploadDocumentResponse =
          uploadDocumentResponse as unknown as QualityResultItem;

        setValues({
          ...adaptedUploadDocumentResponse,
          material: "",
          order: "",
          material_items: [],
          material_stock_batches: [],
        });
      }

      api.success({
        message: t("quality.success-title"),
        description: t("quality.success-add-desc"),
      });
    } catch (error) {
      api.error({
        message: t("quality.error-title"),
        description: handleRequestError(error, t("quality.error-title")),
      });
    }
  };

  const updateQualityResultHandler = async () => {
    try {
      const response = await updateQualityResult({
        id: values?.id!,
        name: values?.name!,
        result_type: values?.result_type as QualityResultResultType,
        curl_cd: values?.curl_cd,
        curl_md: values?.curl_md,
        thickness: values?.thickness,
        remark: values?.remark,
        reel_lab_measurements: values?.reel_lab_measurements,
        material_stock_batches:
          adaptStockBatchesToCreateQualityResultUniq(selectedStockBatches),
        material_items: selectedStockBatches.length
          ? adaptMaterialItemToCreateQualityResultUniq(selectedMaterialItems)
          : [],
      }).unwrap();

      if (document) {
        const uploadDocumentParams = adaptUploadDocumentParams(
          document,
          response
        );

        await uploadQualityDocument(uploadDocumentParams).unwrap();
      }

      api.success({
        message: t("quality.success-title"),
        description: t("quality.success-upd-desc"),
      });

      closeModal();
    } catch (error) {
      api.error({
        message: t("quality.error-title"),
        description: handleRequestError(error, t("quality.error-title")),
      });
    }
  };

  const disableButton = disableQualityResultsForm(
    values?.result_type,
    isValid,
    documentList
  );

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpenModal}
        onCancel={closeModal}
        title={
          isFirstPage
            ? t("quality.add-quality-item")
            : t("quality.add-extra-data")
        }
        width={isSecondPage ? ModalSizeValue?.LARGE : ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: disableButton,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onOk={
          isFirstPage ? handleCreateQualityResult : updateQualityResultHandler
        }
      >
        {isFirstPage && (
          <RequiredQualityFields
            handleDocument={handleDocument}
            adaptedMaterials={adaptedMaterials}
            handleMaterialItemsKeys={handleMaterialItemsKeys}
            handleMaterialItemsRows={handleMaterialItemsRows}
            handleStockBatchesKeys={handleStockBatchesKeys}
            handleStockBatchesRows={handleStockBatchesRows}
            selectedMaterialItemsKeys={selectedMaterialItemsKeys}
            selectedStockBatchesKeys={selectedStockBatchesKeys}
            handleDocumentList={handleDocumentList}
            documentList={documentList}
            adaptedOrders={adaptedOrders}
            isLoadingMaterials={isLoadingMaterials}
            isLoadingOrders={isLoadingOrders}
          />
        )}

        {isSecondPage && <ExtraData />}
      </Modal>
    </>
  );
};

export { UploadDocumentModal };
