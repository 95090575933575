import {
  MaterialStockBatchItemResponse,
  MaterialStockBatchQualityResult,
  MaterialStockItem,
  QualityResultItem,
  QualityResultsResponse,
} from "shared/api";

export const adaptStockBatchesToCreateQualityResult = (
  stockBatches: MaterialStockBatchItemResponse[]
) => {
  return stockBatches?.map((item) => ({
    material_stock_batch: item?.id,
  }));
};

export const adaptMaterialItemToCreateQualityResult = (
  materialItems: MaterialStockItem[]
) => {
  return materialItems?.map((item) => ({
    material_item: item?.id,
  }));
};

export const adaptUploadDocumentParams = (
  document: File,
  response: QualityResultsResponse
) => {
  const formData = new FormData();
  formData.append("file", document);

  const qualityResultResponse = response as unknown as QualityResultItem;

  return {
    id: qualityResultResponse?.id,
    document: formData,
  };
};

export const adaptMaterialItemToCreateQualityResultUniq = (
  materialItems: MaterialStockItem[]
) => {
  const seen = new Set();
  return materialItems
    ?.filter((item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    })
    .map((item) => ({ material_item: item.id }));
};

export const adaptStockBatchesToCreateQualityResultUniq = (
  stockBatches: MaterialStockBatchItemResponse[]
) => {
  const seen = new Set();
  return stockBatches
    ?.filter((item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    })
    .map((item) => ({ material_stock_batch: item.id }));
};

export const getSuppliersFromStockBatches = (
  stockBatches: MaterialStockBatchQualityResult[]
) => {
  const suppliers = stockBatches
    .map((stockBatch) => stockBatch?.material_stock_batch?.supplier)
    .filter(Boolean);

  return suppliers;
};
