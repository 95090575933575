import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getSuppliersFromStockBatches } from "shared/adapters";
import { MaterialStockBatchQualityResult } from "shared/api";

interface TableSuppliersProps {
  stockBatches: MaterialStockBatchQualityResult[];
}

const TableSuppliers: FC<TableSuppliersProps> = ({ stockBatches }) => {
  const { t } = useTranslation();

  const suppliers = useMemo(() => {
    return getSuppliersFromStockBatches(stockBatches);
  }, [stockBatches]);

  return (
    <div className="w-hull h-full flex flex-wrap gap-x-2">
      {!suppliers?.length && t("quality.no-data")}

      {suppliers?.map((supplier) =>
        supplier ? (
          <span key={supplier?.id} className="hover:underline">
            {supplier?.name}
          </span>
        ) : null
      )}
    </div>
  );
};

export { TableSuppliers };
