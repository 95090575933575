import { SuppliersItem } from "../materials";
import { DynamicOutputMaterialObject } from "../orders";

export interface QualityResultsParams {
  expand?: string;
  offset?: number | null;
  limit?: number | null;
  ordering?: string | null;
  batch_code__icontains?: string | null;
  serial_number__icontains?: string | null;
  material_stock_batches__material_stock_batch__supplier__name?: string | null;
  timestamp__gte?: string;
  timestamp__lte?: string;
  name__icontains?: string | null;
  material_items__material_item__serial_number?: string | number | null;
  material_stock_batches__material_stock_batch__batch_code?: string | null;
  material_items__material_item?: number | null;
}

interface MaterialQualityResult {
  id: number;
  name: string;
  material_category: number;
  suppliers: {
    id: number;
    cost_per_unit_currency: string;
    cost_per_unit: string;
    supplier: number;
  }[];
  target_quantity_in_stock: 3106039;
  n_packaging_items: 500;
}

export interface MaterialItemQualityResult {
  id: number;
  material_item: {
    id: number;
    material_stock_batch: {
      id: number;
      material: MaterialQualityResult;
      batch_code: string;
      is_quantity_in_tracker_table: true;
      quantity: null | number;
      delivery_date: string;
      status: string;
      supplier: number;
      produced_in_order_process_step_tracker: null;
    };
    packaging: number;
    serial_number: string;
    quantity: number;
    dynamic_tracker_fields: DynamicOutputMaterialObject;
    status: string;
    order_production_set: number;
    order_production_set_position: number;
    production_number: number;
  };
}

export interface MaterialStockBatchQualityResult {
  id: number;
  material_stock_batch: {
    id: number;
    material: MaterialQualityResult;
    batch_code: string;
    is_quantity_in_tracker_table: true;
    quantity: null | number;
    delivery_date: string;
    status: string;
    supplier: SuppliersItem;
    produced_in_order_process_step_tracker: null;
  };
}

export const enum QualityResultResultType {
  FILE_UPLOAD = "FILE_UPLOAD",
  REEL_LAB_RESULT = "REEL_LAB_RESULT",
}

export interface LabMeasurements {
  number: number;
  tensile_md: number;
  tensile_cd: number;
  elongation_md: number;
  elongation_cd: number;
  [key: string]: number | undefined;
}

export interface QualityResultItem {
  id: number;
  name: string;
  timestamp: string;
  result_type: QualityResultResultType;
  document: string;
  material_items: MaterialItemQualityResult[];
  material_stock_batches: MaterialStockBatchQualityResult[];
  reel_lab_measurements?: LabMeasurements[];
  thickness?: number | null;
  curl_md?: string | null;
  curl_cd?: string | null;
  remark?: string | null;
}
export interface QualityResultsResponse {
  count: number;
  next: string;
  previous: string;
  results: QualityResultItem[];
}

export interface MaterialItemToCreate {
  material_item: number;
}

export interface MaterialStockBatchToCreate {
  material_stock_batch: number;
}

export interface CreateQualityResult {
  name: string;
  result_type: QualityResultResultType;
  material_items: MaterialItemToCreate[];
  material_stock_batches: MaterialStockBatchToCreate[];
}

export interface UploadQualityDocument {
  id: number;
  document: FormData;
}

export interface UpdateQualityResult {
  id?: number;
  name: string;
  result_type: QualityResultResultType;
  material_items: MaterialItemToCreate[];
  material_stock_batches: MaterialStockBatchToCreate[];
  reel_lab_measurements?: LabMeasurements[];
  thickness?: number | null;
  curl_md?: string | null;
  curl_cd?: string | null;
  remark?: string | null;
}

export interface DownloadReelLabResult {
  id: number;
}

export interface GenerateReelLabArchive {
  id: number;
}
