import { Modal } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { FC } from "react";
import { CompanyItem } from "shared/api";
import { useTranslation } from "react-i18next";
import { PopConfirmButton, TableImage } from "shared/ui";
import { openHyperlinkInTab, renderCompanyRole } from "shared/helpers";
import { ModalSizeValue, companiesColumnsData } from "shared/constants";
import { Routes } from "shared/routers";
import { CategorySupplierInformationField } from "pages/SupplyChain/MaterialCategoryManagement/ui";

interface ChildrenCompaniesModalProps {
  isOpen: boolean;
  onClose: () => void;
  parentToShowChildren: CompanyItem | null;
}

const ChildrenCompaniesModal: FC<ChildrenCompaniesModalProps> = ({
  isOpen,
  onClose,
  parentToShowChildren,
}) => {
  const { t } = useTranslation();

  const {
    name,
    address,
    incoTerms,
    customerOrSupplier,
    image,
    evaluationAsSupplier,
    information,
  } = companiesColumnsData;

  const columns: ColumnsType<CompanyItem> = [
    {
      title: t(image.title),
      render: (company: CompanyItem) => (
        <TableImage image={company?.image as string} />
      ),
    },
    {
      title: t(name.title),
      render: (company: CompanyItem) => (
        <PopConfirmButton
          buttonContent={company?.name}
          onClick={() =>
            openHyperlinkInTab(
              `${Routes.companies.hyperlink}/${company?.id}`.replace(
                ":companyID/",
                ""
              )
            )
          }
        />
      ),
    },
    {
      title: t(address.title),
      dataIndex: address.dataIndex,
      key: address.key,
    },
    {
      title: t(evaluationAsSupplier.title),
      dataIndex: evaluationAsSupplier.dataIndex,
      key: evaluationAsSupplier.key,
      width: evaluationAsSupplier.width,
    },
    {
      title: t(incoTerms.title),
      dataIndex: incoTerms.dataIndex,
      key: incoTerms.key,
    },
    {
      title: t(information.title),
      render: (item: CompanyItem) => (
        <CategorySupplierInformationField company={item} />
      ),
    },
    {
      title: t(customerOrSupplier.title),
      render: (item: CompanyItem) => renderCompanyRole(item),
      width: customerOrSupplier.width,
    },
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={t("companiesColumns.childCompaniesModalTitle", {
        name: parentToShowChildren?.name,
      })}
      width={ModalSizeValue.XL_MEDIUM}
    >
      <Table
        columns={columns}
        dataSource={parentToShowChildren?.child_companies as CompanyItem[]}
        rowKey={(obj) => obj.id!}
      />
    </Modal>
  );
};

export { ChildrenCompaniesModal };
