import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useFormik } from "formik";
import debounce from "lodash.debounce";
import { AddProductionRequest } from "pages/Orders/Orders/UI/ProcessStepProduction/ProcessStepProduction";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import { noop } from "shared/helpers";
import { outputMaterialsSchema } from "shared/schemas";
import * as Yup from "yup";

interface OutputMaterialDataForProductionPageProps {
  mainValues: AddProductionRequest;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

export const useOutputMaterialDataForProductionPage = ({
  mainValues,
  orderStepTrackerResponseItem,
}: OutputMaterialDataForProductionPageProps) => {
  const defaultCountOfItemsForOutputMaterials = useMemo(() => {
    return mainValues?.output_materials?.map(
      (material) => material?.numberOfMaterialItems ?? []
    );
  }, [mainValues]);

  const [outputMaterialsStep, setOutputMaterialsStep] = useState(0);
  const [copyValues, setCopyValues] = useState(false);
  const [countOfItems, setCountOfItems] = useState<number[]>(
    defaultCountOfItemsForOutputMaterials as number[]
  );
  const [isEditOutputMaterialsMode, setEditOutputMaterialsMode] = useState<
    boolean[]
  >([]);

  const isSomeEditModeOpen = isEditOutputMaterialsMode.some((mode) => mode);

  const handleCopyValuesChange = (e: CheckboxChangeEvent) =>
    setCopyValues(e.target.checked);

  const handleCountOfItemsChange = (value: number, index: number) => {
    setCountOfItems((prev) => {
      const newCounts = [...prev];
      newCounts[index] = value;
      return newCounts;
    });
  };

  const toggleEditMode = (index: number) => {
    setEditOutputMaterialsMode((prev) => {
      const newModes = [...prev];
      newModes[index] = !newModes[index];
      return newModes;
    });
  };

  // Added separated formik for output materials
  // Because in mainValues we have a lot of values and we have delays while change them
  const outputMaterialsFormik = useFormik<{
    output_materials: ProductionOutputMaterial[];
  }>({
    initialValues: {
      output_materials: mainValues?.output_materials ?? [],
    },
    validateOnMount: false,
    onSubmit: noop,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      output_materials: outputMaterialsSchema(orderStepTrackerResponseItem),
    }),
  });

  const {
    values: outputMaterialsValues,
    setFieldValue: setOutputMaterialFieldValue,
    isValid,
    validateForm,
  } = outputMaterialsFormik;

  const [resolvedValidationArray, setResolvedValidationArray] = useState<
    boolean[]
  >([]);

  const debouncedValidateForm = useCallback(
    debounce(async () => {
      const errors = await validateForm();
      const validationArray = outputMaterialsValues.output_materials.map(
        (_, index) => {
          return !errors.output_materials || !errors.output_materials[index];
        }
      );
      setResolvedValidationArray(validationArray);
    }, 300),
    [validateForm, outputMaterialsValues]
  );

  useEffect(() => {
    debouncedValidateForm();
    return () => {
      debouncedValidateForm.cancel();
    };
  }, [debouncedValidateForm]);

  const memoizedValidationArray = useMemo(
    () => resolvedValidationArray,
    [resolvedValidationArray]
  );

  return {
    outputMaterialsFormik,
    outputMaterialsValues,
    setOutputMaterialFieldValue,
    isValid,
    outputMaterialsStep,
    copyValues,
    handleCopyValuesChange,
    setOutputMaterialsStep,
    countOfItems,
    handleCountOfItemsChange,
    validationArray: memoizedValidationArray,
    isEditOutputMaterialsMode,
    toggleEditMode,
    isSomeEditModeOpen,
  };
};
