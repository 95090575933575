import { Alert, Button } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext, FieldArray } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Widget, WidgetTypeEnum } from "shared/api";
import {
  AllFilterKeys,
  ArticleFlowFilterKeysEnum,
  filterFieldArrayNewRow,
  getFiltersByWidgetType,
  getWidgetFilterFormikValues,
  renderTranslatedFilterName,
} from "shared/helpers";
import { useWidgetFilters } from "shared/hooks";
import { FormSelect, Loader, TableButton } from "shared/ui";

interface WidgetFiltersProps {
  widget: Widget;
}

const WidgetFilters: FC<WidgetFiltersProps> = ({ widget }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, handleBlur, errors } =
    useFormikContext<Widget>();

  const { getFiltersOptionsByFilterParameter, isLoading } = useWidgetFilters({
    widget,
  });

  const filterKeys = useMemo(() => {
    return getFiltersByWidgetType(widget?.type as WidgetTypeEnum) ?? [];
  }, [widget]);

  const availableFilters = useMemo(() => {
    const selectedFilters = values?.filter.map((f) => f.filter_parameter);
    return filterKeys.filter(
      (filterKey) =>
        !selectedFilters.includes(filterKey.value as ArticleFlowFilterKeysEnum)
    );
  }, [filterKeys, values]);

  return (
    <div className="w-full flex flex-col gap-y-2">
      <Title level={5} className="!m-0">
        {t("widgets.selectFilters")}
      </Title>

      <FieldArray name="filter">
        {({ push, remove }) => (
          <div className="flex flex-col gap-y-2">
            {values?.filter?.map((filter, filterIndex) => {
              const { filterParameter, filterValue } =
                getWidgetFilterFormikValues(values, filterIndex);

              const filterOptions =
                getFiltersOptionsByFilterParameter(
                  filterParameter.value as AllFilterKeys
                ) ?? [];

              return (
                <>
                  <div
                    className="flex gap-x-2 items-start justify-between"
                    key={filter.filter_parameter}
                  >
                    <div className="w-1/2">
                      <FormSelect
                        value={renderTranslatedFilterName(
                          filterParameter.value
                        )}
                        placeholder={t("widgets.selectFilterOption")}
                        fieldName={filterParameter.fieldName}
                        options={availableFilters}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        multiple
                      />
                    </div>

                    <div className="w-1/2">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <FormSelect
                          value={filterValue.value}
                          placeholder={t("widgets.selectFilterValue")}
                          fieldName={filterValue.fieldName}
                          options={filterOptions}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          multiple
                          isLoading={isLoading}
                          maxTagTextLength={8}
                        />
                      )}
                    </div>

                    <TableButton
                      type="delete"
                      className="mt-[2px]"
                      onClick={() => remove(filterIndex)}
                    />
                  </div>
                  {errors?.filter?.[filterIndex] && (
                    <Alert
                      type="warning"
                      showIcon
                      message={t("widgets.filterValidation")}
                    />
                  )}
                </>
              );
            })}
            <Button onClick={() => push(filterFieldArrayNewRow)}>
              {t("widgets.addFilter")}
            </Button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

const WidgetFiltersMemo = React.memo(WidgetFilters);

export { WidgetFiltersMemo as WidgetFilters };
