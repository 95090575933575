export const isSupplierSelectValues = [
  { label: "Supplier", value: true },
  { label: "Not Supplier", value: false },
];

export const isBuyerSelectValues = [
  { label: "Buyer", value: true },
  { label: "Not Buyer", value: false },
];

export const isSupplierOrBuyerSelectValues = [
  { label: "Supplier", value: "supplier" },
  { label: "Customer", value: "customer" },
  { label: "Both", value: "both" },
];

export const companiesInitialValues = {
  id: null,
  name: "",
  address: "",
  payment_term_net_days: null,
  paymentTerms: "",
  inco_terms: "",
  is_supplier: false,
  is_buyer: false,
  sections: [],
  product_cards: [],
  evaluation_as_supplier: null,
  categories_supplied: [],
  categories_supplied_new_to_add: [],
  parent_company: null,
  child_companies: [],
  contact_name: null,
  information: null,
};

export const companiesColumnsData = {
  image: {
    title: "companiesColumns.logo",
    dataIndex: "image",
    key: "image",
    width: "20%",
  },
  name: {
    title: "companiesColumns.name",
    dataIndex: "name",
    key: "name",
    width: "20%",
  },
  address: {
    title: "companiesColumns.address",
    dataIndex: "address",
    key: "address",
    width: "20%",
  },
  evaluationAsSupplier: {
    title: "companiesColumns.evaluation",
    dataIndex: "evaluation_as_supplier",
    key: "evaluation_as_supplier",
    width: "8%",
  },
  paymentTerms: {
    title: "companiesColumns.paymentTerms",
    dataIndex: "payment_term_net_days",
    key: "payment_term_net_days",
  },
  incoTerms: {
    title: "companiesColumns.incoTerms",
    dataIndex: "inco_terms",
    key: "inco_terms",
  },
  isSupplier: {
    title: "companiesColumns.isSupplier",
    key: "is_supplier",
  },
  isBuyer: {
    title: "companiesColumns.isBuyer",
    key: "is_buyer",
  },
  customerOrSupplier: {
    title: "companiesColumns.customerOrSupplier",
    width: "13%",
  },
  childCompanies: {
    title: "companiesColumns.childCompanies",
    width: "13%",
  },
  actions: {
    title: "companiesColumns.actions",
    key: "actions",
    width: "10%",
  },
  categoriesSupplied: {
    title: "companiesColumns.categoriesSupplied",
    key: "actions",
  },
  contactName: {
    title: "companiesColumns.contactName",
    key: "actions",
  },
  information: {
    title: "companiesColumns.information",
    key: "actions",
  },
};

export const productCardsColumnsData = {
  key: {
    key: "sort",
  },
  name: {
    title: "dynamicProductCardTableColumns.prodCardName",
    dataIndex: "name",
    key: "name",
    width: "90%",
  },
  actions: {
    title: "dynamicProductCardTableColumns.actions",
    key: "actions",
    width: "5%",
  },
};

export const initialPackageValues = {
  name: null,
  material: null,
  order: null,
  materialFilter: null,
  situation: null,
};

export const materialCategoriesInCompanyData = {
  name: {
    title: "materialCategoriesInCompanyData.name",
    dataIndex: "name",
    key: "name",
    width: "50%",
  },
  unit: {
    title: "materialCategoriesInCompanyData.unit",
    dataIndex: "unit",
    key: "unit",
    width: "20%",
  },
  uniqueCode: {
    title: "materialCategoriesInCompanyData.uniqueCode",
    dataIndex: "unique_code",
    key: "unique_code",
    width: "20%",
  },

  actions: {
    title: "materialCategoriesInCompanyData.actions",
    key: "actions",
    width: "10%",
  },
};
