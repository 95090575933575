import { NotificationInstance } from "antd/es/notification/interface";
import { AddOrUpdateOrderValues } from "shared/types";
import { useState, useEffect } from "react";

export const useValidOrderDate = (
  isOpenModal: boolean,
  values: AddOrUpdateOrderValues,
  handleValidOrderDate: (
    isOpenModal: boolean,
    values: AddOrUpdateOrderValues,
    handleValidDate: (isValid: boolean) => void,
    api: NotificationInstance
  ) => void,
  api: NotificationInstance
) => {
  const [isValidDate, setIsValidDate] = useState(true);

  const handleValidDate = (isValid: boolean) => setIsValidDate(isValid);

  useEffect(() => {
    handleValidOrderDate(isOpenModal, values, handleValidDate, api);
  }, [
    values?.wanted_delivery_datetime,
    values?.production_datetime,
    isOpenModal,
  ]);

  return isValidDate;
};
