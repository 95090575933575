import { MachineItem, Widget, WidgetTypeEnum } from "shared/api";

export const enum MachinesXFields {
  WIDGET_TYPE = "MACHINES",
  MACHINE_X_NAME = "machineXName",
  MACHINE_X_PROCESS_TEMPLATE = "machineXProcessTemplate",
}

export const machinesXColumnsValues = [
  { value: "machineXName", label: "Machines" },
  { value: "machineXProcessTemplate", label: "Process Template" },
];

export const adaptMachinesXData = (machinesX: MachineItem[]) => {
  return machinesX?.map((machine) => {
    return {
      type: WidgetTypeEnum.MACHINES,
      machineXName: machine?.name,
      machineXId: machine?.id,
      machineXProcessTemplate: machine?.process_template?.name,
      machineXProcessTemplateId: machine?.process_template?.id,
    };
  });
};

export const adaptMachinesXProcessTemplatesFilterData = (widget: Widget) => {
  const processTemplatesFilter = widget?.filter?.find(
    (widget) => widget?.filter_parameter === "process_template"
  );

  const adapterProcessTemplates = (
    processTemplatesFilter?.filter_value as string
  )
    ?.split(",")
    .map((processTemplate) => Number(processTemplate));

  return adapterProcessTemplates;
};
