export const initialWidgetConfigurations = {
  graphMaterialLevel: [
    {
      configuration_parameter: "material",
      configuration_value: null,
    },
    {
      configuration_parameter: "redLimit",
      configuration_value: "80",
    },
    {
      configuration_parameter: "yellowLimit",
      configuration_value: "99",
    },
  ],
};
