import { Button, Timeline } from "antd";
import React, { FC } from "react";
import { MaterialsItem } from "shared/api";

interface GoToTableHeaderProps {
  resetMaterialManagementPage: () => void;
  isStockHistory: boolean;
  isMaterialConsumed: boolean;
  selectedMaterial: MaterialsItem | null;
  isMaterialManagementTable: boolean;
  isMaterialReservation: boolean;
  isStockManagement: boolean;
  isMaterialItems: boolean;
  goToStockManagementFromMaterialItems: () => void;
}

const GoToTableHeader: FC<GoToTableHeaderProps> = ({
  resetMaterialManagementPage,
  isStockHistory,
  isMaterialConsumed,
  selectedMaterial,
  isMaterialManagementTable,
  isMaterialReservation,
  isStockManagement,
  isMaterialItems,
  goToStockManagementFromMaterialItems,
}) => {
  if (isMaterialManagementTable) {
    return null;
  }

  const timelineItems = [
    ...(isStockHistory
      ? [{ children: `Stock History for: ${selectedMaterial?.name}` }]
      : []),

    ...(isMaterialReservation
      ? [{ children: `Material Reservation for: ${selectedMaterial?.name}` }]
      : []),

    ...(isMaterialConsumed
      ? [{ children: `Material Consumed for: ${selectedMaterial?.name}` }]
      : []),

    ...(isStockManagement
      ? [{ children: `Stock Management for: ${selectedMaterial?.name}` }]
      : []),

    ...(isMaterialItems
      ? [
          { children: `Stock Management for: ${selectedMaterial?.name}` },
          {
            children: `Material Items for: ${selectedMaterial?.name} - ${selectedMaterial?.name}`,
          },
        ]
      : []),
  ];

  return (
    <div className="flex items-center justify-between">
      <Timeline items={timelineItems} className="!p-0 mt-5" />

      <Button
        type="primary"
        className="bg-blue-primary  mb-3"
        onClick={
          isMaterialItems
            ? () => goToStockManagementFromMaterialItems()
            : () => resetMaterialManagementPage()
        }
      >
        {isMaterialItems
          ? "Go back to stock management"
          : "Go back to material management table"}
      </Button>
    </div>
  );
};

export { GoToTableHeader };
