import { PlusCircleOutlined } from "@ant-design/icons";
import { Alert, Popover } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  OrderStepTrackerResponseItem,
  ProductionInputMaterial,
} from "shared/api";
import {
  getProductionPageInputMaterialTitle,
  openHyperlinkInTab,
} from "shared/helpers";
import { useWindowDimensions } from "shared/hooks";
import { Routes } from "shared/routers";

interface MaterialNameProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  inputMaterial: ProductionInputMaterial;
  handleAddRow: (inputIndex: number) => void;
  inputIndex: number;
}

const MaterialName: FC<MaterialNameProps> = ({
  orderStepTrackerResponseItem,
  inputMaterial,
  handleAddRow,
  inputIndex,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();

  const materialTitle = useMemo(() => {
    return getProductionPageInputMaterialTitle(
      inputIndex,
      orderStepTrackerResponseItem
    );
  }, [inputIndex, orderStepTrackerResponseItem]);

  const materialLink =
    `${Routes.materialManagement.hyperlink}/${inputMaterial.id}`?.replace(
      ":id/",
      ""
    );

  return (
    <div className={classNames("w-full flex items-center gap-x-5 mb-2", {})}>
      <Popover
        content={
          <Alert
            showIcon
            type="info"
            message={t("orders.hyperlink-material")}
          />
        }
      >
        <Title
          level={5}
          className={classNames(
            "truncate !m-0 text-left !text-blue-500 hover:underline cursor-pointer",
            {
              "!text-[12px]": !isDesktop,
            }
          )}
          onClick={() => openHyperlinkInTab(materialLink)}
        >
          {materialTitle}
        </Title>
      </Popover>

      <Popover content={t("orders.add-fields")}>
        <PlusCircleOutlined
          className={classNames(
            "ml-3 text-[25px] text-green-100 cursor-pointer",
            {
              "!text-[15px]": !isDesktop,
            }
          )}
          onClick={() => handleAddRow(inputIndex)}
        />
      </Popover>
    </div>
  );
};

export { MaterialName };
