import { SubmitCreateMaterialValues } from "shared/types";
import { useEffect, useState } from "react";
import { adaptCompaniesIsSuppliers } from "shared/adapters";
import { SupplierItemWithCost, useGetAllCompaniesQuery } from "shared/api";

interface useAddSuppliersProps {
  values: SubmitCreateMaterialValues;
}

export const useAddSuppliers = ({ values }: useAddSuppliersProps) => {
  const { data: companies, isLoading } = useGetAllCompaniesQuery({});

  const initialSupplierDynamicRow = {
    supplier: null,
    cost_per_unit_currency: null,
    cost_per_unit: null,
  };

  const companiesAdapted = adaptCompaniesIsSuppliers(companies || []);

  const ids = values?.suppliers?.map(
    (supplier) => supplier?.supplier?.id || supplier?.supplier
  );

  const unselectedCompanies = companiesAdapted.filter((company) => {
    return !ids.includes(company?.value);
  });

  const [supplierIds, setSupplierIds] = useState<
    number[] | SupplierItemWithCost[]
  >([]);

  useEffect(() => {
    setSupplierIds(values.suppliers);
  }, [values.suppliers]);

  return {
    unselectedCompanies,
    supplierIds,
    isLoading,
    companiesAdapted,
    initialSupplierDynamicRow,
  };
};
