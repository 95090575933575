import * as Yup from "yup";

export const editTemplateConfigSchema = Yup.object().shape({
  name: Yup.string().required("reports.name-validation"),
  template_id: Yup.string().required("reports.template-id-validation"),
});

export const addTemplateConfigSchema = Yup.object().shape({
  name: Yup.string().nullable().required("reports.name-validation"),
  template_id: Yup.string()
    .nullable()
    .required("reports.template-id-validation"),
  report_type: Yup.string()
    .nullable()
    .required("reports.template-id-validation"),
  is_default: Yup.boolean()
    .nullable()
    .required("reports.template-id-validation"),
});
