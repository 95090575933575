import { Modal } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { disableAddSuppliersButton } from "shared/helpers";
import { SupplierItemWithCost } from "shared/api";
import { SubmitCreateMaterialValues } from "shared/types";
import { CompaniesSelect } from "./CompaniesSelect";

interface AddSupplierToMaterialModalProps {
  isOpen: boolean;
  hideAddNewSuppliersModal: () => void;
}

const AddSupplierToMaterialModal: FC<AddSupplierToMaterialModalProps> = ({
  isOpen,
  hideAddNewSuppliersModal,
}) => {
  const { values, setFieldValue } =
    useFormikContext<SubmitCreateMaterialValues>();

  const { t } = useTranslation();

  const { name, suppliers } = values;

  const [suppliesBeforeUpdate, setSuppliesBeforeUpdate] = useState<
    SupplierItemWithCost[]
  >([]);

  useEffect(() => {
    if (isOpen) {
      setSuppliesBeforeUpdate(suppliers);
    }
  }, [isOpen]);

  const closeModal = () => {
    setFieldValue("suppliers", suppliesBeforeUpdate);
    hideAddNewSuppliersModal();
  };

  return (
    <Modal
      width="850px"
      open={isOpen}
      centered
      onCancel={closeModal}
      onOk={hideAddNewSuppliersModal}
      okButtonProps={{
        disabled: disableAddSuppliersButton(values?.suppliers),
        style: {
          background: `${Colors.blue.primary}`,
          color: "white",
        },
      }}
    >
      <div className="h-[650px] overflow-y-auto">
        <Title level={5}>
          {t("material-management.add-new-suppliers")} {name}
        </Title>

        <CompaniesSelect />
      </div>
    </Modal>
  );
};

export { AddSupplierToMaterialModal };
