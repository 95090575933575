import { InitialInputMaterials } from "shared/types";
import { useMemo } from "react";
import {
  adaptMaterialItems,
  adaptMaterials,
  adaptMaterialStockBatches,
  getInitialInputMaterialIds,
} from "shared/adapters";
import {
  useGetAllMaterialItemsQuery,
  useGetAllMaterialsNoPaginationQuery,
  useGetMaterialStockBatchesWithoutPaginationQuery,
} from "shared/api";
import { getInputMaterialIds } from "shared/helpers";

interface UseProductionInputMaterialsContainerDataProps {
  adaptedInitialInputMaterials: InitialInputMaterials[];
}

export const useProductionInputMaterialsContainerData = ({
  adaptedInitialInputMaterials,
}: UseProductionInputMaterialsContainerDataProps) => {
  const initialInputMaterialIds = useMemo(() => {
    return getInitialInputMaterialIds(adaptedInitialInputMaterials);
  }, [adaptedInitialInputMaterials]);

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery(
      {
        id__in: getInputMaterialIds(initialInputMaterialIds),
      },
      {
        skip: !initialInputMaterialIds,
      }
    );

  const { data: materialItems, isLoading: isLoadingMaterialItems } =
    useGetAllMaterialItemsQuery(
      {
        material_stock_batch__material__in: initialInputMaterialIds,
        expand: "material_stock_batch.material.material_category.unit",
      },
      {
        skip: !initialInputMaterialIds,
      }
    );

  const {
    data: materialStockBatches,
    isLoading: isLoadingMaterialStockBatches,
  } = useGetMaterialStockBatchesWithoutPaginationQuery({});

  const adaptedMaterialItems = useMemo(() => {
    return adaptMaterialItems(materialItems || []);
  }, [materialItems]);

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials || []);
  }, [materials]);

  const adaptedStockMaterialBatches = useMemo(() => {
    return adaptMaterialStockBatches(materialStockBatches || []);
  }, [materialStockBatches]);

  const isLoading =
    isLoadingMaterials ||
    isLoadingMaterialItems ||
    isLoadingMaterialStockBatches;

  return {
    adaptedMaterialItems,
    adaptedMaterials,
    adaptedStockMaterialBatches,
    materials,
    materialItems,
    materialStockBatches,
    isLoading,
  };
};
