import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { renderReportTypeTitle } from "shared/helpers";
import { GroupedReports } from "shared/hooks/useExecuteReportsData";
import { useFormikContext } from "formik";
import { ExecuteReportValues } from "shared/types";
import { Modal } from "antd";
import { Colors } from "shared/themes";
import { useTranslation } from "react-i18next";
import { ReportSidebarItem } from "./ReportSidebarItem";

interface ReportsSidebarProps {
  groupedQuests: GroupedReports;
  pdfUrl: string | null;
  clearPdfUrl: () => void;
}

const ReportsSidebar: FC<ReportsSidebarProps> = ({
  groupedQuests,
  pdfUrl,
  clearPdfUrl,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });
  const { values, setFieldValue } = useFormikContext<ExecuteReportValues>();

  const confirmChangeReportAlert = (reportId: number) => {
    setFieldValue("id", reportId);
    clearPdfUrl();
  };

  const selectReport = (reportId: number) => {
    if (pdfUrl) {
      Modal.confirm({
        title: t("changeReportAlert"),
        width: "450px",
        onOk: () => confirmChangeReportAlert(reportId),
        okButtonProps: {
          style: { background: `${Colors.blue.primary}` },
        },
      });
    } else {
      setFieldValue("id", reportId);
    }
  };

  return (
    <div
      className="
        min-w-[250px] max-w-[350px] w-auto p-2
        h-[89vh] border-r-[1px] border-black
        overflow-y-auto sidebar
      "
    >
      <div>
        {Object.entries(groupedQuests).map(([reportType, reports]) => (
          <div key={reportType} className="mb-2">
            <Title
              level={5}
              className={classNames("!mb-0 pl-1 bg-blue-primary !text-white", {
                // TODO: Ask for the correct colors
                // "bg-blue-400": reportType === ReportType.MATERIAL_ITEM,
                // "bg-green-400": reportType === ReportType.MULTIPLE_MATERIAL,
                // "bg-blue-50": reportType === ReportType.MULTIPLE_ORDER,
                // "bg-yellow-400": reportType === ReportType.MULTIPLE_PACKAGING,
                // "bg-pink-400": reportType === ReportType.ORDER,
                // "bg-purple-300": reportType === ReportType.PACKAGING,
                // "bg-orange-300": reportType === ReportType.REEL_LAB_ARCHIVE,
              })}
            >
              {renderReportTypeTitle(reportType)}
            </Title>
            <div className="flex flex-col gap-y-2 p-1">
              {reports.map((report) => {
                return (
                  <ReportSidebarItem
                    key={report?.id}
                    report={report}
                    onClick={() => selectReport(report?.id)}
                    isSelected={values.id === report?.id}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ReportsSidebar };
