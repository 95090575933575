import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  ConfigurationItem,
  ConfigurationType,
  useAddConfigurationFieldMutation,
  useUpdateConfigurationFieldMutation,
  useUploadConfigurationImageMutation,
} from "shared/api";
import { handleRequestError } from "shared/helpers";
import { ConfigurationForm } from "shared/ui";

interface UseConfigurationsProps {
  uploadedFile: File | null;
  closeModalAndClearUploadedImage: () => void;
  values: ConfigurationForm;
  companyNameConfiguration?: ConfigurationItem;
  timezoneConfiguration?: ConfigurationItem;
}

export const useConfigurations = ({
  uploadedFile,
  closeModalAndClearUploadedImage,
  values,
  companyNameConfiguration,
  timezoneConfiguration,
}: UseConfigurationsProps) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [uploadConfigurationImage] = useUploadConfigurationImageMutation();
  const [addConfigurationField] = useAddConfigurationFieldMutation();
  const [updateConfigurationField] = useUpdateConfigurationFieldMutation();

  const handleCustomerImageLogo = async () => {
    try {
      if (uploadedFile) {
        const formData = new FormData();
        formData.append("file", uploadedFile);
        await uploadConfigurationImage({ image: formData });
      }

      api.success({
        message: t("sidebar.logoSuccessUpload"),
      });
    } catch (error) {
      handleRequestError(error, t("sidebar.error"));

      api.error({
        message: t("sidebar.error"),
      });
    } finally {
      closeModalAndClearUploadedImage();
    }
  };

  const handleAddCompanyName = async () => {
    try {
      await addConfigurationField({
        key: ConfigurationType.CompanyName,
        value: values?.companyName!,
        file: null,
      }).unwrap();

      api.success({
        message: t("users-page.success-title"),
      });
    } catch (error) {
      api.error({
        message: t("sidebar.error"),
      });
    }
  };

  const handleUpdateCompanyName = async () => {
    try {
      await updateConfigurationField({
        key: ConfigurationType.CompanyName,
        value: values?.companyName!,
        file: null,
      }).unwrap();

      api.success({
        message: t("users-page.success-title"),
      });
    } catch (error) {
      api.error({
        message: t("sidebar.error"),
      });
    }
  };

  const handleAddTimezone = async () => {
    try {
      await addConfigurationField({
        key: ConfigurationType.Timezone,
        value: values?.timezone!,
        file: null,
      }).unwrap();

      api.success({
        message: t("users-page.success-title"),
      });
    } catch (error) {
      api.error({
        message: t("sidebar.error"),
      });
    }
  };

  const handleUpdateTimezone = async () => {
    try {
      await updateConfigurationField({
        key: ConfigurationType.Timezone,
        value: values?.timezone!,
        file: null,
      }).unwrap();

      api.success({
        message: t("users-page.success-title"),
      });
    } catch (error) {
      api.error({
        message: t("sidebar.error"),
      });
    }
  };

  const handleConfigurationsRequests = async () => {
    await handleCustomerImageLogo();

    if (timezoneConfiguration) {
      await handleUpdateTimezone();
    } else {
      await handleAddTimezone();
    }

    if (companyNameConfiguration) {
      await handleUpdateCompanyName();
    } else {
      await handleAddCompanyName();
    }
  };

  return { handleConfigurationsRequests, contextHolder };
};
