import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { Button, Popover, Switch } from "antd";
import { useTranslation } from "react-i18next";
import {
  CheckOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  addNewRowButton,
  formRowButtonContainer,
  iconScale,
  removeRowButton,
} from "shared/ui/DynamicForm/styles";
import { pushNewRowInitialValues } from "shared/constants";

import { DynamicFormsValuesType } from "./constants";

interface ButtonsContainerProps {
  index: number;
  fieldIndex: number;
  push: (obj: any) => void;
  remove: (index: number) => void | undefined;
}

const ButtonsContainer: FC<ButtonsContainerProps> = ({
  index,
  fieldIndex,
  push,
  remove,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<DynamicFormsValuesType>();

  const requiredValue =
    values.sections?.[index]?.fields?.[fieldIndex]?.required;

  return (
    <>
      <div className="w-[10%] h-[77px] flex items-center justify-center flex-col">
        <Title level={5} className="!h-1/2 justify-start">
          {t("material-management.required")}
        </Title>

        <div className="!h-1/2">
          <Switch
            key={`sections[${index}].fields[${fieldIndex}].required`}
            checked={requiredValue}
            checkedChildren={
              <CheckOutlined className="!text-green-100 !brightness-150 mt-1" />
            }
            unCheckedChildren={
              <MinusOutlined className="!text-white !mt-2.5 !brightness-150" />
            }
            className="!bg-blue-primary"
            onChange={(value) => {
              setFieldValue(
                `sections[${index}].fields[${fieldIndex}].required`,
                value
              );
            }}
          />
        </div>
      </div>

      <div style={formRowButtonContainer}>
        <Popover
          title={t("material-category-management.add-row-popover")}
          className="w-auto"
        >
          <Button
            onClick={() => push(pushNewRowInitialValues)}
            type="primary"
            style={addNewRowButton}
          >
            <PlusOutlined style={iconScale} />
          </Button>
        </Popover>

        <Popover title={t("material-category-management.remove-row-popover")}>
          <Button
            danger
            type="primary"
            disabled={values?.sections?.[index]?.fields?.length <= 1}
            onClick={() => remove(fieldIndex)}
            style={removeRowButton}
          >
            <DeleteOutlined style={iconScale} />
          </Button>
        </Popover>
      </div>
    </>
  );
};

export { ButtonsContainer };
