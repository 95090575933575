import { Button } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NewSectionNameModal } from "shared/ui/NewSectionNameModal";

interface AddNewSectionProps {
  showNewSectionModal: () => void;
  isOpenNewSectionModal: boolean;
  hideNewSectionModal: () => void;
  push: (obj: any) => void;
}

const AddNewSection: FC<AddNewSectionProps> = ({
  showNewSectionModal,
  isOpenNewSectionModal,
  hideNewSectionModal,
  push,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="primary"
        className="btn-primary--dark"
        onClick={showNewSectionModal}
      >
        {t("material-category-management.add-section")}
      </Button>

      <NewSectionNameModal
        isOpenNewSectionModal={isOpenNewSectionModal}
        hideNewSectionModal={hideNewSectionModal}
        push={push}
      />
    </>
  );
};

export { AddNewSection };
