import { useMemo } from "react";
import { adaptCompanies } from "shared/adapters";
import { useGetAllCompaniesQuery } from "shared/api";

export const useStockManagementData = () => {
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetAllCompaniesQuery({});

  const companiesAdapted = useMemo(() => {
    return adaptCompanies(companies || []);
  }, [companies]);

  return {
    companiesData: {
      companiesAdapted,
      isLoading: isLoadingCompanies,
    },
  };
};
