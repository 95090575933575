import { Select } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { UnitItemWithLabel } from "shared/api";

interface MultipleSelectWithLabelsProps {
  value: any;
  fieldName: string;
  placeholder: string;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  error?: string;
  isError?: boolean;
  showSearch?: boolean;
  maxTagCount?: number;
  maxTagTextLength?: number;
  disabled?: boolean;
  optionsWithLabels?: UnitItemWithLabel[] | string[];
}

const MultipleSelectWithLabels: FC<MultipleSelectWithLabelsProps> = ({
  value,
  fieldName,
  placeholder,
  handleBlur,
  setFieldValue,
  error,
  isError,
  showSearch = true,
  optionsWithLabels,
  disabled,
  maxTagCount,
  maxTagTextLength,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Select
        bordered
        placeholder={placeholder}
        labelInValue
        size="large"
        className="w-full mb-1.25"
        onBlur={handleBlur}
        onSelect={(item) => {
          setFieldValue(`${fieldName}`, [...value, item.value]);
        }}
        onDeselect={(item) => {
          setFieldValue(
            `${fieldName}`,
            value.filter((option: any) => option !== item.value)
          );
        }}
        value={value}
        options={optionsWithLabels as UnitItemWithLabel[]}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        showSearch={showSearch}
        disabled={disabled}
        mode="multiple"
        maxTagCount={maxTagCount}
        maxTagTextLength={maxTagTextLength}
      />
      {!!isError && <div className="text-red-100 mt-1px">{t(`${error}`)}</div>}
    </>
  );
};

export { MultipleSelectWithLabels };
