import { MachineItem } from "shared/api/productCard/models";

export const adaptMachines = (allMaterials: MachineItem[]) => {
  const adaptedMachines = allMaterials?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return adaptedMachines || [];
};

export const adaptMachinesToGroup = (machines: MachineItem[]) => {
  const groups = machines?.map(
    (machine) =>
      ({
        id: machine.id,
        group: machine.id,
        title: machine.name,
        process_template: machine.process_template,
      } || [])
  );
  return groups;
};
