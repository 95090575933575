import {
  MaterialCategoryFieldItem,
  OrderStepTrackerResponseItem,
} from "shared/api";
import * as Yup from "yup";

export const AddOrUpdatePackageSchema = Yup.object().shape({
  name: Yup.string().nullable().required("packaging.name-validation"),
  material: Yup.number().nullable().required("packaging.material-validation"),
});

export const addProductionItemSchema = Yup.array().of(
  Yup.object().shape({
    consumptionType: Yup.string(),
    fields: Yup.array().of(
      Yup.object().shape({
        selectValue: Yup.string().required(),

        variant: Yup.number()
          .when("selectValue", {
            is: (selectValue: string) =>
              ["material_item", "material_stock_batch"].includes(selectValue),
            then: Yup.number().required(),
            otherwise: Yup.number().optional().nullable(),
          })
          .test(
            "variant",
            "variant must be null when selectValue is material",
            // eslint-disable-next-line func-names
            function (value) {
              const { selectValue } = this.parent;
              return selectValue !== "material" || value === null;
            }
          ),

        quantity: Yup.number().when("consumptionType", {
          is: (consumptionType: string) => ["MA"].includes(consumptionType),
          then: Yup.number().required(),
          otherwise: Yup.number().optional().nullable(),
        }),
      })
    ),
  })
);

export function outputMaterialsSchema(
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) {
  const configFields =
    orderStepTrackerResponseItem?.process_step.output_materials?.map(
      (material) =>
        material?.material?.material_category?.config_dynamic_tracker_fields
    );

  const flatConfigFields = configFields?.flat();

  const fieldConfig = flatConfigFields?.reduce<{
    [key: string]: MaterialCategoryFieldItem;
  }>((acc, curr) => {
    curr?.fields?.forEach((field) => {
      acc[field.name] = field;
    });
    return acc;
  }, {});

  return Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      materialName: Yup.string(),
      config_data: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          fields: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(),
              type: Yup.string(),
              required: Yup.boolean(),
              description: Yup.string(),
            })
          ),
        })
      ),

      dynamicData: Yup.array()
        .of(
          Yup.object().shape({
            quantity: Yup.string().required("Quantity is required"),
            status: Yup.string().required("Status is required"),

            dynamic_tracker_fields: Yup.lazy((value) =>
              value
                ? Yup.object(
                    Object.keys(value).reduce(
                      (shape: { [key: string]: any }, key) => {
                        shape[key] = Yup.lazy((nestedValue) =>
                          nestedValue
                            ? Yup.object(
                                Object.keys(nestedValue).reduce(
                                  (
                                    nestedShape: { [nestedKey: string]: any },
                                    nestedKey
                                  ) => {
                                    const field = fieldConfig?.[nestedKey];

                                    let validator;
                                    if (field?.required) {
                                      if (
                                        field?.hard_minimum !== null &&
                                        field?.hard_minimum !== undefined &&
                                        field?.hard_maximum !== null &&
                                        field?.hard_maximum !== undefined
                                      ) {
                                        validator = Yup.number()
                                          // Commented hard & soft limits validations
                                          // But don't remove this code because we can move this logic back

                                          // .min(
                                          //   field.hard_minimum,
                                          //   `${nestedKey} must be at least ${field.hard_minimum}`
                                          // )
                                          // .max(
                                          //   field.hard_maximum,
                                          //   `${nestedKey} must be at most ${field.hard_maximum}`
                                          // )
                                          .required(`${nestedKey} is required`);
                                      } else {
                                        validator = Yup.mixed()
                                          .test(
                                            "isNumberOrString",
                                            `${nestedKey} must be a number or a non-empty string`,
                                            (value) => {
                                              if (typeof value === "string") {
                                                return value !== "";
                                              }
                                              return typeof value === "number";
                                            }
                                          )
                                          .required(`${nestedKey} is required`);
                                      }
                                    } else {
                                      validator = Yup.mixed().notRequired();
                                    }

                                    nestedShape[nestedKey] = validator;

                                    return nestedShape;
                                  },
                                  {}
                                )
                              )
                            : Yup.mixed().notRequired()
                        );
                        return shape;
                      },
                      {}
                    )
                  )
                : Yup.mixed().notRequired()
            ),
          })
        )
        .required("Dynamic data is required"),
    })
  );
}
