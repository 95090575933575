import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { todayMarketStyles } from "shared/constants";

interface TodayMarkerLineProps {
  styles: React.CSSProperties;
}

const TodayMarkerLine: FC<TodayMarkerLineProps> = ({ styles }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        ...styles,
        backgroundColor: todayMarketStyles.backgroundColor,
        width: todayMarketStyles.width,
        zIndex: todayMarketStyles.zIndex,
      }}
    >
      <div className={todayMarketStyles.currentTimeLabel}>
        {t("planner.now")}
      </div>
    </div>
  );
};

export { TodayMarkerLine };
