import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Page } from "shared/ui";
import { useModal } from "shared/hooks";
import { noop } from "shared/helpers";
import { adaptProcessTemplates } from "shared/adapters";
import { useParams } from "react-router-dom";
import {
  useGetAppProcessTemplatesWithNoParamsQuery,
  useGetMachineByIdQuery,
} from "shared/api";
import { expandMachinesString, machinesInitialValues } from "shared/constants";
import { AddOrEditMachineSchema } from "shared/schemas";
import { MachinesTable, AddMachineModal, EditMachineModal } from "./ui";

interface MachinesProps {}

const Machines: FC<MachinesProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: machine } = useGetMachineByIdQuery(
    {
      expand: expandMachinesString,
      id: Number(id),
    },
    { skip: !id }
  );

  const [isOpenAddMachineModal, showAddMachineModal, hideAddMachineModal] =
    useModal();

  const [isOpenEditMachineModal, showEditMachineModal, hideEditMachineModal] =
    useModal();

  useEffect(() => {
    if (machine) {
      showEditMachineModal();
    }
  }, [machine]);

  const { data: processTemplates } =
    useGetAppProcessTemplatesWithNoParamsQuery();

  const adaptedProcessTemplates = useMemo(() => {
    return adaptProcessTemplates(processTemplates || []);
  }, [processTemplates]);

  const addOrUpdateMachineFormik = useFormik({
    initialValues:
      { ...machine, process_template: machine?.process_template?.id } ||
      machinesInitialValues,
    validationSchema: AddOrEditMachineSchema,
    enableReinitialize: true,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateMachineFormik}>
      <Page
        title={t("machines.machines-title")}
        buttonTitle={t("machines.add-machines-btn")!}
        onClick={showAddMachineModal}
      >
        <MachinesTable showEditMachineModal={showEditMachineModal} />

        <AddMachineModal
          isOpenModal={isOpenAddMachineModal}
          hideAddMachineModal={hideAddMachineModal}
          adaptedProcessTemplates={adaptedProcessTemplates}
        />

        <EditMachineModal
          isOpenModal={isOpenEditMachineModal}
          hideEditMachineModal={hideEditMachineModal}
          adaptedProcessTemplates={adaptedProcessTemplates}
          // machineId={Number(id)}
        />
      </Page>
    </FormikProvider>
  );
};

export { Machines };
