import {
  DynamicOrderObject,
  MaterialConsumedItem,
  OrderItem,
  OrderPlan,
  OrderProcessStepItem,
  OrdersResponse,
} from "shared/api";
import dayjs from "dayjs";
import {
  renderTimelineOrderStatuses,
  renderTimelineProcessStatuses,
} from "shared/helpers";
import { AdaptedValues } from "shared/types";

export const changeOrdersDateFormat = (orders: OrdersResponse) => {
  const ordersWithChangedDate = orders
    ? orders?.results?.map((item) => {
        return {
          ...item,
          wanted_delivery_datetime: dayjs
            .utc(item?.wanted_delivery_datetime)
            .format("YYYY-MM-DD"),
          production_datetime: dayjs
            .utc(item?.production_datetime)
            .format("YYYY-MM-DD"),
        };
      })
    : [];
  return ordersWithChangedDate;
};

export const adaptOrderPlansForMachines = (
  selectedOrderPlan: OrderPlan | null
) => {
  const adaptedOrderPlansForMachines =
    selectedOrderPlan?.order_plan_for_machine?.map((order, index) => {
      const id = order?.id;
      const group = order?.machine;
      const start_time = dayjs.utc(order?.start_datetime);
      const end_time = dayjs.utc(order?.end_datetime);
      const className = `
          !border-black !text-[12px] !flex !flex-col
          !items-center !justify-center !p-[2px]
      `;
      const orderProcessStepTrackerId = order?.order_process_step_tracker.id;
      const orderValue = `${selectedOrderPlan.order_plan_for_machine?.[index]?.order_process_step_tracker?.order.id}`;
      const positionInOrder = `${selectedOrderPlan.order_plan_for_machine?.[index]?.order_process_step_tracker?.calc_position_in_order}`;
      const countProcessStepTrackers = `${selectedOrderPlan.order_plan_for_machine?.[index]?.order_process_step_tracker?.order?.calc_count_process_step_trackers}`;
      const processStepName = `${selectedOrderPlan.order_plan_for_machine?.[index]?.order_process_step_tracker?.process_step?.name}`;

      const itemTitle = `Order ${orderValue} - ${positionInOrder}/${countProcessStepTrackers} - ${processStepName}`;
      return {
        id,
        group,
        start_time,
        end_time,
        title: itemTitle,
        className,
        orderProcessStepTrackerId,
      };
    });

  return adaptedOrderPlansForMachines;
};

export const transformObject = (inputObject: DynamicOrderObject) => {
  const outputArray = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const cardKey in inputObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (inputObject.hasOwnProperty(cardKey)) {
      const card = inputObject[cardKey];
      // eslint-disable-next-line no-restricted-syntax
      for (const fieldKey in card) {
        // eslint-disable-next-line no-prototype-builtins
        if (card.hasOwnProperty(fieldKey)) {
          const value = card[fieldKey];
          outputArray.push({
            title: cardKey,
            field: fieldKey,
            value,
          });
        }
      }
    }
  }

  return outputArray;
};

export const adaptStepTrackers = (
  orderProcessStepTrackers: OrderProcessStepItem[]
) => {
  const adaptedStepTrackers = orderProcessStepTrackers?.map(
    (item) =>
      ({
        value: item.id,
        label: `Order ${item.order.id} - ${renderTimelineOrderStatuses(
          item
        )}. ${item.process_step.name} - ${renderTimelineProcessStatuses(
          item
        )}.`,
        shortLabel: `${item.process_step.name}`,
      } || [])
  );
  return adaptedStepTrackers;
};

export const adaptOrders = (orders: OrderItem[]): AdaptedValues[] => {
  const adaptedOrders = orders?.map((order) => ({
    value: order?.id,
    label: `Order - ${order?.order_code}`,
  }));

  return adaptedOrders;
};

export const adaptOrdersForExecuteReports = (
  orders: OrderItem[]
): AdaptedValues[] => {
  const adaptedOrders = orders?.map((order) => ({
    value: order?.id,
    label: order?.order_code!,
  }));

  return adaptedOrders;
};

export const adaptMaterialConsumedForRowSelection = (
  materialConsumed?: MaterialConsumedItem[]
) => {
  return materialConsumed?.map((item) => ({
    ...item,
    key: item?.id,
  })) as MaterialConsumedItem[];
};

export const adaptConsumedMaterials = (
  selectedConsumedMaterials: MaterialConsumedItem[]
) => {
  return selectedConsumedMaterials.map((material) => ({
    consumed_datetime: material?.consumed_datetime,
    quantity: material?.quantity,
    order_production_set: material?.order_production_set,
    material: material?.material?.id,
    material_stock_batch: material?.material_stock_batch?.id,
    material_item: material?.material_item?.id,
    reason: material?.reason,
    status: material?.status,
  }));
};
