import { MaterialsConsumedResponse } from "shared/api";
import dayjs from "dayjs";

export const changeMaterialConsumedDateFormat = (
  materialConsumed: MaterialsConsumedResponse
) => {
  const ordersWithChangedDate = materialConsumed
    ? materialConsumed?.results?.map((item) => {
        return {
          ...item,
          consumed_datetime: dayjs
            .utc(item?.consumed_datetime)
            .format("YYYY-MM-DD"),
        };
      })
    : [];
  return ordersWithChangedDate;
};
