import { useMemo, useState } from "react";
import { OrderItem } from "shared/api";
import { useNavigate } from "react-router-dom";
import { useModal } from "./useModal";

export const useProductionExtruder = (
  order?: OrderItem,
  processStepParam?: string
) => {
  const [processStepIndex, setProcessStepIndex] = useState(
    () => Number(processStepParam) || 0
  );

  const resetProcessStepIndex = () => setProcessStepIndex(0);

  const [
    isOpenDynamicOrderStepsModal,
    showOpenDynamicOrderStepsModal,
    hideOpenDynamicOrderStepsModal,
  ] = useModal();
  const navigate = useNavigate();

  const selectedOrder = useMemo(() => {
    if (order) {
      showOpenDynamicOrderStepsModal();
      return order.process_step_trackers?.[processStepIndex] || null;
    }
    return null;
  }, [order, processStepIndex, showOpenDynamicOrderStepsModal]);

  const updateProcessStepIndex = (newIndex: number) => {
    setProcessStepIndex(newIndex);
    navigate(`/process-step-production/${order?.order_code}/index-${newIndex}`);
  };

  const incrementProcessStepIndex = () => {
    const lastIndex = order?.process_step_trackers?.length
      ? order.process_step_trackers.length - 1
      : -1;
    if (processStepIndex < lastIndex) {
      updateProcessStepIndex(processStepIndex + 1);
    }
  };

  const decrementProcessStepIndex = () => {
    if (processStepIndex > 0) {
      updateProcessStepIndex(processStepIndex - 1);
    }
  };

  const clearSelectedOrder = () => setProcessStepIndex(0);

  return {
    selectedOrder,
    incrementProcessStepIndex,
    decrementProcessStepIndex,
    isOpenDynamicOrderStepsModal,
    hideOpenDynamicOrderStepsModal,
    clearSelectedOrder,
    resetProcessStepIndex,
  };
};
