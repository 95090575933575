import { AddDynamicOrderSteps, DropdownValues } from "shared/types";
import { useEffect, useState } from "react";

interface useDynamicInputMaterialsProductionDataProps {
  dynamicStockBatchValue: DropdownValues[];
  values: AddDynamicOrderSteps;
}

export const useDynamicInputMaterialsProductionData = ({
  dynamicStockBatchValue,
  values,
}: useDynamicInputMaterialsProductionDataProps) => {
  const [dynamicInputMaterials, setDynamicInputMaterials] = useState<
    DropdownValues[]
  >([]);

  useEffect(() => {
    setDynamicInputMaterials(dynamicStockBatchValue);
  }, [dynamicStockBatchValue]);

  const getMaterialIds = () => {
    return values?.input_materials?.flatMap((item) =>
      item?.fields?.flatMap((field) => field?.variant)
    );
  };

  const updateDynamicInputMaterials = (listOfIds: string[]) => {
    const updatedDynamicStockBatchValue = dynamicStockBatchValue?.filter(
      (item) => !listOfIds?.includes(item?.value as string)
    );

    setDynamicInputMaterials(updatedDynamicStockBatchValue);
  };

  useEffect(() => {
    const listOfIds = getMaterialIds();

    if (listOfIds) {
      updateDynamicInputMaterials(listOfIds);
    }
  }, [values?.input_materials, dynamicStockBatchValue]);

  return { dynamicInputMaterials };
};
