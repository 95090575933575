import React, { FC } from "react";
import { Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  useConfirmDeleteModal,
  useGetProcessTemplatesColumns,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import {
  useDeleteProcessTemplateMutation,
  useGetAppProcessTemplatesQuery,
  ProcessTemplateItem,
} from "shared/api";
import { AddProcessTemplatesValues } from "shared/types";

interface ProcessTemplatesTableProps {
  showUpdateProcessTemplateModal: () => void;
}

const ProcessTemplatesTable: FC<ProcessTemplatesTableProps> = ({
  showUpdateProcessTemplateModal,
}) => {
  const { t } = useTranslation();

  const { setValues } = useFormikContext<AddProcessTemplatesValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    data: processTemplates,
    isLoading: isLoadingProcessTemplates,
    isFetching: isFetchingProcessTemplates,
  } = useGetAppProcessTemplatesQuery({
    offset,
    limit,
    ordering,
  });

  const [api, contextHolder] = notification.useNotification();

  const [deleteProcessTemplate] = useDeleteProcessTemplateMutation();

  const deleteProcessTemplateHandler = async (record: ProcessTemplateItem) => {
    try {
      await deleteProcessTemplate(record).unwrap();
      api.success({
        message: t("process-templates.success"),
        description: t("process-templates.success-delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("process-templates.error")
      );

      api.error({
        message: t("process-templates.error"),
        description,
      });
    }
  };

  const showDeleteProcessItemModal = useConfirmDeleteModal(
    t("process-templates.delete-alert-msg"),
    deleteProcessTemplateHandler
  );

  const editProcessTypeHandler = (record: ProcessTemplateItem) => {
    showUpdateProcessTemplateModal();
    setValues({
      id: record?.id,
      name: record?.name,
      description: record?.description,
      sections: record?.config_dynamic_process_step_fields,
      abbreviation: record?.abbreviation,
      image: record?.image,
    });
  };

  const columns = useGetProcessTemplatesColumns({
    t,
    editProcessTypeHandler,
    showDeleteProcessItemModal,
    changeSort,
  });

  return (
    <>
      {contextHolder}
      <Table
        loading={isLoadingProcessTemplates || isFetchingProcessTemplates}
        columns={columns}
        dataSource={processTemplates?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: processTemplates?.count,
        }}
      />
    </>
  );
};

export { ProcessTemplatesTable };
