import { FileImageOutlined } from "@ant-design/icons";
import { Image } from "antd";
import classNames from "classnames";
import React, { FC } from "react";

interface TableImageProps {
  image?: string | null;
  preview?: boolean;
  className?: string;
  onClick?: () => void;
}

const TableImage: FC<TableImageProps> = ({
  image,
  preview = true,
  className,
  onClick,
}) => {
  return image ? (
    <div
      className={classNames(
        "w-[50px] h-[50px] overflow-hidden flex items-center justify-center",
        {},
        className
      )}
    >
      <Image
        src={image}
        className="w-full h-full object-contain"
        preview={preview}
        onClick={onClick}
      />
    </div>
  ) : (
    <div className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-gray-300">
      <FileImageOutlined className="scale-150 !object-contain" />
    </div>
  );
};

export { TableImage };
