import { Divider, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterials } from "shared/adapters";
import { useGetAllMaterialsNoPaginationQuery } from "shared/api";
import { selectFiltering } from "shared/helpers";
import { Colors } from "shared/themes";
import { AdaptedValues, ExecuteReportValues } from "shared/types";
import { FormSelect, Loader } from "shared/ui";

interface MultipleItemFilterProps {
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
}

const MultipleItemFilter: FC<MultipleItemFilterProps> = ({
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values, setFieldValue, handleBlur } =
    useFormikContext<ExecuteReportValues>();

  const expandOnOrderChange = (value: number) => {
    onOrderChange(value);
    setFieldValue("order_id", value);
  };

  const {
    data: materials,
    isLoading,
    isFetching,
  } = useGetAllMaterialsNoPaginationQuery(
    {
      order: selectedOrderId ?? undefined,
    },
    {
      skip: !selectedOrderId,
    }
  );

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials ?? []);
  }, [materials]);

  if (isLoading || isFetching) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />

      <div className="flex gap-x-3">
        <div className="flex gap-x-2">
          <Title level={5} className="!w-[100px] !m-0">
            {t("selectOrder")}
          </Title>
          {" - "}
          <Select
            value={selectedOrderId}
            placeholder={t("selectOrder")}
            options={adaptedOrders}
            onChange={expandOnOrderChange}
            className="w-full"
            size="small"
            showSearch
            filterOption={(input, option) => selectFiltering(input, option)}
          />
        </div>

        {selectedOrderId && (
          <>
            <Divider
              type="vertical"
              className="h-full"
              style={{ background: Colors.blue.primary }}
            />

            <div className="flex gap-x-2">
              <Title level={5} className="!w-[110px]  !m-0">
                {t("selectMaterial")}
              </Title>
              {" - "}
              <FormSelect
                value={values?.material_id}
                placeholder={t("selectMaterial")}
                fieldName="material_id"
                options={adaptedMaterials}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                showSearch
                size="small"
                className="!m-0"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { MultipleItemFilter };
