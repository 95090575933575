import React from "react";
import { Checkbox, Button } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CloseOutlined } from "@ant-design/icons";

interface MultipleOptionsTableFilterProps {
  options: { label: string; value: string }[];
  selectedValues: CheckboxValueType[];
  handleChange: (checkedValues: CheckboxValueType[]) => void;
  clearFilters?: () => void;
}

const MultipleOptionsTableFilter: React.FC<MultipleOptionsTableFilterProps> = ({
  options,
  selectedValues,
  handleChange,
  clearFilters,
}) => {
  return (
    <div className="p-5">
      <Checkbox.Group
        options={options}
        value={selectedValues}
        onChange={handleChange}
        className="p-0 m-0"
      />
      <Button
        onClick={() => {
          handleChange([]);
          clearFilters?.();
        }}
        className="btn-filter--x_mark w-full mt-3 mx-0"
        type="primary"
      >
        <CloseOutlined />
      </Button>
    </div>
  );
};

export { MultipleOptionsTableFilter };
