import { Table, notification } from "antd";
import React, { FC } from "react";
import {
  QualityResultItem,
  useDeleteQualityResultMutation,
  useGetQualityResultsQuery,
} from "shared/api/qualityResults";
import {
  useConfirmDeleteModal,
  usePagination,
  useQualityResultsDownload,
  useQualityResultsFilters,
  useQualityTableColumns,
  useTableSorting,
} from "shared/hooks";
import {
  adaptMaterialItemsToShowTableWithCheckbox,
  adaptStockBatchesToShowTableWithCheckbox,
  handleRequestError,
} from "shared/helpers";
import { useTranslation } from "react-i18next";
import { RenderClearFiltersButton } from "shared/ui";
import { useFormikContext } from "formik";
import { MaterialStockBatchItemResponse, MaterialStockItem } from "shared/api";
import { UploadDocumentValues } from "shared/types";
import { expandQualityResultsString, QualityOrdering } from "shared/constants";

interface QualityResultsTableProps {
  showUpdateResult: () => void;
  handleStockBatchesRows: (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => void;
  handleMaterialItemsRows: (materialItems: MaterialStockItem[]) => void;
}

const QualityResultsTable: FC<QualityResultsTableProps> = ({
  showUpdateResult,
  handleStockBatchesRows,
  handleMaterialItemsRows,
}) => {
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const { setValues } = useFormikContext<UploadDocumentValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    nameValue,
    debouncedNameValue,
    handleNameValue,
    clearNameValue,
    batchCodeValue,
    debouncedBatchCodeValue,
    clearBatchCodeValue,
    handleBatchCodeValue,
    serialNumberValue,
    debouncedSerialNumberValue,
    handleSerialNumberValue,
    clearSerialNumberValue,
    supplierValue,
    debouncedSupplierValue,
    handleSupplierValue,
    clearSupplierValue,
    dateGreater,
    handleDateGreater,
    dateLesser,
    handleDateLesser,
    isActiveFiltering,
    clearAllFilters,
    isDataFilterActive,
  } = useQualityResultsFilters();

  const {
    data: qualityResults,
    isLoading,
    isFetching,
  } = useGetQualityResultsQuery({
    offset,
    limit,
    ordering: ordering || QualityOrdering.TIMESTAMP_DESC,
    name__icontains: debouncedNameValue,
    material_stock_batches__material_stock_batch__batch_code:
      debouncedBatchCodeValue,
    material_items__material_item__serial_number: debouncedSerialNumberValue,
    material_stock_batches__material_stock_batch__supplier__name:
      debouncedSupplierValue,
    expand: expandQualityResultsString,
    timestamp__gte: dateGreater || "",
    timestamp__lte: dateLesser || "",
  });

  const openEditQualityResultModal = (record: QualityResultItem) => {
    showUpdateResult();

    setValues({
      id: record?.id,
      name: record?.name,
      result_type: record?.result_type,
      material: "",
      material_items: [],
      material_stock_batches: [],
      reel_lab_measurements: record?.reel_lab_measurements,
      thickness: record?.thickness,
      curl_md: record?.curl_md,
      curl_cd: record?.curl_cd,
      remark: record?.remark,
      document: record?.document,
      order: "",
    });

    handleStockBatchesRows(adaptStockBatchesToShowTableWithCheckbox(record));

    handleMaterialItemsRows(adaptMaterialItemsToShowTableWithCheckbox(record));
  };

  const [deleteQualityResult] = useDeleteQualityResultMutation();

  const deleteQualityResultHandler = async (record: QualityResultItem) => {
    try {
      await deleteQualityResult(record).unwrap();

      api.success({
        message: t("quality.success-title"),
        description: t("quality.success-delete-desc"),
      });
    } catch (error) {
      api.error({
        message: t("quality.error-title"),
        description: handleRequestError(error, t("quality.error-title")),
      });
    }
  };

  const showDeleteQualityResultModal = useConfirmDeleteModal(
    t("quality.delete-warning-message"),
    deleteQualityResultHandler
  );

  const { handleExportReelLabResult, handleExportReelLabArchive } =
    useQualityResultsDownload();

  const columns = useQualityTableColumns({
    t,
    openEditQualityResultModal,
    changeSort,
    handleDateGreater,
    handleDateLesser,
    batchCodeValue,
    clearBatchCodeValue,
    handleBatchCodeValue,
    serialNumberValue,
    supplierValue,
    clearSupplierValue,
    handleSupplierValue,
    handleExportReelLabResult,
    handleExportReelLabArchive,
    showDeleteQualityResultModal,
    isDataFilterActive,
    nameValue,
    handleNameValue,
    clearNameValue,
    handleSerialNumberValue,
    clearSerialNumberValue,
  });

  return (
    <>
      {contextHolder}

      {isActiveFiltering ? (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      ) : null}

      <Table
        columns={columns}
        dataSource={qualityResults?.results}
        rowKey={(obj) => obj.id!}
        loading={isLoading || isFetching}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: qualityResults?.count,
        }}
      />
    </>
  );
};

export { QualityResultsTable };
