import { Avatar, Button, Image, Upload, UploadFile, UploadProps } from "antd";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { AddProcessTemplatesValues } from "shared/types";

interface ProcessTemplateLogoProps {
  documentList: UploadFile[];
  uploadCompanyImageProps: UploadProps<any>;
  previewLogoImage: string | null;
}

const ProcessTemplateLogo: FC<ProcessTemplateLogoProps> = ({
  documentList,
  uploadCompanyImageProps,
  previewLogoImage,
}) => {
  const { t } = useTranslation();

  const { values } = useFormikContext<AddProcessTemplatesValues>();

  const isDocumentListLength = !!documentList.length;

  return (
    <div>
      {values?.image ? (
        <div className="w-full flex items-center justify-between ">
          <Title level={5} className="mb-1.25">
            {t("process-templates.logo")}
          </Title>

          <div className="flex gap-x-3">
            {!isDocumentListLength && (
              <Image src={values?.image!} className="!h-[30px] max-w-[80px]" />
            )}

            <Upload {...uploadCompanyImageProps}>
              <Button className="w-8 h-8 rounded-full p-0">
                <PlusOutlined className="p-0 mb-1" />
              </Button>
            </Upload>
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-between">
          <Title level={5} className="mb-1.25">
            {t("process-templates.logo")}
          </Title>

          <div>
            {previewLogoImage && (
              <Avatar src={previewLogoImage} alt="preview" className="ml-3" />
            )}

            <Upload {...uploadCompanyImageProps}>
              {!previewLogoImage && (
                <Button className="w-8 h-8 rounded-full p-0">
                  <PlusOutlined className="p-0 mb-1" />
                </Button>
              )}
            </Upload>
          </div>
        </div>
      )}
    </div>
  );
};

export { ProcessTemplateLogo };
