import { useEffect, useState } from "react";

export interface PollingData {
  nextRefreshTime: string | undefined;
  pollingInterval: number;
}

export const usePollingSeconds = () => {
  const POLLING_INTERVAL = 60000;

  const [nextRefreshTime, setNextRefreshTime] = useState<Date | null>(null);

  useEffect(() => {
    const updateNextRefreshTime = () => {
      setNextRefreshTime(new Date(Date.now() + POLLING_INTERVAL));
    };

    updateNextRefreshTime();
    const intervalId = setInterval(updateNextRefreshTime, POLLING_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return {
    pollingData: {
      nextRefreshTime: nextRefreshTime?.toLocaleTimeString(),
      pollingInterval: POLLING_INTERVAL,
    } as PollingData,
  };
};
