import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ConfigDynamicTrackerFields,
  useGetAllOrdersQuery,
  useUpdateOrderMutation,
} from "shared/api";
import { dayjs, handleRequestError, noop } from "shared/helpers";
import {
  DatePickerValue,
  GlobalDatePicker,
  FormInput,
  FormSelect,
  HyperlinkPageHeader,
  Loader,
} from "shared/ui";
import { useTranslation } from "react-i18next";
import { adaptDynamicFormValues } from "shared/adapters";
import { DynamicValues } from "shared/types";
import { Input, notification } from "antd";
import { useOrderHyperlinkData } from "shared/hooks";
import { StatusDropdownValues } from "shared/constants";
import { createAddOrOrderSchema } from "shared/schemas";
import { DynamicCompanyFields } from "./UI/DynamicCompanyFields";

const OrderHyperlink = () => {
  const { orderID } = useParams();
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const expandString = "product_card,buyer";

  const { data: orderResponse, isLoading: isLoadingOrder } =
    useGetAllOrdersQuery({
      order_code: orderID,
      limit: 1,
      expand: expandString,
    });

  const orderItem = orderResponse?.results?.[0];

  const [dynamicValues, setDynamicValues] = useState<
    DynamicValues[] | ConfigDynamicTrackerFields[]
  >([]);

  const dynamicFieldsValidation = useMemo(() => {
    return adaptDynamicFormValues(dynamicValues);
  }, [dynamicValues]);

  const validationSchema = useMemo(() => {
    return createAddOrOrderSchema(dynamicFieldsValidation);
  }, [dynamicFieldsValidation]);

  const addOrUpdateOrderFormik = useFormik({
    initialValues: {
      ...orderItem,
      product_card: orderItem?.product_card?.id,
      buyer: orderItem?.buyer?.id,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: noop,
  });

  const { values, errors, setFieldValue, handleBlur, handleChange, isValid } =
    addOrUpdateOrderFormik;

  const { isLoadingCompanies, dynamicCompanyFields } = useOrderHyperlinkData({
    buyer: orderItem?.buyer?.id,
  });

  const isEmptyDynamicFields =
    dynamicCompanyFields?.config_dynamic_order_fields?.length === 0;

  useEffect(() => {
    if (dynamicCompanyFields?.config_dynamic_order_fields) {
      const dynamicValues = dynamicCompanyFields?.config_dynamic_order_fields;

      setDynamicValues(dynamicValues as ConfigDynamicTrackerFields[]);
    }
  }, [dynamicCompanyFields?.config_dynamic_order_fields]);

  const handleWantedDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("wanted_delivery_datetime", dateString);
  };

  const handleProductionDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("production_datetime", dateString);
  };

  const [updateOrder] = useUpdateOrderMutation();

  const updateOrderRequest = async () => {
    try {
      await updateOrder({
        id: values.id!,
        buyer: values.buyer! as unknown as number,
        po_number: values.po_number!,
        product_card: values.product_card! as unknown as number,
        production_datetime: values.production_datetime!,
        quantity_expected: values.quantity_expected!,
        status: values.status!,
        wanted_delivery_datetime: values.wanted_delivery_datetime!,
        dynamic_order_fields: values.dynamic_order_fields!,
        customer_order_number: values.customer_order_number!,
      }).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.update-msg"),
      });
    } catch (error) {
      api.error({
        message: t("orders.error"),
        description: handleRequestError(error, t("orders.error")),
      });
    }
  };

  if (isLoadingOrder || isLoadingCompanies || !values) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateOrderFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Order Hyperlink - ${orderItem?.order_code}`}
            isValid={isValid}
            onOk={updateOrderRequest}
          />

          <div className="w-full p-4">
            <Title level={5}>{t("orders.buyer")}</Title>

            <Input value={orderItem?.buyer?.name} disabled size="large" />

            {!isEmptyDynamicFields && (
              <DynamicCompanyFields
                dynamicCompanyFields={dynamicCompanyFields}
              />
            )}

            <Title level={5} className="mb-1.25">
              {t("orders.po-number")}
            </Title>

            <FormInput
              value={values.po_number!}
              fieldName="po_number"
              placeholder={t("orders.po-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.po_number}
              isError={!!errors.po_number}
            />

            <Title level={5} className="mb-1.25">
              {t("orders.customer-order-number")}
            </Title>

            <FormInput
              value={values.customer_order_number!}
              fieldName="customer_order_number"
              placeholder={t("orders.customer-order-number-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.customer_order_number}
              isError={!!errors.customer_order_number}
            />

            <Title level={5} className="mb-2 mt-2">
              {t("orders.production-datetime")}
            </Title>

            <GlobalDatePicker
              value={dayjs.utc(values.production_datetime)}
              handlePickerChange={handleProductionDateTimeChange}
              error={errors.production_datetime}
              isError={!!errors.production_datetime}
            />

            <Title level={5} className="mb-1.25">
              {t("orders.wanter-delivery-datetime")}
            </Title>

            <GlobalDatePicker
              value={dayjs.utc(values.wanted_delivery_datetime)}
              handlePickerChange={handleWantedDateTimeChange}
              error={errors.wanted_delivery_datetime}
              isError={!!errors.wanted_delivery_datetime}
              minDate={dayjs.utc(values.production_datetime)}
              disabled={!values.production_datetime}
            />

            <Title level={5} className="mt-1.25">
              {t("orders.product-card")}
            </Title>

            <Input
              value={orderItem?.product_card?.name}
              disabled
              size="large"
            />

            <Title level={5} className="mb-1.25">
              {t("orders.quantity-expected")}
            </Title>

            <FormInput
              value={values.quantity_expected!}
              fieldName="quantity_expected"
              placeholder={t("orders.quantity-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.quantity_expected}
              isError={!!errors.quantity_expected}
              numberType
            />

            <Title level={5}>{t("orders.status")}</Title>

            <FormSelect
              value={values.status}
              placeholder={t("orders.status-placeholder")}
              fieldName="status"
              options={StatusDropdownValues}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors.status?.toString()}
              isError={!!errors.status}
              showSearch
            />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { OrderHyperlink };
