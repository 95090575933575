import { Button, notification, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetStandAloneConfigurationsQuery } from "shared/api";
import { StandAloneItem } from "shared/api/standAlone/models";
import {
  usePagination,
  useStandAloneRequests,
  useTableSorting,
} from "shared/hooks";
import { StandAloneFormik } from "shared/types";
import { TableButton } from "shared/ui";

interface StandAloneTableProps {
  showEditStandAloneModal: () => void;
}

const StandAloneTable: FC<StandAloneTableProps> = ({
  showEditStandAloneModal,
}) => {
  const { t } = useTranslation("", { keyPrefix: "standAlone" });

  const [api, contextHolder] = notification.useNotification();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    usePagination();

  const { ordering } = useTableSorting();

  const { setValues } = useFormikContext<StandAloneFormik>();

  const editStandAloneItem = (standAlone: StandAloneItem) => {
    showEditStandAloneModal();
    setValues(standAlone);
  };

  const {
    data: standAloneData,
    isLoading,
    isFetching,
  } = useGetStandAloneConfigurationsQuery({
    offset,
    limit,
    ordering,
  });

  const { showStandAloneDeleteModal } = useStandAloneRequests({ api });

  const columns: ColumnType<StandAloneItem>[] = [
    {
      title: t("name"),
      render: (standAlone: StandAloneItem) => (
        <Button
          onClick={() => editStandAloneItem(standAlone)}
          className="border-none hover:border-none bg-transparent shadow-none"
        >
          <span className="underline">{standAlone?.name}</span>
        </Button>
      ),
    },
    {
      title: t("description"),
      render: (standAlone: StandAloneItem) => standAlone.description,
    },
    {
      title: t("url"),
      render: (standAlone: StandAloneItem) => standAlone.url,
    },
    {
      title: t("bucket"),
      render: (standAlone: StandAloneItem) => standAlone.bucket,
    },
    {
      title: t("org"),
      render: (standAlone: StandAloneItem) => standAlone.org,
    },
    {
      title: t("actions"),
      render: (standAlone: StandAloneItem) => {
        return (
          <TableButton
            type="delete"
            onClick={() => showStandAloneDeleteModal(standAlone)}
          />
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={standAloneData?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: standAloneData?.count,
        }}
      />
    </>
  );
};

export { StandAloneTable };
