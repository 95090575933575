import React from "react";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Sidebar } from "../Sidebar";

interface PageProps {
  children: React.ReactNode;
  title: string;
  buttonTitle?: string;
  onClick?: () => void;
  hideTitle?: boolean;
}

const Page = ({
  children,
  title,
  buttonTitle,
  onClick,
  hideTitle = false,
}: PageProps) => {
  return (
    <div className="w-screen h-full flex">
      <Sidebar isOpenSidebar />

      <div className="w-full">
        <div className="w-full p-3.75">
          {hideTitle ? null : <Title className="capitalize">{title}</Title>}

          {buttonTitle && (
            <div className="flex justify-between items-center">
              <Button
                type="primary"
                className="btn-primary--dark"
                onClick={onClick}
              >
                {buttonTitle}
                <PlusOutlined className="mt-1" />
              </Button>
            </div>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export { Page };
