import { Alert, Button, Popover, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DynamicOutputMaterialData,
  ProductionOutputMaterial,
} from "shared/api";
import { selectFiltering } from "shared/helpers";

interface CountOfItemsStepProps {
  countOfItems: number[];
  handleCountOfItemsChange: (value: number, index: number) => void;
  outputMaterialsValues: {
    output_materials: ProductionOutputMaterial[];
  };
  handleSetOutputMaterialsValuesBasedOnCountOfCopies: (
    materials: DynamicOutputMaterialData[],
    countOfCopiedItems: number,
    index: number
  ) => void;
  inputIndex: number;
  validationArray: boolean[];
}

const CountOfItemsStep: FC<CountOfItemsStepProps> = ({
  countOfItems,
  handleCountOfItemsChange,
  outputMaterialsValues,
  handleSetOutputMaterialsValuesBasedOnCountOfCopies,
  inputIndex,
  validationArray,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const options = useMemo(
    () =>
      Array.from({ length: 101 }, (_, index) => ({
        value: index + 1,
        label: (index + 1).toString(),
      })),
    []
  );

  const dataToCopy =
    outputMaterialsValues?.output_materials?.[inputIndex]?.dynamicData;

  const isNotValidForm = validationArray?.[inputIndex] === false;

  const countOfItemsValue = [countOfItems?.[inputIndex]];

  const defaultCountOfItems =
    outputMaterialsValues?.output_materials?.[inputIndex]
      ?.numberOfMaterialItems;

  return (
    <div className="flex items-center justify-between p-2 w-full">
      {defaultCountOfItems ? (
        <div className="flex gap-x-5 items-center">
          <Alert
            type="warning"
            showIcon
            message={
              <>
                {t("defaultCountOfItems", { defaultCountOfItems })}
                <br />
                {t("confirmDefaultCountOfItems", { defaultCountOfItems })}
              </>
            }
          />

          <Popover open={isNotValidForm} content={t("confirmPopoverMessage")}>
            <Button
              disabled={isNotValidForm}
              onClick={() => {
                handleCountOfItemsChange(defaultCountOfItems, inputIndex);

                handleSetOutputMaterialsValuesBasedOnCountOfCopies(
                  dataToCopy,
                  defaultCountOfItems,
                  inputIndex
                );
              }}
            >
              OK
            </Button>
          </Popover>
        </div>
      ) : null}

      <div className="flex items-center gap-x-3">
        <Title level={5} className="!m-0">
          {t("selectCountOfItems")}
        </Title>

        <Popover
          open={isNotValidForm}
          content={t("confirmPopoverMessage")}
          placement="bottom"
        >
          <Select
            disabled={isNotValidForm}
            className="w-32"
            value={countOfItemsValue}
            onChange={(value) => {
              const countOfItemsValue = value?.[inputIndex];

              handleCountOfItemsChange(countOfItemsValue, inputIndex);

              handleSetOutputMaterialsValuesBasedOnCountOfCopies(
                dataToCopy,
                value as unknown as number,
                inputIndex
              );
            }}
            options={options}
            showSearch
            filterOption={(input, option) => selectFiltering(input, option)}
          />
        </Popover>
      </div>
    </div>
  );
};

export { CountOfItemsStep };
