import React, { FC } from "react";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { noop } from "shared/helpers";
import { useModal } from "shared/hooks";
import { userInitialValues } from "shared/constants";
import { AddOrUpdateUserSchema } from "shared/schemas";
import { UpdateUserModal, AddUserModal, UsersTable } from "./ui";

interface UsersProps {}

const Users: FC<UsersProps> = () => {
  const { t } = useTranslation();

  const [isOpenEditUserModal, showEditUserModal, closeEditUserModal] =
    useModal();

  const [isOpenAddUserModal, showAddUserModal, closeAddUserModal] = useModal();

  const addOrUpdateUserFormik = useFormik({
    initialValues: userInitialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: AddOrUpdateUserSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateUserFormik}>
      <Page
        title={t("users-page.title")}
        buttonTitle={t("users-page.add-btn")!}
        onClick={showAddUserModal}
      >
        <UsersTable showEditUserModal={showEditUserModal} />

        <UpdateUserModal
          isOpenEditUserModal={isOpenEditUserModal}
          closeEditUserModal={closeEditUserModal}
        />
        <AddUserModal
          isOpenNewUserModal={isOpenAddUserModal}
          closeAddUserModal={closeAddUserModal}
        />
      </Page>
    </FormikProvider>
  );
};

export { Users };
