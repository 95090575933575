import React, { FC } from "react";
import { useFormikContext } from "formik";
import {
  DynamicOutputMaterialData,
  OrderStepTrackerResponseItem,
} from "shared/api";
import { Divider, notification } from "antd";
import { useOutputMaterialDataForProductionPage } from "shared/hooks";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AddProductionRequest } from "./ProcessStepProduction";
import { MaterialSection } from "./DynamicOutputMaterials/MaterialSection";
import { OutputMasterCard } from "./DynamicOutputMaterials";
import {
  CountOfItemsStep,
  UpdatedOutputMaterialHeader,
} from "./DynamicOutputMaterials/UpdatedOutputMaterials";
import { UpdateOutputMaterials } from "./DynamicOutputMaterials/UpdatedOutputMaterials/UpdateOutputMaterials";
import { OutputMaterialsWarning } from "./DynamicOutputMaterials/UpdatedOutputMaterials/OutputMaterialsWarning";

interface OutputMaterialsContainerProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  countOfItems: number[];
  handleCountOfItemsChange: (value: number, index: number) => void;
  isEditMode: boolean[];
  toggleEditMode: (index: number) => void;
}

const OutputMaterialsContainer: FC<OutputMaterialsContainerProps> = ({
  orderStepTrackerResponseItem,
  countOfItems,
  handleCountOfItemsChange,
  isEditMode,
  toggleEditMode,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const [api, contextHolder] = notification.useNotification();

  const { values: mainValues, setFieldValue: setMainFieldValue } =
    useFormikContext<AddProductionRequest>();

  const outputMaterials = mainValues?.output_materials;

  const handleSetOutputMaterialsValuesBasedOnCountOfCopies = (
    materials: DynamicOutputMaterialData[],
    countOfCopiedItems: number,
    index: number
  ) => {
    handleCountOfItemsChange(countOfCopiedItems, index);

    const repeatedMaterials = Array.from(
      { length: countOfCopiedItems },
      (_, index) => materials[index % materials.length]
    );

    setMainFieldValue(
      `output_materials.${index}.dynamicData`,
      repeatedMaterials
    );
    api.success({
      message: t("outputMaterialsCopySuccess", { countOfCopiedItems }),
    });
  };

  const {
    outputMaterialsValues,
    setOutputMaterialFieldValue,
    validationArray,
  } = useOutputMaterialDataForProductionPage({
    mainValues,
    orderStepTrackerResponseItem,
  });

  return (
    <>
      {contextHolder}
      <MaterialSection>
        <div className="flex flex-col gap-y-10 p-3">
          {outputMaterials?.map((_, index) => {
            const isActiveEditMode = isEditMode?.[index];

            return (
              <div key={index}>
                <UpdatedOutputMaterialHeader
                  outputMaterials={outputMaterials}
                  index={index}
                  isEditMode={isEditMode}
                  toggleEditMode={toggleEditMode}
                  orderStepTrackerResponseItem={orderStepTrackerResponseItem}
                />

                <div className="w-full flex justify-between">
                  <div
                    className={classNames("w-full border-[1px] border-black", {
                      "!w-[45%]": isActiveEditMode,
                    })}
                  >
                    <OutputMasterCard
                      inputIndex={index}
                      orderStepTrackerResponseItem={
                        orderStepTrackerResponseItem
                      }
                      outputMaterialsValues={outputMaterialsValues}
                      setOutputMaterialFieldValue={setOutputMaterialFieldValue}
                    />

                    <CountOfItemsStep
                      countOfItems={countOfItems}
                      handleCountOfItemsChange={handleCountOfItemsChange}
                      outputMaterialsValues={outputMaterialsValues}
                      handleSetOutputMaterialsValuesBasedOnCountOfCopies={
                        handleSetOutputMaterialsValuesBasedOnCountOfCopies
                      }
                      inputIndex={index}
                      validationArray={validationArray}
                    />
                  </div>

                  {isActiveEditMode ? (
                    <>
                      <div className="flex items-stretch">
                        <Divider type="vertical" className="bg-black h-full" />
                      </div>

                      <UpdateOutputMaterials
                        mainValues={mainValues}
                        index={index}
                        toggleEditMode={toggleEditMode}
                        orderStepTrackerResponseItem={
                          orderStepTrackerResponseItem
                        }
                      />
                    </>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </MaterialSection>

      <div className="flex flex-wrap gap-x-4 w-full items-center justify-center">
        {outputMaterials?.map((_, index) => (
          <OutputMaterialsWarning
            key={index}
            index={index}
            outputMaterials={outputMaterials}
            handleSetOutputMaterialsValuesBasedOnCountOfCopies={
              handleSetOutputMaterialsValuesBasedOnCountOfCopies
            }
            orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          />
        ))}
      </div>
    </>
  );
};

const OutputMaterialsContainerMemo = React.memo(OutputMaterialsContainer);
export { OutputMaterialsContainerMemo as OutputMaterialsContainer };
