import { UploadProps, message } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { UploadDocumentValues } from "shared/types";
import {
  LabMeasurements,
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  QualityResultItem,
  QualityResultResultType,
} from "shared/api";

export const qualityResultStatuses: Record<QualityResultResultType, string> = {
  [QualityResultResultType.FILE_UPLOAD]: "File Upload",
  [QualityResultResultType.REEL_LAB_RESULT]: "Reel Lab Result",
};

export const renderQualityResultStatus = (item: QualityResultItem) => {
  const status =
    qualityResultStatuses[item?.result_type as QualityResultResultType];
  return status || "Unknown status";
};

export const createUploadDocumentProps = (
  handleDocument: (file: File | null) => void,
  handleFileList: (files: UploadFile[]) => void,
  fileList: UploadFile<unknown>[]
) => {
  const props: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload: (file) => {
      handleDocument(file);
      return false;
    },
    fileList,
    onChange: ({ fileList: newFileList }: UploadChangeParam) => {
      handleFileList?.(newFileList);
      const status = newFileList[newFileList.length - 1]?.status;
      if (status === "done") {
        const file = newFileList[newFileList.length - 1]?.originFileObj;
        handleDocument(file || null);
      }
    },
    onRemove: (file: UploadFile<unknown>) => {
      const newFileList = fileList?.filter(
        (fileFromList) => fileFromList.uid !== file.uid
      );
      handleFileList?.(newFileList ?? []);
      handleDocument(null);
    },
  };

  return props;
};

export const getQualityResultMaterialToSetTablesData = (
  record: QualityResultItem
) => {
  const materialIdsFromMaterialItems = record?.material_items?.map(
    (materialItem) =>
      materialItem?.material_item?.material_stock_batch?.material?.id
  );

  const materialIdsFromMaterialStockBatches =
    record?.material_stock_batches?.map(
      (stockBatch) => stockBatch?.material_stock_batch?.material?.id
    );

  const materialFromMaterialItems = materialIdsFromMaterialItems?.[0];
  const materialFromStockBatches = materialIdsFromMaterialStockBatches?.[0];

  return materialFromMaterialItems || materialFromStockBatches;
};

export const adaptStockBatchesToShowTableWithCheckbox = (
  record: QualityResultItem
) => {
  return record?.material_stock_batches?.map(
    (stockBatch) =>
      stockBatch?.material_stock_batch as unknown as MaterialStockBatchItemResponse
  );
};

export const adaptMaterialItemsToShowTableWithCheckbox = (
  record: QualityResultItem
) => {
  return record?.material_items?.map(
    (materialItem) =>
      materialItem?.material_item as unknown as MaterialStockItem
  );
};

export const generateExcelFileProps = (
  handleXlsxFile: (file: File | null) => void,
  handleFileList: (files: UploadFile<unknown>[]) => void,
  fileList: UploadFile<unknown>[]
) => {
  const props: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    fileList,
    beforeUpload: (file) => {
      const isXls =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXls) {
        message.error("You can only upload XLS files!");
      }

      if (isXls) {
        handleXlsxFile(file);
      }

      return false;
    },
    onChange: ({ fileList: newFileList }: UploadChangeParam) => {
      handleFileList?.(newFileList);
    },
    onRemove: (file: UploadFile<unknown>) => {
      const newFileList = fileList?.filter(
        (fileFromList) => fileFromList.uid !== file.uid
      );
      handleFileList?.(newFileList ?? []);
      handleXlsxFile(null);
    },
  };

  return props;
};

export type TableExtraData =
  | "tensileMd"
  | "tensileCd"
  | "elongationMd"
  | "elongationCd";

export const getTableExtraDataValue = (
  values: UploadDocumentValues,
  index: number,
  type: TableExtraData
) => {
  const typeToPropertyMap: { [key in TableExtraData]: keyof LabMeasurements } =
    {
      tensileMd: "tensile_md",
      tensileCd: "tensile_cd",
      elongationMd: "elongation_md",
      elongationCd: "elongation_cd",
    };

  const property = typeToPropertyMap[type];

  return values?.reel_lab_measurements?.[index]?.[property] ?? "No value";
};

const enum FileExtensions {
  IMAGE = "image/jpeg",
  PDF = "application/pdf",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const includesExcelFile = (documentList: UploadFile<any>[]) => {
  return documentList?.some(
    (item) =>
      item?.type === FileExtensions.XLS || item?.type === FileExtensions.XLSX
  );
};

export const disableQualityResultsForm = (
  resultType: string | null,
  isValid: boolean,
  documentList: UploadFile<any>[]
) => {
  const isReelLabResult =
    resultType === QualityResultResultType?.REEL_LAB_RESULT;

  const isFileUpload = resultType === QualityResultResultType?.FILE_UPLOAD;

  const isIncludesExcelFile = includesExcelFile(documentList);

  const disableCondition =
    !isValid || (isFileUpload ? !documentList?.length : !isIncludesExcelFile);

  return isFileUpload || isReelLabResult ? disableCondition : true;
};
