import { WidgetTypeEnum } from "shared/api";
import { StandAloneWidgetResponse } from "shared/api/standAlone/models";
import { standAloneChartColors } from "shared/constants";

export const adaptStandAloneWidgetData = (data: StandAloneWidgetResponse[]) => {
  const updatedData = data?.map((item) => ({
    ...item,
    type: WidgetTypeEnum.STANDALONE_LINE_GRAPH,
  }));

  return updatedData;
};

export const getRandomColorForStandAloneGraph = () =>
  standAloneChartColors[
    Math.floor(Math.random() * standAloneChartColors.length)
  ];
