import { DimensionUnitItem } from "shared/api";

export const adaptDimension = (apiDimensions: DimensionUnitItem[]) => {
  const adaptedDimensions = apiDimensions.map((item) => ({
    label: `${item.name}  - ${item.abbreviation}`,
    value: item.id,
  }));
  return adaptedDimensions || [];
};

export const adaptDimensionUnitsData = (data: DimensionUnitItem[]) => {
  const groupedData = data?.reduce((groups: any, item) => {
    const { dimension } = item;
    const { name } = dimension;
    if (!groups[name]) {
      groups[name] = [];
    }
    groups[name].push(item);

    return groups;
  }, {});

  const transformedArray = Object.entries(groupedData || []).map(
    ([dimensionName, items]) => ({
      label: dimensionName,
      // @ts-ignore
      options: items.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    })
  );

  return transformedArray;
};
