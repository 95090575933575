import { Modal } from "antd";
import React, { FC } from "react";
import { Widget, WidgetTypeEnum } from "shared/api";
import { Colors } from "shared/themes";
import { ProductionDataTrackerConfiguration } from "./ProductionDataTracker/ui";
import { MaterialLevelGraph } from "./MaterialLevelGraph";
import { WidgetMaterialCategories, WidgetMaterials } from "../WidgetSettings";
import { StandAloneWidgetConfiguration } from "./StandAloneWidget";

interface WidgetConfigurationsDialogProps {
  isVisibleConfigurations: boolean;
  closeConfigurationsAndClearValues: () => void;
  updateWidgetSettings: () => Promise<void>;
  isActiveConfigurationsButton: boolean;
  widget: Widget;
  materialCategories: WidgetMaterialCategories;
  materials: WidgetMaterials;
}

const WidgetConfigurationsDialog: FC<WidgetConfigurationsDialogProps> = ({
  closeConfigurationsAndClearValues,
  updateWidgetSettings,
  isActiveConfigurationsButton,
  widget,
  isVisibleConfigurations,
  materialCategories,
  materials,
}) => {
  return (
    <Modal
      open={isVisibleConfigurations}
      onCancel={closeConfigurationsAndClearValues}
      onOk={updateWidgetSettings}
      okButtonProps={{
        disabled:
          widget?.type === WidgetTypeEnum.PRODUCTION_DATA_TRACKER
            ? !isActiveConfigurationsButton
            : false,
        style: {
          background: Colors.blue.primary,
        },
      }}
    >
      {widget?.type === WidgetTypeEnum.PRODUCTION_DATA_TRACKER && (
        <ProductionDataTrackerConfiguration
          materialCategories={materialCategories}
        />
      )}

      {widget?.type === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL && (
        <MaterialLevelGraph materials={materials} />
      )}

      {widget?.type === WidgetTypeEnum.STANDALONE_LINE_GRAPH && (
        <StandAloneWidgetConfiguration />
      )}
    </Modal>
  );
};

const WidgetConfigurationsDialogMemo = React.memo(WidgetConfigurationsDialog);

export { WidgetConfigurationsDialogMemo as WidgetConfigurationsDialog };
