import Title from "antd/es/typography/Title";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProductionOutputMaterial } from "shared/api";
import { showStatusesSummary } from "shared/helpers";

interface StatusesSummaryProps {
  outputMaterials: ProductionOutputMaterial[];
}

const StatusesSummary: FC<StatusesSummaryProps> = ({ outputMaterials }) => {
  const { t } = useTranslation();

  const statusesSummary = useMemo(() => {
    return showStatusesSummary(outputMaterials);
  }, [outputMaterials]);

  const isVisibleSummaryData = useMemo(() => {
    return outputMaterials?.some(({ dynamicData }) => !!dynamicData?.length);
  }, [outputMaterials]);

  if (!isVisibleSummaryData) {
    return null;
  }

  return (
    <div className="w-full flex items-center justify-center gap-x-5">
      <Title level={5} className="!m-0 !text-green-100">
        {t("orders.prima")} {statusesSummary?.primasCount}
      </Title>

      <Title level={5} className="!m-0 !text-red-300">
        {t("orders.wasted")} {statusesSummary?.wastedCount}
      </Title>

      <Title level={5} className="!m-0 !text-yellow-600">
        {t("orders.investigation")} {statusesSummary?.investigateCount}
      </Title>
    </div>
  );
};

export { StatusesSummary };
