import { Button, Input, Modal, Space } from "antd";
import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import {
  AddSupplierToMaterialModal,
  SuppliersTable,
} from "pages/SupplyChain/MaterialManagement/ui";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MaterialCategoriesItem,
  MaterialsItem,
  SupplierItemWithCost,
} from "shared/api";
import { ModalSizeValue } from "shared/constants";
import {
  noop,
  renderUnitName,
  returnUpdateMaterialParams,
} from "shared/helpers";
import { useMaterialManagementRequests, useModal } from "shared/hooks";
import { EditMaterialSchema } from "shared/schemas";
import { Colors } from "shared/themes";
import {
  AdaptedValues,
  CreateMaterialUnit,
  SubmitCreateMaterialValues,
} from "shared/types";
import { FormInput, FormSelect } from "shared/ui";

interface UpdateMaterialManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
  materialCategoriesAdapted: AdaptedValues[];
  materialCategories?: MaterialCategoriesItem[];
  materialItemToUpdate: MaterialsItem | null;
}

const UpdateMaterialManagementModal: FC<UpdateMaterialManagementModalProps> = ({
  isOpen,
  onClose,
  materialCategoriesAdapted,
  materialCategories,
  materialItemToUpdate,
}) => {
  const { t } = useTranslation();

  const [
    isOpenAddNewSuppliersModal,
    showAddNewSupplierModal,
    hideAddNewSuppliersModal,
  ] = useModal();

  const [dndSuppliers, setDndSuppliers] = useState<SupplierItemWithCost[]>([]);

  const handleDndSuppliers = (suppliers: SupplierItemWithCost[]) =>
    setDndSuppliers(suppliers);

  const editMaterialItemFormik = useFormik<SubmitCreateMaterialValues>({
    initialValues: {
      id: materialItemToUpdate?.id!,
      name: materialItemToUpdate?.name!,
      material_category:
        materialItemToUpdate?.material_category as CreateMaterialUnit,
      suppliers: materialItemToUpdate?.suppliers!,
      target_quantity_in_stock: materialItemToUpdate?.target_quantity_in_stock,
      n_packaging_items: materialItemToUpdate?.n_packaging_items,
      expanded_label_name: materialItemToUpdate?.expanded_label_name!,
    },
    enableReinitialize: true,
    validationSchema: EditMaterialSchema,
    onSubmit: noop,
  });

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    isValid,
    resetForm,
  } = editMaterialItemFormik;

  const closeModalAndResetValues = () => {
    onClose();
    resetForm();
  };

  const { contextHolder, updateMaterialItem, isLoadingMaterialUpdate } =
    useMaterialManagementRequests({
      closeEditMaterialModal: closeModalAndResetValues,
    });

  const dynamicUnitName = useMemo(() => {
    return renderUnitName(values, materialCategories ?? []);
  }, [values, materialCategories]);

  const handleMaterialItemUpdate = () => {
    updateMaterialItem(returnUpdateMaterialParams(values, dndSuppliers));
  };

  return (
    <>
      {contextHolder}
      <FormikProvider value={editMaterialItemFormik}>
        <Modal
          title={t("material-management.modal-title")}
          width={ModalSizeValue.LARGE}
          open={isOpen}
          okButtonProps={{
            disabled: !isValid,
            loading: isLoadingMaterialUpdate,
            style: {
              background: `${Colors.blue.primary}`,
            },
          }}
          onCancel={onClose}
          onOk={handleMaterialItemUpdate}
        >
          <Title level={5}>{t("material-management.name")}</Title>

          <FormInput
            value={values.name}
            fieldName="name"
            placeholder={t("material-management.update-modal-name-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.name?.toString()}
            isError={!!errors.name}
          />

          <Title level={5}>{t("material-management.expanded-name")}</Title>

          <FormInput
            value={values.expanded_label_name}
            fieldName="expanded_label_name"
            placeholder={t(
              "material-management.create-modal-expanded-name-placeholder"
            )}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.expanded_label_name?.toString()}
            isError={!!errors.expanded_label_name}
          />

          <Title level={5}>{t("material-management.category-name")}</Title>

          <FormSelect
            value={values?.material_category?.id || values?.material_category}
            placeholder={t(
              "material-management.update-modal-material-category-placeholder"
            )}
            fieldName="material_category.id"
            options={materialCategoriesAdapted}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            error={errors.material_category?.id?.toString()}
            isError={!!errors.material_category?.id}
          />

          <Title level={5}>
            {t("material-management.target-quantity-in-stock")}
          </Title>

          <FormInput
            value={values.target_quantity_in_stock}
            fieldName="target_quantity_in_stock"
            placeholder={t(
              "material-management.target-quantity-in-stock-placeholder"
            )}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.target_quantity_in_stock?.toString()}
            isError={!!errors.target_quantity_in_stock}
            numberType
          />

          <Title level={5}>{t("material-management.n-packaging-items")}</Title>

          <FormInput
            value={values.n_packaging_items}
            fieldName="n_packaging_items"
            placeholder={t("material-management.n-packaging-items-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.n_packaging_items?.toString()}
            isError={!!errors.n_packaging_items}
            numberType
          />

          <Title level={5}>{t("material-management.unit")}</Title>

          <Input disabled value={dynamicUnitName} size="large" />

          <div className="flex items-center justify-between mt-2.5 mb-2.5">
            <Title level={5} className="mt-2.5">
              {t("material-management.suppliers")}
            </Title>

            <Space className="flex items-center justify-center">
              <Button
                type="primary"
                onClick={showAddNewSupplierModal}
                className="btn-primary--dark mb-0"
              >
                {t("material-management.new-supplier")}
              </Button>
            </Space>
          </div>

          <SuppliersTable
            handleDndSuppliers={handleDndSuppliers}
            dynamicUnitName={dynamicUnitName}
          />
        </Modal>

        <AddSupplierToMaterialModal
          isOpen={isOpenAddNewSuppliersModal}
          hideAddNewSuppliersModal={hideAddNewSuppliersModal}
        />
      </FormikProvider>
    </>
  );
};

export { UpdateMaterialManagementModal };
