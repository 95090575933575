import { EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Widget, WidgetTypeEnum } from "shared/api";

interface SettingsButtonContainerProps {
  widget: Widget;
  showConfigurations: () => void;
  disableSettingsButton: boolean;
  showSettings: () => void;
}

const SettingsButtonContainer = ({
  widget,
  showConfigurations,
  disableSettingsButton,
  showSettings,
}: SettingsButtonContainerProps) => {
  const { t } = useTranslation();

  const isVisibleConfigurationsButton =
    widget?.type === WidgetTypeEnum.PRODUCTION_DATA_TRACKER ||
    widget?.type === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL ||
    widget?.type === WidgetTypeEnum.STANDALONE_LINE_GRAPH;

  const isVisibleSettingButton =
    widget?.type !== WidgetTypeEnum.GRAPH_MATERIAL_LEVEL &&
    widget?.type !== WidgetTypeEnum.STANDALONE_LINE_GRAPH;

  return (
    <>
      {isVisibleConfigurationsButton && (
        <Popover content={t("widgets.configurations")}>
          <EyeOutlined
            className="!text-white scale-150 mb-3 !cursor-pointer"
            onClick={showConfigurations}
          />
        </Popover>
      )}

      {isVisibleSettingButton && (
        <Popover
          content={
            disableSettingsButton
              ? t("widgets.disabledSettings")
              : t("widgets.pressToSettings")
          }
        >
          <SettingOutlined
            className={classNames(
              "!text-white scale-150 mb-3 !cursor-pointer",
              {
                "opacity-50": disableSettingsButton,
              }
            )}
            onClick={disableSettingsButton ? undefined : showSettings}
          />
        </Popover>
      )}
    </>
  );
};

export { SettingsButtonContainer };
