import React, { useEffect } from "react";
import { Button, Form, Image, Input, Layout, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { useWindowDimensions } from "shared/hooks";
import { useLoginMutation } from "shared/api";
import i18next from "i18next";
import { handleRequestError } from "shared/helpers";
import { logosImages } from "shared/assets";
import { SignInSchema } from "shared/schemas";
import { useTypedSelector } from "../../../shared/stores";
import { userSelectors } from "../../../shared/stores/user/selectors";
import { ErrorMessage } from "./ui/ErrorMessage";

interface SubmitValues {
  username: string;
  password: string;
}

const SignIn = () => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const token = useTypedSelector(userSelectors.token);
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();
  const [api, contextHolder] = notification.useNotification();

  // comment to trigger rebuild on Vercel

  const onSubmitLogIn = async (params: SubmitValues) => {
    try {
      await login(params).unwrap();

      if (!localStorage.getItem("language")) {
        localStorage.setItem("language", i18next.language);
      }
    } catch (error) {
      api.error({
        message: t("sign-in.error-title"),
        description: handleRequestError(error, t("sign-in.error-title")),
      });
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: (params) => onSubmitLogIn(params),
  });

  const bootstrap = () => {
    if (token) {
      navigate(Routes.orders.url);
    }
  };

  useEffect(bootstrap, [token]);

  return (
    <>
      {contextHolder}
      <Layout>
        <Content className="w-screen h-screen flex items-center justify-center bg-white">
          <Form className={isMobile ? "w-[300px]" : "w-[500px]"}>
            <div className="w-full flex items-center justify-center">
              <Image
                src={logosImages.logoMain}
                className="mb-5 !-[250px]"
                preview={false}
              />
            </div>

            <Form.Item>
              <Input
                size="large"
                value={values.username}
                onBlur={handleBlur("username")}
                onChange={handleChange("username")}
                placeholder={t("sign-in.username-placeholder")!}
              />
              {errors.username && (
                <ErrorMessage errorMessage={errors.username} />
              )}
            </Form.Item>

            <Form.Item>
              <Input.Password
                size="large"
                value={values.password}
                onBlur={handleBlur("password")}
                onChange={handleChange("password")}
                placeholder={t("sign-in.password-placeholder")!}
              />
              {errors.password && (
                <ErrorMessage errorMessage={errors.password} />
              )}
            </Form.Item>

            <Form.Item>
              <Button
                block
                loading={isLoading}
                disabled={!isValid}
                onClick={() => handleSubmit()}
                type="primary"
                htmlType="submit"
                className="btn-primary--dark h-10 flex items-center justify-center"
              >
                {t("sign-in.button")}
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </>
  );
};

export { SignIn };
