import { FormikErrors } from "formik";
import React, { FC } from "react";
import {
  DynamicOutputMaterialData,
  ProductionOutputMaterial,
} from "shared/api";
import { Select } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";

interface UpdateOutputMaterialStatusProps {
  materials: DynamicOutputMaterialData[];
  currentPage: number;
  index: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          output_materials: ProductionOutputMaterial[];
        }>
      >;
}

const UpdateOutputMaterialStatus: FC<UpdateOutputMaterialStatusProps> = ({
  materials,
  currentPage,
  setFieldValue,
  index,
}) => {
  const { t } = useTranslation();

  const statusColors = {
    prima: {
      backgroundColor: "#49CB90",
      marginBottom: "5px",
    },
    wasted: {
      backgroundColor: "#F68787",
      marginBottom: "5px",
    },
    investigation: {
      backgroundColor: "#F1EB9A",
    },
  };

  const value = materials?.[currentPage]?.status;
  const fieldName = `output_materials.${index}.dynamicData.${currentPage}.status`;

  return (
    <div>
      <Title level={5}>Status</Title>

      <Select
        value={value}
        className={classNames("mt-1 w-full", {
          "custom-select-error": value === "W",
          "custom-select-success": value === "P",
          "custom-select-warning": value === "I",
        })}
        placeholder={t("orders.status")}
        onChange={(value) => {
          setFieldValue(fieldName, value);
        }}
      >
        <Select.Option value="P" style={statusColors.prima}>
          {t("orders.prima")}
        </Select.Option>

        <Select.Option value="W" style={statusColors.wasted}>
          {t("orders.wasted")}
        </Select.Option>

        <Select.Option value="I" style={statusColors.investigation}>
          {t("orders.investigation")}
        </Select.Option>
      </Select>
    </div>
  );
};

export { UpdateOutputMaterialStatus };
