import { Popover } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CompanyItem } from "shared/api";

interface ChildCompaniesProps {
  company: CompanyItem;
  handleCompanyWithChildrenModal: (record: CompanyItem) => void;
}

const ChildCompanies: FC<ChildCompaniesProps> = ({
  company,
  handleCompanyWithChildrenModal,
}) => {
  const { t } = useTranslation();

  const renderChildCompanies = () => {
    const childCompanies = (company?.child_companies as CompanyItem[]) || [];
    const childCount = childCompanies.length;

    if (childCount === 0) return null;
    if (childCount === 1) return <span>{childCompanies[0]?.name}</span>;

    return (
      <span>
        {childCompanies[0]?.name} {t("and")} {childCount - 1} {t("other")}
      </span>
    );
  };

  return (
    <Popover content={t("companiesColumns.showChildrenModalHint")}>
      <button
        type="button"
        className="flex flex-col gap-y-2 items-start"
        onClick={() => handleCompanyWithChildrenModal(company)}
      >
        {renderChildCompanies()}
      </button>
    </Popover>
  );
};

export { ChildCompanies };
