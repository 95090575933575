export const processTemplatesColumnsData = {
  image: {
    title: "processTemplatesColumns.image",
    width: "1%",
  },
  name: {
    title: "processTemplatesColumns.name",
    dataIndex: "name",
    key: "name",
    width: "20%",
  },
  description: {
    title: "processTemplatesColumns.description",
    dataIndex: "description",
    key: "description",
    width: "20%",
  },
  abbreviation: {
    title: "processTemplatesColumns.abbreviation",
    dataIndex: "abbreviation",
    key: "abbreviation",
    width: "20%",
  },
  actions: {
    title: "processTemplatesColumns.actions",
    key: "actions",
    width: "10%",
  },
};

export const enum ProcessTemplatesSorting {
  NAME_ASC = "name",
  NAME_DESC = "-name",
}

export const processTemplateInitialValues = {
  id: null,
  name: null,
  description: null,
  newCardName: "",
  sections: [],
  abbreviation: null,
  image: null,
};
