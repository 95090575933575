import { AdaptedStatuses } from "shared/types";

export const expandOrdersString =
  "process_step_trackers.process_step.process_template,buyer,product_card,process_step.output_materials";

export const expandStepTracker =
  "order.buyer,process_step.process_template,process_step.input_materials.material.material_category,process_step.input_materials.consumption_proportional_material,process_step.output_materials.material.material_category.unit,calc_hide_field,calc_default_dynamic_tracker_fields";

export const expandStockMaterialForOrder =
  "material.material_category.unit,produced_in_order_process_step_tracker";

export const initialOrderValues = {
  id: null,
  buyer: null,
  subBuyer: null,
  po_number: null,
  wanted_delivery_datetime: null,
  production_datetime: null,
  product_card: null,
  quantity_expected: null,
  status: null,
  filterStatusValue: "",
  wantedDateGreater: null,
  dynamic_order_fields: {},
  customer_order_number: null,
};

export const orderPlansInitialValues = { id: null, name: null, status: null };

export const StatusDropdownValues: AdaptedStatuses[] = [
  { label: "PO Order", value: "PO" },
  { label: "Pending", value: "PE" },
  { label: "Approved", value: "AP" },
  { label: "Active", value: "AC" },
  { label: "Completed", value: "CO" },
  { label: "Delivered", value: "DE" },
];

export const FilterStatusDropdownValues: AdaptedStatuses[] = [
  { label: "All", value: "" },
  { label: "Active", value: "AC" },
  { label: "Approved", value: "AP" },
  { label: "Completed", value: "CO" },
  { label: "Delivered", value: "DE" },
  { label: "Pending", value: "PE" },
  { label: "PO Order", value: "PO" },
];

export const variantSelectValues = [
  { label: "Material", value: "material" },
  { label: "Material item", value: "material_item" },
  {
    label: "Stock Batch",
    value: "material_stock_batch",
  },
];

export const enum OrdersSorting {
  PO_NUMBER_ASC = "po_number",
  PO_NUMBER_DESC = "-po_number",
  WANTED_DELIVERY_DATE_ASC = "wanted_delivery_datetime",
  WANTED_DELIVERY_DATE_DESC = "-wanted_delivery_datetime",
  PRODUCTION_DATE_ASC = "production_datetime",
  PRODUCTION_DATE_DESC = "-production_datetime",
  PRODUCT_CARD_ASC = "product_card",
  PRODUCT_CARD_DESC = "-product_card",
  QUANTITY_EXPECTED_ASC = "quantity_expected",
  QUANTITY_EXPECTED_DESC = "-quantity_expected",
}

export const enum OrderCardStatuses {
  FINISHED = "FI",
  IN_PROGRESS = "IP",
  PENDING = "PE",
}

export const enum OrderPlanStatuses {
  ACTIVE = "A",
  COMPLETED = "C",
  DRAFT = "D",
}

export const orderCardSelectValues = [
  { value: "FI", label: "Finished" },
  { value: "IP", label: "In Progress" },
  { value: "PE", label: "Pending" },
];

export const orderPlanSelectValues = [
  { value: "A", label: "Active" },
  { value: "C", label: "Completed" },
  { value: "D", label: "Draft" },
];

export const ordersColumnsData = {
  orderCode: {
    title: "ordersTableColumns.order",
    dataIndex: "order_code",
    key: "order_code",
    width: "0%",
  },
  buyer: {
    title: "ordersTableColumns.customer",
    dataIndex: "buyer",
    key: "buyer",
    width: "0%",
  },
  po_number: {
    title: "ordersTableColumns.poNumber",
    dataIndex: "po_number",
    key: "po_number",
    width: "10%",
  },
  customerOrderNumber: {
    title: "ordersTableColumns.customerPO",
    dataIndex: "customer_order_number",
    key: "customer_order_number",
    width: "10%",
  },

  wantedDeliveryDatetime: {
    title: "ordersTableColumns.wdt",
    dataIndex: "wanted_delivery_datetime",
    key: "wanted_delivery_datetime",
    width: "12.5%",
  },
  productionDatetime: {
    title: "ordersTableColumns.prodDate",
    dataIndex: "production_datetime",
    key: "production_datetime",
    width: "12.5%",
  },
  productCard: {
    title: "ordersTableColumns.productCard",
    dataIndex: "product_card",
    key: "product_card",
    width: "2%",
  },
  quantityExpected: {
    title: "ordersTableColumns.amount",
    dataIndex: "quantity_expected",
    key: "quantity_expected",
    width: "1%",
  },
  processSteps: {
    title: "ordersTableColumns.processSteps",
    width: "12.5%",
  },
  status: {
    title: "ordersTableColumns.status",
    dataIndex: "status",
    key: "status",
    width: "5%",
  },
  actions: { title: "ordersTableColumns.actions", key: "actions", width: "10" },
};

export const orderPlansColumnsData = {
  name: {
    title: "orderPlanTableColumns.name",
    dataIndex: "name",
    key: "name",
    width: "80%",
  },
  status: {
    title: "orderPlanTableColumns.status",
    dataIndex: "status",
    key: "status",
    width: "10%",
  },
  actions: {
    title: "orderPlanTableColumns.actions",
    key: "actions",
    width: "10%",
  },
};

export const dropdownOptionsColors = ["#a3f7c0", "#F68787", "#F1EB9A"];

export const enum ProductionHeaderPdfType {
  AllItemsLabels = "allItemsLabels",
  LastSetItemLabels = "lastSetItemLabels",
  AllPackageLabels = "allPackageLabels",
  LastSetPackageLabels = "lastSetPackage ",
}
