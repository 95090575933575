import { Slider } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";

interface RangeSliderProps {
  title: string;
  rangeItemName: string;
  maxValue: number;
  onChange: (value: [number, number]) => void;
  onAfterChange: (value: [number, number]) => void;
}

const RangeSlider: FC<RangeSliderProps> = ({
  maxValue,
  onChange,
  onAfterChange,
  title,
  rangeItemName,
}) => {
  return (
    <div className="flex flex-col items-center p-2.5 w-112.5 shadow-dynamicFormCard">
      <Title level={5}>{title}</Title>

      <div className="w-full flex items-center justify-between">
        <Title level={5} className="!m-0">
          Min {rangeItemName}
        </Title>

        <Title level={5} className="!m-0">
          Max {rangeItemName}
        </Title>
      </div>

      <Slider
        step={10}
        defaultValue={[0, maxValue]}
        max={maxValue}
        onChange={onChange}
        onAfterChange={onAfterChange}
        className="w-100"
        range={{ draggableTrack: true }}
      />
    </div>
  );
};

export { RangeSlider };
