import { PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Input, InputRef, Select } from "antd";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TableButton } from "../../../TableButton";

interface AddItemSelectProps {
  setNewSelectItems?: React.Dispatch<React.SetStateAction<string[]>>;
  newSelectItems?: string[];
  setNewItemValue?: React.Dispatch<React.SetStateAction<string>>;
  newItemValue?: string;
  fieldName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  placeholder: string;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  showSearch?: boolean;
  disabled?: boolean;
  error?: string;
  isError?: boolean;
}

const AddItemSelect: FC<AddItemSelectProps> = ({
  setNewItemValue,
  newItemValue,
  setNewSelectItems,
  newSelectItems,
  fieldName,
  setFieldValue,
  placeholder,
  handleBlur,
  showSearch,
  disabled,
  error,
  isError,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<InputRef>(null);

  const onItemValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewItemValue?.(event?.target?.value)!;
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    setNewSelectItems?.([...(newSelectItems ?? []), newItemValue ?? ""]);
    setNewItemValue?.("");

    inputRef.current?.focus();
  };

  const removeItem = (clickedItem: string) => {
    const updatedItems = newSelectItems?.filter((item) => item !== clickedItem);

    setNewSelectItems?.(updatedItems ?? []);

    setFieldValue(fieldName, updatedItems);
  };

  const renderItemsCount = () => {
    if (newSelectItems?.length === 1) {
      return `${newSelectItems?.length} - item`;
    }
    return `${newSelectItems?.length} - items`;
  };

  return (
    <>
      <Select
        value={renderItemsCount()}
        className="w-full mb-1.25"
        placeholder={placeholder}
        labelInValue
        size="large"
        onBlur={handleBlur}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        showSearch={showSearch}
        disabled={disabled}
        dropdownRender={(menu) => (
          <>
            {newSelectItems?.length ? (
              menu
            ) : (
              <Alert type="warning" showIcon message="No items" />
            )}
            {newSelectItems?.length ? <Divider /> : null}

            <div className="flex gap-x-1 items-center p-1 m-0">
              <Input
                placeholder={placeholder}
                ref={inputRef}
                value={newItemValue}
                onChange={onItemValueChange}
                className="w-[80%]"
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={addItem}
                disabled={!newItemValue?.length}
                className="btn-primary--dark !w-[20%] mt-2.5 flex items justify-center"
              />
            </div>
          </>
        )}
        options={newSelectItems?.map((item) => ({
          label: (
            <div className="!w-full h-full p-1px flex items-center center">
              <div className="!w-[80%] truncate">{item}</div>
              <TableButton
                className="!w-[20%]"
                type="delete-no-popover"
                onClick={() => removeItem(item)}
              />
            </div>
          ),
          value: item,
        }))}
      />

      {!!isError && <div className="text-red-100 mt-1px">{t(`${error}`)}</div>}
    </>
  );
};

export { AddItemSelect };
