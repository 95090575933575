import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  StockMaterialItem,
  useDeleteMaterialStockBatchMutation,
  useGetMaterialCategoryTemplateMutation,
} from "shared/api";
import { downloadExcelFileHelper, handleRequestError } from "shared/helpers";
import { useConfirmDeleteModal } from "./useConfirmDeleteModal";

export const useStockManagementRequests = () => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [deleteMaterialStockBatch] = useDeleteMaterialStockBatchMutation();
  const [getMaterialCategoryTemplate] =
    useGetMaterialCategoryTemplateMutation();

  const deleteMaterialStockBatchHandler = async (record: StockMaterialItem) => {
    try {
      await deleteMaterialStockBatch(record).unwrap();
      api.success({
        message: t("stock-material.success-title"),
        description: t("stock-material.success-delete-desc"),
      });
    } catch (error) {
      api.error({
        message: t("stock-material.error-title"),
        description: handleRequestError(error, t("stock-material.error-title")),
      });
    }
  };

  const showDeleteMaterialStockModal = useConfirmDeleteModal(
    t("stock-material.delete-modal-msg"),
    deleteMaterialStockBatchHandler
  );

  const downloadMaterialCategoryTemplate = async ({
    materialCategoryId,
    materialName,
  }: {
    materialCategoryId: number;
    materialName: string;
  }) => {
    // const materialCategoryId = stockMaterialToManage?.material_category
    //   ?.id as number;

    try {
      const response = await getMaterialCategoryTemplate({
        id: materialCategoryId,
      }).unwrap();

      const adaptedResponseToBlob = response as unknown as Blob;

      downloadExcelFileHelper(adaptedResponseToBlob, materialName);

      api.success({
        message: t("material-management.success"),
        description: t("material-management.excel-success-download-msg"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-management.error")
      );
      api.error({
        message: t("material-management.error"),
        description,
      });
    }
  };

  return {
    showDeleteMaterialStockModal,
    downloadMaterialCategoryTemplate,
    contextHolder,
  };
};
