import React, { FC, useEffect, useRef, useState } from "react";
import Timeline, { TodayMarker } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { Button, notification } from "antd";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Routes } from "shared/routers";
import { dayjs, handleRequestError, noop } from "shared/helpers";
import {
  OrderMachinePlan,
  OrderPlan,
  useDeleteOrderPlanForMachineMutation,
  useUpdateOrderPlanForMachineByMutation,
} from "shared/api";
import { adaptOrderPlansForMachines } from "shared/adapters";
import { useConfirmDeleteModal } from "shared/hooks";
import { DefaultGroupItem, GroupItem, ScheduleItem } from "shared/types";
import { ResizingItemEdgeProps } from "shared/constants";
import { DynamicItemsModal } from "./DynamicItemsModal";
import { CustomGroupHeader } from "./CustomGroupHeader";
import { CustomTimelineItem } from "./CustomTimelineItem";
import { TodayMarkerLine } from "./TodayMarkerLine";

interface TimelineSchedulerProps {
  groups: DefaultGroupItem[];
  selectedOrderPlan: OrderPlan | null;
}

const TimelineScheduler: FC<TimelineSchedulerProps> = ({
  groups,
  selectedOrderPlan,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const timelineRef = useRef(null);

  const [items, setItems] = useState<ScheduleItem[]>([]);
  const [dynamicItemsModal, setDynamicItemsModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupItem | null>(null);

  useEffect(() => {
    const adaptedOrderPlansForMachines = adaptOrderPlansForMachines(
      selectedOrderPlan || null
    );

    setItems(adaptedOrderPlansForMachines as ScheduleItem[]);
  }, [selectedOrderPlan]);

  const currentDay = dayjs().utc();
  const startOfDay = currentDay.startOf("day");
  const endOfDay = currentDay.endOf("day");

  const [visibleTimeStart, setVisibleTimeStart] = useState(
    startOfDay.valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(endOfDay.valueOf());

  const addDynamicTimelineValues = useFormik({
    initialValues: {
      orderProcessStep: null,
      startDate: null,
      endDate: null,
      timelineItems: [],
    },
    onSubmit: noop,
  });

  const [updateOrderPlanForMachineBy] =
    useUpdateOrderPlanForMachineByMutation();

  const [deleteOrderPlanForMachine] = useDeleteOrderPlanForMachineMutation();

  const handleTimeChange = (visibleStart: number, visibleEnd: number) => {
    setVisibleTimeStart(visibleStart);
    setVisibleTimeEnd(visibleEnd);
  };

  const scrollToCurrentTime = () => {
    const currentTime = new Date().getTime();
    const halfVisibleRange = (visibleTimeEnd - visibleTimeStart) / 2;
    const newVisibleTimeStart = currentTime - halfVisibleRange;
    const newVisibleTimeEnd = currentTime + halfVisibleRange;

    setVisibleTimeStart(newVisibleTimeStart);
    setVisibleTimeEnd(newVisibleTimeEnd);
  };

  const openDynamicItemsModal = (group: GroupItem) => {
    setDynamicItemsModal(true);
    setSelectedGroup(group);
  };

  const updateOrderPlanForMachineRequest = async (item: OrderMachinePlan) => {
    try {
      await updateOrderPlanForMachineBy(item).unwrap();
      api.success({
        message: t("plans.success-title"),
        description: t("plans.success-item-msg-upd"),
      });
      setDynamicItemsModal(false);
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );
      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const handleItemMove = (
    itemId: number | string,
    dragTime: number,
    newGroupOrder: number
  ) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const updatedItem = {
          ...item,
          start_time: dragTime,
          end_time:
            dragTime + (Number(item.end_time) - Number(item.start_time)),
          group: groups?.[newGroupOrder].id,
        };

        return updatedItem;
      }
      return item;
    });

    setItems(updatedItems as ScheduleItem[]);

    const updatedItem = updatedItems.find((item) => item.id === itemId);
    updateOrderPlanForMachineRequest({
      id: updatedItem?.id!,
      order_plan: selectedOrderPlan?.id!,
      machine: updatedItem?.group!,
      order_process_step_tracker: updatedItem?.orderProcessStepTrackerId,
      start_datetime: dayjs(updatedItem?.start_time),
      end_datetime: dayjs(updatedItem?.end_time),
    });
  };

  const handleItemResize = (
    itemId: number,
    dragTime: number,
    edge: ResizingItemEdgeProps.LEFT | ResizingItemEdgeProps.RIGHT
  ) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        const updatedItem = {
          ...item,
          start_time:
            edge === ResizingItemEdgeProps.LEFT ? dragTime : item.start_time,
          end_time:
            edge === ResizingItemEdgeProps.LEFT ? item.end_time : dragTime,
        };

        return updatedItem;
      }
      return item;
    });

    setItems(updatedItems as ScheduleItem[]);

    const updatedItem = updatedItems.find((item) => item.id === itemId);
    updateOrderPlanForMachineRequest({
      id: updatedItem?.id!,
      order_plan: selectedOrderPlan?.id!,
      machine: updatedItem?.group!,
      order_process_step_tracker: updatedItem?.orderProcessStepTrackerId,
      start_datetime: dayjs(updatedItem?.start_time),
      end_datetime: dayjs(updatedItem?.end_time),
    });
  };

  const removeClickedItem = (itemId: number | string) => {
    const filteredItems = items.filter((item) => item.id !== itemId);
    setItems(filteredItems);
  };

  const deleteOrderPlanForMachineHandler = async (id: number | string) => {
    try {
      await deleteOrderPlanForMachine({ id }).unwrap();
      api.success({
        message: t("plans.success-title"),
        description: t("plans.success-item-msg-del"),
      });
      removeClickedItem(id);
    } catch (error) {
      const description = handleRequestError(error, t("plans.error"));

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const showDeleteTimelineItemAlert = useConfirmDeleteModal(
    t("planner.delete-item-msg"),
    deleteOrderPlanForMachineHandler
  );

  useEffect(() => {
    addDynamicTimelineValues.setFieldValue("timelineItems", items);
  }, [items]);

  return (
    <>
      {contextHolder}
      <FormikProvider value={addDynamicTimelineValues}>
        <div className="w-[10%] flex gap-x-3 items-center justify-between mb-3">
          <NavLink to={Routes.orderPlans.url}>
            <Button type="primary" className="btn-primary--dark !m-0">
              {t("planner.back-to-order-plans")}
            </Button>
          </NavLink>

          <Button
            type="primary"
            className="!m-0 bg-red-200 hover:!bg-red-300"
            onClick={scrollToCurrentTime}
          >
            {t("planner.current-time")}
          </Button>
        </div>

        <Timeline
          ref={timelineRef}
          groups={groups}
          items={items ?? []}
          defaultTimeStart={startOfDay.toDate()}
          defaultTimeEnd={endOfDay.toDate()}
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          groupRenderer={(group) => (
            <CustomGroupHeader
              group={group}
              openDynamicItemsModal={openDynamicItemsModal}
            />
          )}
          itemRenderer={(props) => <CustomTimelineItem {...props} />}
          lineHeight={175}
          canResize="right"
          canChangeGroup={false}
          stackItems
          onItemDoubleClick={(itemId) => showDeleteTimelineItemAlert(itemId)}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onTimeChange={handleTimeChange}
        >
          <TodayMarker date={100}>
            {({ styles }) => {
              return <TodayMarkerLine styles={styles} />;
            }}
          </TodayMarker>
        </Timeline>

        <DynamicItemsModal
          isOpenModal={dynamicItemsModal}
          setDynamicItemsModal={setDynamicItemsModal}
          selectedGroup={selectedGroup}
          selectedOrderPlan={selectedOrderPlan}
        />
      </FormikProvider>
    </>
  );
};

export { TimelineScheduler };
