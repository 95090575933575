import { Input, Modal, notification } from "antd";
import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { AlertOutlined } from "@ant-design/icons";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  MaterialItemSituation,
  useCreateMaterialStockBatchItemMutation,
} from "shared/api";
import {
  ModalSizeValue,
  materialItemSituations,
  materialItemStatuses,
} from "shared/constants";
import {
  AddStockMaterialItemValues,
  DynamicValues,
  GlobalMaterialItem,
} from "shared/types";
import { DynamicFormMaterialItems } from "./DynamicFormMaterialItems";

interface AddMaterialItemModalProps {
  isOpenModal: boolean;
  hideAddMaterialModal: () => void;
  materialStockBatchID: number | null;
  selectedMaterialItemFromStock?: GlobalMaterialItem;
  dynamicValues: DynamicValues[];
  preselectedMaterialName?: string | null;
}

const AddMaterialItemModal: FC<AddMaterialItemModalProps> = ({
  isOpenModal,
  hideAddMaterialModal,
  materialStockBatchID,
  selectedMaterialItemFromStock,
  dynamicValues,
  preselectedMaterialName,
}) => {
  const { t } = useTranslation();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    isValid,
    validateForm,
  } = useFormikContext<AddStockMaterialItemValues>();

  const closeModal = () => {
    hideAddMaterialModal();
    resetForm();
  };

  useEffect(() => {
    if (isOpenModal) {
      setFieldValue("material_stock_batch", materialStockBatchID);
    }
  }, [materialStockBatchID]);

  useEffect(() => {
    validateForm();
    resetForm();
  }, [isOpenModal]);

  const [createMaterialStockBatchItem] =
    useCreateMaterialStockBatchItemMutation();

  const [api, contextHolder] = notification.useNotification();

  const createStockMaterialItem = async () => {
    try {
      await createMaterialStockBatchItem({
        material_stock_batch: materialStockBatchID!,
        dynamic_tracker_fields: values.dynamic_tracker_fields,
        quantity: values.quantity! as number,
        serial_number: values.serial_number,
        status: values.status!,
        situation: values.situation as MaterialItemSituation,
      }).unwrap();
      api.success({
        message: t("stock-material-item.success-title"),
        description: t("stock-material-item.success-add-desc"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("stock-material-item.error-title")
      );
      api.error({
        message: t("stock-material-item.error-title"),
        description,
      });
    }
  };

  const emptyQuantityField = values.quantity === null || values.quantity === "";

  return (
    <>
      {contextHolder}
      <Modal
        title={t("stock-material-item.add-item")}
        open={isOpenModal}
        width={ModalSizeValue.X_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={createStockMaterialItem}
      >
        <Title level={5}>{t("stock-material-item.material-stock-batch")}</Title>

        <Input
          value={
            selectedMaterialItemFromStock?.material?.name! ||
            (preselectedMaterialName as string)
          }
          size="large"
          disabled
        />

        <Title level={5}>{t("stock-material-item.serial-number")}</Title>

        <div className="relative">
          <FormInput
            value={values.serial_number}
            fieldName="serial_number"
            placeholder={t("stock-material-item.serial-number-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.serial_number?.toString()}
            isError={!!errors.serial_number}
          />
          {!values.serial_number.length && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}
        </div>

        <Title level={5}>{t("stock-material-item.quantity")}</Title>

        <div className="relative">
          <FormInput
            value={values.quantity}
            fieldName="quantity"
            placeholder={t("stock-material-item.quantity-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.quantity?.toString()}
            isError={!!errors.quantity}
            numberType
          />
          {emptyQuantityField && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}

          <Title level={5}>{t("stock-material-item.status")}</Title>

          <FormSelect
            value={values.status}
            placeholder={t("stock-material-item.status-placeholder")}
            fieldName="status"
            options={materialItemStatuses}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            error={errors.status?.toString()}
            isError={!!errors.status}
          />
        </div>

        <Title level={5}>{t("stock-material-item.situation")}</Title>

        <FormSelect
          value={values.situation}
          placeholder={t("stock-material-item.situation-placeholder")}
          fieldName="situation"
          options={materialItemSituations}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />

        <DynamicFormMaterialItems dynamicValues={dynamicValues} />
      </Modal>
    </>
  );
};

const AddMaterialItemModalMemo = React.memo(AddMaterialItemModal);

export { AddMaterialItemModalMemo as AddMaterialItemModal };
