import * as Yup from "yup";

export const AddOrUpdateUserSchema = Yup.object().shape({
  username: Yup.string().required("update-user-validation.username"),
  first_name: Yup.string().required("update-user-validation.firstname"),
  last_name: Yup.string().required("update-user-validation.lastname"),
  email: Yup.string()
    .email("update-user-validation.valid-email")
    .required("update-user-validation.email"),
});
