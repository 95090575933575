import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { AdaptedValues, AddMachinesValues } from "shared/types";
import { ModalSizeValue } from "shared/constants";
import { useCreateMachineMutation } from "../../../../shared/api/productCard/productCardApi";

interface AddMachineModalProps {
  isOpenModal: boolean;
  hideAddMachineModal: () => void;
  adaptedProcessTemplates: AdaptedValues[];
}

const AddMachineModal: FC<AddMachineModalProps> = ({
  isOpenModal,
  hideAddMachineModal,
  adaptedProcessTemplates,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddMachinesValues>();

  const closeModal = () => {
    hideAddMachineModal();
    resetForm();
  };

  const [createMachine] = useCreateMachineMutation();

  const createMachineRequest = async () => {
    try {
      await createMachine({
        name: values.name!,
        process_template: values.process_template!,
      }).unwrap();
      api.success({
        message: t("machines.success-title"),
        description: t("machines.success-add-msg"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(error, t("machines.error"));

      api.error({
        message: t("machines.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("machines.add-machine-title")}
        open={isOpenModal}
        width={ModalSizeValue.PRIMARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          createMachineRequest();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("machines.input-title")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("machines.machine-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5}> {t("machines.select-title")}</Title>

        <FormSelect
          value={values.process_template}
          placeholder={t("machines.process-type-placeholder")}
          fieldName="process_template"
          options={adaptedProcessTemplates}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.process_template?.toString()}
          isError={!!errors.process_template}
        />
      </Modal>
    </>
  );
};

export { AddMachineModal };
