import { useMemo } from "react";
import {
  adaptMachines,
  adaptMaterialCategories,
  adaptMaterials,
  adaptProcessTemplates,
} from "shared/adapters";
import {
  MachineItem,
  Widget,
  WidgetTypeEnum,
  useGetAllMachinesQuery,
  useGetAllMaterialsNoPaginationQuery,
  useGetAppProcessTemplatesWithNoParamsQuery,
  useGetMaterialCategoriesWithNoParamsQuery,
} from "shared/api";
import {
  articleFlowStatuses,
  getFiltersByWidgetType,
  materialConsumptionStatuses,
  orderStatusWidgetStatuses,
} from "shared/helpers";
import {
  AllFilterKeys,
  ArticleFlowFilterKeysEnum,
  MachinesXFilterKeysEnum,
  MaterialConsumptionFilterKeysEnum,
  MaterialTargetAlertFilterKeysEnum,
  OrderStatusFilterKeysEnum,
  ProductionTrackerFilterKeysEnum,
} from "shared/helpers/widgets/types";

interface UseWidgetFiltersProps {
  widget: Widget;
}

export const useWidgetFilters = ({ widget }: UseWidgetFiltersProps) => {
  const {
    data: materials,
    isLoading: isLoadingMaterials,
    isFetching: isFetchingMaterials,
  } = useGetAllMaterialsNoPaginationQuery(undefined, {
    skip:
      widget?.type !== WidgetTypeEnum.ITEM_FLOW &&
      widget?.type !== WidgetTypeEnum.QUALITY_TRACKING &&
      widget?.type !== WidgetTypeEnum.PRODUCTION_DATA_TRACKER,
  });

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials ?? []);
  }, [materials]);

  const {
    data: processTemplates,
    isLoading: isLoadingProcessTemplates,
    isFetching: isFetchingProcessTemplates,
  } = useGetAppProcessTemplatesWithNoParamsQuery(undefined, {
    skip: widget?.type !== WidgetTypeEnum.MACHINES,
  });

  const adaptedProcessTemplates = useMemo(() => {
    return adaptProcessTemplates(processTemplates || []);
  }, [processTemplates]);

  const {
    data: materialCategories,
    isLoading: isLoadingMaterialCategories,
    isFetching: isFetchingMaterialCategories,
  } = useGetMaterialCategoriesWithNoParamsQuery();

  const adaptedMaterialCategories = useMemo(() => {
    return adaptMaterialCategories(materialCategories ?? []);
  }, [materialCategories]);

  const { data: machines } = useGetAllMachinesQuery(
    {},
    { skip: widget?.type !== WidgetTypeEnum.ORDER_STATUS }
  );

  const adaptedMachines = useMemo(() => {
    return adaptMachines((machines as unknown as MachineItem[]) ?? []);
  }, [machines]);

  const getFiltersOptionsByFilterParameter = (
    filterParameter: AllFilterKeys
  ) => {
    switch (filterParameter) {
      case ArticleFlowFilterKeysEnum.MATERIAL:
        return adaptedMaterials;

      case ArticleFlowFilterKeysEnum.STATUS:
        return articleFlowStatuses;

      case MaterialConsumptionFilterKeysEnum.STATUS:
        return materialConsumptionStatuses;

      case MachinesXFilterKeysEnum.PROCESS_TEMPLATE:
        return adaptedProcessTemplates;

      case OrderStatusFilterKeysEnum.STATUS:
        return orderStatusWidgetStatuses;

      case OrderStatusFilterKeysEnum.MACHINES:
        return adaptedMachines;

      case MaterialTargetAlertFilterKeysEnum.MATERIAL_CATEGORY:
        return adaptedMaterialCategories;

      case ProductionTrackerFilterKeysEnum.MATERIAL:
        return adaptedMaterials;

      case ProductionTrackerFilterKeysEnum.STATUS:
        return articleFlowStatuses;

      default:
        return [];
    }
  };

  const filterKeys = useMemo(() => {
    return getFiltersByWidgetType(widget.type as WidgetTypeEnum);
  }, [widget]);

  const isDataLoading =
    isLoadingMaterials ||
    isLoadingProcessTemplates ||
    isLoadingMaterialCategories;
  const isDataFetching =
    isFetchingMaterials ||
    isFetchingProcessTemplates ||
    isFetchingMaterialCategories;

  const isLoading = isDataLoading || isDataFetching;

  return {
    filterKeys,
    getFiltersOptionsByFilterParameter,
    isLoading,
  };
};
