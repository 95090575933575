import { Input, Modal } from "antd";
import Title from "antd/es/typography/Title";
import { FormikProvider, useFormik } from "formik";
import { CompaniesSelect } from "pages/SupplyChain/MaterialManagement/ui";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MaterialCategoriesItem } from "shared/api";
import {
  materialManagementInitialValues,
  ModalSizeValue,
} from "shared/constants";
import { noop, renderUnitName } from "shared/helpers";
import { useMaterialManagementRequests } from "shared/hooks";
import { CreateMaterialSchema } from "shared/schemas";
import { Colors } from "shared/themes";
import { AdaptedValues, SubmitCreateMaterialValues } from "shared/types";
import { FormInput, FormSelect } from "shared/ui";

interface CreateMaterialManagementItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  materialCategoriesAdapted: AdaptedValues[];
  materialCategories?: MaterialCategoriesItem[];
}

const CreateMaterialManagementItemModal: FC<
  CreateMaterialManagementItemModalProps
> = ({ isOpen, onClose, materialCategoriesAdapted, materialCategories }) => {
  const { t } = useTranslation();

  const addMaterialItemFormik = useFormik<SubmitCreateMaterialValues>({
    initialValues: materialManagementInitialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: CreateMaterialSchema,
    onSubmit: noop,
  });

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    isValid,
    resetForm,
  } = addMaterialItemFormik;

  const closeModalAndResetForm = () => {
    onClose();
    resetForm();
  };

  const { contextHolder, createMaterialItem } = useMaterialManagementRequests({
    closeAddMaterialModal: closeModalAndResetForm,
  });

  const handleCreateMaterialItem = () => {
    createMaterialItem({
      name: values?.name,
      material_category: values?.material_category.id!,
      suppliers: values.suppliers!,
      target_quantity_in_stock: values.target_quantity_in_stock!,
      n_packaging_items: values.n_packaging_items!,
      expanded_label_name: values.expanded_label_name!,
    });
  };

  return (
    <FormikProvider value={addMaterialItemFormik}>
      {contextHolder}
      <Modal
        open={isOpen}
        title={t("material-management.add-modal-name")}
        width={ModalSizeValue.X_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onOk={handleCreateMaterialItem}
        onCancel={onClose}
      >
        <Title level={5}>{t("material-management.name")}</Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("material-management.create-modal-name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name?.toString()}
          isError={!!errors.name}
        />

        <Title level={5}>{t("material-management.expanded-name")}</Title>

        <FormInput
          value={values.expanded_label_name}
          fieldName="expanded_label_name"
          placeholder={t(
            "material-management.create-modal-expanded-name-placeholder"
          )}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.expanded_label_name?.toString()}
          isError={!!errors.expanded_label_name}
        />

        <Title level={5}>{t("material-management.category-name")}</Title>

        <FormSelect
          value={values?.material_category?.id}
          placeholder={t(
            "material-management.create-modal-material-category-placeholder"
          )}
          fieldName="material_category.id"
          options={materialCategoriesAdapted}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.material_category?.id?.toString()}
          isError={!!errors.material_category?.id}
          showSearch
        />

        <Title level={5}>
          {t("material-management.target-quantity-in-stock")}
        </Title>

        <FormInput
          value={values.target_quantity_in_stock}
          fieldName="target_quantity_in_stock"
          placeholder={t(
            "material-management.target-quantity-in-stock-placeholder"
          )}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.target_quantity_in_stock?.toString()}
          isError={!!errors.target_quantity_in_stock}
          numberType
        />

        <Title level={5}>{t("material-management.n-packaging-items")}</Title>

        <FormInput
          value={values.n_packaging_items}
          fieldName="n_packaging_items"
          placeholder={t("material-management.n-packaging-items-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.n_packaging_items?.toString()}
          isError={!!errors.n_packaging_items}
          numberType
        />

        <Title level={5}>{t("material-management.unit")}</Title>

        <Input
          disabled
          value={renderUnitName(values, materialCategories ?? [])}
          size="large"
        />

        <CompaniesSelect />
      </Modal>
    </FormikProvider>
  );
};

export { CreateMaterialManagementItemModal };
