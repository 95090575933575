import { PackageSituationEnum } from "shared/api";

export const packageSituations: Record<PackageSituationEnum, string> = {
  [PackageSituationEnum.NO]: "packaging.NO",
  [PackageSituationEnum.SE]: "packaging.SE",
};

export const renderPackageSituation = (situation: PackageSituationEnum) => {
  const status = packageSituations[situation];
  return status || "Unknown Situation";
};
