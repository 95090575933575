import { SupplierItemWithCost } from "shared/api";

export const disableAddSuppliersButton = (
  suppliers: SupplierItemWithCost[]
) => {
  const adaptedSupplierCheck = suppliers?.some((supplier) => {
    const supplierExists = !!supplier?.supplier;

    const costPerUnitDefined =
      supplier?.cost_per_unit !== null && supplier?.cost_per_unit !== undefined;

    const costPerUnitCurrencyExists = !!supplier?.cost_per_unit_currency;

    return (
      !supplierExists ||
      (costPerUnitDefined && !costPerUnitCurrencyExists) ||
      (!costPerUnitDefined && costPerUnitCurrencyExists)
    );
  });

  return adaptedSupplierCheck;
};

export const getUnitTitleForTheTableHeader = (dynamicUnitName: string) => {
  return dynamicUnitName?.endsWith("s")
    ? dynamicUnitName?.slice(0, -1)
    : dynamicUnitName;
};
