import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";

interface HyperlinkPageHeaderProps {
  title: string;
  onOk: () => Promise<void>;
  isValid: boolean;
}

const HyperlinkPageHeader: FC<HyperlinkPageHeaderProps> = ({
  title,
  onOk,
  isValid,
}) => {
  return (
    <header className="h-10 bg-blue-primary sticky top-0 w-full z-50 flex items-center justify-between p-2">
      <Title level={5} className="!text-white">
        {title}
      </Title>

      <Button
        onClick={onOk}
        disabled={!isValid}
        danger={!isValid}
        className="!bg-white"
      >
        {isValid ? "Update" : "Form is not valid"}
      </Button>
    </header>
  );
};

export { HyperlinkPageHeader };
