import { notification } from "antd";
import { FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import {
  MaterialCategoriesItem,
  MaterialsItem,
  Widget,
  useUpdateWidgetMutation,
} from "shared/api";
import {
  adaptFilterForTheWidgetValues,
  adaptWidgetFiltersToUpdate,
  getConfigurationValues,
  handleRequestError,
  noop,
} from "shared/helpers";
import { useWidgetSettings, useWidgetTableData } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { PollingData } from "shared/hooks/usePollingSeconds";
import { widgetsValidationSchema } from "shared/schemas";
import { WidgetSettingsDialog } from "./widgets/WidgetSettingsDialog";
import { WidgetConfigurationsDialog } from "./widgets/WidgetConfigurationsDialog";
import { SettingsButtonContainer } from "./widgets/SettingsButtonContainer";

export interface WidgetMaterialCategories {
  materialCategories: MaterialCategoriesItem[] | undefined;
  isLoadingMaterialCategories: boolean;
}

export interface WidgetMaterials {
  allMaterials: MaterialsItem[] | undefined;
  isLoadingAllMaterials: boolean;
}

interface WidgetSettingsProps {
  widget: Widget;
  materialCategories: WidgetMaterialCategories;
  materials: WidgetMaterials;
  pollingData: PollingData;
}

const WidgetSettings: FC<WidgetSettingsProps> = ({
  widget,
  materialCategories,
  materials,
  pollingData,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [updateWidget] = useUpdateWidgetMutation();

  const { hardRefetch } = useWidgetTableData({
    widget,
    pollingData,
  });

  const {
    isVisibleSettings,
    showSettings,
    hideSettings,
    isVisibleConfigurations,
    showConfigurations,
    hideConfigurations,
    disableSettingsButton,
    widgetColumnsOptions,
    widgetSortByOptions,
  } = useWidgetSettings({ widget });

  const widgetFormik = useFormik<Widget>({
    initialValues: {
      ...widget,
      filter: adaptFilterForTheWidgetValues(widget?.filter),
    },
    enableReinitialize: true,
    validationSchema: widgetsValidationSchema,
    onSubmit: noop,
  });

  const { values: widgetValues } = widgetFormik;

  const {
    materialCategoryValue,
    // TODO: double check this clearing logic
    // materialCategoryField,
    dynamicFieldValue,
    // TODO: double check this clearing logic
    // dynamicFieldField,
  } = getConfigurationValues(widgetValues);

  const closeConfigurationsAndClearValues = () => {
    hideConfigurations();
    // TODO: double check this clearing logic
    // Seems to works correct without this fields

    // setWidgetValue(materialCategoryField, undefined);
    // setWidgetValue(dynamicFieldField, undefined);
  };

  const isActiveConfigurationsButton =
    !!materialCategoryValue && !!dynamicFieldValue;

  const updateWidgetSettings = async () => {
    try {
      await updateWidget({
        id: widgetValues?.id!,
        name: widgetValues?.name ? widgetValues?.name : null,
        column: widgetValues?.column,
        row: widgetValues?.row,
        type: widgetValues?.type,
        uuid: widgetValues?.uuid,
        column_names: widgetValues?.column_names,
        limit_rows: widgetValues?.limit_rows,
        sort_by: [widgetValues?.sort_by].flat() as unknown as string[],
        filter: adaptWidgetFiltersToUpdate(widgetValues),
        dashboard: widgetValues?.dashboard,
        configuration: widgetValues?.configuration,
        influxdb_server: widgetValues?.influxdb_server,
        standalone_query: widgetValues?.standalone_query,
      }).unwrap();

      api.success({
        message: t("widgets.success"),
        description: t("widgets.successMsg"),
      });
    } catch (error) {
      api.error({
        message: t("widgets.error"),
        description: handleRequestError(error, t("widgets.error")),
      });
    } finally {
      hideSettings();
      hideConfigurations();
      hardRefetch(widget?.type);
    }
  };

  return (
    <>
      {contextHolder}

      <SettingsButtonContainer
        widget={widget}
        showConfigurations={showConfigurations}
        disableSettingsButton={disableSettingsButton}
        showSettings={showSettings}
      />

      <FormikProvider value={widgetFormik}>
        <WidgetSettingsDialog
          isVisibleSettings={isVisibleSettings}
          hideSettings={hideSettings}
          updateWidgetSettings={updateWidgetSettings}
          widget={widget}
          widgetColumnsOptions={widgetColumnsOptions}
          widgetSortByOptions={widgetSortByOptions}
        />

        <WidgetConfigurationsDialog
          closeConfigurationsAndClearValues={closeConfigurationsAndClearValues}
          updateWidgetSettings={updateWidgetSettings}
          isActiveConfigurationsButton={isActiveConfigurationsButton}
          widget={widget}
          isVisibleConfigurations={isVisibleConfigurations}
          materialCategories={materialCategories}
          materials={materials}
        />
      </FormikProvider>
    </>
  );
};

const WidgetSettingsMemo = React.memo(WidgetSettings);

export { WidgetSettingsMemo as WidgetSettings };
