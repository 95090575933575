export enum OrderingDirection {
  ASC = "ascend",
  DESC = "descend",
}

export const sortDirections = [
  OrderingDirection.ASC,
  OrderingDirection.DESC,
  OrderingDirection.ASC,
];
