import classNames from "classnames";
import React, { FC } from "react";
import { ReportItem } from "shared/api";

interface ReportSidebarItemProps {
  report: ReportItem;
  onClick: () => void;
  isSelected: boolean;
}

const ReportSidebarItem: FC<ReportSidebarItemProps> = ({
  report,
  onClick,
  isSelected,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        `
        pl-3 text-start text-[12px] h-auto 
        hover:bg-blue-500 hover:text-white
        `,
        {
          "bg-blue-500 text-white": isSelected,
        }
      )}
      onClick={onClick}
    >
      {report.name}
    </button>
  );
};

export { ReportSidebarItem };
