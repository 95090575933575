import { AdaptedValues } from "shared/types";
import { useMemo, useState } from "react";

interface ProcessStepCheckBox {
  label: string;
  value: number;
  key?: number;
}

interface UseProcessStepKeysProps {
  processSteps: number[];
  adaptedSteps: AdaptedValues[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  hideProcessStepsModal: () => void;
  selectedNameValue: string;
  nameValue: string;
}

export const useProcessStepKeys = ({
  processSteps,
  adaptedSteps,
  setFieldValue,
  hideProcessStepsModal,
  nameValue,
  selectedNameValue,
}: UseProcessStepKeysProps) => {
  const fieldName = "process_steps";

  const initialProcessSteps = useMemo(() => processSteps, [processSteps]);

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
    (processSteps as number[]) || []
  );

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      const allKeys = adaptedSteps.map((card) => card.value);
      setSelectedKeys(allKeys);
    } else {
      setSelectedKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys((prevKeys) =>
        Array.from(new Set([...prevKeys, ...selectedRowKeys]))
      );
    },
    onSelectAll: (selected: boolean) => {
      onSelectAll(selected);
    },
    getCheckboxProps: (record: ProcessStepCheckBox) => ({
      name: record.label,
    }),
  };

  const addProcessSteps = () => {
    setFieldValue(fieldName, selectedKeys);

    hideProcessStepsModal();
  };

  const closeModal = () => {
    setFieldValue(fieldName, initialProcessSteps);

    hideProcessStepsModal();

    setSelectedKeys([]);
  };

  const selectedProcessSteps = useMemo(() => {
    return adaptedSteps.filter((card) => selectedKeys.includes(card.value));
  }, [selectedKeys, adaptedSteps]);

  const filteredAllSteps = useMemo(() => {
    return adaptedSteps.filter((step) =>
      step.label.toLowerCase().includes(nameValue.toLowerCase())
    );
  }, [adaptedSteps, nameValue]);

  const filteredSelectedSteps = useMemo(() => {
    return selectedProcessSteps.filter((step) =>
      step.label.toLowerCase().includes(selectedNameValue.toLowerCase())
    );
  }, [selectedProcessSteps, selectedNameValue]);

  return {
    rowSelection,
    selectedProcessSteps,
    addProcessSteps,
    closeModal,
    filteredAllSteps,
    filteredSelectedSteps,
  };
};
