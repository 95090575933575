import { Spin } from "antd";
import classNames from "classnames";
import React, { FC } from "react";

interface LoaderProps {
  type?: "fullscreen" | "fullscreen-backdrop";
  className?: string;
}

const Loader: FC<LoaderProps> = ({ type, className }) => {
  const isFullScreen = type === "fullscreen";
  const isFullScreenWithBackdrop = type === "fullscreen-backdrop";

  return (
    <div
      className={classNames(
        "w-full flex items-center justify-center mt-3",
        {
          "h-screen": isFullScreen || isFullScreenWithBackdrop,
          "bg-black bg-opacity-20 fixed top-0 left-0 z-50 !m-0":
            isFullScreenWithBackdrop,
        },
        className
      )}
    >
      <Spin
        size={isFullScreen || isFullScreenWithBackdrop ? "large" : "default"}
      />
    </div>
  );
};

export { Loader };
