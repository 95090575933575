import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "shared/translations";

const initLocalization = () => {
  i18n.use(initReactI18next).init({
    compatibilityJSON: "v3",
    resources,
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};

const changeLanguage = (newLanguage: string) => {
  i18n.changeLanguage(newLanguage);
};

export const LocalizationService = {
  initLocalization,
  changeLanguage,
};
