import { ItemType } from "antd/es/menu/hooks/useItems";
import { useState, useEffect } from "react";
import { useGetAllDashboardsQuery } from "shared/api";
import { getDashboardChildren } from "shared/helpers";
import { Routes } from "shared/routers";
import { sidebarItemsData } from "shared/ui/Sidebar/constants";

const useDashboardSidebarItems = () => {
  const [sidebarItems, setSidebarItems] = useState<ItemType[]>(
    sidebarItemsData || []
  );

  const { data: dashboards } = useGetAllDashboardsQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    const dashboardChildren = getDashboardChildren(dashboards || []);

    const dashboardItemIndex = sidebarItems.findIndex(
      (item) => item?.key === Routes.dashboards.url
    );

    if (dashboardItemIndex !== -1) {
      const newSidebarItems: ItemType[] = [...sidebarItems];
      const dashboardItem = newSidebarItems[dashboardItemIndex];

      if (dashboardItem && "children" in dashboardItem) {
        dashboardItem.children = dashboardChildren;
        setSidebarItems(newSidebarItems);
      }
    }
  }, [dashboards]);

  return sidebarItems;
};

export { useDashboardSidebarItems };
