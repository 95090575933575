import { useEffect } from "react";
import { AddCompanyValues } from "shared/types";

interface UseCompanyRoleProps {
  values: AddCompanyValues;
  setFieldValue: (field: string, value: any) => void;
}

const useCompanyRole = ({ values, setFieldValue }: UseCompanyRoleProps) => {
  const bothCompanyRoles =
    values?.is_supplier === true && values?.is_buyer === true;

  const isSupplierRole =
    values?.is_supplier === true && values?.is_buyer === false;

  const isCustomerRole =
    values?.is_supplier === false && values?.is_buyer === true;

  useEffect(() => {
    if (bothCompanyRoles) {
      setFieldValue("is_supplier", "both");
    }

    if (isSupplierRole) {
      setFieldValue("is_supplier", "supplier");
    }

    if (isCustomerRole) {
      setFieldValue("is_supplier", "customer");
    }
  }, [
    values?.is_supplier,
    bothCompanyRoles,
    isSupplierRole,
    isCustomerRole,
    setFieldValue,
  ]);
};

export { useCompanyRole };
