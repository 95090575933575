import { Alert, Button, Modal, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  adaptMaterials,
  adaptPackagesForMultiplePackagingReport,
} from "shared/adapters";
import {
  PackagingItem,
  useGetAllMaterialsNoPaginationQuery,
  useGetAllPackagesQuery,
} from "shared/api";
import { useOrdersRequests } from "shared/hooks";
import { Colors } from "shared/themes";
import { Loader } from "shared/ui";

interface GenerateMultiplePackagingReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderIdToGenerateReport: number | null;
  clearOrderId: () => void;
}

const GenerateMultiplePackagingReportModal: FC<
  GenerateMultiplePackagingReportModalProps
> = ({ isOpen, onClose, orderIdToGenerateReport, clearOrderId }) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const [materialId, setMaterialId] = useState<number | null>(null);
  const onMaterialChange = (value: number | null) => setMaterialId(value);

  const [packagesIds, setPackagesIds] = useState<number[] | null>([]);
  const onPackageChange = (values: number[]) => setPackagesIds(values);

  const clearAllValuesAndCloseModal = () => {
    clearOrderId();
    onMaterialChange(null);
    onPackageChange([]);
    onClose();
  };

  const {
    generateMultiplePackagingReportHandler,
    isLoadingGenerateMultiplePackagingReport,
  } = useOrdersRequests(clearAllValuesAndCloseModal);

  const {
    data: materials,
    isLoading,
    isFetching,
  } = useGetAllMaterialsNoPaginationQuery(
    {
      order: orderIdToGenerateReport ?? undefined,
    },
    {
      skip: !orderIdToGenerateReport,
    }
  );

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials ?? []);
  }, [materials]);

  const {
    data: packagings,
    isLoading: isLoadingPackaging,
    isFetching: isFetchingPackages,
    refetch: refetchPackagesData,
  } = useGetAllPackagesQuery(
    {
      order: orderIdToGenerateReport ?? undefined,
      material: materialId ?? undefined,
    },
    {
      skip: !orderIdToGenerateReport || !materialId,
    }
  );

  const refetchPackages = () => {
    refetchPackagesData();
  };

  const adaptedPackages = useMemo(() => {
    return adaptPackagesForMultiplePackagingReport(
      packagings as unknown as PackagingItem[]
    );
  }, [packagings]);

  const selectAllPackages = () => {
    const allPackagesIds = adaptedPackages?.map((pack) => pack.value);
    setPackagesIds(allPackagesIds);
  };

  if (isLoading || isLoadingPackaging || isFetching || isFetchingPackages) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <Modal
      open={isOpen}
      onCancel={clearAllValuesAndCloseModal}
      onOk={() =>
        generateMultiplePackagingReportHandler(
          {
            id: orderIdToGenerateReport as number,
            material_id: materialId,
            packaging_ids: packagesIds,
          },
          refetchPackages
        )
      }
      okButtonProps={{
        style: {
          background: Colors.blue.primary,
        },
        loading: isLoadingGenerateMultiplePackagingReport,
      }}
    >
      <Title level={5}> {t("selectMaterial")}</Title>

      <Select
        value={materialId}
        onChange={onMaterialChange}
        options={adaptedMaterials}
        placeholder={t("selectMaterial")}
        className="w-full"
      />

      <div className="flex items-center justify-between my-2">
        <Title level={5} className="!m-0">
          {t("packages")}
        </Title>
        <Button onClick={selectAllPackages}>{t("selectAll")}</Button>
      </div>

      {adaptedPackages?.length ? (
        <Select
          mode="multiple"
          value={packagesIds}
          onChange={onPackageChange}
          options={adaptedPackages}
          placeholder={t("packages")}
          className="w-full"
        />
      ) : (
        <Alert type="warning" message={t("noOptions")} />
      )}
    </Modal>
  );
};

export { GenerateMultiplePackagingReportModal };
