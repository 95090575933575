import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormInput } from "shared/ui";
import { DynamicFormsValuesType } from "./constants";

interface DescriptionSelectProps {
  index: number;
  fieldIndex: number;
}

const DescriptionSelect: FC<DescriptionSelectProps> = ({
  index,
  fieldIndex,
}) => {
  const { t } = useTranslation();

  const { handleBlur, values, handleChange } =
    useFormikContext<DynamicFormsValuesType>();

  return (
    <div className="w-[16%]">
      <Title level={5}>{t("material-category-management.description")}</Title>
      <FormInput
        key={`sections[${index}].fields[${fieldIndex}].description`}
        value={values.sections[index].fields?.[fieldIndex]?.description}
        fieldName={`sections[${index}].fields[${fieldIndex}].description`}
        placeholder={t("material-category-management.description-placeholder")}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </div>
  );
};

export { DescriptionSelect };
