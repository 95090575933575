import { Dashboard, useGetDashboardByIdQuery } from "shared/api";
import { useEffect, useMemo, useState } from "react";

interface UseDashboardMainPageRequestsProps {
  dashboardId?: string;
}

export const useDashboardMainPageRequests = ({
  dashboardId,
}: UseDashboardMainPageRequestsProps) => {
  const { data: dashboardDetails, isLoading } = useGetDashboardByIdQuery(
    {
      id: Number(dashboardId),
    },
    {
      skip: !dashboardId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const [dashboardsData, setDashboardsData] = useState<Dashboard | undefined>(
    undefined
  );

  useEffect(() => {
    if (dashboardDetails) {
      setDashboardsData(dashboardDetails);
    }
  }, [dashboardDetails]);

  const sortedWidgetsWithArticle = useMemo(() => {
    return {
      id: dashboardDetails?.id,
      name: dashboardDetails?.name,
      widgets: dashboardDetails?.widgets
        ?.slice()
        .sort((a, b) => (a.row ?? Infinity) - (b.row ?? Infinity))
        .map((widget) => ({
          ...widget,
        })),
    };
  }, [dashboardDetails]);

  return {
    dashboardsData,
    isLoading,
    sortedWidgetsWithArticle: sortedWidgetsWithArticle as Dashboard,
  };
};
