import classNames from "classnames";
import React, { FC } from "react";
import {
  InputMaterialCategory,
  MaterialStockItem,
  OrderStepTrackerResponseItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { useFormikContext } from "formik";
import { AdaptedValues, InitialInputMaterials } from "shared/types";
import { MaterialName } from "./MaterialName";
import { AddProductionRequest } from "./ProcessStepProduction";
import { ProductionInputMaterialsForm } from "./ProductionInputMaterialsForm";

interface InputMaterialCardProps {
  inputIndex: number;
  moreThanOneMaterial: boolean;
  inputMaterial: ProductionInputMaterial;
  adaptedMaterials: AdaptedValues[];
  adaptedMaterialItems: AdaptedValues[];
  adaptedStockMaterialBatches: AdaptedValues[];
  adaptedInitialInputMaterials: InitialInputMaterials[];
  materialStockBatches?: StockMaterialItem[];
  materialItems?: MaterialStockItem[];
  dynamicInputMaterialCategory?: InputMaterialCategory;
  addCard: (obj: any) => void;
  removeCard: <T>(index: number) => T | undefined;
  isLoadingInputMaterialsData?: boolean;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const InputMaterialCard: FC<InputMaterialCardProps> = ({
  inputIndex,
  moreThanOneMaterial,
  inputMaterial,
  adaptedMaterials,
  adaptedMaterialItems,
  adaptedStockMaterialBatches,
  adaptedInitialInputMaterials,
  materialStockBatches,
  materialItems,
  dynamicInputMaterialCategory,
  addCard,
  removeCard,
  isLoadingInputMaterialsData,
  orderStepTrackerResponseItem,
}) => {
  const { values } = useFormikContext<AddProductionRequest>();

  return (
    <div
      className={classNames(
        `
        w-full p-2 border-b-[1px] border-black flex 
        gap-x-8  flex-col items-start justify-start
        h-auto
      `
      )}
    >
      <MaterialName
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        inputIndex={inputIndex}
        inputMaterial={inputMaterial}
        handleAddRow={() =>
          addCard({ variant: null, quantity: null, selectValue: null })
        }
      />

      <div
        className={classNames("w-full", {
          "flex flex-wrap gap-2": moreThanOneMaterial,
        })}
      >
        {inputMaterial?.fields?.map((_, fieldIndex) => {
          return (
            <ProductionInputMaterialsForm
              key={fieldIndex}
              values={values}
              inputIndex={inputIndex}
              fieldIndex={fieldIndex}
              adaptedInitialInputMaterials={adaptedInitialInputMaterials}
              adaptedMaterialItems={adaptedMaterialItems}
              adaptedMaterials={adaptedMaterials}
              adaptedStockMaterialBatches={adaptedStockMaterialBatches}
              materialItems={materialItems}
              materialStockBatches={materialStockBatches}
              moreThanOneMaterial={moreThanOneMaterial}
              isLoadingInputMaterialsData={isLoadingInputMaterialsData}
              inputMaterial={inputMaterial}
              removeCard={removeCard}
              dynamicInputMaterialCategory={dynamicInputMaterialCategory}
            />
          );
        })}
      </div>
    </div>
  );
};

export { InputMaterialCard };
