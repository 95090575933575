import { NotificationInstance } from "antd/es/notification/interface";
import { useTranslation } from "react-i18next";
import {
  useCreateStandAloneConfigurationsMutation,
  useDeleteStandAloneConfigurationsMutation,
  useUpdateStandAloneConfigurationsMutation,
} from "shared/api";
import { StandAloneItem } from "shared/api/standAlone/models";
import { handleRequestError } from "shared/helpers";
import { StandAloneFormik } from "shared/types";
import { useConfirmDeleteModal } from "./useConfirmDeleteModal";

interface StandAloneRequests {
  api: NotificationInstance;
  closeDialog?: () => void;
}

export const useStandAloneRequests = ({
  api,
  closeDialog,
}: StandAloneRequests) => {
  const { t } = useTranslation("", { keyPrefix: "standAlone" });

  const [createStandAloneConfigurations] =
    useCreateStandAloneConfigurationsMutation();

  const [deleteStandAloneConfigurations] =
    useDeleteStandAloneConfigurationsMutation();

  const [updateStandAloneConfigurations] =
    useUpdateStandAloneConfigurationsMutation();

  const handleCreateStandAloneItem = async (values: StandAloneFormik) => {
    try {
      await createStandAloneConfigurations({
        name: values.name!,
        description: values.description!,
        url: values.url!,
        token: values.token!,
        bucket: values.bucket ? values.bucket : null,
        org: values.org ? values.org : null,
      }).unwrap();

      closeDialog?.();

      api.success({
        message: t("success"),
        description: t("successAdd"),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  const deleteStandAloneHandler = async (record: StandAloneItem) => {
    try {
      await deleteStandAloneConfigurations(record).unwrap();
      api.success({
        message: t("success"),
        description: t("successDel"),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  const showStandAloneDeleteModal = useConfirmDeleteModal(
    t("deleteWarning"),
    deleteStandAloneHandler
  );

  const updateStandAloneHandler = async (record: StandAloneItem) => {
    try {
      await updateStandAloneConfigurations(record).unwrap();
      api.success({
        message: t("success"),
        description: t("successUpd"),
      });

      closeDialog?.();
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  return {
    handleCreateStandAloneItem,
    showStandAloneDeleteModal,
    updateStandAloneHandler,
  };
};
