import { useMemo } from "react";
import { adaptCompanies, adaptProductCard } from "shared/adapters";
import {
  useGetAllCompaniesQuery,
  useGetAllProductCardsWithNoParamsQuery,
} from "shared/api";

interface useOrdersOptionsProps {
  buyer: number;
}

const useOrdersOptions = ({ buyer }: useOrdersOptionsProps) => {
  const { data: companies } = useGetAllCompaniesQuery(
    { is_buyer: true, is_parent_company: true },
    {
      refetchOnReconnect: true,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const companiesAdapted = useMemo(() => {
    return adaptCompanies(companies || []);
  }, [companies]);

  const { data: subBuyers } = useGetAllCompaniesQuery(
    { is_buyer: true, parent_company: Number(buyer) },
    {
      refetchOnReconnect: true,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: !buyer,
    }
  );

  const adaptedSubBuyers = useMemo(() => {
    return adaptCompanies(subBuyers || []);
  }, [subBuyers]);

  const { data: productCards } = useGetAllProductCardsWithNoParamsQuery(
    {
      company: buyer!,
    },

    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: buyer === null,
    }
  );

  const adaptedProductCard = useMemo(() => {
    return adaptProductCard(productCards || []);
  }, [productCards]);

  const dynamicCompanyFields = useMemo(() => {
    return companies?.find((item) => item.id === buyer);
  }, [companies, buyer]);

  return {
    companiesAdapted,
    adaptedSubBuyers,
    adaptedProductCard,
    dynamicCompanyFields,
  };
};

export { useOrdersOptions };
