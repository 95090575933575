export const expandMaterialItemsStringWithUnit =
  "material_stock_batch.material.material_category.unit";

export const materialItemStatuses = [
  { value: "P", label: "Prima" },
  { value: "W", label: "Wasted" },
  { value: "I", label: "Investigation" },
];

export const enum MaterialItemSituation {
  NORMAL = "N",
  SENT = "SE",
  USED = "US",
}

export const materialItemSituations = [
  { value: "N", label: "Normal" },
  { value: "SE", label: "Sent" },
  { value: "US", label: "Used" },
];

export const columnsMaterialItemsData = {
  timestamp: {
    title: "materialItemsColumns.created",
    width: "10%",
  },
  materialName: {
    title: "materialItemsColumns.materialName",
    width: "10%",
  },
  serialNumber: {
    title: "materialItemsColumns.serialNumber",
    dataIndex: "serial_number",
    key: "serial_number",
    width: "10%",
  },
  set: {
    title: "SET",
    width: "2%",
  },
  pos: {
    title: "POS",
    width: "2%",
  },
  quantity: {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "10%",
  },
  calcTotalOriginalQuantity: {
    title: "materialItemsColumns.toq",
    dataIndex: "calc_total_original_quantity",
    key: "calc_total_original_quantity",
    width: "10%",
  },
  calcTotalConsumedQuantity: {
    title: "materialItemsColumns.tcq",
    dataIndex: "calc_total_consumed_quantity",
    key: "calc_total_consumed_quantity",
    width: "10%",
  },
  calcTotalRemainingQuantity: {
    title: "materialItemsColumns.trq",
    dataIndex: "calc_total_remaining_quantity",
    key: "calc_total_remaining_quantity",
    width: "10%",
  },
  status: {
    title: "materialItemsColumns.status",
    dataIndex: "status",
    key: "status",
    width: "10%",
  },
  calcPercentageRemainingQuantity: {
    title: "materialItemsColumns.percentage",
    dataIndex: "calc_percentage_remaining_quantity",
    key: "calc_percentage_remaining_quantity",
    width: "5%",
  },
  situation: {
    title: "materialItemsColumns.situation",
  },
  actions: {
    title: "materialItemsColumns.printLabel",
    key: "actions",
    width: "5%",
  },
};

export const MaterialItemsWidth = {
  materialName: "20%",
  serialNumber: "20%",
  calcTotalOriginalQuantity: "16%",
  calcTotalConsumedQuantity: "10%",
  calcTotalRemainingQuantity: "16%",
};

export const materialItemInitialValues = {
  id: null,
  status: null,
  material_stock_batch: null,
  serial_number: "",
  quantity: null,
  dynamic_tracker_fields: {},
  situation: null,
};

export const materialReservationInitialValues = {
  id: null,
  material: null,
  reservation_date: null,
  quantity: null,
};
