import {
  MachineItem,
  OrderItem,
  OrderStatusColumns,
  Widget,
  WidgetTableData,
  WidgetTypeEnum,
} from "shared/api";
import { widgetsIcons } from "shared/assets";

export const enum OrderStatusWidgetEnum {
  ICON = "icon",
  WIDGET_TYPE = "ORDER_STATUS",
  ORDER_STATUS_DATE_TIME = "orderStatusDateTime",
  ORDER_STATUS_MACHINES = "orderStatusMachines",
  ORDER_STATUS_ORDER = "orderStatusOrder",
  ORDER_STATUS = "orderStatus",
  ORDER_STATUS_ID = "orderStatusId",
  ORDER_STATUS_PACKAGES_ORDER_CODE = "orderStatusPackagesOrderCode",
  ORDER_STATUS_PROGRESS = "orderStatusProgress",
  ORDER_PRODUCTION_TIME = "orderProductionTime",
  ORDER_DELIVERY_TIME = "orderDeliveryTime",
}

export const orderStatusColumnsValues = [
  { value: "orderStatusDateTime", label: "Date Time" },
  { value: "orderStatusOrder", label: "Order" },
  { value: "orderStatusMachines", label: "Machines" },
  { value: "orderStatus", label: "Status" },
  { value: "orderStatusPackagesOrderCode", label: "Packages" },
  { value: "orderStatusProgress", label: "Progress" },
  { value: "orderProductionTime", label: "Production Time" },
  { value: "orderDeliveryTime", label: "Delivery Time" },
];

export const adaptOrderStatusData = (ordersData: OrderItem[]) => {
  return ordersData.map((order) => ({
    type: WidgetTypeEnum.ORDER_STATUS,

    icon: order?.status,

    orderStatusId: order?.id ?? "No Data",

    orderStatusDateTime: order?.production_datetime ?? null,

    orderStatusMachines: order?.process_step_trackers?.map((tracker) => {
      return {
        machineName:
          (tracker?.machine as unknown as MachineItem)?.name ?? "No Data",
        machineID:
          (tracker?.machine as unknown as MachineItem)?.id ?? "No Data",
      };
    }),

    orderStatusOrder: order?.order_code ?? "No Data",

    orderStatusPackagesOrderCode: order?.order_code ?? "No Data",

    orderStatusProgress:
      order?.calc_progress === null ? null : order?.calc_progress,

    orderStatus: order?.status,

    orderProductionTime: order?.production_datetime ?? "No Data",

    orderDeliveryTime: order?.wanted_delivery_datetime ?? "No Data",
  }));
};

export const orderStatusesColors = {
  DE: "!bg-green-100 !text-white",
  CO: "!bg-green-100 !text-white",
  AC: "!bg-yellow-100",
  PE: "!bg-red-100 !text-white",
  AP: "!bg-blue-100",
  PO: "!bg-gray-50",
};

export const colorOrderStatusWidgetButton = (record: WidgetTableData) => {
  return orderStatusesColors[
    record.orderStatus as keyof typeof orderStatusesColors
  ];
};

export const orderStatusWidgetStatuses = [
  { value: "AC", label: "Active" },
  { value: "AP", label: "Approved" },
  { value: "CO", label: "Completed" },
  { value: "DE", label: "Delivered" },
  { value: "PE", label: "Pending" },
  { value: "PO", label: "PO Order" },
];

export const orderStatusStatusFilter = (widget: Widget) => {
  const statusFilter = widget?.filter?.find(
    (widget) => widget?.filter_parameter === "order_status_status__in"
  );

  return (statusFilter?.filter_value as string[]) ?? undefined;
};

export const getIncludeCalcProgressParameter = (widget: Widget) => {
  const includeCalcProgress = (
    widget?.column_names as unknown as OrderStatusColumns[]
  )?.find((item) => item === "orderStatusProgress");

  return !!includeCalcProgress;
};

export const getOrderStatusMachines = (widget: Widget) => {
  const statusFilter = widget?.filter?.find(
    (widget) =>
      widget?.filter_parameter === "process_step_trackers__machine__in"
  );

  const transformedMachinesFilter = (statusFilter?.filter_value as string)
    ?.split(",")
    .map((machine) => Number(machine));

  return transformedMachinesFilter;
};

export const renderOrderStatusIcon = (status: string) => {
  if (status === "DE") return widgetsIcons.logisticGreen;
  if (status === "CO") return widgetsIcons.logisticGreen;
  if (status === "AC") return widgetsIcons.logisticYellow;
  if (status === "PE") return widgetsIcons.logisticRed;

  return undefined;
};
