import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CorrectAllPackagingParams,
  MaterialItem,
  MaterialStockItem,
  PackageReport,
  PackagingItem,
  useGenerateMaterialItemReportDataMutation,
  useGenerateMaterialItemReportMutation,
  useGeneratePackageReportDataMutation,
  useGeneratePackageReportMutation,
  useGetCorrectAllPackagingMutation,
} from "shared/api";
import {
  downloadJsonFile,
  getPackagingDataFromMateriaStockItem,
  handleRequestError,
  reloadPage,
} from "shared/helpers";

export const useTrackingListRequests = () => {
  const { t } = useTranslation();
  const [api] = notification.useNotification();

  const [generateMaterialItemReport] = useGenerateMaterialItemReportMutation();

  const [generatePackageReportData] = useGeneratePackageReportDataMutation();

  const [generateMaterialItemReportData] =
    useGenerateMaterialItemReportDataMutation();

  const [generatePackageReport] = useGeneratePackageReportMutation();

  const [getCorrectAllPackaging, { isLoading: isLoadingCorrectAllPackaging }] =
    useGetCorrectAllPackagingMutation();

  const createMaterialItemReport = async (
    record: MaterialItem | MaterialStockItem
  ) => {
    const id = (record as MaterialStockItem)?.material_stock_batch?.id;
    const materialItem = record as MaterialItem;
    try {
      const response = await generateMaterialItemReport({
        id: materialItem.id,
        dynamic_tracker_fields: materialItem.dynamic_tracker_fields,
        material_stock_batch: id,
        order_production_set_position:
          materialItem.order_production_set_position!,
        packaging: materialItem.packaging,
        production_number: materialItem.production_number!,
        quantity: materialItem.quantity,
        serial_number: materialItem.serial_number,
        status: materialItem.status,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      const description = handleRequestError(error, t("packaging.error"));
      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  const handleMaterialReports = async (materialItem: MaterialStockItem) => {
    try {
      const response = await generateMaterialItemReportData({
        id: materialItem.id!,
        dynamic_tracker_fields: materialItem?.dynamic_tracker_fields!,
        material_stock_batch: materialItem?.material_stock_batch?.id!,
        order_production_set_position:
          materialItem?.order_production_set_position! as number,
        packaging: (materialItem.packaging as PackagingItem)?.id!,
        production_number: materialItem?.production_number! as number,
        quantity: materialItem?.quantity!,
        serial_number: materialItem?.serial_number!,
        status: materialItem?.status!,
      }).unwrap();

      downloadJsonFile(response, t("orders.article-data"));

      api.success({
        message: t("packaging.success"),
        description: t("packaging.generated"),
      });
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  const handlePackageReports = async (materialItem: MaterialStockItem) => {
    const packageReportData =
      materialItem?.packaging as unknown as PackageReport;

    try {
      const response = await generatePackageReportData(
        packageReportData
      ).unwrap();

      downloadJsonFile(response, t("orders.package-data"));
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  const createPackageReport = async (record: MaterialStockItem) => {
    const packagingDataFromMaterial = getPackagingDataFromMateriaStockItem(
      record!
    );

    try {
      const response = await generatePackageReport(
        packagingDataFromMaterial
      ).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      const description = handleRequestError(error, t("packaging.error"));
      api.error({
        message: t("packaging.error"),
        description,
      });
    }
  };

  const handleCorrectAllPackaging = async (
    params: CorrectAllPackagingParams
  ) => {
    try {
      const response = await getCorrectAllPackaging({ id: params.id }).unwrap();

      if (response !== undefined && response !== null) {
        reloadPage();
      }

      api.success({
        message: t("packaging.success"),
      });
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  return {
    createMaterialItemReport,
    handleMaterialReports,
    handlePackageReports,
    createPackageReport,
    handleCorrectAllPackaging,
    loadingStates: {
      isLoadingCorrectAllPackaging,
    },
  };
};
