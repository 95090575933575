import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { QualityResultItem } from "shared/api";

interface TableMaterialItemsProps {
  record: QualityResultItem;
}

const TableMaterialItems: FC<TableMaterialItemsProps> = ({ record }) => {
  const { t } = useTranslation();

  return (
    <div className="w-hull h-full flex flex-wrap gap-x-2">
      {!record?.material_items?.length && t("quality.no-data")}

      {record.material_items.map((materialItem) => (
        <span key={materialItem?.id}>
          {materialItem?.material_item?.serial_number}
        </span>
      ))}
    </div>
  );
};

export { TableMaterialItems };
