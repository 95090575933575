import dayjs from "dayjs";
import React, { FC } from "react";

interface DateWithTimeRowProps {
  text: string;
  format?: "withSeconds" | "withoutSeconds";
}

const DateWithTimeRow: FC<DateWithTimeRowProps> = ({
  text,
  format = "withSeconds",
}) => {
  const timezone = localStorage.getItem("timezone");

  const dateFormat =
    format === "withSeconds" ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY HH:mm";

  return (
    <span>
      {dayjs(text)
        .tz(timezone ?? "UTC")
        .format(dateFormat)}
    </span>
  );
};

export { DateWithTimeRow };
