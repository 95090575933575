import { Alert, Button, Modal, Table, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { FormikProvider, useFormik } from "formik";
import { TableButton, TableSearchFilter } from "shared/ui";
import { Colors } from "shared/themes";
import {
  useConfirmDeleteModal,
  useDebouncedTableFilter,
  useModal,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import {
  handleRequestError,
  addThousandsSeparator,
  renderMaterialItemsStatuses,
  noop,
} from "shared/helpers";
import { adaptDynamicFormValues } from "shared/adapters";
import {
  MaterialStockItem,
  useDeleteStockMaterialItemMutation,
  useGetMaterialItemByIDQuery,
  useGetMaterialItemsByIDQuery,
} from "shared/api";
import { useParams } from "react-router-dom";
import {
  ModalSizeValue,
  sortDirections,
  MaterialItemsSorting,
  columnsMaterialItemsData,
  expandMaterialItemsStringWithUnit,
  materialItemInitialValues,
} from "shared/constants";
import {
  AddStockMaterialItemValues,
  DynamicValues,
  GlobalMaterialItem,
} from "shared/types";
import { AddMaterialItemModal } from "./AddMaterialItemModal";
import { EditStockItemModal } from "./EditStockItemModal";
import { createAddOrUpdateMaterialItemSchema } from "./schemas/schemas";

interface MaterialItemsModalProps {
  isOpenModal: boolean;
  hideMaterialItemsByIdModal: () => void;
  materialStockBatchID: number | null;
  selectedMaterialItemFromStock?: GlobalMaterialItem;
  renderAddButton: boolean;
  preselectedMaterialName?: string | null;
}

const MaterialItemsModal: FC<MaterialItemsModalProps> = ({
  isOpenModal,
  hideMaterialItemsByIdModal,
  materialStockBatchID,
  selectedMaterialItemFromStock,
  renderAddButton,
  preselectedMaterialName,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;
  const { ordering, changeSort } = useTableSorting();

  const [deleteStockMaterialItem] = useDeleteStockMaterialItemMutation();

  const {
    materialName,
    serialNumber,
    actions,
    calcPercentageRemainingQuantity,
    calcTotalConsumedQuantity,
    calcTotalOriginalQuantity,
    calcTotalRemainingQuantity,
    status,
  } = columnsMaterialItemsData;

  const [
    isOpenAddMaterialItemModal,
    showAddMaterialItemModal,
    hideAddMaterialModal,
  ] = useModal();

  const [
    isOpenEditMaterialItemModal,
    showEditMaterialItemModal,
    hideEditMaterialItemModal,
  ] = useModal();

  const {
    debouncedValue: debouncedStatusIm,
    handleFilterChange: handledStatusIm,
    value: statusInValue,
    clearFilterValue: clearStatusInValue,
  } = useDebouncedTableFilter("", 400);

  const idWithoutMaterialIdString = id?.replace(/-materialId/g, "");

  const { data: newTabMateriaItems } = useGetMaterialItemByIDQuery(
    {
      id: Number(idWithoutMaterialIdString),
      expand: expandMaterialItemsStringWithUnit,
    },
    {
      skip: !id,
    }
  );

  const newTabMaterialItemDynamicFields =
    newTabMateriaItems?.[0]?.material_stock_batch?.material?.material_category
      ?.config_dynamic_tracker_fields;

  const [dynamicValues, setDynamicValues] = useState<DynamicValues[]>([]);
  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  const addOrUpdateStockMaterialItemFormik =
    useFormik<AddStockMaterialItemValues>({
      initialValues: materialItemInitialValues,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: createAddOrUpdateMaterialItemSchema(
        dynamicFieldsValidation
      ),
      enableReinitialize: true,
      onSubmit: noop,
    });

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetMaterialItemsByIDQuery(
    {
      material_stock_batch: materialStockBatchID,
      expand: expandMaterialItemsStringWithUnit,
      limit,
      offset,
      ordering,
      status__in: debouncedStatusIm.toUpperCase(),
    },
    { skip: materialStockBatchID === null }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dynamicMaterialItemFields, setDynamicMaterialItemFields] =
    useState<null | MaterialStockItem>(null);

  useEffect(() => {
    const dynamicValues =
      selectedMaterialItemFromStock?.material?.material_category
        ?.config_dynamic_tracker_fields || newTabMaterialItemDynamicFields;

    setDynamicValues(dynamicValues);
  }, [materialStockBatchID, selectedMaterialItemFromStock, newTabMateriaItems]);

  const deleteStockMaterialItemHandler = async (record: MaterialStockItem) => {
    try {
      await deleteStockMaterialItem(record).unwrap();
      api.success({
        message: t("stock-material-item.success-title"),
        description: t("stock-material-item.success-delete-desc"),
      });
    } catch (error: any) {
      const description = handleRequestError(
        error,
        t("stock-material-item.error-title")
      );
      api.error({
        message: t("stock-material-item.error-title"),
        description,
      });
    }
  };

  const showDeleteCategoryModal = useConfirmDeleteModal(
    t("stock-material-item.delete-modal-msg"),
    deleteStockMaterialItemHandler
  );

  const editStockMaterialHandler = (record: MaterialStockItem) => {
    showEditMaterialItemModal();
    setDynamicMaterialItemFields(record);
    addOrUpdateStockMaterialItemFormik.setValues({
      id: record.id,
      dynamic_tracker_fields: record.dynamic_tracker_fields,
      material_stock_batch: record.material_stock_batch.id,
      quantity: record.quantity,
      serial_number: record.serial_number,
      status: record.status,
      situation: record.situation,
    });
  };

  const renderQuantityWithAbbreviation = (
    calc_total_original_quantity: number | null,
    calc_total_consumed_quantity: number | null,
    calc_total_remaining_quantity: number | null,
    selectedItemFromStock: GlobalMaterialItem
  ) => {
    let message;

    if (calc_total_original_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_original_quantity)} - ${
        typeof selectedItemFromStock?.material.material_category.unit ===
        "object"
          ? selectedItemFromStock.material.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_consumed_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_consumed_quantity)} - ${
        typeof selectedItemFromStock?.material.material_category.unit ===
        "object"
          ? selectedItemFromStock.material.material_category.unit.abbreviation
          : ""
      }`;
    }

    if (calc_total_remaining_quantity !== null) {
      message = `${addThousandsSeparator(calc_total_remaining_quantity)} - ${
        typeof selectedItemFromStock?.material.material_category.unit ===
        "object"
          ? selectedItemFromStock.material.material_category.unit.abbreviation
          : ""
      }`;
    }

    return message;
  };

  const columns: ColumnsType<MaterialStockItem> = [
    {
      title: t(materialName.title),
      render: (item: MaterialStockItem) => (
        <TableButton
          type="title"
          title={item?.material_stock_batch?.material?.name}
          onClick={() => editStockMaterialHandler(item)}
        />
      ),
    },
    {
      title: t(serialNumber.title),
      dataIndex: serialNumber.dataIndex,
      key: serialNumber.key,
    },
    {
      title: t(calcTotalOriginalQuantity.title),
      render: (item: GlobalMaterialItem) =>
        renderQuantityWithAbbreviation(
          item.calc_total_original_quantity!,
          null,
          null,
          selectedMaterialItemFromStock!
        ),
    },
    {
      title: t(calcTotalConsumedQuantity.title),
      render: (item: GlobalMaterialItem) =>
        renderQuantityWithAbbreviation(
          null,
          item.calc_total_consumed_quantity!,
          null,
          selectedMaterialItemFromStock!
        ),
    },
    {
      title: t(calcTotalRemainingQuantity.title),
      render: (item: GlobalMaterialItem) =>
        renderQuantityWithAbbreviation(
          null,
          null,
          item.calc_total_remaining_quantity!,
          selectedMaterialItemFromStock!
        ),
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
            MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: t(calcPercentageRemainingQuantity.title),
      render: (item: GlobalMaterialItem) =>
        `${item.calc_percentage_remaining_quantity}%`,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
            MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
          );
        },
      }),
      sorter: true,
    },
    {
      title: t(status.title),
      render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsSorting.STATUS_ASC,
            MaterialItemsSorting.STATUS_DESC
          );
        },
      }),
      sorter: true,
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearStatusInValue}
          confirm={confirm}
          value={statusInValue!}
          handleFilterChange={handledStatusIm!}
          visible={visible}
          placeholder={t("orders.filter-by-status-placeholder")!}
          title={t("orders.filter-by-status")!}
        />
      ),
    },
    {
      title: t(actions.title),
      key: actions.key,
      render: (record: MaterialStockItem) => {
        setDynamicMaterialItemFields(record);

        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => showDeleteCategoryModal(record)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateStockMaterialItemFormik}>
        <Modal
          title="Material Items"
          width={ModalSizeValue.LARGE}
          open={isOpenModal}
          onCancel={hideMaterialItemsByIdModal}
          okButtonProps={{
            style: {
              background: `${Colors.blue.primary}`,
              display: "none",
            },
          }}
          onOk={hideMaterialItemsByIdModal}
        >
          {renderAddButton && (
            <Button
              type="primary"
              className="btn-primary--dark "
              onClick={showAddMaterialItemModal}
            >
              {t("stock-material.add-item")}
              <PlusOutlined />
            </Button>
          )}

          {!materialItems?.results.length && (
            <Alert
              type="warning"
              showIcon
              message={t("stock-material.no-stock-items")}
              className="mb-5"
            />
          )}

          <Table
            loading={isLoading || isFetching}
            columns={columns}
            dataSource={materialItems?.results}
            rowKey={(obj) => obj.id!}
            pagination={{
              pageSize,
              showSizeChanger: true,
              current: currentPage,
              onShowSizeChange(page, pageSize) {
                setPageSize(pageSize);
                setCurrentPage(page);
              },
              onChange(page) {
                setCurrentPage(page);
              },
              total: materialItems?.count,
            }}
            className={!materialItems?.results.length ? "hidden" : "block"}
          />

          <AddMaterialItemModal
            isOpenModal={isOpenAddMaterialItemModal}
            hideAddMaterialModal={hideAddMaterialModal}
            materialStockBatchID={materialStockBatchID}
            selectedMaterialItemFromStock={selectedMaterialItemFromStock}
            dynamicValues={dynamicValues}
            preselectedMaterialName={preselectedMaterialName}
          />

          <EditStockItemModal
            isOpenModal={isOpenEditMaterialItemModal}
            hideEditMaterialItemModal={hideEditMaterialItemModal}
            materialStockBatchID={materialStockBatchID}
            selectedMaterialItemFromStock={selectedMaterialItemFromStock}
            dynamicValues={dynamicValues}
            preselectedMaterialName={preselectedMaterialName}
          />
        </Modal>
      </FormikProvider>
    </>
  );
};

const MaterialItemsModalMemo = React.memo(MaterialItemsModal);

export { MaterialItemsModalMemo as MaterialItemsModal };
