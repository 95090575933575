import { Button, Modal, Popover, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DynamicForm, FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  useUpdateMaterialCategoryMutation,
  UnitItemWithLabel,
} from "shared/api";
import { CloseOutlined } from "@ant-design/icons";
import {
  ModalSizeValue,
  lockMaterialCategoryValues,
  variantMaterialSelectValues,
} from "shared/constants";
import { AddOrEditMateriaCategoryValues } from "shared/types";

interface UpdateMaterialModalProps {
  isVisibleEditMaterialCategoryModal: boolean;
  hideEditMaterialCategoryModal: () => void;
  adaptedDimensionUnits: UnitItemWithLabel[];
}

const UpdateMaterialCategoryModal: FC<UpdateMaterialModalProps> = ({
  isVisibleEditMaterialCategoryModal,
  hideEditMaterialCategoryModal,
  adaptedDimensionUnits,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [updateMaterialCategory] = useUpdateMaterialCategoryMutation();

  const {
    values,
    resetForm,
    isValid,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
  } = useFormikContext<AddOrEditMateriaCategoryValues>();

  const closeModalAndResetForm = () => {
    hideEditMaterialCategoryModal();
    resetForm();
  };

  const submitMaterialCategoryEdit = async () => {
    try {
      await updateMaterialCategory({
        name: values.name!,
        unit: values.unit!,
        id: values.id,
        config_dynamic_tracker_fields: values.sections || [],
        unique_code: values.unique_code!,
        lock_material_consume_variant: values?.lock_material_consume_variant!,
        default_material_consume_variant:
          values?.default_material_consume_variant!,
        export_col_for_quantity: values.export_col_for_quantity
          ? values.export_col_for_quantity
          : null,
        export_col_for_serial_number: values.export_col_for_serial_number
          ? values.export_col_for_serial_number
          : null,
        // We don't set supplier in this modal. But it's required in the API
        suppliers: [],
      }).unwrap();
      api.success({
        message: t("material-category-management.success-title"),
        description: t("material-category-management.success-update-desc"),
      });
      closeModalAndResetForm();
    } catch (error: any) {
      const description = handleRequestError(
        error,
        t("material-category-management.error-title")
      );

      api.error({
        message: t("material-category-management.error-title"),
        description,
      });
    }
  };

  const clearDefaultMaterialConsumeVariant = () => {
    setFieldValue("default_material_consume_variant", null);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("material-category-management.edit-modal-name")}
        width={
          values?.sections?.length
            ? ModalSizeValue.LARGE
            : ModalSizeValue.SECONDARY
        }
        open={isVisibleEditMaterialCategoryModal}
        okButtonProps={{
          disabled: !isValid,
          style: { background: `${Colors.blue.primary}` },
        }}
        onCancel={closeModalAndResetForm}
        onOk={() => {
          submitMaterialCategoryEdit();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("material-category-management.add-modal-title")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder="Enter material name"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("material-category-management.unique-code")}
        </Title>

        <FormInput
          value={values.unique_code}
          fieldName="unique_code"
          placeholder={t(
            "material-category-management.unique-code-placeholder"
          )}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.unique_code}
          isError={!!errors.unique_code}
        />

        <Title level={5} className="mb-1.25">
          {t("material-category-management.export-col-for-serial-number")}
        </Title>

        <FormInput
          value={values.export_col_for_serial_number}
          fieldName="export_col_for_serial_number"
          placeholder={t(
            "material-category-management.export-col-for-serial-number-placeholder"
          )}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.export_col_for_serial_number}
          isError={!!errors.export_col_for_serial_number}
          numberType
        />

        <Title level={5} className="mb-1.25">
          {t("material-category-management.export-col-for-quantity")}
        </Title>

        <FormInput
          value={values.export_col_for_quantity}
          fieldName="export_col_for_quantity"
          placeholder={t(
            "material-category-management.export-col-for-quantity-placeholder"
          )}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.export_col_for_quantity}
          isError={!!errors.export_col_for_quantity}
          numberType
        />

        <div className="w-full flex items-center justify-between">
          <Title level={5} className="mb-1.25">
            {t("material-category-management.default-material-consume-variant")}
          </Title>

          <Popover content={t("material-category-management.clear-values")}>
            <Button
              className="w-6 h-6 rounded-full p-0 flex items-center justify-center mb-2"
              onClick={clearDefaultMaterialConsumeVariant}
            >
              <CloseOutlined />
            </Button>
          </Popover>
        </div>

        <FormSelect
          value={values?.default_material_consume_variant}
          placeholder={t(
            "material-category-management.default-material-consume-variant"
          )}
          fieldName="default_material_consume_variant"
          options={variantMaterialSelectValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors?.default_material_consume_variant}
          isError={!!errors.default_material_consume_variant}
        />

        <Title level={5} className="mb-1.25">
          {t("material-category-management.lock-material-consume-variant")}
        </Title>

        <FormSelect
          value={values?.lock_material_consume_variant}
          placeholder={t(
            "material-category-management.lock-material-consume-variant"
          )}
          fieldName="lock_material_consume_variant"
          options={lockMaterialCategoryValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors?.lock_material_consume_variant}
          isError={!!errors.lock_material_consume_variant}
        />

        <Title level={5} className="mb-1.25">
          {t("material-category-management.add-modal-unit")}
        </Title>

        <FormSelect
          value={values.unit}
          placeholder={t("material-category-management.select-dim-unit")}
          fieldName="unit"
          withLabels
          optionsWithLabels={adaptedDimensionUnits}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.unit}
          isError={!!errors.unit}
        />

        <DynamicForm adaptedDimensionUnits={adaptedDimensionUnits} />
      </Modal>
    </>
  );
};

export { UpdateMaterialCategoryModal };
