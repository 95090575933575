import React, { FC } from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { OutputMaterialsEditModePagination } from "./OutputMaterialsEditModePagination";

interface OutputMaterialEditModeFooterProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  handleCurrentPage: (page: number) => void;
  submitEditing: () => void;
}

const OutputMaterialEditModeFooter: FC<OutputMaterialEditModeFooterProps> = ({
  currentPage,
  totalPages,
  handleCurrentPage,
  handlePageChange,
  submitEditing,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  return (
    <div className="w-full flex flex-col items-center justify-between mt-2">
      <OutputMaterialsEditModePagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleCurrentPage={handleCurrentPage}
      />

      <Button
        type="primary"
        className="
            bg-green-300 h-10 text-[18px] mt-2
            flex items-center justify-between 
            hover:brightness-125 hover:bg-green-300
        "
        icon={<CheckOutlined />}
        onClick={submitEditing}
      >
        {t("submitEditing")}
      </Button>
    </div>
  );
};

export { OutputMaterialEditModeFooter };
