import { Modal } from "antd";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { SupplierItemWithCost } from "shared/api";
import { FormInput, FormSelect, TableButton } from "shared/ui";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { ModalSizeValue, currencies } from "shared/constants";
import { Colors } from "shared/themes";
import { getCostPerValues } from "shared/helpers";
import { SubmitCreateMaterialValues } from "shared/types";

interface ModalToUpdateSupplierProps {
  isOpenModal: boolean;
  clickedSupplier: SupplierItemWithCost | null;
  supplierIndexToChange: number;
  close: () => void;
}

const ModalToUpdateSupplier: FC<ModalToUpdateSupplierProps> = ({
  isOpenModal,
  clickedSupplier,
  supplierIndexToChange,
  close,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue, handleChange } =
    useFormikContext<SubmitCreateMaterialValues>();

  const [supplierBeforeUpdate, setSupplierBeforeUpdate] =
    useState<SupplierItemWithCost | null>(null);

  const closeModalAddSetPreviousSupplierValue = () => {
    setFieldValue(`suppliers.${supplierIndexToChange}`, supplierBeforeUpdate);
    close();
  };

  useEffect(() => {
    if (clickedSupplier) {
      setSupplierBeforeUpdate(clickedSupplier);
    }
  }, [clickedSupplier]);

  const {
    costPerUnitCurrencyValue,
    costPerUnitCurrencyFieldName,
    costPerUnitValie,
    costPerUnitFieldName,
  } = getCostPerValues(values, supplierIndexToChange);

  const clearCostPerUnitCurrency = () => {
    setFieldValue(costPerUnitCurrencyFieldName, null);
  };

  const clearCostPerUnit = () => {
    setFieldValue(costPerUnitFieldName, null);
  };
  return (
    <Modal
      title={t("material-management.change-supplier")}
      open={isOpenModal}
      onOk={close}
      onCancel={closeModalAddSetPreviousSupplierValue}
      width={ModalSizeValue.MEDIUM}
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
        },
      }}
    >
      <div className="w-full flex items-center justify-between gap-x-3">
        <FormSelect
          value={costPerUnitCurrencyValue}
          placeholder={t("material-management.cost-per-unit-currency")}
          fieldName={costPerUnitCurrencyFieldName}
          options={currencies}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />

        <TableButton type="delete" onClick={clearCostPerUnitCurrency} />
      </div>

      <Title level={5}>{t("material-management.cost-per-unit")}</Title>

      <div className="w-full flex items-center justify-between gap-x-3">
        <FormInput
          value={costPerUnitValie}
          placeholder={t("material-management.cost-per-unit")}
          fieldName={costPerUnitFieldName}
          handleBlur={handleBlur}
          handleChange={handleChange}
          numberType
        />

        <TableButton type="delete" onClick={clearCostPerUnit} />
      </div>
    </Modal>
  );
};

export { ModalToUpdateSupplier };
