import {
  ExpandedOrderProcessStepTracker,
  MaterialStockItem,
  Widget,
  WidgetTableData,
  WidgetTypeEnum,
} from "shared/api";
import { renderMaterialItemsStatuses } from "shared/helpers/materialStockItem";
import { widgetsIcons } from "shared/assets";
import { noWidgetFieldData } from "../widgets";

export type ArticleOrderProcessStepTracker = {
  machine: {
    id: number;
    name: string;
    process_template: number;
  };
};

export const enum ArticleFlowFields {
  WIDGET_TYPE = "ITEM_FLOW",
  ARTICLE_IMAGE = "articleImage",
  ARTICLE_DATE_TIME = "articleDateTime",
  ARTICLE_MACHINE = "articleMachine",
  ARTICLE_ARTICLE = "articleArticle",
  ARTICLE_STATUS = "articleStatus",
  ARTICLE_ORDER_ID = "articleOrderId",
}

export const articleFlowColumnsValues = [
  { value: "articleImage", label: "Icon" },
  { value: "articleDateTime", label: "Date Time" },
  { value: "articleMachine", label: "Machine" },
  { value: "articleArticle", label: "Article" },
  { value: "articleStatus", label: "Status" },
];

export const adaptArticleFlowData = (data?: MaterialStockItem[]) => {
  return data?.map((item) => {
    return {
      type: WidgetTypeEnum.ITEM_FLOW,
      articleImage: renderMaterialItemsStatuses(item) || noWidgetFieldData,
      articleDateTime: item?.timestamp || noWidgetFieldData,
      articleMachine:
        (
          item?.order_production_set
            ?.order_process_step_tracker as unknown as ArticleOrderProcessStepTracker
        )?.machine || noWidgetFieldData,

      articleArticle:
        {
          id: item?.id,
          serial_number: item?.serial_number,
        } || noWidgetFieldData,
      articleStatus: renderMaterialItemsStatuses(item) || noWidgetFieldData,

      articleOrderId: (
        item?.order_production_set
          ?.order_process_step_tracker as ExpandedOrderProcessStepTracker
      )?.order,
    };
  });
};

export const articleFlowIcons = {
  Prima: widgetsIcons.logisticGreen,
  Waste: widgetsIcons.logisticRed,
  Investigation: widgetsIcons.logisticYellow,
};

export const renderArticleFlowIcon = (record: WidgetTableData) => {
  return articleFlowIcons[record.articleImage as keyof typeof articleFlowIcons];
};

export const articleStatusColors = {
  Prima: "!bg-green-100 !text-white",
  Waste: "!bg-red-100 !text-white",
  Investigation: "!bg-yellow-100",
};

export const colorStatusButton = (record: WidgetTableData) => {
  return articleStatusColors[
    record.articleStatus as keyof typeof articleStatusColors
  ];
};

export const articleFlowMaterialFilter = (widget: Widget) => {
  const materialFilter = widget?.filter?.find(
    (widget) =>
      widget?.filter_parameter === "material_stock_batch__material__in" ||
      widget?.filter_parameter ===
        "production_tracking_material_stock_batch__material__in"
  );

  return (materialFilter?.filter_value as number[]) ?? undefined;
};

export const articleFlowStatusFilter = (widget: Widget) => {
  const statusFilter = widget?.filter?.find(
    (widget) =>
      widget?.filter_parameter === "status__in" ||
      widget?.filter_parameter === "production_tracking_status__in"
  );

  return (statusFilter?.filter_value as string[]) ?? undefined;
};
