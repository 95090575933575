import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import {
  FormSelect,
  GlobalDatePicker,
  FormInput,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  MaterialConsumedItem,
  MaterialsItem,
  useUpdateMaterialConsumedMutation,
} from "shared/api";
import {
  ModalSizeValue,
  ConsumedSelectFieldVariant,
  ConsumedStatusesDropDown,
  SelectFieldValues,
} from "shared/constants";

import { AddMaterialConsumedValues } from "shared/types";
import { FilteredStockBatchTable } from "./FilteredStockBatchTable";
import { FilteredMaterialItemsTable } from "./FilteredMaterialItemsTable";

export interface EditConsumedModalProps {
  isOpenModal: boolean;
  selectedMaterial: MaterialsItem | null;
  setOpenEditConsumedModal: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicItemFromTable: MaterialConsumedItem | null;
}

const EditConsumedModal: FC<EditConsumedModalProps> = ({
  isOpenModal,
  selectedMaterial,
  setOpenEditConsumedModal,
  dynamicItemFromTable,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    resetForm,
    isValid,
  } = useFormikContext<AddMaterialConsumedValues>();

  const handleConsumedDateTimeChange = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("consumed_datetime", dateString);
  };

  const [updateMaterialConsumed] = useUpdateMaterialConsumedMutation();

  const updateMaterialConsumedItemRequest = async () => {
    try {
      await updateMaterialConsumed({
        id: values.id,
        material: values.material,
        quantity: values.quantity!,
        consumed_datetime: values.consumed_datetime!,
        material_item: values.material_item,
        material_stock_batch: values.material_stock_batch,
        order_process_step_tracker: values.order_process_step_tracker,
        reason: values.reason!,
        status: values.status!,
      }).unwrap();
      api.success({
        message: t("material-consumed.success"),
        description: t("material-consumed.success-msg"),
      });
      setOpenEditConsumedModal(false);
      resetForm();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-consumed.error")
      );

      api.error({
        message: t("material-consumed.error"),
        description,
      });
    }
  };

  const changeModalWidth = () => {
    if (values.fieldVariant === "material") {
      return ModalSizeValue.PRIMARY;
    }

    if (
      values.fieldVariant === "material_item" ||
      values.fieldVariant === "material_stock_batch"
    ) {
      return ModalSizeValue.LARGE;
    }

    return ModalSizeValue.PRIMARY;
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Edit consumed"
        open={isOpenModal}
        width={changeModalWidth()}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={() => {
          setOpenEditConsumedModal(false);
          resetForm();
        }}
        onOk={() => {
          updateMaterialConsumedItemRequest();
        }}
      >
        <Title level={5}> {t("material-consumed.date-time")}</Title>

        <GlobalDatePicker
          value={dayjs.utc(values.consumed_datetime)}
          handlePickerChange={handleConsumedDateTimeChange}
          error={errors.consumed_datetime}
          isError={!!errors.consumed_datetime}
        />

        <Title level={5} className="mb-1.25">
          {t("material-consumed.quantity")}
        </Title>

        <FormInput
          value={values.quantity}
          fieldName="quantity"
          placeholder={t("material-consumed.quantity-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.quantity}
          isError={!!errors.quantity}
          numberType
        />

        <Title level={5}> {t("material-consumed.field-variant")}</Title>

        <FormSelect
          value={values.fieldVariant}
          placeholder={t("material-consumed.variant-placeholder")}
          fieldName="fieldVariant"
          options={ConsumedSelectFieldVariant}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.fieldVariant?.toString()}
          isError={!!errors.fieldVariant}
        />

        {values.fieldVariant === SelectFieldValues.MATERIAL && (
          <>
            <Title level={5}> {t("material-consumed.material")}</Title>

            <FormInput
              value={selectedMaterial?.name}
              fieldName="material"
              placeholder={t("material-consumed.material-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.material}
              isError={!!errors.material}
              disabled
            />
          </>
        )}

        {values.fieldVariant === SelectFieldValues.MATERIAL_STOCK_BATCH && (
          <FilteredStockBatchTable
            selectedMaterial={selectedMaterial}
            edit
            dynamicItemFromTable={dynamicItemFromTable}
          />
        )}

        {values.fieldVariant === SelectFieldValues.MATERIAL_ITEM && (
          <FilteredMaterialItemsTable
            selectedMaterial={selectedMaterial}
            edit
            dynamicItemFromTable={dynamicItemFromTable}
          />
        )}

        <Title level={5} className="mb-1.25">
          {t("material-consumed.reason")}
        </Title>

        <FormInput
          value={values.reason}
          fieldName="reason"
          placeholder={t("material-consumed.reason-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Title level={5}> {t("material-consumed.status")}</Title>

        <FormSelect
          value={values.status}
          placeholder={t("material-consumed.status-placeholder")}
          fieldName="status"
          options={ConsumedStatusesDropDown}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status?.toString()}
          isError={!!errors.status}
        />
      </Modal>
    </>
  );
};

export { EditConsumedModal };
