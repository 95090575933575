import { useState } from "react";

type SelectedKeys = () => [React.Key[], (keys: React.Key[]) => void];

export const useTableKeys: SelectedKeys = () => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const handleKeys = (keys: React.Key[]) => setSelectedKeys(keys);

  return [selectedKeys, handleKeys];
};
