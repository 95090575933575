import { useFormikContext } from "formik";
import React, { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { FormInput } from "shared/ui";
import { getConsumptionType, renderDynamicQuantity } from "shared/helpers";
import { ProductionInputMaterial } from "shared/api";
import { useTranslation } from "react-i18next";
import { AddDynamicOrderSteps } from "shared/types";

interface DynamicQuantityDropDownProps {
  inputIndex: number;
  fieldIndex: number;
  inputMaterial: ProductionInputMaterial;
  removeItem: (fieldIndex: number) => void;
}

const DynamicQuantityDropDown: FC<DynamicQuantityDropDownProps> = ({
  inputIndex,
  fieldIndex,
  inputMaterial,
  removeItem,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, handleChange } =
    useFormikContext<AddDynamicOrderSteps>();

  const { isFixed, isProportional } = getConsumptionType(
    inputMaterial.consumptionType
  );

  const quantityValue =
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex]?.quantity;

  const quantityFieldName = `input_materials.${inputIndex}.fields.${fieldIndex}.quantity`;

  const disableInput = isFixed || isProportional;

  return (
    <>
      <FormInput
        value={
          renderDynamicQuantity(
            inputMaterial,
            inputMaterial?.consumptionType,
            quantityValue
          )!
        }
        fieldName={quantityFieldName}
        placeholder={t("orders.enter-quantity")}
        handleChange={handleChange}
        handleBlur={handleBlur}
        numberType
        disabled={disableInput}
      />
      <div className="w-full flex items-center justify-center">
        {inputMaterial?.fields.length > 1 && (
          <DeleteOutlined
            onClick={() => removeItem(fieldIndex)}
            className="btn-remove"
          />
        )}
      </div>
    </>
  );
};

export { DynamicQuantityDropDown };
