import { useState } from "react";
import { MaterialsItem, MaterialStockItem } from "shared/api";

const useMaterialManagementSteps = () => {
  const [materialManagementPage, setMaterialManagementPage] = useState(1);

  const isMaterialManagementTable = materialManagementPage === 1;
  const isStockHistory = materialManagementPage === 2;
  const isMaterialConsumed = materialManagementPage === 3;
  const isMaterialReservation = materialManagementPage === 4;
  const isStockManagement = materialManagementPage === 5;
  const isMaterialItems = materialManagementPage === 6;

  const [materialItem, setMaterialItem] = useState<MaterialsItem | null>(null);
  const [
    materialStockBatchForMaterialItems,
    setMaterialStockBatchForMaterialItems,
  ] = useState<MaterialStockItem | null>(null);

  const resetMaterialManagementPage = () => {
    setMaterialManagementPage(1);
    setMaterialItem(null);
  };

  const handelMaterialStockHistoryDetails = (item: MaterialsItem) => {
    setMaterialItem(item);
    setMaterialManagementPage(2);
  };

  const handelMaterialConsumedDetails = (item: MaterialsItem) => {
    setMaterialItem(item);
    setMaterialManagementPage(3);
  };

  const handleMaterialReservationDetails = (item: MaterialsItem) => {
    setMaterialItem(item);
    setMaterialManagementPage(4);
  };

  const handleStockManagementDetails = (item: MaterialsItem) => {
    setMaterialItem(item);
    setMaterialManagementPage(5);
  };

  const handleMaterialItemsDetails = (
    materialStockBatchMaterialId: MaterialStockItem
  ) => {
    setMaterialStockBatchForMaterialItems(materialStockBatchMaterialId);
    setMaterialManagementPage(6);
  };

  const goToStockManagementFromMaterialItems = () => {
    setMaterialManagementPage(5);
  };

  return {
    isMaterialManagementTable,
    isStockHistory,
    isMaterialConsumed,
    materialItem,
    handelMaterialStockHistoryDetails,
    resetMaterialManagementPage,
    handelMaterialConsumedDetails,
    isMaterialReservation,
    handleMaterialReservationDetails,
    isStockManagement,
    handleStockManagementDetails,
    isMaterialItems,
    handleMaterialItemsDetails,
    materialStockBatchForMaterialItems,
    goToStockManagementFromMaterialItems,
  };
};

export { useMaterialManagementSteps };
