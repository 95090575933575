import React, { FC } from "react";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  categorySectionHeader,
  headerButtonContainer,
  headerTitleContainer,
} from "shared/ui/DynamicForm/styles";
import {
  AddCompanyValues,
  AddProcessTemplatesValues,
  AddOrEditMateriaCategoryValues,
} from "shared/types";

interface CardHeaderProps {
  index: number;
  onClick: () => void;
  remove: (index: number) => void | undefined;
}

const CardHeader: FC<CardHeaderProps> = ({ index, onClick, remove }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<
    | AddOrEditMateriaCategoryValues
    | AddCompanyValues
    | AddProcessTemplatesValues
  >();

  return (
    <div style={categorySectionHeader}>
      <div style={headerTitleContainer}>
        <Title level={5} className="mb-1.25">
          {values.sections[index].title}
        </Title>
      </div>

      <div style={headerButtonContainer}>
        <Button
          type="primary"
          className="btn-primary--dark mb-2.5"
          onClick={onClick}
        >
          {t("material-category-management.add-new-section")}
        </Button>

        <Button type="primary" danger onClick={() => remove(index)}>
          {t("material-category-management.delete-section")}
        </Button>
      </div>
    </div>
  );
};

export { CardHeader };
