import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import {
  Dashboard,
  DashboardModes,
  UpdateWidgets,
  Widget,
  useUpdateDashboardMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { StrictModeDroppable, usePollingSeconds } from "shared/hooks";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { WidgetLayoutItem } from "./WidgetLayoutItem";

const getWidgets = (widgets: Widget[], column: number): Widget[] =>
  widgets.filter((widget) => widget.column === column);

const reorderWidgets = (
  list: Widget[],
  startIndex: number,
  endIndex: number
): Widget[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const moveWidgets = (
  source: Widget[],
  destination: Widget[],
  droppableSource: { droppableId: string; index: number },
  droppableDestination: { droppableId: string; index: number }
): Record<string, Widget[]> => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, Widget[]> = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties | undefined
): React.CSSProperties => ({
  userSelect: "none",
  padding: grid * 2,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginBottom: "12px",
  background: isDragging ? Colors.blue[100] : Colors.blue.primary,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? Colors.blue.primary : Colors.gray[100],
  padding: grid,
  width: "100%",
});

const WidgetsLayout = ({
  dashboardsData,
  sortedWidgetsWithArticle,
}: {
  dashboardsData?: Dashboard;
  sortedWidgetsWithArticle: Dashboard;
}) => {
  const { t } = useTranslation();

  const { pollingData } = usePollingSeconds();

  const [updateDashboard] = useUpdateDashboardMutation();

  const isSingleColumn = useMemo(() => {
    return (
      dashboardsData?.configuration?.[0]?.configuration_value ===
      DashboardModes.ONE_COLUMN
    );
  }, [dashboardsData?.configuration?.[0]?.configuration_value]);

  const removeWidget = async (widgetId: string) => {
    const filteredWidgets = dashboardsData?.widgets?.filter(
      (widget) => widget.uuid !== widgetId
    );

    await updateDashboard({
      id: dashboardsData?.id!,
      name: dashboardsData?.name!,
      widgets: filteredWidgets as UpdateWidgets[],
      configuration: dashboardsData?.configuration ?? [],
    });
  };

  const [dndWidgets, setDnDWidgets] = useState<Widget[][]>([[], []]);

  useEffect(() => {
    if (
      sortedWidgetsWithArticle &&
      sortedWidgetsWithArticle.widgets &&
      sortedWidgetsWithArticle.widgets.length > 0
    ) {
      if (isSingleColumn) {
        setDnDWidgets([sortedWidgetsWithArticle.widgets]);
      } else {
        setDnDWidgets([
          getWidgets(sortedWidgetsWithArticle.widgets, 1),
          getWidgets(sortedWidgetsWithArticle.widgets, 2),
        ]);
      }
    } else {
      setDnDWidgets([[], []]);
    }
  }, [sortedWidgetsWithArticle, isSingleColumn]);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const sourceInd = +source.droppableId;
      const destinationInd = +destination.droppableId;

      let items;
      const newState = [...dndWidgets];

      if (sourceInd === destinationInd) {
        items = reorderWidgets(
          dndWidgets[sourceInd],
          source.index,
          destination.index
        );
        newState[sourceInd] = items;
      } else {
        const result = moveWidgets(
          dndWidgets[sourceInd],
          dndWidgets[destinationInd],
          source,
          destination
        );
        newState[sourceInd] = result[sourceInd];
        newState[destinationInd] = result[destinationInd];
      }

      const updatedDashboardsData = newState.map((column, index) =>
        column.map((widget, idx) => ({
          ...widget,
          column: isSingleColumn ? 1 : index + 1,
          row: idx + 1,
        }))
      ) as UpdateWidgets[][];

      await updateDashboard({
        id: dashboardsData?.id!,
        name: dashboardsData?.name!,
        widgets: updatedDashboardsData.flat() as UpdateWidgets[],
        configuration: dashboardsData?.configuration ?? [],
      });

      setDnDWidgets(newState);
    },
    [dndWidgets, updateDashboard, dashboardsData, isSingleColumn]
  );

  if (!dashboardsData?.widgets?.length) {
    return (
      <div className="p-2">
        <Alert type="info" showIcon message={t("widgets.no-widgets")} />
      </div>
    );
  }

  return (
    <div className="p-2">
      <Title level={4}>
        {t("widgets.widgetsRefetchMessage", {
          pollingTime: pollingData.nextRefreshTime,
        })}
      </Title>

      <div className="w-full flex gap-x-5">
        <DragDropContext onDragEnd={onDragEnd}>
          {isSingleColumn ? (
            <StrictModeDroppable key={0} droppableId="0">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {dndWidgets[0].map((widget, index) => (
                    <Draggable
                      key={widget.uuid}
                      draggableId={widget?.uuid!}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <WidgetLayoutItem
                            widget={widget}
                            onClick={() => removeWidget(widget?.uuid!)}
                            pollingData={pollingData}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          ) : (
            dndWidgets.map((column, ind) => (
              <StrictModeDroppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {column.map((widget, index) => (
                      <Draggable
                        key={widget.uuid}
                        draggableId={widget?.uuid!}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <WidgetLayoutItem
                              widget={widget}
                              onClick={() => removeWidget(widget?.uuid!)}
                              pollingData={pollingData}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            ))
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

const WidgetsLayoutMemo = React.memo(WidgetsLayout);

export { WidgetsLayoutMemo as WidgetsLayout };
