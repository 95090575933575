import { useFormikContext } from "formik";
import React, { FC } from "react";
import { FormInput, FormSelect } from "shared/ui";
import { AdaptedValues, AddProcessStepValues } from "shared/types";
import { filterProportionalMaterials } from "shared/helpers";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { getInputMaterialConsumptionValues } from "shared/adapters";
import { ConsumptionTypesValues } from "shared/constants";

interface InputMaterialConsumptionProps {
  index: number;
  adaptedMaterials: AdaptedValues[];
}

const InputMaterialConsumption: FC<InputMaterialConsumptionProps> = ({
  index,
  adaptedMaterials,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue, handleChange } =
    useFormikContext<AddProcessStepValues>();

  const {
    materialConsumptionTypeValue,
    isFixedValue,
    isProportionalValue,
    consumptionFixedValue,
    consumptionProportionalValue,
    consumptionProportionalMaterial,
    materialConsumptionTypeFieldName,
    consumptionFixedValueFieldName,
    consumptionProportionalFieldName,
    consumptionProportionalMaterialFieldName,
  } = getInputMaterialConsumptionValues(values, index);

  const filteredProportionalMaterials = filterProportionalMaterials(
    values?.output_materials,
    adaptedMaterials
  );

  return (
    <>
      <div className="flex flex-col w-full">
        <Title level={5}>{t("process-step.consumption-type")}</Title>

        <FormSelect
          value={materialConsumptionTypeValue}
          placeholder={t("process-step.consumption-type")}
          fieldName={materialConsumptionTypeFieldName}
          options={ConsumptionTypesValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      </div>

      {isFixedValue && (
        <div className="flex flex-col  w-full ">
          <Title level={5}>{t("process-step.fixed-value")}</Title>
          <FormInput
            value={consumptionFixedValue}
            fieldName={consumptionFixedValueFieldName}
            placeholder={t("process-step.fixed-value")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            numberType
          />
        </div>
      )}

      {isProportionalValue && (
        <>
          <div className="flex flex-col  w-full">
            <Title level={5}>{t("process-step.proportional-value")}</Title>
            <FormInput
              value={consumptionProportionalValue!}
              fieldName={consumptionProportionalFieldName}
              placeholder={t("process-step.proportional-value")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              numberType
            />
          </div>

          <div className="flex flex-col  w-full ">
            <Title level={5}>{t("process-step.proportional-material")}</Title>
            <FormSelect
              value={consumptionProportionalMaterial}
              fieldName={consumptionProportionalMaterialFieldName}
              placeholder={t("process-step.proportional-material")}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              options={filteredProportionalMaterials}
            />
          </div>
        </>
      )}
    </>
  );
};

export { InputMaterialConsumption };
