import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { AlertOutlined } from "@ant-design/icons";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  AdaptedValues,
  AddProcessStepValues,
  ProcessStepInputMaterial,
  ProcessStepOutputMaterial,
} from "shared/types";
import { ModalSizeValue } from "shared/constants";
import {
  adaptDynamicProcessStepFields,
  adaptProcessStepInputMaterials,
} from "shared/adapters";
import { ProcessTemplateItem } from "../../../../shared/api/productCard/models";
import { useCreateProcessStepMutation } from "../../../../shared/api/productCard/productCardApi";
import { ProcessStepDynamicFields } from "./ProcessStepDynamicFields";
import { InputMaterialsDynamicForm } from "./InputMaterialsDynamicForm";
import { OutputMaterialsDynamicForm } from "./OutputMaterialsDynamicForm";

interface AddProcessStepModalProps {
  isOpenModal: boolean;
  hideAddProcessStepModal: () => void;
  adaptedProcessTemplates: AdaptedValues[];
  adaptedMaterials: AdaptedValues[];
  dynamicProcessTemplateFields?: ProcessTemplateItem;
  isLoadingMaterials: boolean;
}

const AddProcessStepModal: FC<AddProcessStepModalProps> = ({
  isOpenModal,
  hideAddProcessStepModal,
  adaptedProcessTemplates,
  adaptedMaterials,
  dynamicProcessTemplateFields,
  isLoadingMaterials,
}) => {
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddProcessStepValues>();

  const closeModal = () => {
    hideAddProcessStepModal();
    resetForm();
  };

  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [createProcessStep] = useCreateProcessStepMutation();

  const createProcessStepRequest = async () => {
    try {
      await createProcessStep({
        name: values.name!,
        dynamic_process_step_fields: adaptDynamicProcessStepFields(
          values.dynamic_process_step_fields
        ),
        input_materials: adaptProcessStepInputMaterials(
          values?.input_materials as ProcessStepInputMaterial[]
        ),
        output_materials:
          values?.output_materials as ProcessStepOutputMaterial[],
        process_template: values.process_template,
      }).unwrap();
      api.success({
        message: t("process-step.success-title"),
        description: t("process-step.success-add-msg"),
      });
      closeModal();
    } catch (error: any) {
      const description = handleRequestError(error, t("process-step.error"));

      api.error({
        message: t("process-step.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("process-step.add-process-step")}
        open={isOpenModal}
        width={ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          createProcessStepRequest();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("process-step.process-step-name")}
        </Title>

        <div className="relative">
          <FormInput
            value={values.name}
            fieldName="name"
            placeholder={t("process-step.name-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.name}
            isError={!!errors.name}
          />
          {!values?.name?.length && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}
        </div>

        <Title level={5}> {t("process-step.process-template")}</Title>

        <FormSelect
          value={values.process_template}
          placeholder={t("process-step.process-template")}
          fieldName="process_template"
          options={adaptedProcessTemplates}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.process_template?.toString()}
          isError={!!errors.process_template}
          showSearch
        />

        {dynamicProcessTemplateFields?.config_dynamic_process_step_fields
          .length ? (
          <ProcessStepDynamicFields
            dynamicProcessTemplateFields={dynamicProcessTemplateFields}
          />
        ) : null}

        <InputMaterialsDynamicForm
          adaptedMaterials={adaptedMaterials}
          isLoadingMaterials={isLoadingMaterials}
        />

        <OutputMaterialsDynamicForm
          adaptedMaterials={adaptedMaterials}
          isLoadingMaterials={isLoadingMaterials}
        />
      </Modal>
    </>
  );
};

export { AddProcessStepModal };
