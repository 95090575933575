import { useMemo, useState } from "react";
import { adaptMaterialCategories } from "shared/adapters";
import { useGetMaterialCategoriesWithNoParamsQuery } from "shared/api";

const useItemViewMaterialCategoryData = () => {
  const { data: materialCategories, isLoading: isLoadingMaterialCategories } =
    useGetMaterialCategoriesWithNoParamsQuery();

  const adaptedMaterialCategories = useMemo(() => {
    return adaptMaterialCategories(materialCategories ?? []);
  }, [materialCategories]);

  const [selectedMaterialCategoryId, setSelectedMaterialCategoryId] = useState<
    number | null
  >(null);

  const handleSelectMaterialCategory = (materialCategoryId: number | null) => {
    setSelectedMaterialCategoryId(materialCategoryId);
  };

  const selectedMaterialCategoryItem = useMemo(() => {
    return materialCategories?.find(
      (materialCategory) => materialCategory.id === selectedMaterialCategoryId
    );
  }, [selectedMaterialCategoryId]);

  return {
    isLoadingMaterialCategories,
    adaptedMaterialCategories,
    handleSelectMaterialCategory,
    selectedMaterialCategoryItem,
  };
};

export { useItemViewMaterialCategoryData };
