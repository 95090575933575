import React from "react";
import { Button, Radio } from "antd";
import { AdaptedStatuses, AdaptedValues } from "shared/types";
import { CloseOutlined } from "@ant-design/icons";

interface TableRadioGroupProps {
  options: AdaptedValues[] | AdaptedStatuses[];
  selectedValue: number | string;
  onChange: (value: number | string) => void;
}

const TableRadioGroup: React.FC<TableRadioGroupProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <div className="p-2 w-auto">
      <Radio.Group
        onChange={(e) => onChange(e.target.value)}
        value={selectedValue}
        className="flex flex-col gap-y-2"
      >
        {options.map((option) => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
      <Button
        className="bg-blue-primary w-full mt-2 flex items-center justify-center"
        onClick={() => onChange("")}
      >
        <CloseOutlined className="!text-white" />
      </Button>
    </div>
  );
};

export { TableRadioGroup };
