import { useMemo } from "react";
import {
  Widget,
  WidgetTypeEnum,
  useGetAllMachinesQuery,
  useGetAllMaterialsNoPaginationQuery,
  useGetAllMaterialsQuery,
  useGetAllOrdersQuery,
  useGetMaterialByIdQuery,
  useGetMaterialCategoriesWithNoParamsQuery,
  useGetMaterialConsumedQuery,
  useGetMaterialItemsQuery,
  useRunStandAloneWidgetDataQuery,
} from "shared/api";
import {
  adaptArticleFlowData,
  adaptGraphMaterialLvl,
  adaptMachinesXData,
  adaptMachinesXProcessTemplatesFilterData,
  adaptMaterialConsumptionData,
  adaptMaterialTargetAlert,
  adaptMaterialTargetArticleFilterData,
  adaptOrderStatusData,
  adaptProductionTrackingData,
  adaptQualityTrackingData,
  articleFlowMaterialFilter,
  articleFlowStatusFilter,
  getIncludeCalcProgressParameter,
  getOrderStatusMachines,
  materialConsumptionStatusFilter,
  orderStatusStatusFilter,
  widgetsRequestsParams,
} from "shared/helpers";
import { adaptStandAloneWidgetData } from "shared/helpers/widgets/standAlone";
import { PollingData } from "./usePollingSeconds";

interface UseWidgetTableDataProps {
  widget: Widget;
  pollingData: PollingData;
}

export const useWidgetTableData = ({
  widget,
  pollingData,
}: UseWidgetTableDataProps) => {
  const { pollingInterval: POLLING_INTERVAL } = pollingData;

  const { data: materialCategories, isLoading: isLoadingMaterialCategories } =
    useGetMaterialCategoriesWithNoParamsQuery(undefined, {
      skip: widget?.type !== WidgetTypeEnum.PRODUCTION_DATA_TRACKER,
    });

  const { data: allMaterials, isLoading: isLoadingAllMaterials } =
    useGetAllMaterialsNoPaginationQuery(
      {},
      { skip: widget?.type !== WidgetTypeEnum.GRAPH_MATERIAL_LEVEL }
    );

  const {
    articleFlowParams,
    materialConsumed,
    orderStatus,
    machinesX,
    materialTargetAlert,
  } = widgetsRequestsParams;

  const defaultSorting = articleFlowParams.ordering;
  const widgetSorting = widget?.sort_by?.[0];

  const requestSorting = !widget?.sort_by?.length
    ? defaultSorting
    : widgetSorting;

  const configurationValue = widget?.configuration?.[0]?.configuration_value;

  const configurationParameter =
    widget?.configuration?.[0]?.configuration_parameter;

  const {
    data: articleFlow,
    isLoading: isLoadingArticleFlow,
    isFetching: isFetchingArticleFlow,
    refetch: refetchArticleFlow,
  } = useGetMaterialItemsQuery(
    {
      expand: articleFlowParams.expand,
      limit: widget?.limit_rows,
      ordering: requestSorting,
      material_stock_batch__in: articleFlowMaterialFilter(widget),
      status__in: articleFlowStatusFilter(widget),
    },
    {
      skip:
        widget?.type !== WidgetTypeEnum.ITEM_FLOW &&
        widget?.type !== WidgetTypeEnum.QUALITY_TRACKING &&
        widget?.type !== WidgetTypeEnum.PRODUCTION_DATA_TRACKER,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: productionDateTracker,
    isLoading: isLoadingProductionDataTracker,
    isFetching: isFetchingProductionDataTracker,
    refetch: refetchProductionDataTracker,
  } = useGetMaterialItemsQuery(
    {
      expand: articleFlowParams.expand,
      limit: widget?.limit_rows,
      ordering: requestSorting,
      material_stock_batch__in: articleFlowMaterialFilter(widget),
      status__in: articleFlowStatusFilter(widget),
      material_stock_batch__material__material_category:
        Number(configurationParameter) ?? undefined,
    },
    {
      skip:
        widget?.type !== WidgetTypeEnum.PRODUCTION_DATA_TRACKER ||
        !configurationParameter,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: materialConsumption,
    isLoading: isLoadingMaterialConsumption,
    isFetching: isFetchingMaterialConsumption,
    refetch: refetchMaterialConsumption,
  } = useGetMaterialConsumedQuery(
    {
      limit: widget?.limit_rows,
      expand: materialConsumed.expand,
      ordering: requestSorting,
      status__in: materialConsumptionStatusFilter(widget),
    },
    {
      skip: widget?.type !== WidgetTypeEnum.MATERIAL_CONSUMPTION,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: orderStatusData,
    isLoading: isLoadingOrderStatus,
    isFetching: isFetchingOrderStatus,
    refetch: refetchOrderStatus,
  } = useGetAllOrdersQuery(
    {
      limit: widget?.limit_rows,
      expand: orderStatus.expand,
      ordering: requestSorting,
      status__in: orderStatusStatusFilter(widget),
      ...(getIncludeCalcProgressParameter(widget) && {
        include_calc_progress: true,
      }),
      process_step_trackers__machine__in: getOrderStatusMachines(widget),
    },
    {
      skip: widget?.type !== WidgetTypeEnum.ORDER_STATUS,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: machinesXData,
    isLoading: isLoadingMachinesX,
    isFetching: isFetchingMachinesX,
    refetch: refetchMachinesX,
  } = useGetAllMachinesQuery(
    {
      limit: widget?.limit_rows,
      expand: machinesX.expand,
      ordering: requestSorting,
      process_template__in: adaptMachinesXProcessTemplatesFilterData(widget),
    },
    {
      skip: widget?.type !== WidgetTypeEnum.MACHINES,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: materialsTargetAlert,
    isLoading: isLoadingMaterialsTargetAlert,
    isFetching: isFetchingMaterialsTargetAlert,
    refetch: refetchMaterialsTargetAlert,
  } = useGetAllMaterialsQuery(
    {
      limit: widget?.limit_rows,
      ordering: requestSorting,
      expand: materialTargetAlert.expand,
      material_category__in: adaptMaterialTargetArticleFilterData(widget),
    },
    {
      skip: widget?.type !== WidgetTypeEnum.MATERIAL_TARGET_ALERT,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: materialLevelGraph,
    isLoading: isLoadingMaterialLevelGraph,
    isFetching: isFetchingMaterialLevelGraph,
    refetch: refetchGraphMaterialLvl,
  } = useGetMaterialByIdQuery(
    { id: widget?.configuration?.[0]?.configuration_value as number },
    {
      skip:
        widget?.type !== WidgetTypeEnum.GRAPH_MATERIAL_LEVEL ||
        !widget?.configuration?.[0]?.configuration_value,
      pollingInterval: POLLING_INTERVAL,
    }
  );

  const {
    data: standAloneData,
    refetch: refetchStandAloneWidgetData,
    isLoading: isLoadingStandAloneData,
    isFetching: isFetchingStandAloneData,
  } = useRunStandAloneWidgetDataQuery(widget, {
    skip:
      widget?.type !== WidgetTypeEnum.STANDALONE_LINE_GRAPH ||
      widget?.standalone_query === null ||
      widget?.influxdb_server === null,
  });

  const getWidgetData = (widgetType: string) => {
    switch (widgetType) {
      case WidgetTypeEnum.ITEM_FLOW:
        return adaptArticleFlowData(articleFlow?.results ?? []);

      case WidgetTypeEnum.QUALITY_TRACKING:
        return adaptQualityTrackingData(articleFlow?.results ?? []);

      case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
        return adaptProductionTrackingData(
          configurationValue?.toString() ?? "No dynamic field value provided",
          materialCategories ?? [],
          productionDateTracker?.results ?? []
        );

      case WidgetTypeEnum.MATERIAL_CONSUMPTION:
        return adaptMaterialConsumptionData(materialConsumption?.results ?? []);

      case WidgetTypeEnum.ORDER_STATUS:
        return adaptOrderStatusData(orderStatusData?.results ?? []);

      case WidgetTypeEnum.MACHINES:
        return adaptMachinesXData(machinesXData?.results ?? []);

      case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
        return adaptMaterialTargetAlert(materialsTargetAlert?.results ?? []);

      case WidgetTypeEnum.GRAPH_MATERIAL_LEVEL:
        return adaptGraphMaterialLvl(materialLevelGraph ?? []);

      case WidgetTypeEnum.STANDALONE_LINE_GRAPH:
        return adaptStandAloneWidgetData(standAloneData ?? []);

      default:
        return undefined;
    }
  };

  const widgetData = useMemo(() => {
    return getWidgetData(widget.type);
  }, [
    articleFlow,
    materialConsumption,
    orderStatusData,
    machinesXData,
    materialsTargetAlert,
    productionDateTracker,
    configurationValue,
    configurationParameter,
    materialLevelGraph,
    widget?.filter,
    standAloneData,
  ]);

  const isWidgetLoading =
    isLoadingArticleFlow ||
    isLoadingMaterialConsumption ||
    isLoadingOrderStatus ||
    isLoadingMachinesX ||
    isLoadingMaterialsTargetAlert ||
    isLoadingProductionDataTracker ||
    isLoadingMaterialLevelGraph ||
    isLoadingStandAloneData;

  const isWidgetFetching =
    isFetchingArticleFlow ||
    isFetchingMaterialConsumption ||
    isFetchingOrderStatus ||
    isFetchingMachinesX ||
    isFetchingMaterialsTargetAlert ||
    isFetchingProductionDataTracker ||
    isFetchingMaterialLevelGraph ||
    isFetchingStandAloneData;

  const hardRefetch = (widgetType: string) => {
    switch (widgetType) {
      case WidgetTypeEnum.ITEM_FLOW:
        return refetchArticleFlow();

      case WidgetTypeEnum.MATERIAL_CONSUMPTION:
        return refetchMaterialConsumption();

      case WidgetTypeEnum.ORDER_STATUS:
        return refetchOrderStatus();

      case WidgetTypeEnum.MACHINES:
        return refetchMachinesX();

      case WidgetTypeEnum.MATERIAL_TARGET_ALERT:
        return refetchMaterialsTargetAlert();

      case WidgetTypeEnum.PRODUCTION_DATA_TRACKER:
        return refetchProductionDataTracker();

      case WidgetTypeEnum.GRAPH_MATERIAL_LEVEL:
        return refetchGraphMaterialLvl();

      case WidgetTypeEnum.STANDALONE_LINE_GRAPH:
        return refetchStandAloneWidgetData();

      default:
        return undefined;
    }
  };

  const isGaugeWidget = widget?.type === WidgetTypeEnum.GRAPH_MATERIAL_LEVEL;
  const isStandAloneWidget =
    widget?.type === WidgetTypeEnum.STANDALONE_LINE_GRAPH;

  return {
    widgetData,
    isLoading: isWidgetLoading || isWidgetFetching,
    hardRefetch,
    materialCategories: {
      materialCategories,
      isLoadingMaterialCategories,
    },
    materials: {
      allMaterials,
      isLoadingAllMaterials,
    },
    isGaugeWidget,
    isStandAloneWidget,
  };
};
