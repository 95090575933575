import { FormikProvider, useFormik } from "formik";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useGetDimensionUnitsQuery,
  useGetMaterialCategoryByIdQuery,
  useUpdateMaterialCategoryMutation,
} from "shared/api";
import {
  DynamicForm,
  FormInput,
  FormSelect,
  HyperlinkPageHeader,
  Loader,
} from "shared/ui";
import { handleRequestError, noop } from "shared/helpers";
import { adaptDimensionUnitsData } from "shared/adapters";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { Button, Popover, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  expandDimensionUnits,
  lockMaterialCategoryValues,
  variantMaterialSelectValues,
} from "shared/constants";
import { AddOrEditMaterialSchema } from "shared/schemas";

const MaterialCategoryHyperlink = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [updateMaterialCategory] = useUpdateMaterialCategoryMutation();

  const { data: materialCategory, isLoading: isMaterialCategoryLoading } =
    useGetMaterialCategoryByIdQuery(
      {
        id: Number(id),
      },
      { skip: !id }
    );

  const addOrUpdateMaterialFormik = useFormik({
    initialValues: {
      ...materialCategory,
      sections: materialCategory?.config_dynamic_tracker_fields,
    },
    validationSchema: AddOrEditMaterialSchema,
    enableReinitialize: true,
    onSubmit: noop,
  });

  const { data: dimensionUnits } = useGetDimensionUnitsQuery({
    expand: expandDimensionUnits,
  });

  const adaptedDimensionUnits = useMemo(() => {
    return adaptDimensionUnitsData(dimensionUnits || []);
  }, [dimensionUnits]);

  const { values, handleBlur, handleChange, errors, setFieldValue, isValid } =
    addOrUpdateMaterialFormik;

  const clearDefaultMaterialConsumeVariant = () => {
    setFieldValue("default_material_consume_variant", null);
  };

  const submitMaterialCategoryEdit = async () => {
    try {
      await updateMaterialCategory({
        name: values.name!,
        unit: values.unit!,
        id: values.id,
        config_dynamic_tracker_fields: values.sections || [],
        unique_code: values.unique_code!,
        lock_material_consume_variant: values?.lock_material_consume_variant!,
        default_material_consume_variant:
          values?.default_material_consume_variant!,
        export_col_for_quantity: values.export_col_for_quantity
          ? values.export_col_for_quantity
          : null,
        export_col_for_serial_number: values.export_col_for_serial_number
          ? values.export_col_for_serial_number
          : null,
        // We don't set supplier in this modal. But it's required in the API
        suppliers: [],
      }).unwrap();
      api.success({
        message: t("material-category-management.success-title"),
        description: t("material-category-management.success-update-desc"),
      });
    } catch (error) {
      api.error({
        message: t("material-category-management.error-title"),
        description: handleRequestError(
          error,
          t("material-category-management.error-title")
        ),
      });
    }
  };

  if (isMaterialCategoryLoading) return <Loader type="fullscreen" />;

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateMaterialFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Material Category Hyperlink - ${materialCategory?.name}`}
            isValid={isValid}
            onOk={submitMaterialCategoryEdit}
          />
          <div className="w-full p-4">
            <Title level={5} className="mb-1.25">
              {t("material-category-management.add-modal-title")}
            </Title>
            <FormInput
              value={values.name}
              fieldName="name"
              placeholder="Enter material name"
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            <Title level={5} className="mb-1.25">
              {t("material-category-management.unique-code")}
            </Title>

            <FormInput
              value={values.unique_code}
              fieldName="unique_code"
              placeholder={t(
                "material-category-management.unique-code-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.unique_code}
              isError={!!errors.unique_code}
            />

            <Title level={5} className="mb-1.25">
              {t("material-category-management.export-col-for-serial-number")}
            </Title>

            <FormInput
              value={values.export_col_for_serial_number}
              fieldName="export_col_for_serial_number"
              placeholder={t(
                "material-category-management.export-col-for-serial-number-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.export_col_for_serial_number}
              isError={!!errors.export_col_for_serial_number}
              numberType
            />

            <Title level={5} className="mb-1.25">
              {t("material-category-management.export-col-for-quantity")}
            </Title>

            <FormInput
              value={values.export_col_for_quantity}
              fieldName="export_col_for_quantity"
              placeholder={t(
                "material-category-management.export-col-for-quantity-placeholder"
              )}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.export_col_for_quantity}
              isError={!!errors.export_col_for_quantity}
              numberType
            />

            <div className="w-full flex items-center justify-between">
              <Title level={5} className="mb-1.25">
                {t(
                  "material-category-management.default-material-consume-variant"
                )}
              </Title>

              <Popover content={t("material-category-management.clear-values")}>
                <Button
                  className="w-6 h-6 rounded-full p-0 flex items-center justify-center mb-2"
                  onClick={clearDefaultMaterialConsumeVariant}
                >
                  <CloseOutlined />
                </Button>
              </Popover>
            </div>

            <FormSelect
              value={values?.default_material_consume_variant}
              placeholder={t(
                "material-category-management.default-material-consume-variant"
              )}
              fieldName="default_material_consume_variant"
              options={variantMaterialSelectValues}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors?.default_material_consume_variant}
              isError={!!errors.default_material_consume_variant}
            />

            <Title level={5} className="mb-1.25">
              {t("material-category-management.lock-material-consume-variant")}
            </Title>

            <FormSelect
              value={values?.lock_material_consume_variant}
              placeholder={t(
                "material-category-management.lock-material-consume-variant"
              )}
              fieldName="lock_material_consume_variant"
              options={lockMaterialCategoryValues}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors?.lock_material_consume_variant}
              isError={!!errors.lock_material_consume_variant}
            />

            <Title level={5} className="mb-1.25">
              {t("material-category-management.add-modal-unit")}
            </Title>

            <FormSelect
              value={values.unit}
              placeholder={t("material-category-management.select-dim-unit")}
              fieldName="unit"
              withLabels
              optionsWithLabels={adaptedDimensionUnits}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors.unit}
              isError={!!errors.unit}
            />

            <DynamicForm adaptedDimensionUnits={adaptedDimensionUnits} />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { MaterialCategoryHyperlink };
