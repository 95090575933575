import { Button } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  ArticleFlowWidgetType,
  WidgetTableData,
  WidgetTypeEnum,
} from "shared/api";
import {
  ArticleFlowFields,
  colorStatusButton,
  openHyperlinkInTab,
  renderArticleFlowIcon,
  renderQualityTrackingIcon,
} from "shared/helpers";
import { Routes } from "shared/routers";
import { DateWithTimeRow, PopConfirmButton, TableImage } from "shared/ui";

interface ArticleFlowWidgetTableProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

const ArticleFlowWidget: FC<ArticleFlowWidgetTableProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const { t } = useTranslation();

  const isArticleFlow = record?.type === WidgetTypeEnum.ITEM_FLOW;

  // Index is hardcoded, because with this hyperlink we will open order details. Not a specific step
  const orderLink = `/process-step-production/${parseInt(
    (
      record as unknown as ArticleFlowWidgetType
    )?.articleArticle?.serial_number.split("-")[0],
    10
  )}/index-0`;

  const machinesLink =
    `${Routes.machines.hyperlinkUrl}/${record?.articleMachine?.id}`.replace(
      ":id/",
      ""
    );

  const articleLink = `${Routes.materialManagement.url}/article/${
    (record as unknown as ArticleFlowWidgetType)?.articleArticle?.id
  }`.replace(":id/", "");

  if (text === ArticleFlowFields.WIDGET_TYPE) {
    return <span>{t(`widgets.${text}`)} </span>;
  }

  if (fieldKey === ArticleFlowFields.ARTICLE_IMAGE) {
    return (
      <TableImage
        image={
          isArticleFlow
            ? renderArticleFlowIcon(record)
            : renderQualityTrackingIcon(record)
        }
        preview={false}
        className="w-9 h-9"
        onClick={() => openHyperlinkInTab(orderLink)}
      />
    );
  }

  if (fieldKey === ArticleFlowFields.ARTICLE_MACHINE) {
    return (
      <PopConfirmButton
        buttonContent={record?.articleMachine?.name}
        className=""
        onClick={() => openHyperlinkInTab(machinesLink)}
      />
    );
  }

  if (fieldKey === ArticleFlowFields.ARTICLE_ARTICLE) {
    return (
      <PopConfirmButton
        buttonContent={
          (record as unknown as ArticleFlowWidgetType)?.articleArticle
            ?.serial_number
        }
        onClick={() => openHyperlinkInTab(articleLink)}
      />
    );
  }

  if (fieldKey === ArticleFlowFields.ARTICLE_STATUS) {
    return (
      <Button className={classNames("w-full", colorStatusButton(record))}>
        {text}
      </Button>
    );
  }

  if (fieldKey === ArticleFlowFields.ARTICLE_DATE_TIME) {
    return <DateWithTimeRow text={text} />;
  }

  return <span>{text}</span>;
};

const ArticleFlowWidgetMemo = React.memo(ArticleFlowWidget);

export { ArticleFlowWidgetMemo as ArticleFlowWidget };
