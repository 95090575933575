import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterials } from "shared/adapters";
import { MaterialsItem, Widget } from "shared/api";
import { useMaterialLevelGraph } from "shared/hooks";
import { FormInput, FormSelect, Loader } from "shared/ui";

interface MaterialLevelGraphProps {
  materials: {
    allMaterials: MaterialsItem[] | undefined;
    isLoadingAllMaterials: boolean;
  };
}

const MaterialLevelGraph: FC<MaterialLevelGraphProps> = ({ materials }) => {
  const { t } = useTranslation();

  const { setFieldValue, handleBlur, handleChange } =
    useFormikContext<Widget>();

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials.allMaterials ?? []);
  }, [materials]);

  const {
    materialField,
    materialValue,
    redColorValueField,
    redColorValue,
    yellowColorValue,
    yellowColorValueField,
  } = useMaterialLevelGraph({ materials });

  return (
    <div className="bg-white w-full self-start p-1">
      {materials.isLoadingAllMaterials ? (
        <Loader />
      ) : (
        <div>
          <Title level={5}>{t("widgets.material")}</Title>
          <FormSelect
            value={Number(materialValue) || null}
            placeholder={t("widgets.selectMaterial")}
            fieldName={materialField}
            options={adaptedMaterials}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            isLoading={materials.isLoadingAllMaterials}
            showSearch
          />

          <Title level={5}>{t("widgets.redLimit")}</Title>
          <FormInput
            value={redColorValue.toString()}
            fieldName={redColorValueField}
            placeholder={t("widgets.redLimit")}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <Title level={5}>{t("widgets.yellowLimit")}</Title>
          <FormInput
            value={yellowColorValue.toString()}
            fieldName={yellowColorValueField}
            placeholder={t("widgets.yellowLimit")}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
      )}
    </div>
  );
};

const MaterialLevelGraphMemo = React.memo(MaterialLevelGraph);
export { MaterialLevelGraphMemo as MaterialLevelGraph };
