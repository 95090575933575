import { AddCompanyValues } from "shared/types";
import { MaterialCategoriesItem } from "shared/api";

export const adaptMaterialCategories = (
  materialCategories: MaterialCategoriesItem[]
) => {
  const materialCategoriesAdapted = materialCategories?.map((item) => ({
    label: `${item.name}`,
    value: item.id!,
    key: item.id!,
  }));
  return materialCategoriesAdapted || [];
};

export const getMaterialCategoriesFromCompany = (values: AddCompanyValues) => {
  const materialCategories = values?.categories_supplied?.map((item) => ({
    ...(item?.material_category as MaterialCategoriesItem),
  }));

  return materialCategories;
};

export const getIdsOfMaterialCategoriesToFind = (values: AddCompanyValues) => {
  const idsToInclude = values?.categories_supplied_new_to_add?.map(
    (value) => (value.material_category as MaterialCategoriesItem)?.id
  );

  return idsToInclude;
};

export const filterMaterialCategories = (
  materialCategories: MaterialCategoriesItem[],
  idsToInclude: number[]
) => {
  const filteredCategories = materialCategories
    ?.filter((category) => idsToInclude?.includes(category.id!))
    ?.map((category) => ({
      id: 1,
      material_category: category,
    }));

  return filteredCategories;
};

export const adaptMaterialCategoryForUpdate = (values: AddCompanyValues) => {
  const adaptedMaterialCategories = values?.categories_supplied?.map(
    (item) => ({
      material_category: (item?.material_category as MaterialCategoriesItem)
        ?.id as number,
    })
  );

  return adaptedMaterialCategories;
};
