import { Collapse } from "antd";
import { FieldArray } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  MaterialStockItem,
  OrderStepTrackerResponseItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { AdaptedValues, InitialInputMaterials } from "shared/types";
import { InputMaterialCard } from "./InputMaterialCard";

interface HiddenInputMaterialsProps {
  visibleMaterials: ProductionInputMaterial[];
  hiddenMaterials: ProductionInputMaterial[];
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  adaptedMaterials: AdaptedValues[];
  adaptedMaterialItems: AdaptedValues[];
  adaptedStockMaterialBatches: AdaptedValues[];
  adaptedInitialInputMaterials: InitialInputMaterials[];
  materialStockBatches?: StockMaterialItem[];
  materialItems?: MaterialStockItem[];
}

const HiddenInputMaterials: FC<HiddenInputMaterialsProps> = ({
  visibleMaterials,
  hiddenMaterials,
  orderStepTrackerResponseItem,
  adaptedMaterials,
  adaptedMaterialItems,
  adaptedStockMaterialBatches,
  adaptedInitialInputMaterials,
  materialStockBatches,
  materialItems,
}) => {
  const { t } = useTranslation();

  return (
    <Collapse accordion className="!white-arrow mt-4">
      <Collapse.Panel
        header={
          <span className="!text-white">
            {t("orders.additionalInputMaterials")}
          </span>
        }
        key="hidden-materials"
        className="w-full bg-blue-primary !text-white"
      >
        {hiddenMaterials.map((inputMaterial, hiddenIndex) => {
          // Calculate index for hidden materials
          // We need to get index from values (because we use FieldArray here)
          // So started from visibleMaterials.length to get correct index
          const hiddenMaterialIndex = hiddenIndex + visibleMaterials.length;

          const dynamicInputMaterialCategory =
            orderStepTrackerResponseItem?.process_step?.input_materials?.[
              hiddenMaterialIndex
            ]?.material?.material_category;

          const isMoreThatOneMaterial = inputMaterial.fields.length > 1;

          return (
            <FieldArray
              key={inputMaterial.id}
              name={`input_materials.${hiddenMaterialIndex}.fields`}
              render={(arrayHelpers) => (
                <InputMaterialCard
                  inputIndex={hiddenMaterialIndex}
                  moreThanOneMaterial={isMoreThatOneMaterial}
                  inputMaterial={inputMaterial}
                  adaptedMaterials={adaptedMaterials}
                  adaptedMaterialItems={adaptedMaterialItems}
                  adaptedStockMaterialBatches={adaptedStockMaterialBatches}
                  adaptedInitialInputMaterials={adaptedInitialInputMaterials}
                  materialStockBatches={materialStockBatches}
                  materialItems={materialItems}
                  dynamicInputMaterialCategory={dynamicInputMaterialCategory}
                  addCard={arrayHelpers.push}
                  removeCard={arrayHelpers.remove}
                  orderStepTrackerResponseItem={orderStepTrackerResponseItem}
                />
              )}
            />
          );
        })}
      </Collapse.Panel>
    </Collapse>
  );
};

export { HiddenInputMaterials };
