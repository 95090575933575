import { Modal, notification } from "antd";
import { useFormik } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DashboardConfigurationParameter,
  DashboardModes,
  useAddDashboardMutation,
} from "shared/api";
import { handleRequestError, noop } from "shared/helpers";
import { Colors } from "shared/themes";
import { FormInput } from "shared/ui/FormInput";
import { RadioButtons } from "shared/ui/RadioButtons";
import { TbColumns1, TbColumns2 } from "react-icons/tb";

interface AddDashboardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddDashboardModal: FC<AddDashboardModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [addDashboard] = useAddDashboardMutation();
  const navigate = useNavigate();

  const addDashboardFormik = useFormik({
    initialValues: {
      name: null,
      configuration: [
        {
          configuration_parameter: DashboardConfigurationParameter.MODE,
          configuration_value: DashboardModes.ONE_COLUMN,
        },
      ],
    },
    enableReinitialize: true,
    onSubmit: noop,
  });

  const { values, handleBlur, handleChange, resetForm, setFieldValue } =
    addDashboardFormik;

  const [isOneColumn, setIsOneColumn] = useState(true);

  const selectOneColumnMode = () => {
    setIsOneColumn(true);
    setFieldValue(
      "configuration.0.configuration_value",
      DashboardModes.ONE_COLUMN
    );
  };

  const selectDoubleColumnMode = () => {
    setIsOneColumn(false);
    setFieldValue(
      "configuration.0.configuration_value",
      DashboardModes.DOUBLE_COLUMNS
    );
  };

  const closeAndResetModal = () => {
    onClose();
    resetForm();
    setIsOneColumn(true);
  };

  const handleAddDashboard = async () => {
    try {
      const response = await addDashboard({
        name: values.name!,
        widgets: [],
        configuration: [
          {
            configuration_parameter: DashboardConfigurationParameter.MODE,
            configuration_value: values.configuration[0].configuration_value,
          },
        ],
      }).unwrap();

      if (response) navigate(`/dashboards/${response.id}`);

      api.success({
        message: t("dashboards.success-title"),
        description: t("dashboards.add-success-msg"),
      });
    } catch (error) {
      api.error({
        message: t("dashboards.error-title"),
        description: handleRequestError(error, t("dashboards.error-title")),
      });
    } finally {
      closeAndResetModal();
    }
  };

  const isDisabledForm =
    !values.name || !values.configuration[0].configuration_value;

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={closeAndResetModal}
        title={t("dashboards.add-dashboard")}
        okButtonProps={{
          disabled: isDisabledForm,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onOk={handleAddDashboard}
      >
        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("dashboards.add-dashboard-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <RadioButtons
          isActive={!!isOneColumn}
          selectLeftOption={selectOneColumnMode}
          selectRightOption={selectDoubleColumnMode}
          title={t("dashboards.select-mode")}
          leftVariantTitle={t("dashboards.one-column")}
          rightVariantTitle={t("dashboards.two-columns")}
          leftIcon={<TbColumns1 className="ml-2 scale-150" />}
          rightIcon={<TbColumns2 className="ml-2 scale-150" />}
        />
      </Modal>
    </>
  );
};

export { AddDashboardModal };
