import { Table } from "antd";
import { useFormikContext } from "formik";
import React from "react";
import { ColumnsType } from "antd/es/table";
import { LabMeasurements } from "shared/api";
import { FormInput } from "shared/ui";
import { useTranslation } from "react-i18next";
import { getTableExtraDataValue } from "shared/helpers";
import { UploadDocumentValues } from "shared/types";
import { extraDataColumns } from "shared/constants";

const ExtraDataTable = () => {
  const { t } = useTranslation();

  const { values, handleBlur, handleChange } =
    useFormikContext<UploadDocumentValues>();

  const { number, tensileMd, tensileCd, elongationMd, elongationCd } =
    extraDataColumns;

  const columns: ColumnsType<LabMeasurements> = [
    {
      title: t(number.title),
      width: number.width,
      render: (record: LabMeasurements) => record?.number,
    },
    {
      title: t(tensileMd.title),
      width: tensileMd.width,
      render: (
        _measurements: LabMeasurements,
        _record: LabMeasurements,
        index: number
      ) => {
        return (
          <FormInput
            value={getTableExtraDataValue(values, index, "tensileMd")}
            fieldName={`reel_lab_measurements.${index}.tensile_md`}
            placeholder={t("quality.enter-value")}
            handleBlur={handleBlur}
            handleChange={handleChange}
            numberType
            className="!h-8"
          />
        );
      },
    },
    {
      title: t(tensileCd.title),
      width: tensileCd.width,
      render: (
        _measurements: LabMeasurements,
        _record: LabMeasurements,
        index: number
      ) => (
        <FormInput
          value={getTableExtraDataValue(values, index, "tensileCd")}
          fieldName={`reel_lab_measurements.${index}.tensile_cd`}
          placeholder={t("quality.enter-value")}
          handleBlur={handleBlur}
          handleChange={handleChange}
          numberType
          className="!h-8"
        />
      ),
    },
    {
      title: t(elongationMd.title),
      width: elongationMd.width,
      render: (
        _measurements: LabMeasurements,
        _record: LabMeasurements,
        index: number
      ) => (
        <FormInput
          value={getTableExtraDataValue(values, index, "elongationMd")}
          fieldName={`reel_lab_measurements.${index}.elongation_md`}
          placeholder={t("quality.enter-value")}
          handleBlur={handleBlur}
          handleChange={handleChange}
          numberType
          className="!h-8"
        />
      ),
    },
    {
      title: t(elongationCd.title),
      width: elongationCd.width,
      render: (
        _measurements: LabMeasurements,
        _record: LabMeasurements,
        index: number
      ) => (
        <FormInput
          value={getTableExtraDataValue(values, index, "elongationCd")}
          fieldName={`reel_lab_measurements.${index}.elongation_cd`}
          placeholder={t("quality.enter-value")}
          handleBlur={handleBlur}
          handleChange={handleChange}
          numberType
          className="!h-8"
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={values?.reel_lab_measurements}
      rowKey={(obj) => obj.number!}
      className="mt-3"
    />
  );
};

export { ExtraDataTable };
