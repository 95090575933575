export const standAloneIntialFormikValues = {};

export const standAloneChartColors = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(199, 199, 199, 1)",
  "rgba(83, 102, 255, 1)",
  "rgba(255, 99, 71, 1)",
  "rgba(60, 179, 113, 1)",
  "rgba(123, 104, 238, 1)",
  "rgba(255, 140, 0, 1)",
  "rgba(70, 130, 180, 1)",
  "rgba(244, 164, 96, 1)",
  "rgba(32, 178, 170, 1)",
];
