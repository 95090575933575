import { useMemo } from "react";
import { useGetAllCompaniesQuery } from "shared/api";

interface UseOrderHyperlinkDataProps {
  buyer?: number;
}

export const useOrderHyperlinkData = ({
  buyer,
}: UseOrderHyperlinkDataProps) => {
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetAllCompaniesQuery(
      {},
      {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
      }
    );

  const dynamicCompanyFields = useMemo(() => {
    return companies?.find((item) => item?.id === buyer);
  }, [companies, buyer]);

  return {
    isLoadingCompanies,
    dynamicCompanyFields,
  };
};
