import {
  MaterialConsumedItem,
  Widget,
  WidgetTableData,
  WidgetTypeEnum,
} from "shared/api";
import { widgetsIcons } from "shared/assets";

interface Machine {
  name: string;
}

interface OrderProcessStepTracker {
  machine?: Machine;
}

interface OrderProductionSet {
  order_process_step_tracker?: OrderProcessStepTracker;
}

interface Material {
  order_production_set?: OrderProductionSet;
}

export const enum MaterialConsumptionFields {
  WIDGET_TYPE = "MATERIAL_CONSUMPTION",
  ICON = "icon",
  MATERIAL_CONSUMED_DATE = "consumedDateTime",
  MATERIAL_CONSUMED_ORDER_PROD_SET = "consumedOrderProductionSet",
  MATERIAL_CONSUMED_MATERIAL = "consumedMaterial",
  MATERIAL_CONSUMED_MATERIAL_STOCK_BATCH = "consumedMaterialStockBatch",
  MATERIAL_CONSUMED_MATERIAL_ITEM = "consumedMaterialItem",
  MATERIAL_CONSUMED_QUANTITY = "consumedQuantity",
  MATERIAL_CONSUMED_STATUS = "consumedStatus",
}

export const materialConsumptionColumnsValues = [
  { value: "consumedDateTime", label: "Date Time" },
  { value: "consumedOrderProductionSet", label: "Order Production Set" },
  { value: "consumedMaterial", label: "Material" },
  { value: "consumedMaterialStockBatch", label: "Material Stock Batch" },
  { value: "consumedMaterialItem", label: "Material Item" },
  { value: "consumedQuantity", label: "Quantity" },
  { value: "consumedStatus", label: "Status" },
];

export const adaptMaterialConsumptionData = (
  materialsConsumed: MaterialConsumedItem[]
) => {
  return materialsConsumed.map((material) => {
    return {
      type: WidgetTypeEnum.MATERIAL_CONSUMPTION,

      icon: material?.status,

      consumedDateTime: material?.consumed_datetime ?? "No data",

      consumedOrderProductionSet:
        (material as Material)?.order_production_set?.order_process_step_tracker
          ?.machine?.name ?? "No data",

      consumedMaterial: material?.material?.name ?? "No data",

      consumedMaterialStockBatch:
        material?.material_stock_batch?.material?.name ?? "No data",

      consumedMaterialItem:
        material?.material_stock_batch?.material?.name ?? "No data",

      consumedQuantity: material?.quantity ?? "No data",

      consumedStatus: material?.status ?? "No data",
    };
  });
};

export const materialConsumptionStatusColors = {
  US: "!bg-green-100 !text-white",
  WA: "!bg-red-100 !text-white",
  LO: "!bg-yellow-100",
};

export const colorMaterialConsumptionStatusButton = (
  record: WidgetTableData
) => {
  return materialConsumptionStatusColors[
    record.consumedStatus as keyof typeof materialConsumptionStatusColors
  ];
};

export const materialConsumptionStatusFilter = (widget: Widget) => {
  const statusFilter = widget?.filter?.find(
    (widget) => widget?.filter_parameter === "material_consumption_status__in"
  );

  return (statusFilter?.filter_value as string[]) ?? undefined;
};

export const getMaterialConsumptionImage = (status: string) => {
  if (status === "US") return widgetsIcons.logisticGreen;
  if (status === "WA") return widgetsIcons.logisticRed;
  if (status === "LO") return widgetsIcons.logisticYellow;

  return "";
};
