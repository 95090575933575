import { ExecuteReportValues } from "shared/types";
import {
  materialItemFilterValidation,
  multipleItemFilterValidation,
  multipleOrderFilterValidation,
  multiplePackagingFilterValidation,
  multipleQualityValidation,
  orderFilterValidation,
  packagingFilterValidation,
  reelLabValidation,
} from "shared/schemas";
import { useMemo } from "react";
import { adaptOrdersForExecuteReports } from "shared/adapters";
import {
  ReportItem,
  ReportType,
  useGetAllOrdersWithNoParamsQuery,
  useGetAllReportsQuery,
} from "shared/api";
import groupBy from "lodash.groupby";

interface UseExecuteReportsDataProps {
  values: ExecuteReportValues;
}

export type GroupedReports = Record<string, ReportItem[]>;

export const useExecuteReportsData = ({
  values,
}: UseExecuteReportsDataProps) => {
  const selectedPackageId = values.id;

  const { data: reports, isLoading: isLoadingReports } =
    useGetAllReportsQuery();

  const isEmptyReportsResponse = reports?.length === 0;

  const selectedReport = useMemo(() => {
    return reports?.find((report) => report?.id === values?.id);
  }, [reports, values?.id]);

  const groupedQuests: GroupedReports = useMemo(() => {
    return groupBy(reports, "report_type");
  }, [reports]);

  // Flag for get different filters
  const selectedReportsType = useMemo(() => {
    const selectedReport = reports?.find(
      (report) => report?.id === selectedPackageId
    );

    return selectedReport?.report_type as ReportType;
  }, [selectedPackageId]);

  const isMaterialItem = selectedReportsType === ReportType.MATERIAL_ITEM;
  const isPackaging = selectedReportsType === ReportType.PACKAGING;
  const isMultiplePackaging =
    selectedReportsType === ReportType.MULTIPLE_PACKAGING;
  const isOrderType = selectedReportsType === ReportType.ORDER;
  const isMultipleOrderType = selectedReportsType === ReportType.MULTIPLE_ORDER;
  const isReelLabResultType =
    selectedReportsType === ReportType.REEL_LAB_ARCHIVE;
  const isMultipleQualityType =
    selectedReportsType === ReportType.MULTIPLE_QUALITY;
  const isMultipleItemType =
    selectedReportsType === ReportType.MULTIPLE_MATERIAL_ITEM;

  const isNotSupportedYetType =
    selectedReportsType === ReportType.MULTIPLE_MATERIAL;

  const { data: orders } = useGetAllOrdersWithNoParamsQuery({});

  const adaptedOrders = useMemo(() => {
    return adaptOrdersForExecuteReports(orders ?? []);
  }, [orders]);

  const conditionSchemaMap = [
    { condition: isMaterialItem, schema: materialItemFilterValidation },
    { condition: isPackaging, schema: packagingFilterValidation },
    {
      condition: isMultiplePackaging,
      schema: multiplePackagingFilterValidation,
    },
    { condition: isOrderType, schema: orderFilterValidation },
    { condition: isMultipleOrderType, schema: multipleOrderFilterValidation },
    { condition: isReelLabResultType, schema: reelLabValidation },
    { condition: isMultipleQualityType, schema: multipleQualityValidation },
    { condition: isMultipleItemType, schema: multipleItemFilterValidation },
  ];

  return {
    selectedReport,
    groupedQuests,
    isLoadingReports,
    selectedFilter: {
      isMaterialItem,
      isPackaging,
      isMultiplePackaging,
      isOrderType,
      isMultipleOrderType,
      isNotSupportedYetType,
      isReelLabResultType,
      isMultipleQualityType,
      isMultipleItemType,
    },
    adaptedOrders,
    conditionSchemaMap,
    isEmptyReportsResponse,
  };
};
