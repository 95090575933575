import { FilterFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import React from "react";
import { MaterialsItem } from "shared/api";
import {
  columnsMaterialManagementData,
  MaterialTableSorting,
  sortDirections,
} from "shared/constants";
import { AdaptedValues } from "shared/types";
import {
  ColumnName,
  ProgressBar,
  TableButton,
  TableRadioGroup,
  TableSearchFilter,
} from "shared/ui";

interface MaterialManagementTableProps {
  t: TFunction;
  handleMaterialItemToEdit: (materialItem: MaterialsItem) => void;
  changeSort: Function;
  nameValue: string;
  clearNameValue: () => void;
  handleNamedValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  materialCategoriesAdapted: AdaptedValues[];
  handleMaterialCategory: (value: number | string) => void;
  materialCategoryFilteringItem: string | number;
  handelMaterialStockHistoryDetails: (item: MaterialsItem) => void;
  handelMaterialConsumedDetails: (item: MaterialsItem) => void;
  handleMaterialReservationDetails: (item: MaterialsItem) => void;
  handleStockManagementDetails: (item: MaterialsItem) => void;
  showDeleteCategoryModal: (record: MaterialsItem) => void;
}

export const useUpdatedMaterialManagementTableColumnsGeneration = ({
  t,
  handleMaterialItemToEdit,
  changeSort,
  nameValue,
  clearNameValue,
  handleNamedValue,
  materialCategoriesAdapted,
  handleMaterialCategory,
  materialCategoryFilteringItem,
  handelMaterialStockHistoryDetails,
  handelMaterialConsumedDetails,
  handleMaterialReservationDetails,
  handleStockManagementDetails,
  showDeleteCategoryModal,
}: MaterialManagementTableProps): ColumnsType<MaterialsItem> => [
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.name.title)}
        shortTitle={t(columnsMaterialManagementData.name.title)}
      />
    ),
    render: (item: MaterialsItem) => {
      return (
        <TableButton
          type="details"
          onClick={() => handelMaterialStockHistoryDetails(item)}
          itemName={item.name}
          className="!text-[12px]"
        />
      );
    },
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.MATERIAL_ASC,
          MaterialTableSorting.MATERIAL_DESC
        );
      },
    }),
    sorter: true,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": nameValue.length,
        })}
      />
    ),

    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearNameValue}
        confirm={confirm}
        value={nameValue!}
        handleFilterChange={handleNamedValue!}
        visible={visible}
        title={t("material-management.filter-title")!}
        placeholder={t("material-management.filter-placeholder")!}
      />
    ),
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.material_category.title)}
        shortTitle={t(columnsMaterialManagementData.material_category.title)}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.material_category?.name}</span>
    ),
    filterDropdown: () => (
      <TableRadioGroup
        options={materialCategoriesAdapted}
        selectedValue={materialCategoryFilteringItem}
        onChange={handleMaterialCategory}
      />
    ),
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.MATERIAL_CATEGORY_ASC,
          MaterialTableSorting.MATERIAL_CATEGORY_DESC
        );
      },
    }),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-green-600  scale-125": !!materialCategoryFilteringItem,
        })}
      />
    ),
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.targetQuantityInStock.title)}
        shortTitle={t(
          columnsMaterialManagementData.targetQuantityInStock.title
        )}
      />
    ),
    render: (item: MaterialsItem) => <ProgressBar item={item} />,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.remaining.title)}
        shortTitle={t(columnsMaterialManagementData.remaining.shortTitle)}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">
        {item?.calc_total_remaining_quantity}
      </span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.REMAINING_ASC,
          MaterialTableSorting.REMAINING_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.remainingPercentage.title)}
        shortTitle={t(
          columnsMaterialManagementData.remainingPercentage.shortTitle
        )}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.calc_percentage_remaining}</span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.REMAINING_PERCENTAGE_ASC,
          MaterialTableSorting.REMAINING_PERCENTAGE_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.reserved.title)}
        shortTitle={t(columnsMaterialManagementData.reserved.shortTitle)}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.calc_total_reserved_quantity}</span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.TOTAL_RESERVED_ASC,
          MaterialTableSorting.TOTAL_RESERVED_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.available.title)}
        shortTitle={t(columnsMaterialManagementData.available.shortTitle)}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">
        {item?.calc_total_available_quantity}
      </span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.TOTAL_AVAILABLE_ASC,
          MaterialTableSorting.TOTAL_AVAILABLE_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.availablePercentage.title)}
        shortTitle={t(
          columnsMaterialManagementData.availablePercentage.shortTitle
        )}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.calc_percentage_available}</span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.PERCENTAGE_AVAILABLE_ASC,
          MaterialTableSorting.PERCENTAGE_AVAILABLE_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.missing.title)}
        shortTitle={t(columnsMaterialManagementData.missing.shortTitle)}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.calc_total_missing_quantity}</span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.MISSING_ASC,
          MaterialTableSorting.MISSING_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.missingPercentage.title)}
        shortTitle={t(
          columnsMaterialManagementData.missingPercentage.shortTitle
        )}
      />
    ),
    render: (item: MaterialsItem) => (
      <span className="!text-[12px]">{item?.calc_percentage_missing}</span>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialTableSorting.MISSING_PERCENTAGE_ASC,
          MaterialTableSorting.MISSING_PERCENTAGE_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: (
      <ColumnName
        fullTitle={t(columnsMaterialManagementData.actions.title)}
        shortTitle={t(columnsMaterialManagementData.actions.title)}
      />
    ),
    render: (item: MaterialsItem) => {
      return (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="consumed"
            onClick={() => handelMaterialConsumedDetails(item)}
          />

          <TableButton
            type="reservation"
            onClick={() => handleMaterialReservationDetails(item)}
          />

          <TableButton
            type="settings"
            onClick={() => handleStockManagementDetails(item)}
          />

          <TableButton
            type="edit"
            onClick={() => handleMaterialItemToEdit(item)}
          />

          <TableButton
            type="delete"
            onClick={() => showDeleteCategoryModal(item)}
          />
        </div>
      );
    },
  },
];
