import { Divider } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { ExecuteReportValues } from "shared/types";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReportItem } from "shared/api";
import { Colors } from "shared/themes";
import { DatePickerValue, GlobalDatePicker } from "shared/ui";

interface DatesBlockProps {
  selectedReport?: ReportItem;
}

const DatesBlock: FC<DatesBlockProps> = ({ selectedReport }) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { setFieldValue, values } = useFormikContext<ExecuteReportValues>();

  const handleWantedTimeStart = (_: DatePickerValue, dateString: string) => {
    setFieldValue("order_wanted_delivery_datetime_start", dateString);
  };

  const handleWantedTimeEnd = (_: DatePickerValue, dateString: string) => {
    setFieldValue("order_wanted_delivery_datetime_end", dateString);
  };

  const handleProductionTimeStart = (
    _: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("order_production_datetime_start", dateString);
  };

  const handleProductionTimeEnd = (_: DatePickerValue, dateString: string) => {
    setFieldValue("order_production_datetime_end", dateString);
  };

  const today = dayjs()
    .set("hour", 6)
    .set("minute", 0)
    .set("second", 0)
    .format("YYYY-MM-DD HH:mm:ss");

  const yesterday = dayjs()
    .subtract(1, "day")
    .set("hour", 6)
    .set("minute", 0)
    .set("second", 0)
    .format("YYYY-MM-DD HH:mm:ss");

  // TODO: check clearFormikValueOnReportChange function if preselecting will works wrong
  useEffect(() => {
    if (selectedReport) {
      // Set default values for production time start
      handleProductionTimeStart(null, yesterday);
      // Set default values for production time end
      handleProductionTimeEnd(null, today);
    }
  }, [selectedReport]);

  return (
    <>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />

      <div className="flex gap-x-3">
        <div>
          <Title level={5} className="mb-1.25">
            {t("orderWantedDeliveryDatetimeStart")}
          </Title>

          <GlobalDatePicker
            handlePickerChange={handleWantedTimeStart}
            size="small"
            showTime
          />
        </div>

        <Divider
          type="vertical"
          className="h-full"
          style={{ background: Colors.blue.primary }}
        />

        <div>
          <Title level={5} className="mb-1.25">
            {t("orderWantedDeliveryDatetimeEnd")}
          </Title>

          <GlobalDatePicker
            handlePickerChange={handleWantedTimeEnd}
            size="small"
            showTime
          />
        </div>

        <Divider
          type="vertical"
          className="h-full"
          style={{ background: Colors.blue.primary }}
        />

        <div>
          <Title level={5} className="mb-1.25">
            {t("orderProductionDatetimeStart")}
          </Title>

          <GlobalDatePicker
            value={dayjs.utc(values.order_production_datetime_start)}
            handlePickerChange={handleProductionTimeStart}
            size="small"
            showTime
          />
        </div>

        <Divider
          type="vertical"
          className="h-full"
          style={{ background: Colors.blue.primary }}
        />

        <div>
          <Title level={5} className="mb-1.25">
            {t("orderProductionDatetimeEnd")}
          </Title>

          <GlobalDatePicker
            value={dayjs.utc(values.order_production_datetime_end)}
            handlePickerChange={handleProductionTimeEnd}
            size="small"
            showTime
          />
        </div>
      </div>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />
    </>
  );
};

export { DatesBlock };
