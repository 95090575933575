import { FC } from "react";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { DynamicNumberInput } from "shared/features/DynamicFormsComponents/DynamicIntegerInput";
import { renderDynamicInputType } from "shared/helpers";
import { AddOrUpdateOrderValues, IDynamicCompanyFields } from "shared/types";
import { DynamicFieldsEnum } from "shared/constants";
import { SupplierCompanyItem } from "shared/api";
import { DynamicTextTypeInput, DynamicSelectInput } from "shared/features";

interface DynamicCompanyFieldsProps {
  dynamicCompanyFields?: IDynamicCompanyFields | SupplierCompanyItem;
}

const DynamicCompanyFields: FC<DynamicCompanyFieldsProps> = ({
  dynamicCompanyFields,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<AddOrUpdateOrderValues>();

  if (dynamicCompanyFields?.config_dynamic_order_fields?.length === 0) {
    return null;
  }

  return (
    <>
      {dynamicCompanyFields?.config_dynamic_order_fields && (
        <div className="mt-4">
          <Title level={5}>{t("orders.dynamic-orders-title")}</Title>
        </div>
      )}
      {dynamicCompanyFields?.config_dynamic_order_fields?.map((card) => (
        <div
          key={card.title}
          className="w-full h-auto p-2.5 mb-2.5 rounded-xl shadow-dynamicFormCard"
        >
          <Title level={4}>{card?.title}</Title>
          <div>
            {card?.fields.map((field, fieldIndex: number) => {
              const cardFieldName = `dynamic_order_fields.${card.title}.${field.name}`;

              const cardValue =
                values.dynamic_order_fields?.[card.title]?.[field.name];

              return (
                <div key={field?.name}>
                  {/* TO-DO temporary hide EXPRESSION Title. Backend do not have finished this type */}
                  {field.type === DynamicFieldsEnum.EXPRESSION ? null : (
                    <Title level={5} className="!text-primary">
                      {field.name}: {t("orders.type")} -{" "}
                      {renderDynamicInputType(field?.type)}
                    </Title>
                  )}

                  {field.type === DynamicFieldsEnum.TEXT && (
                    <DynamicTextTypeInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={cardValue}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.INTEGER && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={cardValue}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.FLOAT && (
                    <DynamicNumberInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={cardValue}
                      fieldName={cardFieldName}
                    />
                  )}

                  {field.type === DynamicFieldsEnum.CHOICES && (
                    <DynamicSelectInput
                      card={card}
                      fieldIndex={fieldIndex}
                      value={cardValue}
                      fieldName={cardFieldName}
                    />
                  )}

                  {/* TO-DO temporary null. Backend do not have finished this type */}
                  {field.type === DynamicFieldsEnum.EXPRESSION && null}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export { DynamicCompanyFields };
