import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import {
  ConfigDynamicTrackerFields,
  DynamicOutputMaterialData,
  DynamicOutputMaterialObject,
  OrderStepTrackerResponseItem,
} from "shared/api";
import { useTranslation } from "react-i18next";
import { UpdateOutputMaterialNumberInput } from "./UpdateOutputMaterialNumberInput";
import { UpdateOutputMaterialTextInput } from "./UpdateOutputMaterialTextInput";
import { UpdateOutputMaterialChoicesSelect } from "./UpdateOutputMaterialChoicesSelect";

interface DynamicFieldsForEditingProps {
  index: number;
  currentPage: number;
  materials: DynamicOutputMaterialData[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const DynamicFieldsForEditing: FC<DynamicFieldsForEditingProps> = ({
  index,
  currentPage,
  materials,
  setFieldValue,
  orderStepTrackerResponseItem,
}) => {
  const renderDynamicFields = (
    configData: ConfigDynamicTrackerFields[],
    dynamicData: DynamicOutputMaterialObject,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const { t } = useTranslation("", { keyPrefix: "orders" });

    return configData?.map((section: ConfigDynamicTrackerFields) => (
      <div key={section.title}>
        <Title level={5} className="bg-blue-primary !text-white mt-2">
          {section.title}
        </Title>
        {section.fields.map((field, cardIndex: number) => {
          const fieldName = `output_materials.${index}.dynamicData.${currentPage}.dynamic_tracker_fields.${section.title}.${field.name}`;
          const fieldValue = dynamicData[section.title][field.name];
          const fieldType = field.type;

          let inputComponent;
          switch (fieldType) {
            case "integer":
            case "float":
              inputComponent = (
                <UpdateOutputMaterialNumberInput
                  fieldName={fieldName}
                  fieldValue={fieldValue}
                  setFieldValue={setFieldValue}
                  section={section}
                  cardIndex={cardIndex}
                  placeholder={
                    field?.type === "float"
                      ? t("enterFloat")
                      : t("enterInteger")
                  }
                />
              );
              break;
            case "text":
              inputComponent = (
                <UpdateOutputMaterialTextInput
                  fieldName={fieldName}
                  fieldValue={fieldValue}
                  setFieldValue={setFieldValue}
                  section={section}
                  cardIndex={cardIndex}
                />
              );
              break;
            case "choices":
              inputComponent = (
                <UpdateOutputMaterialChoicesSelect
                  fieldName={fieldName}
                  fieldValue={fieldValue}
                  setFieldValue={setFieldValue}
                  section={section}
                  cardIndex={cardIndex}
                  field={field}
                />
              );
              break;
            default:
              // eslint-disable-next-line no-console
              console.warn(`Unhandled field type: ${fieldType}`);
              inputComponent = null;
          }

          return (
            <div key={field.name}>
              <Title level={5} className="!m-0">
                {field?.name}
              </Title>
              {inputComponent}
            </div>
          );
        })}
      </div>
    ));
  };

  const dynamicData = materials[currentPage]?.dynamic_tracker_fields;

  const configData =
    orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
      ?.material?.material_category?.config_dynamic_tracker_fields;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dynamicData
        ? renderDynamicFields(configData ?? [], dynamicData, setFieldValue)
        : null}
    </>
  );
};

export { DynamicFieldsForEditing };
