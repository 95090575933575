import { Alert, Modal, Select, Table } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo, useState } from "react";
import { adaptOrders } from "shared/adapters";
import {
  useGetAllOrdersWithNoParamsQuery,
  useGetMaterialItemsForProductionPageQuery,
} from "shared/api";
import {
  ModalSizeValue,
  trackingListExpand,
  trackingListOrdering,
} from "shared/constants";
import { Loader } from "shared/ui";
import { useTranslation } from "react-i18next";
import {
  useGenerateMoveItemsColumns,
  useMoveProductionItems,
} from "shared/hooks";

import { Colors } from "shared/themes";
import { selectFiltering } from "shared/helpers";

interface MoveItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: number;
  materialItemId: number | null;
}

const MoveItemsModal: FC<MoveItemsModalProps> = ({
  isOpen,
  onClose,
  orderId,
  materialItemId,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });
  const { t: tableTranslation } = useTranslation();

  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

  const onOrderChange = (order: number | null) => {
    setSelectedOrderId(order);
  };

  const { data: orders, isLoading: isLoadingOrders } =
    useGetAllOrdersWithNoParamsQuery({});

  const allOrders = useMemo(() => orders ?? [], [orders]);

  const adaptedOrders = useMemo(() => {
    return adaptOrders(allOrders);
  }, [allOrders]);

  const {
    moveItemsHandler,
    selectTableRows,
    closeModal,
    selectedMaterialItemsKeys,
    contextHolder,
  } = useMoveProductionItems({
    orderId,
    onClose,
    onOrderChange,
  });

  const {
    data: materialItemsToMove,
    isLoading: isLoadingMaterialItems,
    isFetching: isFetchingMaterialItems,
  } = useGetMaterialItemsForProductionPageQuery(
    {
      order: selectedOrderId ?? undefined,
      material: materialItemId as number,
      ordering: trackingListOrdering,
      expand: trackingListExpand,
    },
    {
      skip: !selectedOrderId || !materialItemId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const columns = useGenerateMoveItemsColumns({ t: tableTranslation });

  const isVisibleTable = selectedOrderId && materialItemsToMove?.length;
  const isNoTableResults = selectedOrderId && !materialItemsToMove?.length;

  const isValidModal = selectedOrderId && selectedMaterialItemsKeys.length;

  return (
    <>
      {contextHolder}

      <Modal
        open={isOpen}
        onCancel={closeModal}
        title={t("moveItems")}
        onOk={moveItemsHandler}
        width={ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: !isValidModal,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        {(isLoadingMaterialItems || isFetchingMaterialItems) && (
          <Loader type="fullscreen-backdrop" />
        )}

        <Title level={5}>{t("selectOrder")}</Title>

        <Select
          value={selectedOrderId}
          options={adaptedOrders}
          onChange={onOrderChange}
          className="w-full mb-3"
          size="large"
          placeholder={t("selectOrder")}
          showSearch
          filterOption={(input, option) => selectFiltering(input, option)}
          loading={isLoadingOrders}
        />

        {!selectedOrderId && <Alert showIcon message={t("selectOrderFirst")} />}

        {isVisibleTable ? (
          <Table
            rowSelection={{
              type: "checkbox",
              columnWidth: "5%",
              selectedRowKeys: selectedMaterialItemsKeys,
              ...selectTableRows,
            }}
            loading={isLoadingMaterialItems || isFetchingMaterialItems}
            columns={columns}
            dataSource={materialItemsToMove}
            rowKey={(obj) => obj.id!}
          />
        ) : null}

        {isNoTableResults && (
          <Alert type="warning" showIcon message={t("noDataForThisOrder")} />
        )}
      </Modal>
    </>
  );
};

export { MoveItemsModal };
