import { Input, Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  adaptMaterials,
  adaptCompanies,
  adaptStockMaterialData,
} from "shared/adapters";
import {
  useGetAllMaterialsNoPaginationQuery,
  useUpdateMaterialStockBatchMutation,
  EDitStockMaterialItem,
  useGetAllCompaniesQuery,
  SuppliersItem,
} from "shared/api";
import { ModalSizeValue, stockMaterialStatuses } from "shared/constants";

interface EditStockMaterialModalProps {
  isOpenEditStockMaterial: boolean;
  hideEditStockModal: () => void;
  isActiveDynamicTable?: boolean;
}

const EditStockMaterialModal: FC<EditStockMaterialModalProps> = ({
  isOpenEditStockMaterial,
  hideEditStockModal,
  isActiveDynamicTable,
}) => {
  const { t } = useTranslation();

  const {
    values,
    resetForm,
    isValid,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
  } = useFormikContext<EDitStockMaterialItem>();

  const closeModal = () => {
    hideEditStockModal();
    if (!isActiveDynamicTable) {
      resetForm();
    }
  };

  const { data: allMaterials } = useGetAllMaterialsNoPaginationQuery();

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(allMaterials ?? []);
  }, [allMaterials]);

  const { data: companies } = useGetAllCompaniesQuery({});

  const companiesAdapted = useMemo(() => {
    return adaptCompanies(companies || []);
  }, [companies]);

  const handlePickerChange = (_: DatePickerValue, dateString: string) => {
    if (!isActiveDynamicTable) {
      setFieldValue("delivery_date", dateString);
    }

    if (isActiveDynamicTable) {
      setFieldValue("stockMaterial.delivery_date", dateString);
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const [updateMaterialStockBatch] = useUpdateMaterialStockBatchMutation();

  const updateStockMaterialItem = async () => {
    try {
      const adaptedStockData = adaptStockMaterialData(
        values,
        !!isActiveDynamicTable
      );
      await updateMaterialStockBatch(adaptedStockData).unwrap();

      api.success({
        message: t("stock-material-modals.success-title"),
        description: t("stock-material-modals.success-update-desc"),
      });
      hideEditStockModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("stock-material.error-title")
      );

      api.error({
        message: t("stock-material.error-title"),
        description,
      });
    }
  };

  const isQuantityInTheTrackerItemsVariant =
    !!values?.is_quantity_in_tracker_table;

  return (
    <>
      {contextHolder}
      <Modal
        title={t("stock-material.edit-modal-title")}
        open={isOpenEditStockMaterial}
        width={ModalSizeValue.SECONDARY}
        closable={!isActiveDynamicTable}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={() => {
          updateStockMaterialItem();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.material")}
        </Title>
        <FormSelect
          value={
            isActiveDynamicTable
              ? values?.stockMaterial?.material?.id
              : values.material
          }
          placeholder={t("stock-material-modals.material-placeholder")}
          fieldName={
            isActiveDynamicTable ? "stockMaterial.material.id" : "material"
          }
          options={adaptedMaterials}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.material}
          isError={!!errors.material}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.batchNumber")}
        </Title>

        <FormInput
          value={
            isActiveDynamicTable
              ? values?.stockMaterial?.batch_code
              : values.batch_code
          }
          fieldName={
            isActiveDynamicTable ? "stockMaterial.batch_code" : "batch_code"
          }
          placeholder={t("stock-material-modals.batchCode-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.batch_code}
          isError={!!errors.batch_code}
        />

        {isQuantityInTheTrackerItemsVariant ? null : (
          <>
            <Title level={5} className="mb-1.25">
              {t("stock-material-modals.quantity")}
            </Title>

            <div className="flex gap-2.5">
              <FormInput
                numberType
                value={
                  isActiveDynamicTable
                    ? values?.stockMaterial?.quantity
                    : values.quantity
                }
                fieldName={
                  isActiveDynamicTable ? "stockMaterial.quantity" : "quantity"
                }
                placeholder={t("stock-material-modals.quantity-placeholder")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.quantity}
                isError={!!errors.quantity}
              />

              <Input
                value={
                  isActiveDynamicTable
                    ? values?.stockMaterial?.material?.material_category?.unit
                        ?.name
                    : values?.materialDetails?.material_category?.unit?.name
                }
                disabled
                size="large"
                className="mb-1.25 w-[45%] text-[14px]"
              />
            </div>
          </>
        )}

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.deliveryDate")}
        </Title>

        <GlobalDatePicker
          handlePickerChange={handlePickerChange}
          value={
            isActiveDynamicTable
              ? dayjs.utc(values?.stockMaterial?.delivery_date)
              : dayjs.utc(values.delivery_date)
          }
          error={errors.delivery_date}
          isError={!!errors.delivery_date}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.status")}
        </Title>
        <FormSelect
          value={
            isActiveDynamicTable ? values?.stockMaterial?.status : values.status
          }
          placeholder={t("stock-material-modals.status-placeholder")}
          fieldName={isActiveDynamicTable ? "stockMaterial.status" : "status"}
          options={stockMaterialStatuses}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status}
          isError={!!errors.status}
        />

        <Title level={5} className="w-full mt-1.25 mb-0">
          {t("stock-material-modals.supplier")}
        </Title>

        <FormSelect
          value={
            isActiveDynamicTable
              ? values?.stockMaterial?.supplier
              : (values?.supplier as SuppliersItem)?.id
          }
          placeholder={t("material-management.suppliers-placeholder")}
          fieldName={
            isActiveDynamicTable ? "stockMaterial.supplier" : "supplier"
          }
          options={companiesAdapted}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.supplier?.toString()}
          isError={!!errors.supplier}
          showSearch
        />
      </Modal>
    </>
  );
};

const EditStockMaterialModalMemo = React.memo(EditStockMaterialModal);

export { EditStockMaterialModalMemo as EditStockMaterialModal };
