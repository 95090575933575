export const userInitialValues = {
  id: null,
  username: "",
  email: "",
  first_name: "",
  last_name: "",
};

export const columnsData = {
  first_name: {
    title: "userTableColumns.firstName",
    dataIndex: "first_name",
    key: "first_name",
    width: "20%",
  },
  last_name: {
    title: "userTableColumns.lastName",
    dataIndex: "last_name",
    key: "last_name",
    width: "20%",
  },
  username: {
    title: "userTableColumns.username",
    dataIndex: "username",
    key: "username",
    width: "20%",
  },
  email: {
    title: "userTableColumns.email",
    dataIndex: "email",
    key: "email",
    width: "20%",
  },
  actions: { title: "userTableColumns.actions", key: "actions", width: "10%" },
};

export enum OrderingUsersEnum {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  USERNAME = "username",
  EMAIL = "email",
  REVERSE_FIRST_NAME = "-first_name",
  REVERSE_LAST_NAME = "-last_name",
  REVERSE_USERNAME = "-username",
  REVERSE_EMAIL = "-email",
}

export enum SelectValueEnum {
  FIRST_NAME_ACS = "fist_name_asc",
  FIRST_NAME_DESC = "fist_name_desc",
  LAST_NAME_ASC = "last_name_asc",
  LAST_NAME_DESC = "last_name_desc",
  USERNAME_ASC = "username_asc",
  USERNAME_DESC = "username_desc",
  EMAIL_ASC = "email_asc",
  EMAIL_DESC = "email_desc",
}
