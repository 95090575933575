import classNames from "classnames";
import React, { FC } from "react";
import { Button } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { InitialInputMaterials } from "shared/types";
import { useFormikContext } from "formik";
import { SelectVariantDropDown } from "./SelectVariantDropDown";
import { AddProductionRequest } from "./ProcessStepProduction";

interface DynamicStockBatchValue {
  label: string;
  value: number | null;
}

interface DynamicInputMaterialVariantProps {
  moreThanOneMaterial: boolean;
  adaptedInitialInputMaterials: InitialInputMaterials[];
  inputIndex: number;
  fieldIndex: number;
  dynamicStockBatchValue: DynamicStockBatchValue[];
}

const DynamicInputMaterialVariant: FC<DynamicInputMaterialVariantProps> = ({
  moreThanOneMaterial,
  adaptedInitialInputMaterials,
  inputIndex,
  fieldIndex,
  dynamicStockBatchValue,
}) => {
  const { values } = useFormikContext<AddProductionRequest>();

  const dynamicAdaptedFields =
    adaptedInitialInputMaterials?.[inputIndex]?.fields;

  const selectValue =
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex].selectValue;

  const shouldShowDropDown = selectValue !== "material";

  return (
    <div
      className={classNames("w-[30%]", {
        "w-full": moreThanOneMaterial,
      })}
    >
      {dynamicAdaptedFields?.map((_, index) => {
        return (
          <React.Fragment key={index}>
            {shouldShowDropDown ? (
              <SelectVariantDropDown
                inputIndex={inputIndex}
                fieldIndex={fieldIndex}
                dynamicStockBatchValue={dynamicStockBatchValue}
              />
            ) : (
              <Button
                disabled
                className={classNames("w-[100%] !h-10 mb-1.5 p-0", {
                  hidden: index > 0,
                })}
              >
                <StopOutlined className="scale-150 text-red-200" />
              </Button>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export { DynamicInputMaterialVariant };
