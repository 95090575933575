import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderStepTrackerResponseItem } from "shared/api";
import { useOrderProcessStepTrackerGeneratePdfFiles } from "shared/hooks";
import { ProductionHeaderPdfType } from "shared/constants";
import classNames from "classnames";
import { HeaderPdfExportButton } from "./HeaderPdfExportButton";

interface GeneratePdfFilesContainerProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const GeneratePdfFilesContainer: FC<GeneratePdfFilesContainerProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const {
    generateAllItemsLabelsPdf,
    generateLastSetLabelsPdf,
    generateAllPackageLabelsPdf,
    generateLastSetPackageLabelsPdf,
    contextHolder,
    loadingStates,
  } = useOrderProcessStepTrackerGeneratePdfFiles({
    orderProcessStepTrackerId: orderStepTrackerResponseItem?.id as number,
  });

  const generatePdfFiledsMapData = [
    {
      title: t("allItemsLabels"),
      onClick: generateAllItemsLabelsPdf,
      type: ProductionHeaderPdfType.AllItemsLabels,
      loading: loadingStates?.isLoadingGenerateAllItemsLabels,
    },
    {
      title: t("lastSetItemLabels"),
      onClick: generateLastSetLabelsPdf,
      type: ProductionHeaderPdfType.LastSetItemLabels,
      loading: loadingStates?.isLoadingGenerateLastSetLabels,
    },
    {
      title: t("allPackageLabels"),
      onClick: generateAllPackageLabelsPdf,
      type: ProductionHeaderPdfType.AllPackageLabels,
      loading: loadingStates?.isLoadingGenerateAllPackageLabels,
    },
    {
      title: t("lastSetPackageLabels"),
      onClick: generateLastSetPackageLabelsPdf,
      type: ProductionHeaderPdfType.LastSetPackageLabels,
      loading: loadingStates?.isLoadingGenerateLastSetPackageLabels,
    },
  ];

  return (
    <>
      {contextHolder}

      <div className={classNames("flex flex-wrap gap-2", {})}>
        {generatePdfFiledsMapData?.map((item) => (
          <HeaderPdfExportButton
            key={item.title}
            title={item.title}
            onClick={item.onClick}
            type={item.type}
            loading={item.loading}
          />
        ))}
      </div>
    </>
  );
};

export { GeneratePdfFilesContainer };
