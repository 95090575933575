import { Alert, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { DateWithTimeRow, GlobalChart, Loader } from "shared/ui";
import { calculateOffset, renderUnit } from "shared/helpers";
import { adaptMaterialDetailChardData } from "shared/adapters";
import {
  useGetMaterialDetailsQuery,
  useGetMaterialDetailsWithoutParamsQuery,
  MaterialDetailsItem,
  MaterialsItem,
} from "shared/api";
import { materialDetailsColumns } from "shared/constants";
import { Volumes } from "./Volumes";

interface MaterialDetailsProps {
  materialItem: MaterialsItem | null;
}

const MaterialDetails: FC<MaterialDetailsProps> = ({ materialItem }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const isFirstPage = currentPage === 1;
  const {
    name,
    materialStockBatch,
    materialReservation,
    materialConsumed,
    datetime,
    quantityAvailable,
    quantityReserved,
  } = materialDetailsColumns;

  useEffect(() => {
    if (isFirstPage) {
      setOffset(0);
    } else {
      setOffset(calculateOffset(pageSize, currentPage));
    }
    setLimit(pageSize);
  }, [currentPage, pageSize]);

  const {
    data: materialDetails,
    isLoading: isLoadingMaterialDetails,
    isFetching: isFetchingMaterialDetails,
  } = useGetMaterialDetailsQuery(
    { id: Number(materialItem?.id), limit, offset },
    { skip: !materialItem?.id }
  );

  const { data: materialDetailsForTheChart } =
    useGetMaterialDetailsWithoutParamsQuery(
      { id: Number(materialItem?.id) },
      { skip: !materialItem?.id }
    );

  const columns: ColumnsType<MaterialDetailsItem> = [
    {
      title: t(name.title),
      width: name.width,
      render: () => materialItem?.name,
    },
    {
      title: t(datetime.title),
      render: (item: MaterialDetailsItem) => (
        <DateWithTimeRow text={item?.datetime} />
      ),
      width: datetime.width,
    },
    {
      title: t(materialStockBatch.title),
      render: (item: MaterialDetailsItem) => {
        if (item.material_stock_batch === null) {
          return (
            <span className="opacity-50">
              {t("material-category-management.null")}
            </span>
          );
        }
        return item.material_stock_batch;
      },
      width: materialStockBatch.width,
    },
    {
      title: t(materialReservation.title),
      render: (item: MaterialDetailsItem) => {
        if (item.material_reservation === null) {
          return (
            <span className="opacity-50">
              {t("material-category-management.null")}
            </span>
          );
        }
        return item.material_reservation;
      },
      width: materialReservation.width,
    },
    {
      title: t(materialConsumed.title),
      render: (item: MaterialDetailsItem) => {
        if (item.material_consumed === null) {
          return (
            <span className="opacity-50">
              {t("material-category-management.null")}
            </span>
          );
        }
        return item.material_consumed;
      },
      width: materialConsumed.width,
    },
    {
      title: t(quantityAvailable.title),
      render: (item: MaterialDetailsItem) => renderUnit(materialItem, item),
      width: quantityAvailable.width,
    },
    {
      title: t(quantityReserved.title),
      render: (item: MaterialDetailsItem) => renderUnit(materialItem, item),
      width: quantityReserved.width,
    },
  ];

  const chartData = adaptMaterialDetailChardData(
    materialDetailsForTheChart,
    materialItem
  );

  if (isLoadingMaterialDetails) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <div>
      {!materialDetails?.results?.length && (
        <Alert
          message={t("material-category-management.empty-stock-history")}
          type="warning"
          showIcon
        />
      )}

      {!materialItem && <Loader />}

      {materialDetails?.results?.length ? (
        <Volumes materialItem={materialItem} />
      ) : null}

      <Table
        loading={isLoadingMaterialDetails || isFetchingMaterialDetails}
        columns={columns}
        dataSource={materialDetails?.results}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: materialDetails?.count,
        }}
        className={!materialDetails?.results?.length ? "hidden" : "block"}
      />

      <GlobalChart
        data={chartData}
        chartLabel={t("material-category-management.av-and-res-qnt")!}
        hidden={!materialDetails?.results?.length}
        yAxisLabel={renderUnit(
          materialItem,
          materialDetails?.results?.[0]!,
          true
        )}
        tooltipExtraData={t("material-category-management.unit")!}
      />
    </div>
  );
};

export { MaterialDetails };
