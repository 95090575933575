import React, { FC } from "react";
import { Modal, notification } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useCreateNewUserMutation, UserItem } from "shared/api";
import { ModalSizeValue } from "shared/constants";

interface AddUserModalProps {
  isOpenNewUserModal: boolean;
  closeAddUserModal: () => void;
}

const AddUserModal: FC<AddUserModalProps> = ({
  isOpenNewUserModal,
  closeAddUserModal,
}) => {
  const { values, resetForm, isValid, handleChange, handleBlur, errors } =
    useFormikContext<UserItem>();

  const closeModal = () => {
    closeAddUserModal();
    resetForm();
  };

  const [createNewUser] = useCreateNewUserMutation();
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const onAddUser = async ({
    first_name,
    last_name,
    username,
    email,
  }: UserItem) => {
    try {
      await createNewUser({ first_name, last_name, username, email }).unwrap();
      api.success({
        message: t("users-page.success-title"),
        description: t("users-page.success-add-desc"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const { first_name, last_name, username, email } = values;

  return (
    <>
      {contextHolder}

      <Modal
        title={t("add-user-modal.add-user")}
        open={isOpenNewUserModal}
        width={ModalSizeValue.PRIMARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          onAddUser({ first_name, last_name, username, email });
        }}
      >
        <Title level={5} className="text-[14px]">
          {t("add-user-modal.first-name")}
        </Title>

        <FormInput
          value={values.first_name}
          fieldName="first_name"
          placeholder="modal-placeholders.firstname-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.first_name}
          isError={!!errors.first_name}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.last-name")}
        </Title>

        <FormInput
          value={values.last_name}
          fieldName="last_name"
          placeholder="modal-placeholders.lastname-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.last_name}
          isError={!!errors.last_name}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.username")}
        </Title>

        <FormInput
          value={values.username}
          fieldName="username"
          placeholder="modal-placeholders.username-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.username}
          isError={!!errors.username}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.email")}
        </Title>

        <FormInput
          value={values.email}
          fieldName="email"
          placeholder="modal-placeholders.email-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          isError={!!errors.email}
        />
      </Modal>
    </>
  );
};

export { AddUserModal };
