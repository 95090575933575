import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";

const EmptyWidgetsState = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center justify-center">
      <Title level={5}>{t("dashboards.no-widgets-found")}</Title>
    </div>
  );
};

export { EmptyWidgetsState };
