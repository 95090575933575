import en from "./en.json";
import tu from "./tu.json";
import sw from "./sw.json";
import du from "./du.json";
import nw from "./nw.json";
import fi from "./fi.json";
import ar from "./ar.json";
import kl from "./kl.json";
import dn from "./dn.json";
import sp from "./sp.json";
import gm from "./gm.json";
import it from "./it.json";
import cz from "./cz.json";

export const resources = {
  en: { translation: en },
  tu: { translation: tu },
  sw: { translation: sw },
  du: { translation: du },
  nw: { translation: nw },
  fi: { translation: fi },
  ar: { translation: ar },
  kl: { translation: kl },
  dn: { translation: dn },
  sp: { translation: sp },
  gm: { translation: gm },
  it: { translation: it },
  cz: { translation: cz },
};
