import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OrderStepTrackerResponseItem } from "shared/api";
import { Routes } from "shared/routers";
import { useWindowDimensions } from "shared/hooks";
import { GeneratePdfFilesContainer } from "./GeneratePdfFilesContainer";
import { MobileAndTabletProdPageHeader } from "./MobileAndTabletProdPageHeader";
import { OrderStatus } from "./OrderStatus";
import { OrderCodeInfo } from "./OrderCodeInfo";
import { ProcessStepTitle } from "./ProcessStepTitle";

interface ProdPageHeaderProps {
  decrementProcessStepIndex: () => void;
  incrementProcessStepIndex: () => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  closeProdPageModal: () => void;
  isLoadingOrderCodes: boolean;
  navigateOrder: (direction: "next" | "previous") => void;
}

const ProdPageHeader: FC<ProdPageHeaderProps> = ({
  incrementProcessStepIndex,
  decrementProcessStepIndex,
  orderStepTrackerResponseItem,
  closeProdPageModal,
  isLoadingOrderCodes,
  navigateOrder,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();

  const processStep = orderStepTrackerResponseItem?.process_step;
  const processStepName = processStep?.name;
  const processStepId = processStep?.id;
  const orderCode = orderStepTrackerResponseItem?.order?.order_code;

  if (!isDesktop) {
    return (
      <MobileAndTabletProdPageHeader
        incrementProcessStepIndex={incrementProcessStepIndex}
        decrementProcessStepIndex={decrementProcessStepIndex}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        closeProdPageModal={closeProdPageModal}
        navigateOrder={navigateOrder}
        isLoadingOrderCodes={isLoadingOrderCodes}
      />
    );
  }

  return (
    <div
      className="p-2 bg-blue-primary mb-1 brightness-150 w-full flex items-center justify-between"
      style={{ position: "sticky", top: 0, width: "100%", zIndex: 1000 }}
    >
      <div className="flex gap-x-2">
        <OrderCodeInfo
          navigateOrder={navigateOrder}
          orderCode={orderCode}
          isLoadingOrderCodes={isLoadingOrderCodes}
        />

        <OrderStatus
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        />

        <ProcessStepTitle
          decrementProcessStepIndex={decrementProcessStepIndex}
          incrementProcessStepIndex={incrementProcessStepIndex}
          processStepId={processStepId}
          processStepName={processStepName}
        />
      </div>

      <GeneratePdfFilesContainer
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />

      <Link to={Routes.orders.url}>
        <Button
          onClick={closeProdPageModal}
          type="default"
          className="
           !text-white !text-bold mr-2
           hover:!bg-white hover:!text-blue-primary
            flex items-center justify-center h-[70%]
          "
        >
          <ArrowLeftOutlined />
          {t("orders.back-to-orders")}
        </Button>
      </Link>
    </div>
  );
};

export { ProdPageHeader };
