import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Title from "antd/es/typography/Title";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
} from "shared/ui";
import { adaptMachines } from "shared/adapters";
import { useGetOrderProcessStepTrackersQuery } from "shared/api";
import { AddDynamicOrderSteps } from "shared/types";
import { expandStepTracker } from "shared/constants";
import { useGetAllMachinesWithNoParamsQuery } from "../../../../shared/api/productCard/productCardApi";

import { ConfigCardStepFields } from "./ConfigCardStepFields";

const DynamicProcessStepsForTheOrderPage = () => {
  const { productCardID } = useParams();
  const { values, handleBlur, handleChange, setFieldValue, setValues, errors } =
    useFormikContext<AddDynamicOrderSteps>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const { data: machines } = useGetAllMachinesWithNoParamsQuery();

  const adaptedMachines = useMemo(() => {
    return adaptMachines(machines || []);
  }, [machines]);

  const { data: orderStepTrackerResponseItem } =
    useGetOrderProcessStepTrackersQuery({
      id: Number(productCardID),
      expand: expandStepTracker,
    });

  useEffect(() => {
    if (orderStepTrackerResponseItem) {
      setValues({
        id: orderStepTrackerResponseItem?.id,
        machine: orderStepTrackerResponseItem?.machine,
        start_datetime: orderStepTrackerResponseItem?.start_datetime,
        end_datetime: orderStepTrackerResponseItem?.end_datetime,
        variant: orderStepTrackerResponseItem.variant,
        variantValue: orderStepTrackerResponseItem.variantValue,
        input_materials: orderStepTrackerResponseItem.input_materials,
        output_materials:
          orderStepTrackerResponseItem.process_step.output_materials,
        status: orderStepTrackerResponseItem.status,
      });
    }
  }, [orderStepTrackerResponseItem]);

  const handleStartDateTimeChange = (
    date: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("start_datetime", date ? dateString : null);
  };

  const handleEndDateTimeChange = (
    date: DatePickerValue,
    dateString: string
  ) => {
    setFieldValue("end_datetime", date ? dateString : null);
  };

  return (
    <div>
      <Title level={1}>Dynamic Card</Title>
      {contextHolder}
      <Title level={5} className="mb-1.25">
        {t("orders.id")}
      </Title>

      <FormInput
        value={values.id}
        fieldName="id"
        placeholder={t("orders.id")}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.id}
        isError={!!errors.id}
        disabled
      />

      <Title level={5} className="mb-1.25">
        {t("orders.buyer")}
      </Title>

      <FormInput
        value={orderStepTrackerResponseItem?.order.buyer.name!}
        fieldName="buyer"
        placeholder={t("orders.buyer")}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled
      />

      <Title level={5} className="mb-1.25">
        {t("orders.machines")}
      </Title>

      <FormSelect
        value={values.machine}
        placeholder={t("orders.machines")}
        fieldName="machine"
        options={adaptedMachines}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        error={errors.machine?.toString()}
        isError={!!errors.machine}
      />

      <Title level={5} className="mb-1.25">
        {t("orders.start-datetime")}
      </Title>

      <GlobalDatePicker
        value={
          values?.start_datetime ? dayjs.utc(values?.start_datetime) : null
        }
        handlePickerChange={handleStartDateTimeChange}
        error={errors.start_datetime}
        isError={!!errors.start_datetime}
      />

      <Title level={5} className="mb-1.25 mt-1.25">
        {t("orders.end-datetime")}
      </Title>

      <GlobalDatePicker
        value={values?.end_datetime ? dayjs.utc(values?.end_datetime) : null}
        handlePickerChange={handleEndDateTimeChange}
        error={errors.end_datetime}
        isError={!!errors.end_datetime}
      />

      <Title level={5} className="mb-1.25 mt-1.25">
        {t("orders.process-step")}
      </Title>

      <FormInput
        value={orderStepTrackerResponseItem?.process_step.name!}
        fieldName="process_step.name"
        placeholder={t("orders.process-step")}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled
      />

      <Title level={5} className="mb-1.25">
        {t("orders.dynamic-process-step-fields")}
      </Title>

      {orderStepTrackerResponseItem?.process_step.process_template.config_dynamic_process_step_fields?.map(
        (card) => {
          return (
            <div
              key={card.title}
              className="w-full h-auto p-2 shadow-dynamicFormCard rounded-xl mt-2"
            >
              <Title level={5}> {card.title}</Title>

              {card.fields.map((field) => {
                return <ConfigCardStepFields field={field} key={field.name} />;
              })}
            </div>
          );
        }
      )}
    </div>
  );
};

export { DynamicProcessStepsForTheOrderPage };
