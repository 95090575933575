import React, { FC, useMemo, useState } from "react";
import { UploadFile } from "antd";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { noop } from "shared/helpers";
import { useGetDimensionUnitsQuery } from "shared/api";
import { adaptDimensionUnitsData } from "shared/adapters";
import { useModal } from "shared/hooks";
import {
  processTemplateInitialValues,
  expandDimensionUnits,
} from "shared/constants";
import { AddOrEditProcessTemplateSchema } from "shared/schemas";
import {
  ProcessTemplatesTable,
  AddProcessTemplateModal,
  EditProcessTemplateModal,
} from "./ui";

interface ProcessTemplatesProps {}

const ProcessTemplates: FC<ProcessTemplatesProps> = () => {
  const { t } = useTranslation();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const { data: dimensionUnits } = useGetDimensionUnitsQuery({
    expand: expandDimensionUnits,
  });

  const unitsWithLabel = useMemo(() => {
    return adaptDimensionUnitsData(dimensionUnits || []);
  }, [dimensionUnits]);

  const [
    isOpenAddProcessTemplateModal,
    showAddProcessTemplateModal,
    hideAddProcessTemplateModal,
  ] = useModal();

  const [
    isOpenUpdateProcessTemplateModal,
    showUpdateProcessTemplateModal,
    hideUpdateProcessTemplateModal,
  ] = useModal();

  const addOrUpdateProcessTemplatesFormik = useFormik({
    initialValues: processTemplateInitialValues,
    validationSchema: AddOrEditProcessTemplateSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateProcessTemplatesFormik}>
      <Page
        title={t("process-templates.title")}
        buttonTitle={t("process-templates.add-process-type-btn")!}
        onClick={showAddProcessTemplateModal}
      >
        <ProcessTemplatesTable
          showUpdateProcessTemplateModal={showUpdateProcessTemplateModal}
        />

        <AddProcessTemplateModal
          isOpenAddProcessTemplateModal={isOpenAddProcessTemplateModal}
          hideAddProcessTemplateModal={hideAddProcessTemplateModal}
          adaptedDimensionUnits={unitsWithLabel}
          documentList={documentList}
          handleDocumentList={handleDocumentList}
        />

        <EditProcessTemplateModal
          isOpenUpdateProcessTemplateModal={isOpenUpdateProcessTemplateModal}
          hideUpdateProcessTemplateModal={hideUpdateProcessTemplateModal}
          adaptedDimensionUnits={unitsWithLabel}
          documentList={documentList}
          handleDocumentList={handleDocumentList}
        />
      </Page>
    </FormikProvider>
  );
};

export { ProcessTemplates };
