import {
  EDitStockMaterialItem,
  StockMaterialItem,
  SuppliersItem,
} from "shared/api";

export const adaptStockBatchObjectToArray = (
  selectedStockBatch: StockMaterialItem | undefined
) => {
  const arrayWithStockBatch = selectedStockBatch
    ? [{ ...selectedStockBatch }]
    : [];
  return arrayWithStockBatch;
};

export const adaptMaterialStockBatches = (
  allMaterials: StockMaterialItem[]
) => {
  const adaptedMaterialStockBatches = allMaterials?.map((item) => ({
    label: item.material.name!,
    value: item.id!,
  }));
  return adaptedMaterialStockBatches || [];
};

export const adaptStockMaterialData = (
  values: EDitStockMaterialItem,
  isActiveDynamicTable: boolean
) => {
  const adaptedData = {
    id: isActiveDynamicTable ? values?.stockMaterial?.id : values.id,
    material: isActiveDynamicTable
      ? values?.stockMaterial?.material.id
      : values.material,
    batch_code: isActiveDynamicTable
      ? values?.stockMaterial?.batch_code
      : values.batch_code,
    is_quantity_in_tracker_table: values.is_quantity_in_tracker_table,
    quantity: isActiveDynamicTable
      ? values?.stockMaterial?.quantity
      : values.quantity,
    delivery_date: isActiveDynamicTable
      ? values?.stockMaterial?.delivery_date
      : values.delivery_date,
    status: isActiveDynamicTable
      ? values?.stockMaterial?.status
      : values.status,
    supplier: isActiveDynamicTable
      ? values?.stockMaterial?.supplier
      : (values?.supplier as SuppliersItem)?.id,
  };
  return adaptedData;
};
