import { useState } from "react";
import { DatePickerValue } from "shared/ui";

interface DateFilters {
  dateGreater: string | null;
  dateLesser: string | null;
  handleDateGreater: (date: DatePickerValue, dateString: string) => void;
  handleDateLesser: (date: DatePickerValue, dateString: string) => void;
  clearDates: () => void;
  isDataFilterActive: boolean;
}

export const useDateFilters = (): DateFilters => {
  const [dateGreater, setDateGreater] = useState<string | null>(null);
  const [dateLesser, setDateLesser] = useState<string | null>(null);

  const handleDateGreater = (_: DatePickerValue, dateString: string) => {
    setDateGreater(dateString);
  };

  const handleDateLesser = (_: DatePickerValue, dateString: string) => {
    setDateLesser(dateString);
  };

  const clearDates = () => {
    setDateGreater(null);
    setDateLesser(null);
  };

  const isDataFilterActive = !!dateGreater || !!dateLesser;

  return {
    dateGreater,
    dateLesser,
    handleDateGreater,
    handleDateLesser,
    clearDates,
    isDataFilterActive,
  };
};
