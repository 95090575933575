import { Alert, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MaterialStockItem, useGetMaterialItemsByIDQuery } from "shared/api";
import {
  useDateFilters,
  useDebouncedTableFilter,
  usePagination,
  useTableSorting,
} from "shared/hooks";
import {
  DateRange,
  RenderClearFiltersButton,
  TableSearchFilter,
} from "shared/ui";
import {
  sortDirections,
  MaterialItemsOrdering,
  expandMaterialItemsString,
  materialItemsColumn,
} from "shared/constants";
import dayjs from "dayjs";
import { FilterFilled } from "@ant-design/icons";

interface MaterialItemsTableProps {
  handleMaterialItemsRows: (materialItems: MaterialStockItem[]) => void;
  isEditMode?: boolean;
  selectedMaterialItems?: MaterialStockItem[];
  selectedMaterialItemsKeys: React.Key[];
  handleMaterialItemsKeys: (keys: React.Key[]) => void;
  selectedStockBatchesKeys?: React.Key[];
}

const MaterialItemsTable: FC<MaterialItemsTableProps> = ({
  handleMaterialItemsRows,
  isEditMode,
  selectedMaterialItems,
  selectedMaterialItemsKeys,
  handleMaterialItemsKeys,
  selectedStockBatchesKeys,
}) => {
  const { t } = useTranslation();

  const pagination = usePagination();

  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedSerialNumberValue,
    handleFilterChange: handleSerialNumberValue,
    value: serialNumberValue,
    clearFilterValue: clearSerialNumberValue,
  } = useDebouncedTableFilter("", 400);

  const {
    dateGreater,
    dateLesser,
    handleDateGreater,
    handleDateLesser,
    clearDates,
  } = useDateFilters();

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetMaterialItemsByIDQuery({
    offset,
    limit,
    ordering,
    expand: expandMaterialItemsString,
    serial_number__icontains: debouncedSerialNumberValue,
    material_stock_batch__in: selectedStockBatchesKeys as number[],
    timestamp__lte: dateLesser || "",
    timestamp__gte: dateGreater || "",
  });

  const isActiveDateFiltering = dateLesser?.length || dateGreater?.length;

  const noFilteringResults =
    !materialItems?.results?.length && serialNumberValue.length;

  const clearFilters = () => {
    clearSerialNumberValue();
    clearDates();
  };

  const { name, serialNumber, timestamp } = materialItemsColumn;

  const columns: ColumnsType<MaterialStockItem> = [
    {
      title: t(name.title),
      width: name.width,
      render: (record: MaterialStockItem) =>
        record?.material_stock_batch?.material?.name,
    },
    {
      title: t(timestamp.title),
      width: timestamp.width,
      render: (record: MaterialStockItem) =>
        dayjs.utc(record?.timestamp).format("YYYY-MM-DD"),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsOrdering.TIMESTAMP_ASC,
            MaterialItemsOrdering.TIMESTAMP_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterDropdown: () => {
        return (
          <DateRange
            title={t("quality.timestamp")}
            leftPickerTitle={t("quality.timestamp-lte")}
            rightPickerTitle={t("quality.timestamp-gte")}
            handlePickerChangeGreater={handleDateGreater}
            handlePickerChangeLesser={handleDateLesser}
          />
        );
      },
      filterIcon: (
        <FilterFilled
          className={isActiveDateFiltering ? "!text-blue-600" : ""}
        />
      ),
    },
    {
      title: t(serialNumber.title),
      width: serialNumber.width,
      render: (record: MaterialStockItem) => record?.serial_number,
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearSerialNumberValue}
          confirm={confirm}
          value={serialNumberValue}
          handleFilterChange={handleSerialNumberValue}
          visible={visible}
          placeholder={t("quality.filter-by-serial-number")!}
          title={t("quality.filter-by-serial-number")!}
        />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            MaterialItemsOrdering.SERIAL_NUMBER_ASC,
            MaterialItemsOrdering.SERIAL_NUMBER_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterIcon: (
        <FilterFilled
          className={serialNumberValue?.length ? "!text-yellow-600" : ""}
        />
      ),
    },
  ];

  const selectTableRows = {
    onChange: (
      _selectedRowKeys: React.Key[],
      selectedRows: MaterialStockItem[]
    ) => {
      handleMaterialItemsRows(selectedRows);
      handleMaterialItemsKeys(selectedRows.map((row) => row.id));
    },
    getCheckboxProps: (record: MaterialStockItem) => ({
      name: record?.material_stock_batch?.material?.name,
    }),
  };

  useEffect(() => {
    if (isEditMode && selectedMaterialItems?.length) {
      const keys = selectedMaterialItems.map((item) => item.id);
      handleMaterialItemsKeys(keys);
    }
  }, [isEditMode, selectedMaterialItems]);

  if (noFilteringResults) {
    return (
      <div className="mt-5">
        <RenderClearFiltersButton onClick={clearFilters} />

        <Alert
          type="warning"
          showIcon
          message={t("quality.no-material-items")}
          className="my-3"
        />
      </div>
    );
  }

  return (
    <>
      <Title level={5}>{t("quality.material-items")}</Title>

      <Table
        rowSelection={{
          type: "checkbox",
          columnWidth: "5%",
          selectedRowKeys: selectedMaterialItemsKeys,
          ...selectTableRows,
        }}
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={materialItems?.results}
        rowKey={(obj) => obj.id!}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: materialItems?.count,
        }}
      />
    </>
  );
};

export { MaterialItemsTable };
