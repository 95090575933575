import { FieldArray, useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormInput, FormSelect, TableButton } from "shared/ui";
import { AdaptedValues, AddProcessStepValues } from "shared/types";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import { initialValuesForOutputMaterialsRow } from "shared/constants";

interface OutputMaterialsDynamicFormProps {
  adaptedMaterials: AdaptedValues[];
  isLoadingMaterials: boolean;
}

const OutputMaterialsDynamicForm: FC<OutputMaterialsDynamicFormProps> = ({
  adaptedMaterials,
  isLoadingMaterials,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue, handleChange } =
    useFormikContext<AddProcessStepValues>();

  return (
    <FieldArray
      name="output_materials"
      render={(arrayHelpers) => (
        <div>
          {values.output_materials.map((material, index) => {
            const materialValue = values?.output_materials[index]?.material;
            const materialFieldName = `output_materials.${index}.material`;

            const defaultNumberOfItemsValue =
              values?.output_materials[index]?.default_number_of_items;
            const defaultNumberOfItemsFieldName = `output_materials.${index}.default_number_of_items`;

            return (
              <div className="relative flex gap-x-4" key={index}>
                <div className="w-full">
                  <Title level={5} className="!w-[45%] text-left">
                    {t("process-step.output-materials-name")}
                  </Title>
                  <FormSelect
                    value={materialValue}
                    placeholder={t(
                      "process-step.output-material-type-placeholder"
                    )}
                    fieldName={materialFieldName}
                    options={adaptedMaterials}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    multiple
                    showSearch
                    isLoading={isLoadingMaterials}
                  />
                </div>

                <div className="w-full">
                  <Title level={5} className="!mt-0 !w-[45%] text-left">
                    {t("process-step.default-number-of-items")}
                  </Title>

                  <FormInput
                    value={defaultNumberOfItemsValue}
                    fieldName={defaultNumberOfItemsFieldName}
                    placeholder={t("process-step.default-number-of-items")}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    numberType
                  />
                </div>

                <TableButton
                  type="delete"
                  className="mt-10"
                  onClick={() => arrayHelpers.remove(index)}
                />
              </div>
            );
          })}
          <Button
            type="primary"
            className="btn-primary--dark mt-1"
            onClick={() =>
              arrayHelpers.push(initialValuesForOutputMaterialsRow)
            }
          >
            {t("process-step.add-output-material")}
          </Button>
        </div>
      )}
    />
  );
};

export { OutputMaterialsDynamicForm };
