import React, { FC } from "react";
import { ReportItem } from "shared/api";
import { DatesBlock } from "./DatesBlock";

interface MultipleOrderFilteringProps {
  selectedReport?: ReportItem;
}

const MultipleOrderFiltering: FC<MultipleOrderFilteringProps> = ({
  selectedReport,
}) => {
  return <DatesBlock selectedReport={selectedReport} />;
};

export { MultipleOrderFiltering };
