import { SubmitCreateMaterialValues } from "shared/types";
import { adaptSuppliersToUpdateMaterial } from "shared/adapters";
import {
  MaterialsItem,
  OrderStepTrackerResponseItem,
  SupplierItemWithCost,
  SupplierToUpdateMaterial,
} from "shared/api";
import { addThousandsSeparator } from "../global";

export interface MaterialCategory {
  id: number | null;
  name?: string | null;
  unit?: number | null;
}

export const handleHyperlinkMaterialToEdit = (
  material: MaterialsItem | null,
  setValues: (
    values: React.SetStateAction<SubmitCreateMaterialValues>,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const materialCategory = material?.material_category as MaterialCategory;

  if (material) {
    setValues({
      ...material,
      material_category: materialCategory,
      quantity: null,
    });
  }
};

export const returnUpdateMaterialParams = (
  values: SubmitCreateMaterialValues,
  dndSuppliers: SupplierItemWithCost[]
) => {
  return {
    id: values.id!,
    name: values.name!,
    material_category: values.material_category.id!,
    suppliers: adaptSuppliersToUpdateMaterial(
      dndSuppliers
    ) as SupplierToUpdateMaterial[],
    target_quantity_in_stock: values.target_quantity_in_stock!,
    n_packaging_items: values.n_packaging_items!,
    expanded_label_name: values.expanded_label_name!,
  };
};

export const renderQuantityWithAbbreviation = (
  calc_total_original_quantity: number | null,
  calc_total_consumed_quantity: number | null,
  calc_total_remaining_quantity: number | null,
  unitAbbreviation: string | null
) => {
  let message;

  if (calc_total_original_quantity !== null) {
    message = `${addThousandsSeparator(
      calc_total_original_quantity
    )} - ${unitAbbreviation}`;
  }

  if (calc_total_consumed_quantity !== null) {
    message = `${addThousandsSeparator(
      calc_total_consumed_quantity
    )} - ${unitAbbreviation}`;
  }

  if (calc_total_remaining_quantity !== null) {
    message = `${addThousandsSeparator(
      calc_total_remaining_quantity
    )} - ${unitAbbreviation}`;
  }

  return message;
};

export const getProductionPageInputMaterialTitle = (
  inputIndex: number,
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const materialName =
    orderStepTrackerResponseItem?.process_step?.input_materials?.[inputIndex]
      ?.material?.name;

  const materialCategoryName =
    orderStepTrackerResponseItem?.process_step?.input_materials?.[inputIndex]
      ?.material?.material_category?.name;

  const materialTitle = `${materialCategoryName}: ${materialName}`;

  return materialTitle;
};
