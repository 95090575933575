import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import { MaterialStockItem } from "shared/api";
import {
  columnsMaterialItemsData,
  MaterialItemsSorting,
  sortDirections,
} from "shared/constants";
import {
  renderMaterialItemSituation,
  renderMaterialItemsStatuses,
  renderQuantityWithAbbreviation,
} from "shared/helpers";
import { GlobalMaterialItem } from "shared/types";
import { TableButton, TableSearchFilter } from "shared/ui";

const {
  materialName,
  serialNumber,
  actions,
  calcPercentageRemainingQuantity,
  calcTotalConsumedQuantity,
  calcTotalOriginalQuantity,
  calcTotalRemainingQuantity,
  status,
  situation,
} = columnsMaterialItemsData;

interface MaterialItemsColumnInMaterialManagementProps {
  t: TFunction;
  unitAbbreviation: string;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  clearStatusInValue: () => void;
  statusInValue: string;
  handledStatusIm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editStockMaterialHandler: (record: MaterialStockItem) => void;
  showDeleteCategoryModal: (record: MaterialStockItem) => void;
}

export const useMaterialItemsColumnInMaterialManagement = ({
  t,
  unitAbbreviation,
  changeSort,
  clearStatusInValue,
  statusInValue,
  handledStatusIm,
  editStockMaterialHandler,
  showDeleteCategoryModal,
}: MaterialItemsColumnInMaterialManagementProps): ColumnsType<MaterialStockItem> => [
  {
    title: t(materialName.title),
    render: (item: MaterialStockItem) => (
      <TableButton
        type="title"
        title={item?.material_stock_batch?.material?.name}
        onClick={() => editStockMaterialHandler(item)}
      />
    ),
  },
  {
    title: t(serialNumber.title),
    dataIndex: serialNumber.dataIndex,
    key: serialNumber.key,
  },
  {
    title: t(calcTotalOriginalQuantity.title),
    render: (item: GlobalMaterialItem) =>
      renderQuantityWithAbbreviation(
        item.calc_total_original_quantity!,
        null,
        null,
        unitAbbreviation
      ),
  },
  {
    title: t(calcTotalConsumedQuantity.title),
    render: (item: GlobalMaterialItem) =>
      renderQuantityWithAbbreviation(
        null,
        item.calc_total_consumed_quantity!,
        null,
        unitAbbreviation
      ),
  },
  {
    title: t(calcTotalRemainingQuantity.title),
    render: (item: GlobalMaterialItem) =>
      renderQuantityWithAbbreviation(
        null,
        null,
        item.calc_total_remaining_quantity!,
        unitAbbreviation
      ),
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
          MaterialItemsSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(calcPercentageRemainingQuantity.title),
    render: (item: GlobalMaterialItem) =>
      `${item.calc_percentage_remaining_quantity}%`,
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_ASC,
          MaterialItemsSorting.CALC_PERCENTAGE_REMAINING_DESC
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(status.title),
    render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          MaterialItemsSorting.STATUS_ASC,
          MaterialItemsSorting.STATUS_DESC
        );
      },
    }),
    sorter: true,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearStatusInValue}
        confirm={confirm}
        value={statusInValue!}
        handleFilterChange={handledStatusIm!}
        visible={visible}
        placeholder={t("orders.filter-by-status-placeholder")!}
        title={t("orders.filter-by-status")!}
      />
    ),
  },
  {
    title: t(situation.title),
    render: (item: MaterialStockItem) =>
      renderMaterialItemSituation(item.situation as string),
  },
  {
    title: t(actions.title),
    key: actions.key,
    render: (record: MaterialStockItem) => {
      return (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="delete"
            onClick={() => showDeleteCategoryModal(record)}
          />
        </div>
      );
    },
  },
];
