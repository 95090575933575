import {
  AddDynamicOrderSteps,
  InitialInputMaterials,
  AdaptedValues,
} from "shared/types";
import { AddProductionRequest } from "pages/Orders/Orders/UI/ProcessStepProduction/ProcessStepProduction";
import {
  ConfigDynamicTrackerFields,
  MaterialStockItem,
  OrderStatuses,
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
  StockMaterialItem,
} from "shared/api";
import * as Yup from "yup";
import { ConsumptionTypes } from "shared/constants";
import { renderMaterialItemsStatuses } from "../materialStockItem";

export const getChoicesValidation = () => {
  return Yup.mixed().test(
    "unit-validation",
    "Unit is required",
    // eslint-disable-next-line func-names
    function (value) {
      const type = this.resolve(Yup.ref("type"));

      if (type === "integer") {
        return Yup.number().required().isValidSync(value);
      }

      if (type === "choices" && Array.isArray(value)) {
        const arraySchema = Yup.array()
          .min(1, "At least one choice is required")
          .required("Required");

        if (Array.isArray(value)) {
          return arraySchema.isValidSync(value);
        }
        return arraySchema.isValidSync([value]);
      }

      return Yup.number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable()
        .isValidSync(value);
    }
  );
};

export const createDynamicChoicesValidation = () => {
  return Yup.mixed().when("type", {
    is: "choices",
    then: getChoicesValidation(),
    otherwise: Yup.mixed().notRequired(),
  });
};

export const createDynamicUnitValidation = () => {
  return Yup.mixed().when("type", {
    is: (value: string) => value === "float" || value === "integer",
    then: Yup.number().required("Unit is required"),
    otherwise: Yup.mixed().notRequired(),
  });
};

export const copyDynamicValuesForTheFirstMaterialAfterChangeItemsCount = (
  values: AddProductionRequest,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const outputMaterials = values?.output_materials;

  if (outputMaterials) {
    const firstMaterial = outputMaterials[0];
    const numberOfMaterialItems = firstMaterial?.numberOfMaterialItems;
    const dynamicDataLength = firstMaterial?.dynamicData?.length;
    const materialDynamicData = firstMaterial?.dynamicData;

    const isNumberOfMaterialItemsExceedsDynamicDataLength =
      dynamicDataLength &&
      numberOfMaterialItems &&
      numberOfMaterialItems > dynamicDataLength;

    if (isNumberOfMaterialItemsExceedsDynamicDataLength) {
      let lastItem;

      if (materialDynamicData) {
        lastItem = { ...materialDynamicData[dynamicDataLength - 1] };
      }

      if (materialDynamicData && lastItem) {
        firstMaterial.dynamicData = [
          ...materialDynamicData,
          ...Array(numberOfMaterialItems - dynamicDataLength).fill(lastItem),
        ];
      }
      setFieldValue("output_materials", outputMaterials);
    }
  }
};

export const copyDynamicValuesForTheSecondMaterialAfterChangeItemsCount = (
  values: AddProductionRequest,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const outputMaterials = values?.output_materials;

  if (outputMaterials) {
    const secondMaterial = outputMaterials[1];
    const numberOfMaterialItems = secondMaterial?.numberOfMaterialItems;
    const dynamicDataLength = secondMaterial?.dynamicData?.length;
    const materialDynamicData = secondMaterial?.dynamicData;

    const isNumberOfMaterialItemsExceedsDynamicDataLength =
      dynamicDataLength &&
      numberOfMaterialItems &&
      numberOfMaterialItems > dynamicDataLength;

    if (isNumberOfMaterialItemsExceedsDynamicDataLength) {
      let lastItem;

      if (materialDynamicData) {
        lastItem = { ...materialDynamicData[dynamicDataLength - 1] };
      }

      if (materialDynamicData && lastItem) {
        secondMaterial.dynamicData = [
          ...materialDynamicData,
          ...Array(numberOfMaterialItems - dynamicDataLength).fill(lastItem),
        ];
      }
      setFieldValue("output_materials", outputMaterials);
    }
  }
};

export const disableRequestButton = (values: AddDynamicOrderSteps): boolean => {
  const isEmptyInputMaterials = (values.input_materials ?? [])?.some(
    (inputMaterial) =>
      !inputMaterial.selectValue || !inputMaterial.fields?.[0]?.quantity
  );

  const isEmptyOutputMaterialsDynamicData = (
    values.output_materials ?? []
  ).some((material) => {
    const isDynamicDataUndefined = material?.dynamicData === undefined;

    const isDynamicDataEmpty = material?.dynamicData?.length === 0;

    const isAnyFieldEmpty = material?.dynamicData?.some((field) => {
      const isQuantityEmpty = !field?.quantity?.toString()?.length;

      const isStatusUndefined = field?.status === undefined;

      const isStatusEmpty = !field?.status?.toString().length;

      return isQuantityEmpty || isStatusUndefined || isStatusEmpty;
    });

    return isDynamicDataUndefined || isDynamicDataEmpty || isAnyFieldEmpty;
  });

  const isDynamicTrackerFieldsUndefined =
    values?.output_materials?.some((material) =>
      material?.dynamicData?.some(
        (material) => material?.dynamic_tracker_fields === undefined
      )
    ) ?? false;

  const isAnyFieldInDynamicTrackerFieldsEmpty =
    values?.output_materials?.some((material) =>
      material?.dynamicData?.some(
        (dynamicData) =>
          dynamicData?.dynamic_tracker_fields &&
          Object.values(dynamicData?.dynamic_tracker_fields).some(
            (dynamicField) =>
              Object.values(dynamicField).some(
                (value) => value === undefined || !value?.toString()?.length
              )
          )
      )
    ) ?? false;

  const isEmptyOutputMaterials =
    isEmptyOutputMaterialsDynamicData ||
    isDynamicTrackerFieldsUndefined ||
    isAnyFieldInDynamicTrackerFieldsEmpty;

  return isEmptyInputMaterials || isEmptyOutputMaterials;
};

export const adaptChoicesForProdPageDynamicFields = (choices: string[]) => {
  const adaptedChoices = choices.map((choice) => ({
    value: choice,
    label: choice,
  }));

  return adaptedChoices;
};

export const renderDynamicPlaceholder = (
  values: AddDynamicOrderSteps,
  inputIndex: number,
  fieldIndex: number,
  dynamicBatchesValues: number
) => {
  const selectValue =
    values?.input_materials?.[inputIndex]?.selectValue?.[fieldIndex];

  switch (selectValue) {
    case "material_item":
      return "Select Material Item";

    case "material_stock_batch":
      return dynamicBatchesValues > 0
        ? "Select Material Stock Batch"
        : "No material stock batches";

    default:
      return "Select value";
  }
};

export interface Unit {
  id: number;
  dimension: number;
  name: string;
  abbreviation: string;
}

export const renderDynamicSelectValues = (
  values: AddProductionRequest,
  inputIndex: number,
  fieldIndex: number,
  adaptedMaterials: AdaptedValues[],
  adaptedMaterialItems: AdaptedValues[],
  adaptedStockMaterialBatches: AdaptedValues[],
  adaptedInitialInputMaterials: InitialInputMaterials,
  materialStockBatches: StockMaterialItem[],
  materialItems: MaterialStockItem[]
) => {
  const selectValue =
    values.input_materials?.[inputIndex]?.fields?.[fieldIndex].selectValue;

  switch (selectValue) {
    case "material":
      return adaptedMaterials;

    case "material_item":
      // eslint-disable-next-line no-case-declarations
      const dynamicMaterialItemsValues = materialItems?.filter(
        (item) =>
          item.material_stock_batch.material.id ===
          adaptedInitialInputMaterials?.id
      );

      // eslint-disable-next-line no-case-declarations
      const adaptedMaterialItems = dynamicMaterialItemsValues
        ?.filter((item) => item?.calc_total_remaining_quantity !== 0)
        .map((item) => {
          const itemStatus = renderMaterialItemsStatuses(item);
          const remainingValue = `rem. - ${item?.calc_total_remaining_quantity}`;
          const unit = item?.material_stock_batch?.material?.material_category
            ?.unit as unknown as Unit;
          const unitAbbreviation = unit?.abbreviation;

          const label = `${item?.serial_number} (${itemStatus}) ${remainingValue}${unitAbbreviation}`;
          const value = item?.id;

          return { label, value };
        });

      return adaptedMaterialItems;

    case "material_stock_batch":
      // eslint-disable-next-line no-case-declarations
      const dynamicBatchValues = materialStockBatches?.filter(
        (item) => item.material.id === adaptedInitialInputMaterials?.id
      );

      return dynamicBatchValues?.map((item) => ({
        label: item?.batch_code,
        value: item?.id,
      }));

    default:
      return [];
  }
};

export const hideSelectValueDropdown = (
  values: AddProductionRequest,
  inputIndex: number,
  fieldIndex: number
) =>
  values?.input_materials?.[inputIndex]?.selectValue?.[fieldIndex] ===
  "material";

export const disableQuantityField = (
  values: AddDynamicOrderSteps,
  inputIndex: number,
  fieldIndex: number
) =>
  !values.input_materials?.[inputIndex]?.fields?.[0]?.variant &&
  values.input_materials?.[inputIndex]?.fields?.[fieldIndex].selectValue !==
    "material";

export const showVariantDropDown = (
  values: AddProductionRequest,
  index: number,
  inputIndex: number,
  fieldIndex: number
) => index === 0 && !hideSelectValueDropdown(values, inputIndex, fieldIndex);

export const adaptInputMaterialValues = (
  values: AddProductionRequest,
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem
) => {
  const adaptedValues = values?.input_materials?.map((item) => {
    const fieldsData = item?.fields?.map((field) => {
      const dynamicQuantity = field?.quantity;
      const dynamicVariant = field?.variant;
      const selectedVariant = field?.selectValue;

      return {
        quantity: dynamicQuantity || null,
        variant: dynamicVariant || null,
        selectValue: selectedVariant,
      };
    });

    const data = {
      consumptionType: item?.consumption_type,
      consumedProportionalId: item?.consumption_proportional_material?.id,
      consumedProportionalValue: item?.consumption_proportional_value,
      consumedFixedValue: item?.consumption_fixed_value,
      fieldsData,
    };

    return data;
  });

  const adaptedRequestData = adaptedValues?.flatMap((item) => {
    return item?.fieldsData?.map((fieldData) => {
      const currentVariant = fieldData?.selectValue;

      const getQuantity = () => {
        if (
          item?.consumptionType === ConsumptionTypes.FI ||
          item?.consumptionType === ConsumptionTypes.PR
        ) {
          return null;
        }

        return fieldData?.quantity;
      };

      const getMaterialId = () => {
        if (currentVariant === "material") {
          return orderStepTrackerResponseItem?.process_step
            ?.input_materials?.[0]?.material?.id;
        }

        if (
          currentVariant === "material_item" ||
          currentVariant === "material_stock_batch"
        ) {
          return fieldData?.variant;
        }

        return null;
      };

      return {
        id: getMaterialId(),
        variant: currentVariant,
        quantity: getQuantity(),
      };
    });
  });

  return adaptedRequestData?.flat();
};

export const checkOnEmptyVariant = (values: AddProductionRequest) => {
  const isEmpty = values?.input_materials?.some(
    (material, index) => material?.fields?.[index]?.variant === null
  );

  return isEmpty;
};

export const updateAdaptedInitialInputMaterials = (
  inputIndex: number,
  setAdaptedInitialInputMaterials: (
    value: React.SetStateAction<InitialInputMaterials[]>
  ) => void
) => {
  setAdaptedInitialInputMaterials((prevState) => {
    let newState = [...prevState];

    if (newState[inputIndex]) {
      newState[inputIndex].fields = [
        ...(newState[inputIndex].fields || []),
        {
          variant: null,
          quantity: null,
        },
      ];
    } else {
      newState = [
        ...newState,
        {
          fields: [
            {
              variant: null,
              quantity: null,
            },
          ],
        },
      ];
    }

    return newState;
  });
};

export const updateAdaptedInitialInputMaterialsRemove = (
  index: number,
  fieldIndex: number,
  setAdaptedInitialInputMaterials: (
    value: React.SetStateAction<InitialInputMaterials[]>
  ) => void
) => {
  setAdaptedInitialInputMaterials((prevState) => {
    const newState = [...prevState];

    if (newState[index]) {
      newState[index].fields.splice(fieldIndex, 1);
    }

    return newState;
  });
};

export const enum DynamicTypes {
  INTEGER = "integer",
  FLOAT = "float",
  TEXT = "text",
  CHOICES = "choices",
}

export const dynamicFormInputTypes: Record<DynamicTypes, string> = {
  [DynamicTypes.INTEGER]: "Number",
  [DynamicTypes.FLOAT]: "Decimal Number",
  [DynamicTypes.TEXT]: "Text",
  [DynamicTypes.CHOICES]: "Choice",
};

export const renderDynamicInputType = (type: string) => {
  const status = dynamicFormInputTypes[type as DynamicTypes];
  return status || "Unknown Type";
};

export const enum DynamicStatuses {
  PRIMA = "P",
  WASTED = "W",
  INVESTIGATION = "I",
}

export type DynamicStatusesType = "success" | "error" | "warning" | "default";

export const dynamicDropdownStyles: Record<DynamicStatuses, string> = {
  [DynamicStatuses.PRIMA]: "success",
  [DynamicStatuses.WASTED]: "error",
  [DynamicStatuses.INVESTIGATION]: "warning",
};

export const colorSelectedStatusDropdown = (
  status: string
): DynamicStatusesType => {
  const color = dynamicDropdownStyles[status as DynamicStatuses];

  return (color as DynamicStatusesType) || "warning";
};

const countStatus = (
  outputMaterials: ProductionOutputMaterial[],
  status: string
) =>
  outputMaterials
    ?.flatMap((material) => material?.dynamicData)
    ?.filter((dynamicData) => dynamicData?.status === status)?.length ?? 0;

export const showStatusesSummary = (
  outputMaterials: ProductionOutputMaterial[]
) => ({
  primasCount: countStatus(outputMaterials, "P"),
  wastedCount: countStatus(outputMaterials, "W"),
  investigateCount: countStatus(outputMaterials, "I"),
});

export const orderStatusColors = [
  "#F1EB9A",
  "#FFF",
  "#a3f7c0",
  "#a3f7c0",
  "#F68787",
  "#FFF",
];

export const dynamicOrderDropdownStyles: Record<OrderStatuses, string> = {
  [OrderStatuses.DE]: "success",
  [OrderStatuses.CO]: "success",
  [OrderStatuses.AC]: "warning",
  [OrderStatuses.PE]: "error",
  [OrderStatuses.AP]: "default",
  [OrderStatuses.PO]: "default",
};

export const colorOrderStatusDropdown = (status: string) => {
  const color = dynamicOrderDropdownStyles[status as OrderStatuses];

  return (color as DynamicStatusesType) || "default";
};

export const colorOrderStatusButton = (status: OrderStatuses) => {
  switch (status) {
    case OrderStatuses.PE:
      return "bg-red-200";

    case OrderStatuses.DE:
    case OrderStatuses.CO:
      return "bg-green-50";

    case OrderStatuses.AC:
      return "bg-yellow-100";

    default:
      return "";
  }
};

export const getDynamicTrackerFields = (
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined,
  inputIndex: number
) => {
  return (
    orderStepTrackerResponseItem?.process_step.output_materials?.[inputIndex]
      ?.material?.material_category?.config_dynamic_tracker_fields ?? []
  );
};

export const getSoftAndHardValues = (
  cardForSoftHardValues: ConfigDynamicTrackerFields,
  fieldIndex: number,
  value?: string | number
) => {
  const softMinimum =
    cardForSoftHardValues.fields?.[fieldIndex]?.soft_minimum ?? 0;
  const softMaximum =
    cardForSoftHardValues.fields?.[fieldIndex]?.soft_maximum ?? 0;
  const hardMinimum =
    cardForSoftHardValues.fields?.[fieldIndex]?.hard_minimum ?? 0;
  const hardMaximum =
    cardForSoftHardValues.fields?.[fieldIndex]?.hard_maximum ?? 0;

  const hideSoftHardValues =
    !softMaximum && !softMinimum && !hardMaximum && !hardMinimum;

  const valueAsNumber = Number(value);

  const lessThenSoftMin = valueAsNumber < softMinimum;
  const moreThenSoftMax = valueAsNumber > softMaximum;

  const lessThenHardMin = valueAsNumber < hardMinimum;
  const moreThenHardMax = valueAsNumber > hardMaximum;

  const disableDynamicStyling = value === "" || hideSoftHardValues;
  const errorStyling = lessThenHardMin || moreThenHardMax;
  const warningStyling =
    (lessThenSoftMin && !lessThenHardMin) ||
    (moreThenSoftMax && !moreThenHardMax);

  return {
    softMinimum,
    softMaximum,
    hardMinimum,
    hardMaximum,
    hideSoftHardValues,
    lessThenSoftMin,
    moreThenSoftMax,
    disableDynamicStyling,
    errorStyling,
    warningStyling,
  };
};
