import { InitialOutputMaterials } from "shared/types";
import { useEffect, useState } from "react";
import { adaptOutputMaterialValues } from "shared/adapters";
import { OrderStepTrackerResponseItem } from "shared/api";

interface OutputMaterialsProductionPageDataProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const useOutputMaterialsProductionPageData = ({
  orderStepTrackerResponseItem,
  setFieldValue,
}: OutputMaterialsProductionPageDataProps) => {
  const outputMaterials =
    orderStepTrackerResponseItem?.process_step.output_materials;

  const [adaptedInitialOutputMaterials, setAdaptedInitialOutputMaterials] =
    useState<InitialOutputMaterials[]>([]);

  useEffect(() => {
    if (orderStepTrackerResponseItem) {
      const adaptedInitialOutputMaterials: InitialOutputMaterials[] =
        adaptOutputMaterialValues(orderStepTrackerResponseItem);

      setAdaptedInitialOutputMaterials(adaptedInitialOutputMaterials);
    }
  }, [orderStepTrackerResponseItem]);

  useEffect(() => {
    if (adaptedInitialOutputMaterials) {
      setFieldValue("output_materials", adaptedInitialOutputMaterials);
    }
  }, [adaptedInitialOutputMaterials]);

  return {
    adaptedInitialOutputMaterials,
    outputMaterials,
  };
};
