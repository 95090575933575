import { Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useStandAloneRequests } from "shared/hooks";
import { Colors } from "shared/themes";
import { StandAloneFormik } from "shared/types";
import { FormInput } from "shared/ui";

interface EditStandAloneItemDialogProps {
  isOpen: boolean;
  onCancel: () => void;
}

const EditStandAloneItemDialog: FC<EditStandAloneItemDialogProps> = ({
  isOpen,
  onCancel,
}) => {
  const { t } = useTranslation("", { keyPrefix: "standAlone" });
  const [api, contextHolder] = notification.useNotification();

  const { values, handleBlur, handleChange, resetForm, isValid, errors } =
    useFormikContext<StandAloneFormik>();

  const closeDialogAndResetValues = () => {
    onCancel();
    resetForm();
  };

  const { updateStandAloneHandler } = useStandAloneRequests({
    api,
    closeDialog: closeDialogAndResetValues,
  });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={onCancel}
        onOk={() =>
          updateStandAloneHandler({
            id: values.id!,
            name: values.name!,
            description: values.description!,
            url: values.url!,
            ...(values.token && { token: values.token! }),
            bucket: values.bucket ? values.bucket : null,
            org: values.org ? values.org : null,
          })
        }
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("namePlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("description")}
        </Title>

        <FormInput
          value={values.description}
          fieldName="description"
          placeholder={t("descriptionPlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.description}
          isError={!!errors.description}
        />

        <Title level={5} className="mb-1.25">
          {t("url")}
        </Title>

        <FormInput
          value={values.url}
          fieldName="url"
          placeholder={t("urlPlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.url}
          isError={!!errors.url}
        />

        <Title level={5} className="mb-1.25">
          {t("replaceToken")}
        </Title>

        <FormInput
          value={values.token}
          fieldName="token"
          placeholder={t("tokenPlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.token}
          isError={!!errors.token}
        />

        <Title level={5} className="mb-1.25">
          {t("bucket")}
        </Title>

        <FormInput
          value={values.bucket}
          fieldName="bucket"
          placeholder={t("bucketPlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Title level={5} className="mb-1.25">
          {t("org")}
        </Title>

        <FormInput
          value={values.org}
          fieldName="org"
          placeholder={t("orgPlaceholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </Modal>
    </>
  );
};

export { EditStandAloneItemDialog };
