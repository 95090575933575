import { Button, Modal } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { noop } from "shared/helpers";
import { adaptMaterials } from "shared/adapters";
import { useGetAllMaterialsNoPaginationQuery, PackagingItem } from "shared/api";
import { useModal } from "shared/hooks";
import { ModalSizeValue, initialPackageValues } from "shared/constants";

import { AddOrUpdatePackageSchema } from "shared/schemas";
import { PackagingTable } from "./UI/PackagingTable";
import { AddPackagingModal } from "./UI/AddPackagingModal";
import { EditPackagingModal } from "./UI/EditPackagingModal";
import { ExportLabelModal } from "./UI/ExportLabelModal";

interface PackagesModalProps {
  isOpenModal: boolean;
  hidePackagesModal: () => void;
  orderID: number | null;
  orderCode: string | null;
}

const PackagesModal: FC<PackagesModalProps> = ({
  isOpenModal,
  hidePackagesModal,
  orderID,
  orderCode,
}) => {
  const { t } = useTranslation();

  const [materialFilter, setMaterialFilter] = useState<number | string>("");

  const handleMaterialFilter = (value: number | string) =>
    setMaterialFilter(value);

  const [
    isOpenAddPackagingModal,
    showOpenAddPackagingModal,
    hideOpenAddPackagingModal,
  ] = useModal();

  const [
    isEditAddPackagingModal,
    showEditAddPackagingModal,
    hideEditAddPackagingModal,
  ] = useModal();

  const [
    isOpenExportLabelModal,
    showOpenExportLabelModal,
    hideOpenExportLabelModal,
  ] = useModal();

  const addOrUpdatePackageFormik = useFormik({
    initialValues: initialPackageValues,
    validationSchema: AddOrUpdatePackageSchema,
    onSubmit: noop,
  });

  const { setFieldValue } = addOrUpdatePackageFormik;

  const closeModal = () => {
    hidePackagesModal();
    setFieldValue("order", null);
  };

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery();

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials || []);
  }, [materials]);

  useEffect(() => {
    if (orderID) {
      addOrUpdatePackageFormik.setFieldValue("order", orderID);
    }
  }, [orderID]);

  const [packageMaterialItems, setPackageMaterialItems] =
    useState<PackagingItem | null>(null);

  return (
    <Modal
      title={`${t("packaging.packages-for-order")} ${orderCode}`}
      open={isOpenModal}
      width={ModalSizeValue.LARGE}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      onCancel={closeModal}
    >
      <FormikProvider value={addOrUpdatePackageFormik}>
        <div className="w-full h-full flex">
          <div className="w-full">
            <div>
              <div className="flex justify-between items-center">
                <Button
                  type="primary"
                  className="btn-primary--dark"
                  onClick={showOpenAddPackagingModal}
                >
                  {t("packaging.add-item")}
                  <PlusOutlined />
                </Button>
              </div>

              <PackagingTable
                showEditAddPackagingModal={showEditAddPackagingModal}
                showOpenExportLabelModal={showOpenExportLabelModal}
                orderID={orderID}
                setPackageMaterialItems={setPackageMaterialItems}
                materialFilter={materialFilter}
                handleMaterialFilter={handleMaterialFilter}
                orderCode={orderCode}
              />

              <AddPackagingModal
                hideOpenAddPackagingModal={hideOpenAddPackagingModal}
                isOpenModal={isOpenAddPackagingModal}
                adaptedMaterials={adaptedMaterials}
                orderID={orderID}
                isLoadingMaterials={isLoadingMaterials}
              />

              <EditPackagingModal
                isOpenModal={isEditAddPackagingModal}
                hideEditAddPackagingModal={hideEditAddPackagingModal}
                adaptedMaterials={adaptedMaterials}
                orderID={orderID}
                isLoadingMaterials={isLoadingMaterials}
              />

              <ExportLabelModal
                isOpenModal={isOpenExportLabelModal}
                hideOpenExportLabelModal={hideOpenExportLabelModal}
                packageMaterialItems={packageMaterialItems}
              />
            </div>
          </div>
        </div>
      </FormikProvider>
    </Modal>
  );
};

export { PackagesModal };
