import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { Loader, Sidebar } from "shared/ui";
import { adaptMachinesToGroup } from "shared/adapters";
import { OrderPlan, useGetOrderPlansWithoutParamsQuery } from "shared/api";
import { orderPlansParams } from "shared/constants";
import { useGetAllMachinesWithNoParamsQuery } from "../../../../shared/api/productCard/productCardApi";
import { TimelineScheduler } from "./TimelineScheduler";

interface TimelinePageProps {
  isOpenSidebar: boolean;
}

const TimelinePage: FC<TimelinePageProps> = ({ isOpenSidebar }) => {
  const { id } = useParams();

  const { data: machines, isLoading } = useGetAllMachinesWithNoParamsQuery();

  const groups = useMemo(() => {
    return adaptMachinesToGroup(machines || []);
  }, [machines]);

  const [selectedOrderPlan, setSelectedOrderPlan] = useState<OrderPlan | null>(
    null
  );

  const { data: orderPlans } = useGetOrderPlansWithoutParamsQuery({
    expand: orderPlansParams.expand,
  });

  const filteredOrder = useMemo(
    () => orderPlans?.find((order) => order.id === Number(id)),
    [orderPlans, id]
  );

  useEffect(() => {
    if (filteredOrder) {
      setSelectedOrderPlan(filteredOrder);
    }
  }, [orderPlans]);

  return (
    <div className="w-full h-full flex">
      <Sidebar isOpenSidebar={isOpenSidebar} />

      <div className="w-full">
        <div className="p-3.75">
          <Title className="capitalize">Planner</Title>

          {!isLoading ? (
            <TimelineScheduler
              groups={groups}
              selectedOrderPlan={selectedOrderPlan}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export { TimelinePage };
