import { Badge, Select } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProductionOutputMaterial } from "shared/api";

interface UpdatedStatusInputProps {
  notDyNamicValues: ProductionOutputMaterial;
  inputIndex: number;
  memoizedSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const UpdatedStatusInput: FC<UpdatedStatusInputProps> = ({
  notDyNamicValues,
  inputIndex,
  memoizedSetFieldValue,
}) => {
  const { t } = useTranslation();

  const statusColors = {
    prima: {
      backgroundColor: "#49CB90",
      marginBottom: "5px",
    },
    wasted: {
      backgroundColor: "#F68787",
      marginBottom: "5px",
    },
    investigation: {
      backgroundColor: "#F1EB9A",
    },
  };

  const statusValue = notDyNamicValues?.dynamicData?.[0]?.status;
  const fieldName = `output_materials.${inputIndex}.dynamicData.${0}.status`;

  return (
    <div>
      <Title level={5}>Status</Title>
      <Badge.Ribbon color="red" text="" className="!right-[1px]">
        <Select
          value={statusValue}
          className={classNames("mt-1 w-full", {
            "custom-select-error": statusValue === "W",
            "custom-select-success": statusValue === "P",
            "custom-select-warning": statusValue === "I",
          })}
          placeholder={t("orders.status")}
          onChange={(value) => {
            memoizedSetFieldValue(fieldName, value);
          }}
        >
          <Select.Option value="P" style={statusColors.prima}>
            {t("orders.prima")}
          </Select.Option>

          <Select.Option value="W" style={statusColors.wasted}>
            {t("orders.wasted")}
          </Select.Option>

          <Select.Option value="I" style={statusColors.investigation}>
            {t("orders.investigation")}
          </Select.Option>
        </Select>
      </Badge.Ribbon>
    </div>
  );
};

export { UpdatedStatusInput };
