import { Badge, Input, Popover } from "antd";
import classNames from "classnames";
import React, { FC, useMemo } from "react";
import { ConfigDynamicTrackerFields, DynamicKeyValue } from "shared/api";
import { SoftHardValues } from "shared/ui";

interface UpdateOutputMaterialNumberInputProps {
  fieldName: string;
  fieldValue: DynamicKeyValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  section: ConfigDynamicTrackerFields;
  cardIndex: number;
  placeholder: string;
}

const UpdateOutputMaterialNumberInput: FC<
  UpdateOutputMaterialNumberInputProps
> = ({
  fieldName,
  fieldValue,
  setFieldValue,
  section,
  cardIndex,
  placeholder,
}) => {
  const currentField = section?.fields?.[cardIndex];

  const { softMaximum, softMinimum, hardMinimum, hardMaximum, isRequired } =
    useMemo(
      () => ({
        softMaximum: currentField?.soft_maximum,
        softMinimum: currentField?.soft_minimum,
        hardMinimum: currentField?.hard_minimum,
        hardMaximum: currentField?.hard_maximum,
        isRequired: currentField?.required,
      }),
      [currentField]
    );

  const {
    lessThenSoftMin,
    moreThenSoftMax,
    lessThenHardMin,
    moreThenHardMax,
    disableDynamicStyling,
  } = useMemo(
    () => ({
      lessThenSoftMin: softMinimum && Number(fieldValue) < softMinimum,
      moreThenSoftMax: softMaximum && Number(fieldValue) > softMaximum,
      lessThenHardMin: hardMinimum && Number(fieldValue) < hardMinimum,
      moreThenHardMax: hardMaximum && Number(fieldValue) > hardMaximum,
      disableDynamicStyling: fieldValue === "",
    }),
    [fieldValue, softMinimum, softMaximum, hardMinimum, hardMaximum]
  );

  const { errorStyling, warningStyling } = useMemo(
    () => ({
      errorStyling: lessThenHardMin || moreThenHardMax,
      warningStyling:
        (lessThenSoftMin && !lessThenHardMin) ||
        (moreThenSoftMax && !moreThenHardMax),
    }),
    [lessThenSoftMin, moreThenSoftMax, lessThenHardMin, moreThenHardMax]
  );

  return (
    <Badge.Ribbon text="" color={isRequired ? "red" : "transparent"}>
      <Popover
        open={!!softMaximum || !!softMinimum || !!hardMaximum || !!hardMinimum}
        content={
          <SoftHardValues
            softMaximum={softMaximum}
            softMinimum={softMinimum}
            hardMaximum={hardMaximum}
            hardMinimum={hardMinimum}
            size="small"
            className="!mb-0"
          />
        }
      >
        <Input
          type="number"
          value={fieldValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(fieldName, Number(e.target.value))
          }
          onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
            e.currentTarget.blur()
          }
          placeholder={placeholder}
          className={classNames("mt-1 relative", {
            "!bg-white": disableDynamicStyling,
            "!bg-red-50": errorStyling,
            "!bg-yellow-200": warningStyling,
          })}
        />
      </Popover>
    </Badge.Ribbon>
  );
};

export { UpdateOutputMaterialNumberInput };
