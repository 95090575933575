import { Button, Select } from "antd";
import Title from "antd/es/typography/Title";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderStepTrackerResponseItem } from "shared/api";
import { useTrackingListRequests } from "shared/hooks";
import { AdaptedValues } from "shared/types";

interface TrackingListHeaderProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  isDataFilterActive: boolean;
  adaptedOutputMaterial: AdaptedValues[];
  materialItemId: number | null;
  handleMaterialItemId: (value: number) => void;
}

const TrackingListHeader: FC<TrackingListHeaderProps> = ({
  orderStepTrackerResponseItem,
  isDataFilterActive,
  adaptedOutputMaterial,
  materialItemId,
  handleMaterialItemId,
}) => {
  const { t } = useTranslation();

  const { handleCorrectAllPackaging, loadingStates } =
    useTrackingListRequests();

  return (
    <div className="flex gap-x-4 items-center">
      <Title level={5}>{t("orders.output-material")!}</Title>

      <Select
        value={materialItemId}
        onChange={handleMaterialItemId}
        options={adaptedOutputMaterial}
        placeholder="Select material"
        className={classNames("!w-30% mb-3", {
          "mb-6": isDataFilterActive,
        })}
      />

      <Button
        type="primary"
        className="btn-primary--dark !m-0 self-start h-10"
        loading={loadingStates.isLoadingCorrectAllPackaging}
        onClick={() =>
          handleCorrectAllPackaging({
            id: orderStepTrackerResponseItem?.order?.id as number,
          })
        }
      >
        {t("orders.alignReelsOnPallet")}
      </Button>
    </div>
  );
};

export { TrackingListHeader };
