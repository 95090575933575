import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { ExpandUnitItem, MaterialCategoriesItem } from "shared/api";
import { getMaterialCategoriesFromCompany } from "shared/adapters";
import { TableButton } from "shared/ui";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { useModal } from "shared/hooks";
import { AdaptedValues, AddCompanyValues } from "shared/types";
import { materialCategoriesInCompanyData } from "shared/constants";
import { AddCategoriesToCompanyModal } from "./AddCategoriesToCompanyModal";

interface CategoriesSuppliedTableProps {
  adaptedMaterialCategories: AdaptedValues[];
  materialCategories?: MaterialCategoriesItem[];
}

const CategoriesSuppliedTable: FC<CategoriesSuppliedTableProps> = ({
  adaptedMaterialCategories,
  materialCategories,
}) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<AddCompanyValues>();

  const [isOpenCategoriesModal, showCategoriesModal, hideCategoriesModal] =
    useModal();

  const [materialCategoriesFromCompany, setMaterialCategoriesFromCompany] =
    useState<MaterialCategoriesItem[]>([]);

  useEffect(() => {
    if (values?.categories_supplied) {
      const materialCategories = getMaterialCategoriesFromCompany(values);

      setMaterialCategoriesFromCompany(
        materialCategories as MaterialCategoriesItem[]
      );
    }
  }, [values?.categories_supplied]);

  const { name, unit, uniqueCode, actions } = materialCategoriesInCompanyData;

  const removeItemFromTable = (id: number) => {
    const filteredMaterialCategories = materialCategoriesFromCompany.filter(
      (item) => item.id !== id
    );

    const filteredValues = values?.categories_supplied?.filter(
      (value) => (value?.material_category as MaterialCategoriesItem)?.id !== id
    );

    setMaterialCategoriesFromCompany(filteredMaterialCategories);
    setFieldValue("categories_supplied", filteredValues);
  };

  const columns: ColumnsType<MaterialCategoriesItem> = [
    {
      title: t(name.title),
      width: name.width,
      render: (record: MaterialCategoriesItem) => <span>{record?.name}</span>,
    },
    {
      title: t(unit.title),
      width: unit.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{(record?.unit as ExpandUnitItem)?.name}</span>
      ),
    },
    {
      title: t(uniqueCode.title),
      width: uniqueCode.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{record?.unique_code}</span>
      ),
    },
    {
      title: t(actions.title),
      width: actions.width,
      render: (record: MaterialCategoriesItem) => (
        <TableButton
          type="delete"
          onClick={() => removeItemFromTable(record?.id!)}
        />
      ),
    },
  ];

  return (
    <>
      <div className="w-full flex items-center justify-between mt-3">
        <Title level={5} className="mb-2">
          {t("companies.category-supplied")}
        </Title>

        <Button
          type="primary"
          className="btn-primary--dark"
          onClick={showCategoriesModal}
        >
          {t("companies.add-category-supplied")}
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={materialCategoriesFromCompany}
        rowKey={(obj) => obj.id!}
      />

      <AddCategoriesToCompanyModal
        isOpen={isOpenCategoriesModal}
        closeModal={hideCategoriesModal}
        adaptedMaterialCategories={adaptedMaterialCategories}
        materialCategories={materialCategories}
      />
    </>
  );
};

export { CategoriesSuppliedTable };
