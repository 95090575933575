import React, { FC } from "react";
import { AlertOutlined } from "@ant-design/icons";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormInput } from "shared/ui";
import {
  AddOrUpdateOrderValues,
  Card,
  AddProcessStepValues,
} from "shared/types";
import { ConfigDynamicTrackerFields } from "../../api/materials/models";

interface DynamicTextTypeInputProps {
  card: ConfigDynamicTrackerFields | Card;
  fieldIndex: number;
  fieldName?: string;
  value: number | string;
}

const DynamicTextTypeInput: FC<DynamicTextTypeInputProps> = ({
  card,
  fieldIndex,
  fieldName,
  value,
}) => {
  const { t } = useTranslation();
  const { handleBlur, handleChange } = useFormikContext<
    AddOrUpdateOrderValues | AddProcessStepValues
  >();

  return (
    <div className="relative">
      <FormInput
        value={value}
        fieldName={fieldName!}
        placeholder={t("orders.placeholder-input")}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      {card.fields?.[fieldIndex]?.required === true &&
        !value?.toString().length && (
          <AlertOutlined className="btn-remove absolute top-2 right-2" />
        )}
    </div>
  );
};

const DynamicTextTypeInputMemo = React.memo(DynamicTextTypeInput);

export { DynamicTextTypeInputMemo as DynamicTextTypeInput };
