import { useState, useCallback } from "react";
import debounce from "lodash.debounce";

interface UseRangeProps {
  maxValueLimit: number;
}

const useRange = ({ maxValueLimit }: UseRangeProps) => {
  const [minValue, setMinValue] = useState<number | string>();
  const [maxValue, setMaxValue] = useState<number | string>();

  const handleRangeChange = (value: [number, number]) => {
    setMinValue(value[0]);
    setMaxValue(value[1]);
  };

  const debouncedRangeHandler = useCallback(
    debounce(handleRangeChange, 400),
    []
  );

  const onChange = (value: [number, number]) => {
    debouncedRangeHandler(value);
  };

  const onAfterChange = (value: [number, number]) => {
    debouncedRangeHandler(value);
  };

  const reset = (min: number | string, max: number | string) => {
    setMinValue(min);
    setMaxValue(max);
  };

  const isFilterActive = () => {
    return Number(minValue) > 0 || Number(maxValue) < maxValueLimit;
  };

  return {
    minValue,
    maxValue,
    onChange,
    onAfterChange,
    reset,
    isFilterActive,
  };
};

export { useRange };
