import { Divider, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { ExecuteReportValues, AdaptedValues } from "shared/types";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { adaptMaterialItemsData } from "shared/adapters";
import { useGetAllMaterialItemsQuery } from "shared/api";
import { Colors } from "shared/themes";
import { FormSelect, Loader } from "shared/ui";
import { selectFiltering } from "shared/helpers";

interface MaterialItemFilterProps {
  adaptedOrders: AdaptedValues[];
  selectedOrderId: number | null;
  onOrderChange: (value: number) => void;
}

const MaterialItemFilter: FC<MaterialItemFilterProps> = ({
  adaptedOrders,
  selectedOrderId,
  onOrderChange,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values, setFieldValue, handleBlur } =
    useFormikContext<ExecuteReportValues>();

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetAllMaterialItemsQuery(
    {
      order: selectedOrderId ?? undefined,
    },
    { skip: !selectedOrderId }
  );

  const adaptedMaterialItems = useMemo(() => {
    return adaptMaterialItemsData(materialItems ?? []);
  }, [materialItems]);

  if (isLoading || isFetching) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <>
      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />

      <div className="flex gap-x-3">
        <div className="flex gap-x-2">
          <Title level={5} className="!w-[100px] !m-0">
            {t("selectOrder")}
          </Title>
          {" - "}
          <Select
            value={selectedOrderId}
            placeholder={t("selectOrder")}
            options={adaptedOrders}
            onChange={onOrderChange}
            className="w-full"
            size="small"
            showSearch
            filterOption={(input, option) => selectFiltering(input, option)}
          />
        </div>

        {selectedOrderId && (
          <>
            <Divider
              type="vertical"
              className="h-full"
              style={{ background: Colors.blue.primary }}
            />

            <div className="flex gap-x-2">
              <Title level={5} className="!w-[180px]  !m-0">
                {t("selectMaterialItem")}
              </Title>
              {" - "}
              <FormSelect
                value={values?.material_item_id}
                placeholder={t("selectMaterialItem")}
                fieldName="material_item_id"
                options={adaptedMaterialItems}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                showSearch
                size="small"
                className="!m-0"
              />
            </div>
          </>
        )}
      </div>

      <Divider className="my-1.5" style={{ background: Colors.blue.primary }} />
    </>
  );
};

export { MaterialItemFilter };
