import { Alert, Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  DynamicOutputMaterialData,
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import { useDynamicDataComparison } from "shared/hooks";

interface OutputMaterialsWarningProps {
  index: number;
  outputMaterials: ProductionOutputMaterial[];
  handleSetOutputMaterialsValuesBasedOnCountOfCopies: (
    materials: DynamicOutputMaterialData[],
    countOfCopiedItems: number,
    index: number
  ) => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const OutputMaterialsWarning: FC<OutputMaterialsWarningProps> = ({
  outputMaterials,
  index,
  handleSetOutputMaterialsValuesBasedOnCountOfCopies,
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const materialName =
    orderStepTrackerResponseItem?.process_step?.output_materials?.[index]
      ?.material?.name;
  const dynamicData = outputMaterials?.[index]?.dynamicData;

  const masterCardDataToRevert = [outputMaterials?.[index]?.dynamicData?.[0]];
  const countOfCopiesToRevert = dynamicData?.length;

  const redoValueAsMasterCard = () => {
    handleSetOutputMaterialsValuesBasedOnCountOfCopies(
      masterCardDataToRevert,
      countOfCopiesToRevert,
      index
    );
  };

  const { message, isAllCardsAreTheSame } =
    useDynamicDataComparison(dynamicData);

  const warningTitle = `${materialName}. ${t("countOfItems", {
    numberOfItems: dynamicData?.length,
  })}`;

  return dynamicData?.length > 1 ? (
    <div className="flex flex-col gap-y-2">
      <Title level={5} className="!m-0">
        {warningTitle}
      </Title>
      <Alert
        showIcon
        type={isAllCardsAreTheSame ? "success" : "warning"}
        message={message}
      />

      {!isAllCardsAreTheSame ? (
        <Button onClick={redoValueAsMasterCard}>
          {t("revertMessage", { countOfCopiesToRevert })}
        </Button>
      ) : null}
    </div>
  ) : null;
};

export { OutputMaterialsWarning };
