import { Badge, Input } from "antd";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigDynamicTrackerFields, DynamicKeyValue } from "shared/api";

interface UpdateOutputMaterialTextInputProps {
  fieldName: string;
  fieldValue: DynamicKeyValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  section: ConfigDynamicTrackerFields;
  cardIndex: number;
}

const UpdateOutputMaterialTextInput: FC<UpdateOutputMaterialTextInputProps> = ({
  fieldName,
  fieldValue,
  setFieldValue,
  section,
  cardIndex,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const currentField = section?.fields?.[cardIndex];

  const { isRequired } = useMemo(
    () => ({
      isRequired: currentField?.required,
    }),
    [currentField]
  );

  return (
    <Badge.Ribbon text="" color={isRequired ? "red" : "transparent"}>
      <Input
        placeholder={t("enterTextValue")!}
        type="text"
        value={fieldValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue(fieldName, e.target.value)
        }
      />
    </Badge.Ribbon>
  );
};

export { UpdateOutputMaterialTextInput };
