import { ClearOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { ExecuteReportValues } from "shared/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReportItem } from "shared/api";
import * as Yup from "yup";
import { useModal } from "shared/hooks";
import { ExecuteReports, ReportsData } from "./ExecuteReportForm";
import { DownloadReport } from "./DownloadReport";

interface ExportReportHeaderProps {
  pdfUrl: string | null;
  selectedReport?: ReportItem;
  executeReports: ExecuteReports;
  isValid: boolean;
  validationSchema?: Yup.AnyObjectSchema;
  getReportData: ReportsData;
  clearPdfUrl: () => void;
}

const ExportReportHeader: FC<ExportReportHeaderProps> = ({
  pdfUrl,
  selectedReport,
  executeReports,
  isValid,
  validationSchema,
  getReportData,
  clearPdfUrl,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const { values, resetForm } = useFormikContext<ExecuteReportValues>();

  const [
    isVisibleDownloadReportModal,
    showDownloadReportModal,
    hideDownloadReportModal,
  ] = useModal();

  const clearFormAndResetPdfFile = () => {
    resetForm();
    clearPdfUrl();
  };

  return (
    <div className="w-full flex items-center gap-x-4">
      <Title level={3}>
        {t("selectedReport", { name: selectedReport?.name })}
      </Title>

      {values?.id && (
        <ClearOutlined
          onClick={clearFormAndResetPdfFile}
          className="mb-4 self-end !text-red-100 scale-150"
          type="primary"
        />
      )}

      <Button
        onClick={executeReports.handleExecuteReport}
        className="btn-primary--dark min-w-32 !m-0"
        type="primary"
        loading={executeReports.isLoadingReportExport}
        disabled={!isValid || !validationSchema}
      >
        {t("exportReport")}
      </Button>

      <Button
        onClick={getReportData.handleGetReportData}
        className="bg-green-300 w-32"
        type="primary"
        loading={getReportData.isLoadingReportData}
        disabled={!isValid || !validationSchema}
      >
        {t("getData")}
      </Button>

      {pdfUrl && (
        <>
          <Button
            className="bg-green-100"
            type="primary"
            onClick={showDownloadReportModal}
          >
            {t("download")}
          </Button>

          <Button type="primary" danger onClick={clearFormAndResetPdfFile}>
            {t("clearPdf")}
          </Button>
        </>
      )}

      <DownloadReport
        isOpen={isVisibleDownloadReportModal}
        onClose={hideDownloadReportModal}
        selectedReport={selectedReport}
      />
    </div>
  );
};

export { ExportReportHeader };
