import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EmptyReportsMessage = () => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  return <Alert type="warning" showIcon message={t("noReportsToExecute")} />;
};

export { EmptyReportsMessage };
