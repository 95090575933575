type ArticleFlowFilterKeys =
  | "material_stock_batch__material__in"
  | "status__in";

type MaterialConsumptionFilterKeys = "material_consumption_status__in";

type OrderStatusFilterKeys =
  | "order_status_status__in"
  | "process_step_trackers__machine__in";

type MachinesXFilterKeys = "process_template";

type MaterialTargetAlertFilterKeys = "material_category__in";

type ProductionTrackerFilterKeys =
  | "production_tracking_material_stock_batch__material__in"
  | "production_tracking_status__in";

export const enum ArticleFlowFilterKeysEnum {
  MATERIAL = "material_stock_batch__material__in",
  STATUS = "status__in",
}

export const enum OrderStatusFilterKeysEnum {
  STATUS = "order_status_status__in",
  MACHINES = "process_step_trackers__machine__in",
}

export const enum MaterialConsumptionFilterKeysEnum {
  STATUS = "material_consumption_status__in",
}

export const enum MachinesXFilterKeysEnum {
  PROCESS_TEMPLATE = "process_template",
}

export const enum MaterialTargetAlertFilterKeysEnum {
  MATERIAL_CATEGORY = "material_category__in",
}

export const enum ProductionTrackerFilterKeysEnum {
  MATERIAL = "production_tracking_material_stock_batch__material__in",
  STATUS = "production_tracking_status__in",
}

export type AllFilterKeys =
  | ArticleFlowFilterKeys
  | MaterialConsumptionFilterKeysEnum
  | MachinesXFilterKeys
  | MaterialTargetAlertFilterKeys
  | OrderStatusFilterKeys
  | ProductionTrackerFilterKeys;

export interface WidgetFiltersKeys {
  articleFlow: Record<string, ArticleFlowFilterKeys>;
  materialConsumption: Record<string, MaterialConsumptionFilterKeys>;
  machinesX: Record<string, MachinesXFilterKeys>;
  materialTargetAlert: Record<string, MaterialTargetAlertFilterKeys>;
  orderStatus: Record<string, OrderStatusFilterKeys>;
  productionTracker: Record<string, ProductionTrackerFilterKeys>;
}
