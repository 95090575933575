import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreatePackage,
  GeneratePackageReportParams,
  PackagesParams,
  PackagingItem,
  PackagingParams,
  PackagingResponse,
  UpdatePackage,
} from "./models";

export const packagingApi = createApi({
  baseQuery,
  reducerPath: "packagingApi",
  tagTypes: ["packagings"],
  endpoints: (builder) => ({
    getAllPackages: builder.query<PackagingResponse, PackagingParams>({
      query: ({
        limit,
        offset,
        expand,
        ordering,
        name__icontains,
        order,
        material,
      }) => ({
        url: "/api/v1/packagings/",
        method: "GET",
        params: {
          limit,
          offset,
          expand,
          ordering,
          name__icontains,
          order,
          material,
        },
      }),
      providesTags: ["packagings"],
    }),

    getAllPackagesWithoutParams: builder.query<PackagingItem[], PackagesParams>(
      {
        query: (params) => ({
          url: "/api/v1/packagings/",
          method: "GET",
          params,
        }),
      }
    ),

    createPackage: builder.mutation<void, CreatePackage>({
      query: (body) => ({
        url: "/api/v1/packagings/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["packagings"],
    }),

    deletePackage: builder.mutation<void, PackagingItem>({
      query: (body) => ({
        url: `/api/v1/packagings/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["packagings"],
    }),

    updatePackage: builder.mutation<void, UpdatePackage>({
      query: (body) => ({
        url: `/api/v1/packagings/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["packagings"],
    }),

    generatePackageReport: builder.mutation<void, GeneratePackageReportParams>({
      query: (body) => ({
        url: `/api/v1/packagings/${body.id}/generate_report/`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/pdf",
        },
        responseHandler: (response) => response.blob(),
      }),
      invalidatesTags: ["packagings"],
    }),
  }),
});

export const {
  useGetAllPackagesQuery,
  useGetAllPackagesWithoutParamsQuery,
  useCreatePackageMutation,
  useDeletePackageMutation,
  useUpdatePackageMutation,
  useGeneratePackageReportMutation,
} = packagingApi;
