import React, { FC } from "react";
import { WidgetTableData } from "shared/api";
import { MaterialTargetAlertFields, openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import { PopConfirmButton } from "shared/ui";

interface MaterialTargetAlertProps {
  text: string;
  record: WidgetTableData;
  fieldKey: string;
}

const MaterialTargetAlert: FC<MaterialTargetAlertProps> = ({
  text,
  record,
  fieldKey,
}) => {
  const materialName = record?.adaptMaterialTargetName as string;
  const materialId = record?.adaptMaterialTargeId as number;

  const materialLink =
    `${Routes.materialManagement.hyperlink}/${materialId}`?.replace(":id/", "");

  const materialCategory =
    record?.adaptMaterialTargetMaterialCategory as string;
  const materialCategoryId =
    record.adaptMaterialTargetMaterialCategoryId as number;

  const materialCategoryLink =
    `${Routes.materialCategoryManagement.hyperlink}/${materialCategoryId}`?.replace(
      ":id/",
      ""
    );

  if (fieldKey === MaterialTargetAlertFields.ADAPT_MATERIAL_TARGET_NAME) {
    return (
      <PopConfirmButton
        buttonContent={materialName}
        onClick={() => openHyperlinkInTab(materialLink)}
      />
    );
  }

  if (
    fieldKey ===
    MaterialTargetAlertFields.ADAPT_MATERIAL_TARGET_MATERIAL_CATEGORY
  ) {
    return (
      <PopConfirmButton
        buttonContent={materialCategory}
        onClick={() => openHyperlinkInTab(materialCategoryLink)}
      />
    );
  }

  return <span>{text}</span>;
};

const MaterialTargetAlertMemo = React.memo(MaterialTargetAlert);

export { MaterialTargetAlertMemo as MaterialTargetAlert };
