import React, { FC } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { addThousandsSeparator } from "shared/helpers";

export interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number | number[] | undefined;
    borderColor: string;
    backgroundColor: string;
  }[];
}

interface GlobalChartProps {
  data: ChartData;
  chartLabel?: string;
  hidden?: boolean;
  yAxisLabel?: string;
  tooltipExtraData?: string;
}

const GlobalChart: FC<GlobalChartProps> = ({
  data,
  chartLabel,
  hidden,
  yAxisLabel,
  tooltipExtraData,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback(value: number | string) {
            return `${addThousandsSeparator(value as number)} - ${yAxisLabel}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: chartLabel,
      },
      tooltip: {
        callbacks: {
          label(context: any) {
            const value = context.raw;
            const statusLabel = context.dataset.label;
            return `${statusLabel} - ${addThousandsSeparator(
              value
            )}. ${tooltipExtraData}: ${yAxisLabel}`;
          },
        },
      },
    },
  };

  if (hidden) {
    return null;
  }

  return <Line data={data} options={options} />;
};

export { GlobalChart };
