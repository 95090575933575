import React, { FC, useState } from "react";
import { Badge } from "antd";
import {
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import { useFormik, useFormikContext } from "formik";
import { noop } from "shared/helpers";
import { useTranslation } from "react-i18next";
import { AddProductionRequest } from "../../ProcessStepProduction";
import { UpdateOutputMaterialQuantity } from "./UpdateOutputMaterialQuantity";
import { UpdateOutputMaterialStatus } from "./UpdateOutputMaterialStatus";
import { MasterCardOverlay } from "./MasterCardOverlay";
import { OutputMaterialEditModeFooter } from "./OutputMaterialEditModeFooter";
import { DynamicFieldsForEditing } from "./DynamicFieldsForEditing";

interface UpdateOutputMaterialsProps {
  mainValues: AddProductionRequest;
  index: number;
  toggleEditMode: (index: number) => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const UpdateOutputMaterials: FC<UpdateOutputMaterialsProps> = ({
  mainValues,
  index,
  toggleEditMode,
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const { setFieldValue: setMainFieldValue } =
    useFormikContext<AddProductionRequest>();

  const outputMaterialsFormik = useFormik<{
    output_materials: ProductionOutputMaterial[];
  }>({
    initialValues: {
      output_materials: mainValues?.output_materials ?? [],
    },
    validateOnMount: false,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const { setFieldValue, values } = outputMaterialsFormik;
  const materials = values?.output_materials?.[index]?.dynamicData || [];

  const totalPages = materials.length;
  const unitName = values?.output_materials?.[index]?.unit?.name;

  const [currentPage, setCurrentPage] = useState(1);
  const handleCurrentPage = (page: number) => setCurrentPage(page - 1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const isMasterCard = currentPage === 0;

  const badgeTitle = isMasterCard
    ? t("masterCard")
    : t("editingItemNumber", { itemNumber: currentPage + 1 });

  const submitEditing = () => {
    setMainFieldValue(
      `output_materials.${index}`,
      values.output_materials?.[index]
    );
    toggleEditMode(index);
  };

  return (
    <div className="w-[45%]">
      <Badge.Ribbon text={badgeTitle} className="text-[18px]">
        <div className="w-full h-auto p-2 border-[1px] border-black relative">
          {isMasterCard && <MasterCardOverlay handleNext={handleNext} />}

          {materials.length > 0 && (
            <div key={currentPage}>
              <UpdateOutputMaterialQuantity
                materials={materials}
                currentPage={currentPage}
                setFieldValue={setFieldValue}
                unitName={unitName}
                index={index}
              />
              <UpdateOutputMaterialStatus
                materials={materials}
                currentPage={currentPage}
                setFieldValue={setFieldValue}
                index={index}
              />
            </div>
          )}

          <DynamicFieldsForEditing
            index={index}
            currentPage={currentPage}
            materials={materials}
            setFieldValue={setFieldValue}
            orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          />

          <OutputMaterialEditModeFooter
            currentPage={currentPage}
            totalPages={totalPages}
            handleCurrentPage={handleCurrentPage}
            handlePageChange={handlePageChange}
            submitEditing={submitEditing}
          />
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export { UpdateOutputMaterials };
