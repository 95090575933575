import { FormikProvider, useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAddProcessTemplateImageMutation,
  useGetAppProcessTemplateByIdQuery,
  useGetDimensionUnitsQuery,
  useUpdateProcessTemplateMutation,
} from "shared/api";
import { DynamicForm, FormInput, HyperlinkPageHeader, Loader } from "shared/ui";
import {
  adaptDynamicProcessTemplateField,
  handleRequestError,
  noop,
} from "shared/helpers";
import { UploadFile, notification } from "antd";
import { useCompanyImageUpload } from "shared/hooks";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { expandDimensionUnits } from "shared/constants";
import { adaptDimensionUnitsData } from "shared/adapters";
import { AddProcessTemplatesValues } from "shared/types";
import { AddOrEditProcessTemplateSchema } from "shared/schemas";
import { ProcessTemplateLogo } from "./ui/ProcessTemplateLogo";

const ProcessTemplateHyperlink = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [updateProcessTemplate] = useUpdateProcessTemplateMutation();
  const [addProcessStepImage] = useAddProcessTemplateImageMutation();

  const { data: processTemplate, isLoading: isLoadingProcessTemplate } =
    useGetAppProcessTemplateByIdQuery(
      {
        id: Number(id),
      },
      {
        skip: !id,
      }
    );

  const { data: dimensionUnits, isLoading: isLoadingDimensionUnits } =
    useGetDimensionUnitsQuery({
      expand: expandDimensionUnits,
    });

  const adaptedDimensionUnits = useMemo(() => {
    return adaptDimensionUnitsData(dimensionUnits || []);
  }, [dimensionUnits]);

  const addOrUpdateProcessTemplatesFormik = useFormik({
    initialValues: {
      ...processTemplate?.[0],
      sections: processTemplate?.[0]?.config_dynamic_process_step_fields,
    },
    validationSchema: AddOrEditProcessTemplateSchema,
    enableReinitialize: true,
    onSubmit: noop,
  });

  const { values, handleBlur, handleChange, errors, isValid } =
    addOrUpdateProcessTemplatesFormik;

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);
  const clearDocumentList = () => setDocumentList([]);

  const { uploadedFile, previewLogoImage, uploadCompanyImageProps } =
    useCompanyImageUpload({
      documentList,
      handleDocumentList,
      closeModal: () => {},
    });

  const updateProcessTemplateRequest = async () => {
    try {
      const response = await updateProcessTemplate({
        id: values.id!,
        name: values.name!,
        description: values.description!,
        config_dynamic_process_step_fields: adaptDynamicProcessTemplateField(
          values as AddProcessTemplatesValues
        ),
        abbreviation: values.abbreviation!,
      }).unwrap();

      if (response && uploadedFile) {
        const formData = new FormData();
        formData.append("file", uploadedFile);

        await addProcessStepImage({
          id: response?.id,
          image: formData,
        }).unwrap();
      }

      api.success({
        message: t("process-templates.success"),
        description: t("process-templates.success-update-msg"),
      });

      clearDocumentList();
    } catch (error) {
      api.error({
        message: t("process-templates.error"),
        description: handleRequestError(error, t("process-templates.error")),
      });
    } finally {
      clearDocumentList();
    }
  };

  if (
    isLoadingProcessTemplate ||
    isLoadingDimensionUnits ||
    adaptedDimensionUnits?.length === 0
  )
    return <Loader type="fullscreen" />;

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateProcessTemplatesFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Process template Hyperlink - ${processTemplate?.[0]?.name}`}
            isValid={isValid}
            onOk={updateProcessTemplateRequest}
          />

          <div className="p-4 w-full">
            <ProcessTemplateLogo
              documentList={documentList}
              uploadCompanyImageProps={uploadCompanyImageProps}
              previewLogoImage={previewLogoImage}
            />

            <Title level={5} className="mb-1.25">
              {t("process-templates.input-title")}
            </Title>

            <FormInput
              value={values.name}
              fieldName="name"
              placeholder={t("process-templates.name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            <Title level={5} className="mb-1.25">
              {t("process-templates.description-title")}
            </Title>

            <FormInput
              value={values.description}
              fieldName="description"
              placeholder={t("process-templates.description-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.description}
              isError={!!errors.description}
            />

            <Title level={5} className="mb-1.25">
              {t("process-templates.abbreviation")}
            </Title>

            <FormInput
              value={values.abbreviation}
              fieldName="abbreviation"
              placeholder={t("process-templates.abbreviation")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.abbreviation}
              isError={!!errors.abbreviation}
            />

            <DynamicForm adaptedDimensionUnits={adaptedDimensionUnits} />
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { ProcessTemplateHyperlink };
