import {
  useGenerateAllItemsLabelsMutation,
  useGenerateAllPackageLabelsMutation,
  useGenerateLastSetLabelsMutation,
  useGenerateLastSetPackageLabelsMutation,
} from "shared/api";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { handleRequestError } from "shared/helpers";

interface OrderProcessStepTrackerGeneratePdfFilesProps {
  orderProcessStepTrackerId: number;
}

export const useOrderProcessStepTrackerGeneratePdfFiles = ({
  orderProcessStepTrackerId,
}: OrderProcessStepTrackerGeneratePdfFilesProps) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [
    generateAllItemsLabels,
    { isLoading: isLoadingGenerateAllItemsLabels },
  ] = useGenerateAllItemsLabelsMutation();
  const [generateLastSetLabels, { isLoading: isLoadingGenerateLastSetLabels }] =
    useGenerateLastSetLabelsMutation();
  const [
    generateAllPackageLabels,
    { isLoading: isLoadingGenerateAllPackageLabels },
  ] = useGenerateAllPackageLabelsMutation();
  const [
    generateLastSetPackageLabels,
    { isLoading: isLoadingGenerateLastSetPackageLabels },
  ] = useGenerateLastSetPackageLabelsMutation();

  const generateAllItemsLabelsPdf = async () => {
    try {
      const response = await generateAllItemsLabels({
        id: orderProcessStepTrackerId,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  const generateLastSetLabelsPdf = async () => {
    try {
      const response = await generateLastSetLabels({
        id: orderProcessStepTrackerId,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  const generateAllPackageLabelsPdf = async () => {
    try {
      const response = await generateAllPackageLabels({
        id: orderProcessStepTrackerId,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  const generateLastSetPackageLabelsPdf = async () => {
    try {
      const response = await generateLastSetPackageLabels({
        id: orderProcessStepTrackerId,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        api.success({
          message: t("packaging.success"),
          description: t("packaging.generated"),
        });
      }
    } catch (error) {
      api.error({
        message: t("packaging.error"),
        description: handleRequestError(error, t("packaging.error")),
      });
    }
  };

  return {
    generateAllItemsLabelsPdf,
    generateLastSetLabelsPdf,
    generateAllPackageLabelsPdf,
    generateLastSetPackageLabelsPdf,
    contextHolder,
    loadingStates: {
      isLoadingGenerateAllItemsLabels,
      isLoadingGenerateLastSetLabels,
      isLoadingGenerateAllPackageLabels,
      isLoadingGenerateLastSetPackageLabels,
    },
  };
};
