import { Popover } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MaterialCategoriesItem, MaterialCategorySupplier } from "shared/api";

interface CategorySuppliersProps {
  materialCategory: MaterialCategoriesItem;
  handleCategorySuppliersModal: (record: MaterialCategoriesItem) => void;
}

const CategorySuppliers: FC<CategorySuppliersProps> = ({
  materialCategory,
  handleCategorySuppliersModal,
}) => {
  const { t } = useTranslation();

  const renderChildCompanies = () => {
    const suppliers =
      (materialCategory?.suppliers as MaterialCategorySupplier[]) || [];
    const suppliersCount = suppliers.length;

    if (suppliersCount === 0) return null;
    if (suppliersCount === 1) return <span>{suppliers[0]?.company?.name}</span>;

    return (
      <span>
        {suppliers[0]?.company?.name} {t("and")} {suppliersCount - 1}{" "}
        {t("other")}
      </span>
    );
  };

  return (
    <Popover content={t("companiesColumns.categorySuppliers")}>
      <button
        type="button"
        className="flex flex-col gap-y-2 items-start"
        onClick={() => handleCategorySuppliersModal(materialCategory)}
      >
        {renderChildCompanies()}
      </button>
    </Popover>
  );
};

export { CategorySuppliers };
