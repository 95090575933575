import { Input, Modal, notification } from "antd";
import React, { FC, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { AlertOutlined } from "@ant-design/icons";
import { FormInput, FormSelect, Loader } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import {
  MaterialStockItem,
  useGetMaterialItemByIDQuery,
  useUpdateStockMaterialItemMutation,
} from "shared/api";
import { useParams } from "react-router-dom";
import {
  ModalSizeValue,
  expandMaterialItemsString,
  materialItemSituations,
  materialItemStatuses,
} from "shared/constants";

import {
  AddStockMaterialItemValues,
  DynamicValues,
  GlobalMaterialItem,
} from "shared/types";
import { DynamicFormMaterialItems } from "./DynamicFormMaterialItems";

interface EditStockItemModalProps {
  isOpenModal: boolean;
  hideEditMaterialItemModal: () => void;
  materialStockBatchID: number | null;
  selectedMaterialItemFromStock?: GlobalMaterialItem | null;
  dynamicValues: DynamicValues[];
  preselectedMaterialName?: string | null;
}

const EditStockItemModal: FC<EditStockItemModalProps> = ({
  isOpenModal,
  hideEditMaterialItemModal,
  materialStockBatchID,
  selectedMaterialItemFromStock,
  dynamicValues,
  preselectedMaterialName,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    isValid,
    setValues,
  } = useFormikContext<AddStockMaterialItemValues>();

  const [api, contextHolder] = notification.useNotification();

  const [updateStockMaterialItem] = useUpdateStockMaterialItemMutation();

  const closeModal = () => {
    hideEditMaterialItemModal();
    resetForm();
  };

  const updateStockMaterialItemRequest = async () => {
    try {
      await updateStockMaterialItem({
        id: values.id!,
        dynamic_tracker_fields: values.dynamic_tracker_fields,
        material_stock_batch: materialStockBatchID!,
        quantity: values.quantity as number,
        serial_number: values.serial_number,
        status: values.status!,
        situation: values.situation!,
      }).unwrap();
      api.success({
        message: t("stock-material-item.success-title"),
        description: t("stock-material-item.success-update-msg"),
      });
      closeModal();
    } catch (error: any) {
      const description = handleRequestError(
        error,
        t("stock-material-item.error")
      );

      api.error({
        message: t("stock-material-item.error"),
        description,
      });
    }
  };

  const emptyQuantityField = values.quantity === null || values.quantity === "";

  const idWithoutMaterialIdString = id?.replace(/-materialId/g, "");

  const { data: materiaItems } = useGetMaterialItemByIDQuery(
    {
      id: Number(idWithoutMaterialIdString),
      expand: expandMaterialItemsString,
    },
    {
      skip: !id,
    }
  );

  const setItemValues = useCallback(
    (item: MaterialStockItem) => {
      setValues({
        id: item?.id,
        material_stock_batch: item?.material_stock_batch?.id,
        dynamic_tracker_fields: item?.dynamic_tracker_fields,
        quantity: item?.quantity,
        serial_number: item?.serial_number,
        status: item?.status,
        situation: item?.situation,
      });
    },
    [setValues]
  );

  useEffect(() => {
    if (materiaItems) {
      setItemValues(materiaItems[0]);
    }
  }, [materiaItems, setItemValues]);

  return (
    <>
      {contextHolder}
      <Modal
        title={t("stock-material-item.edit-item")}
        open={isOpenModal}
        width={ModalSizeValue.X_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={() => {
          updateStockMaterialItemRequest();
        }}
      >
        <Title level={5}>{t("stock-material-item.material-stock-batch")}</Title>

        <Input
          value={
            selectedMaterialItemFromStock?.material?.name! ||
            (preselectedMaterialName as string)
          }
          size="large"
          disabled
        />

        <Title level={5}>{t("stock-material-item.serial-number")}</Title>

        <div className="relative">
          <FormInput
            value={values.serial_number}
            fieldName="serial_number"
            placeholder={t("stock-material-item.serial-number-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.serial_number?.toString()}
            isError={!!errors.serial_number}
          />
          {!values.serial_number.length && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}
        </div>

        <Title level={5}>{t("stock-material-item.quantity")}</Title>

        <div className="relative">
          <FormInput
            value={values.quantity}
            fieldName="quantity"
            placeholder={t("stock-material-item.quantity-placeholder")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.quantity?.toString()}
            isError={!!errors.quantity}
            numberType
          />
          {emptyQuantityField && (
            <AlertOutlined className="btn-remove  absolute top-2 right-2" />
          )}

          <Title level={5}>{t("stock-material-item.status")}</Title>

          <FormSelect
            value={values.status}
            placeholder={t("stock-material-item.status-placeholder")}
            fieldName="status"
            options={materialItemStatuses}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            error={errors.status?.toString()}
            isError={!!errors.status}
          />
        </div>

        <Title level={5}>{t("stock-material-item.situation")}</Title>

        <FormSelect
          value={values.situation}
          placeholder={t("stock-material-item.situation-placeholder")}
          fieldName="situation"
          options={materialItemSituations}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />

        {dynamicValues === undefined ? (
          <Loader />
        ) : (
          <DynamicFormMaterialItems dynamicValues={dynamicValues} />
        )}
      </Modal>
    </>
  );
};

const EditStockItemModalMemo = React.memo(EditStockItemModal);

export { EditStockItemModalMemo as EditStockItemModal };
