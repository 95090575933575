import { FieldArray } from "formik";
import React, { FC } from "react";
import {
  MaterialStockItem,
  OrderStepTrackerResponseItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { AdaptedValues, InitialInputMaterials } from "shared/types";
import { InputMaterialCard } from "./InputMaterialCard";

interface VisibleInputMaterialsProps {
  inputMaterial: ProductionInputMaterial;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  inputIndex: number;
  adaptedMaterials: AdaptedValues[];
  adaptedMaterialItems: AdaptedValues[];
  adaptedStockMaterialBatches: AdaptedValues[];
  adaptedInitialInputMaterials: InitialInputMaterials[];
  materialStockBatches?: StockMaterialItem[];
  materialItems?: MaterialStockItem[];
  isLoadingInputMaterialsData: boolean;
}

const VisibleInputMaterials: FC<VisibleInputMaterialsProps> = ({
  inputMaterial,
  orderStepTrackerResponseItem,
  inputIndex,
  adaptedMaterials,
  adaptedMaterialItems,
  adaptedStockMaterialBatches,
  adaptedInitialInputMaterials,
  materialStockBatches,
  materialItems,
  isLoadingInputMaterialsData,
}) => {
  const moreThanOneMaterial = inputMaterial?.fields?.length > 1;

  const dynamicInputMaterialCategory =
    orderStepTrackerResponseItem?.process_step?.input_materials?.[inputIndex]
      ?.material?.material_category;

  const hiddenMaterial = inputMaterial.calcHideField;

  // Added extra check to avoid rendering hidden materials
  if (hiddenMaterial) {
    return null;
  }

  return (
    <FieldArray
      key={inputMaterial.id}
      name={`input_materials.${inputIndex}.fields`}
      render={(arrayHelpers) => (
        <InputMaterialCard
          inputIndex={inputIndex}
          moreThanOneMaterial={moreThanOneMaterial}
          inputMaterial={inputMaterial}
          adaptedMaterials={adaptedMaterials}
          adaptedMaterialItems={adaptedMaterialItems}
          adaptedStockMaterialBatches={adaptedStockMaterialBatches}
          adaptedInitialInputMaterials={adaptedInitialInputMaterials}
          materialStockBatches={materialStockBatches}
          materialItems={materialItems}
          dynamicInputMaterialCategory={dynamicInputMaterialCategory}
          addCard={arrayHelpers.push}
          removeCard={arrayHelpers.remove}
          isLoadingInputMaterialsData={isLoadingInputMaterialsData}
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        />
      )}
    />
  );
};

export { VisibleInputMaterials };
