import { DynamicFormsValuesType } from "shared/features/DynamicFormsComponents/constants";

export const getDynamicFieldName = (
  isText: boolean,
  isChoices: boolean,
  index: number,
  fieldIndex: number
) => {
  if (isText) {
    return null;
  }

  if (isChoices) {
    return `sections[${index}].fields[${fieldIndex}].choices`;
  }

  return `sections[${index}].fields[${fieldIndex}].unit`;
};

export const getDynamicUnitSelectValue = (
  isText: boolean,
  isChoices: boolean,
  index: number,
  fieldIndex: number,
  values: DynamicFormsValuesType
) => {
  if (isChoices) {
    return values.sections[index].fields[fieldIndex].choices;
  }
  if (isText) {
    return null;
  }
  return values.sections[index].fields[fieldIndex].unit;
};
