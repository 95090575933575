import { Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReportItem, useCreateReportMutation } from "shared/api";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { isDefaultOptions, reportTypesOptions } from "shared/constants";

interface AddTemplateConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddTemplateConfigModal: FC<AddTemplateConfigModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [createReport] = useCreateReportMutation();

  const {
    resetForm,
    values,
    handleChange,
    handleBlur,
    errors,
    isValid,
    setFieldValue,
  } = useFormikContext<ReportItem>();

  const handleCreateTemplateConfig = async () => {
    try {
      await createReport(values).unwrap();

      api.success({
        message: t("reports.success-title"),
        description: t("reports.success-create-msg"),
      });
    } catch (error) {
      api.error({
        message: t("reports.error-title"),
        description: handleRequestError(error, t("reports.error")),
      });
    } finally {
      onClose();
      resetForm();
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        onCancel={onClose}
        onOk={handleCreateTemplateConfig}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("reports.name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("reports.name")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="mb-1.25">
          {t("reports.template-id")}
        </Title>

        <FormInput
          value={values.template_id}
          fieldName="template_id"
          placeholder={t("reports.template-id")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.template_id}
          isError={!!errors.template_id}
        />

        <Title level={5} className="mb-1.25">
          {t("reports.report-type")}
        </Title>

        <FormSelect
          value={values.report_type}
          placeholder={t("reports.report-type")}
          fieldName="report_type"
          options={reportTypesOptions}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />

        <Title level={5} className="mb-1.25">
          {t("reports.is-default")}
        </Title>

        <FormSelect
          value={values.is_default}
          placeholder={t("reports.is-default")}
          fieldName="is_default"
          options={isDefaultOptions}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      </Modal>
    </>
  );
};

export { AddTemplateConfigModal };
