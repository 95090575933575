import { Modal } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { FormInput, FormSelect } from "shared/ui";
import { useFormikContext } from "formik";
import { Widget } from "shared/api";
import { ModalSizeValue } from "shared/constants";
import { WidgetFilters } from "../WidgetFilters";

interface WidgetSettingsDialogProps {
  isVisibleSettings: boolean;
  hideSettings: () => void;
  updateWidgetSettings: () => Promise<void>;
  widget: Widget;
  widgetColumnsOptions: {
    value: string;
    label: string;
  }[];
  widgetSortByOptions: {
    value: string;
    label: string;
  }[];
}

const WidgetSettingsDialog: FC<WidgetSettingsDialogProps> = ({
  isVisibleSettings,
  hideSettings,
  updateWidgetSettings,
  widget,
  widgetColumnsOptions,
  widgetSortByOptions,
}) => {
  const { t } = useTranslation();

  const {
    values: widgetValues,
    setFieldValue: setWidgetValue,
    handleBlur: handleWidgetBlur,
    handleChange: handleWidgetChange,
    isValid,
    errors,
  } = useFormikContext<Widget>();

  return (
    <Modal
      open={isVisibleSettings}
      onCancel={hideSettings}
      title={t("widgets.settings")}
      width={ModalSizeValue.MEDIUM}
      onOk={updateWidgetSettings}
      okButtonProps={{
        disabled: !isValid,
        style: {
          background: Colors.blue.primary,
        },
      }}
    >
      <div className="flex items-center justify-between gap-x-3">
        <div className="flex flex-col gap-y-2 w-full">
          <Title level={5} className="!m-0">
            {t("widgets.widgetName")}
          </Title>
          <FormInput
            value={widgetValues?.name!}
            placeholder={t("widgets.widgetNamePlaceholder")}
            fieldName="name"
            handleBlur={handleWidgetBlur}
            className="!z-20"
            handleChange={handleWidgetChange}
          />

          <Title level={5} className="!m-0">
            {t("widgets.columnsTitle")}
          </Title>
          <FormSelect
            value={widgetValues?.column_names}
            placeholder={t("widgets.columnsTitle")}
            fieldName="column_names"
            options={widgetColumnsOptions}
            setFieldValue={setWidgetValue}
            handleBlur={handleWidgetBlur}
            multiple
            className="!z-20"
            error={errors?.column_names}
            isError={!!errors?.column_names}
          />

          <Title level={5} className="!m-0">
            {t("widgets.itemsToShow")}
          </Title>
          <FormInput
            value={widgetValues?.limit_rows!}
            placeholder={t("widgets.itemsToShow")}
            fieldName="limit_rows"
            handleBlur={handleWidgetBlur}
            className="!z-20"
            handleChange={handleWidgetChange}
            numberType
            error={errors?.limit_rows}
            isError={!!errors?.limit_rows}
          />

          <Title level={5} className="!m-0">
            {t("widgets.selectSorting")}
          </Title>
          <FormSelect
            value={widgetValues?.sort_by}
            placeholder={t("widgets.selectSorting")}
            fieldName="sort_by"
            options={widgetSortByOptions}
            setFieldValue={setWidgetValue}
            handleBlur={handleWidgetBlur}
            className="!z-20"
          />
          <WidgetFilters widget={widget} />
        </div>
      </div>
    </Modal>
  );
};

const WidgetSettingsDialogMemo = React.memo(WidgetSettingsDialog);

export { WidgetSettingsDialogMemo as WidgetSettingsDialog };
