import { Button, Modal } from "antd";
import React, { FC } from "react";
import { Colors } from "shared/themes";
import { AdaptedValues, AddCompanyValues } from "shared/types";
import { FieldArray, useFormikContext } from "formik";
import { FormSelect, TableButton } from "shared/ui";
import { useTranslation } from "react-i18next";
import { MaterialCategoriesItem } from "shared/api";
import {
  filterMaterialCategories,
  getIdsOfMaterialCategoriesToFind,
} from "shared/adapters";

interface AddCategoriesToCompanyModalProps {
  isOpen: boolean;
  closeModal: () => void;
  adaptedMaterialCategories: AdaptedValues[];
  materialCategories?: MaterialCategoriesItem[];
}

const AddCategoriesToCompanyModal: FC<AddCategoriesToCompanyModalProps> = ({
  isOpen,
  closeModal,
  adaptedMaterialCategories,
  materialCategories,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue } =
    useFormikContext<AddCompanyValues>();

  const onOk = () => {
    const idsToInclude = getIdsOfMaterialCategoriesToFind(values);

    const filteredCategories = filterMaterialCategories(
      materialCategories ?? [],
      idsToInclude as number[]
    );

    setFieldValue("categories_supplied", [
      ...(values?.categories_supplied ?? []),
      ...(filteredCategories ?? []),
    ]);
    setFieldValue("categories_supplied_new_to_add", []);

    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
        },
      }}
      onOk={onOk}
    >
      <FieldArray
        name="categories_supplied_new_to_add"
        render={(arrayHelpers) => (
          <div>
            {values?.categories_supplied_new_to_add?.map((category, index) => {
              const test = values?.categories_supplied_new_to_add?.[index]
                ?.material_category as MaterialCategoriesItem;

              const categorySuppliedValue = test?.id || null;

              const categorySuppliedFieldName = `categories_supplied_new_to_add.${index}.material_category.id`;

              return (
                <div key={index} className="w-full flex gap-x-3">
                  <FormSelect
                    value={categorySuppliedValue}
                    placeholder={t("companies.category-supplied")}
                    fieldName={categorySuppliedFieldName}
                    options={adaptedMaterialCategories}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                  />
                  <TableButton
                    type="delete"
                    className="mt-2"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                </div>
              );
            })}
            <Button
              type="primary"
              className="btn-primary--dark"
              onClick={() => arrayHelpers.push("")}
            >
              {t("companies.add-category-supplied")}
            </Button>
          </div>
        )}
      />
    </Modal>
  );
};

export { AddCategoriesToCompanyModal };
