import {
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import React, { FC } from "react";
import { WidgetTableData } from "shared/api";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Line } from "react-chartjs-2";
import { getRandomColorForStandAloneGraph } from "shared/helpers/widgets/standAlone";

dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface StandAloneGraphProps {
  widgetsData: WidgetTableData[];
}

interface CustomChartData extends ChartData<"line"> {
  labels: string[];
  datasets: ChartDataset<"line">[];
}

const transformDataToChartData = (data: WidgetTableData[]): CustomChartData => {
  const timezone = localStorage.getItem("timezone") || "UTC";
  const dateFormat = "DD/MM/YYYY HH:mm";

  const labels = data.map((item) =>
    dayjs(item.timestamp).tz(timezone).format(dateFormat)
  );

  const fieldNames = Object.keys(data[0]).filter(
    (key) => key !== "timestamp" && key !== "type"
  );

  const datasets = fieldNames.map((fieldName) => ({
    label: fieldName,
    data: data.map((item) => item[fieldName]),
    borderColor: getRandomColorForStandAloneGraph(),
    backgroundColor: getRandomColorForStandAloneGraph(),
  }));

  return {
    labels,
    datasets,
  };
};

const StandAloneGraph: FC<StandAloneGraphProps> = ({ widgetsData }) => {
  const chartData: CustomChartData = transformDataToChartData(widgetsData);

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback(value: number | string) {
            return value.toString();
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label(context: any) {
            const value =
              context.raw !== undefined ? context.raw.toString() : "undefined";
            return `${value}`;
          },
        },
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="bg-white">
      <Line data={chartData ?? []} options={options} />;
    </div>
  );
};

const StandAloneGraphMemo = React.memo(StandAloneGraph);
export { StandAloneGraphMemo as StandAloneGraph };
