import { notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  CreateMaterialParams,
  MaterialsItem,
  UpdateMaterial,
  useCreateMaterialMutation,
  useDeleteMaterialMutation,
  useUpdateMaterialMutation,
} from "shared/api";
import { handleRequestError } from "shared/helpers";
import { useConfirmDeleteModal } from "./useConfirmDeleteModal";

interface MaterialManagementRequestsProps {
  closeAddMaterialModal?: () => void;
  closeEditMaterialModal?: () => void;
}

const useMaterialManagementRequests = ({
  closeAddMaterialModal,
  closeEditMaterialModal,
}: MaterialManagementRequestsProps) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [createMaterial] = useCreateMaterialMutation();
  const [updateMaterial, { isLoading: isLoadingMaterialUpdate }] =
    useUpdateMaterialMutation();
  const [deleteMaterial, { isLoading: isLoadingDeleteMaterial }] =
    useDeleteMaterialMutation();

  const createMaterialItem = async (params: CreateMaterialParams) => {
    try {
      await createMaterial(params).unwrap();
      api.success({
        message: t("material-category-management.success-title"),
        description: t("material-category-management.success-add-desc"),
      });
      closeAddMaterialModal?.();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-category-management.error-title")
      );
      api.error({
        message: t("material-category-management.error-title"),
        description,
      });
    }
  };

  const updateMaterialItem = async (params: UpdateMaterial) => {
    try {
      await updateMaterial(params).unwrap();
      api.success({
        message: t("material-management.success-title"),
        description: t("material-management.success-update-desc"),
      });

      closeEditMaterialModal?.();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-category-management.error-title")
      );
      api.error({
        message: t("material-category-management.error-title"),
        description,
      });
    }
  };

  const deleteMaterialHandler = async (record: MaterialsItem) => {
    try {
      await deleteMaterial(record).unwrap();
      api.success({
        message: t("material-category-management.success-title"),
        description: t("material-management.success-delete-desc"),
      });
    } catch (error) {
      const description = handleRequestError(
        error,
        t("material-category-management.error-title")
      );

      api.error({
        message: t("material-category-management.error-title"),
        description,
      });
    }
  };

  const showDeleteCategoryModal = useConfirmDeleteModal(
    t("material-category-management.delete-modal-msg"),
    deleteMaterialHandler,
    isLoadingDeleteMaterial
  );

  return {
    contextHolder,
    createMaterialItem,
    updateMaterialItem,
    showDeleteCategoryModal,
    isLoadingMaterialUpdate,
    isLoadingDeleteMaterial,
  };
};

export { useMaterialManagementRequests };
