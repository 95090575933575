import {
  MaterialStockItem,
  useMoveMateriaProductionItemsMutation,
} from "shared/api";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { handleRequestError } from "shared/helpers";
import { useTableKeys } from "./useTableKeys";

interface UseMoveProductionItemsProps {
  orderId: number;
  onClose: () => void;
  onOrderChange: (order: number | null) => void;
}

export const useMoveProductionItems = ({
  orderId,
  onClose,
  onOrderChange,
}: UseMoveProductionItemsProps) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const [api, contextHolder] = notification.useNotification();

  const [moveMateriaProductionItems] = useMoveMateriaProductionItemsMutation();

  const [selectedMaterialItemsKeys, handleMaterialItemsKeys] = useTableKeys();

  const closeModal = () => {
    onClose();
    onOrderChange(null);
    handleMaterialItemsKeys([]);
  };

  const selectTableRows = {
    onChange: (
      _selectedRowKeys: React.Key[],
      selectedRows: MaterialStockItem[]
    ) => {
      handleMaterialItemsKeys(selectedRows.map((row) => row.id));
    },
    getCheckboxProps: (record: MaterialStockItem) => ({
      name: record?.serial_number,
    }),
  };

  const moveItemsHandler = async () => {
    try {
      await moveMateriaProductionItems({
        id: orderId,
        material_item_ids: selectedMaterialItemsKeys as number[],
      }).unwrap();

      api.success({
        message: t("moveSuccess"),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    } finally {
      closeModal();
    }
  };

  return {
    moveItemsHandler,
    selectTableRows,
    closeModal,
    selectedMaterialItemsKeys,
    contextHolder,
  };
};
