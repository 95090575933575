import { Select } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useItemViewMaterialCategoryData } from "shared/hooks";
import { Loader, Page } from "shared/ui";
import { ItemViewTable } from "./ui";

const ItemView = () => {
  const { t } = useTranslation("", { keyPrefix: "itemView" });

  const {
    isLoadingMaterialCategories,
    adaptedMaterialCategories,
    handleSelectMaterialCategory,
    selectedMaterialCategoryItem,
  } = useItemViewMaterialCategoryData();

  return (
    <Page title={t("title")}>
      {isLoadingMaterialCategories && <Loader type="fullscreen-backdrop" />}

      <Title level={5}>{t("selectMaterialCategory")}</Title>

      <Select
        options={adaptedMaterialCategories}
        className="w-1/3"
        placeholder={t("selectMaterialCategory")}
        onChange={handleSelectMaterialCategory}
      />

      <ItemViewTable
        selectedMaterialCategoryItem={selectedMaterialCategoryItem ?? null}
      />
    </Page>
  );
};

export { ItemView };
