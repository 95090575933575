import { Input, Modal, notification, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
  Loader,
} from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError, noop } from "shared/helpers";
import {
  useUpdateMaterialStockBatchMutation,
  EditStockMaterialItem,
  MaterialStockBatchItemResponse,
} from "shared/api";
import { ModalSizeValue, stockMaterialStatuses } from "shared/constants";
import { AddOrEditStockMaterialSchema } from "shared/schemas";
import { AdaptedValues } from "shared/types";

interface UpdatedEditStockMaterialModalProps {
  isOpenEditStockMaterial: boolean;
  hideEditStockModal: () => void;
  itemToEdit: MaterialStockBatchItemResponse | null;
  companiesData: {
    companiesAdapted: AdaptedValues[];
    isLoading: boolean;
  };
}

const UpdatedEditStockMaterialModal: FC<UpdatedEditStockMaterialModalProps> = ({
  isOpenEditStockMaterial,
  hideEditStockModal,
  itemToEdit,
  companiesData,
}) => {
  const { t } = useTranslation();

  const editStockManagementFormik = useFormik<EditStockMaterialItem>({
    initialValues: {
      id: itemToEdit?.id,
      material: itemToEdit?.material?.id,
      batch_code: itemToEdit?.batch_code,
      is_quantity_in_tracker_table: itemToEdit?.is_quantity_in_tracker_table,
      quantity: itemToEdit?.quantity,
      delivery_date: itemToEdit?.delivery_date,
      status: itemToEdit?.status,
      supplier: itemToEdit?.supplier?.id,
    },
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: AddOrEditStockMaterialSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    errors,
    resetForm,
    isValid,
  } = editStockManagementFormik;

  const closeModal = () => {
    hideEditStockModal();
    resetForm();
  };

  const handlePickerChange = (_: DatePickerValue, dateString: string) => {
    setFieldValue("delivery_date", dateString);
  };

  const [api, contextHolder] = notification.useNotification();

  const [updateMaterialStockBatch] = useUpdateMaterialStockBatchMutation();

  const updateStockMaterialItem = async () => {
    try {
      await updateMaterialStockBatch(values).unwrap();

      api.success({
        message: t("stock-material-modals.success-title"),
        description: t("stock-material-modals.success-update-desc"),
      });
      hideEditStockModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("stock-material.error-title")
      );

      api.error({
        message: t("stock-material.error-title"),
        description,
      });
    }
  };

  const isQuantityInTheTrackerItemsVariant =
    !!values?.is_quantity_in_tracker_table;

  if (companiesData.isLoading) {
    return <Loader type="fullscreen-backdrop" />;
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={t("stock-material.edit-modal-title")}
        open={isOpenEditStockMaterial}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        className="p-3.75"
        onCancel={closeModal}
        onOk={() => {
          updateStockMaterialItem();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.material")}
        </Title>

        <Select
          size="large"
          value={itemToEdit?.material?.name}
          disabled
          className="w-full"
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.batchNumber")}
        </Title>

        <FormInput
          value={values.batch_code}
          fieldName="batch_code"
          placeholder={t("stock-material-modals.batchCode-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.batch_code}
          isError={!!errors.batch_code}
        />

        {isQuantityInTheTrackerItemsVariant ? null : (
          <>
            <Title level={5} className="mb-1.25">
              {t("stock-material-modals.quantity")}
            </Title>

            <div className="flex gap-2.5">
              <FormInput
                numberType
                value={values.quantity}
                fieldName="quantity"
                placeholder={t("stock-material-modals.quantity-placeholder")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.quantity}
                isError={!!errors.quantity}
              />

              <Input
                value={itemToEdit?.material?.material_category?.unit?.name}
                disabled
                size="large"
                className="mb-1.25 w-[45%] text-[14px]"
              />
            </div>
          </>
        )}

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.deliveryDate")}
        </Title>

        <GlobalDatePicker
          handlePickerChange={handlePickerChange}
          value={dayjs.utc(values.delivery_date)}
          error={errors.delivery_date}
          isError={!!errors.delivery_date}
        />

        <Title level={5} className="mb-1.25">
          {t("stock-material-modals.status")}
        </Title>
        <FormSelect
          value={values.status}
          placeholder={t("stock-material-modals.status-placeholder")}
          fieldName="status"
          options={stockMaterialStatuses}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status}
          isError={!!errors.status}
        />

        <Title level={5} className="w-full mt-1.25 mb-0">
          {t("stock-material-modals.supplier")}
        </Title>

        <FormSelect
          value={values?.supplier}
          placeholder={t("material-management.suppliers-placeholder")}
          fieldName="supplier"
          options={companiesData.companiesAdapted}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.supplier?.toString()}
          isError={!!errors.supplier}
          showSearch
        />
      </Modal>
    </>
  );
};

const EditStockMaterialModalMemo = React.memo(UpdatedEditStockMaterialModal);

export { EditStockMaterialModalMemo as UpdatedEditStockMaterialModal };
