import { useMemo, useState } from "react";
import { adaptMaterialCategories } from "shared/adapters";
import {
  MaterialsItem,
  useGetMaterialCategoriesWithNoParamsQuery,
} from "shared/api";

interface UseMaterialManagementDataProps {
  showEditMaterialModal: () => void;
}

const useMaterialManagementData = ({
  showEditMaterialModal,
}: UseMaterialManagementDataProps) => {
  const { data: materialCategories } =
    useGetMaterialCategoriesWithNoParamsQuery({ expand: "unit" });

  const materialCategoriesAdapted = useMemo(() => {
    return adaptMaterialCategories(materialCategories || []);
  }, [materialCategories]);

  const [materialItemToUpdate, setMaterialItemToUpdate] =
    useState<MaterialsItem | null>(null);

  const handleMaterialItemToEdit = (materialItem: MaterialsItem) => {
    showEditMaterialModal();
    setMaterialItemToUpdate(materialItem);
  };

  return {
    materialCategories,
    materialCategoriesAdapted,
    materialItemToUpdate,
    handleMaterialItemToEdit,
  };
};

export { useMaterialManagementData };
