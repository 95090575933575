import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { QualityResultItem } from "shared/api";

interface TableMaterialStockBatchProps {
  record: QualityResultItem;
}

const TableMaterialStockBatch: FC<TableMaterialStockBatchProps> = ({
  record,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-hull h-full flex flex-wrap gap-x-2">
      {!record?.material_stock_batches?.length && t("quality.no-data")}

      {record.material_stock_batches.map((materialStockBatch) => (
        <span key={materialStockBatch.id}>
          {materialStockBatch?.material_stock_batch?.batch_code}
        </span>
      ))}
    </div>
  );
};

export { TableMaterialStockBatch };
