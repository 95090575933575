import { Widget, WidgetTypeEnum } from "shared/api";
import {
  getColumnsValuesByWidgetType,
  getSortByOptionsByWidgetType,
} from "shared/helpers";
import { useModal } from "./useModal";

interface WidgetSettingsProps {
  widget: Widget;
}

export const useWidgetSettings = ({ widget }: WidgetSettingsProps) => {
  const [isVisibleSettings, showSettings, hideSettings] = useModal();

  const [isVisibleConfigurations, showConfigurations, hideConfigurations] =
    useModal();

  const isEmptyConfiguration = widget?.configuration?.length === 0;

  const disableSettingsButton =
    isEmptyConfiguration &&
    widget?.type === WidgetTypeEnum.PRODUCTION_DATA_TRACKER;

  const widgetColumnsOptions = getColumnsValuesByWidgetType(
    widget.type as WidgetTypeEnum
  );

  const widgetSortByOptions = getSortByOptionsByWidgetType(
    widget?.type as WidgetTypeEnum
  );

  return {
    isVisibleSettings,
    showSettings,
    hideSettings,
    isVisibleConfigurations,
    showConfigurations,
    hideConfigurations,
    disableSettingsButton,
    widgetColumnsOptions,
    widgetSortByOptions,
  };
};
