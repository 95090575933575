import { useState } from "react";
import { MaterialItemsWidgetResponse } from "shared/api";

interface ItemViewMassUpdateProps {
  materialItems?: MaterialItemsWidgetResponse;
}

const useItemViewMassUpdate = ({ materialItems }: ItemViewMassUpdateProps) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const mappedMaterialItems = materialItems?.results.map((item) => ({
    ...item,
    key: item.id,
  }));

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      const allKeys = mappedMaterialItems?.map((card) => card.key);
      setSelectedKeys(allKeys ?? []);
    } else {
      setSelectedKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys);
    },
    onSelectAll: (selected: boolean) => {
      onSelectAll(selected);
    },
    getCheckboxProps: (record: any) => ({
      name: record.key,
    }),
  };

  return { selectedKeys, rowSelection };
};

export { useItemViewMassUpdate };
