import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "shared/api";
import { UserReducer } from "./types";

const initialState: UserReducer = {
  token: "",
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
      }
    );

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      localStorage.clear();
      return initialState;
    });
  },
});

export const {
  reducer: userReducer,
  actions: { logout },
} = user;
