import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { RootState } from "../stores/rootReducer";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).user;

    if (token) {
      headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  },
});

export const baseQueryWrapper: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error && result?.error?.status === 401) {
    localStorage.clear();
    api.dispatch({ type: "user/logout" });
  }

  return result;
};

export { baseQueryWrapper as baseQuery };
