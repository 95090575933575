import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateQualityResult,
  DownloadReelLabResult,
  GenerateReelLabArchive,
  QualityResultItem,
  QualityResultsParams,
  QualityResultsResponse,
  UpdateQualityResult,
  UploadQualityDocument,
} from "./models";

export const qualityResultsApi = createApi({
  baseQuery,
  reducerPath: "qualityResultsApi",
  tagTypes: ["qualityResults"],
  endpoints: (builder) => ({
    getQualityResults: builder.query<
      QualityResultsResponse,
      QualityResultsParams
    >({
      query: (params) => ({
        url: "/api/v1/quality_results/",
        method: "GET",
        params,
      }),
      providesTags: ["qualityResults"],
    }),

    createQualityResult: builder.mutation<
      QualityResultsResponse,
      CreateQualityResult
    >({
      query: (body) => ({
        url: "/api/v1/quality_results/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["qualityResults"],
    }),

    uploadQualityDocument: builder.mutation<
      QualityResultsResponse,
      UploadQualityDocument
    >({
      query: (body) => ({
        url: `/api/v1/quality_results/${body.id}/document/`,
        method: "POST",
        body: body.document,
        formData: true,
      }),
      invalidatesTags: ["qualityResults"],
    }),

    deleteQualityResult: builder.mutation<void, QualityResultItem>({
      query: (body) => ({
        url: `/api/v1/quality_results/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["qualityResults"],
    }),

    updateQualityResult: builder.mutation<
      QualityResultsResponse,
      UpdateQualityResult
    >({
      query: (body) => ({
        url: `/api/v1/quality_results/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["qualityResults"],
    }),

    exportReelLabResult: builder.query<void, DownloadReelLabResult>({
      query: (body) => ({
        url: `/api/v1/quality_results/${body.id}/export_reel_lab_result_xls/`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportReelLabArchive: builder.query<void, GenerateReelLabArchive>({
      query: (body) => ({
        url: `/api/v1/quality_results/${body.id}/export_reel_lab_archive_pdf/`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetQualityResultsQuery,
  useCreateQualityResultMutation,
  useUploadQualityDocumentMutation,
  useDeleteQualityResultMutation,
  useUpdateQualityResultMutation,
  useExportReelLabResultQuery,
  useExportReelLabArchiveQuery,
} = qualityResultsApi;
