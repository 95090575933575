import { AddOrUpdateOrderValues } from "shared/types";
import React from "react";
import { RenderClearFiltersButton } from "shared/ui";

interface RenderClearOrderFiltersButtonProps {
  clearAllFilters: () => void;
  poNumberContains: string;
  filterStatusValue: string;
  productionDateLesser: string | null;
  productionDateGreater: string | null;
  wantedDeliveryDateLesser: string | null;
  wantedDeliveryDateGreater: string | null;
  quantityLowerThen?: string | number | null;
  quantityGreaterThen?: string | number | null;
  orderCodeContains: string;
  maxQuantityValue: number;
  values: AddOrUpdateOrderValues;
  productCardContainsValue: string;
}

export const useRenderClearOrderFiltersButton = ({
  clearAllFilters,
  poNumberContains,
  filterStatusValue,
  productionDateLesser,
  productionDateGreater,
  wantedDeliveryDateLesser,
  wantedDeliveryDateGreater,
  quantityLowerThen,
  quantityGreaterThen,
  orderCodeContains,
  maxQuantityValue,
  values,
  productCardContainsValue,
}: RenderClearOrderFiltersButtonProps) => {
  const renderButtonAndClearAllFilters = () => {
    const isPoNumber = poNumberContains && poNumberContains.length > 0;

    const isFilterStatusValue =
      (values.filterStatusValue && values.filterStatusValue.length > 0) ||
      (filterStatusValue && filterStatusValue.length > 0);

    const isProductionDateLesser =
      productionDateLesser && productionDateLesser.length > 0;

    const isProductionDateGreater =
      productionDateGreater && productionDateGreater.length > 0;

    const isWantedDeliveryDateLesser =
      wantedDeliveryDateLesser && wantedDeliveryDateLesser.length > 0;

    const isWantedDeliveryDateGreater =
      wantedDeliveryDateGreater && wantedDeliveryDateGreater.length > 0;

    const isLowerQuantity = Number(quantityLowerThen) < maxQuantityValue;

    const isGreaterQuantity = Number(quantityGreaterThen) > 0;

    const isOrderCodeValue = orderCodeContains && orderCodeContains.length > 0;

    const isProductCardContainsValue =
      productCardContainsValue && productCardContainsValue.length > 0;

    const globalCondition =
      isPoNumber ||
      isFilterStatusValue ||
      isProductionDateLesser ||
      isProductionDateGreater ||
      isWantedDeliveryDateLesser ||
      isWantedDeliveryDateGreater ||
      isLowerQuantity ||
      isGreaterQuantity ||
      isOrderCodeValue ||
      isProductCardContainsValue;

    if (globalCondition) {
      return <RenderClearFiltersButton onClick={() => clearAllFilters()} />;
    }

    return null;
  };

  return {
    renderButtonAndClearAllFilters,
  };
};
