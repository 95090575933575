import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import React, { useMemo, useState } from "react";
import { ExpandUnitItem, MaterialCategoriesItem } from "shared/api";
import { materialCategoriesInCompanyData } from "shared/constants";
import { AdaptedValues, AddCompanyValues } from "shared/types";
import { TableButton } from "shared/ui";

interface UseCompaniesSuppliedProps {
  t: TFunction;
  values: AddCompanyValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  adaptedMaterialCategories: AdaptedValues[];
  hideModal: () => void;
  isEditMode?: boolean;
  materialCategories: MaterialCategoriesItem[];
}

const useCompaniesSupplied = ({
  t,
  values,
  setFieldValue,
  adaptedMaterialCategories,
  hideModal,
  isEditMode,
  materialCategories,
}: UseCompaniesSuppliedProps) => {
  const editModeIds =
    values?.categories_supplied?.map(
      (item) =>
        (item?.material_category as MaterialCategoriesItem)?.id as number
    ) || [];

  const addModeIds =
    values?.categories_supplied?.map(
      (item) => item?.material_category as number
    ) || [];

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
    isEditMode ? editModeIds : addModeIds
  );

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      const allKeys = adaptedMaterialCategories.map((card) => card.value);
      setSelectedKeys(allKeys);
    } else {
      setSelectedKeys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys);
    },
    onSelectAll: (selected: boolean) => {
      onSelectAll(selected);
    },
    // TODO: fix any type with AdaptedValues | MaterialCategoriesItem
    getCheckboxProps: (record: any) => ({
      name: record.label,
    }),
  };

  const removeItem = (key: React.Key) => {
    const newSelectedKeys = selectedKeys.filter((item) => item !== key);
    setSelectedKeys(newSelectedKeys);

    setFieldValue(
      "categories_supplied",
      newSelectedKeys.map((key) => ({
        material_category: isEditMode ? { id: key } : key,
      }))
    );
  };

  const { name, unit, uniqueCode, actions } = materialCategoriesInCompanyData;

  const newItemsColumns: ColumnsType<AdaptedValues> = [
    {
      title: t("companies.material-category"),
      width: "100%",
      render: (record: AdaptedValues) => record?.label,
    },
  ];

  const selectedColumns: ColumnsType<any> = [
    {
      title: t(name.title),
      width: name.width,
      render: (record: MaterialCategoriesItem) => <span>{record?.name}</span>,
    },
    {
      title: t(unit.title),
      width: unit.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{(record?.unit as ExpandUnitItem)?.name}</span>
      ),
    },
    {
      title: t(uniqueCode.title),
      width: uniqueCode.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{record?.unique_code}</span>
      ),
    },
  ];

  const addedItemsColumns: ColumnsType<MaterialCategoriesItem> = [
    {
      title: t(name.title),
      width: name.width,
      render: (record: MaterialCategoriesItem) => <span>{record?.name}</span>,
    },
    {
      title: t(unit.title),
      width: unit.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{(record?.unit as ExpandUnitItem)?.name}</span>
      ),
    },
    {
      title: t(uniqueCode.title),
      width: uniqueCode.width,
      render: (record: MaterialCategoriesItem) => (
        <span>{record?.unique_code}</span>
      ),
    },
    {
      title: t(actions.title),
      width: "100%",
      render: (record: AdaptedValues) => {
        return (
          <TableButton type="delete" onClick={() => removeItem(record?.key!)} />
        );
      },
    },
  ];

  const addCategories = () => {
    setFieldValue(
      "categories_supplied",
      selectedKeys.map((key) => ({
        material_category: isEditMode ? { id: key } : key,
      }))
    );

    hideModal();
  };

  const selectedMaterialCategories = useMemo(() => {
    return materialCategories
      ?.map((item) => ({
        ...item,
        key: item?.id,
      }))
      .filter((card) => selectedKeys.includes(card?.id as number));
  }, [selectedKeys, adaptedMaterialCategories]);

  return {
    rowSelection,
    newItemsColumns,
    selectedColumns,
    addCategories,
    selectedMaterialCategories,
    addedItemsColumns,
    removeItem,
  };
};

export { useCompaniesSupplied };
