export const orderProcessStepParams = {
  expand: "order,process_step",
  orderStatus: "AC",
};

export const orderPlansParams = {
  expand:
    "order_plan_for_machine.order_process_step_tracker.order,order_plan_for_machine.order_process_step_tracker.process_step",
};

export const enum ResizingItemEdgeProps {
  LEFT = "left",
  RIGHT = "right",
}

export const todayMarketStyles = {
  backgroundColor: "#F68787",
  width: "2px",
  zIndex: "50",
  currentTimeLabel: `
      w-[50px] flex items-center 
      justify-center h-auto p-1 
      rounded-tr-xl rounded-br-xl
      bg-red-200 text-white text-[12px]
    `,
};
