import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import {
  authApi,
  configurationsApi,
  reportsApi,
  usersApi,
  materialsApi,
  suppliersApi,
  productCardApi,
  ordersApi,
  packagingApi,
  qualityResultsApi,
  dashboardsApi,
  standAloneApi,
} from "shared/api";
import { isDev } from "../helpers";
import { rootReducer } from "./rootReducer";

const createStore = () => {
  const logger = createLogger({
    collapsed: true,
  });

  const defaultMiddleware = [
    thunk,
    authApi.middleware,
    usersApi.middleware,
    materialsApi.middleware,
    suppliersApi.middleware,
    productCardApi.middleware,
    ordersApi.middleware,
    packagingApi.middleware,
    qualityResultsApi.middleware,
    reportsApi.middleware,
    dashboardsApi.middleware,
    configurationsApi.middleware,
    standAloneApi.middleware,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: isDev ? [logger, ...defaultMiddleware] : [...defaultMiddleware],
  });

  return store;
};

export const store = createStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
