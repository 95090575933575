import { Colors } from "shared/themes";

export const categorySectionContainer = {
  width: "100%",
  height: "auto",
  borderRadius: "12px",
  padding: "15px",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  marginBottom: "15px",
};
export const categorySectionHeader = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const headerTitleContainer = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
};

export const headerButtonContainer = { display: "flex", gap: "10px" };

export const formContainer = {
  width: "100%",
  height: "auto",
  border: `1px solid ${Colors.gray[100]}`,
  borderRadius: "12px",
  marginTop: "10px",
  padding: "15px",
  overflow: "auto",
};

export const formRowContainer = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const checkboxContainer = {
  width: "10%",
  alignSelf: "flex-end",
  paddingBottom: "15px",
};

export const checkboxTitle = {
  display: "inline-block",
  marginBottom: "0px",
  marginLeft: "10px",
};

export const formRowButtonContainer = {
  width: "10%",
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

export const addNewRowButton = {
  alignSelf: "center",
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `${Colors.green[100]}`,
  width: "auto",
};

export const removeRowButton = {
  alignSelf: "center",
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "auto",
};

export const iconScale = {
  scale: "1.5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
