import { Input, Modal } from "antd";
import {
  ModalSizeValue,
  widgetsData,
  hideButtonStyles,
} from "shared/constants";
import React, { FC, useState } from "react";
import { useWidgetsData } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { WidgetsSection } from "./WidgetsSection";
import { EmptyWidgetsState } from "./EmptyWidgetsState";

interface WidgetsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WidgetsModal: FC<WidgetsModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const { filteredWidgets } = useWidgetsData({ widgetsData, searchValue });

  return (
    <Modal
      title={t("dashboards.widgets-modal-title")}
      open={isOpen}
      onCancel={onClose}
      width={ModalSizeValue.XL_MEDIUM}
      okButtonProps={hideButtonStyles}
      cancelButtonProps={hideButtonStyles}
    >
      <Input
        className="mb-5"
        size="large"
        value={searchValue}
        placeholder={t("dashboards.widgets-input-placeholder")!}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />

      <div className="w-full flex flex-wrap gap-y-4">
        {filteredWidgets.length ? (
          filteredWidgets?.map((category) => (
            <WidgetsSection
              key={category.category}
              category={category}
              onClose={onClose}
            />
          ))
        ) : (
          <EmptyWidgetsState />
        )}
      </div>
    </Modal>
  );
};

const WidgetsModalMemo = React.memo(WidgetsModal);

export { WidgetsModalMemo as WidgetsModal };
