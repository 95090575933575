import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { MaterialsItem, Widget } from "shared/api";
import {
  getConfigurationValues,
  getConfigurationsColors,
} from "shared/helpers";

interface MaterialLevelGraphProps {
  materials: {
    allMaterials: MaterialsItem[] | undefined;
    isLoadingAllMaterials: boolean;
  };
}

export const useMaterialLevelGraph = ({
  materials,
}: MaterialLevelGraphProps) => {
  const { values, setFieldValue } = useFormikContext<Widget>();

  // Get formik value and field for material parameter
  const {
    materialCategoryField: materialParameterField,
    dynamicFieldField: materialField,
    dynamicFieldValue: materialValue,
  } = getConfigurationValues(values);

  // Get formik values and fields for colors
  const {
    redColorValueField,
    redColorValue,
    yellowColorValue,
    yellowColorValueField,
  } = getConfigurationsColors(values);

  // Name to automatically update the widget name when a material is selected
  const selectedMaterialName = useMemo(() => {
    const material = materials.allMaterials?.find(
      (material) => material.id === materialValue
    );

    return material?.name;
  }, [materialValue]);

  // Set "material" as configuration field name when material is selected
  useEffect(() => {
    if (materialValue) {
      setFieldValue(materialParameterField, "material");
      // Update widget name to make it clear to understand what material is selected
      setFieldValue("name", `${selectedMaterialName || values?.name}`);
    }
  }, [materialValue]);

  return {
    materialField,
    materialValue,
    redColorValueField,
    redColorValue,
    yellowColorValue,
    yellowColorValueField,
  };
};
