import { Button, Table, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { AddDynamicOrderSteps } from "shared/types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  OrderStepTrackerResponseItem,
  useGetMaterialItemsForProductionPageQuery,
} from "shared/api";
import { getTrackingListOptions } from "shared/helpers";
import { adaptOutputMaterials } from "shared/adapters";
import { RenderClearFiltersButton } from "shared/ui";
import {
  useDateFilters,
  useDebouncedTableFilter,
  useGenerateTrackingListMaterialItemsColumns,
  useModal,
  useOrderProductionSet,
  useTrackingListRequests,
} from "shared/hooks";
import { trackingListExpand, trackingListOrdering } from "shared/constants";
import { PackagesModal } from "../Packages/PackagesModal";
import { ChangeOrderProductionSetModal } from "./ChangeOrderProductionSetModal";
import { MoveItemsModal } from "./MoveItemsModal";
import { TrackingListHeader } from "./TrackingListHeader";

interface TrackingListProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const TrackingList: FC<TrackingListProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation();
  const [, contextHolder] = notification.useNotification();

  const [visiblePackagesModal, showPackagesModal, hidePackagesModal] =
    useModal();

  const [isVisibleMoveItemsModal, showMoveItemsModal, hideMoveItemsModal] =
    useModal();

  const { values } = useFormikContext<AddDynamicOrderSteps>();

  const { orderCode, orderId, outputMaterials, firstMaterialFromList } =
    getTrackingListOptions(orderStepTrackerResponseItem);

  const adaptedOutputMaterial = useMemo(() => {
    return adaptOutputMaterials(outputMaterials ?? []);
  }, [outputMaterials]);

  const {
    dateGreater,
    dateLesser,
    clearDates,
    handleDateGreater,
    handleDateLesser,
    isDataFilterActive,
  } = useDateFilters();

  const {
    dateGreater: movedDateGreater,
    dateLesser: movedDateLesser,
    clearDates: movedClearDates,
    handleDateGreater: movedHandleDateGreater,
    handleDateLesser: movedHandleDateLesser,
    isDataFilterActive: isMovedDataFilterActive,
  } = useDateFilters();

  const {
    debouncedValue: debouncedSerialNumberValue,
    handleFilterChange: handleSerialNumberValue,
    value: serialNumberValue,
    clearFilterValue: clearSerialNumberValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedMovedSerialNumberValue,
    handleFilterChange: handleMovedSerialNumberValue,
    value: movedSerialNumberValue,
    clearFilterValue: clearMovedSerialNumberValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedSetValue,
    handleFilterChange: handleSetValue,
    value: setValue,
    clearFilterValue: clearSetValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedSetMovedValue,
    handleFilterChange: handleSetMovedValue,
    value: setMovedValue,
    clearFilterValue: clearSetMovedValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedPosValue,
    handleFilterChange: handlePosValue,
    value: posValue,
    clearFilterValue: clearPosValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedPosMovedValue,
    handleFilterChange: handlePosMovedValue,
    value: posMovedValue,
    clearFilterValue: clearPosMovedValue,
  } = useDebouncedTableFilter("", 400);

  const [statusFilter, setStatusFilter] = useState<number | string>("");

  const handleStatusFilter = (value: number | string) => setStatusFilter(value);

  const [movedStatusFilter, setMovedStatusFilter] = useState<number | string>(
    ""
  );

  const handleMovedStatusFilter = (value: number | string) => {
    setMovedStatusFilter(value);
  };

  const isActiveMaterialItemsTableFilters =
    isDataFilterActive ||
    serialNumberValue ||
    setValue ||
    posValue ||
    statusFilter;

  const clearMaterialItemsTableFilters = () => {
    clearDates();
    clearSerialNumberValue();
    clearSetValue();
    clearPosValue();
    setStatusFilter("");
  };

  const isActiveMovedMaterialItemsTableFilters =
    isMovedDataFilterActive ||
    movedSerialNumberValue ||
    setMovedValue ||
    posMovedValue ||
    movedStatusFilter;

  const clearMovedMaterialItemsTableFilters = () => {
    movedClearDates();
    clearMovedSerialNumberValue();
    clearSetMovedValue();
    clearPosMovedValue();
    setMovedStatusFilter("");
  };

  const [materialItemId, setMaterialItemId] = useState<number | null>(
    Number(firstMaterialFromList)
  );

  const handleMaterialItemId = (value: number) => setMaterialItemId(value);

  // Set Tables materialItemId filter when process step or order changes
  useEffect(() => {
    if (firstMaterialFromList) {
      setMaterialItemId(Number(firstMaterialFromList));
    }
  }, [firstMaterialFromList]);

  const {
    data: materialItems,
    isLoading,
    isFetching,
  } = useGetMaterialItemsForProductionPageQuery(
    {
      material: materialItemId as number,
      order_production_set__order_process_step_tracker: values.id as number,
      ordering: trackingListOrdering,
      expand: trackingListExpand,
      timestamp__gte: dateGreater || undefined,
      timestamp__lte: dateLesser || undefined,
      serial_number__icontains: debouncedSerialNumberValue,
      order_production_set__set_number: Number(debouncedSetValue) || undefined,
      order_production_set_position: Number(debouncedPosValue) || undefined,
      status: statusFilter as string,
      produced_in_order: orderId,
    },
    {
      skip: !materialItemId || !orderId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const {
    data: movedMaterialItems,
    isLoading: isLoadingMovedMaterialItems,
    isFetching: isFetchingMovedMaterialItems,
  } = useGetMaterialItemsForProductionPageQuery(
    {
      material: materialItemId as number,
      ordering: trackingListOrdering,
      expand: trackingListExpand,
      moved_to_order: orderId,
      timestamp__gte: movedDateGreater || undefined,
      timestamp__lte: movedDateLesser || undefined,
      serial_number__icontains: debouncedMovedSerialNumberValue,
      order_production_set__set_number:
        Number(debouncedSetMovedValue) || undefined,
      order_production_set_position:
        Number(debouncedPosMovedValue) || undefined,
      status: movedStatusFilter as string,
    },
    {
      skip: !materialItemId || !orderId,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const {
    createMaterialItemReport,
    handleMaterialReports,
    handlePackageReports,
    createPackageReport,
  } = useTrackingListRequests();

  const {
    isVisibleOrderProductionSetModal,
    openModalAndSelectOrder,
    closeModalAndClearOrder,
    orderProductionSetId,
  } = useOrderProductionSet();

  const columnMaterialItems = useGenerateTrackingListMaterialItemsColumns({
    t,
    openModalAndSelectOrder,
    posValue,
    clearPosValue,
    handlePosValue,
    handleDateGreater,
    handleDateLesser,
    createPackageReport,
    createMaterialItemReport,
    showPackagesModal,
    handleMaterialReports,
    handlePackageReports,
    serialNumberValue,
    handleSerialNumberValue,
    clearSerialNumberValue,
    setValue,
    clearSetValue,
    handleSetValue,
    statusFilter,
    handleStatusFilter,
    isDataFilterActive,
  });

  const movedColumnMaterialItems = useGenerateTrackingListMaterialItemsColumns({
    t,
    openModalAndSelectOrder,
    setValue: setMovedValue,
    clearSetValue: clearSetMovedValue,
    handleSetValue: handleSetMovedValue,
    handleDateGreater: movedHandleDateGreater,
    handleDateLesser: movedHandleDateLesser,
    createPackageReport,
    createMaterialItemReport,
    showPackagesModal,
    handleMaterialReports,
    handlePackageReports,
    serialNumberValue: movedSerialNumberValue,
    handleSerialNumberValue: handleMovedSerialNumberValue,
    clearSerialNumberValue: clearMovedSerialNumberValue,
    posValue: posMovedValue,
    handlePosValue: handlePosMovedValue,
    clearPosValue: clearPosMovedValue,
    statusFilter: movedStatusFilter,
    handleStatusFilter: handleMovedStatusFilter,
    isDataFilterActive: isMovedDataFilterActive,
  });

  return (
    <>
      {contextHolder}

      <div className="w-screen overflow-auto mt-4">
        <TrackingListHeader
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          adaptedOutputMaterial={adaptedOutputMaterial}
          isDataFilterActive={isDataFilterActive}
          materialItemId={materialItemId}
          handleMaterialItemId={handleMaterialItemId}
        />

        {isActiveMaterialItemsTableFilters && (
          <RenderClearFiltersButton onClick={clearMaterialItemsTableFilters} />
        )}

        <Table
          loading={isLoading || isFetching}
          columns={columnMaterialItems}
          dataSource={materialItems ?? []}
          rowKey={(obj) => obj.id!}
          rowClassName={(record) =>
            record?.calc_is_moved ? "!bg-gray-200" : ""
          }
        />

        <div className="flex gap-x-4 items-center">
          <Title level={5}>{t("orders.movedItemsTitle")!}</Title>
          <Button
            onClick={showMoveItemsModal}
            type="primary"
            className="btn-primary--dark"
          >
            {t("orders.moveItems")!}
          </Button>
        </div>

        {isActiveMovedMaterialItemsTableFilters && (
          <RenderClearFiltersButton
            onClick={clearMovedMaterialItemsTableFilters}
          />
        )}

        <Table
          loading={isLoadingMovedMaterialItems || isFetchingMovedMaterialItems}
          columns={movedColumnMaterialItems}
          dataSource={movedMaterialItems ?? []}
          rowKey={(obj) => obj.id!}
        />

        <PackagesModal
          isOpenModal={visiblePackagesModal}
          hidePackagesModal={hidePackagesModal}
          orderID={orderId}
          orderCode={orderCode}
        />

        <ChangeOrderProductionSetModal
          isOpen={isVisibleOrderProductionSetModal}
          onClose={closeModalAndClearOrder}
          orderProductionSetId={orderProductionSetId}
        />

        <MoveItemsModal
          isOpen={isVisibleMoveItemsModal}
          onClose={hideMoveItemsModal}
          orderId={orderId}
          materialItemId={materialItemId}
        />
      </div>
    </>
  );
};

export { TrackingList };
