import { Alert, Button, Popover } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC } from "react";
import {
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import {
  getProductionOutputMaterialInfo,
  openHyperlinkInTab,
} from "shared/helpers";
import { useTranslation } from "react-i18next";

interface UpdatedOutputMaterialHeaderProps {
  outputMaterials: ProductionOutputMaterial[];
  index: number;
  isEditMode: boolean[];
  toggleEditMode: (index: number) => void;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const UpdatedOutputMaterialHeader: FC<UpdatedOutputMaterialHeaderProps> = ({
  outputMaterials,
  index,
  isEditMode,
  toggleEditMode,
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const { materialLink, materialTitle, isVisibleEditModeButton } =
    getProductionOutputMaterialInfo(
      t,
      index,
      outputMaterials,
      orderStepTrackerResponseItem
    );

  const isActiveEditMode = isEditMode?.[index];

  return (
    <div className="w-full flex items-center justify-between p-2">
      <Popover
        content={
          <Alert showIcon type="info" message={t("hyperlink-material")} />
        }
      >
        <Title
          level={5}
          onClick={() => openHyperlinkInTab(materialLink)}
          className="!text-blue-500 hover:underline cursor-pointer"
        >
          {materialTitle}
        </Title>
      </Popover>

      {isVisibleEditModeButton ? (
        <Button
          onClick={() => toggleEditMode(index)}
          type="primary"
          className="btn-primary--dark h-10 !bg-blue-500 hover:!brightness-150 text-[18px]"
        >
          {isActiveEditMode ? t("hideEditMode") : t("showEditMode")}
        </Button>
      ) : null}
    </div>
  );
};

export { UpdatedOutputMaterialHeader };
