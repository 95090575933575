import { Badge, Select } from "antd";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ConfigDynamicTrackerFields,
  DynamicKeyValue,
  MaterialCategoryFieldItem,
} from "shared/api";

interface UpdateOutputMaterialChoicesSelectProps {
  fieldName: string;
  fieldValue: DynamicKeyValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  section: ConfigDynamicTrackerFields;
  cardIndex: number;
  field: MaterialCategoryFieldItem;
}

const UpdateOutputMaterialChoicesSelect: FC<
  UpdateOutputMaterialChoicesSelectProps
> = ({ fieldName, fieldValue, setFieldValue, section, cardIndex, field }) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });

  const currentField = section?.fields?.[cardIndex];

  const { isRequired } = useMemo(
    () => ({
      isRequired: currentField?.required,
    }),
    [currentField]
  );

  return (
    <Badge.Ribbon text="" color={isRequired ? "red" : "transparent"}>
      <Select
        value={fieldValue}
        onChange={(value) => setFieldValue(fieldName, value)}
        className="w-full"
        placeholder={t("selectPlaceholder", { field: field.name })!}
      >
        {(field.choices as string[] | number[])?.map((choice: any) => (
          <Select.Option key={choice} value={choice}>
            {choice}
          </Select.Option>
        ))}
      </Select>
    </Badge.Ribbon>
  );
};

export { UpdateOutputMaterialChoicesSelect };
