import { DragOutlined } from "@ant-design/icons";
import { useFormikContext } from "formik";
import React, { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ConfigDynamicTrackerFields, UnitItemWithLabel } from "shared/api";
import {
  ButtonsContainer,
  DescriptionSelect,
  NameSelect,
  TypeSelect,
  UnitSelect,
} from "shared/features";
import { DynamicFormPopConfirm } from "shared/ui";

interface DynamicCardRowProps {
  index: number;
  fieldIndex: number;
  adaptedDimensionUnits: UnitItemWithLabel[];
  remove: <T>(index: number) => T | undefined;
  push: (obj: any) => void;
}

interface DynamicCardValues {
  sections: ConfigDynamicTrackerFields[];
}

const DynamicCardRow: FC<DynamicCardRowProps> = ({
  index,
  fieldIndex,
  adaptedDimensionUnits,
  remove,
  push,
}) => {
  const cardRef = useRef(null);

  const { values, setFieldValue } = useFormikContext<DynamicCardValues>();

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const dragCard = values.sections[index].fields[dragIndex];

    const newFields = [...values.sections[index].fields];

    newFields.splice(dragIndex, 1);

    newFields.splice(hoverIndex, 0, dragCard);

    setFieldValue(`sections.${index}.fields`, newFields);
  };

  const [{ isDragging }, drag] = useDrag({
    type: "field",
    item: { type: "field", index: fieldIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "field",
    drop: (item: { type: string; index: number }) => {
      const dragIndex = item.index;
      const hoverIndex = fieldIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  drag(drop(cardRef));

  return (
    <div
      ref={cardRef}
      key={fieldIndex}
      className="flex items-center justify-between p-1"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <DragOutlined className="mt-6 cursor-move" />

      <NameSelect index={index} fieldIndex={fieldIndex} />

      <TypeSelect index={index} fieldIndex={fieldIndex} />

      <DescriptionSelect index={index} fieldIndex={fieldIndex} />

      <UnitSelect
        index={index}
        fieldIndex={fieldIndex}
        adaptedDimensionUnits={adaptedDimensionUnits}
        isEdit
      />

      <DynamicFormPopConfirm index={index} fieldIndex={fieldIndex} />

      <ButtonsContainer
        index={index}
        fieldIndex={fieldIndex}
        remove={() => remove(fieldIndex)}
        push={push}
      />
    </div>
  );
};

export { DynamicCardRow };
