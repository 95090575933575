import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, InputRef } from "antd";
import { FilterConfirmProps } from "antd/es/table/interface";
import Title from "antd/es/typography/Title";
import React, { FC, useEffect, useRef } from "react";

interface TableSearchFilterProps {
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  confirm: (param?: FilterConfirmProps) => void;
  clearFilters: (() => void) | undefined;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  visible: boolean;
  placeholder?: string;
  title?: string;
}

const TableSearchFilter: FC<TableSearchFilterProps> = ({
  setSelectedKeys,
  clearFilters,
  confirm,
  value,
  handleFilterChange,
  visible,
  placeholder = "Enter value",
  title,
}) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [visible]);

  const clearAllValues = () => {
    setSelectedKeys([]);
    clearFilters?.();
    confirm();
  };

  return (
    <div className="flex-col p-3">
      <Title level={5}>{title}</Title>

      <div className="flex items-center justify-between ">
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleFilterChange(e);
          }}
          onPressEnter={() => {
            confirm();
          }}
        />

        <Button
          onClick={clearAllValues}
          className="btn-filter--x_mark"
          type="primary"
        >
          <CloseOutlined />
        </Button>
      </div>
    </div>
  );
};

export { TableSearchFilter };
