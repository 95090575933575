import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { UploadExcel } from "pages/SupplyChain/MaterialManagement/ui/UploadExcel";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialsItem } from "shared/api";
import { useStockManagementRequests } from "shared/hooks";

interface StockManagementHeaderProps {
  showAddStockModal: () => void;
  selectedMaterial: MaterialsItem | null;
}

const StockManagementHeader: FC<StockManagementHeaderProps> = ({
  selectedMaterial,
  showAddStockModal,
}) => {
  const { t } = useTranslation();

  const [isVisibleExcelModal, setIsVisibleExcelModal] = useState(false);
  const toggleExcelModal = () => setIsVisibleExcelModal(!isVisibleExcelModal);

  const { downloadMaterialCategoryTemplate } = useStockManagementRequests();

  return (
    <div className="flex gap-x-2">
      <Button
        type="primary"
        className="btn-primary--dark "
        onClick={showAddStockModal}
      >
        {t("stock-material.add-btn")}
        <PlusOutlined />
      </Button>

      <Button
        type="primary"
        className="btn-primary--dark"
        onClick={() =>
          downloadMaterialCategoryTemplate({
            materialCategoryId: selectedMaterial?.material_category
              ?.id as number,
            materialName: selectedMaterial?.name as string,
          })
        }
      >
        {t("stock-material.download-excel")}
      </Button>

      <Button
        onClick={toggleExcelModal}
        type="primary"
        className="btn-primary--dark"
      >
        {t("stock-material.upload-excel-file")}
      </Button>

      <UploadExcel
        isOpen={isVisibleExcelModal}
        onClose={toggleExcelModal}
        stockMaterialToManage={selectedMaterial}
      />
    </div>
  );
};

export { StockManagementHeader };
