import { Modal, notification } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { FormInput, FormSelect } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useCreateOrderPlanMutation } from "shared/api";
import { ModalSizeValue, orderPlanSelectValues } from "shared/constants";
import { AddOrderPlanValues } from "shared/types";

interface AddPlanModalProps {
  isOpenModal: boolean;
  setOpenAddPlanModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPlanModal: FC<AddPlanModalProps> = ({
  isOpenModal,
  setOpenAddPlanModal,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const {
    values,
    resetForm,
    handleBlur,
    handleChange,
    isValid,
    setFieldValue,
    errors,
  } = useFormikContext<AddOrderPlanValues>();

  const [createOrderPlan] = useCreateOrderPlanMutation();

  const createOrderPlanRequest = async () => {
    try {
      await createOrderPlan({
        name: values.name!,
        status: values.status!,
      }).unwrap();
      api.success({
        message: t("plans.success-title"),
        description: t("plans.success-add-msg"),
      });
      setOpenAddPlanModal(false);
      resetForm();
    } catch (error) {
      const description = handleRequestError(error, t("plans.error-title"));
      api.error({
        message: t("plans.error-title"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("plans.add-plan")}
        open={isOpenModal}
        width={ModalSizeValue.SECONDARY}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={() => {
          setOpenAddPlanModal(false);
          resetForm();
        }}
        onOk={() => {
          createOrderPlanRequest();
        }}
      >
        <Title level={5} className="mb-1.25">
          {t("plans.name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder={t("plans.name-placeholder")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5}>{t("plans.status")}</Title>

        <FormSelect
          value={values.status}
          placeholder={t("plans.status-placeholder")}
          fieldName="status"
          options={orderPlanSelectValues}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          error={errors.status?.toString()}
          isError={!!errors.status}
        />
      </Modal>
    </>
  );
};

export { AddPlanModal };
