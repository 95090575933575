import { Tag } from "antd";
import React, { FC } from "react";
import { Loader } from "shared/ui";
import { renderOrderStatuses } from "shared/helpers";
import { OrderCardStatuses } from "shared/constants";
import { OrderStepTrackerResponseItem } from "shared/api";

interface OrderStatusProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
}

const OrderColorsEnum = {
  PENDING: "#c6d1dd",
  IN_PROGRESS: "#F1EB9A",
  FINISHED: "#A4F6A5",
};

const OrderStatus: FC<OrderStatusProps> = ({
  orderStepTrackerResponseItem,
}) => {
  const renderStatusColor = () => {
    switch (orderStepTrackerResponseItem?.status) {
      case OrderCardStatuses.PENDING:
        return OrderColorsEnum.PENDING;

      case OrderCardStatuses.IN_PROGRESS:
        return OrderColorsEnum.IN_PROGRESS;

      case OrderCardStatuses.FINISHED:
        return OrderColorsEnum.FINISHED;

      default:
        return "";
    }
  };

  if (!orderStepTrackerResponseItem) {
    return <Loader />;
  }

  return (
    <Tag
      color={renderStatusColor()}
      className="ml-3 h-full !text-black self-center"
    >
      {renderOrderStatuses(orderStepTrackerResponseItem)}
    </Tag>
  );
};

export { OrderStatus };
