import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface PdfReportProps {
  pdfUrl: string | null;
}

const PdfReport: FC<PdfReportProps> = ({ pdfUrl }) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });
  const defaultZoom = 75;
  const pdfUrlWithZoom = `${pdfUrl}#zoom=${defaultZoom}`;

  if (!pdfUrl) return null;

  return (
    <div className="flex flex-col w-full">
      <object
        data={pdfUrlWithZoom}
        type="application/pdf"
        width="100%"
        height="750px"
      >
        <p>
          {t("browserPdfSupportIssue")} <a href={pdfUrl}> {t("downloadPdf")}</a>
        </p>
      </object>
    </div>
  );
};

export { PdfReport };
