import { FormikProvider, useFormik } from "formik";
import {
  DynamicValues,
  AddProcessStepValues,
  ProcessStepInputMaterial,
  ProcessStepOutputMaterial,
} from "shared/types";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  adaptDynamicFormValues,
  adaptMaterials,
  adaptProcessStepInputMaterials,
  adaptProcessTemplates,
} from "shared/adapters";
import {
  ConfigDynamicTrackerFields,
  useGetAllMaterialsNoPaginationQuery,
  useGetAppProcessTemplatesWithNoParamsQuery,
  useGetProcessStepByIdQuery,
  useUpdateProcessStepMutation,
} from "shared/api";
import { handleRequestError, noop } from "shared/helpers";
import { FormInput, FormSelect, HyperlinkPageHeader, Loader } from "shared/ui";
import { useProcessStepDynamicData } from "shared/hooks";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { AlertOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { createAddOrUpdateProcessStepSchema } from "shared/schemas";
import { ProcessStepDynamicFields } from "./ui";
import { InputMaterialsDynamicForm } from "./ui/InputMaterialsDynamicForm";
import { OutputMaterialsDynamicForm } from "./ui/OutputMaterialsDynamicForm";

const ProcessStepsHyperlink = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery();

  const { data: processTemplates } =
    useGetAppProcessTemplatesWithNoParamsQuery();

  const { data: processStep, isLoading } = useGetProcessStepByIdQuery(
    { id: Number(id), expand: "process_template" },
    {
      skip: !id,
    }
  );

  const selectedProcessStep = processStep?.[0];

  const [dynamicValues, setDynamicValues] = useState<
    DynamicValues[] | ConfigDynamicTrackerFields[]
  >([]);

  const handleDynamicValues = (dynamicValues: DynamicValues[]) =>
    setDynamicValues(dynamicValues);

  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  const addOrUpdateProcessStepFormik = useFormik({
    initialValues: {
      ...selectedProcessStep,
      process_template: selectedProcessStep?.process_template?.id,
    },
    validationSchema: createAddOrUpdateProcessStepSchema(
      dynamicFieldsValidation
    ),
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const { values, setFieldValue, handleBlur, handleChange, errors, isValid } =
    addOrUpdateProcessStepFormik;

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials || []);
  }, [materials]);

  const adaptedProcessTemplates = useMemo(() => {
    return adaptProcessTemplates(processTemplates || []);
  }, [processTemplates]);

  const { dynamicProcessTemplateFields } = useProcessStepDynamicData({
    processTemplates,
    values: values as unknown as AddProcessStepValues,
    handleDynamicValues,
    setFieldValue: addOrUpdateProcessStepFormik.setFieldValue,
  });

  const [updateProcessStep] = useUpdateProcessStepMutation();

  const updateProcessStepRequest = async () => {
    try {
      await updateProcessStep({
        id: values?.id!,
        name: values.name!,
        dynamic_process_step_fields: values.dynamic_process_step_fields,
        input_materials: adaptProcessStepInputMaterials(
          values?.input_materials as ProcessStepInputMaterial[]
        ),
        output_materials:
          values?.output_materials as ProcessStepOutputMaterial[],
        process_template: values.process_template!,
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-update-msg"),
      });
    } catch (error: any) {
      const description = handleRequestError(error, t("process-step.error"));
      api.error({
        message: t("process-step.error"),
        description,
      });
    }
  };

  if (isLoading) {
    return <Loader type="fullscreen" />;
  }

  return (
    <>
      {contextHolder}
      <FormikProvider value={addOrUpdateProcessStepFormik}>
        <div className="h-auto w-screen flex items-center justify-center flex-col ">
          <HyperlinkPageHeader
            title={`Process Step Hyperlink - ${selectedProcessStep?.name}`}
            isValid={isValid}
            onOk={updateProcessStepRequest}
          />

          <div className="p-4 w-full">
            <Title level={5} className="mb-1.25">
              {t("process-step.process-step-name")}
            </Title>

            <div className="relative w-full">
              <FormInput
                value={values.name!}
                fieldName="name"
                placeholder={t("process-step.name-placeholder")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.name}
                isError={!!errors.name}
              />
              {!values?.name?.length && (
                <AlertOutlined className="btn-remove  absolute top-2 right-2" />
              )}
            </div>

            <Title level={5}> {t("process-step.process-type-name")}</Title>

            <FormSelect
              value={values.process_template}
              placeholder={t("process-step.process-type-placeholder")}
              fieldName="process_template"
              options={adaptedProcessTemplates}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              error={errors.process_template?.toString()}
              isError={!!errors.process_template}
            />

            <ProcessStepDynamicFields
              dynamicProcessTemplateFields={dynamicProcessTemplateFields}
            />

            {adaptedMaterials.length ? (
              <div className="w-full">
                <InputMaterialsDynamicForm
                  adaptedMaterials={adaptedMaterials}
                  isLoadingMaterials={isLoadingMaterials}
                />

                <OutputMaterialsDynamicForm
                  adaptedMaterials={adaptedMaterials}
                  isLoadingMaterials={isLoadingMaterials}
                />
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </FormikProvider>
    </>
  );
};

export { ProcessStepsHyperlink };
